// Library
import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import clsx from "clsx";

//Components
import MovieColabLogo from "../Utilities/MovieColabLogo";
import ProfileDropdown from "../ProfileDropdown/ProfileDropdown.component";
import NotificationsDropdown from "../Notifications/NotificationDropDown/NotificationsDropdown.component";
import SelectProjectDropDown from "../SelectProjectDropDown/SelectProjectDropDown.component";
import NotificationModal from "../Notifications/NotificationModal/NotificationModal";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import { Input, Popover } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Tooltip from "@material-ui/core/Tooltip";

// Styles
import useStyles from "./SidebarAndNavbar.styles";
import { withRouter, matchPath } from "react-router-dom";

//icons
import { Bell, User, ChevronDown, Search } from "react-feather";

import {
  House,
  CheckSquareOffset,
  Clock,
  Cube,
  FilmStrip,
  Users,
  GearSix,
  Queue,
  Warning,
  PersonSimple,
} from "phosphor-react";

import { fget } from "../../API/callsAPI";
import StringTruncate from "../Utilities/StringTruncate/StringTruncate";
import ReviewerIcon from "../Utilities/Svg/Reviewer";

function NavbarAndSidebar(props) {
  const { userRoleForProject } = props;
  //States
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openModal, setModalOpen] = useState(false);
  const [openNotificationsDropDownModal, setopenNotificationsDropDownModal] =
    useState(false);
  const [isNotificationModalOpen, setNotificationModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [projectId, setProjectId] = useState(null);
  const [currentProject, setCurrentProject] = useState({});
  //state that updates sidebar
  const [sidebarType, setSidebarType] = useState("internal"); //internal || external

  useEffect(() => {
    setCurrentProject(props.selectedProject);
    setProjectId(props.selectedProject.id);
    console.log(userRoleForProject);
    if (userRoleForProject === "reviewer") {
      setSidebarType("external");
    } else {
      setSidebarType("internal");
    }
    return () => {};
  }, [userRoleForProject, props.selectedProject]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModelToggle = () => {
    setModalOpen(!openModal);
  };
  const handleNotificationsModalToggle = () => {
    setopenNotificationsDropDownModal(!openNotificationsDropDownModal);
  };

  const handleNotificationModal = () => {
    setNotificationModalOpen(!isNotificationModalOpen);
  };

  const { pathname } = props.location;

  const onChangeSearchQuery = (e) => {
    props.handleSearchQuery(e.target.value);
  };

  const isPath = pathname === "/";
  const isNotification = pathname === "/notifications";
  const isReports = pathname === "/reports";
  const TIMEOUT = 3000;
  let timer;
  function mouseEnter() {
    timer = setTimeout(() => {
      setOpen(true);
    }, TIMEOUT);
  }

  function mouseLeave() {
    clearTimeout(timer);
    setOpen(false);
  }

  const openpopover = Boolean(anchorEl);
  const id = openpopover ? "simple-popover" : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.topToolbar}>
          {isPath || isNotification || isReports ? (
            <a href="/" style={{ textDecoration: "none" }}>
              <div style={{ display: "flex", cursor: "pointer" }}>
                <div style={{ margin: "5px 5px" }}>
                  <MovieColabLogo />
                </div>
                <Typography className={classes.title}>Movie Colab</Typography>
              </div>
            </a>
          ) : null}

          {!isPath && !isNotification && !isReports ? (
            <div className={classes.projectNameAndImage}>
              <img
                className={classes.projectThumbnail}
                alt="project image"
                src={
                  currentProject?.thumbnail
                    ? currentProject?.thumbnail
                    : "https://images.pexels.com/photos/577514/pexels-photo-577514.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                }
              />
              <span style={{ fontSize: "16px" }}>
                {StringTruncate(currentProject?.name, 25)}
              </span>
              {/* <SelectProjectDropDown
                items={projectItems}
                // projectId={projectId}
                history={props.location}
              /> */}
            </div>
          ) : null}
          <div className={classes.searchDiv}>
            <Search size="20px" style={{ marginLeft: "10px" }} />
            <Input
              className={classes.searchInput}
              disableUnderline="false"
              placeholder="Search anything"
              onChange={(e) => onChangeSearchQuery(e)}
            />
          </div>

          <div className={classes.topBarIconDiv}>
            <Button
              className={classes.topBarIcon}
              aria-describedby={id}
              onClick={handleClick}
            >
              <Bell />
            </Button>
            <Popover
              id={id}
              open={openpopover}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              PaperProps={{
                style: {
                  backgroundColor: "transparent",
                  border: "none",
                  boxShadow: "none",
                },
              }}
            >
              <div className={classes.popoverArrowTriangle}></div>
              <div style={{ position: "relative" }}>
                <div className={classes.popoverArrow}></div>
                <NotificationsDropdown
                  handleNotificationsModalToggle={
                    handleNotificationsModalToggle
                  }
                  handleNotificationModal={handleNotificationModal}
                />
              </div>
            </Popover>
            <Button
              className={classes.topBarIcon}
              onClick={() => window.open("/reports", "_blank")}
            >
              <Warning size={25} />
            </Button>
            <Button className={classes.topBarIcon} onClick={handleModelToggle}>
              <User />
              <ChevronDown />
            </Button>
          </div>
        </Toolbar>
      </AppBar>

      {/* sidebar drawer isn't shown when its notification and reports page or projects page */}
      {/* render below sidebar if it is admin or normal user             */}
      {!isPath &&
      !isNotification &&
      !isReports &&
      sidebarType === "internal" ? (
        <Drawer
          // //  style={{backgroundColor:"red"}}
          onMouseLeave={mouseLeave}
          onMouseEnter={mouseEnter}
          onClick={mouseLeave}
          PaperProps={{
            style: {
              borderRight: "0px",
              borderRadius: "10px",
              height: "96.5vh",
              margin: "15px",
              position: "fixed",
            },
          }}
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div
            style={{
              display: "flex",
              position: "relative",
              margin: "1rem 0 3rem 11px",
            }}
          >
            <a href="/" style={{ textDecoration: "none", cursor: "pointer" }}>
              <div style={{ zIndex: 2 }}>
                <MovieColabLogo />
              </div>
            </a>
          </div>
          <List
            className={open ? classes.sideBarListOpen : classes.sideBarList}
          >
            <NavLink
              to="/"
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem>
                {open ? (
                  <div className={`${classes.iconDiv}`}>
                    <ListItemIcon
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <House size="25px" weight="fill" />
                    </ListItemIcon>
                    <ListItemText className={classes.text} disableTypography>
                      Home
                    </ListItemText>
                  </div>
                ) : (
                  <Tooltip arrow title="Home" placement="right">
                    <ListItemIcon className={classes.iconDiv}>
                      <House size="25px" />
                    </ListItemIcon>
                  </Tooltip>
                )}
              </ListItem>
            </NavLink>

            <NavLink
              to={`/${projectId}/tasks`}
              style={{ textDecoration: "none" }}
            >
              <ListItem id="tasks">
                {matchPath(pathname, `/${projectId}/tasks`) ? (
                  // when selected and drawer is open
                  open ? (
                    <div className={`${classes.iconDiv} ${classes.selected}`}>
                      <ListItemIcon
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <CheckSquareOffset size="25px" weight="fill" />
                      </ListItemIcon>

                      <ListItemText className={classes.text} disableTypography>
                        My Tasks
                      </ListItemText>
                    </div>
                  ) : (
                    // when selected and drawer is closed
                    <Tooltip arrow title="My Tasks" placement="right">
                      <ListItemIcon
                        className={`${classes.iconDiv} ${classes.selected}`}
                      >
                        <CheckSquareOffset size="25px" />
                      </ListItemIcon>
                    </Tooltip>
                  )
                ) : // not selected and drawer is open
                open ? (
                  <div className={classes.iconDiv}>
                    <ListItemIcon
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CheckSquareOffset size="25px" />
                    </ListItemIcon>
                    <ListItemText className={classes.text} disableTypography>
                      My Tasks
                    </ListItemText>
                  </div>
                ) : (
                  // not selected and drawer is closed
                  <ListItemIcon className={classes.iconDiv}>
                    <CheckSquareOffset size="25px" />
                  </ListItemIcon>
                )}
              </ListItem>
            </NavLink>

            <NavLink
              to={`/${projectId}/updates`}
              style={{ textDecoration: "none" }}
            >
              <ListItem id="Updates">
                {matchPath(pathname, `/${projectId}/updates`) ? (
                  // when selected and drawer is open
                  open ? (
                    <div className={`${classes.iconDiv} ${classes.selected}`}>
                      <ListItemIcon
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Clock size="25px" weight="fill" />
                      </ListItemIcon>

                      <ListItemText className={classes.text} disableTypography>
                        Updates
                      </ListItemText>
                    </div>
                  ) : (
                    // when selected and drawer is closed
                    <Tooltip arrow title="Updates" placement="right">
                      <ListItemIcon
                        className={`${classes.iconDiv} ${classes.selected}`}
                      >
                        <Clock size="25px" weight="bold" />
                      </ListItemIcon>
                    </Tooltip>
                  )
                ) : // not selected and drawer is open
                open ? (
                  <div className={classes.iconDiv}>
                    <ListItemIcon
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Clock size="25px" />
                    </ListItemIcon>
                    <ListItemText className={classes.text} disableTypography>
                      Updates
                    </ListItemText>
                  </div>
                ) : (
                  // not selected and drawer is closed
                  <ListItemIcon className={classes.iconDiv}>
                    <Clock size="25px" />
                  </ListItemIcon>
                )}
              </ListItem>
            </NavLink>

            <NavLink
              to={`/${projectId}/assets`}
              style={{ textDecoration: "none" }}
            >
              <ListItem id="Assets">
                {matchPath(pathname, `/${projectId}/assets`) ? (
                  // when selected and drawer is open
                  open ? (
                    <div className={`${classes.iconDiv} ${classes.selected}`}>
                      <ListItemIcon
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Cube size="25px" weight="fill" />
                      </ListItemIcon>

                      <ListItemText className={classes.text} disableTypography>
                        Assets
                      </ListItemText>
                    </div>
                  ) : (
                    // when selected and drawer is closed
                    <Tooltip arrow title="Assets" placement="right">
                      <ListItemIcon
                        className={`${classes.iconDiv} ${classes.selected}`}
                      >
                        <Cube size="25px" weight="fill" />
                      </ListItemIcon>
                    </Tooltip>
                  )
                ) : // not selected and drawer is open
                open ? (
                  <div className={classes.iconDiv}>
                    <ListItemIcon
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Cube size="25px" />
                    </ListItemIcon>
                    <ListItemText className={classes.text} disableTypography>
                      Assets
                    </ListItemText>
                  </div>
                ) : (
                  // not selected and drawer is closed
                  <ListItemIcon className={classes.iconDiv}>
                    <Cube size="25px" />
                  </ListItemIcon>
                )}
              </ListItem>
            </NavLink>

            <NavLink
              to={`/${projectId}/sequence`}
              style={{ textDecoration: "none" }}
            >
              <ListItem id="Sequences">
                {matchPath(pathname, `/${projectId}/sequence`) ? (
                  // when selected and drawer is open
                  open ? (
                    <div className={`${classes.iconDiv} ${classes.selected}`}>
                      <ListItemIcon
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FilmStrip size="25px" weight="fill" />
                      </ListItemIcon>

                      <ListItemText className={classes.text} disableTypography>
                        Sequences
                      </ListItemText>
                    </div>
                  ) : (
                    // when selected and drawer is closed
                    <Tooltip arrow title="Sequence" placement="right">
                      <ListItemIcon
                        className={`${classes.iconDiv} ${classes.selected}`}
                      >
                        <FilmStrip size="25px" weight="fill" />
                      </ListItemIcon>
                    </Tooltip>
                  )
                ) : // not selected and drawer is open
                open ? (
                  <div className={classes.iconDiv}>
                    <ListItemIcon
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FilmStrip size="25px" />
                    </ListItemIcon>
                    <ListItemText className={classes.text} disableTypography>
                      Sequences
                    </ListItemText>
                  </div>
                ) : (
                  // not selected and drawer is closed
                  <ListItemIcon className={classes.iconDiv}>
                    <FilmStrip size="25px" />
                  </ListItemIcon>
                )}
              </ListItem>
            </NavLink>

            {/* Showing options that are experimental       */}

            {props.showSeqExperimental ? (
              <NavLink
                to={`/${projectId}/playlist`}
                style={{ textDecoration: "none" }}
              >
                <ListItem id="Playlist">
                  {matchPath(pathname, `/${projectId}/playlist`) ? (
                    // when selected and drawer is open
                    open ? (
                      <div className={`${classes.iconDiv} ${classes.selected}`}>
                        <ListItemIcon
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Queue size="25px" weight="fill" />
                        </ListItemIcon>

                        <ListItemText
                          className={classes.text}
                          disableTypography
                        >
                          Playlist
                        </ListItemText>
                      </div>
                    ) : (
                      // when selected and drawer is closed
                      <Tooltip arrow title="Playlist" placement="right">
                        <ListItemIcon
                          className={`${classes.iconDiv} ${classes.selected}`}
                        >
                          <Queue size="25px" weight="fill" />
                        </ListItemIcon>
                      </Tooltip>
                    )
                  ) : // not selected and drawer is open
                  open ? (
                    <div className={classes.iconDiv}>
                      <ListItemIcon
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Queue size="25px" />
                      </ListItemIcon>
                      <ListItemText className={classes.text} disableTypography>
                        Playlist
                      </ListItemText>
                    </div>
                  ) : (
                    // not selected and drawer is closed
                    <ListItemIcon className={classes.iconDiv}>
                      <Queue size="25px" />
                    </ListItemIcon>
                  )}
                </ListItem>
              </NavLink>
            ) : (
              ""
            )}

            <NavLink
              to={`/${projectId}/review`}
              style={{ textDecoration: "none" }}
            >
              <ListItem id="Reviewer">
                {matchPath(pathname, `/${projectId}/review`) ? (
                  // when selected and drawer is open
                  open ? (
                    <div className={`${classes.iconDiv} ${classes.selected}`}>
                      <ListItemIcon
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <ReviewerIcon size="25px" weight="fill" />
                      </ListItemIcon>

                      <ListItemText className={classes.text} disableTypography>
                        Review
                      </ListItemText>
                    </div>
                  ) : (
                    // when selected and drawer is closed
                    <Tooltip arrow title="Review" placement="right">
                      <ListItemIcon
                        className={`${classes.iconDiv} ${classes.selected}`}
                      >
                        <ReviewerIcon size="25px" weight="bold" />
                      </ListItemIcon>
                    </Tooltip>
                  )
                ) : // not selected and drawer is open
                open ? (
                  <div className={classes.iconDiv}>
                    <ListItemIcon
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ReviewerIcon size="25px" />
                    </ListItemIcon>
                    <ListItemText className={classes.text} disableTypography>
                      Review
                    </ListItemText>
                  </div>
                ) : (
                  // not selected and drawer is closed
                  <ListItemIcon className={classes.iconDiv}>
                    <ReviewerIcon size="25px" />
                  </ListItemIcon>
                )}
              </ListItem>
            </NavLink>
            <NavLink
              to={`/${projectId}/departments`}
              style={{ textDecoration: "none" }}
            >
              <ListItem id="Departments">
                {matchPath(pathname, `/${projectId}/departments`) ? (
                  // when selected and drawer is open
                  open ? (
                    <div className={`${classes.iconDiv} ${classes.selected}`}>
                      <ListItemIcon
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Users size="25px" weight="fill" />
                      </ListItemIcon>

                      <ListItemText className={classes.text} disableTypography>
                        Departments
                      </ListItemText>
                    </div>
                  ) : (
                    // when selected and drawer is closed
                    <Tooltip arrow title="Departments" placement="right">
                      <ListItemIcon
                        className={`${classes.iconDiv} ${classes.selected}`}
                      >
                        <Users size="25px" weight="bold" />
                      </ListItemIcon>
                    </Tooltip>
                  )
                ) : // not selected and drawer is open
                open ? (
                  <div className={classes.iconDiv}>
                    <ListItemIcon
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Users size="25px" />
                    </ListItemIcon>
                    <ListItemText className={classes.text} disableTypography>
                      Departments
                    </ListItemText>
                  </div>
                ) : (
                  // not selected and drawer is closed
                  <ListItemIcon className={classes.iconDiv}>
                    <Users size="25px" />
                  </ListItemIcon>
                )}
              </ListItem>
            </NavLink>

            <NavLink
              to={`/${projectId}/settings`}
              style={{ textDecoration: "none" }}
            >
              <ListItem id="Settings">
                {matchPath(pathname, `/${projectId}/settings`) ? (
                  // when selected and drawer is open
                  open ? (
                    <div className={`${classes.iconDiv} ${classes.selected}`}>
                      <ListItemIcon
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <GearSix size="25px" weight="fill" />
                      </ListItemIcon>

                      <ListItemText className={classes.text} disableTypography>
                        Settings
                      </ListItemText>
                    </div>
                  ) : (
                    // when selected and drawer is closed
                    <Tooltip arrow title="Settings" placement="right">
                      <ListItemIcon
                        className={`${classes.iconDiv} ${classes.selected}`}
                      >
                        <GearSix size="25px" weight="fill" />
                      </ListItemIcon>
                    </Tooltip>
                  )
                ) : // not selected and drawer is open
                open ? (
                  <div className={classes.iconDiv}>
                    <ListItemIcon
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <GearSix size="25px" />
                    </ListItemIcon>
                    <ListItemText className={classes.text} disableTypography>
                      Settings
                    </ListItemText>
                  </div>
                ) : (
                  // not selected and drawer is open
                  <ListItemIcon className={classes.iconDiv}>
                    <GearSix size="25px" />
                  </ListItemIcon>
                )}
              </ListItem>
            </NavLink>
          </List>
        </Drawer>
      ) : null}

      {/* add external reviewer component here */}
      {sidebarType === "external" && !isPath ? (
        <Drawer
          //style={{backgroundColor:"red"}}
          onMouseLeave={mouseLeave}
          onMouseEnter={mouseEnter}
          onClick={mouseLeave}
          PaperProps={{
            style: {
              borderRight: "0px",
              borderRadius: "10px",
              height: "96.5vh",
              margin: "15px",
              position: "fixed",
            },
          }}
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div
            style={{
              display: "flex",
              position: "relative",
              margin: "1rem 0 3rem 11px",
            }}
          >
            <a href="/" style={{ textDecoration: "none", cursor: "pointer" }}>
              <div style={{ zIndex: 2 }}>
                <MovieColabLogo />
              </div>
            </a>
          </div>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                height: "80%",
                width: "80%",
                backgroundColor: "#1B1D28",
                border: "1px solid #6C65D9",
                borderRadius: "10px",
                textOrientation: "mixed",
                writingMode: "vertical-rl",
                padding: "25%",
                paddingTop: "50%",
                transform: "rotate(180deg)",
              }}
            >
              Update status of the version by selecting Revision OR Complete
              icons in the action section.
            </div>
          </div>
        </Drawer>
      ) : (
        ""
      )}

      <Modal
        open={openModal}
        onClose={handleModelToggle}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ProfileDropdown
          themeToggler={props.themeToggler}
          userInfo={props.userInfo}
        />
      </Modal>
      {/* <Modal
        open={openNotificationsDropDownModal}
        onClose={handleNotificationsModalToggle}
      >
        <NotificationsDropdown
          handleNotificationsModalToggle={handleNotificationsModalToggle}
          handleNotificationModal={handleNotificationModal}
        />
      </Modal> */}
      <Modal
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={isNotificationModalOpen}
        onClose={() => setNotificationModalOpen(false)}
      >
        {/* setNotificationModalOpen is passed to close the modal after redirecting to url */}
        <NotificationModal
          handleNotificationModal={handleNotificationModal}
          setNotificationModalOpen={setNotificationModalOpen}
        />
      </Modal>
      <main className={open ? classes.contentOpen : classes.contentClose}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
}

export default withRouter(NavbarAndSidebar);
