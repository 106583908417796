export function getStartEndDateForProject(tasks, projectId) {
  const projectTasks = tasks.filter((t) => t.project === projectId);
  let start = projectTasks[0].start;
  let end = projectTasks[0].end;

  for (let i = 0; i < projectTasks.length; i++) {
    const task = projectTasks[i];
    if (start.getTime() > task.start.getTime()) {
      start = task.start;
    }
    if (end.getTime() < task.end.getTime()) {
      end = task.end;
    }
  }
  return [start, end];
}

/**
 * Returns new task array after date change
 * @param {Object} task task that was changed
 * @param {Array} tasks all task array
 * @returns {Array} updated task array
 */
export const handleTaskChange = (task, tasks) => {
  let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
  if (task.project) {
    const [start, end] = getStartEndDateForProject(newTasks, task.project);
    const project = newTasks[newTasks.findIndex((t) => t.id === task.project)];
    if (
      project.start.getTime() !== start.getTime() ||
      project.end.getTime() !== end.getTime()
    ) {
      const changedProject = { ...project, start, end };
      newTasks = newTasks.map((t) =>
        t.id === task.project ? changedProject : t
      );
    }
  }
  return newTasks;
};

function getNextField(taskId, taskList) {
  let dependencies = [];
  taskList.map((eachItem) => {
    if (eachItem.next === taskId) {
      dependencies.push(eachItem.id);
    }
  });
  return dependencies;
}

export function TaskToGanttTasks(tasksList) {
  let localTasks = [];
  let trackableArray = [];
  tasksList.forEach((eachTask, index) => {
    if (
      trackableArray.findIndex((each) => each === eachTask.linked.id) === -1
    ) {
      trackableArray.push(eachTask.linked.id);
      let projectObj = {
        start: new Date(eachTask.start_time),
        end: new Date(eachTask.end_time),
        name: eachTask.linked.name || eachTask.linked.code,
        id: eachTask.linked.id,
        progress: 25,
        type: "project",
        parentSequence: eachTask.linked.parent_sequence || "Asset",
        hideChildren: false,
        displayOrder: index + 1,
      };

      let taskObj = {
        id: eachTask.id,
        type: "task",
        name: eachTask.name,
        start: new Date(eachTask.start_time),
        end: new Date(eachTask.end_time),
        /**
         * From 0 to 100
         */
        progress: 0,
        isDisabled: false,
        // project: string;
        dependencies: getNextField(eachTask.id, tasksList),
        hideChildren: false,
        displayOrder: index + 2,
        isChildTask: true,
        taskStatus: eachTask.status || {},
        linked: eachTask.linked.id,
        project: eachTask.linked.id,
      };
      localTasks.push(projectObj);
      localTasks.push(taskObj);
    } else {
      localTasks.map((eachItem) => {
        if (eachItem.type === "project" && eachItem.id === eachTask.linked.id) {
          if (
            new Date(eachItem.end).getTime() <
            new Date(eachTask.end_time).getTime()
          ) {
            eachItem.end = new Date(eachTask.end_time);
          }
          return null;
        } else return null;
      });
      let taskObj = {
        id: eachTask.id,
        type: "task",
        name: eachTask.name,
        start: new Date(eachTask.start_time),
        end: new Date(eachTask.end_time),
        /**
         * From 0 to 100
         */
        progress: 0,
        isDisabled: false,
        // project: string;
        dependencies: getNextField(eachTask.id, tasksList),
        hideChildren: false,
        displayOrder: index + 1,
        isChildTask: true,
        taskStatus: eachTask.status || {},
        linked: eachTask.linked.id,
        project: eachTask.linked.id,
      };
      localTasks.push(taskObj);
    }
  });
  return localTasks;
}
