const VersionIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
    >
      <path
        d="M13.1414 14.2638L12.368 14.6988L9.27422 16.4391C9.19085 16.4872 9.09627 16.5125 9 16.5125C8.90373 16.5125 8.80915 16.4872 8.72578 16.4391L2.53828 12.9586C2.4512 12.9091 2.3787 12.8376 2.32808 12.7512C2.27747 12.6648 2.25054 12.5665 2.25 12.4664V5.53359C2.25054 5.43345 2.27747 5.33522 2.32808 5.24881C2.3787 5.16239 2.4512 5.09085 2.53828 5.0414L8.72578 1.56093C8.80915 1.5128 8.90373 1.48746 9 1.48746C9.09627 1.48746 9.19085 1.5128 9.27422 1.56093L15.4617 5.0414C15.5488 5.09085 15.6213 5.16239 15.6719 5.24881C15.7225 5.33522 15.7495 5.43345 15.75 5.53359V7.26679V8.1334V8.5667"
        stroke="currentColor"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.6726 5.24533L9.06321 9.00002L2.32727 5.24533"
        stroke="currentColor"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.06328 9L9 16.5094"
        stroke="currentColor"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.9625 12.3167L15.9075 9.448H16.9133L15.5295 13.1875H14.382L13.005 9.448H14.0175L14.9625 12.3167Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default VersionIcon;
