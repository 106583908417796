//change file names.
import { React } from "react";
import { Divider, Button } from "@material-ui/core";
import useStyles from "./Rearrange.style.js";
import { Reorder } from "framer-motion";
import { X } from "phosphor-react";
import { useState } from "react";
import { Item } from "./RearrangeItems.jsx";
import RearrangeIcon from "../Svg/RearrangeIcon.jsx";
import { useEffect } from "react";
export default function Rearrange({
  onClose,
  itemsList,
  tasksOrder,
  rearrangeTaskOrder,
}) {
  const [items, setItems] = useState(itemsList);
  const classes = useStyles();

  const handleRearrangeOder = (e) => {
    setItems(e);
  };

  useEffect(() => {
    let rearrangedTask = [];
    for (let i = 0; i < itemsList.length; i++) {
      const index = tasksOrder.findIndex((item) => itemsList[i].id === item);
      rearrangedTask[index] = items[i];
    }
    setItems(rearrangedTask);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasksOrder]);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
          <RearrangeIcon />
          <span className={classes.rearrangeText}>Rearrange</span>
        </div>
        <div className={classes.title}>Asset Creation</div>
        <div style={{ display: "flex", gap: "5px" }}>
          <Button
            className={classes.button}
            onClick={() => {
              if (items) {
                rearrangeTaskOrder(items.map((each) => each.id));
              }
            }}
          >
            Done
          </Button>
          <Button onClick={onClose}>
            <X size={24} weight="bold" className={classes.closeIcon} />
          </Button>
        </div>
      </div>
      <Divider style={{ width: "100%" }} />
      <Reorder.Group
        className={classes.reorderGroup}
        layoutScroll
        values={items}
        onReorder={(e) => handleRearrangeOder(e)}
      >
        {items.map((item) => (
          <Item item={item} key={item.id} />
        ))}
      </Reorder.Group>
    </div>
  );
}
