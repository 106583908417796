import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  popoverArrow: {
    borderRadius: "5px",
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderBottom: `20px solid ${theme.palette.secondary.light}`,
    borderTop: "3px solid transparent",
    width: "0",
    height: "0",
    transform: "rotate(-90deg)",
  },
  popoverArrowTriangle: {
    borderRadius: "3px",
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderBottom: `20px solid ${theme.palette.secondary.main}`,
    borderTop: "3px solid transparent",
    width: "0",
    height: "0",
    transform: "rotate(-90deg)",
    position: "absolute",
    left: "-0.8%",
    zIndex: "-1",
  },
  card: {
    width: "180px",
    borderRadius: "10px",
    backgroundColor: theme.palette.secondary.light,
    padding: "10px",
    border: `1px solid ${theme.palette.secondary.main}`,
  },
}));

export default useStyles;
