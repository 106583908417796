import React from "react";

const PageBreakSvg = () => {
  return (
    <svg
      width="398"
      height="291"
      viewBox="0 0 398 291"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_7780_14844)">
        <path
          d="M398 291H0C4.36108 279.89 12.4147 270.464 23.7678 267.668C40.3757 263.583 57.9512 274.814 74.5211 270.591C83.9355 268.195 91.3474 261.21 99.1356 255.409C128.517 233.523 168.782 227.055 203.55 238.639C221.724 244.691 238.284 255.275 256.901 259.776C280.975 265.597 306.15 260.768 330.906 259.974C355.017 259.199 389.563 269.014 398 291Z"
          fill="#F3F5FD"
        />
        <path
          d="M262.378 178.198H124.218V0H232.975L262.378 31.0443V178.198Z"
          fill="#DEE8F5"
        />
        <path d="M232.975 0V31.0443H262.378L232.975 0Z" fill="#F3F5FD" />
        <path
          d="M371.129 152.601H281.107V36.4922H351.97L371.129 56.7202V152.601Z"
          fill="#606479"
        />
        <path
          d="M351.971 36.4922V56.7202H371.129L351.971 36.4922Z"
          fill="#DEE8F5"
        />
        <path
          d="M105.49 152.601H15.4678V36.4922H86.3313L105.49 56.7202V152.601Z"
          fill="#606479"
        />
        <path
          d="M86.3311 36.4922V56.7202H105.49L86.3311 36.4922Z"
          fill="#DEE8F5"
        />
        <path
          d="M113.28 133.145C112.731 133.144 112.193 132.999 111.718 132.725C111.243 132.451 110.849 132.057 110.575 131.582C110.301 131.108 110.156 130.57 110.156 130.022C110.155 129.474 110.299 128.936 110.572 128.461L124.126 104.932C124.329 104.572 124.601 104.255 124.926 104.001C125.252 103.746 125.625 103.558 126.024 103.449C126.423 103.339 126.839 103.31 127.249 103.362C127.66 103.414 128.056 103.547 128.414 103.753C128.772 103.96 129.086 104.235 129.338 104.563C129.589 104.891 129.773 105.266 129.878 105.665C129.983 106.065 130.009 106.481 129.952 106.89C129.895 107.3 129.758 107.694 129.547 108.049L115.993 131.578C115.719 132.055 115.323 132.451 114.847 132.726C114.371 133.001 113.83 133.146 113.28 133.145Z"
          fill="#0CBC8B"
        />
        <path
          d="M134.257 109.484C133.757 109.484 133.254 109.462 132.746 109.42C128.096 109.023 123.795 106.797 120.788 103.232C117.781 99.6673 116.314 95.0549 116.711 90.4096C117.108 85.7644 119.336 81.467 122.904 78.4627C126.473 75.4584 131.089 73.9934 135.739 74.3899C140.258 74.7756 144.454 76.8899 147.45 80.2916C150.446 83.6933 152.012 88.1195 151.821 92.6468C151.629 97.1741 149.696 101.453 146.423 104.59C143.15 107.727 138.791 109.48 134.255 109.484H134.257ZM134.235 80.5746C131.267 80.5705 128.416 81.7281 126.292 83.7994C124.167 85.8706 122.94 88.6907 122.872 91.6553C122.804 94.6199 123.902 97.493 125.929 99.6591C127.956 101.825 130.752 103.112 133.717 103.243C136.682 103.374 139.581 102.34 141.792 100.362C144.003 98.3836 145.35 95.6188 145.546 92.6598C145.741 89.7009 144.768 86.7833 142.836 84.5323C140.903 82.2814 138.165 80.8761 135.209 80.6177C134.882 80.5884 134.557 80.5746 134.235 80.5746Z"
          fill="#0CBC8B"
        />
        <path
          d="M90.6208 285.522L81.6211 284.453C82.0259 281.049 82.4842 276.961 82.5153 276.291C82.5153 276.334 82.5153 276.369 82.5153 276.397H91.5784C91.5763 276.667 91.5763 277.493 90.6208 285.522Z"
          fill="#E98862"
        />
        <path
          d="M54.5079 286.602L45.4448 286.573C45.4448 286.573 45.4746 277.223 45.4746 276.395H54.5377C54.5381 277.225 54.5079 286.602 54.5079 286.602Z"
          fill="#E98862"
        />
        <path
          d="M93.9642 282.389C93.2141 263.414 93.1688 243.484 92.934 224.459C89.8154 203.939 88.1784 181.432 84.3305 161.055L84.2157 161.089L83.8782 158.791L51.0452 156.769C51.0452 156.769 50.189 172.841 49.8903 178.198C48.29 206.908 45.0424 253.056 43.1094 282.148L59.0207 283.491C61.8627 254.879 66.4965 209.297 69.0644 181.097C71.8826 195.467 74.5402 211.648 77.0421 226.188C77.5897 244.762 78.0481 264.118 77.998 282.662L93.9642 282.389Z"
          fill="#262626"
        />
        <path
          d="M65.5252 290.105C65.9955 290.103 66.4456 289.914 66.7766 289.58C67.1076 289.247 67.2924 288.795 67.2904 288.325C67.2904 288.282 67.2904 288.239 67.2847 288.196C67.2584 287.86 67.1364 287.538 66.9331 287.268C66.7298 286.998 66.4537 286.793 66.1372 286.674L55.5869 283.145C55.3825 283.077 55.166 283.055 54.952 283.079C54.7381 283.102 54.5319 283.172 54.3474 283.283C52.8971 284.288 51.1624 284.802 49.3981 284.749C48.0239 284.651 46.7049 284.17 45.5916 283.359C45.4937 283.279 45.375 283.228 45.2494 283.213C45.1239 283.198 44.9965 283.219 44.8822 283.273C44.7679 283.327 44.6714 283.413 44.6039 283.519C44.5364 283.626 44.5007 283.75 44.501 283.877V289.03C44.501 289.336 44.6227 289.63 44.8395 289.846C45.0562 290.063 45.3502 290.185 45.6567 290.185H45.6611L65.5252 290.105Z"
          fill="#606479"
        />
        <path
          d="M101.663 290.104C102.133 290.102 102.583 289.914 102.914 289.58C103.245 289.246 103.43 288.795 103.428 288.325C103.428 288.282 103.426 288.239 103.422 288.196C103.396 287.86 103.274 287.538 103.071 287.268C102.868 286.998 102.591 286.793 102.275 286.674L91.726 283.145C91.5216 283.077 91.3051 283.055 91.0911 283.079C90.8772 283.102 90.671 283.172 90.4865 283.283C89.0362 284.288 87.3015 284.802 85.5372 284.749C84.1629 284.651 82.8437 284.17 81.7302 283.359C81.6324 283.279 81.5137 283.228 81.3881 283.213C81.2625 283.198 81.1352 283.219 81.0209 283.273C80.9066 283.327 80.8101 283.412 80.7426 283.519C80.6751 283.626 80.6394 283.75 80.6396 283.877V289.03C80.6396 289.336 80.7614 289.63 80.9782 289.846C81.1949 290.063 81.4889 290.185 81.7954 290.185H81.8002L101.663 290.104Z"
          fill="#606479"
        />
        <path
          d="M61.6836 112.56C61.6836 112.56 65.0205 110.792 64.2756 101.713L71.1705 100.436C71.1705 100.436 70.3937 108.576 73.8838 111.16L61.6836 112.56Z"
          fill="#E98862"
        />
        <path
          d="M60.3635 98.1922C60.8408 100.947 62.3453 103.555 64.7772 104.444C67.6644 105.501 69.8029 105.572 73.1316 105.572C77.674 105.572 77.5238 100.839 77.6515 96.0695C77.781 91.2835 77.3982 86.1448 75.6028 84.4762C72.0208 81.1458 61.502 83.2547 60.2228 88.8254C59.562 91.7069 59.9073 95.5702 60.3635 98.1922Z"
          fill="#E98862"
        />
        <path
          d="M80.4575 84.4233C79.698 81.6392 77.3295 80.2793 73.4133 81.302C70.6279 82.0294 70.2754 81.3753 67.9164 80.2409C66.5655 79.5912 62.1332 79.8878 61.0366 82.8849C59.861 86.0954 56.2241 83.4954 55.8862 88.7255C55.6035 93.1032 57.0847 99.3608 60.0608 102.882C61.0914 104.101 62.7724 105.562 64.0745 105.924C65.3532 106.279 70.8817 105.967 71.419 105.65C74.059 104.084 73.788 96.9018 73.3693 92.2943C73.3007 91.5363 73.4401 90.2764 75.3874 90.2764C76.7253 90.2764 77.4663 90.2445 78.1572 89.747C78.1585 89.747 81.218 87.2069 80.4575 84.4233Z"
          fill="#0F1011"
        />
        <path
          d="M75.9843 96.9743C76.037 97.6719 75.8113 98.3621 75.3564 98.894C74.9015 99.426 74.2545 99.7565 73.5565 99.8134C72.8585 99.8704 72.1663 99.6491 71.6311 99.198C71.0959 98.7468 70.761 98.1024 70.6997 97.4054C70.666 97.065 70.6997 96.7212 70.7989 96.3938C70.8982 96.0664 71.061 95.7617 71.2782 95.4972C71.4953 95.2326 71.7625 95.0134 72.0644 94.852C72.3664 94.6907 72.6972 94.5903 73.038 94.5567C73.0673 94.5537 73.0962 94.5511 73.1243 94.5494C75.015 94.4894 75.8648 95.5165 75.9843 96.9743Z"
          fill="#E98862"
        />
        <path
          d="M113.548 126.692C113.548 126.692 120.82 123.307 121.963 122.392C125.987 119.172 125.903 116.772 125.359 115.757C124.717 114.558 117.169 118.517 117.169 118.517C117.169 118.517 118.632 116.49 117.843 115.934C117.518 115.706 117.073 115.918 115.767 117.221C114.278 118.707 111.728 120.792 111.728 120.792L113.548 126.692Z"
          fill="#E98862"
        />
        <path
          d="M51.4494 135.937C51.8661 137.608 52.0939 139.32 52.1282 141.041C52.1282 150.096 48.0283 154.156 48.3304 158.805C48.8051 166.112 84.3363 164.385 86.1468 160.235C88.0108 155.955 84.2276 153.318 84.3933 139.319C84.411 137.796 84.5288 136.202 84.6911 134.579L91.6826 147.083C92.0487 147.738 92.5662 148.295 93.192 148.709C93.8177 149.123 94.5336 149.382 95.2797 149.463C96.0259 149.544 96.7806 149.446 97.481 149.176C98.1813 148.907 98.807 148.474 99.3056 147.913L118.676 125.277L111.403 120.167L96.6695 136.728L85.1464 116.118C84.0554 112.862 81.5717 110.681 76.505 110.464C74.7722 110.39 66.3172 110.378 60.1266 110.378C55.968 110.378 52.5538 111.653 50.5819 113.622L50.5521 113.652C49.8291 114.387 49.2718 115.268 48.9173 116.236C48.5629 117.204 48.4195 118.236 48.497 119.264C48.689 122.186 49.329 127.984 51.4494 135.937Z"
          fill="#6C65D9"
        />
        <path
          d="M179.341 50.2504L175.693 46.6055L152.022 70.2538L155.671 73.8987L179.341 50.2504Z"
          fill="#6C65D9"
        />
        <path
          d="M175.693 73.8988L179.341 70.2539L155.671 46.6056L152.022 50.2505L175.693 73.8988Z"
          fill="#6C65D9"
        />
        <path
          d="M234.575 50.2504L230.926 46.6055L207.256 70.2538L210.904 73.8987L234.575 50.2504Z"
          fill="#6C65D9"
        />
        <path
          d="M230.926 73.8988L234.575 70.2539L210.904 46.6056L207.256 50.2505L230.926 73.8988Z"
          fill="#6C65D9"
        />
        <path
          d="M169.354 114.313L165.541 110.811C165.959 110.356 175.985 99.6562 194.809 99.6562C213.742 99.6562 222.576 110.488 222.942 110.949L218.888 114.168C218.586 113.796 211.125 104.83 194.809 104.83C178.362 104.831 169.442 114.217 169.354 114.313Z"
          fill="#6C65D9"
        />
        <path
          d="M322.815 191.131L318.367 192.182L323.943 215.745L328.391 214.694L322.815 191.131Z"
          fill="#F0F6FF"
        />
        <path
          d="M327.709 189.303L323.89 209.985L326.359 210.44L330.178 189.758L327.709 189.303Z"
          fill="#606479"
        />
        <path
          d="M327.71 189.297L327.276 191.578L329.743 192.047L330.177 189.766L327.71 189.297Z"
          fill="#DEE8F5"
        />
        <path
          d="M320.064 194.556L318.692 195.096L326.694 215.388L328.065 214.848L320.064 194.556Z"
          fill="#0CBC8B"
        />
        <path
          d="M330.373 203.438H318.311V218.84H330.373V203.438Z"
          fill="#606479"
        />
        <path d="M286.113 246.66H281.602V291H286.113V246.66Z" fill="#606479" />
        <path d="M282.399 246.66H277.888V291H282.399V246.66Z" fill="#DEE8F5" />
        <path d="M342.306 246.66H337.795V291H342.306V246.66Z" fill="#606479" />
        <path d="M338.593 246.66H334.082V291H338.593V246.66Z" fill="#DEE8F5" />
        <path d="M298.486 246.66H293.975V291H298.486V246.66Z" fill="#606479" />
        <path d="M294.773 246.66H290.262V291H294.773V246.66Z" fill="#DEE8F5" />
        <path d="M354.68 246.66H350.168V291H354.68V246.66Z" fill="#606479" />
        <path d="M350.967 246.66H346.456V291H350.967V246.66Z" fill="#DEE8F5" />
        <path
          d="M354.68 218.278H294.003V247.723H354.68V218.278Z"
          fill="#606479"
        />
        <path
          d="M338.565 218.278H277.888V247.723H338.565V218.278Z"
          fill="#DEE8F5"
        />
        <g opacity="0.53">
          <path
            d="M335.301 222.464H281.153V243.407H335.301V222.464Z"
            fill="#F3F5FD"
          />
        </g>
        <path
          d="M308.227 235.338C309.555 235.338 310.631 234.263 310.631 232.936C310.631 231.609 309.555 230.533 308.227 230.533C306.898 230.533 305.822 231.609 305.822 232.936C305.822 234.263 306.898 235.338 308.227 235.338Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_7780_14844">
          <rect width="398" height="291" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PageBreakSvg;
