import { Button, Card, CardActions, CardContent } from "@material-ui/core";
import React from "react";
import { X } from "react-feather";
import useStyles from "./CreateTaskOptionModal.style";

export default function CreateTaskOptionModal(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div class={classes.popoverArrow}></div>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div className={classes.clear} onClick={(e) => props.closeModal()}>
              <X size="30px" />
            </div>
          </div>
          <CardActions className={classes.cardAction}>
            <Button
              color="secondary"
              className={classes.newTaskButton}
              onClick={() => {
                props.handleNewTaskModal();
                props.closeModal();
              }}
            >
              Create New Task
            </Button>
          </CardActions>
          <CardActions className={classes.cardAction}>
            <Button
              color="secondary"
              className={classes.newTaskButton}
              onClick={() => {
                props.handleCreateTaskFromTemplateModal();
                props.closeModal();
              }}
            >
              Create Task From Template
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </div>
  );
}
