import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  advanceModal: {
    width: "430px",
    height: "455px",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "20px",
    padding: "20px",
  },
  advanceModalHeader: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "36px",
  },
  crossBtn: {
    minWidth: "0px",
    padding: "5px",
  },
  formControl: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "40px",
    background: theme.palette.secondary.light,
    borderRadius: "10px",
    marginTop: "10px",
  },
  dropdownDiv: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },
  selectText: {
    marginLeft: "10px",
    fontFamily: "Poppins",
    fontSize: "14px",
    color: theme.palette.text.primary,
  },
  iconStyle: {
    marginRight: "10px",
    height: "20px",
    color: theme.palette.secondary.main,
  },
  formLabelText: {
    height: "10%",
    width: "100%",
    opacity: "0",
    background: theme.palette.secondary.light,
  },
  durationDropDown: {
    backgroundColor: theme.palette.secondary.light,
    "& ul.MuiList-padding": {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
  divider: {
    width: "85%",
    backgroundColor: theme.palette.text.tertiary,
    marginLeft: "15px",
  },
  datePicker: {
    marginTop: "12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  datePickerDiv: {
    width: "46%",
    borderRadius: "4px",
  },
  datePickerText: {
    fontSize: "14px",
    fontFamily: "Poppins",
    color: theme.palette.text.tertiary,
  },
  dateInput: {
    height: "48px",
    padding: "10px 14px",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    width: "100%",
  },
  userDiv: {
    marginTop: "10px",
  },
  userPlusIcon: {
    width: "36px",
    height: "36px",
    borderRadius: "50%",
    borderColor: theme.palette.text.tertiary,
    color: theme.palette.text.tertiary,
    border: "2px solid",
  },
  advanceNotificationBtnDiv: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  filterBtn: {
    height: "100%",
    width: "135px",
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default useStyles;
