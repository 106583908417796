import React, { useState, useRef } from "react";
import useStyles from "./TrackableStats.style";
import { Button, List, ListItem } from "@material-ui/core";
import StatisticSvg from "../Svg/StatisticSvg";

export default function TrackableStats({ type, stats }) {
  const classes = useStyles();
  const ref = useRef(null);
  const [isPopoverOver, setPopoverOpen] = useState(false);

  return (
    <>
      <div
        ref={ref}
        onMouseEnter={() => setPopoverOpen(true)}
        onMouseLeave={() => setPopoverOpen(false)}
        style={{ position: "relative" }}
      >
        <Button>
          <StatisticSvg />
        </Button>

        {isPopoverOver ? (
          <div
            className={classes.card}
            style={{
              position: "absolute",
              left: `${ref?.current?.offsetWidth}px`,
              top: "-50%",
              cursor: "auto",
              zIndex: "10",
            }}
          >
            <span className={classes.header}>Content Stats</span>
            <List style={{ width: "100%" }}>
              <ListItem className={classes.listItem}>
                <span style={{ fontSize: "11px" }}>Category Name</span>
                <span style={{ fontSize: "11px" }}>Content Permissions</span>
              </ListItem>
              <ListItem className={classes.listItem}>
                <span>{type} :</span>
                <span className={classes.textStats}>
                  {stats.visibleCount} out of {stats.total}
                </span>
              </ListItem>
            </List>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
