import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import useStyles from "./SequenceOverview.styles";

//@Components
import SequencePageTools from "../SequencePageTools/SequencePageTools.component";
import SequencePageNewSequenceModal from "./../SequencePageModals/SequencePageNewSequenceModal";
import SequenceList from "../SequenceList/SequenceList.component";
import NoData from "../../../Pages/InformativeComponents/NoData.component";
import { checkImage } from "../../utils";
//@MUI
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  Modal,
} from "@material-ui/core";
import CustomPagination from "../../Utilities/CustomPagination/CustomPagination.component";
//@API
import { fget, fpostBFF } from "../../../API/callsAPI";
import Loader from "../../Utilities/Loader/Loader";
import NewDataLoadingLoader from "../../Utilities/NewDataLoadingLoader/NewDataLoadingLoader.component";

export default function SequenceOverview(props) {
  const classes = useStyles();
  const history = useHistory();
  const tableRef = useRef();

  const [sliderState, setSliderState] = useState("0");
  const [sequenceList, setSequenceList] = React.useState();
  const [sequenceFilteredStatus, setSequenceFilteredStatus] = useState("");
  const [statusList, setStatusList] = useState([]);
  const [isNewSequenceModalOpen, setNewSequenceModalOpen] = useState(false);
  const [sortByDefault, setSortByDefault] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [toggler, setToggler] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [file, setFile] = useState("");
  const [dragScreen, setDragScreen] = useState(false);
  //state will be true when we start drag file inside modals
  const [dragFileEnterModal, setDragFileEnterModal] = useState(false);
  const [scrollValue, setScrollValue] = useState("");
  const [updateOnPageChange, setUpdateOnPageChange] = useState(0);
  const [isNewPageLoading, setIsNewPageLoading] = useState(false);
  const [sequenceStats, setSequenceStats] = useState({
    total: 0,
    visibleCount: 0,
  });

  const decodedURL = decodeURIComponent(
    escape(window.atob(history.location.search.substring(1)))
  );
  let searchParams = new URLSearchParams(decodedURL);

  useEffect(() => {
    setSequenceFilteredStatus(searchParams.get("status") || "");
    setSortByDefault(searchParams.get("sortBy") || "A-Z");
    if (
      searchParams.get("status") === null ||
      searchParams.get("status") === ""
    ) {
      fetchSequence();
    }

    var scrollStored = JSON.parse(localStorage.getItem("scroll"));
    if (scrollStored) {
      setScrollValue(scrollStored.sequence);
    } else {
      const scrollData = {
        asset: "0",
        assetVersion: "0",
        sequence: "0",
        shots: "0",
        shotVersion: "0",
      };
      localStorage.setItem("scroll", JSON.stringify(scrollData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      parseInt(sequenceFilteredStatus) > 0 ||
      updateOnPageChange ||
      refreshCounter
    )
      fetchFilteredSequence();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sequenceFilteredStatus, currentPage, updateOnPageChange, refreshCounter]);

  const updateRefreshCounter = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  const handlePageChange = (pageNo) => {
    setCurrentPage(pageNo);
    setUpdateOnPageChange(updateOnPageChange + 1);
  };

  function handleNewSequenceModalState() {
    setNewSequenceModalOpen(!isNewSequenceModalOpen);
  }

  const handleToggler = () => {
    setToggler(!toggler);
  };

  const fetchFilteredSequence = async () => {
    const url = `trackables/shot-sequence/?page=${currentPage}&project=${props.params.projectId}&status=${sequenceFilteredStatus}&sortBy=${sortByDefault}`;

    try {
      const filteredSeqRes = await fget({
        url: `trackables/shot-sequence/?page=${currentPage}&project=${props.params.projectId}&status=${sequenceFilteredStatus}`,
      });
      setSequenceList(filteredSeqRes?.data);
      setIsLoaded(true);
      setIsNewPageLoading(false);
    } catch (error) {
      console.log(error);
      setIsNewPageLoading(false);
    }
    history.push("?" + btoa(unescape(encodeURIComponent(url.split("?")[1]))));
  };

  //post req bff to get projectDetails,status,sequence
  const fetchSequence = async () => {
    const sequence = {
      url: "web/sequence/list/",
      data: {
        project_id: props.params.projectId,
      },
    };
    try {
      const sequenceRes = await fpostBFF(sequence);
      setSequenceList(sequenceRes?.data?.sequence_list);
      setSequenceStats((prevData) => ({
        ...prevData,
        visibleCount: sequenceRes?.data?.sequence_list.count,
      }));
      setIsLoaded(true);
      setStatusList(sequenceRes?.data?.status_list?.results);
      window.scrollTo(0, sessionStorage.getItem("scrollpos"));
    } catch (error) {
      console.log(error);
    }
  };

  async function fetchFile(id) {
    if (id) {
      // fetching the signed url only if file is there

      try {
        const res = await fget({
          url: `trackables/sequence/${id}/file`,
        });
        setFile(res?.data?.signed_url);
      } catch (error) {
        setFile("");
        console.log(error);
      }
    } else setFile("");
  }

  useEffect(() => {
    sequenceList?.results &&
      searchParams.get("sortBy") &&
      handleSubmitSort(searchParams.get("sortBy"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sequenceList]);

  //Function to sort Sequences
  function handleSubmitSort(sortBy) {
    searchParams.set("sortBy", `${sortBy}`);
    history.push(
      "?" + btoa(unescape(encodeURIComponent(searchParams.toString())))
    );

    switch (sortBy) {
      case "A-Z":
        sequenceList?.results?.sort((a, b) => a.code.localeCompare(b.code));
        setSortByDefault("A-Z");
        break;
      case "Z-A":
        sequenceList?.results?.sort((a, b) => b.code.localeCompare(a.code));
        setSortByDefault("Z-A");
        break;
      case "LatestModified":
        sequenceList?.results?.sort(function (a, b) {
          var dateA = new Date(a.updated_at),
            dateB = new Date(b.updated_at);
          return dateB - dateA;
        });
        setSortByDefault("LatestModified");
        break;
      case "LastModified":
        sequenceList?.results?.sort(function (a, b) {
          var dateA = new Date(a.updated_at),
            dateB = new Date(b.updated_at);
          return dateA - dateB;
        });
        setSortByDefault("LastModified");
        break;
      case "DateCreated":
        sequenceList?.results?.sort(function (a, b) {
          var dateA = new Date(a.created_at),
            dateB = new Date(b.created_at);
          return dateA - dateB;
        });
        setSortByDefault("DateCreated");
        break;
      default:
        return sequenceList?.results;
    }
  }
  //event to save scrollBar position
  window.onbeforeunload = function (e) {
    sessionStorage.setItem("scrollpos", window.scrollY);
  };

  const handleDragFile = (e) => {
    setDragScreen(true);
    if (e.type === "drop") {
      //if we drop file , removing drag zone screen
      setDragScreen(false);
    }
    if (e.type === "dragleave") {
      //if we leave the drag file , removing drag zone screen
      if (dragFileEnterModal) {
        setDragScreen(true);
      } else {
        setDragScreen(false);
      }
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragScreen = (el) => {
    setDragScreen(el);
  };

  let scrollTimer = null;
  const handleTableScroll = (e) => {
    if (scrollTimer !== null) clearTimeout(scrollTimer);

    scrollTimer = setTimeout(() => {
      searchParams.set("scroll", `${e.target.scrollTop}`);
      history.push(
        "?" + btoa(unescape(encodeURIComponent(searchParams.toString())))
      );
      let scrollStored = JSON.parse(localStorage.getItem("scroll"));
      scrollStored.sequence = e.target.scrollTop;
      localStorage.setItem("scroll", JSON.stringify(scrollStored));
    }, 700);
  };

  const getSequenceStats = async () => {
    try {
      const sequenceRes = await fget({
        url: `trackables/trackable-count/sequence/${props.params.projectId}/`,
      });
      setSequenceStats((prevData) => ({
        ...prevData,
        total: sequenceRes?.data?.instances,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSequenceStats();
  }, [refreshCounter]);

  return (
    <div
      className={classes.root}
      onDragEnter={(e) => handleDragFile(e)}
      onDrop={(e) => handleDragFile(e)}
      onDragLeave={(e) => handleDragFile(e)}
      onDragOver={(e) => handleDragFile(e)}
    >
      <SequencePageTools
        handleSliderChange={(value) => {
          setSliderState(value);
          sessionStorage.setItem("sliderState", `${sliderState}`);
        }}
        sliderState={sliderState}
        openNewSequenceModal={handleNewSequenceModalState}
        status={statusList}
        handleStatusChange={(value) => {
          if (value === "") fetchSequence();
          else setSequenceFilteredStatus(value);
        }}
        filterValues={{ status: sequenceFilteredStatus }}
        handleToggler={handleToggler}
        onSubmit={handleSubmitSort}
        defaultvalue={sortByDefault}
        sequenceStats={sequenceStats}
      />
      {isLoaded ? (
        <>
          {sequenceList && sequenceList.count !== 0 ? (
            <>
              <TableContainer className={classes.sequenceTableContainer}>
                <Table aria-label="simple table" className={classes.table}>
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      <TableCell
                        align="left"
                        className={classes.sequencePageTableCellTwo}
                      >
                        Sequence Name
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.sequencePageTableCell}
                      >
                        last Modified
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.sequencePageTableCell}
                      >
                        Description
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.sequencePageTableCell}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.sequencePageTableCell}
                      >
                        Sequence Code
                      </TableCell>
                      {/* <TableCell align="center"
                        className={classes.sequencePageTableCell}>Users</TableCell> */}
                      <TableCell
                        align="right"
                        className={classes.sequencePageTableCellThree}
                      >
                        Actions
                      </TableCell>
                      {/* <TableCell align="center">Departments</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody
                    className={classes.tableBody}
                    onScroll={handleTableScroll}
                    ref={tableRef}
                    onLoad={() => (tableRef.current.scrollTop = scrollValue)}
                    style={{
                      height:
                        sequenceList.count > 50
                          ? `calc(100vh - 290px)`
                          : `calc(100vh - 250px)`,
                    }}
                  >
                    {sequenceList && sequenceList.count !== 0
                      ? sequenceList.results
                          ?.filter((search) => {
                            if (props.searchQuery === undefined) {
                              return search;
                            } else {
                              return search;
                            }
                          })
                          .map((seq) => {
                            return (
                              <SequenceList
                                dragScreen={dragScreen}
                                handleDragScreen={handleDragScreen}
                                setDragFileEnterModal={setDragFileEnterModal}
                                projectId={props.params.projectId}
                                status={statusList}
                                data={seq}
                                sliderState={sliderState}
                                toggler={toggler}
                                updateRefreshCounter={updateRefreshCounter}
                                setIsViewModalOpen={setIsViewModalOpen}
                                fetchFile={fetchFile}
                              />
                            );
                          })
                      : " No Sequences"}
                  </TableBody>
                </Table>
              </TableContainer>
              {sequenceList && sequenceList.count > 50 ? (
                <footer style={{ bottom: "0", height: "100%" }}>
                  <div>
                    <CustomPagination
                      handlePageChange={handlePageChange}
                      currentPage={currentPage}
                      totalItem={sequenceList.count}
                      totalPage={Math.ceil(sequenceList.count / 50)}
                      setIsNewPageLoading={setIsNewPageLoading}
                    />
                  </div>
                </footer>
              ) : (
                ""
              )}
            </>
          ) : (
            <NoData />
          )}
        </>
      ) : (
        <Loader />
      )}
      <div>
        <Modal className={classes.sequencePageModals} open={isNewPageLoading}>
          <NewDataLoadingLoader trackable="Sequences" />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.sequencePageModals}
          open={isNewSequenceModalOpen}
          onClose={() => setNewSequenceModalOpen(false)}
        >
          <SequencePageNewSequenceModal
            dragScreen={dragScreen}
            handleDragScreen={handleDragScreen}
            setDragFileEnterModal={setDragFileEnterModal}
            projectId={props.params.projectId}
            status={statusList}
            handleNewSequenceModal={handleNewSequenceModalState}
            updateRefreshCounter={updateRefreshCounter}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.sequencePageModals}
          open={isViewModalOpen}
          onClose={() => {
            setIsViewModalOpen(false);
          }}
        >
          {file ? (
            checkImage(file) ? (
              <img
                src={file}
                className="card-img-top"
                alt="sequence"
                style={{ width: "500px", height: "auto" }}
              />
            ) : (
              <video
                src={file}
                controls
                autoPlay
                style={{ width: "auto", height: "500px" }}
              />
            )
          ) : (
            <span>No file found</span>
          )}
        </Modal>
      </div>
    </div>
  );
}
