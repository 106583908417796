import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    boxShadow: "none",
    fontFamily: "Poppins",

  },
  cardContentDiv1: {
    display: "flex",
    marginTop:"5%",
    justifyContent: "space-between",

  },
  label: {
    color: "grey",
  },

  input: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    padding: "2%",
  },
  childContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: "2%"
  },
  iconContainer: {
    display: "flex",
    alignItems: "start",
    cursor: "pointer",
  },
  cardContentDiv2: {
    display: "flex",
    marginTop: "2%",
  },
  cardContentDiv3: {
    marginTop: "1%",
  },
  cardContentDiv4: {
    marginTop: "2%",
  },
  cardContentDiv5: {
    display: "flex",
    flexDirection: "row",
    direction: "row",
    marginTop: "2%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  buttonContainer: {
    marginTop: "3%",
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.secondary,
    borderRadius: "6px",
    "& hover ": {
      color: theme.palette.text.primary,
    },
  },
  menuConatiner: {
    height: "180px",
    padding: "0%",
  },
  menuItem: {
    backgroundColor: theme.palette.primary.main,
  },
  statusColor: {
    borderRadius: "50%",
    display: "inline-block",
    margin: "0px 14px",
    height: "15px",
    width: "15px",
    border: "2px solid",
    borderColor: theme.palette.borderColor,
  },
  menuPaper: {
    height: "160px",
    width: "310px",
  },
  TaskPageModals: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default useStyles;
