import React, { useEffect, useState } from "react";
import useStyles from "./UsersList.style";
import { Button, Card,  Input } from "@material-ui/core";
import { Check, MagnifyingGlass, X } from "phosphor-react";
import UserAvatar from "../UserAvatar/UserAvatar.component";

export default function UsersList(props) {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUsers, setSelectedUsers] = useState(
    props.assignedUser !== undefined || null ? props.assignedUser : []
  );

  function handleUserSelect(user) {
    if (props.assignedUser[0] !== null) {
      const exist = selectedUsers.some((eachUser) => eachUser.id === user.id);
      if (exist) {
        setSelectedUsers(selectedUsers.filter((each) => each.id !== user.id));
      } else {
        if (props.type === "multiple") {
          setSelectedUsers([...selectedUsers, user]);
        } else {
          setSelectedUsers([user]);
        }
      }
    } else {
      setSelectedUsers([user]);
    }
  }

  useEffect(() => {
    props?.getSelectedUser(selectedUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers]);

  const handleSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <Card className={classes.card}>
      <div className={classes.toolContainer}>
        <div className={classes.searchDiv}>
          <MagnifyingGlass size={25} style={{ marginLeft: "2%" }} />
          <Input
            className={classes.searchInput}
            disableUnderline="false"
            placeholder="Search anything"
            onChange={(e) => handleSearchQuery(e)}
          />
        </div>
        <div>
          <X
            size={27}
            className={classes.icon}
            onClick={() => {
              if (props?.showDoneButton) {
                props.handleResetAssignedUser();
                props.closeModal();
              } else {
                props.closeModal();
              }
            }}
          />
        </div>
      </div>
      <div className={classes.userList}>
        {props.usersList
          ? props.usersList &&
            props.usersList
              ?.filter((search) => {
                if (searchQuery === undefined) {
                  return search;
                } else if (searchQuery === "") {
                  return search;
                } else if (
                  `${search.first_name + " " + search.last_name}`
                    ?.toLowerCase()
                    .includes(searchQuery.toLowerCase())
                ) {
                  return search;
                }
                return null;
              })
              .map((user, index) => (
                <div
                  className={classes.user}
                  key={index}
                  onClick={() => {
                    handleUserSelect(user);
                  }}
                >
                  <div className={classes.displayFlex}>
                    <UserAvatar
                      avatar={user?.avatar}
                      firstName={user.first_name}
                      lastName={user.last_name}
                    />
                    <span style={{ fontSize: "14px" }}>
                      {user.first_name + "  " + user.last_name}
                    </span>
                  </div>
                  <div>
                    {selectedUsers?.map((singleUser) =>
                      singleUser?.id === user?.id ? (
                        <span>
                          <Check
                            size={25}
                            style={{ color: "lime", fontWeight: "600" }}
                          />
                        </span>
                      ) : (
                        ""
                      )
                    )}
                  </div>
                </div>
              ))
          : "no User"}
      </div>
      {props.showDoneButton === true ? (
        <div className={classes.buttonContainer}>
          <Button
            className={classes.button}
            onClick={() => {
              props.handleSubmit();
              setTimeout(() => {
                props.closeModal();
              }, 1000);
            }}
          >
            Done
          </Button>
        </div>
      ) : (
        ""
      )}
    </Card>
  );
}
