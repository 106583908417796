import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
  },
  imageIconColor: {
    color: theme.palette.text.primary,
    fontSize: "21px",
    fontWeight: "bold",
  },
}));

export default useStyles;
