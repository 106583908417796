import { DotsThree } from "phosphor-react";
import React, { useState } from "react";
import _ from "lodash";
import useStyles from "./TaskPageKanbanView.style.js";
import { DragSource, DropTarget } from "react-dnd";
import { Divider, MenuItem, Menu } from "@material-ui/core";
import * as Icon from "react-feather";

export function Col(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [statusToDelete, setStatusToDelete] = useState();

  const handleOpenMenu = (e, status) => {
    setAnchorEl(e.currentTarget);
    setStatusToDelete(status);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return _.flowRight(
    props.connectDragSource,
    props.connectDropTarget
  )(
    <div
      className={classes.column}
      style={{
        border:
          props.dropTarget === props.column.id
            ? `1.5px dashed rgb(${props.column?.color})`
            : `1.5px solid rgb(${props.column?.color})`,
      }}
    >
      <div
        style={{
          display: props.dropTarget === props.column.id ? "none" : "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0px 8px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={classes.columnTitle}>{props.column.name}</div>
          <span
            className={classes.statusColor}
            style={{
              backgroundColor: `rgb(${props.column?.color})`,
            }}
          ></span>
        </div>
        <button
          className={classes.button}
          onClick={(e) => {
            handleOpenMenu(e, props.column);
          }}
          style={{
            display: props.column.id === "noStatus" ? "none" : "block",
          }}
          disabled={props.column.id === "noStatus" ? true : false}
        >
          <DotsThree size={22} />
        </button>
      </div>
      {props.children}
      <div>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          elevation={0}
          MenuListProps={{ className: `${classes.menuPop}` }}
        >
          <MenuItem
            className={classes.modalButton}
            onClick={(e) => {
              props.handleEditStatusModal(e, statusToDelete);
              handleCloseMenu();
            }}
          >
            <Icon.Edit3 className={classes.editAndDeleteIcon} />
            Edit Status
          </MenuItem>
          <Divider />
          <MenuItem
            className={classes.modalButton}
            onClick={(e) => {
              props.handleDeleteStatusModal(e, statusToDelete);
              handleCloseMenu();
            }}
          >
            <Icon.Trash2 className={classes.editAndDeleteIcon} />
            Delete Status
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}
export const Column = _.flowRight([
  DropTarget(
    "Card",
    {
      hover(props, monitor) {
        const draggingItem = monitor.getItem();
        props.hoverOver(draggingItem.id, props.column.id);
      },
      drop(props, monitor) {
        const draggingItem = monitor.getItem();
        props.moveCard(draggingItem.id, props.column.id, 0);
      },
    },
    (connect) => ({
      connectDropTarget: connect.dropTarget(),
    })
  ),
  DragSource(
    "Card",
    {
      beginDrag(props) {
        return { id: props.id };
      },

      isDragging(props, monitor) {
        return props.id === monitor.getItem().id;
      },
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    })
  ),
])(Col);
