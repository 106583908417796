import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  layers: {
    height: "27px",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: "6px",
    paddingLeft: "5px",
    width: "92%",
  },
  layerBtn: {
    height: "25px",
    minWidth: "0px",
    padding: "3px",
    marginLeft: "3px",
    fontSize: "20px",
  },
  newLayers: {
    height: "27px",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "6px",
    paddingLeft: "5px",
    width: "92%",
  },
}));

export default useStyles;
