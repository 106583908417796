import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: "30px",
    width: "30px",
    textAlign: "center",
    borderRadius: "50%",
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.primary,
    fontWeight: "400 !important",
    fontSize: "15px",
  },
  userImage: {
    objectFit: "cover",
    height: "30px",
    width: "30px",
    borderRadius: "50%",
  },
}));

export default useStyles;
