import { Button } from "@material-ui/core";
import { Plus } from "phosphor-react";
import React from "react";
import Loader from "../../../../Utilities/Loader/Loader";
import NoResultFound from "../../../../Utilities/NoResultFound/NoResultFound";
import SinglePhaseCard from "./SinglePhaseCard.component";
import useStyles from "./ManageTaskPhasesComponent.style";

const ManageTaskPhasesComponent = ({
  phases,
  departments,
  handleEditTaskPhaseInfo,
  handleDeleteTaskPhaseInfo,
  setIsNewTaskPhaseModalOpen,
  isLoaded,
  closeManagePhaseModal,
}) => {
  const classes = useStyles();
  return (
    <div style={{ height: "90%" }}>
      <div style={{ height: "87%", overflowY: "auto" }}>
        {isLoaded ? (
          <>
            {phases?.length > 0 ? (
              <div>
                {phases.map((singlePhase) => {
                  return (
                    <SinglePhaseCard
                      key={singlePhase?.id}
                      singlePhase={singlePhase}
                      departments={departments}
                      handleEditTaskPhaseInfo={handleEditTaskPhaseInfo}
                      handleDeleteTaskPhaseInfo={handleDeleteTaskPhaseInfo}
                    />
                  );
                })}
              </div>
            ) : (
              <div className={classes.noResultDiv}>
                <NoResultFound />
              </div>
            )}
          </>
        ) : (
          <Loader />
        )}
        <div
          className={classes.addNewPhaseButton}
          onClick={() => {
            setIsNewTaskPhaseModalOpen(true);
          }}
        >
          <div>
            <Plus /> <span>Add New Task Phase</span>
          </div>
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.doneButton}
          onClick={() => closeManagePhaseModal()}
        >
          <span>Done</span>
        </Button>
      </div>
    </div>
  );
};

export default ManageTaskPhasesComponent;
