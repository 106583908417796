const SequencerIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="2.96652"
        cy="2.82052"
        r="1.24777"
        stroke="#F9F9F9"
        stroke-width="1.25"
      />
      <circle
        cx="10.4575"
        cy="2.82052"
        r="1.24777"
        stroke="#F9F9F9"
        stroke-width="1.25"
      />
      <circle
        cx="15.2732"
        cy="15.1272"
        r="1.24777"
        stroke="#F9F9F9"
        stroke-width="1.25"
      />
      <circle
        cx="7.78219"
        cy="15.1272"
        r="1.24777"
        stroke="#F9F9F9"
        stroke-width="1.25"
      />
      <circle
        cx="6.71213"
        cy="8.97384"
        r="1.24777"
        stroke="#F9F9F9"
        stroke-width="1.25"
      />
      <path
        d="M8.49362 8.74198C8.34718 8.88842 8.34718 9.12586 8.49362 9.27231L10.8801 11.6588C11.0266 11.8052 11.264 11.8052 11.4104 11.6588C11.5569 11.5123 11.5569 11.2749 11.4104 11.1285L9.28912 9.00714L11.4104 6.88582C11.5569 6.73938 11.5569 6.50194 11.4104 6.35549C11.264 6.20905 11.0266 6.20905 10.8801 6.35549L8.49362 8.74198ZM12.4235 3.19531H13.8039V2.44531H12.4235V3.19531ZM13.8039 3.19531C14.7196 3.19531 15.3844 3.53907 15.8251 4.03587C16.2721 4.53962 16.5065 5.22185 16.5102 5.91572C16.5139 6.60955 16.2868 7.29009 15.8431 7.79214C15.4057 8.28712 14.7379 8.63214 13.8039 8.63214V9.38214C14.9406 9.38214 15.8175 8.95381 16.4052 8.28876C16.9866 7.63078 17.2648 6.76461 17.2602 5.91174C17.2557 5.0589 16.9684 4.19442 16.3862 3.53814C15.7978 2.87491 14.926 2.44531 13.8039 2.44531V3.19531ZM13.8039 8.63214H8.75879V9.38214H13.8039V8.63214Z"
        fill="#F9F9F9"
      />
      <path
        d="M6.1746 14.8623C6.32105 15.0088 6.32105 15.2462 6.1746 15.3927L3.78812 17.7792C3.64167 17.9256 3.40423 17.9256 3.25779 17.7792C3.11134 17.6327 3.11134 17.3953 3.25779 17.2488L5.37911 15.1275L3.25779 13.0062C3.11134 12.8597 3.11134 12.6223 3.25779 12.4759C3.40423 12.3294 3.64167 12.3294 3.78812 12.4759L6.1746 14.8623ZM4.90617 9.34912L4.17044 9.34912L4.17044 8.59912L4.90617 8.59912L4.90617 9.34912ZM4.17044 9.34912C2.44577 9.34912 1.44411 10.6878 1.37845 12.0686C1.3458 12.755 1.54938 13.4229 1.99209 13.9153C2.4295 14.4019 3.13206 14.7525 4.17044 14.7525L4.17044 15.5025C2.95146 15.5025 2.03416 15.084 1.43434 14.4167C0.839815 13.7554 0.58878 12.8849 0.629293 12.033C0.709939 10.3371 1.96563 8.59912 4.17044 8.59912L4.17044 9.34912ZM4.17044 14.7525L5.90944 14.7525L5.90944 15.5025L4.17044 15.5025L4.17044 14.7525Z"
        fill="#F9F9F9"
      />
      <path
        d="M8.72287 3.08548C8.86932 2.93903 8.86932 2.70159 8.72287 2.55515L6.33639 0.168662C6.18994 0.0222154 5.9525 0.0222154 5.80606 0.168662C5.65961 0.315109 5.65961 0.552546 5.80606 0.698992L7.92738 2.82031L5.80606 4.94163C5.65961 5.08808 5.65961 5.32552 5.80606 5.47196C5.9525 5.61841 6.18994 5.61841 6.33639 5.47196L8.72287 3.08548ZM4.77905 3.19531H8.45771V2.44531H4.77905V3.19531Z"
        fill="#F9F9F9"
      />
      <path
        d="M13.5051 15.3926C13.6516 15.2462 13.6516 15.0087 13.5051 14.8623L11.1186 12.4758C10.9722 12.3293 10.7347 12.3293 10.5883 12.4758C10.4418 12.6222 10.4418 12.8597 10.5883 13.0061L12.7096 15.1274L10.5883 17.2488C10.4418 17.3952 10.4418 17.6326 10.5883 17.7791C10.7347 17.9255 10.9722 17.9255 11.1186 17.7791L13.5051 15.3926ZM9.59473 15.5024H13.2399V14.7524H9.59473V15.5024Z"
        fill="#F9F9F9"
      />
    </svg>
  );
};

export default SequencerIcon;
