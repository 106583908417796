import React, { useState } from "react";
import { fpatch } from "../../../../API/callsAPI";
import TaskGanttOverview from "../../../Utilities/TaskGantt/TaskGanttOverview/TaskGanttOverview";
import ErrorOrSuccessMessage from "../../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";

export default function TaskPageGanttViewOverview({
  task,
  statusList,
  viewModeUser,
  externalSaveEvent,
  onTaskRefresh,
  absoluteLinked,
  projectUsers,
  projectId,
  departments,
  handleTaskDelete,
  ganttFilterLoad,
  setGanttFilterLoad,
  rearrangeTaskOrder,
  trackableType,
}) {
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  const handleTaskDateChange = async (updatedTask) => {
    if (!ganttFilterLoad) {
      let updatedTasks = {
        url: `trackables/task/`,
        data: updatedTask,
      };
      try {
        const updateTaskRes = await fpatch(updatedTasks);
        if (updateTaskRes?.status) {
          onTaskRefresh();
        }
      } catch (error) {
        console.log(error);
      }
    } else setGanttFilterLoad(false);
  };

  const handleTaskUpdate = async (taskId, updatedTaskForm) => {
    let updatedTask = {
      url: `trackables/task/${taskId}/`,
      data: updatedTaskForm,
    };
    try {
      const taskUpdateRes = await fpatch(updatedTask);
      setSnackValue({
        isOpen: true,
        message: `SuccessFully edited task : ${taskUpdateRes.data.name}`,
        isError: false,
      });
      onTaskRefresh();
    } catch (error) {
      console.log(error);
    }
  };

  const handleTaskNextUpdate = async (taskNextArray, validStatus) => {
    try {
      await fpatch({ url: "trackables/task-order/", data: taskNextArray });
      onTaskRefresh();
      validStatus(true);
    } catch (error) {
      validStatus(false);
      console.log(error);
    }
  };
  return (
    <>
      <ErrorOrSuccessMessage snackValue={snackValue} />
      <TaskGanttOverview
        tasksList={task}
        rearrangeTaskOrder={rearrangeTaskOrder}
        statusList={statusList}
        onTaskDateChange={(updatedTask) => handleTaskDateChange(updatedTask)}
        onTaskUpdate={(taskId, updatedTaskForm) =>
          handleTaskUpdate(taskId, updatedTaskForm)
        }
        projectId={projectId}
        viewModeUser={viewModeUser}
        externalSaveEvent={externalSaveEvent}
        submitNextField={handleTaskNextUpdate}
        projectUsers={projectUsers}
        departments={departments}
        handleDeleteTask={handleTaskDelete}
        onTaskRefresh={onTaskRefresh}
        trackableType={trackableType}
      />
    </>
  );
}
