import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  TextField,
  Select,
  Button,
  MenuItem,
} from "@material-ui/core";
import useStyles from "./TaskGanttEditModal.style";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { useFormik } from "formik";
import * as yup from "yup";
import TaskGanttEditNextOverview from "../TaskGanttEditNext/TaskGanttEditNextOverview";
import UsersViewer from "../../../../UsersViewer/UsersViewer";
import { Cookie } from "phosphor-react";

export default function TaskGanttEditModal(props) {
  const classes = useStyles();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [assignedUser, setAssignedUser] = useState([]);
  const [priority, setPriority] = useState(0);
  const [priorityHover, setPriorityHover] = useState(0);
  const [defaultPriority, setDefaultPriority] = useState(0);

  useEffect(() => {
    if (props.selectedTask.assigned_users.length > 0) {
      setAssignedUser(props.selectedTask?.assigned_users);
    }
  }, [props.selectedTask]);

  useEffect(() => {
    setPriority(0);
    setPriorityHover(0);
    if (props.selectedTask.priority === "Low") {
      setDefaultPriority(1);
    } else if (props.selectedTask.priority === "Medium") {
      setDefaultPriority(2);
    }
    if (props.selectedTask.priority === "High") {
      setDefaultPriority(3);
    }
  }, [props.selectedTask]);

  function getTaskUser(user) {
    setAssignedUser(user);
  }

  function removeTaskUser(user) {
    setAssignedUser(assignedUser.filter((sUser) => sUser.id !== user.id));
  }

  function handleStartDateChange(date) {
    setStartDate(date);
  }

  function handleEndDateChange(date) {
    setEndDate(date);
  }

  //Validators
  const editGanttTaskValidationScheme = yup.object({
    taskName: yup
      .string("String Required")
      .min(1, "Too Short")
      .max(50, "Maximum Character Limit:50")
      .required("This is a required field"),
  });

  useEffect(() => {
    setStartDate(props.selectedTask.start_time);
    setEndDate(props.selectedTask.end_time);
  }, [props.selectedTask]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      taskName: props.selectedTask.name ? props.selectedTask.name : "",
      description: props.selectedTask.description
        ? props.selectedTask.description
        : "",
      status: props.selectedTask.status
        ? props.selectedTask.status.id.toString()
        : "",
      department: props.selectedTask.department
        ? props.selectedTask.department.id.toString()
        : "",
    },
    validationSchema: editGanttTaskValidationScheme,
    onSubmit: (values) => {
      let allId = [];
      assignedUser?.map((eachUser) => allId.push(eachUser.id));
      const updatedData = {
        name: values.taskName,
        description: values.description,
        start_time: new Date(startDate).toISOString(),
        end_time: new Date(endDate).toISOString(),
        status: values.status,
        department: values.department,
        assigned_users: allId,
        priority: `${
          (priority === 1 && "Low") ||
          (priority === 2 && "Medium") ||
          (priority === 3 && "High") ||
          props.selectedTask?.priority ||
          "Medium"
        }`,
      };
      props.onSubmit(updatedData);
    },
  });
  return (
    <>
      <Card className={classes.card}>
        <CardContent style={{ padding: "12px" }}>
          <form enctype="multipart/form-data" onSubmit={formik.handleSubmit}>
            <div className={classes.cardContentDiv1}>
              <div>
                <label className={classes.label}>Task Name</label>
                <div className={classes.childContainer}>
                  <TextField
                    fullWidth
                    size="small"
                    type="text"
                    value={formik.values.taskName}
                    onChange={formik.handleChange}
                    className={classes.input}
                    placeholder="Enter Task Name Here"
                    InputProps={{
                      disableUnderline: true,
                      style: { width: "310px", fontSize: "14px" },
                    }}
                    name="taskName"
                  />
                </div>
              </div>
            </div>
            <div className={classes.cardContentDiv2}>
              <div>
                <label className={classes.label}>From</label>
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={MomentUtils}
                >
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk
                    invalidDateMessage={"inValid"}
                    variant="inline"
                    format="yyyy/MM/DD"
                    style={{ marginTop: "2.7%" }}
                    value={startDate}
                    onChange={(date) => handleStartDateChange(new Date(date))}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        width: "137px",
                        fontSize: "14px",
                        padding: "2%",
                      },
                    }}
                    className={classes.input}
                    id="Startdate-picker"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                      style: {
                        padding: "2%",
                      },
                    }}
                    size="small"
                    fullWidth={false}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div>
                <label className={classes.label}>To</label>
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={MomentUtils}
                >
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk
                    invalidDateMessage={"inValid"}
                    variant="inline"
                    style={{ marginTop: "2.7%" }}
                    format="yyyy/MM/DD"
                    value={endDate}
                    onChange={(date) => handleEndDateChange(new Date(date))}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        width: "137px",
                        fontSize: "14px",
                        padding: "2%",
                      },
                    }}
                    className={classes.input}
                    id="Startdate-picker"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                      style: {
                        padding: "2%",
                      },
                    }}
                    size="small"
                    fullWidth={false}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
            {/* <div className={classes.cardContentDiv3}>
              <label className={classes.label}>Tags</label>
              <TaskGanttEditTags />
            </div> */}
            <div className={classes.cardContentDiv4}>
              <label className={classes.label}>Users</label>
              <div style={{ margin: "10px 0px" }}>
                <UsersViewer
                  removeTaskUser={removeTaskUser}
                  getTaskUser={getTaskUser}
                  selectedTask={props.selectedTask}
                  usersList={props.projectUsers}
                  assignedUser={assignedUser}
                  type={"multiple"}
                />
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <div className={classes.cardContentDiv5}>
                <div className={classes.row}>
                  <label
                    className={classes.label}
                    style={{ marginRight: "10px" }}
                  >
                    Status
                  </label>
                  <Select
                    disableUnderline
                    style={{ width: "150px" }}
                    labelId="demo-simple-select-outlined-label"
                    className={classes.input}
                    id="selectStatus"
                    name="status"
                    value={formik.values.status}
                    onChange={formik.handleChange}
                    label="Select Status"
                    // MenuProps={{ classes: { paper: classes.menuPaper } }}
                  >
                    {props.statusList &&
                      props.statusList.map((status) => (
                        <MenuItem
                          className={classes.menuItem}
                          value={status.id}
                          key={status.id}
                        >
                          <span
                            className={classes.statusColor}
                            style={{
                              backgroundColor: `rgb(${status?.color})`,
                            }}
                          ></span>
                          {status.name} - {status.code} {status.id}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
              </div>
              <div className={classes.cardContentDiv5}>
                <div
                  className={classes.row}
                  style={{ display: "flex", alignItems: "stretch" }}
                >
                  <label
                    className={classes.label}
                    style={{ marginRight: "10px" }}
                  >
                    Priority{" "}
                  </label>
                  <div>
                    {[...Array(3)].map((star, index) => {
                      const selectedPriority = index + 1;
                      return (
                        <label>
                          <input
                            type="radio"
                            style={{ display: "none" }}
                            name="priority"
                            value={selectedPriority}
                            onClick={() => setPriority(selectedPriority)}
                          />
                          <Cookie
                            size={20}
                            style={{ marginRight: "3px", cursor: "pointer" }}
                            color={
                              selectedPriority <=
                              (priorityHover || priority || defaultPriority)
                                ? "#FC5959"
                                : "#e4e5e9"
                            }
                            onMouseEnter={() =>
                              setPriorityHover(selectedPriority)
                            }
                            onMouseLeave={() => setPriorityHover(0)}
                          />
                        </label>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.cardContentDiv5}>
              <div className={classes.row}>
                <label
                  className={classes.label}
                  style={{ marginRight: "10px" }}
                >
                  Department{" "}
                </label>
                <Select
                  disableUnderline
                  style={{ width: "250px" }}
                  labelId="demo-simple-select-outlined-label"
                  className={classes.input}
                  id="selectDepartment"
                  name="department"
                  value={formik.values.department}
                  onChange={formik.handleChange}
                  label="Select Department"
                  // MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {props.departments &&
                    props.departments.map((dept) => (
                      <MenuItem
                        className={classes.menuItem}
                        value={dept.id}
                        key={dept.id}
                      >
                        {dept.name} - {dept.id}
                      </MenuItem>
                    ))}
                </Select>
              </div>
            </div>
            <div
              style={{
                minWidth: "145px",
                maxWidth: "240px",
                display: "flex",
                marginTop: "12px",
                alignItems: "center",
              }}
            >
              <span className={classes.label} style={{ marginRight: "8px" }}>
                Next
              </span>
              <TaskGanttEditNextOverview
                submitNextField={props.submitNextField}
                tasksList={props.tasksList}
                selectedTask={props.selectedTask}
                selectProps={{ disabled: true }}
              />
            </div>

            <div style={{ marginTop: "3%" }}>
              <label className={classes.label} style={{ marginRight: "10px" }}>
                Description{" "}
              </label>
              <TextField
                style={{ height: "80px", marginTop: "2%" }}
                multiline
                fullWidth
                type="text"
                size="small"
                value={formik.values.description}
                onChange={formik.handleChange}
                className={classes.input}
                placeholder="Write a Description"
                InputProps={{
                  disableUnderline: true,
                  style: { fontSize: "14px" },
                }}
                name="description"
              />
            </div>
            <div className={classes.buttonContainer}>
              <Button className={classes.button} type="submit" value="Submit">
                Done
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </>
  );
}
