import React from 'react'
import {CheckSquareOffset,
        Cube,
        FilmStrip,
        FilmSlate
        } from "phosphor-react"
        
export const TrackablesAndIcons = [
  {
    id: 10,
    icon: <CheckSquareOffset size={18} />,
    trackable: "Tasks",
  },
  {
    id: 12,
    icon: <Cube size={18} />,
    trackable: "Asset",
  },
  {
    id: 13,
    icon: <FilmStrip size={18} />,
    trackable: "Sequence",
  },
  {
    id: 14,
    icon: <FilmSlate size={18} />,
    trackable: "Shot",
  },
  {
    id: 18,
    icon: <Cube size={18} />,
    trackable: "Asset Version",
  },
  {
    id: 17,
    icon: <FilmSlate size={18} />,
    trackable: "Shot Version",
  },
];
