import { Button } from "@material-ui/core";
import React from "react";
import useStyles from "./TemplateDetails.style";

const TemplateDetails = ({
  templateDetails,
  handleDeleteTaskTemplateInfo,
  handleEditTaskTemplateModalInfo,
}) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.title}>{templateDetails?.name}</div>
      <div className={classes.description}>{templateDetails.description}</div>
      <div className={classes.phaseAndDuration}>
        <div className={classes.textColor}>
          Total Phases - {templateDetails?.total_phases}
        </div>
        <div className={classes.textColor}>
          Total Days - {templateDetails?.total_days}
        </div>
      </div>
      <div className={classes.buttons}>
        <Button
          className={classes.saveButton}
          onClick={() => handleEditTaskTemplateModalInfo(templateDetails)}
        >
          <span>Edit Template</span>
        </Button>
        <Button
          className={classes.discardButton}
          onClick={() => handleDeleteTaskTemplateInfo(templateDetails)}
        >
          <span>Delete Template</span>
        </Button>
      </div>
    </div>
  );
};

export default TemplateDetails;
