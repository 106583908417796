import { Card, CardMedia, CardContent } from "@material-ui/core";
import UserAvatar from "../../Utilities/UserAvatar/UserAvatar.component";
import useStyles from "./PlayerCard.style";

export default function PlayerCard({
  annotationDetails,
  scrollToComment,
  annotationValue,
}) {
  const classes = useStyles();

  return (
    <div
      style={{
        position: "relative",
        width: "233px",
        height: "180px",
        left: `${annotationValue - 5.9}%`,
        cursor: "pointer",
      }}
      onClick={() => scrollToComment(annotationDetails.id)}
    >
      <Card className={classes.cardContainer}>
        <CardMedia
          component="img"
          alt="annotation screenshot"
          src={annotationDetails.attachment}
          className={classes.cardMedia}
          height="123"
        />
        <CardContent className={classes.cardContent}>
          <div className={classes.cardContentUser}>
            <UserAvatar
              avatar={annotationDetails?.author?.avatar}
              firstName={annotationDetails?.author?.first_name[0]}
              lastName={annotationDetails?.author?.last_name[0]}
            />
            <p className={classes.userDetail}>
              {annotationDetails.author.first_name}{" "}
              {annotationDetails.author.last_name}
            </p>
          </div>
          <div className={classes.annotationDate}>
            {new Intl.DateTimeFormat("en-US", {
              day: "2-digit",
              month: "long",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            }).format(new Date(annotationDetails.created_at))}
          </div>
        </CardContent>
      </Card>
      <div className={classes.pointer}></div>
    </div>
  );
}
