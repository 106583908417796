import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import Loader from "../../Utilities/Loader/Loader.jsx";
import NoResultFound from "../../Utilities/NoResultFound/NoResultFound.jsx";
import useStyles from "./ReportedIssues.style.js";

const ReportedIssues = ({
  reportsList,
  handleViewAllReportsModalState,
  isLoaded,
}) => {
  const classes = useStyles();
  return (
    <div>
      <div>
        <div className={classes.flexBetween}>
          <h2>Reported Issues</h2>
          <Button
            onClick={() => handleViewAllReportsModalState()}
            disabled={reportsList?.length > 0 ? false : true}
          >
            View All
          </Button>
        </div>
        {isLoaded ? (
          <>
            {reportsList?.length > 0 ? (
              <TableContainer className={classes.tableContainer}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead className={classes.tableHeader}>
                    <TableRow className={classes.tableRow}>
                      <TableCell className={classes.headTable} align="left">
                        Issue Name
                      </TableCell>
                      <TableCell className={classes.headTable} align="right">
                        Submitted on
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBody}>
                    {reportsList?.slice(0, 5)?.map((report) => (
                      <TableRow key={report.name} className={classes.bodyRow}>
                        <TableCell className={classes.issueName}>
                          {report?.subject}
                        </TableCell>
                        <TableCell className={classes.issueDate} align="right">
                          <span>
                            {new Intl.DateTimeFormat("en-US", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            }).format(new Date(report?.created_at))}
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                    ...
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <NoResultFound />
            )}
          </>
        ) : (
          <div style={{ height: "200px" }}>
            <Loader />
          </div>
        )}
      </div>
      <div style={{ marginTop: "80px" }}>
        <p>
          We at <span className={classes.colorChange}>MovieColab</span>
          {"  "}
          are constantly working to make your work flow as smooth as possible.{" "}
          <br />
          <br />
          <span className={classes.colorChange}>Thank you</span> for reporting
          the issues, You can find your issues being resolved in the section
          above. :)
        </p>
      </div>
    </div>
  );
};

export default ReportedIssues;
