import React, { useState } from "react";

import { Button, Modal, TableCell, TableRow, Tooltip } from "@material-ui/core";
import useStyles from "./ShotsList.styles";
import * as Icon from "react-feather";

import NoThumbnail from "../../Utilities/NoThumbnail.component";
import ImageViewer from "./../../Utilities/ImageViewer/ImageViewer.component";
import StringTruncate from "../../Utilities/StringTruncate/StringTruncate";

import { useHistory } from "react-router";
import DeleteModal from "../../Utilities/DeleteModal/DeleteModal.component";
import ShotsPageEditShotModal from "../ShotsPageModals/ShotsPageEditShotModal";
import { ArrowSquareIn } from "phosphor-react";
import StatusDropDown from "../../Utilities/StatusDropDown/StatusDropDown.component";
import { fdelete } from "../../../API/callsAPI";
import DateAndTimeViewer from "../../Utilities/DateAndTimeViewer/DateAndTimeViewer.component";

export default function ShotsList(props) {
  const classes = useStyles();
  const history = useHistory();
  const [isDeleteShotModalOpen, setDeleteShotModalOpen] = useState(false);
  const [isEditShotModalOpen, setEditShotModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState({});

  function handledDeleteShotModalOpenState(e) {
    e?.stopPropagation();
    setDeleteShotModalOpen(true);
  }

  function handleEditShotModalState(e) {
    e?.stopPropagation();
    setEditShotModalOpen(!isEditShotModalOpen);
  }

  const handleDeleteShot = async () => {
    try {
      await fdelete({
        url: `trackables/shot/${deleteData?.id}/`,
      });
      props.updateRefreshCounter();
      setDeleteShotModalOpen(false);
      setEditShotModalOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <TableRow
        hover
        onClick={(event) => {
          if (event.ctrlKey) {
            window.open(
              `/${props.projectId}/sequence/${props.sequenceId}/shots/${props.data.id}/shotversions`
            );
            sessionStorage.setItem("scrollpos", window.scrollY);
          } else {
            history.push(
              `/${props.projectId}/sequence/${props.sequenceId}/shots/${props.data.id}/shotversions`
            );
            sessionStorage.setItem("scrollpos", window.scrollY);
          }
        }}
        style={{
          cursor: "pointer",
          display: "table",
          width: "100%",
          tableLayout: "fixed",
        }}
      >
        <TableCell
          component="th"
          scope="row"
          className={classes.shotListTableCellTwo}
          align="left"
        >
          <div style={{ display: "flex" }}>
            {props.data.thumbnail ? (
              <ImageViewer
                sliderState={props.shotPageToggler ? 25 : 0}
                url={props.data.thumbnail}
              />
            ) : (
              <NoThumbnail
                sliderState={props.shotPageToggler ? 25 : 0}
                className={classes.ShotsImage}
              />
            )}
            <p style={{ marginLeft: "10px" }}>
              {props.data.code ? props.data.code : "No Name"}
            </p>
          </div>
        </TableCell>
        <TableCell align="center" className={classes.shotListTableCell}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DateAndTimeViewer dateAndTime={props?.data?.updated_at} />
          </div>
        </TableCell>
        <TableCell align="center" className={classes.shotListTableCell}>
          {props.data.description ? (
            <>
              <Tooltip title={props.data.description}>
                <p>{StringTruncate(props.data.description, 50)}</p>
              </Tooltip>
            </>
          ) : (
            <p style={{ color: "white" }}>No Description</p>
          )}
        </TableCell>
        <TableCell
          align="center"
          style={{ color: "green" }}
          className={classes.shotListTableCell}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "15px",
            }}
          >
            <StatusDropDown
              url={`trackables/shot/${props.data.id}/`}
              updateRefreshCounter={props.updateRefreshCounter}
              statusList={props.status}
              currStatus={props.data.status}
            />
          </div>
        </TableCell>
        <TableCell
          style={{ padding: "1px" }}
          className={classes.shotListTableCellTwo}
          align="right"
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                props
                  .fetchFile(props.data.file ? props.data.id : null)
                  // opening the modal after the function has fetched file, else previous file loads first
                  .then(() => props.setIsViewModalOpen(true));
              }}
            >
              View
            </Button>
            <Button
              style={{ minWidth: "25px", padding: "2px" }}
              onClick={(event) => {
                event.stopPropagation();
                history.push(
                  `/${props.projectId}/sequence/${props.sequenceId}/shots/${props.data.id}/task`
                );
              }}
            >
              <ArrowSquareIn height={25} width={25} />
            </Button>
            <Button
              onClick={(e) => {
                setDeleteData(props?.data);
                handleEditShotModalState(e);
              }}
            >
              <Icon.Edit3 style={{ height: "20px", width: "20px" }} />
            </Button>
          </div>
        </TableCell>
      </TableRow>
      <div>
        <Modal
          className={classes.shotListPageModals}
          open={isEditShotModalOpen}
          onClose={() => setEditShotModalOpen(false)}
        >
          <ShotsPageEditShotModal
            parentShot={props.data}
            shotId={props.data.id}
            dragScreen={props.dragScreen}
            handleDragScreen={props.handleDragScreen}
            setDragFileEnterModal={props.setDragFileEnterModal}
            departments={props.departmentsList}
            projectId={props.projectId}
            sequenceId={props.sequenceId}
            status={props.status}
            handleEditShotModal={handleEditShotModalState}
            handledDeleteShotModalOpenState={handledDeleteShotModalOpenState}
            updateRefreshCounter={props.updateRefreshCounter}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.shotListPageModals}
          open={isDeleteShotModalOpen}
          onClose={() => setDeleteShotModalOpen(false)}
        >
          <DeleteModal
            type="Shot"
            name={deleteData?.name || deleteData?.code}
            handleCloseDeleteModal={() => {
              setDeleteShotModalOpen(false);
            }}
            handleDelete={handleDeleteShot}
          />
        </Modal>
      </div>
    </>
  );
}
