import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  sortButton: {
    textTransform: "none",
    height: "2.5rem",
    width: "100%",
    color: theme.palette.text.primary,
    margin: "0px 2px",
    "& :first-child": {
      color: theme.palette.text.primary,
      margin: "8px 1px",
    },
    "& :last-child": {
      margin: "8px",
      fontSize: "1.2rem",
    },
  },
  popoverArrow: {
    position: "absolute",
    borderRadius: "5px",
    top: "-23px",
    borderLeft: "25px solid transparent",
    borderRight: "25px solid transparent",
    borderBottom: `25px solid ${theme.palette.primary.main}`,
    width: "0",
    height: "0",
    zIndex: "100",
  },
  popoverArrowTriangle: {
    borderRadius: "3px",
    borderLeft: "25px solid transparent",
    borderRight: "25px solid transparent",
    borderBottom: `25px solid ${theme.palette.secondary.main}`,
    width: "0",
    height: "0",
    zIndex: "100",
  },
  sortActive: {
    color: theme.palette.secondary.main,
  },
  sortInactive: {
    color: theme.palette.text.secondary,
  },
  dotBlur: {
    height: "7px",
    width: "7px",
    borderRadius: "50%",
    backgroundColor: "#6C65D9",
    marginLeft: "50%",
    filter: "blur(4px)",
  },
  dot: {
    height: "5px",
    width: "5px",
    borderRadius: "50%",
    backgroundColor: "#6C65D9",
    marginLeft: "50%",
    marginTop: "-5px",
  },
  filterBtn: {
    textTransform: "none",
    height: "40px",
    width: "126px",
    color: theme.palette.text.primary,
    margin: "0px 2px",
    borderRadius: "10px",
    "& :first-child": {
      color: theme.palette.text.primary,
      margin: "8px 1px",
    },
    "& :last-child": {
      margin: "8px",
      fontSize: "16px",
    },
  },
}));

export default useStyles;
