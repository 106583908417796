import React, { useState, useEffect } from "react";
//UI
import { Filter } from "react-feather";
import { Users, Wrench } from "phosphor-react";
import { Divider, Button, Modal } from "@material-ui/core";
import useStyles from "./NotificationTools.styles";
import { TrackableData } from "../../Utilities/Trackables/trackablesData";
import AllNotification from "../../Utilities/Svg/AllNotification.svg";
//filter files
import NotificationProjectFilter from "../NotificationFilters/NotificationProjectFilter";
import NotificationDurationFilter from "../NotificationFilters/NotificationDurationFilter";
import NotificationAdvanceFilter from "../NotificationFilters/NotificationAdvanceFilter";

function NotificationsTools({
  filterNotification,
  selectedTrackable,
  projects,
  handleProject,
  selectedProject,
  toSettingsPage,
  period,
  handlePeriod,
  handleAdvanceFilter,
  clearAdvanceFilter,
  handleSubFilter,
  selectedSubFilter,
  subFilterData,
}) {
  const classes = useStyles();
  const [isAdvanceFilterModal, setAdvanceFilterModal] = useState(false); //to open & close advance filter modal

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: "85px",
        marginRight: "10px",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "17%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <NotificationProjectFilter
          projects={projects}
          handleProject={handleProject}
          selectedProject={selectedProject}
        />
        {selectedProject && (
          <Button className={classes.wrenchBtn} onClick={toSettingsPage}>
            <Wrench size={18} />
          </Button>
        )}
        <Divider orientation="vertical" flexItem className={classes.divider} />
      </div>
      <div
        style={{
          height: "100%",
          width: "60%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "90%",
          }}
        >
          <Button
            onClick={() => {
              filterNotification("");
            }}
            className={
              selectedTrackable === ""
                ? classes.selectedBtn
                : classes.notificationBtn
            }
          >
            <img src={AllNotification} className={classes.notificationIcons} />
            <div className={classes.btnText}>All</div>
          </Button>
          <Button
            onClick={() => {
              filterNotification("department");
            }}
            className={
              selectedTrackable === "department"
                ? classes.selectedBtn
                : classes.notificationBtn
            }
          >
            <Users size="20px" className={classes.notificationIcons} />
            <div className={classes.btnText}>Departments</div>
          </Button>
          {TrackableData.map((trackable) => {
            return (
              <Button
                key={trackable.id}
                onClick={() => {
                  filterNotification(trackable.type);
                }}
                className={
                  selectedTrackable === trackable.type
                    ? classes.selectedBtn
                    : classes.notificationBtn
                }
              >
                <div className={classes.notificationIcons}>
                  {trackable.icon}
                </div>
                <div className={classes.btnText}>{trackable.trackable}</div>
              </Button>
            );
          })}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            width: "60%",
            height: "30px",
            marginTop: "5px",
          }}
        >
          {subFilterData?.map((subfilter) => {
            return (
              <Button
                className={
                  selectedSubFilter === subfilter?.id
                    ? classes.selectedBtn
                    : classes.notificationBtn
                }
                onClick={() => handleSubFilter(subfilter?.id)}
              >
                {subfilter.model === "taskcomment" ||
                subfilter.model === "trackablecomment" ? (
                  <span className={classes.btnText}>
                    {subfilter.model_id == 15 ? "Trackable " : "Task "}
                    {subfilter?.event_name
                      .split("_")
                      .map((s) => s[0].toUpperCase() + s.slice(1))
                      .join(" ")}
                  </span>
                ) : (
                  <span className={classes.btnText}>
                    {subfilter?.event_name
                      .split("_")
                      .map((s) => s[0].toUpperCase() + s.slice(1))
                      .join(" ")}
                  </span>
                )}
              </Button>
            );
          })}
        </div>
      </div>
      <div
        style={{
          height: "100%",
          width: "23%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Button onClick={() => setAdvanceFilterModal(true)}>
          <Filter /> <span style={{ fontSize: "14px" }}>Advanced Filters</span>
        </Button>
        <NotificationDurationFilter
          period={period}
          handlePeriod={handlePeriod}
        />
      </div>
      <Modal
        className={classes.advanceFilterModal}
        open={isAdvanceFilterModal}
        onClose={() => setAdvanceFilterModal(false)}
      >
        <NotificationAdvanceFilter
          closeAdvanceFilterModal={() => setAdvanceFilterModal(false)}
          handleAdvanceFilter={handleAdvanceFilter}
          clearAdvanceFilter={clearAdvanceFilter}
        />
      </Modal>
    </div>
  );
}

export default NotificationsTools;
