import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  taskTemplateModals: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  //style for single card
  singleTemplate: {
    height: "180px",
    // width: "370px",
    border: `1px solid ${theme.palette.text.tertiary}`,
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "10px",
    overflow: "hidden",
    backgroundColor: theme.palette.secondary.light,
  },
  flexBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  templateName: {
    fontSize: "18px",
    fontWeight: 500,
    textAlign: "center",
    padding: "0 10px",
  },
  duration: {
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 600,
  },
  details: {
    color: theme.palette.text.tertiary,
    padding: "0px",
  },
}));

export default useStyles;
