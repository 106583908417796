import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "450px",
    "& .MuiFormHelperText-root": {
      color: "#ffcc00",
    },
  },
  card: {
    borderRadius: "10px",
    color: theme.palette.text.tertiary,
  },
  cardContent: {
    backgroundColor: theme.palette.primary.main,
    padding: "20px",
    position: "relative",
  },
  cardContentDiv1: {
    marginTop: "5%",
  },
  cardContentDiv3: {
    backgroundColor: theme.palette.secondary.light,
    padding: "4px",
    margin: '2px 0px',
    borderRadius: "10px",
  },
    cardContentInput1: {
    padding: "0.2px 6px",
  },
    cardAction: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
    doneButton: {
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    width: "100%",
    textTransform: "none",
    padding: "6px",
    borderRadius: "7px",
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.text.primary,
    },
  },
}));
