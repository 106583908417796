import { MenuItem, Select } from "@material-ui/core";
import React from "react";
import useStyles from "./CustomDropdown.style";

const CustomDropdown = ({ value, options, updateValue, showNone }) => {
  const classes = useStyles();
  return (
    <div className={classes.dropdownContainer}>
      <Select
        style={{ width: "100%" }}
        disableUnderline
        className={classes.select}
        value={value}
        onChange={(e) => updateValue(e?.target?.value)}
      >
        {showNone ? <MenuItem value="none">None</MenuItem> : ""}
        {options.map((option) => (
          <MenuItem value={option?.value}>{option?.title}</MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default CustomDropdown;
