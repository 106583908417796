import axios from "axios";
import {
  createCookie,
  deleteCookie,
  getPayload,
} from "../Components/authorization";

//request interceptor to add the auth token header to requests
axios.interceptors.request.use(
  (config) => {
    /*
     **********************************************************
     ***************Use this access token *********************
     **********************************************************
     */
    let accessToken = readCookie("access");
    // let accessToken =
    //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjI1MTIzODg2LCJqdGkiOiI5ZDkwZDNkMjEwYWU0ODYyYTA0NjZmZjNiMjdhMjU0OCIsInVzZXJfaWQiOiIxYjJiZjQ4OS04YmI2LTRkNmMtYTk4My0wY2RhZWY0ZGFkMjkiLCJhdWQiOlsiUlRNU0lOVkVOVCIsIk1PVkNPTEFCIiwiTU9WQ09MQUJWMiJdLCJhZG1pbl9vcmciOjR9.lMldEb8Z9gp9ZAZs2aA2sTnCO0N9WqP85P5Gxx6jCKzFICJtbK53qWXiZ6AFYS9EqsSJzsUmJ9dj8cJO231VeN9RZ1L-UEJhD2HgBkS5qtNXDifrE4_kUrCrlJXBhtMZDGXPpsYu7bM6PHeosk76puI-v6k2mklyHwlKxK_yx4W0uL66hjqD5ka4L6jhzKO2UOKurBTQGSCZ-kfBYbAM_Q7DPsdyeqnZWs7r-Zx1ZHX8Ju6Zl-WTkBDz65ZMhrjHmasm7ry1luXh8rJMQwhBQJW5o26-pMvQ0UJ_ZRHKuNmgrpWuAr_iGeX1qP7j5dX643EQseNKAc7jYy_YokZrb8rJVuHaduOeSciEsoWYSxtnzi7frksDFDa_Z02tnSt5gXqTCSRBSa3bcV7YC5XbUMflsoNrwrz2wIgPgKIS_0XH_88613BmBT3R1uOVCljDGgYPbc-0bzAcBi51SdTeNVmVYDBcDIsEqms_LIbUdGKa2VRLum9FIJR9IPozR4mmYAJ2VCxSe2vmn2IuPSQLymhossf5hScd75CGVQqXfsDyMZrKLs0UuMIBCNSJee0HpdHhcFwU3it06Wn4EdDNStmMCEbM-9aQ5i3_8Lv9bvz491pk_6mL-Fbz4uAUD06E8GhpjyTZTZzG5ukRQNnioNuQ9jV_PNR0wBlohJeQ-GceyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjI1MTIzODg2LCJqdGkiOiI5ZDkwZDNkMjEwYWU0ODYyYTA0NjZmZjNiMjdhMjU0OCIsInVzZXJfaWQiOiIxYjJiZjQ4OS04YmI2LTRkNmMtYTk4My0wY2RhZWY0ZGFkMjkiLCJhdWQiOlsiUlRNU0lOVkVOVCIsIk1PVkNPTEFCIiwiTU9WQ09MQUJWMiJdLCJhZG1pbl9vcmciOjR9.lMldEb8Z9gp9ZAZs2aA2sTnCO0N9WqP85P5Gxx6jCKzFICJtbK53qWXiZ6AFYS9EqsSJzsUmJ9dj8cJO231VeN9RZ1L-UEJhD2HgBkS5qtNXDifrE4_kUrCrlJXBhtMZDGXPpsYu7bM6PHeosk76puI-v6k2mklyHwlKxK_yx4W0uL66hjqD5ka4L6jhzKO2UOKurBTQGSCZ-kfBYbAM_Q7DPsdyeqnZWs7r-Zx1ZHX8Ju6Zl-WTkBDz65ZMhrjHmasm7ry1luXh8rJMQwhBQJW5o26-pMvQ0UJ_ZRHKuNmgrpWuAr_iGeX1qP7j5dX643EQseNKAc7jYy_YokZrb8rJVuHaduOeSciEsoWYSxtnzi7frksDFDa_Z02tnSt5gXqTCSRBSa3bcV7YC5XbUMflsoNrwrz2wIgPgKIS_0XH_88613BmBT3R1uOVCljDGgYPbc-0bzAcBi51SdTeNVmVYDBcDIsEqms_LIbUdGKa2VRLum9FIJR9IPozR4mmYAJ2VCxSe2vmn2IuPSQLymhossf5hScd75CGVQqXfsDyMZrKLs0UuMIBCNSJee0HpdHhcFwU3it06Wn4EdDNStmMCEbM-9aQ5i3_8Lv9bvz491pk_6mL-Fbz4uAUD06E8GhpjyTZTZzG5ukRQNnioNuQ9jV_PNR0wBlohJeQ-Gc";

    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//response interceptor to refresh token on receiving token expired error
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    // console.log("err")

    const originalRequest = error.config;
    let refreshToken = readCookie("refresh");
    console.log(error.response);
    if (
      refreshToken &&
      (error.response.status === 401) &&
      !originalRequest._retry
    ) {
      if (Date.now() >= getPayload(readCookie("refresh")).exp * 1000) {
        deleteCookie("access");
        deleteCookie("refresh");
        window.location = "/";
      }
      originalRequest._retry = true;

      return axios
        .post(`${process.env.REACT_APP_BASE_URL_SSO}api/token/refresh/`, {
          refresh: refreshToken,
        })
        .then((res) => {
          if (res.status === 200) {
            createCookie("access", res.data.access);
            console.log("Access token refreshed!");
            return axios(originalRequest);
          }
        });
    }
    return Promise.reject(error);
  }
);

function readCookie(name) {
  let key = name + "=";
  let cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1, cookie.length);
    }
    if (cookie.indexOf(key) === 0) {
      return cookie.substring(key.length, cookie.length);
    }
  }
  return false;
}

// let token_value = readCookie("access")
//   ? readCookie("access")
//   : "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjIwMjMxNTM2LCJqdGkiOiI2NDBiZWIzZThjN2M0ODJjODQ0N2M4MDdhNDViZmY1YiIsInVzZXJfaWQiOiIxYjJiZjQ4OS04YmI2LTRkNmMtYTk4My0wY2RhZWY0ZGFkMjkiLCJhdWQiOlsiTU9WQ09MQUIiXSwiYWRtaW5fb3JnIjo0fQ.Ui42r2g6nyGDepwyMOdqYkm7Uhec-PiHZ4yBCoiHs0aIFncxx-SZgSiinrKWDFvT5eJ02Y7xJaqwQOG7c3zzPnS_IqLPKFN-m0agJ-seNacpyXio7XqK5_Uq2NWVGTE4n_bOm_9l-5KT2UDIiXgTYhEuBTDcLu_U8GwGRhux6xm7A-hOSxI7BtRra36SdgxYIzT-yZzLTVCWyBy51M592oYf56jlrkUN2CaX5D16xurw0FVanTfrRLMh4ToseQpNhM7BYxMVOkVmRvFx77VqhqGTHRgnrKN5oy1uy6Ep1Jd5VUr-36N2tXoYPMjgOsrv35KJQw3p7PCcqolo0EyHekXsQ8H8Lm9Pbu1W3ciX_f9grhGSyFicoRFZHSyih0pSuQrfswudqPnJldqYDE4vLiVohcpTtJKHAw_y6sVeLxi4aN9uAW_hHXTymJcAGcX9iqZ74pcF_5R8fMBgadSi0O8BzyNB8e5ntRcnhQpHtQBBl2TxZ5Rs0Lzn3sk3CKwL-7-5-qRx5I8fhI6Lk7LqPGoQCjEXaI4TNMWY2b960qKnVvYB2zcnaD_XavoQjsGFE9FJLs_1A1-MaG_OYa7QXaqqFy77mDVZjp54QZmAtFCEjfYka5g2TBe7xJsD44nNWPx5Qwfq_uAsns9YY6a13gytZZ1vNpQlYTSKR5NqWDU";

// export const fgetToken = async ({ url, data }) => {
//   const res = await axios.post(`${url}`, data, {});
//   return res;
// };

export const fget = async ({ url, signal }) => {
  const res = await axios.get(process.env.REACT_APP_BASE_URL + `${url}`, {
    signal,
  });
  return res;
};

export const fget2 = async ({ url }) => {
  // console.log(readCookie("access"))
  let headers = {
    Authorization: `Bearer ${readCookie("access")}`,
    // contentType: "multipart/form-data",
  };
  const res = await axios.get(process.env.REACT_APP_BASE_URL + `${url}`, {
    headers,
  });
  return res;
};

export const fgetNextPage = async ({ url }) => {
  let headers = { Authorization: `Bearer ${readCookie("access")}` };
  const res = await axios.get(url, {
    headers,
  });
  return res;
};

export const fpatch = async ({ url, data }) => {
  // let headers = { Authorization: `Bearer ${token_value}` };
  const res = await axios.patch(
    process.env.REACT_APP_BASE_URL + `${url}`,
    data
  );
  return res;
};

export const fpost = async ({ url, data }) => {
  // let headers = { Authorization: `Bearer ${token_value}` };
  const res = await axios.post(process.env.REACT_APP_BASE_URL + `${url}`, data);
  return res;
};

export const fpost2 = async ({ url, data }) => {
  // let headers = {
  //   Authorization: `Bearer ${token_value}`,
  // };
  const res = await axios.post(`${url}`, data);
  return res;
};

export const fpostBFF = async ({ url, data }) => {
  // let headers = {
  //   Authorization: `Bearer ${token_value}`,
  // };
  const res = await axios.post(
    process.env.REACT_APP_BASE_URL_MCBFF + `${url}`,
    data
  );
  return res;
};

export const fgetBFF = async ({ url, data }) => {
  const res = await axios.get(process.env.REACT_APP_BASE_URL_MCBFF + `${url}`);
  return res;
};

export const fdelete = async ({ url }) => {
  const res = await axios.delete(process.env.REACT_APP_BASE_URL + `${url}`);
  return res;
};

export const fdelete2 = async ({ url, data }) => {
  const res = await axios({
    method: "DELETE",
    url: process.env.REACT_APP_BASE_URL + `${url}`,
    data,
  });
  return res;
};

export const exportFileWithXhr = ({
  url,
  data,
  updateProgressBar,
  transferComplete,
  transferFailed,
  transferCanceled,
}) => {
  return new Promise(function (resolve, reject) {
    var oReq = new XMLHttpRequest();

    oReq.upload.onprogress = updateProgressBar;
    oReq.addEventListener("load", transferComplete);
    oReq.addEventListener("error", transferFailed);
    oReq.addEventListener("abort", transferCanceled);

    oReq.open("PUT", url);
    oReq.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    oReq.send(data);
    oReq.onreadystatechange = function () {
      if (oReq.status === 200) {
        resolve();
      }
    };
  });
};
export const uploadFile = ({ url, data }) => {
  const fetchConfig = {
    method: "PUT",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: data,
  };
  return fetch(url, fetchConfig);
};
