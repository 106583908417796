import React, { useState } from "react";
import { Select, MenuItem, Snackbar } from "@material-ui/core";
import { useStyles } from "./TaskGanttEditNext.styles";
import { Alert } from "@material-ui/lab";

export default function TaskGanttEditNextOverview({
  selectedTask,
  tasksList,
  submitNextField,
  selectProps,
}) {
  const [taskNextArray, setTaskNextArray] = useState(selectedTask.next);
  const [lastSuccessOrder, setLastSuccessOrder] = useState([]);
  const [snackValue, setSnackValue] = React.useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  const [inProg, setInProg] = useState(false);
  const classes = useStyles();

  function handleNextChange(value) {
    setInProg(true);
    let taskToNext = [value];
    // tasksList.forEach((eachTask) => {
    //   if (eachTask.id !== selectedTask.id) {
    //     // prettier-ignore
    //     taskToNext.push({
    //       'id': eachTask.id,
    //       'next': eachTask.next,
    //     });
    //   } else {
    //     // prettier-ignore
    //     taskToNext.push({
    //       'id': selectedTask.id,
    //       'next': value,
    //     });
    //   }
    // });
    submitNextField({ order: taskToNext }, (flag) => {
      if (flag === true) {
        setLastSuccessOrder(taskNextArray);
        setSnackValue({
          isOpen: true,
          message: "Success, Updated Next",
          isError: false,
        });
      } else {
        setTaskNextArray(lastSuccessOrder);
        setSnackValue({
          isOpen: true,
          message: "Next Field Not Valid",
          isError: true,
        });
      }
      setInProg(false);
    });
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackValue.isOpen}
        autoHideDuration={1000}
        onClose={() => setSnackValue({ isOpen: false, message: "" })}
        // message={`${snackValue.message}`}
      >
        <Alert severity={snackValue.isError === false ? "success" : "warning"}>
          {snackValue.message}
        </Alert>
      </Snackbar>
      {selectedTask ? (
        <Select
          disabled={inProg}
          value={taskNextArray}
          onChange={(event) => {
            setTaskNextArray(event.target.value);
            handleNextChange(event.target.value);
          }}
          label="Choose task"
          disableUnderline={true}
          className={classes.nextField}
          autoWidth={true}
          {...selectProps}
        >
          {tasksList.map((eachTask) => {
            if (eachTask.id !== selectedTask.id) {
              return <MenuItem value={eachTask.id}>{eachTask.name}</MenuItem>;
            } else return null;
          })}
        </Select>
      ) : (
        ""
      )}
    </>
  );
}
