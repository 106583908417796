import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    statusColor: {
        borderRadius: "50%",
        display: "inline-block",
        margin: "0px 14px",
        height: "13px",
        width: "13px",
        border: "2px solid",
        borderColor: theme.palette.borderColor,
      },
}))

export default useStyles;