import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "521px",
    height: "auto",
    background: theme.palette.primary.main,
    borderRadius: "20px",
    padding: "24px 24px 24px 36px",
    fontFamily: "Poppins",
  },
  containerHeader: {
    fontSize: "24px",
    margin: "0px",
  },
  containerText: {
    color: theme.palette.text.tertiary,
    fontSize: "14px",
  },
  divider: {
    height: "2px",
    marginTop: "-5px",
  },
  doneBtn: {
    height: "45px",
    width: "163px",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "10px",
  },
  cancelBtn: {
    height: "45px",
    width: "183px",
    borderRadius: "10px",
    border: `2px solid ${theme.palette.secondary.main}`,
  },
  userBtn: {
    minWidth: "0px",
    padding: "5px",
    borderRadius: "50%",
    border: "1px solid #606479",
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  reorderGroup: {
    padding: "0%",
    margin: "0%",
    height: "100%",
    overflowY: "auto",
  },
  itemContainer: {
    padding: "6px",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  iconContainer: {
    width: "33%",
    display: "flex",
    justifyContent: "flex-end",
  },
  icon: {
    color: theme.palette.text.tertiary,
    cursor: "grab",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
}));

export default useStyles;
