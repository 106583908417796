import React from "react";
import AssetsOverview from "../../Components/Assets/AssetsOverview/AssetsOverview.component";
import PropTypes from "prop-types";
function AssetsPage(props) {
  return (
    <div>
      <AssetsOverview
        params={props.match.params}
        searchQuery={props.searchQuery}
      />
    </div>
  );
}

export default AssetsPage;

AssetsPage.propTypes = {
  searchQuery: PropTypes.string,
};
