import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px 10px",
  },
  inputAndSendContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: "5px",
    gap: "10px",
  },
  inputFieldAndFileName: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    padding: "0px 5px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  fileName: {
    borderTop: `1px solid ${theme.palette.text.tertiary}`,
    padding: "10px 5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.palette.text.tertiary,
  },
  fileInputIcon: {
    color: theme.palette.text.tertiary,
    transform: "rotate(90deg)",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.text.secondary,
    },
  },
  commentInput: {
    padding: "5px",
  },
  sendButton: {
    backgroundColor: theme.palette.secondary.main,
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    height: "30px",
    width: "30px",
    marginBottom: "5px",
  },
  text: {
    color: theme.palette.primary.light,
  },
  icon: {
    color: theme.palette.text.primary,
    cursor: "pointer",
    "&:hover ": {
      color: theme.palette.primary.light,
    },
  },
  flexBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid gray",
    padding: "6px",
  },
  replyingToText: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
}));

export default useStyles;
