import { Button } from "@material-ui/core";
import useStyles from "./TaskTemplateTools.style";
import React from "react";
import { SortButton } from "../../../CommonComponents/CommonButtons/CommonButtons.Component";
import { ArrowSquareIn, Plus } from "phosphor-react";

const TaskTemplateTools = ({
  handleNewTaskTemplateModalState,
  onSubmit,
  defaultvalue,
  setIsImportTemplateModalOpen,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <SortButton onSubmit={onSubmit} defaultvalue={defaultvalue} />
      </div>
      <div className={classes.buttons}>
        <Button
          size="small"
          className={classes.addNewTemplateButton}
          disableElevation
          onClick={() => handleNewTaskTemplateModalState()}
        >
          <Plus size={20} />
          <span className={classes.buttonName}>Add New Template</span>
        </Button>
        <Button
          size="small"
          className={classes.importTemplateButton}
          disableElevation
          onClick={() => {
            setIsImportTemplateModalOpen(true);
          }}
        >
          <ArrowSquareIn size={20} />
          <span className={classes.buttonName}> Import Templates</span>
        </Button>
      </div>
    </div>
  );
};

export default TaskTemplateTools;
