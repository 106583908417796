import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    height: "80%",
  },
  card: {
    borderRadius: "10px",
    backgroundColor: theme.palette.primary.main,
    height: "100%",
    padding: "20px",
  },
  cardContent: {
    height: "100%",
    padding: "0px !important",
  },
  detailsAndManageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    gap: "5px",
    height: "100%",
  },
  line: {
    height: "90%",
    width: "2px",
    backgroundColor: theme.palette.text.tertiary,
    display: "block",
  },
  cardTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: "24px",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  titleButtons: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  rearrangeButton: {
    cursor: "pointer",
    color: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
  },

  closeButton: {
    cursor: "pointer",
    color: theme.palette.text.primary,
    display: "flex",
    alignItems: "center",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
