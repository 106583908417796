import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
  },
  viewerComponent: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "30px",
    height: "77%",
    minHeight: "500px",
    minWidth: "900px",
    width: "90%",
    position: "absolute",
    // top: matchHeight ? "10%" : "4%",
    top: "4%",
    left: "5%",
    zIndex: "1205",
    outline: "none",
  },
  leftButtonContainer: {
    position: "absolute",
    color: "green",
    top: "50%",
    left: "-50px",
  },
  rightButtonContainer: {
    position: "absolute",
    color: "green",
    top: "50%",
    right: "-50px",
  },
  leftOrRightButton: {
    padding: "0px",
    height: "100px",
    width: "50px",
    minWidth: "0px",
  },
  toolsContainer: {
    height: "72px",
    width: "100%",
    display: "flex",
    gap: "40px",
    fontSize: "14px",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 30px",
    fontSize: "18px",
  },
  annotationButton: {
    minWidth: "0px",
    paddin: "5px",
  },
  iconStyle: {
    height: "30px",
    width: "30px",
  },
  closeButton: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    cursor: "pointer",
  },
  imageIconColor: {
    color: theme.palette.text.primary,
    fontSize: "21px",
    fontWeight: "bold",
  },
  modelViewerDiv: {
    width: "100%",
    position: "relative",
    height: "98%",
  },
  allowHrdChange: {
    position: "absolute",
    bottom: "0.1%",
    paddingLeft: "20px",
    width: "100%",
    height: "40px",
    boxShadow: "0 -10px 5px 1px rgba(0,0,0,0.45)",
    background: "rgba(0,0,0,0.45)",
    borderRadius: " 0 0 20px 20px",
  },
  notAllowed: {
    display: "none",
    position: "absolute",
    marginTop: "-50px",
    marginLeft: "20px",
  },
  imageDiv: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "10px",
    cursor: "pointer",
  },
  imageStyle: {
    height: "19.14px",
    width: "29.78px",
    marginTop: "2px",
  },
  downloadOption: {
    backgroundColor: "#1B1D28",
    padding: "5px",
    cursor: "pointer",
  },
  player: {
    height: "100%",
    width: "100%",
  },
  noFile: {
    display: "flex",
    justifyContent: "center",
  },
  versionSelector: {
    height: "120px",
    backgroundColor: "",
    minWidth: "900px",
    width: "100%",
  },
  closeFullScreen: {
    position: "absolute",
    top: "10px",
    right: "20px",
    cursor: "pointer",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    height: "30px",
    width: "30px",
  },
}));

export default useStyles;
