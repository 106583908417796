import React from "react";
import DepartmentDetailsOverview from "../../Components/DepartmentDetails/DepartmentDetailsOverview/DepartmentDetailsOverview.component";

export default function DepartmentDetail(props) {
  console.log(props);
  return (
    <div>
      <DepartmentDetailsOverview params={props.match.params} />
    </div>
  );
}
