import React, { useState, useEffect } from "react";
import useStyles from "./ReviewVersionEditReview.styles";
import { TextField, Button, CircularProgress, Modal } from "@material-ui/core";
import { fget, fpatch, uploadFile, fdelete } from "../../../API/callsAPI";
//Validators
import { useFormik } from "formik";
import * as yup from "yup";
//icons
import { X } from "react-feather";
import { Asterisk } from "phosphor-react";
//components
import UploadFile from "../../Utilities/UploadFile/UploadFile";
import DragScreen from "../../Utilities/DragScreen/DragScreen";
import ConfirmDeleteFile from "../../Utilities/ConfirmDeleteFile/ConfirmDeleteFile.component";
import HelperText from "../../Utilities/HelperText/HelperText";

function ReviewVersionEditReview({
  handleEditModal,
  setSnackValue,
  updateRefreshCounter,
  review,
}) {
  const classes = useStyles();
  const [fileUploading, setFileUploading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [file, uploadFileSet] = useState();
  const [deleteFileModal, setDeleteFileModal] = useState(false);
  const [fileTouched, setFileTouched] = useState(false);

  //Validators
  const ReviewValidationScheme = yup.object({
    reviewFileName: yup
      .string("String required")
      .min(1, "Too Short!")
      .max(50, "Maximum Character Limit: 50")
      .required("This is required field"),
  });

  useEffect(() => {
    if (review) {
      const fileNameArray = review?.file?.split("/");
      if (fileNameArray) {
        uploadFileSet(fileNameArray[fileNameArray.length - 1]);
      }
    }
  }, [review]);

  const handleClearUpload = (event) => {
    event?.stopPropagation();
    uploadFileSet(null);
    setFileTouched(false);
    setDeleteFileModal(false);
  };

  const displaySelectedFile = (event) => {
    let file = event.target.files[0];
    event.target.value = null;
    uploadFileSet(file);
    setFileTouched(true);
  };

  const handleDeleteFile = async () => {
    const isFileUpdated = typeof file === "string" ? false : true;
    if (isFileUpdated) {
      handleClearUpload();
    } else {
      try {
        const res = await fdelete({
          url: `reviewversion/${review.id}/file`,
        });
        if (res.status === 200) {
          setSnackValue({
            isOpen: true,
            message: `file deleted successfully`,
            isError: false,
          });

          handleClearUpload();
        }
      } catch (error) {
        setSnackValue({
          isOpen: true,
          message: `Something went wrong in file delete`,
          isError: true,
        });
      }
    }
  };

  const formik = useFormik({
    //add initail values for editing a version
    initialValues: {
      reviewFileName: review.name,
    },
    validationSchema: ReviewValidationScheme,
    onSubmit: async (values) => {
      let formdata = new FormData();
      formdata.append("name", values.reviewFileName);
      const isFileUpdated = typeof file === "string" ? false : true;
      setLoader(true);
      if (fileTouched && isFileUpdated) {
        setFileUploading(true);
      }
      if (isFileUpdated) {
        if (file) {
          formdata.append("file_name", file.name);
        }
      }
      let newReviewFile = {
        url: `review-version/${review.id}/`,
        data: formdata,
      };
      try {
        const newFileRes = await fpatch(newReviewFile);
        if (newFileRes.status === 200 || 201) {
          if (isFileUpdated && file) {
            getSignedUrl(newFileRes.data.id);
          } else {
            setLoader(false);
            setFileUploading(false);
            handleEditModal();
            updateRefreshCounter();
          }
        }
      } catch (error) {
        setLoader(false);
        setFileUploading(false);
      }
    },
  });

  const getSignedUrl = async (newId) => {
    try {
      const uploadUrl = await fget({
        url: `reviewversion/${newId}/upload`,
      });
      if (uploadUrl.status === 200) {
        uploadFile({ url: uploadUrl.data?.signed_url, data: file }).then(() => {
          setLoader(false);
          setFileUploading(false);
          handleEditModal();
          updateRefreshCounter();
        });
      }
    } catch (error) {
      setFileUploading(false);
      setLoader(false);
    }
  };

  const clearAll = () => {};

  const handleDragFile = (e) => {
    if (e.type === "dragenter") {
      //when  file enter- display dragscreen
    }
    //catching the dragged file for uploading
    if (e.type === "drop") {
      uploadFileSet(e.dataTransfer.files[0]);
      setFileTouched(true);
    }
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div
      className={classes.container}
      onDragEnter={(e) => handleDragFile(e)}
      onDrop={(e) => handleDragFile(e)}
      onDragLeave={(e) => handleDragFile(e)}
      onDragOver={(e) => handleDragFile(e)}
    >
      <DragScreen />
      <div className={classes.containerHeader}>
        <p style={{ margin: "0px" }}>Edit Version</p>
        <Button onClick={handleEditModal}>
          <X size={28} />
        </Button>
      </div>
      <form
        style={{ display: "flex", flexDirection: "column", gap: "18px" }}
        onSubmit={formik.handleSubmit}
      >
        <div className={classes.innerContainer1}>
          <span>File Name</span>
          <Asterisk size={12} color="#FF0000" weight="fill" />
          <TextField
            fullWidth
            placeholder="Enter file name"
            name="reviewFileName"
            id="TextField"
            InputProps={{ disableUnderline: true }}
            className={classes.newReviewTextField}
            value={formik.values.reviewFileName}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.reviewFileName)}
          />
          {formik.errors.reviewFileName && formik.touched.reviewFileName ? (
            <HelperText text={formik.errors.reviewFileName} />
          ) : null}
        </div>

        <div className={classes.innerContainer1}>
          Attachment
          <UploadFile
            fileUploading={fileUploading}
            uploadProgress={uploadProgress}
            thumbnail={file}
            handleClearUpload={handleClearUpload}
            handleThumbnailUpload={displaySelectedFile}
            setDeleteFileModal={setDeleteFileModal}
          />
        </div>
        <div className={classes.btnContainer}>
          <Button
            className={classes.newFileBtn}
            style={{ backgroundColor: loader && "#eeeeee" }}
            type="submit"
            value="Submit"
          >
            {loader ? (
              <CircularProgress
                color="secondary"
                style={{ width: "25px", height: "25px" }}
              />
            ) : (
              "Done Editing"
            )}
          </Button>
          <Button className={classes.clearBtn} onClick={clearAll}>
            Clear All
          </Button>
        </div>
      </form>
      <div>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={deleteFileModal}
          onClose={() => setDeleteFileModal(false)}
        >
          <ConfirmDeleteFile
            name={typeof file === "object" ? file?.name : file}
            closeModal={() => {
              setDeleteFileModal(false);
            }}
            deleteFile={() => {
              handleDeleteFile();
            }}
          />
        </Modal>
      </div>
    </div>
  );
}

export default ReviewVersionEditReview;
