import { Grid, Modal } from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import { fget } from "../../../API/callsAPI";
import ReportIssueSvg from "../../Utilities/Svg/ReportIssueSvg";
import ReportedIssues from "../ReportPageComponents/ReportedIssues.component";
import ReportIssueForm from "../ReportPageComponents/ReportIssueForm.component";
import ViewAllReportsModal from "../ReportPageModals/ViewAllReportsModal.component";
import ReportPageTools from "../ReportPageTools/ReportPageTools.component";

const ReportPageOverview = () => {
  const [dragScreen, setDragScreen] = useState(false);
  //state will be true when we start drag file inside modals
  const [dragFileEnterModal, setDragFileEnterModal] = useState(false);
  const [isViewAllReportsModalOpen, setIsViewAllReportsModalOpen] =
    useState(false);
  const [reportsList, setReportsList] = useState([]);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  const updateRefreshCounter = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  const handleDragScreen = (el) => {
    setDragScreen(el);
  };

  const handleViewAllReportsModalState = () => {
    setIsViewAllReportsModalOpen(!isViewAllReportsModalOpen);
  };

  const getAllReports = async () => {
    try {
      const res = await fget({ url: `report/` });
      setReportsList(res?.data?.results);
      setIsLoaded(true);
    } catch (error) {
      console.log(error);
      setIsLoaded(true);
    }
  };
  useEffect(() => {
    getAllReports();
  }, [refreshCounter]);

  return (
    <div>
      <ReportPageTools />
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <ReportIssueSvg />
        </Grid>
        <Grid item xs={5} style={{ padding: "22px" }}>
          <ReportIssueForm
            dragScreen={dragScreen}
            handleDragScreen={handleDragScreen}
            setDragFileEnterModal={setDragFileEnterModal}
            updateRefreshCounter={updateRefreshCounter}
          />
        </Grid>
        <Grid item xs={3}>
          <ReportedIssues
            isLoaded={isLoaded}
            reportsList={reportsList}
            handleViewAllReportsModalState={handleViewAllReportsModalState}
          />
        </Grid>
      </Grid>
      <div>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={isViewAllReportsModalOpen}
          onClose={() => setIsViewAllReportsModalOpen(false)}
        >
          <ViewAllReportsModal
            reportsList={reportsList}
            handleClose={() => setIsViewAllReportsModalOpen(false)}
          />
        </Modal>
      </div>
    </div>
  );
};

export default ReportPageOverview;
