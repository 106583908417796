import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    height: "700px",
  },
  card: {
    borderRadius: "10px",
    backgroundColor: theme.palette.primary.main,
    padding: "20px",
  },
  cardTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: "24px",
    textAlign: "center",
    marginBottom: "30px",
  },
  description: {
    textAlign: "center",
    marginBottom: "30px",
  },

  textColor: {
    color: theme.palette.text.tertiary,
  },

  phaseAndDuration: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "30px",
  },
  buttons: {
    gap: "10px",
    display: "flex",
    alignItems: "center",
    padding: "10px 0px !important",
    marginTop: "10px",
    justifyContent: "space-around",
  },

  saveButton: {
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    width: "180px",
    borderRadius: "10px",
    fontSize: "14px",
    padding: "12px",
    color: theme.palette.text.secondary,
  },
  discardButton: {
    height: "40px",
    width: "180px",
    borderRadius: "10px",
    fontSize: "14px",
    padding: "12px",
    color: theme.palette.text.secondary,
    border: `2px solid ${theme.palette.secondary.main}`,
  },
}));

export default useStyles;
