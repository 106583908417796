import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "670px",
  },
  card: {
    borderRadius: "10px",
    backgroundColor: theme.palette.primary.main,
    padding: "20px 20px 0px 20px",
  },
  cardContent: {
    padding: "0px",
  },
  cardTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: "24px",
    fontWeight: 500,
  },
  closeButton: {
    cursor: "pointer",
    color: theme.palette.text.primary,
    display: "flex",
    alignItems: "center",
  },

  textColor: {
    color: theme.palette.text.primary,
  },

  selectText: {
    textAlign: "center",
    fontSize: "18px",
    fontWeight: 500,
  },

  projectList: {
    height: "400px",
    overflowY: "auto",
    paddingRight: "10px",
  },

  singleProject: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    margin: "5px 0px",
    padding: "5px 10px",
  },

  selectedProject: {
    borderRadius: "10px",
    backgroundColor: "#252A38",
  },

  divider: {
    height: "1px",
    backgroundColor: "#606479",
  },

  cardAction: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10px",
  },

  button: {
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    width: "180px",
    borderRadius: "10px",
    fontSize: "14px",
    padding: "12px",
    color: theme.palette.text.secondary,
  },

  tableContainer: {
    height: "400px",
    marginTop: "5px",
    paddingRight: "10px",
  },

  tableHeader: {
    background: theme.palette.primary.main,
  },

  headTable: {
    color: theme.palette.primary.light,
    padding: "10px 0% !important",
    width: `${91 / 6}%`,
    background: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
  },

  tableCell: {
    padding: "1.3% 0% !important",
  },

  tableCellWithColor: {
    padding: "1.2% 0% !important",
    color: theme.palette.text.tertiary,
  },
}));

export default useStyles;
