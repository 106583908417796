import { ActionTypes } from "../actions/Loader";

const defaultState = {
  isLoading: false,
};

export default function LoaderReducer(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.SET_LOADER:
      return { ...state, isLoading: action.payload };

    default:
      return state;
  }
}
