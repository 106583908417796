export const StatusRearrange = (status) => {
  const order = ["OPEN", "BLCK", "RTS", "WIP", "RVW", "RVSN", "APPR"];
  //array of custom status
  let unorderedStatus = status.filter((item) => !order.includes(item?.code));
  //array of non custom status
  let orderedStatus = status.filter((item) => order.includes(item?.code));

  const sortObj = order.reduce((obj, item, index) => {
    return {
      ...obj,
      [item]: index,
    };
  }, {});

  //function to sort the non custom status array in the order
  const sortedStatus = orderedStatus.sort(
    (a, b) => sortObj[a.code] - sortObj[b.code]
  );
  //merge both sorted and custom status
  let finalOrder = sortedStatus.concat(unorderedStatus);
  return finalOrder;
};
