import React, { useState } from "react";
import useStyles from "./PlaylistCreateNewPlaylist.styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
} from "@material-ui/core";
import { X } from "react-feather";

const PlaylistFilterModal = ({ handleCloseModal }) => {
  const [loading] = useState(false);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <span className={classes.title}>Filter By</span>
            </div>
            <Button style={{ minWidth: 0, minHeight: 0 }}>
              <X className={classes.closeIcon} onClick={handleCloseModal} />
            </Button>
          </div>

          <CardActions className={classes.cardAction}>
            <Button
              type="submit"
              value="Submit"
              size="small"
              disableElevation
              style={{ marginTop: "10px" }}
            >
              {loading ? <CircularProgress size={23} /> : <b>Filter</b>}
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </div>
  );
};

export default PlaylistFilterModal;
