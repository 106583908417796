import useStyles from "./TaskDetailsOnHover.style";
import { Card } from "@material-ui/core";
import PriorityViewer from "../PriorityViewer/PriorityViewer.component";
import StatusViewer from "../StatusViewer/StatusViewer";
import UsersViewer from "../UsersViewer/UsersViewer";

const TaskDetailsOnHover = ({ task }) => {
  const classes = useStyles();

  return (
    <>
      {task?.id ? (
        <div style={{ zIndex: 10 }}>
          <div className={classes.popoverArrow}>
            <div className={classes.innerPop} />
          </div>
          <Card className={classes.card}>
            <div className={classes.taskDetails}>
              <span className={classes.taskName}>{task?.name}</span>
              <div className={classes.dateAndPriority}>
                <div className={classes.date}>
                  <span>
                    {new Intl.DateTimeFormat("en-US", {
                      day: "2-digit",
                      month: "short",
                    }).format(new Date(task?.start_time))}
                  </span>{" "}
                  -{" "}
                  <span>
                    {new Intl.DateTimeFormat("en-US", {
                      day: "2-digit",
                      month: "short",
                    }).format(new Date(task?.end_time))}
                  </span>
                </div>
                <PriorityViewer priority={task?.priority} />
              </div>
              <div className={classes.flexCenter}>
                {task?.status?.id ? (
                  <StatusViewer status={task?.status} />
                ) : (
                  <span>No Status</span>
                )}
              </div>
              <div className={classes.flexCenter}>
                {task?.assigned_users?.length > 0 ? (
                  <UsersViewer
                    assignedUser={task?.assigned_users}
                    selectedTask={task}
                    editable="no"
                  />
                ) : (
                  <span>No assigned user</span>
                )}
              </div>
            </div>
          </Card>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default TaskDetailsOnHover;
