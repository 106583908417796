import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  board: {
    display: "flex",
    width: "90vw",
    overflowX: "auto",
    overflowY: "hidden",
    paddingBottom: "5px",

    "&::-webkit-scrollbar": {
      height: "10px",
    },
  },
  column: {
    marginTop: "10px",
    marginBottom: "5px",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "15px",
    verticalAlign: "top",
    minWidth: "350px",
    maxWidth: "360px",
    padding: "10px 10px 10px 3px",
    height: `97%`,
    // overflowY: "auto",
    marginRight: "15px",
  },
  dropHereImageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  group: {
    height: "95%",
    overflowY: "auto",
    paddingRight: "10px",
  },
  showScrollBar: {
    "&::-webkit-scrollbar": {
      width: "5px",
      cursor: "pointer",
    },
  },
  hideScrollBar: {
    "&::-webkit-scrollbar": {
      width: "5px",
      backgroundColor: "transparent !important",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent !important",
    },
  },
  addNewStatus: {
    border: `2px dashed ${theme.palette.text.tertiary}`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
    marginBottom: "5px",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "15px",
    minWidth: "300px",
    maxWidth: "300px",
    height: "73vh",
    cursor: "pointer",
  },
  kanbanPageModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  linkedClass: {
    color: theme.palette.text.tertiary,
    fontSize: "12px",
  },
  columnTitle: {
    fontSize: "18px",
    fontWeight: "600",
  },
  statusColor: {
    borderRadius: "50%",
    display: "inline-block",
    margin: "0px 5px",
    height: "15px",
    width: "15px",
    border: `1px solid ${theme.palette.border.main}`,
  },
  trackable: {
    borderRadius: "10px",
    border: `2px solid ${theme.palette.text.tertiary}`,
    padding: "0px 5px 10px 5px",
    marginTop: "5px",
  },
  groupInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "5px 0px 5px 5px",
  },
  groupTitle: {
    fontSize: "16px",
    cursor: "pointer",
    "&:hover": {
      color: "gray",
    },
  },
  modalRoot: {
    width: "20%",
    borderRadius: "20px",
    fontFamily: "poppins",
  },

  card: {
    background: theme.palette.primary.main,
    borderRadius: "20px",
  },

  cardContent: {
    backgroundColor: "#393E46",
  },

  headText: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    fontWeight: "600",
    fontSize: "30px",
    margin: "3px 0px",
  },

  text: {
    fontSize: "16px",
    textAlign: "center",
  },

  buttonDiv: {
    display: "flex",
    padding: "20px 0px 0px 0px",
    alignItems: "center",
    justifyContent: "space-evenly",
  },

  yesButton: {
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    width: "120px",
    borderRadius: "20px",
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.text.primary,
    },
  },
  noButton: {
    height: "40px",
    width: "120px",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "20px",
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  floatingDiv: {
    position: "fixed",
    right: "0px",
    width: "450px",
    borderLeft: "2px solid #606479",
    backgroundColor: theme.palette.primary.main,
    overflowX: "hidden",
    overflowY: "scroll",
    zIndex: 100,
  },
  button: {
    padding: "0px",
    width: "30px",
    backgroundColor: "transparent",
    color: theme.palette.text.primary,
    border: "none",
    marginLeft: "5px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  menuIcon: {
    cursor: "pointer",
  },
  menuPop: {
    margin: "0px",
    backgroundColor: theme.palette.secondary.light,
    boxShadow: "none",
  },

  modalButton: {
    width: "160px",
    height: "40px",
    borderColor: theme.palette.secondary.light,
    background: theme.palette.secondary.light,
    textAlign: "center",
    color: theme.palette.text.primary,
  },
  editAndDeleteIcon: {
    color: theme.palette.text.primary,
    width: "30px",
    height: "45px",
    paddingRight: "10px",
  },
}));

export default useStyles;
