import { Cube, FilmSlate } from "phosphor-react";
import React, { useState, useRef, useEffect } from "react";
import TableViewRowItem from "./TableViewRowItem";
import useDraggableScroll from "use-draggable-scroll";
import { ChevronLeft, ChevronRight } from "react-feather";
import { Button } from "@material-ui/core";
import { useLocation } from "react-router";
import { useStyles } from "./TableViewRow.style";
import { parseInt } from "lodash";

export default function TableViewRow({
  tasks,
  trackableName,
  linkedClass,
  handleViewTaskModal,
  openTaskViewModal,
}) {
  const classes = useStyles();
  const ref = useRef(null);
  const [clientWidth, setClientWidth] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [leftScroll, setLeftScroll] = useState(0);
  const location = useLocation();
  const { onMouseDown } = useDraggableScroll(ref);

  const sideScroll = (element, speed, distance, step, direction) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      if (direction === "right") {
        element.scrollLeft += step;
      } else {
        element.scrollLeft -= step;
      }
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  useEffect(() => {
    const handleResize = () => {
      setClientWidth(ref.current?.clientWidth);
      setScrollWidth(ref.current?.scrollWidth);
      setLeftScroll(ref.current?.scrollLeft);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setClientWidth(ref.current?.clientWidth);
  }, [ref.current?.clientWidth]);

  useEffect(() => {
    setScrollWidth(ref.current?.scrollWidth);
  }, [ref.current?.scrollWidth]);

  return (
    <div className={classes.root}>
      <div className={classes.trackableName}>
        <Button
          style={{ padding: 0, minWidth: 0 }}
          onClick={() => {
            if (tasks[0].linked_class === "Asset") {
              location.pathname = `/${tasks[0].linked.project}/assets/${tasks[0].linked.id}/assetversions`;
              window.open(`${location.pathname}`);
            } else {
              location.pathname = `/${tasks[0].linked.project}/sequence/${tasks[0].linked.parent_sequence}/shots/${tasks[0].linked.id}/shotversions`;
              window.open(`${location.pathname}`);
            }
          }}
        >
          {trackableName}
        </Button>
        <br />
        <span className={classes.trackableIcon}>
          {linkedClass === "Asset" ? (
            <Cube height={18} width={18} />
          ) : (
            <FilmSlate height={18} width={18} />
          )}
          {linkedClass}
        </span>
      </div>
      <hr style={{ margin: "0px", marginTop: "-6px", zIndex: 10 }} />
      <div className={classes.rowContainer}>
        <div className={classes.tablerow}>
          {scrollWidth > clientWidth ? (
            ref.current?.scrollLeft > 0 ? (
              <div className={classes.tableRowLeftButton}>
                <ChevronLeft
                  className={classes.ChevronRight}
                  onClick={() => {
                    sideScroll(ref.current, 1, 1200, 10, "left");
                  }}
                />
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          <div
            ref={ref}
            onMouseDown={onMouseDown}
            style={{
              display: "flex",
              overflow: "hidden",
              width: "100%",
            }}
            onScroll={() => setLeftScroll(ref.current?.scrollLeft)}
          >
            {tasks.map((eachTask) => {
              return (
                <TableViewRowItem
                  key={eachTask?.id}
                  task={eachTask}
                  handleViewTaskModal={(selectedtask) =>
                    handleViewTaskModal(selectedtask, tasks)
                  }
                />
              );
            })}
          </div>
          {scrollWidth > clientWidth ? (
            parseInt(scrollWidth - clientWidth) !== parseInt(leftScroll) ? (
              <div
                className={`${
                  openTaskViewModal
                    ? classes.tablerowButtonOpenModal
                    : classes.tablerowButton
                }`}
              >
                <ChevronRight
                  className={classes.ChevronRight}
                  onClick={() => {
                    sideScroll(ref.current, 1, 1200, 10, "right");
                  }}
                />
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
