import React from "react";

const DropHereIcon = ({ color }) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.55556 0H0V5.55556H5.55556V0ZM44.4444 11.1111H50V27.7778H44.4444V16.6667H16.6667V44.4444H27.7778V50H11.1111V11.1111H44.4444ZM11.1111 0H16.6667V5.55556H11.1111V0ZM5.55556 11.1111H0V16.6667H5.55556V11.1111ZM0 22.2222H5.55556V27.7778H0V22.2222ZM5.55556 33.3333H0V38.8889H5.55556V33.3333ZM22.2222 0H27.7778V5.55556H22.2222V0ZM38.8889 0H33.3333V5.55556H38.8889V0ZM33.3333 38.8889V33.3333H50V38.8889H44.4444V44.4444H38.8889V50H33.3333V38.8889ZM44.4444 44.4444V50H50V44.4444H44.4444Z"
        fill={`rgb(${color})`}
      />
    </svg>
  );
};

export default DropHereIcon;
