import React, { useState, useEffect } from "react";
import { FormHelperText, CircularProgress } from "@material-ui/core";
import { Plus } from "react-feather";
import { Asterisk } from "phosphor-react";
import Popper from "@material-ui/core/Popper";
import SelectTrackablesModal from "./SelectTrackablesModal";
import Autocomplete from "@material-ui/lab/Autocomplete";
import HelperText from "../../Utilities/HelperText/HelperText";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";
//Validators
import { useFormik } from "formik";
import * as yup from "yup";
//
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import moment from "moment";
import MomentUtils from "@date-io/moment";

import useStyles from "./NewTask.styles.js";

import { fpost, fget } from "../../../API/callsAPI";
import { useHistory } from "react-router-dom";
import ModalTitleAndButtons from "../../Utilities/ModalTitleAndButtons/ModalTitleAndButtons.component";

const NewTask = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [selectedStartDate, setSelectedStartDate] = React.useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = React.useState(new Date());
  const [selectedTrackable, setSelectedTrackable] = useState([]);
  const [findAsset, setFindAsset] = useState(false);
  const [findSequence, setFindSequence] = useState(false);
  const [selectedSequence, setSelectedSequence] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [userList, setUserList] = useState(props.allUsers);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id1 = open ? "simple-popper" : undefined;

  const onChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };

  const getShot = async () => {
    try {
      const res = await fget({
        url: `trackables/shot/${props.defaultLinked}/`,
      });
      setSelectedTrackable([{ name: res.data.code, id: res.data.id }]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (props.defaultLinked) {
      if (props.trackableType === "asset") {
        const defaultSelectedAsset = props.assetList.find(
          (item) => item.id == props.defaultLinked
        );
        setSelectedTrackable([
          {
            name: defaultSelectedAsset?.name,
            id: defaultSelectedAsset?.id,
          },
        ]);
      } else {
        getShot();
      }
    }
    if (props.allUsers) {
      setUserList(props.allUsers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUsersChange = (data) => {
    if (data) {
      setSelectedUsers(data.map((u) => u.id));
    }
  };

  //Validators
  const NewTaskValidationScheme = yup.object({
    taskName: yup
      .string("String required")
      .min(1, "Too Short!")
      .max(10, "Maximum Character Limit: 50")
      .required("This is a required field"),
    description: yup.string().required("This is a required field"),
  });

  const formik = useFormik({
    initialValues: {
      taskName: props.taskName
        ? props.taskName
            .replace(/<\/?p[^>]*>/g, "")
            .replace(/[<br>""]+/g, "")
            .replace(/[^A-Za-z ""]+/g, "")
        : "",
      description: "",
      department: "",
      linked: props.defaultLinked,
      status: "",
    },
    validationSchema: NewTaskValidationScheme,

    onSubmit: async (values) => {
      if (selectedTrackable[0].name && values.department) {
        setLoading(true);
        const formDataObj = {
          description: values.description,
          project: props.projectId,
          assigned_users: selectedUsers,
          status: values.status,
          linked: parseInt(selectedTrackable[0]?.id), //index will always be 0
          start_time: selectedStartDate.toISOString(),
          end_time: selectedEndDate.toISOString(),
          department: values.department.id,
          color: "decolor",
          name: values.taskName,
        };

        let newTask = {
          url: "trackables/task/",
          data: formDataObj,
        };

        try {
          const newTaskRes = await fpost(newTask);
          if (newTaskRes.status === 200 || 201) {
            props.setSnackValue({
              isOpen: true,
              message: `SuccessFully Created task"${newTaskRes.data.name}"`,
              isError: false,
            });
            props.updateRefreshCounter();
            props.handleNewTaskModal();
            setLoading(false);
          }
        } catch (error) {
          props.setSnackValue({
            isOpen: true,
            message: `${error}`,
            isError: true,
          });
          setLoading(false);
        }
      } else {
        formik.errors.linked = "trackable is a required field";
        formik.errors.department = "This is a required field";
      }
    },
  });

  const handleSelectTrackable = (data) => {
    //logic change to switch for enabling single selection
    setSelectedTrackable([data]);
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <form enctype="multipart/form-data" onSubmit={formik.handleSubmit}>
            <ModalTitleAndButtons
              name="Task"
              type="New"
              closeModal={() => props.handleNewTaskModal()}
            />
            <div className={classes.cardContentDiv1}>
              <div className={classes.textFieldContainer}>
                <span>Task Name</span>
                <Asterisk size={12} color="#FF0000" weight="fill" />
                <div style={{ marginTop: "5px" }}>
                  <TextField
                    type="text"
                    multiline
                    className={classes.cardContentInput1}
                    placeholder="Enter a name"
                    InputProps={{ disableUnderline: true }}
                    value={formik.values.taskName}
                    error={Boolean(formik.errors.taskName)}
                    onChange={formik.handleChange}
                    name="taskName"
                    id="taskName"
                  />
                  {formik.errors.taskName && formik.touched.taskName ? (
                    <HelperText text={formik.errors.taskName} />
                  ) : null}
                </div>
              </div>
              <div className={classes.textFieldContainer}>
                <span>Description</span>
                <Asterisk size={12} color="#FF0000" weight="fill" />
                <div style={{ marginTop: "5px" }}>
                  <TextField
                    type="text"
                    multiline
                    className={classes.cardContentInput1}
                    style={{ overflow: "auto" }}
                    InputProps={{ disableUnderline: true }}
                    placeholder="Enter a description"
                    value={formik.values.description}
                    error={Boolean(formik.errors.description)}
                    onChange={formik.handleChange}
                    name="description"
                    id="description"
                  />
                  {formik.errors.description && formik.touched.description ? (
                    <HelperText text={formik.errors.description} />
                  ) : null}
                </div>
              </div>
              <div className={classes.textFieldContainer}>
                <span>Select Department</span>
                <Asterisk size={12} color="#FF0000" weight="fill" />
                <div
                  className={classes.cardContentDiv2}
                  style={{ marginTop: "5px" }}
                >
                  <Select
                    style={{ width: "100%" }}
                    labelId="demo-simple-select-outlined-label"
                    id="department"
                    disableUnderline
                    className={classes.cardContentInput1}
                    label="Select Task Template"
                    value={formik.values.department}
                    error={Boolean(formik.errors.department)}
                    onChange={formik.handleChange}
                    name="department"
                  >
                    <MenuItem value="">None</MenuItem>
                    {props.departments &&
                      props.departments.map((eachDepartment) => (
                        <MenuItem value={eachDepartment}>
                          {eachDepartment.name} - {eachDepartment.id}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
                {formik.errors.department && formik.touched.department ? (
                  <HelperText text={formik.errors.department} />
                ) : null}
              </div>
              <div className={classes.textFieldContainer}>
                <span>Select Users</span>
                <div
                  className={classes.cardContentDiv2}
                  style={{ marginTop: "5px" }}
                >
                  <Autocomplete
                    multiple
                    name="selectedUsers"
                    id="selectMultipleUser"
                    options={userList ? userList : []}
                    getOptionLabel={(option) =>
                      option
                        ? `${option.first_name}-${option.last_name}`
                        : "No Option"
                    }
                    onChange={(e, value) => handleUsersChange(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="selectedUsers"
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                      />
                    )}
                  />
                </div>
              </div>

              <div className={classes.textFieldContainer}>
                <span>Select Trackable</span>
                <Asterisk size={12} color="#FF0000" weight="fill" />
                <div
                  className={classes.cardContentDiv2}
                  style={{ marginTop: "5px" }}
                >
                  <div
                    aria-describedby={id1}
                    onClick={props.defaultLinked ? "" : handleClick}
                    className={classes.cardContentInput1}
                    style={
                      props.defaultLinked
                        ? { height: "33px", color: "white" }
                        : {
                            height: "33px",
                            color: "white",
                            cursor: "pointer",
                          }
                    }
                  >
                    <div
                      style={{
                        margin: "5px",
                      }}
                    >
                      {selectedTrackable[0]?.name}
                    </div>
                  </div>

                  <Popper
                    id={id1}
                    open={open}
                    anchorEl={anchorEl}
                    style={{ zIndex: 1400 }}
                    // placement="center"
                    // modifiers={{
                    //   offset: {
                    //     enabled: true,
                    //     offset: "0, 10",
                    //   },
                    // }}
                    className={classes.popperDiv}
                  >
                    <SelectTrackablesModal
                      assetList={props.assetList}
                      sequenceList={props.sequenceList}
                      setSelectedTrackable={setSelectedTrackable}
                      setAnchorEl={setAnchorEl}
                      findAsset={findAsset}
                      setFindAsset={setFindAsset}
                      findSequence={findSequence}
                      setFindSequence={setFindSequence}
                      setSelectedSequence={setSelectedSequence}
                      searchQuery={searchQuery}
                      selectedSequence={selectedSequence}
                      onChangeSearchQuery={onChangeSearchQuery}
                      handleSelectTrackable={handleSelectTrackable}
                      selectedTrackable={selectedTrackable}
                    />
                  </Popper>
                </div>
                {formik.errors.linked && formik.touched.linked ? (
                  <HelperText text={formik.errors.linked} />
                ) : null}
              </div>
              <div
                className={classes.textFieldContainer}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <span>Select Status</span>
                  <div
                    className={classes.cardContentDiv2}
                    style={{ width: "150px", marginTop: "5px" }}
                  >
                    <Select
                      style={{ width: "100%" }}
                      labelId="demo-simple-select-outlined-label"
                      id="selectStatus"
                      label="Select Status"
                      disableUnderline
                      className={classes.cardContentInput1}
                      onChange={formik.handleChange}
                      name="status"
                      value={formik.values.status}
                    >
                      <MenuItem
                        className={classes.addNewStatusButton}
                        onClick={() => {
                          history.push(`/${props.projectId}/settings`);
                        }}
                      >
                        <Plus />
                        Add new Status
                      </MenuItem>
                      <MenuItem value="">None</MenuItem>
                      {props.taskStatus &&
                        props.taskStatus.map((eachStatus) => (
                          <MenuItem value={eachStatus.id}>
                            <span
                              style={{
                                height: "15px",
                                width: "15px",
                                backgroundColor: `rgb(${eachStatus?.color})`,
                                borderRadius: "50%",
                                display: "inline-block",
                                margin: "0px 5px",
                              }}
                            ></span>
                            {eachStatus.name} - {eachStatus.code}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                </div>
                <div style={{ maxWidth: "50%" }}>
                  <span>Status Code</span>
                  <div
                    style={{ marginTop: "5px" }}
                    className={classes.cardContentDiv3}
                  >
                    <TextField
                      className={classes.cardContentInput1}
                      InputProps={{
                        disableUnderline: true,
                        style: { opacity: "0.4" },
                      }}
                      disabled
                      fullWidth
                      value={
                        props?.taskStatus?.find(
                          (eachStatus) => eachStatus.id === formik.values.status
                        )?.code
                      }
                    />
                  </div>
                </div>
              </div>

              <div className={classes.datePickers}>
                <div className={classes.datePickerDiv}>
                  <div style={{ marginTop: "4px", marginLeft: "4px" }}>
                    Start Date
                  </div>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      autoOk
                      invalidDateMessage={"inValid"}
                      variant="inline"
                      format="yyyy/MM/DD"
                      margin="normal"
                      name="startDate"
                      value={selectedStartDate}
                      onChange={(date) => handleStartDateChange(new Date(date))}
                      style={{ marginTop: "5px", height: "30px" }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      id="Startdate-picker"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                        style: {
                          padding: "0px",
                        },
                      }}
                      className={classes.cardContentInput1}
                      size="small"
                      fullWidth={true}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div className={classes.datePickerDiv}>
                  <div style={{ marginTop: "4px", marginLeft: "4px" }}>
                    Deadline
                  </div>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      autoOk
                      invalidDateMessage={"inValid"}
                      variant="inline"
                      format="yyyy/MM/DD"
                      margin="normal"
                      value={selectedEndDate}
                      style={{ marginTop: "5px", height: "30px" }}
                      onChange={(date) => handleEndDateChange(new Date(date))}
                      id="Enddate-picker"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                        style: {
                          padding: "0px",
                        },
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      className={classes.cardContentInput1}
                      size="small"
                      fullWidth={true}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
            <br />

            <CardActions className={classes.cardAction}>
              <Button
                fullWidth
                type="submit"
                value="Submit"
                size="small"
                disableElevation
              >
                {loading ? (
                  <CircularProgress size={30} />
                ) : (
                  <b>Create New Task</b>
                )}
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default NewTask;
