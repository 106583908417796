import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "420px",
  },
  card: {
    borderRadius: "10px",
    backgroundColor: theme.palette.primary.main,
    padding: "20px 20px 0px 20px",
  },
  cardContent: {
    padding: "0px",
  },
  cardTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: "24px",
  },
  closeButton: {
    cursor: "pointer",
    color: theme.palette.text.primary,
    display: "flex",
    alignItems: "center",
  },

  textColor: {
    color: theme.palette.text.primary,
  },
  inputTitle: {
    color: theme.palette.text.tertiary,
  },

  cardContentDiv1: {
    backgroundColor: theme.palette.secondary.light,
    padding: "4px",
    borderRadius: "10px",
    marginTop: "10px",
    marginBottom: "8px",
  },
  cardContentDiv2: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    height: "80px",
    width: "100%",
    marginTop: "10px",
  },
  cardContentInput: {
    maxHeight: "100%",
    overflow: "hidden",
    padding: "2px 6px",
  },
  buttons: {
    gap: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px !important",
    marginTop: "10px",
  },

  confirmButton: {
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    width: "180px",
    borderRadius: "10px",
    fontSize: "14px",
    padding: "12px",
    color: theme.palette.text.secondary,
  },
  cancelButton: {
    height: "40px",
    width: "180px",
    borderRadius: "10px",
    fontSize: "14px",
    padding: "12px",
    color: theme.palette.text.secondary,
    border: `2px solid ${theme.palette.secondary.main}`,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
