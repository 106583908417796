import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    height: "700px",
  },

  addNewPhaseButton: {
    height: "70px",
    width: "98%",
    border: `2px dashed ${theme.palette.secondary.main}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    cursor: "pointer",
  },

  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 0px !important",
    marginTop: "10px",
  },

  doneButton: {
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    width: "180px",
    borderRadius: "10px",
    fontSize: "14px",
    padding: "12px",
    color: theme.palette.text.secondary,
  },

  noResultDiv: {
    height: "400px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
