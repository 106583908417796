import { Button, Card, CardContent } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Check, Save, X } from "react-feather";
import { fget } from "../../../../API/callsAPI";
import useStyles from "./SequencerVersionSwitcherModal.style";
import NoThumbnail from "../../../Utilities/no-thumbnail.png";
import Loader from "../../../Utilities/Loader/Loader";

const SequencerVersionSwitcherModal = ({
  closeModal,
  versionSwitcherModalData,
  handleUpdatePlayList,
}) => {
  const classes = useStyles();
  const [shotVersionList, setShotVersionList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newSelectedVersion, setNewSelectedVersion] = useState({});
  const [disableButton, setDisableButton] = useState(true);
  useEffect(() => {
    fetchShotVersions();
  }, []);

  //function to get shot versions of selected shot
  const fetchShotVersions = async () => {
    setIsLoading(true);
    try {
      const res = await fget({
        url: `trackables/shot-version/?page=${1}&shot=${
          versionSwitcherModalData?.selectedShotId
        }`,
      });
      //find and filter to take the selected one in the first position
      const alreadyInTimeline = res.data.results?.find(
        (singleVer) =>
          singleVer.id === versionSwitcherModalData.selectedShotVersionId
      );
      const restVersions = res.data.results?.filter(
        (singleVer) =>
          singleVer.id !== versionSwitcherModalData.selectedShotVersionId
      );
      setShotVersionList([alreadyInTimeline, ...restVersions]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  //handle select new version
  const handleSelectNewVersion = (newVersion) => {
    if (versionSwitcherModalData?.selectedShotVersionId !== newVersion.id) {
      if (newSelectedVersion.id === newVersion.id) {
        setNewSelectedVersion({});
        setDisableButton(true);
      } else {
        setNewSelectedVersion(newVersion);
        setDisableButton(false);
      }
    } else {
      console.log("already selected");
    }
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div className={classes.cardTitleContainer}>
            <div></div>
            <div>
              <span className={classes.title}>Choose Version</span>
            </div>
            <div className={classes.buttons}>
              <Button
                className={classes.closeAndSaveButton}
                onClick={() => closeModal()}
              >
                <X size={25} />
              </Button>
              {shotVersionList?.length > 1 ? (
                <>
                  <Button
                    className={classes.closeAndSaveButton}
                    disabled={disableButton}
                    onClick={() => {
                      handleUpdatePlayList(newSelectedVersion.id);
                    }}
                  >
                    <Save size={25} />
                  </Button>
                  <Button
                    className={classes.checkButton}
                    disabled={disableButton}
                    /* onClick={() => {
                      localStorage.setItem(
                        "shotInPlaylist",
                        newSelectedVersion.id
                      );
                    }} */
                  >
                    <Check size={25} />
                  </Button>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <div style={{ marginBottom: "5px" }}>
            {shotVersionList?.length > 1 ? <span>Latest Versions</span> : ""}
          </div>
          <div>
            {isLoading ? (
              <Loader />
            ) : (
              <div
                className={classes.shotVersionContainer}
                style={{
                  paddingBottom: shotVersionList?.length > 4 ? "10px" : "0px",
                }}
              >
                {shotVersionList?.map((singleVersion, index) => {
                  return (
                    <div
                      className={classes.singleVersionAndSeparator}
                      key={singleVersion?.id}
                    >
                      <SingleVersion
                        singleVersion={singleVersion}
                        handleSelectNewVersion={handleSelectNewVersion}
                        newSelectedVersion={newSelectedVersion}
                        versionSwitcherModalData={versionSwitcherModalData}
                      />
                      <div className={classes.separatorDiv}>
                        {shotVersionList.length > 1 ? (
                          <div
                            className={classes.separator}
                            style={{
                              display:
                                index === shotVersionList.length - 1
                                  ? "none"
                                  : "block",
                            }}
                          ></div>
                        ) : (
                          <h1 className={classes.singleVersionText}>
                            There is only <br /> one version in <br />
                            this shot
                          </h1>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <div style={{ textAlign: "end", marginTop: "5px" }}>
            {shotVersionList?.length > 1 ? <span>Oldest Versions</span> : ""}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default SequencerVersionSwitcherModal;

// other component to display single version
const SingleVersion = ({
  singleVersion,
  handleSelectNewVersion,
  newSelectedVersion,
  versionSwitcherModalData,
}) => {
  const classes = useStyles();
  return (
    <div
      className={classes.singleVersionContainer}
      onClick={() => handleSelectNewVersion(singleVersion)}
      //should try to use class instead of inline css
      style={{
        backgroundColor:
          newSelectedVersion?.id === singleVersion?.id
            ? "#606479"
            : "transparent",
        borderWidth:
          versionSwitcherModalData?.selectedShotVersionId === singleVersion?.id
            ? "2px"
            : "0px",
        cursor:
          versionSwitcherModalData?.selectedShotVersionId === singleVersion?.id
            ? "not-allowed"
            : "pointer",
      }}
    >
      <img
        src={singleVersion?.thumbnail || NoThumbnail}
        className={classes.versionImage}
      />
      <p className={classes.versionName}>{singleVersion?.name}</p>
      <div className={classes.statusAndVersionInfo}>
        <div className={classes.statusDiv}>
          <span>{singleVersion?.status?.name || "None"}</span>
          <span
            className={classes.statusColor}
            style={{
              backgroundColor: `rgb(${singleVersion?.status?.color})`,
            }}
          ></span>
        </div>
        <div
          className={
            newSelectedVersion?.id === singleVersion?.id
              ? classes.selectedVersionDateAndCreator
              : classes.dateAndCreator
          }
        >
          <div>
            {new Date(singleVersion?.updated_at).toDateString() +
              " " +
              new Date(singleVersion?.updated_at).toLocaleTimeString()}
          </div>
          <div>
            Updated by {""}
            {singleVersion?.updated_by ? (
              <>
                {singleVersion?.updated_by?.first_name +
                  " " +
                  singleVersion?.updated_by?.last_name}
              </>
            ) : (
              <>
                {singleVersion?.created_by?.first_name +
                  " " +
                  singleVersion?.created_by?.last_name}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
