import React, { useState } from "react";
import { fdelete2, fpost } from "../../../API/callsAPI";
//@MUI
import useStyles from "./ReviewPageManageReviewModal.styles";
import { Divider, Button, Modal, TextField } from "@material-ui/core";
//components
import UserViewerForManageUsers from "../../Utilities/ManageUsers/UsersViewerForManageUsers/UserViewerForManageUsers.component";
import SelectUserModal from "../../Utilities/ManageUsers/SelectUsersModal/SelectUserModal.component";

function ReviewPageManageReviewModal({
  handleManageReviewModal,
  orgUsers,
  projectId,
  setSnackValue,
  projectReviewer,
  getReviewer,
}) {
  const classes = useStyles();
  const [isSelectUserModalOpen, setSelectUserModalOpen] = useState(false);
  const [reviewerUserList, setReviewerUserList] = useState(projectReviewer);
  const [userMailId, setUserMailId] = useState("");

  const handleSelectUserModalState = () => {
    setSelectUserModalOpen(!isSelectUserModalOpen);
  };

  const handleSelectUser = (users) => {
    setReviewerUserList(users);
  };

  const removeUser = async (usersToRemove) => {
    const formData = {
      users: usersToRemove,
    };
    let reviwerUsers = {
      url: `project/${projectId}/delete-reviewers/`,
      data: formData,
    };

    try {
      const res = await fdelete2(reviwerUsers);
      if (res.status === 200 || 201) {
        setSnackValue({
          isOpen: true,
          message: res.data.detail,
          isError: false,
        });
        getReviewer();
      }
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  const addReviewers = async () => {
    const addedUsers = reviewerUserList.filter(
      (obj2) => !projectReviewer.some((obj1) => obj1.value === obj2.value)
    );
    const removedUsers = projectReviewer.filter(
      (obj1) => !reviewerUserList.some((obj2) => obj2.value === obj1.value)
    );
    if (addedUsers.length !== 0) {
      const users = addedUsers.map((user) => user.email);
      const formData = {
        project: projectId,
        email: users,
      };
      let reviwerUsers = {
        url: "add-reviewer/",
        data: formData,
      };
      try {
        const res = await fpost(reviwerUsers);
        if (res.status === 200 || 201) {
          setSnackValue({
            isOpen: true,
            message: res.data.detail,
            isError: false,
          });
          getReviewer();
        }
      } catch (error) {
        setSnackValue({
          isOpen: true,
          message: `${error}`,
          isError: true,
        });
      }
    }
    if (removedUsers.length !== 0) {
      const users = removedUsers.map((user) => user.value);
      removeUser(users);
    }
    if (userMailId !== "") {
      const formData = {
        project: projectId,
        email: userMailId,
      };
      let reviwerUsers = {
        url: "add-new-reviewer/",
        data: formData,
      };
      try {
        const res = await fpost(reviwerUsers);
        if (res.status === 200 || 201) {
          setSnackValue({
            isOpen: true,
            message: res.data.detail,
            isError: false,
          });
        }
      } catch (error) {
        setSnackValue({
          isOpen: true,
          message: `${error}`,
          isError: true,
        });
      }
    }
    handleManageReviewModal();
  };

  return (
    <div className={classes.container}>
      <p className={classes.containerHeader}>Manage Reviewers</p>
      <div>
        <span className={classes.containerText}>Reviewers</span>
        <Divider variant="inset" className={classes.divider} />
      </div>
      <div
        style={{
          marginTop: "17px",
          marginBottom: "43px",
          display: "flex",
          flexDirection: "column",
          gap: "25px",
        }}
      >
        <UserViewerForManageUsers
          currentUsers={reviewerUserList}
          handleSelectUserModalChange={handleSelectUserModalState}
          handleSelectUsers={handleSelectUser}
        />
        <TextField
          placeholder="User Mail Id"
          fullWidth
          type="email"
          name="userEmail"
          id="userEmail"
          InputProps={{ disableUnderline: true }}
          value={userMailId}
          onChange={(e) => setUserMailId(e.target.value)}
          className={classes.textField}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button className={classes.doneBtn} onClick={addReviewers}>
          Done
        </Button>
        <Button className={classes.cancelBtn} onClick={handleManageReviewModal}>
          Cancel
        </Button>
      </div>
      <div>
        <Modal
          className={classes.modal}
          open={isSelectUserModalOpen}
          onClose={handleSelectUserModalState}
        >
          <SelectUserModal
            closeModal={handleSelectUserModalState}
            assignedUser={reviewerUserList}
            usersList={orgUsers}
            handleSelectUsers={handleSelectUser}
          />
        </Modal>
      </div>
    </div>
  );
}

export default ReviewPageManageReviewModal;
