import React from "react";
import * as Icon from "react-feather";
//@MUI
import { Button, Tooltip } from "@material-ui/core";
import useStyle from "./AssetVersionsTools.styles";
import BreadCrumb from "../../Utilities/BreadCrumb/BreadCrumb.jsx";
import {
  FilterButton,
  SortButton,
  ToggleButton,
} from "../../CommonComponents/CommonButtons/CommonButtons.Component";
import { ArrowSquareIn } from "phosphor-react";
import { useHistory } from "react-router";
import TrackableStats from "../../Utilities/TrackableStats/TrackableStats";
function AssetVersionsTools(props) {
  const classes = useStyle();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <BreadCrumb
          shotCode={props?.parentAsset ? props?.parentAsset.name : ""}
        />
        <TrackableStats
          stats={props.assetVersionStats}
          type={"Asset Versions"}
        />
      </div>
      <div className={classes.divContainerRight}>
        <Tooltip title="See Tasks" placement="top">
          <Button
            style={{ minWidth: "25px", padding: "2px" }}
            onClick={(event) => {
              event.stopPropagation();
              history.push(`/${props.projectId}/assets/${props?.assetID}/task`);
            }}
          >
            <ArrowSquareIn height={25} width={25} />
          </Button>
        </Tooltip>
        <div>
          <ToggleButton handleToggler={props?.handleAssetToggler} />
        </div>
        <div>
          <SortButton
            onSubmit={props?.onSubmit}
            defaultvalue={props?.defaultValue}
          />
        </div>
        <div>
          <FilterButton
            btnActive={props?.filterButtonActive}
            openFilterModal={props?.handleFilterModalState}
          />
        </div>
        <Button
          size="small"
          id="assetVersion"
          className={classes.buttonEditAssetandNewVersion}
          onClick={props?.openNewAssetVerisonModal}
          disableElevation
        >
          <Icon.Plus className={classes.buttonIcon} />
          New Version
        </Button>
      </div>
    </div>
  );
}

export default AssetVersionsTools;
