import { Typography } from "@material-ui/core";
import { Info } from "phosphor-react";
import React from "react";

const NoResultMessage = ({ name, iconSize, fontSize }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <Info size={iconSize} color="#606479" />
      <Typography
        style={{
          color: "#606479",
          fontWeight: 500,
          fontSize: fontSize,
          textAlign: "center",
        }}
      >
        No {name} added yet, please check later!
      </Typography>
    </div>
  );
};

export default NoResultMessage;
