//Library
import React from "react";
import ProjectsPageOverview from "../../Components/ProjectPage/ProjectPageOverview/ProjectPageOverview.component.jsx";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
//Utils
import { fget } from "../../API/callsAPI";

// FIX-ME Destructure the props
export default function ProjectsPage(props) {
  //States
  const [projectItems, setProjectItems] = useState([]);
  const [error, setError] = useState(null);
  const [currentActiveProjectPage, setCurrentActiveProjectPage] = useState(1);
  const [refreshToken, setRefreshToken] = useState(0);
  const [totalActiveProjectCount, setTotalActiveProjectCount] = useState(0);
  const [isNewActiveProjectsLoading, setIsNewActiveProjectsLoading] =
    useState(false);

  const [projectView, setProjectView] = useState("active");
  const [archivedProjectPageNo, setArchivedProjectPageNo] = useState(1);
  const [totalArchivedProject, setTotalArchivedProject] = useState(0);
  const [isNewArchivedPageLoading, setIsNewArchivedPageLoading] =
    useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    //fetchProjectsBff();
    if (projectView === "active") {
      fetchActiveProjects();
    } else {
      getArchivedProj();
    }
  }, [
    refreshToken,
    currentActiveProjectPage,
    projectView,
    archivedProjectPageNo,
  ]);

  const updateRefreshToken = () => {
    setRefreshToken(refreshToken + 1);
  };

  //fetch projects
  const fetchActiveProjects = async () => {
    try {
      const projectsRes = await fget({
        url: `project/?page=${currentActiveProjectPage}`,
      });
      setProjectItems(projectsRes.data.results);
      setTotalActiveProjectCount(projectsRes?.data?.count);
      setIsNewActiveProjectsLoading(false);
      setIsLoaded(true);
    } catch (error) {
      setIsLoaded(true);
      setIsNewActiveProjectsLoading(false);
      setError(error.response ? error.response.data.detail : error.message);
    }
  };

  const getArchivedProj = async () => {
    try {
      const ProjectsRes = await fget({
        url: `project/?archived=true&page=${archivedProjectPageNo}`,
      });
      setProjectItems(ProjectsRes?.data?.results);
      setTotalArchivedProject(ProjectsRes?.data?.count);
      setIsLoaded(true);
      setIsNewArchivedPageLoading(false);
    } catch (error) {
      setIsLoaded(true);
      console.log(error);
    }
  };

  const handleProjectView = (value) => {
    setIsLoaded(false);
    setProjectView(value);
  };

  const handlePageChange = (pageNo) => {
    if (projectView === "active") {
      setCurrentActiveProjectPage(pageNo);
    } else {
      setArchivedProjectPageNo(pageNo);
    }
  };

  return (
    <div>
      <ProjectsPageOverview
        //general props
        projectItems={projectItems}
        searchQuery={props.searchQuery}
        updateRefreshToken={updateRefreshToken}
        projectView={projectView}
        isLoaded={isLoaded}
        handleProjectView={handleProjectView}
        handlePageChange={handlePageChange}
        //props for active projects
        currentActiveProjectPage={currentActiveProjectPage}
        totalActiveProjectCount={totalActiveProjectCount}
        setIsNewActiveProjectsLoading={setIsNewActiveProjectsLoading}
        isNewActiveProjectsLoading={isNewActiveProjectsLoading}
        //props for archived projects
        setIsNewArchivedPageLoading={setIsNewArchivedPageLoading}
        archivedProjectPageNo={archivedProjectPageNo}
        totalArchivedProject={totalArchivedProject}
        isNewArchivedPageLoading={isNewArchivedPageLoading}
        //extra props
        {...props}
      />
      {/* {isActiveProjectsLoaded ? (
        <ProjectsPageOverview
          ProjectItems={projectItems}
          searchQuery={props.searchQuery}
          updateRefreshToken={updateRefreshToken}
          currentActiveProjectPage={currentActiveProjectPage}
          totalActiveProjectCount={totalActiveProjectCount}
          isNewActiveProductsLoading={isNewActiveProjectsLoading}
          setIsNewActiveProductsLoading={setIsNewActiveProjectsLoading}
          setProjectView={setProjectView}
          projectView={projectView}
          setIsNewArchivedPageLoading={setIsNewArchivedPageLoading}
          archivedProjectPageNo={archivedProjectPageNo}
          isArchivedLoaded={isArchivedLoaded}
          totalArchivedProject={totalArchivedProject}
          isNewPageLoading={isNewArchivedPageLoading}
          handlePageChange={handlePageChange}
        />
      ) : (
        <Loader />
      )} */}
    </div>
  );
}

ProjectsPage.propTypes = {
  searchQuery: PropTypes.string,
};
