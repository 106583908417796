import React, { useState, useRef } from "react";
import { useHistory } from "react-router";
//MUI components
import TableRow from "@material-ui/core/TableRow";
import { Button, Modal, TableCell } from "@material-ui/core";
import useStyles from "./AssetsList.styles";
import AssetListDepartmentsTags from "../AssetsListContents/AssetListDepartmentsTags.component";
import AssetListCharactersTags from "../AssetsListContents/AssetListCharactersTags.component";
import ImageViewer from "../../Utilities/ImageViewer/ImageViewer.component";
import NoThumbnail from "../../Utilities/NoThumbnail.component";
import AssetsPageEditAssetModal from "../AssetsPageModal/AssetsPageEditAssetModal";
import AssetVersionsListStatusButtons from "../../AssetsVersions/AssetVersionsListContents/AssetVersionsListStatusButtons.component";
import { ArrowSquareIn } from "phosphor-react";
import StatusDropDown from "../../Utilities/StatusDropDown/StatusDropDown.component";
import { fdelete } from "../../../API/callsAPI";
import DeleteModal from "../../Utilities/DeleteModal/DeleteModal.component";
import DateAndTimeViewer from "../../Utilities/DateAndTimeViewer/DateAndTimeViewer.component";
import MultipleDepartmentViewer from "../../Utilities/MultipleDepartmentViewer/MultipleDepartmentViewer";

function AssetsList(props) {
  const ref = useRef(null);
  const [isEditAssetModalOpen, setEditAssetModalOpen] = useState(false);
  const [isDeleteAssetModalOpen, setDeleteAssetModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [showDepartments, setShowDepartments] = useState(false);

  const classes = useStyles();
  const history = useHistory();

  function handleEditAssetModalState(e) {
    e?.stopPropagation();
    setEditAssetModalOpen(!isEditAssetModalOpen);
  }

  function handleDeleteAssetModalState(e, data) {
    e?.stopPropagation();
    setDeleteData(data);
    setDeleteAssetModalOpen(!isDeleteAssetModalOpen);
  }

  const handleDeleteAsset = async () => {
    try {
      await fdelete({
        url: `trackables/asset/${deleteData?.id}/`,
      });
      props.updateRefreshCounter();
      setDeleteAssetModalOpen(false);
      setEditAssetModalOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <TableRow
        hover
        onClick={(event) => {
          if (event.ctrlKey) {
            window.open(
              `/${props.projectId}/assets/${props.data.id}/assetversions`
            );
            sessionStorage.setItem("scrollpos", window.scrollY);
          } else {
            history.push(
              `/${props.projectId}/assets/${props.data.id}/assetversions`
            );
            sessionStorage.setItem("scrollpos", window.scrollY);
          }
        }}
        style={{
          cursor: "pointer",
          display: "table",
          width: "100%",
          tableLayout: "fixed",
        }}
      >
        <TableCell
          component="th"
          scope="row"
          className={classes.listTextTwo}
          align="left"
        >
          <div style={{ display: "flex" }}>
            {props.data.thumbnail ? (
              <ImageViewer
                sliderState={props.isAssetTogglerOn ? 25 : 0}
                url={props.data.thumbnail}
              />
            ) : (
              <NoThumbnail sliderState={props.isAssetTogglerOn ? 25 : 0} />
            )}
            <p style={{ marginLeft: "10px" }}>{props.data.name}</p>
          </div>
        </TableCell>
        <TableCell className={classes.listText} align="center">
          <div className={classes.DepartmentDiv}>
            <AssetListCharactersTags content={props.data.type} />
          </div>
        </TableCell>
        <TableCell className={classes.listText}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "15px",
            }}
          >
            <StatusDropDown
              url={`trackables/asset/${props.data.id}/`}
              statusList={props?.statusList}
              currStatus={props?.data?.status}
              updateRefreshCounter={props.updateRefreshCounter}
            />
          </div>
        </TableCell>
        <TableCell className={classes.listText} align="center">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DateAndTimeViewer dateAndTime={props?.data?.updated_at} />
          </div>
        </TableCell>
        <TableCell className={classes.listText} align="center">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {props.data.allowed_dept.length !== 0 ? (
              <div style={{ display: "flex" }}>
                <AssetListDepartmentsTags
                  allowedDepartments={props.data.allowed_dept[0]}
                />
                {props.data.allowed_dept.length > 1 ? (
                  <div
                    ref={ref}
                    onMouseEnter={() => setShowDepartments(true)}
                    onMouseLeave={() => setShowDepartments(false)}
                    style={{
                      width: "30px",
                      textAlign: "left",
                      position: "relative",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "5px",
                    }}
                  >
                    +{props.data.allowed_dept.length - 1}
                    {showDepartments ? (
                      <div
                        style={{
                          marginTop: "0px",
                          position: "absolute",
                          left: `${ref?.current?.offsetWidth}px`,
                          cursor: "auto",
                          zIndex: "2",
                        }}
                      >
                        <MultipleDepartmentViewer
                          department={props.data.allowed_dept}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              "No Departments"
            )}
          </div>
        </TableCell>

        <TableCell align="right" className={classes.listTextTwo}>
          <Button
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              props
                .fetchFile(props.data.file ? props.data.id : null)
                // opening the modal after the function has fetched file, else previous file loads first
                .then(() => props.setIsViewModalOpen(true));
            }}
          >
            View
          </Button>

          <Button
            style={{ minWidth: "25px", padding: "2px" }}
            onClick={(event) => {
              event.stopPropagation();
              history.push(`/${props.projectId}/assets/${props.data.id}/task`);
            }}
          >
            <ArrowSquareIn height={25} width={25} />
          </Button>
          <AssetVersionsListStatusButtons
            openEditAssetVerisonModal={handleEditAssetModalState}
          />
        </TableCell>
      </TableRow>
      <div>
        <Modal
          className={classes.assetsListModals}
          open={isDeleteAssetModalOpen}
          onClose={() => setDeleteAssetModalOpen(false)}
        >
          <DeleteModal
            type="asset"
            name={deleteData?.name}
            handleCloseDeleteModal={() => {
              setDeleteAssetModalOpen(false);
            }}
            handleDelete={handleDeleteAsset}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.assetsListModals}
          open={isEditAssetModalOpen}
          onClose={() => setEditAssetModalOpen(false)}
        >
          <AssetsPageEditAssetModal
            dragScreen={props.dragScreen}
            handleDragScreen={props.handleDragScreen}
            setDragFileEnterModal={props.setDragFileEnterModal}
            parentAsset={props.data}
            projectId={props.projectId}
            assetID={props.data.id}
            assetType={props.assetType}
            statusList={props.statusList}
            handleEditAssetModal={handleEditAssetModalState}
            handleDeleteAssetModalState={handleDeleteAssetModalState}
            updateRefreshCounter={props.updateRefreshCounter}
            setSnackValue={props.setSnackValue}
          />
        </Modal>
      </div>
    </>
  );
}

export default AssetsList;
