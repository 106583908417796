import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
  },
  submitCommentField: {
    width: "90%",
    height: "66px",
    padding: "12px 10px",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    overflowY: "hidden",
    marginLeft: "4px",
  },
  submitButton: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "10px",
    marginLeft: "8px",
    minWidth: "44px",
    height: "44px",
  },
  commentHead: {
    display: "flex",
    fontFamily: "poppins",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: "13px",
    marginBottom: "9px",
  },
  time: {
    color: theme.palette.text.tertiary,
    fontFamily: "poppins",
    fontSize: "14px",
  },
  replyCard: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 15px",
    gap: "15px",
    width: "87%",
    marginLeft: "4px",
    position: "absolute",
    marginTop: "-50px",
  },
}));

export default useStyles;
