import React from "react";
import useStyles from "./SequencerPlayerSeekbar.style";
import { Button, Slider } from "@material-ui/core";
import {
  Pause,
  Play,
  Rectangle,
  SpeakerHigh,
  CornersOut,
} from "phosphor-react";
export default function SequencerPlayerSeekbar({
  isPaused,
  totalDurationTimeline,
  currentGlobalTime,
  onTimelineSeek,
  setIsPaused,
}) {
  const classes = useStyles();
  return (
    <>
      <div>
        <Button className={classes.btn} onClick={() => setIsPaused(!isPaused)}>
          {isPaused ? (
            <Play weight="fill" size={25} className={classes.playpauseButton} />
          ) : (
            <Pause
              weight="fill"
              size={25}
              className={classes.playpauseButton}
            />
          )}
        </Button>
      </div>
      <div className={classes.btnContainer}>
        <Button className={classes.btnPrevious}>
          <Play weight="fill" className={classes.playpauseButton} />
        </Button>
        <Button className={classes.btnNext}>
          <Play weight="fill" className={classes.playpauseButton} />
        </Button>
      </div>
      <div className={classes.flex}>
        <SpeakerHigh size={28} className={classes.icon} />
      </div>

      <div style={{ width: "100%" }}>
        <Slider
          color="secondary"
          onChangeCommitted={(event, value) =>
            onTimelineSeek(parseInt(value / 24))
          }
          min={0}
          max={totalDurationTimeline}
          step={1}
          value={parseInt(currentGlobalTime * 24)}
        />
      </div>
      <div className={classes.flex}>
        <Button className={classes.btn}>
          <Rectangle size={27} />
        </Button>
      </div>
      <div className={classes.flex}>
        <Button className={classes.btn}>
          <CornersOut size={27} />
        </Button>
      </div>
    </>
  );
}
