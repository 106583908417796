import React, { useState, useEffect } from "react";
import useStyles from "./ImportStatusFromProject.style.js";
import { Card, CardContent, Grid, Input, Button } from "@material-ui/core";
import * as Icon from "react-feather";
import { fget } from "../../../../../API/callsAPI";
import ProjectListItems from "./ProjectList/ProjectList.jsx";

export default function ImportStatusModal({
  allProjects,
  handleImportStatusModal,
  handleConfrimModal,
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const classes = useStyles();

  function handleSearchQuery(e) {
    setSearchQuery(e.target.value);
  }

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Grid container justifyContent="space-between">
            <Grid item lg={10} md={10} sm={10} xs={10}>
              <span className={classes.title}>
                Choose a project to import a status from
              </span>
            </Grid>
            <Grid item lg={1} md={2} sm={2} xs={2}>
              <Button style={{ minWidth: "0" }}>
                <Icon.X
                  className={classes.closeIcon}
                  onClick={() => handleImportStatusModal()}
                />
              </Button>
            </Grid>
          </Grid>
          <div className={classes.searchDiv}>
            <Icon.Search style={{ marginLeft: "2%" }} />
            <Input
              className={classes.searchInput}
              disableUnderline="false"
              placeholder="Search anything"
              onChange={(e) => handleSearchQuery(e)}
            />
          </div>
          <div style={{ marginTop: "3%" }}>
            <Grid container className={classes.projectListContainer}>
              {allProjects.length > 0
                ? allProjects &&
                  allProjects
                    ?.filter((search) => {
                      if (searchQuery === undefined) {
                        return search;
                      } else if (searchQuery === "") {
                        return search;
                      } else if (
                        search.name
                          ?.toLowerCase()
                          .includes(searchQuery.toLowerCase())
                      ) {
                        return search;
                      }
                      return null;
                    })
                    .map((project) => {
                      return (
                        <div
                          key={project.id}
                          style={{ width: "100%" }}
                          onClick={(e) => handleConfrimModal(e, project)}
                        >
                          <ProjectListItems project={project} />
                        </div>
                      );
                    })
                : "Loading...."}
            </Grid>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
