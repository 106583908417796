import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  listText: {
    color: theme.palette.text.primary,
  },

  select: {
    fontSize: "16px",
    minWidth: "45%",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.16)",
      borderRadius: "5px",
    },
  },
  statusColor: {
    borderRadius: "50%",
    display: "inline-block",
    margin: "0px 5px",
    height: "15px",
    width: "15px",
    border: `1px solid ${theme.palette.border.main}`
  }
}));

export default useStyles;
