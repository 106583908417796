import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "400px",
    maxWidth: "1160px",
    minHeight: "350px",
  },
  card: {
    borderRadius: "20px",
    color: theme.palette.text.primary,
    border: `2px solid ${theme.palette.secondary.main}`,
  },
  cardContent: {
    backgroundColor: theme.palette.primary.main,
    padding: "10px",
    position: "relative",
    paddingBottom: "12px !important",
  },
  cardTitleAndVersionsContainer: {
    overflowX: "auto",
  },
  cardTitleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  title: {
    fontSize: "24px",
    fontWeight: 500,
    textAlign: "center",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    gap: "10px",
  },
  closeAndSaveButton: {
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: "10px",
    backgroundColor: "transparent",
    color: theme.palette.text.primary,
  },
  checkButton: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "10px",
  },
  rotateText: {
    writingMode: "vertical-lr",
    transform: "rotate(180deg)",
  },
  versionAndSideTitlesContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    gap: "10px",
  },
  loaderDiv: {
    height: "200px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  shotVersionContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    maxHeight: "515px",
    gap: "8px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
  },
  singleVersionAndSeparator: {
    display: "flex",
    alignItems: "center",
  },
  singleVersionContainer: {
    padding: "10px 3px",
    borderRadius: "20px",
    // border: `2px solid ${theme.palette.secondary.main}`,
    borderColor: theme.palette.secondary.main,
    borderStyle: "solid",
  },
  separatorDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  separator: {
    height: "200px",
    borderLeft: `1px solid ${theme.palette.text.tertiary}`,
    marginLeft: "8px",
  },
  versionImage: {
    height: "140px",
    width: "260px",
    borderRadius: "20px",
  },
  versionName: {
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "center",
    display: "block",
  },
  statusAndVersionInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 3px",
  },
  statusDiv: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },

  statusColor: {
    borderRadius: "50%",
    display: "inline-block",
    marginRight: "10px",
    height: "15px",
    width: "15px",
    border: `1px solid ${theme.palette.border.main}`,
  },
  dateAndCreator: {
    fontSize: "10px",
    color: theme.palette.text.tertiary,
  },
  selectedVersionDateAndCreator: {
    fontSize: "10px",
    color: theme.palette.text.primary,
  },
  singleVersionText: {
    textAlign: "center",
    marginLeft: "40px",
  },
}));

export default useStyles;
