//plays a video plyback one after the other!

export class SequencerStreamer {
  #containerElement;
  #arrayOfElements = [];
  #orderedVideo;

  #currentActiveShotIndex = 0;

  #currentGlobalTime;

  //callback functions
  #onGlobalTimeUpdate;

  #onUrlExpire;

  #onPause = null;

  //play/pause State
  #isPlaying; //tru to play

  constructor(
    containerElement,
    { orderedVideo, setMediaState },
    { onGlobalTimeUpdate, onShotChange, onPause, onPlay, onError, onUrlExpire }
  ) {
    this.#containerElement = containerElement;
    this.#orderedVideo = orderedVideo;
    this.#onGlobalTimeUpdate = onGlobalTimeUpdate;
    this.#onUrlExpire = onUrlExpire;
    if (onPause) {
      this.#onPause = onPause;
    }
    //this.destroy();

    var delChild = this.#containerElement.lastChild;
    while (delChild) {
      this.#containerElement.removeChild(delChild);
      delChild = this.#containerElement.lastChild;
    }

    const arrayOfElement = [];
    for (let i = 0; i < orderedVideo.length; i++) {
      const localVid = document.createElement("video");
      localVid.style.height = "100%";
      localVid.ariaLabel = i;
      localVid.style.width = "100%";
      localVid.style.marginRight = "-100%";
      localVid.style.backgroundColor = "#252A38";
      localVid.style.position = "relative";
      localVid.ontimeupdate = this.handleVideoTimeUpdate.bind(
        this,
        orderedVideo[i]
      );
      if (i === 0) {
        localVid.style.zIndex = 50;
      } else {
        localVid.style.zIndex = 0;
      }
      localVid.src = orderedVideo[i].sources;
      localVid.onerror = (event) => {
        this.#onUrlExpire(orderedVideo[i], (result) => {
          event.target.src = result;
        });
      };
      arrayOfElement.push(localVid);
    }

    for (let i = 0; i < arrayOfElement.length; i++) {
      this.#containerElement.appendChild(arrayOfElement[i]);
    }
    this.#arrayOfElements = arrayOfElement;
    console.log(this.#arrayOfElements, this.#containerElement.children);
  }

  startRenderer() {
    this.#arrayOfElements[0].play();
  }

  handleVideoTimeUpdate(shotInfo, event) {
    if (event.target.currentTime === 0) {
      return;
    }
    if (event.target.currentTime <= shotInfo.end) {
      //update gl9obal time
      this.#currentGlobalTime =
        this.#orderedVideo[shotInfo.index].previousEnd +
        event.target.currentTime;
      this.#onGlobalTimeUpdate(this.#currentGlobalTime);
    } else {
      //console.log('ended', this.#arrayOfElements[this.#currentActiveShotIndex]);
      // the video has ended
      this.#arrayOfElements[this.#currentActiveShotIndex].pause();
      this.#arrayOfElements[this.#currentActiveShotIndex].currentTime =
        shotInfo.start;

      if (shotInfo.index !== this.#orderedVideo.length - 1) {
        this.#currentActiveShotIndex = this.#currentActiveShotIndex + 1;
        this.handleElementVisiblity(this.#currentActiveShotIndex);
        this.handlePlayPause();
      } else {
        this.#isPlaying = false;
        this.#currentActiveShotIndex = 0;
        this.handleElementVisiblity(this.#currentActiveShotIndex);
        this.#onPause();
        // this.handlePlayPause();
      }
      //   this.#arrayOfElements[this.#currentActiveShotIndex].play();
    }
  }

  handleElementVisiblity(playerIndex) {
    for (let i = 0; i < this.#arrayOfElements.length; i++) {
      if (playerIndex === i) {
        this.#arrayOfElements[i].style.zIndex = 50;
      } else {
        this.#arrayOfElements[i].style.zIndex = 2;
      }
    }
  }

  // destroy() {
  //   //flushes all the players
  //   const children = this.#containerElement.children;
  //   for (let i = 0; i < children.length; i++) {
  //     this.#containerElement.removeChild(children[i]);
  //   }
  // }

  set seekTotime(value) {
    //setloading state
    let timeBuffer;
    //check the video for that time
    const result = this.#orderedVideo.find(
      (eachvideo) =>
        eachvideo.fpsStart / 24 <= value && eachvideo.fpsEnd / 24 >= value
    );

    if (result) {
      if (result.index !== 0) {
        timeBuffer = value - this.#orderedVideo[result.index - 1].fpsEnd / 24;
      } else {
        timeBuffer = value;
      }
      console.log("found shot at:", result);
      this.toggleShot(result.index, timeBuffer);
    }

    //toggle the player and start playing
  }

  set setPlaying(state) {
    //revieces boolean value
    if (state === true && this.#isPlaying !== true) {
      this.#isPlaying = true;
      //call func to update players
      this.handlePlayPause();
    } else {
      this.#isPlaying = false;
      this.handlePlayPause();
    }
  }

  handlePlayPause() {
    //functions call play pause methods agressively, call with check on updation!
    if (this.#isPlaying) {
      console.log("playing");
      this.#arrayOfElements[this.#currentActiveShotIndex].play();
    } else {
      console.log("not playing");
      this.#arrayOfElements[this.#currentActiveShotIndex].pause();
    }
  }

  toggleShot(shotIndex, timeBuffer) {
    //pause current playing shot if any
    //evaluate for extra time
    this.#arrayOfElements[this.#currentActiveShotIndex].pause();
    this.handleElementVisiblity(shotIndex);
    this.#currentActiveShotIndex = shotIndex;
    //start playing the current shot
    if (timeBuffer) {
      this.#arrayOfElements[this.#currentActiveShotIndex].currentTime =
        timeBuffer;
    } else {
      this.#arrayOfElements[this.#currentActiveShotIndex].currentTime = 0;
    }

    // this.#arrayOfElements[this.#currentActiveShotIndex].play();
    this.handlePlayPause();
    //set startof all other players
    for (let i = 0; i < this.#arrayOfElements.length; i++) {
      if (i !== shotIndex) {
        this.#arrayOfElements[i].currentTime = this.#orderedVideo[i].start;
      }
    }
  }
}
