import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  notificationsDropdown: {
    paddingLeft: "50px",
    paddingRight: "50px",
    width: "100%",

    background: theme.palette.primary.main,
    borderRadius: "10px",
    right: "12vh",
    fontFamily: "Nunito sans",
    color: theme.palette.text.primary,
    zIndex: 2000,
    paddingTop: "10px",
  },
  notificationsDropdownMenu: {
    height: "100%",
    width: "100%",
  },

  notificationsItems: {
    padding: "20px",
    cursor: "pointer",
    display: "flex",
    gap: "20px",
    alignItems: "center",
    color: theme.palette.text.primary,
    p: {
      color: theme.palette.text.primary,
      padding: "0",
      margin: "0",
      textAlign: "start",
      width: "50%",
    },
    th: { display: "flex", justifyContent: "space-between", width: "100%" },
  },
  notificationDrop: {
    color: theme.palette.secondary.main,
    marginLeft: "5px",
    padding: "2px",
  },
  description: {
    color: theme.palette.text.primary,
    padding: "0",
    margin: "0",
    textAlign: "start",
    width: "70%",
    padding: "5px",
    fontFamily: "Nunito Sans",
    fontSize: "larger",
    fontWeight: "bold",
  },
  description2: {
    color: theme.palette.text.primary,
    padding: "0",
    margin: "0",
    textAlign: "start",
    width: "70%",
    padding: "5px",
    fontFamily: "Nunito Sans",
  },
  date: {
    fontFamily: "Nunito Sans",
    fontSize: "10px",
  },
  seeAll: {
    fontFamily: "Nunito Sans",
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
}));

export default useStyles;
