import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    // borderCollapse: "inherit", //this property isn't necessary it brings bug to table line
  },

  ShotsImageDiv: {
    background: "#C4C4C4",
    borderRadius: "10px",
    marginRight: "20px",
  },
  ShotsImage: {
    borderRadius: "8px",
  },
  DepartmentsDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  DepartmentDiv: {
    display: "inline-grid",
    textAlign: "center",

    borderRadius: "20px",
  },
  shotListTableCell: {
    padding: "1px",
    overflow: "hidden",
    borderCollapse: "collapse",
    width: "23.3%",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
  },
  shotListTableCellTwo: {
    padding: "1px",
    overflow: "hidden",
    borderCollapse: "collapse",
    width: "15%",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
  },
  shotListPageModals: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default useStyles;
