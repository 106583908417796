import React, { useState } from "react";
// import ReactPlayer from "react-player";
import VideoController from "../ComparePageVideoController/VideoController";
import useStyles from "./VideoPlayer.styles";

export default function VideoPlayer({
  isPlaying,
  totalDuration,
  setTotalDuration,
  setCurrentTimeSeek,
  handlePlayPause,
  refPlayer,
  currentDuration,
  onProgress,
  fileURL,
  handlePrevious,
  handleNext,
  volume,
  muted,
  onVolumeChange,
  onMute,
  loopVersions,
}) {
  const classes = useStyles();
  const [showControls, setShowControls] = useState(false);
  return (
    <>
      <div
        className={classes.playerContainer}
        onMouseEnter={() => setShowControls(true)}
        onMouseLeave={() => setShowControls(false)}
      >
        {/* <ReactPlayer
          height={"100%"}
          width={"100%"}
          style={{
            borderRadius: "0px !important",
          }}
          config={{
            file: {
              attributes: {
                crossOrigin: "anonymous",
              },
            },
          }}
          onContextMenu={(e) => e.preventDefault()}
          progressInterval={totalDuration < 10 ? 100 : 250}
          ref={refPlayer}
          url={fileURL}
          playing={isPlaying}
          volume={volume / 100}
          muted={muted}
          onDuration={(duration) => {
            setTotalDuration(duration);
          }}
          onProgress={(p) => {
            onTimeUpdate(p.playedSeconds);
          }}
        /> */}
        <video
          style={{
            height: "100%",
            width: "100%",
          }}
          src={fileURL ? fileURL : ""}
          crossOrigin="anonymous"
          ref={refPlayer}
          onLoadedData={() => {
            setShowControls(true);
            setTotalDuration(refPlayer.current.duration);
          }}
          onContextMenu={(e) => e.preventDefault()}
          onTimeUpdate={(e) => {
            onProgress(refPlayer.current.currentTime);
          }}
          onEnded={() => loopVersions()}
        />
        <div className={classes.controlsContainer}>
          {showControls ? (
            <VideoController
              type="general"
              isPlaying={isPlaying}
              totalDuration={totalDuration}
              currentDuration={currentDuration}
              volume={volume}
              muted={muted}
              onTimeSeek={(time) => setCurrentTimeSeek(time)}
              handlePlayPause={handlePlayPause}
              handlePrevious={handlePrevious}
              handleNext={handleNext}
              onVolumeChange={onVolumeChange}
              onMute={onMute}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
