import React, { useState } from "react";
//UI
import { ChevronDown } from "react-feather";
import { MenuItem, FormControl, Select, Divider } from "@material-ui/core";
import useStyles from "./NotificationProjectFilter.styles";

function NotificationProjectFilter({
  projects,
  handleProject,
  selectedProject,
}) {
  const classes = useStyles();
  const [projectName, setProjectName] = useState("All Projects");

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <div className={classes.dropdownDiv}>
        <text className={classes.selectText}>{projectName}</text>
        <ChevronDown className={classes.iconStyle} />
      </div>
      <Select
        style={{ height: "0%", opacity: "0" }}
        className={classes.formLabelText}
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        label="Select Status"
        defaultValue="none"
        MenuProps={{
          classes: {
            paper: classes.dropDown,
          },
        }}
        value={selectedProject}
        onChange={(event) => {
          setProjectName(event.target.value[1]);
          handleProject(event.target.value[0]);
        }}
      >
        <MenuItem value={["", "All Projects"]} className={classes.menuItem}>
          <div>All Projects</div>
          <Divider className={classes.divider} />
        </MenuItem>

        {projects &&
          projects.map((project) => (
            <MenuItem
              value={[project.id, project.name]}
              key={project.id}
              className={classes.menuItem}
            >
              <div>{project.name}</div>
              <Divider className={classes.divider} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

export default NotificationProjectFilter;
