import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  CombinePlayerContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.secondary.light,
  },
  videoControllerContainer: {
    height: "11%",
    width: "100%",
    position: "relative",
    marginTop: "-7.9%",
  },
  playerContainer: {
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateColumns: "1fr",
  },
  playerContainerOne: {
    width: "100%",
    height: "100%",
    gridRowStart: "1",
    gridColumnStart: "1",

    position: "relative",
  },
  playerContainerTwo: {
    width: "100%",
    height: "100%",
    gridRowStart: "1",
    gridColumnStart: "1",
    position: "relative",
  },
}));

export default useStyles;
