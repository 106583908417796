import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: `calc(100vh - 210px)`,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#000000",
  },

  card: {
    backgroundColor: "#000000",
    borderRadius: "20px",
    padding: "10px 15px",
  },

  cardContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
  },
  errorHead: {
    color: "#FF0000",
    fontWeight: 600,
    fontSize: "48px",
  },

  errorOccurred: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    fontSize: "24px",
    color: "#606479",
  },

  errorAndButton: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "50%",
  },

  errorMessage: {
    width: "700px",
    height: "100px",
    overflowX: "hidden",
    overflowY: "auto",
    color: "yellow",
    marginTop: "40px",
    fontWeight: "200",
    textAlign: "center",
  },

  buttonDiv: {
    display: "flex",
    alignItems: "center",
  },

  reportIssueButton: {
    backgroundColor: theme.palette.secondary.main,
    height: "45px",
    width: "150px",
    borderRadius: "10px",
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.text.primary,
    },
    fontSize: "14px",
    margin: "5px",
  },

  showErrorButton: {
    height: "45px",
    width: "150px",
    borderRadius: "10px",
    border: `2px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    cursor: "pointer",
    display: "flex",
    gap: "5%",
    alignItems: "center",
    fontSize: "14px",
    margin: "15px",
  },
}));

export default useStyles;
