import {
  Button,
  Card,
  CircularProgress,
  TextField,
  Input,
  Snackbar,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Authorization } from "../../Components/authorization";
import { Alert } from "@material-ui/lab";

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [inProg, setInProg] = useState(false);
  const [initialCred, setInitialCred] = useState({ email: "", password: "" });
  const [snackValue, setSnackValue] = React.useState({
    isOpen: false,
    message: "",
    isError: false,
  });

  //Validators
  const userValidationScheme = yup.object({
    email: yup
      .string()
      .email("Must be an email")
      .max(225)
      .required("Email is Required"),
    password: yup.string().required("Password is required field"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: userValidationScheme,
    onSubmit: async (values) => {
      setInProg(true);
      new Authorization().getAccessToken(values.email, values.password).then(
        (res) => {
          if (res) {
            setSnackValue({
              isOpen: true,
              message: `SuccessFully Logged In`,
              isError: false,
            });
            props.setloggedIn(true);
          }
          setInProg(false);
        },
        (err) => {
          setSnackValue({
            isOpen: true,
            message: "LogIn Failed",
            isError: true,
          });
          setInProg(false);
          console.log("Auth Error", err);
        }
      );
    },
  });
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackValue.isOpen}
        autoHideDuration={2000}
        onClose={() => setSnackValue({ isOpen: false, message: "" })}
        // message={`${snackValue.message}`}
      >
        <Alert severity={snackValue.isError === false ? "success" : "warning"}>
          {snackValue.message}
        </Alert>
      </Snackbar>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          background: "black",
          flexDirection: "column",
        }}
      >
        <h1
          style={{
            fontSize: "70px",
            padding: "20px 30px",
            margin: "0px",
            fontFamily: "Nunito Sans",
            color: "#4ECCA3",
          }}
        >
          MovieColab
        </h1>

        <Card
          style={{
            width: "517px",
            backgroundColor: "rgba(121, 121, 121, 0.3)",
            backdropFilter: "blur(3px)",
            borderRadius: "40px",
            margin: "35px",
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "90%",
                margin: "20px",
                paddingBottom: "0px",
                color: "#4ECCA3",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              <p>Email</p>
              <Input
                name="email"
                style={{
                  borderRadius: "10px",
                  background: "#393E46",
                  height: "40px",
                  padding: "5px 15px",
                }}
                inputProps={{ disableUnderline: true }}
                required={true}
                onChange={formik.handleChange}
                value={formik.values.email}
                error={Boolean(formik.errors.email)}
                helperText={formik.errors.email}
              />
              <p>Password</p>
              <Input
                name="password"
                type="password"
                inputProps={{ disableUnderline: true }}
                style={{
                  borderRadius: "10px",
                  background: "#393E46",
                  height: "40px",
                  padding: "5px 15px",
                }}
                required={true}
                onChange={formik.handleChange}
                value={formik.values.password}
                error={Boolean(formik.errors.password)}
                helperText={formik.errors.password}
              />
            </div>
            <Button
              style={{
                background: "#4ECCA3",
                borderRadius: "15px",
                color: "black",
                fontWeight: "bold",
                width: "15%",
                marginLeft: "40%",
                marginTop: "35px",
                marginBottom: "35px",
              }}
              type="submit"
              disabled={inProg}
            >
              {inProg ? (
                <CircularProgress style={{ height: "20px", width: "20px" }} />
              ) : (
                "LogIn"
              )}
            </Button>
          </form>
        </Card>
      </div>
    </>
  );
}
