import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  breadCrumbLink: {
    fontSize: "14px",
    color: theme.palette.text.tertiary,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.text.primary,
      textDecoration: "none",
    },
    fontFamily: "poppins",
  },
  endBreadCrumb: {
    fontSize: "14px",
    color: theme.palette.text.primary,
    fontFamily: "poppins",
  },
  separatorIcon: {
    color: theme.palette.text.primary,
  },
}));

export default useStyles;
