import React from "react";
import { Button, Switch } from "@material-ui/core";
import * as Icon from "react-feather";
import SideBySide from "./SideBySide";
import OnTop from "./OnTop";

function ComparePageTool(props) {
  return (
    <div style={{ display: "flex", width: "100%", marginTop: "-15px" }}>
      <Button
        style={{ width: "5%", marginRight: "37%", marginLeft: "2%" }}
        onClick={props.goTo}
      >
        <Icon.ChevronLeft />
        Back
      </Button>
      <div style={{ display: "flex", alignItems: "center" }}>
        <SideBySide />
        <div
          style={{ marginRight: "10px", marginTop: "-5px", marginLeft: "5px" }}
        >
          <Switch
            height={20}
            width={40}
            offColor={"#171B21"}
            onColor={"#4ECCA3"}
            onChange={props.changeView}
          />
        </div>
        <OnTop />
      </div>
    </div>
  );
}

export default ComparePageTool;
