import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  itemContainer: {
    padding: "6px",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: {
    color: theme.palette.text.tertiary,
    cursor: "grab",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  dateContainer: {
    width: "36%",
    display: "flex",
    justifyContent: "center",
  },
  iconContainer: {
    width: "33%",
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export default useStyles;
