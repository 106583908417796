import React, { useState } from "react";
import SelectUserModal from "../../Utilities/ManageUsers/SelectUsersModal/SelectUserModal.component";
import useStyles from "./PlaylistEditModal.styles";
import {
  Button,
  Card,
  CircularProgress,
  CardContent,
  TextField,
  Modal,
  Input,
  Avatar,
  Select,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import {
  X,
  UserPlus,
  Trash,
  Circle,
  Check,
  MagnifyingGlass,
} from "phosphor-react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect } from "react";
import UserAvatar from "../../Utilities/UserAvatar/UserAvatar.component";

const PlaylistEditModal = ({
  handleCloseModal,
  handleDeleteModal,
  selectedPlaylist,
  handlePlaylistEdit,
  project,
  sequenceList,
  allUsers,
}) => {
  const [loading, setLoading] = useState(false);
  const [zeroSelectedField, setZeroSelectedField] = useState(false);
  const [selectedSeq, setSelectedSeq] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [openSequenceSelectModal, setOpenSequenceSelectModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSelectUserModalOpen, setSelectUserModalOpen] = useState(false);
  const classes = useStyles();

  //Validators
  const EditPlaylistValidationSchema = yup.object({
    playlistName: yup
      .string("String required")
      .min(1, "Too Short!")
      .max(50, "Maximum Character Limit: 50"),
  });

  useEffect(() => {
    if (selectedPlaylist) {
      setSelectedSeq(selectedPlaylist?.sequences);
      const newuser = selectedPlaylist?.allowed_users.map((user) => {
        return {
          label: `${user.first_name} ${user.last_name}`,
          value: user.id,
        };
      });
      setSelectedUser(newuser);
    }
  }, []);

  const handleSearchQuery = (e) => {
    setSearchQuery(e.target.value);
    console.log(e.target.value);
  };

  const formik = useFormik({
    initialValues: {
      playlistName: selectedPlaylist?.name ? selectedPlaylist?.name : "",
      accessCode: selectedPlaylist?.access_code
        ? selectedPlaylist?.access_code
        : "",
      resolution: selectedPlaylist.resolution_width
        ? `${selectedPlaylist?.resolution_width},${selectedPlaylist?.resolution_height},${selectedPlaylist?.aspect_ratio}`
        : "",
      frameRate: selectedPlaylist?.frame_rate
        ? selectedPlaylist?.frame_rate
        : "",
    },
    validationSchema: EditPlaylistValidationSchema,

    onSubmit: async (values) => {
      if (formik.values.playlistName || formik.values.accessCode) {
        setZeroSelectedField(false);
        setLoading(true);
        const formDataObj = {
          name: formik.values.playlistName,
          access_code: formik.values.accessCode,
          frame_rate: formik.values.frameRate,
          aspect_ratio: formik.values.resolution.split(",")[2],
          resolution_width: formik.values.resolution.split(",")[0],
          resolution_height: formik.values.resolution.split(",")[1],
          project: project,
          sequences: selectedSeq.map((eachSeq) => eachSeq.id),
          allowed_users: selectedUser.map((user) => user.value),
        };
        handlePlaylistEdit(selectedPlaylist.id, formDataObj);
      } else {
        setZeroSelectedField(true);
      }
    },
  });

  const handleSequenceSelect = (sequence) => {
    if (selectedSeq.length) {
      const exist = selectedSeq.some((eachSeq) => eachSeq.id === sequence.id);
      if (exist) {
        setSelectedSeq(
          selectedSeq.filter((eachSeq) => eachSeq.id !== sequence.id)
        );
      } else {
        setSelectedSeq([...selectedSeq, sequence]);
      }
    } else {
      setSelectedSeq([sequence]);
    }
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <form enctype="multipart/form-data" onSubmit={formik.handleSubmit}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <span className={classes.title}>Edit Playlist</span>
              </div>
              <div>
                <Button
                  style={{ minWidth: 0, minHeight: 0 }}
                  onClick={() => handleDeleteModal(selectedPlaylist)}
                >
                  <Trash
                    className={classes.icon}
                    style={{ width: "20px", height: "20px" }}
                    color="#FF0000"
                    weight="regular"
                  />
                </Button>
                <Button
                  style={{ minWidth: 0, minHeight: 0 }}
                  onClick={handleCloseModal}
                >
                  <X
                    className={classes.icon}
                    style={{ width: "20px", height: "20px" }}
                  />
                </Button>
              </div>
            </div>
            <div className={classes.cardContentDiv1}>
              <div className={classes.textFieldContainer}>
                <span>Playlist Name</span>
                <div style={{ marginTop: "5px" }}>
                  <TextField
                    type="text"
                    multiline
                    className={classes.cardContentInput1}
                    placeholder="Enter playlist name"
                    InputProps={{ disableUnderline: true }}
                    value={formik.values.playlistName}
                    error={Boolean(formik.errors.playlistName)}
                    helperText={
                      formik.errors.playlistName ? (
                        <p style={{ marginTop: "-7px" }}>
                          {formik.errors.playlistName}
                        </p>
                      ) : null
                    }
                    onChange={formik.handleChange}
                    name="playlistName"
                    id="playlistName"
                  />
                </div>
              </div>

              <div className={classes.textFieldContainer}>
                <span>Access Code</span>
                <div style={{ marginTop: "5px" }}>
                  <TextField
                    type="text"
                    multiline
                    className={classes.cardContentInput1}
                    placeholder="Enter code"
                    InputProps={{ disableUnderline: true }}
                    value={formik.values.accessCode}
                    error={Boolean(formik.errors.accessCode)}
                    helperText={
                      formik.errors.accessCode ? formik.errors.accessCode : null
                    }
                    onChange={formik.handleChange}
                    name="accessCode"
                    id="accessCode"
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                  gap: "5px",
                  marginRight: "5px",
                }}
              >
                <label id="demo-multiple-select">Sequences</label>
                <div
                  className={classes.cardContentInput1}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                  onClick={() => setOpenSequenceSelectModal(true)}
                >
                  {selectedSeq.length
                    ? selectedSeq.map((eachSeq, index) =>
                        index <= 3
                          ? selectedSeq.at(-1) === eachSeq
                            ? eachSeq.code
                            : eachSeq.code + ", "
                          : index === 4
                          ? "..."
                          : ""
                      )
                    : "Select Sequences"}
                </div>
              </div>
              <div className={classes.textFieldContainer}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: "10px",
                  }}
                >
                  <span>Select Users</span>
                  <hr
                    className={classes.hrTimeline}
                    style={{ width: "77%", alignSelf: "flex-end" }}
                  />
                </div>
                <div
                  style={{
                    marginTop: "5px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    {selectedUser.length > 2
                      ? selectedUser.map((user) => (
                          <div
                            style={{
                              marginRight: "10px",
                            }}
                            key={user.value}
                          >
                            <Tooltip title={user?.label}>
                              <UserAvatar
                                avatar={user?.avatar}
                                firstName={user?.label?.split(" ")[0]}
                                lastName={user?.label?.split(" ")[1]}
                              />
                            </Tooltip>
                          </div>
                        ))
                      : selectedUser.map((user) => (
                          <div className={classes.fullNameUser}>
                            <UserAvatar
                              avatar={user?.avatar}
                              firstName={user?.label?.split(" ")[0]}
                              lastName={user?.label?.split(" ")[1]}
                            />
                            <span style={{ margin: "0 5px" }}>
                              {user?.label}
                            </span>
                          </div>
                        ))}
                  </div>
                  <Button
                    style={{
                      marginTop: "-10px",
                      minHeight: 0,
                      minWidth: 0,
                      marginRight: "-5px",
                    }}
                    onClick={() =>
                      setSelectUserModalOpen(!isSelectUserModalOpen)
                    }
                  >
                    <UserPlus className={classes.userPlusIcon} />
                  </Button>
                </div>
              </div>
              <div className={classes.textFieldContainer}>
                <span>Timeline Settings</span>
                <hr className={classes.hrTimeline} />
              </div>
              <div className={classes.textFieldContainer}>
                <span>Resolution</span>
                <div style={{ marginTop: "5px" }}>
                  <Select
                    style={{ padding: "10px" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    disableUnderline
                    className={classes.cardContentInput1}
                    value={formik.values.resolution}
                    onChange={formik.handleChange}
                    renderValue={
                      formik.values.resolution !== ""
                        ? undefined
                        : () => (
                            <p className={classes.placeholder}>
                              Select Resolution
                            </p>
                          )
                    }
                    name="resolution"
                    MenuProps={{
                      getContentAnchorEl: () => null,
                      classes: {
                        paper: classes.paper,
                      },
                    }}
                    inputProps={{
                      classes: {
                        root: classes.input,
                      },
                    }}
                    displayEmpty
                    required
                  >
                    <MenuItem value="1920,1080,16:9">
                      1920 X 1080 (16:9)
                    </MenuItem>
                    <MenuItem value="1540,920,12:5">1540 X 920 (12:5)</MenuItem>
                  </Select>
                </div>
              </div>
              <div className={classes.textFieldContainer}>
                <span>FrameRate</span>
                <div style={{ marginTop: "5px" }}>
                  <Select
                    style={{ padding: "10px" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    disableUnderline
                    className={classes.cardContentInput1}
                    value={formik.values.frameRate}
                    onChange={formik.handleChange}
                    name="frameRate"
                    renderValue={
                      formik.values.frameRate !== ""
                        ? undefined
                        : () => (
                            <p className={classes.placeholder}>
                              Select FrameRate
                            </p>
                          )
                    }
                    MenuProps={{
                      getContentAnchorEl: () => null,
                      classes: {
                        paper: classes.paper,
                      },
                    }}
                    inputProps={{
                      classes: {
                        root: classes.input,
                      },
                    }}
                    displayEmpty
                    required
                  >
                    <MenuItem value="24">24</MenuItem>
                  </Select>
                </div>
              </div>
              {zeroSelectedField && (
                <p
                  style={{
                    position: "absolute",
                    color: "yellow",
                    marginTop: "-10px",
                    marginLeft: "50px",
                  }}
                >
                  Please select atleast one field
                </p>
              )}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                className={classes.submitBtn}
                type="submit"
                value="Submit"
                size="small"
                disableElevation
                style={{ marginTop: "10px" }}
              >
                {loading ? (
                  <CircularProgress size={23} className={classes.icon} />
                ) : (
                  <b>Save Changes</b>
                )}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
      <div>
        <Modal
          open={openSequenceSelectModal}
          onClose={() => setOpenSequenceSelectModal(false)}
          className={classes.center}
        >
          <div className={classes.sequenceModal}>
            <div className={classes.toolContainer}>
              <div className={classes.searchDiv}>
                <MagnifyingGlass size={25} style={{ marginLeft: "2%" }} />
                <Input
                  className={classes.searchInput}
                  disableUnderline="false"
                  placeholder="Search anything"
                  onChange={(e) => handleSearchQuery(e)}
                />
              </div>
              <div>
                <X
                  size={27}
                  className={classes.seqModalCloseIcon}
                  onClick={() => setOpenSequenceSelectModal(false)}
                />
              </div>
            </div>
            <div className={classes.sequenceList}>
              {sequenceList && sequenceList.count !== 0
                ? sequenceList.results
                    ?.filter((search) => {
                      if (searchQuery === undefined) {
                        return search;
                      } else if (searchQuery === "") {
                        return search;
                      } else if (
                        search.code
                          ?.toLowerCase()
                          .includes(searchQuery.toLowerCase())
                      ) {
                        return search;
                      } else return null;
                    })
                    .map((sequence) => (
                      <>
                        <div
                          className={classes.singleSequence}
                          key={sequence}
                          onClick={() => {
                            handleSequenceSelect(sequence);
                          }}
                        >
                          {selectedSeq?.map((eachSeq) =>
                            eachSeq?.id === sequence?.id ? (
                              <div
                                style={{
                                  position: "absolute",
                                  marginTop: "3px",
                                  marginLeft: "2px",
                                }}
                              >
                                <Check size={25} className={classes.check} />
                              </div>
                            ) : (
                              ""
                            )
                          )}

                          <Circle size={25} className={classes.check} />
                          {sequence.code}
                        </div>
                        {sequenceList.results.at(-1) === sequence ? (
                          ""
                        ) : (
                          <hr className={classes.hrSeq} />
                        )}
                      </>
                    ))
                : "No Sequences"}
            </div>
            <div className={classes.seqButtonDiv}>
              <Button
                className={classes.seqButton}
                onClick={() => setOpenSequenceSelectModal(false)}
              >
                Done
              </Button>
            </div>
          </div>
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.modal}
          open={isSelectUserModalOpen}
          onClose={() => setSelectUserModalOpen(!isSelectUserModalOpen)}
        >
          <SelectUserModal
            closeModal={() => setSelectUserModalOpen(!isSelectUserModalOpen)}
            assignedUser={selectedUser}
            usersList={allUsers}
            handleSelectUsers={(users) => setSelectedUser(users)}
          />
        </Modal>
      </div>
    </div>
  );
};

export default PlaylistEditModal;
