import { makeStyles } from "@material-ui/core/styles";
import { renderToStaticMarkup } from "react-dom/server";
const useStyles = makeStyles((theme) => ({
  comparePageSelectVersionModal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  canvasDiv: {
    borderRadius: "10px",
    height: "95%",
    width: "95%",
    padding: "2% 5%",
    background: theme.palette.primary.main,
  },
  modalHead: {
    background: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.palette.secondary.main,
    fontFamily: "Nunito Sans",
  },

  selectVersion: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    border: `1px dashed ${theme.palette.border.main}`,
    cursor: "pointer",
  },
  videoPlayerCard: {
    height: "100%",
    width: "80%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.secondary.light,
    padding: "17px 18px 26px 18px",
    boxSizing: "border-box",
    borderRadius: "10px",
  },
}));

export default useStyles;
