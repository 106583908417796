import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  layerBtn: {
    height: "25px",
    minWidth: "0px",
    padding: "3px",
    marginLeft: "3px",
    fontSize: "20px",
  },
  newLayers: {
    height: "27px",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "6px",
    paddingLeft: "5px",
    width: "92%",
  },
  addNewLayer: {
    fontSize: "14px",
    color: theme.palette.text.tertiary,
  },
  toolsContainer: {
    background: theme.palette.primary.main,
    padding: "4px",
    border: `2px solid ${theme.palette.primary.dark}`,
    width: "10%",
    display: "flex",
    justifyContent: "space-around",
    borderRadius: "10px 10px 0px 0px",
  },
  toolsButton: {
    padding: "3px",
    minWidth: "0",
  },
  containerOne: {
    height: "40px",
    display: "flex",
    justifyContent: "center",
  },
}));

export default useStyles;
