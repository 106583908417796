import React, { useState, useRef, useEffect } from "react";
import { fget } from "../../../../API/callsAPI";
import { Button, useMediaQuery, Modal } from "@material-ui/core";
import { CaretLeft, CaretRight, DownloadSimple, X } from "phosphor-react";
import { ErrorBoundary } from "@sentry/react";
import useStyles from "./ReviewPageVersionViewerOverview.styles";
//
import { checkImage, checkGlb, getModelFileType } from "../../../utils";
import ImageViewer from "../../Image-Viewer/ImageViewer.component";
import MediaError from "../../ErrorFalbackComponents/MediaError";
import ModelViewerLatest from "../../Model-Viewer/ModelViewer2.0";
import Player from "../../../Player/player.component";
import NoResultFound from "../../NoResultFound/NoResultFound";
import ReviewVersionViewerTools from "../ReviewVersionViewerTools/ReviewVersionViewerTools";
import VersionSelector from "../../VersionViewer/VersionSelector/VersionSelector";
import ReviewVersionCommentOverview from "../ReviewVersionCommentOverview/ReviewVersionCommentOverview";
import CanvasFrame from "../../../CanvasFrame/CanvasFrame.component";

const ReviewPageVersionViewerOverview = ({
  projectId,
  onClose, //to close version viewer
  selectedVersion,
  setSelectedVersion,
  versionList,
  updateVersionStatus, //to update version status to completed or revision
  isExternalReviewer,
  loggedInUser,
  allUsersName,
  parentReview,
}) => {
  const classes = useStyles();
  const matchHeight = useMediaQuery("(min-height:755px)");
  const matchWidth = useMediaQuery("(min-width:1240px)");
  const [showBtn, setShowBtn] = useState(false); // to show or hide the status change button
  const [currentStatus, setCurrentStatus] = useState(""); //status marked by each user for each version
  const [seekTime, setSeekTime] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [glbScreenshot, setGlbScreenshot] = useState(false);
  const [allowHdrChange, setAllowHdrChange] = useState(false);
  const [leftArrow, setLeftArrow] = useState(false);
  const [rightArrow, setRightArrow] = useState(false);
  const [file, setFile] = useState(null);
  const [canvasModal, setCanvasModal] = useState(false);
  const [screenshot, setScreenshot] = useState(null);
  const [commentList, setCommentList] = useState({ data: [], loading: true });
  //image screenshot for canvas
  const [imageRef, setImageRef] = useState(null);
  // state for opening fullscreen viewer of images
  const [isOpenFullscreenViewer, setIsOpenFullscreenViewer] = useState(false);
  const [fileForFullscreenViewer, setFileForFullscreenViewer] = useState(null);
  const [playerProgress, setPlayerProgress] = useState("00:00");
  const frameRate = 24;
  const player = useRef(null);
  const playerContainer = useRef(null);
  const versionViewerModalRef = useRef(null);

  const fetchFile = async () => {
    const fetchFileRes = await fget({
      url: `reviewversion/${selectedVersion.id}/file`,
    });
    setFile(fetchFileRes.data.signed_url);
    versionList[versionList.length - 1] === selectedVersion
      ? setRightArrow(true)
      : setRightArrow(false);
    versionList[0] === selectedVersion
      ? setLeftArrow(true)
      : setLeftArrow(false);
  };

  const fetchComments = async () => {
    const commentRes = await fget({
      url: `review-version/${selectedVersion.id}/comments/`,
    });
    setCommentList({ data: commentRes.data.results, loading: false });
  };

  useEffect(() => {
    fetchFile();
    fetchComments();
  }, [selectedVersion]);

  const changeSeekTime = (time) => {
    time = time.trim();
    if (time.includes(":")) {
      var timeInNos = 0; //setting time to 0
      if (time.length === 4) {
        timeInNos +=
          parseInt(time.substr(0, 1)) * 60 + parseInt(time.substr(2, 2));
      } else {
        timeInNos +=
          parseInt(time.substr(0, 2)) * 60 + parseInt(time.substr(3, 2));
      }
      setSeekTime(timeInNos);
    } else {
      setSeekTime(time);
    }
  };

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const seekFiveSecondsForward = () => {
    if (player && player.current) {
      player.current.currentTime = Math.min(
        player.current.duration,
        player.current.currentTime + 5
      );
    }
  };

  const seekFiveSecondsBack = () => {
    if (player && player.current) {
      player.current.currentTime = Math.min(
        player.current.duration,
        player.current.currentTime - 5
      );
    }
  };

  const seekForward = () => {
    if (player && player.current) {
      setPlaying(false);
      player.current.pause();
      player.current.currentTime = Math.min(
        player.current.duration,
        player.current.currentTime + 1 / frameRate
      );
    }
  };

  const seekBack = () => {
    if (player && player.current) {
      setPlaying(false);
      player.current.pause();
      player.current.currentTime = Math.min(
        player.current.duration,
        player.current.currentTime - 1 / frameRate
      );
    }
  };

  const handleFullScreen = () => {
    if (playerContainer.current) {
      if (!document.fullscreenElement) {
        playerContainer.current.requestFullscreen();
        setIsFullScreen(true);
      } else {
        document.exitFullscreen();
        setIsFullScreen(false);
      }
    }
  };

  const handleSeekKeyboardEvent = (event) => {
    switch (true) {
      case event.key === " ":
        handlePlayPause();
        break;
      case !event.shiftKey && !event.ctrlKey && event.key === "ArrowRight":
        seekFiveSecondsForward();
        break;
      case !event.shiftKey && !event.ctrlKey && event.key === "ArrowLeft":
        seekFiveSecondsBack();
        break;
      case event.keyCode === 188 || event.key === ",":
        seekBack();
        break;
      case event.keyCode === 190 || event.key === ".":
        seekForward();
        break;
      case event.key === "f":
        handleFullScreen();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    selectedVersion?.status_by_users?.map((eachUser) => {
      if (eachUser.user.id == loggedInUser?.id) {
        setCurrentStatus(eachUser.status);
      }
    });
  }, [selectedVersion]);

  const showChangeStatusButtons = () => {
    selectedVersion.reviewers.some((user) => {
      if (user.id === loggedInUser.id) {
        setShowBtn(true);
      }
    });
  };

  useEffect(() => {
    versionViewerModalRef.current.focus();
    showChangeStatusButtons();
  }, []);

  const handleOpenCanvas = () => {
    checkGlb(file) ? setGlbScreenshot(true) : setCanvasModal(!canvasModal);
  };
  function handleKeyDown(event) {
    event.stopPropagation();
  }

  return (
    <div
      className={classes.viewerComponent}
      ref={versionViewerModalRef}
      tabIndex={0}
      onKeyDown={handleSeekKeyboardEvent}
    >
      <div className={classes.leftButtonContainer}>
        <Button
          className={classes.leftOrRightButton}
          style={{ color: leftArrow ? "gray" : "#6C65D9" }}
          disabled={leftArrow}
          onClick={() => {
            versionList.map((version, index) => {
              if (version === selectedVersion) {
                setCommentList({ data: [], loading: true });
                setSelectedVersion(versionList[index - 1]);
              }
            });
          }}
        >
          <CaretLeft size={100} weight="bold" fill="#6C65D9" />
        </Button>
      </div>
      <div className={classes.rightButtonContainer}>
        <Button
          className={classes.leftOrRightButton}
          style={{ color: rightArrow ? "gray" : "#6C65D9" }}
          disabled={rightArrow}
          onClick={() => {
            versionList.map((version, index) => {
              if (version.id === selectedVersion.id) {
                setCommentList({ data: [], loading: true });
                setSelectedVersion(versionList[index + 1]);
              }
            });
          }}
        >
          <CaretRight weight="bold" size={100} fill="#6C65D9" />
        </Button>
      </div>
      <ReviewVersionViewerTools
        matchWidth={matchWidth}
        onClose={onClose}
        updateVersionStatus={updateVersionStatus}
        isExternalReviewer={isExternalReviewer}
        currentStatus={currentStatus}
        setCurrentStatus={setCurrentStatus}
        parentReview={parentReview}
        selectedVersion={selectedVersion}
        showBtn={showBtn}
      />
      <div
        style={{
          height: matchHeight ? "90%" : "485px",
          display: "flex",
          justifyContent: matchWidth ? "space-between" : "center",
          padding: "0 20px",
        }}
      >
        <div
          style={{
            width: matchWidth ? "69%" : "820px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {file ? (
            checkImage(file) ? (
              <ImageViewer
                matchHeight={matchHeight}
                setImageRef={setImageRef}
                setIsOpenFullscreenViewer={setIsOpenFullscreenViewer}
                setFileForFullscreenViewer={setFileForFullscreenViewer}
                file={file}
              />
            ) : checkGlb(file) ? (
              file ? (
                <div className={classes.modelViewerDiv}>
                  <ErrorBoundary
                    fallback={<MediaError />}
                    beforeCapture={(scope) => {
                      scope.setTag("location", "Model viewer");
                      scope.setTag("version", selectedVersion);
                    }}
                    showDialog
                  >
                    <ModelViewerLatest
                      versionType={"reviewversion"}
                      fileType={getModelFileType(file)}
                      fileURL={file}
                      glbScreenshot={glbScreenshot}
                      setGlbScreenshot={setGlbScreenshot}
                      allowHdrChange={allowHdrChange}
                      setAllowHdrChange={setAllowHdrChange}
                      setScreenshot={setScreenshot}
                      canvasModalToggle={() => setCanvasModal(!canvasModal)}
                      canvasModal={canvasModal}
                      // move hdr store to parent
                      modelBackgroundList={[]}
                      currentModelBackgroundId={[]}
                      versionObj={selectedVersion}
                      projectId={projectId}
                    />
                  </ErrorBoundary>

                  <div
                    className={`${classes.imageIconColor} ${
                      !allowHdrChange
                        ? classes.allowHrdChange
                        : classes.notAllowed
                    }`}
                  >
                    <div className={classes.imageDiv}>
                      <div>
                        <a href={file} download>
                          <DownloadSimple
                            size={20}
                            className={classes.imageIconColor}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                "File is Loading"
              )
            ) : (
              <div className={classes.player}>
                <Player
                  seekTime={seekTime}
                  setSeekTime={setSeekTime}
                  setPlayerProgress={setPlayerProgress}
                  setScreenshot={setScreenshot}
                  screenshot={screenshot}
                  canvasModal={canvasModal}
                  setCanvasModal={setCanvasModal}
                  data={file}
                  player={player}
                  playing={playing}
                  setPlaying={setPlaying}
                  handlePlayPause={handlePlayPause}
                  seekBack={seekBack}
                  seekForward={seekForward}
                  selectedVersion={selectedVersion?.id}
                  playerContainer={playerContainer}
                  handleFullScreen={handleFullScreen}
                  isFullScreen={isFullScreen}
                  handleSeekKeyboardEvent={handleSeekKeyboardEvent}
                />
              </div>
            )
          ) : (
            <NoResultFound />
          )}

          {/* video player */}
        </div>
        <div
          style={{
            width: matchWidth ? "40%" : "420px",
            display: matchWidth ? "block" : "none",
            marginLeft: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: "20px",
            }}
          >
            Comments <span>{commentList.data.length} Comments</span>
          </div>
          <ReviewVersionCommentOverview
            selectedVersion={selectedVersion}
            fetchComments={fetchComments}
            commentList={commentList}
            loggedInUser={loggedInUser}
            handleOpenCanvas={handleOpenCanvas}
            changeSeekTime={changeSeekTime}
            allUsersName={allUsersName}
          />
        </div>
      </div>
      <div className={classes.versionSelector}>
        <VersionSelector
          selectedVersion={selectedVersion}
          setSelectedVersion={setSelectedVersion}
          setCommentList={setCommentList}
          versionList={{ results: versionList }}
          onVersionChange={{}}
          versionModalRef={versionViewerModalRef}
        />
      </div>
      <div onKeyDown={handleKeyDown}>
        {/* handleSubmit of comments will be different for review versions not handled yet! */}
        <Modal open={canvasModal}>
          <CanvasFrame
            image={screenshot}
            imageRef={imageRef}
            users={[]}
            closeModal={() => setCanvasModal(!canvasModal)}
            toggle={() => setCanvasModal(!canvasModal)}
            progress={playerProgress}
            isReviewVersionViewer={true}
            versionId={selectedVersion.id}
            fetchComments={fetchComments}
          />
        </Modal>
      </div>
      <div>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          BackdropProps={{
            style: { backgroundColor: "black", opacity: "0.7" },
          }}
          open={isOpenFullscreenViewer}
          onClose={() => setIsOpenFullscreenViewer(false)}
        >
          <div
            style={{
              borderRadius: "30px",
              height: "90%",
              width: "90%",
              position: "relative",
              outline: "none",
            }}
          >
            {fileForFullscreenViewer ? (
              <img
                src={fileForFullscreenViewer}
                style={{
                  borderRadius: "30px",
                  height: "100%",
                  width: "100%",
                }}
              />
            ) : (
              "No File Found"
            )}
            <X
              className={classes.closeFullScreen}
              onClick={() => setIsOpenFullscreenViewer(false)}
            />
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ReviewPageVersionViewerOverview;
