import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  shotVersionPageModals: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  shotVersionListTableCell: {
    color: theme.palette.text.primary,
    padding: "0% !important",
    // overflow: "hidden",
    // borderCollapse: "collapse",
    width: "16.25%",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
  },
  shotVersionListTableCellTwo: {
    padding: "0px !important",
    overflow: "hidden",
    borderCollapse: "collapse",
    width: "10%",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
  },
  shotVersionListTableCellThree: {
    padding: "0 1% 0 0 !important",
    overflow: "hidden",
    borderCollapse: "collapse",
    width: "15%",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  chipAndPopupContainer: {
    maxWidth: "100px",
    textAlign: "left",
    position: "relative",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "5px",
  },
  chip: {
    fontSize: "14px",
    minWidth: "20px",
    cursor: "pointer",
  },
  popUp: {
    marginTop: "0px",
    position: "absolute",
    cursor: "auto",
    zIndex: "10 !important",
  },
}));

export default useStyles;
