import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  videoConatiner: {
    width: "100%",
    overflow: "hidden",
    marginTop: "2%",
    height: "75%",
    display:"flex",
  },
  titleContainer:{
    width: "100%",
    height:"10%",
    display: "flex",
    alignItems: "center",
    gap: "12px",
    justifyContent: "space-between",
  },
  controllerContainer: {
    width: "100%",
    height: "15%",
    display:"flex",
    alignItems:"center",
    gap: "16px",
    justifyContent: "center",
    paddingLeft: "2%",
    paddingRight: "2%",
    borderRadius: "0px 0px 15px 15px",
    backgroundColor: theme.palette.primary.main,
  },
}));

export default useStyles;
