import React, { useState, useEffect } from "react";
import useStyles from "./PlaylistOverview.styles";
import PlaylistTool from "../PlayListTool/PlaylistTool.component";
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  Modal,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import NoData from "../../../Pages/InformativeComponents/NoData.component";
import { fdelete, fget, fpost, fpatch } from "../../../API/callsAPI";
import PlaylistItems from "../PlaylistItems/PlaylistItems.component";
import { Pagination } from "@material-ui/lab";
import PlaylistFilterModal from "../PlaylistModals/PlaylistFilterModal";
import SortModal from "../../Utilities/SortModal/SortModal";
import PlaylistCreateNewPlaylistModal from "../PlaylistModals/PlaylistCreateNewPlaylistModal";
import { useHistory } from "react-router-dom";
import PlaylistEditModal from "../PlaylistModals/PlaylistEditModal";
import DeleteModal from "../../Utilities/DeleteModal/DeleteModal.component";
import Loader from "../../Utilities/Loader/Loader";

export default function PlaylistOverview({ params, searchQuery }) {
  const [toggler, setToggler] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [createPlaylistModalOpen, setCreatePlaylistModalOpen] = useState(false);
  const [editPlaylistModalOpen, setEditPlaylistModalOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [playlist, setPlaylist] = useState({});
  const [setcurrentPage] = useState(1);
  const [sortByDefault] = useState("");
  const [sequenceList, setSequenceList] = useState([]);
  const [projectUsers, setProjectUsers] = useState([]);
  const [selectedPlaylist, setSelectedPlaylist] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  const [allUsers, setAllUsers] = useState([]);

  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    fetchPlaylist();
    fetchUsers();
    fetchSequence();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCounter]);

  const updateRefreshCounter = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  const fetchPlaylist = async () => {
    try {
      const playlistRes = await fget({
        url: `sequencers/timelines?project=${params.projectId}`,
      });
      setPlaylist(playlistRes.data);
      console.log(playlistRes.data);
      setIsLoaded(true);
    } catch (error) {
      setIsLoaded(true);
      console.log(error);
    }
  };

  const fetchSequence = async () => {
    const url = `trackables/shot-sequence/?project=${params.projectId}`;

    try {
      const seqRes = await fget({
        url: url,
      });
      setSequenceList(seqRes.data);
      // setIsLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUsers = async () => {
    try {
      const usersRes = await fget({
        url: `project/${params.projectId}/users`,
      });
      //
      let temp = [];
      usersRes.data.results.map((el) =>
        temp.push({
          label: el?.first_name + " " + el?.last_name,
          value: el?.id,
          avatar: el?.avatar,
        })
      );
      setAllUsers(temp);

      if (usersRes.status === 200) {
        setProjectUsers(usersRes.data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createNewPlaylist = async (formData) => {
    let newPlaylist = {
      url: "sequencers/timelines/",
      data: formData,
    };
    try {
      const res = await fpost(newPlaylist);
      if (res.status === 200) {
        updateRefreshCounter();
        setCreatePlaylistModalOpen(false);
        setSnackValue({
          isOpen: true,
          message: `SuccessFully created`,
          isError: false,
        });
      }
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `Oops, some error occured. Please try again`,
        isError: true,
      });
    }
  };

  const handlePlaylistDelete = async (id) => {
    try {
      await fdelete({
        url: `sequencers/timelines/${id}/`,
      });
      updateRefreshCounter();
      setEditPlaylistModalOpen(false);
      setDeleteModalOpen(false);
      setSnackValue({
        isOpen: true,
        message: `SuccessFully deleted`,
        isError: false,
      });
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `Oops, some error occured. Please try again`,
        isError: true,
      });
    }
  };

  const handlePlaylistEdit = async (id, formData) => {
    try {
      const res = await fpatch({
        url: `sequencers/timelines/${id}/`,
        data: formData,
      });
      if (res.status === 200) {
        updateRefreshCounter();
        setEditPlaylistModalOpen(false);
        setSnackValue({
          isOpen: true,
          message: `SuccessFully edited`,
          isError: false,
        });
      }
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `Oops, some error occured. Please try again`,
        isError: true,
      });
    }
  };

  const handleEditModal = (data) => {
    setEditPlaylistModalOpen(true);
    setSelectedPlaylist(data);
  };

  const handleDeleteModal = (data) => {
    setDeleteModalOpen(true);
    setSelectedPlaylist(data);
  };

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackValue.isOpen}
        autoHideDuration={2000}
        onClose={() => setSnackValue({ isOpen: false, message: "" })}
      >
        <Alert severity={snackValue.isError === false ? "success" : "warning"}>
          {snackValue.message}
        </Alert>
      </Snackbar>
      <PlaylistTool
        handleToggler={() => setToggler(!toggler)}
        openFilterModal={() => setFilterModalOpen(true)}
        openCreatePlaylistModal={() => setCreatePlaylistModalOpen(true)}
        onSubmit={() => console.log("hello")}
        defaultvalue={sortByDefault}
      />
      {isLoaded ? (
        <>
          {playlist && playlist.count !== 0 ? (
            <>
              <TableContainer className={classes.tableContainer}>
                <Table aria-label="simple table" className={classes.table}>
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      <TableCell align="left" className={classes.tableCell}>
                        Playlist Name
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        last Modified
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        Users
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        Created By
                      </TableCell>

                      <TableCell align="right" className={classes.tableCell}>
                        <div style={{ marginRight: "10px" }}>Actions</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBody}>
                    {playlist && playlist.count !== 0
                      ? playlist.results
                          ?.filter((search) => {
                            if (searchQuery === undefined) {
                              return search;
                            } else if (searchQuery === "") {
                              return search;
                            } else if (
                              search.name
                                ?.toLowerCase()
                                .includes(searchQuery.toLowerCase())
                            ) {
                              return search;
                            } else return null;
                          })
                          .map((item) => {
                            return (
                              <PlaylistItems
                                data={item}
                                toggler={toggler}
                                onItemSelect={(event) => {
                                  history.push(
                                    `/${params.projectId}/playlist/${item.id}/sequencer`
                                  );
                                }}
                                handleEditModal={handleEditModal}
                                key={item.id}
                              />
                            );
                          })
                      : " No Playlist"}
                  </TableBody>
                </Table>
              </TableContainer>
              {playlist && playlist.count > 50 ? (
                <footer style={{ bottom: "0", height: "100%" }}>
                  <div className={classes.footer}>
                    <Pagination
                      onChange={(e, v) => setcurrentPage(v)}
                      count={Math.ceil(playlist.count / 50)}
                    />
                  </div>
                </footer>
              ) : (
                ""
              )}
            </>
          ) : (
            <NoData />
          )}
        </>
      ) : (
        <Loader />
      )}
      <div>
        <Modal
          className={classes.playListPageModals}
          open={filterModalOpen}
          onClose={() => setFilterModalOpen(false)}
        >
          <PlaylistFilterModal
            handleCloseModal={() => setFilterModalOpen(false)}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.playListPageModals}
          open={createPlaylistModalOpen}
          onClose={() => setCreatePlaylistModalOpen(false)}
        >
          <PlaylistCreateNewPlaylistModal
            handleCloseModal={() =>
              setCreatePlaylistModalOpen(!createPlaylistModalOpen)
            }
            projectUsers={projectUsers}
            allUsers={allUsers}
            sequenceList={sequenceList}
            project={params.projectId}
            createNewPlaylist={createNewPlaylist}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.playListPageModals}
          open={editPlaylistModalOpen}
          onClose={() => setEditPlaylistModalOpen(false)}
        >
          <PlaylistEditModal
            handleCloseModal={() =>
              setEditPlaylistModalOpen(!editPlaylistModalOpen)
            }
            handleDeleteModal={handleDeleteModal}
            selectedPlaylist={selectedPlaylist}
            handlePlaylistEdit={handlePlaylistEdit}
            project={params.projectId}
            sequenceList={sequenceList}
            allUsers={allUsers}
          />
        </Modal>
      </div>
      <div>
        <Modal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          className={classes.playListPageModals}
        >
          <DeleteModal
            name={selectedPlaylist.name}
            type="Playlist"
            handleCloseDeleteModal={() => setDeleteModalOpen(false)}
            handleDelete={() => {
              handlePlaylistDelete(selectedPlaylist.id).then((res) => {
                setDeleteModalOpen(false);
              });
            }}
          />
        </Modal>
      </div>
    </div>
  );
}
