import {
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
  CircularProgress,
  Modal,
  Divider,
} from "@material-ui/core";
import { Asterisk } from "phosphor-react";
import React, { useEffect } from "react";
import useStyles from "./ProjectPageNewProjectModal.styles";

import { fpatch, fpost } from "./../../../API/callsAPI";
import PropTypes from "prop-types";
//Validators
import { useFormik } from "formik";
import * as yup from "yup";
import ModalTitleAndButtons from "../../Utilities/ModalTitleAndButtons/ModalTitleAndButtons.component";
import UserViewerForManageUsers from "../../Utilities/ManageUsers/UsersViewerForManageUsers/UserViewerForManageUsers.component";
import { useState } from "react";
import SelectUserModal from "../../Utilities/ManageUsers/SelectUsersModal/SelectUserModal.component";
import { X } from "react-feather";
import HelperText from "../../Utilities/HelperText/HelperText";
//
export default function ProjectPageNewProjectModal({
  type,
  project,
  handleClose,
  updateRefreshToken,
  setSnackValue,
  usersList,
  openDeleteProjectModal,
}) {
  const classes = useStyles();

  const [thumbnail, uploadThumbnailSet] = React.useState();
  const [thumbnailTouched, setThumbnailTouch] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  const [projectUsers, setProjectUsers] = useState([]);
  const [projectAdmin, setProjectAdmin] = useState([]);
  const [isSelectAdminModalOpen, setSelectAdminModalOpen] = useState(false);
  const [isSelectUserModalOpen, setSelectUserModalOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleThumbnailUpload = (e) => {
    let file = e.target.files[0];
    uploadThumbnailSet(file);
    setThumbnailTouch(true);
  };

  const removeThumbnail = () => {
    uploadThumbnailSet(null);
    setThumbnailTouch(false);
    setIsHovered(false);
  };

  const handleSelectAdmin = (admins) => {
    setProjectAdmin(admins);
  };

  const handleSelectUser = (users) => {
    setProjectUsers(users);
  };

  const handleSelectAdminModalState = () => {
    setSelectAdminModalOpen(!isSelectAdminModalOpen);
  };

  const handleSelectUserModalState = () => {
    setSelectUserModalOpen(!isSelectUserModalOpen);
  };

  useEffect(() => {
    const admins = usersList?.filter((el) =>
      project?.admin_users?.includes(el.value)
    );
    const users = usersList?.filter((el) => project?.users?.includes(el.value));
    setProjectAdmin(admins);
    setProjectUsers(users);
    if (project?.thumbnail?.length > 0) {
      console.log("true");
      setThumbnailTouch(true);
    }
  }, [project]);

  //Validators
  const NewProjectValidationSchema = yup.object({
    projectName: yup
      .string("String required")
      .label("projectName")
      .min(1, "Too Short!")
      .max(50, "Maximum Character Limit: 50")
      .required("This is a required field"),

    abbreviation: yup
      .string("String required")
      .label("abbreviation")
      .max(15, "Maximum Character Limit: 15")
      .required("This is a required field"),

    description: yup
      .string("String required")
      .label("description")
      .max(100, "Maximum Character Limit: 100")
      .required("This is a required field"),
  });
  const formik = useFormik({
    initialValues: {
      projectName: project?.name ? project?.name : "",
      abbreviation: project?.abbreviation ? project?.abbreviation : "",
      description: project?.description ? project?.description : "",
    },
    validationSchema: NewProjectValidationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();

      setLoader(true);
      formData.append("name", values.projectName);
      formData.append("abbreviation", values.abbreviation);
      formData.append("description", values.description);
      thumbnail && formData.append("thumbnail", thumbnail);
      if (projectAdmin.length > 0) {
        for (let i = 0; i < projectAdmin.length; i++) {
          formData.append("admin_users", projectAdmin[i]?.value);
        }
      }
      if (projectUsers.length > 0) {
        for (let i = 0; i < projectUsers.length; i++) {
          formData.append("users", projectUsers[i]?.value);
        }
      }

      try {
        if (type === "New") {
          const newProject = {
            url: "project/",
            data: formData,
          };
          const newProjectRes = await fpost(newProject);
          if (newProjectRes.status === 200 || 201) {
            setLoader(false);
            setSnackValue({
              isOpen: true,
              message: `SuccessFully Created "${newProjectRes.data.name}"`,
              isError: false,
            });
          }
          updateRefreshToken();
          handleClose();
        } else {
          const editProject = {
            url: `project/${project.id}/`,
            data: formData,
          };
          const editProjectRes = await fpatch(editProject);
          if (editProjectRes.status === 200 || 201) {
            setLoader(false);
            setSnackValue({
              isOpen: true,
              message: `SuccessFully edited "${editProjectRes.data.name}"`,
              isError: false,
            });
          }
          updateRefreshToken();
          handleClose();
        }
      } catch (error) {
        setLoader(false);
        setSnackValue({
          isOpen: true,
          message: `${error}`,
          isError: true,
        });
      }
    },
  });

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <form onSubmit={formik.handleSubmit}>
            <ModalTitleAndButtons
              name="Project"
              type={type}
              closeModal={() => handleClose()}
              openDeleteModal={openDeleteProjectModal}
            />

            <div className={classes.imageInputAndNameAndAbbreviation}>
              {thumbnailTouched ? (
                <div
                  className={classes.imageViewerDiv}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <div
                    className={classes.removeThumbnailIcon}
                    onClick={() => removeThumbnail()}
                    style={{
                      display: isHovered ? "flex" : "none",
                    }}
                  >
                    <X size={18} />
                  </div>
                  <div className={classes.imageContentDiv}>
                    <img
                      className={classes.projectThumbnail}
                      src={
                        thumbnail
                          ? URL.createObjectURL(thumbnail)
                          : project?.thumbnail
                      }
                    />
                  </div>
                </div>
              ) : (
                <div className={classes.cardContentDiv2}>
                  <input
                    type="file"
                    className={classes.imageInput}
                    accept="image/png, image/jpeg"
                    id="thumbnailUpload"
                    onChange={(e) => handleThumbnailUpload(e)}
                  />
                </div>
              )}

              <div style={{ marginLeft: "3%", width: "70%" }}>
                <div>
                  <span className={classes.inputLabel}>Project Name</span>
                  <Asterisk size={12} color="#FF0000" weight="fill" />
                  <div className={classes.cardContentDiv3}>
                    <TextField
                      type="text"
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontFamily: "Poppins",
                        },
                      }}
                      className={classes.cardContentInput1}
                      placeholder="Enter project name"
                      name="projectName"
                      onChange={formik.handleChange}
                      value={formik.values.projectName}
                      error={
                        formik.touched.projectName &&
                        Boolean(formik.errors.projectName)
                      }
                    />
                    {formik.errors.projectName && formik.touched.projectName ? (
                      <HelperText text={formik.errors.projectName} />
                    ) : null}
                  </div>
                </div>
                <div style={{ marginTop: "6%" }}>
                  <span className={classes.inputLabel}>
                    Project Abbreviation
                  </span>
                  <Asterisk size={12} color="#FF0000" weight="fill" />
                  <div className={classes.cardContentDiv3}>
                    <TextField
                      type="text"
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontFamily: "Poppins",
                        },
                      }}
                      className={classes.cardContentInput1}
                      placeholder="Enter Abbreviation"
                      name="abbreviation"
                      onChange={formik.handleChange}
                      value={formik.values.abbreviation}
                      error={
                        formik.touched.abbreviation &&
                        Boolean(formik.errors.abbreviation)
                      }
                    />
                    {formik.errors.abbreviation &&
                    formik.touched.abbreviation ? (
                      <HelperText text={formik.errors.abbreviation} />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.cardContentDiv4}>
              <span className={classes.inputLabel}>Description</span>
              <Asterisk size={12} color="#FF0000" weight="fill" />
              <div>
                <TextField
                  type="text"
                  id="desc"
                  placeholder="Enter a project description"
                  className={classes.cardContentInput1}
                  style={{ height: "70px", overflow: "auto" }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      fontFamily: "Poppins",
                    },
                  }}
                  name="description"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  multiline
                />
                {formik.errors.description && formik.touched.description ? (
                  <HelperText text={formik.errors.description} />
                ) : null}
              </div>
            </div>
            <div className={classes.label}>
              <label>Select Admin</label>
              <Divider flexItem className={classes.divider} />
            </div>
            <UserViewerForManageUsers
              currentUsers={projectAdmin}
              handleSelectUserModalChange={handleSelectAdminModalState}
              handleSelectUsers={handleSelectAdmin}
            />

            <div className={classes.label}>
              <label>Select Users</label>
              <Divider flexItem className={classes.divider} />
            </div>
            <UserViewerForManageUsers
              currentUsers={projectUsers}
              handleSelectUserModalChange={handleSelectUserModalState}
              handleSelectUsers={handleSelectUser}
            />
            <CardActions className={classes.cardAction}>
              <Button
                className={classes.doneButton}
                type="submit"
                value="Submit"
                size="small"
                disableElevation
              >
                {loader ? (
                  <CircularProgress style={{ width: "25px", height: "25px" }} />
                ) : (
                  <>{type === "New" ? "Create New Project" : "Done Editing"}</>
                )}
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
      <div>
        {/* to select admins */}
        <Modal
          className={classes.modal}
          open={isSelectAdminModalOpen}
          onClose={() => setSelectAdminModalOpen(!isSelectAdminModalOpen)}
        >
          <SelectUserModal
            closeModal={() => setSelectAdminModalOpen(!isSelectAdminModalOpen)}
            assignedUser={projectAdmin}
            usersList={usersList}
            handleSelectUsers={handleSelectAdmin}
          />
        </Modal>
        {/* to select users */}
        <Modal
          className={classes.modal}
          open={isSelectUserModalOpen}
          onClose={() => setSelectUserModalOpen(!isSelectUserModalOpen)}
        >
          <SelectUserModal
            closeModal={() => setSelectUserModalOpen(!isSelectUserModalOpen)}
            assignedUser={projectUsers}
            usersList={usersList}
            handleSelectUsers={handleSelectUser}
          />
        </Modal>
      </div>
    </div>
  );
}

ProjectPageNewProjectModal.propTypes = {
  handleClose: PropTypes.func,
};
