import { React } from "react";
import useStyles from "./StatusColor.style.js";
export default function StatusColor({ color }) {
  const classes = useStyles();
  return (
    <div
      style={{
        backgroundColor: `rgb(${color})`,
      }}
      className={classes.statusColor}
    ></div>
  );
}
