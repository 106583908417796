import React, { useState } from "react";
import { useLocation } from "react-router";
import { TableRow, Button, Tooltip, TableCell } from "@material-ui/core";
import useStyles from "../TaskPageListViewOverview.styles";
import { CaretDown, CaretUp } from "phosphor-react";
import TaskPageListItems from "./TaskPageListItems";
import { useEffect } from "react";
import ExpandAllIcon from "../../../../Utilities/Svg/ExpandAllIcon";

export default function TaskPageListGrouped({
  groupedTask,
  taskStatus,
  projectUsers,
  departments,
  scrollValue,
  handleTaskUpdate,
  updateRefreshCounter,
  handleEditTaskModal,
  handleViewTaskModal,
  setSelectedTrackable,
  selectedTrackable,
}) {
  const classes = useStyles();
  const location = useLocation();
  const [hideItems, setHideItems] = useState(false);

  useEffect(() => {
    if (selectedTrackable === "none") {
      setHideItems(false);
    } else {
      if (selectedTrackable === groupedTask?.trackable) {
        setHideItems(false);
      } else {
        setHideItems(true);
      }
    }
  }, [selectedTrackable]);

  const openTrackableInNewTab = () => {
    if (groupedTask.linkedClass === "Asset") {
      location.pathname = `/${departments[0].project}/assets/${groupedTask.trackable}/assetversions`;
      window.open(`${location.pathname}`);
    } else {
      location.pathname = `/${departments[0].project}/sequence/${groupedTask.tasks[0].linked.parent_sequence}/shots/${groupedTask.trackable}/shotversions`;
      window.open(`${location.pathname}`);
    }
  };

  return (
    <>
      <TableRow hover={false} className={classes.borderBottom}>
        <TableCell colSpan={10} style={{ padding: "0px" }}>
          <div className={classes.buttonContainer}>
            <Button
              className={classes.trackableName}
              onClick={() => openTrackableInNewTab()}
            >
              <span>{groupedTask.trackableName}</span>
            </Button>
            {selectedTrackable === groupedTask?.trackable ? (
              <Tooltip title="Expand all trackable" placement="top">
                <Button
                  onClick={() => setSelectedTrackable("none")}
                  style={{ minWidth: "10px" }}
                >
                  <ExpandAllIcon />
                </Button>
              </Tooltip>
            ) : (
              <Tooltip title="Collapse other trackables" placement="top">
                <Button
                  onClick={() => setSelectedTrackable(groupedTask?.trackable)}
                  style={{ minWidth: "10px" }}
                >
                  <ExpandAllIcon />
                </Button>
              </Tooltip>
            )}
            <Tooltip
              title={
                hideItems
                  ? `Expand ${groupedTask?.trackableName}`
                  : `Collapse ${groupedTask?.trackableName}`
              }
              placement="top"
            >
              <Button
                onClick={() => setHideItems(!hideItems)}
                style={{ minWidth: "10px" }}
              >
                {hideItems ? <CaretDown size={20} /> : <CaretUp size={20} />}
              </Button>
            </Tooltip>
          </div>
        </TableCell>
      </TableRow>
      {groupedTask.tasks.map((singleTask) => (
        <TaskPageListItems
          key={singleTask?.id}
          handleViewTaskModal={(task) =>
            handleViewTaskModal(task, groupedTask.tasks)
          }
          singleTask={singleTask}
          statusList={taskStatus}
          currStatus={singleTask.status}
          updateRefreshCounter={updateRefreshCounter}
          hideItems={hideItems}
          handleEditTaskModal={handleEditTaskModal}
          handleTaskUpdate={handleTaskUpdate}
          projectUsers={projectUsers}
          departments={departments}
          scrollValue={scrollValue}
        />
      ))}
    </>
  );
}
