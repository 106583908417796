import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontFamily: "Poppins",
    fontSize: "14px",
  },
  divContainer: {
    display: "flex",
    gap: "24px",
  },
  innerLeftContainer: {
    display: "flex",
    gap: "12px",
  },
  manageReviewBtn: {
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: "10px",
    width: "183px",
    height: "46px",
  },
  addReviewBtn: {
    backgroundColor: theme.palette.secondary.main,
    height: "46px",
    width: "auto",
    borderRadius: "10px",
    fontSize: "14px",
    padding: "12px",
    color: theme.palette.text.secondary,
  },
  divider: {
    background: "#606479",
    marginTop: "12px",
  },
  heading: {
    margin: "0px",
    fontSize: "24px",
    color: theme.palette.text.tertiary,
  },
}));

export default useStyles;
