import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "370px",
    maxWidth: "450px",
    "& .MuiFormHelperText-root": {
      color: "#ffcc00",
    },
  },
  card: {
    borderRadius: "10px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.tertiary,
  },
  cardContent: {
    backgroundColor: theme.palette.primary.main,
    position:"relative"
  },
  title: {
    color: theme.palette.text.primary,
  },
  closeIcon: {
    color: theme.palette.text.primary,
    cursor: "pointer"
  },
  displayFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  cardContentDiv1: {
    justifyContent: "space-around",
  },
  dropDown:{
    backgroundColor:theme.palette.secondary.light
  },
  cardContentDiv2: {
    backgroundColor: theme.palette.secondary.light,
    padding: "4px",
    borderRadius: "10px",
  },

  cardContentDiv3: {
    backgroundColor: theme.palette.secondary.light,
    width: "170px",
    height: "45px",
    padding: "0px 8px",
    borderRadius: "10px",
  },

  labelText: {
    display: "block",
    borderRadius: "5px",
    cursor: "pointer",
    textAlign: "center",
  },

  fileName: {
    paddingTop: "5px",
    marginLeft: "8px",
    fontSize: "0.85rem",
    color: theme.palette.text.primary,
  },

  cardContentInput1: {
    padding: "0.2px 6px",
  },

  done: {
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    width: "100%",
    textTransform: "none",
    padding: "6px",
    borderRadius: "7px",
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.text.primary,
    },
  },
  cardAction: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    padding: "0"
  },
  addNewStatusButton: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "10px",
  },
}));

export default useStyles;
