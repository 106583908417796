import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  sequencerToolRoot: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dropdownContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: "20px",
    alignItems: "center",
  },
  displayFlexAlignCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dropDownSelect: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "10px",
    padding: "2% 3%",
    width: "160px",
  },
  menuItem: {
    backgroundColor: theme.palette.primary.main,
  },
  selectedSequence: {
    position: "absolute",
    bottom: "0px",
    width: "98%",
    overflowY: "hidden",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "10px",
    margin: "5px 5px 5px 0px",
  },
  switchDiv: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
}));

export default useStyles;
