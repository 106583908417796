import { Card, CardContent, Grid } from "@material-ui/core";
import React from "react";
import NoResultMessage from "../../../../Utilities/NoResultFound/NoResultMessage";
import UsersViewer from "../../../../Utilities/UsersViewer/UsersViewer";
import useStyles from "./ProjectHealthWidgets.style";

const DepartmentWisePerformance = ({ healthData, projectId }) => {
  const classes = useStyles();
  return (
    <div className={classes.deptRoot}>
      <div className={classes.departmentWiseText}>
        Department Wise Performance
      </div>
      {healthData?.task_completion?.length ? (
        <Grid container spacing={1} className={classes.departments}>
          {healthData?.task_completion?.map((singleDept) => {
            return (
              <Grid item md={12} lg={6}>
                <Card
                  className={classes.singleDepartmentCard}
                  onClick={() =>
                    window.open(
                      `/${projectId}/departments/${singleDept?.department?.id}/department`
                    )
                  }
                >
                  <CardContent
                    className={classes.singleDepartmentCardComponent}
                  >
                    <span className={classes.departmentName}>
                      {singleDept?.department?.name ?? ""}
                    </span>
                    <div className={classes.flexCenter}>
                      {singleDept?.department?.users?.length ? (
                        <UsersViewer
                          assignedUser={singleDept?.department?.users}
                          selectedTask={{
                            assigned_users: singleDept?.department?.users,
                          }}
                          editable="no"
                        />
                      ) : (
                        "No User"
                      )}
                    </div>
                    {/*  <LinearProgress
                    variant="determinate"
                    color="primary"
                    value={90}
                    style={{
                      color: "red",
                      backgroundColor: "#1B1D28",
                    }}
                  /> */}
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <div style={{ height: "70%" }} className={classes.flexCenter}>
          <NoResultMessage name="Department" iconSize={30} fontSize="18px" />
        </div>
      )}
    </div>
  );
};

export default DepartmentWisePerformance;
