import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
export default function Loader() {
  return (
    <div
      style={{
        height: "400px",
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ScaleLoader
        color="#6C65D9"
        height={55}
        widht={15}
        style={{
          display: "flex",
          verticalAlign: "middle",
        }}
      />
    </div>
  );
}
