import { Button, Grid } from "@material-ui/core";
import { Reorder, useDragControls } from "framer-motion";
import { Circle, DotsNine, Triangle } from "phosphor-react";
import React from "react";
import useStyles from "./SingleCardForRearrange.style.js";

const SingleCardForRearrange = ({ itemsList, index, item, departments }) => {
  const classes = useStyles();
  const dragControls = useDragControls();
  return (
    <Reorder.Item
      value={item}
      style={{ width: "100%", listStyleType: "none" }}
      dragControls={dragControls}
      dragListener={true}
    >
      <div className={classes.singleCardContainer}>
        {/* <div className={classes.flexBetween}>
          <div>{item?.name}</div>
          <div className={classes.textGray}>Days - {item?.number_of_days}</div>
          <div className={classes.textGray}>
            {departments?.find((dep) => dep.id === item?.dept)?.name}
          </div>
          <Button className={classes.textGray} dragControls={dragControls}>
            <DotsNine size={25} />
          </Button>
        </div> */}
        <Grid container style={{ alignItems: "center" }}>
          <Grid item xs={3} style={{ textAlign: "start" }}>
            <div>{item?.name}</div>
          </Grid>
          <Grid item xs={3} style={{ textAlign: "center" }}>
            <div className={classes.textGray}>
              Days - {item?.number_of_days}
            </div>
          </Grid>
          <Grid item xs={3} style={{ textAlign: "end" }}>
            <div className={classes.textGray}>
              {departments?.find((dep) => dep.id === item?.dept)?.name}
            </div>
          </Grid>
          <Grid item xs={3} style={{ textAlign: "end" }}>
            <Button className={classes.textGray} dragControls={dragControls}>
              <DotsNine size={25} />
            </Button>
          </Grid>
        </Grid>
      </div>
      <div
        className={classes.customArrow}
        style={{
          display: itemsList.length - 1 === index ? "none" : "flex",
        }}
      >
        <Circle className={classes.circle} weight="fill" />
        <div className={classes.line}></div>
        <Triangle className={classes.triangle} weight="fill" />
      </div>
    </Reorder.Item>
  );
};

export default SingleCardForRearrange;
