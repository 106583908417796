import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  listText: {
    color: theme.palette.text.primary,
    padding: "2px",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
    width: "14.2%",
  },
  actionBtn: {
    minWidth: "0px",
    padding: "5px",
  },
  reviewVersionListModals: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  avatar: {
    height: "30px",
    width: "30px",
    textAlign: "center",
    borderRadius: "50%",
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.primary,
    fontWeight: "400 !important",
    fontSize: "15px",
  },
  userImage: {
    objectFit: "contain",
    height: "35px",
    width: "35px",
    borderRadius: "50%",
  },
  arrowIconCircle: {
    padding: "2px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    border: `1px solid ${theme.palette.text.tertiary}`,
  },
  avatarWithName: {
    border: `2px solid ${theme.palette.text.tertiary}`,
    display: "flex",
    alignItems: "center",
    gap: "5px",
    borderRadius: "25px",
    padding: "0px 5px",
  },
}));

export default useStyles;
