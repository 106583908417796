import React, { useState } from "react";
import {
  Card,
  CardContent,
  Button,
  Modal,
  CircularProgress,
} from "@material-ui/core";
import * as Icon from "react-feather";
import useStyles from "./ManageUsersModal.style.js";
import PropTypes from "prop-types";
import UserViewerForManageUsers from "../UsersViewerForManageUsers/UserViewerForManageUsers.component.jsx";
import SelectUserModal from "../SelectUsersModal/SelectUserModal.component.jsx";

export default function ManageUsersModal({
  submitManageUsersChanges,
  closeModal,
  projectAdmin,
  projectUsers,
  usersList,
  handleSelectAdmin,
  handleSelectUser,
  isUpdating,
}) {
  const classes = useStyles();

  const [isSelectAdminModalOpen, setSelectAdminModalOpen] = useState(false);
  const [isSelectUserModalOpen, setSelectUserModalOpen] = useState(false);

  function handleSelectAdminModalState() {
    setSelectAdminModalOpen(!isSelectAdminModalOpen);
  }

  function handleSelectUserModalState() {
    setSelectUserModalOpen(!isSelectUserModalOpen);
  }

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <form
            enctype="multipart/form-data"
            onSubmit={submitManageUsersChanges}
          >
            <div className={classes.displayFlex}>
              <span className={classes.title}>Manage Users</span>
              <div>
                <Icon.X
                  size="30px"
                  className={classes.closeIcon}
                  onClick={() => closeModal()}
                />
              </div>
            </div>
            <div className={classes.label}>
              <label>Select Admin</label>
            </div>
            <UserViewerForManageUsers
              currentUsers={projectAdmin}
              handleSelectUserModalChange={handleSelectAdminModalState}
              handleSelectUsers={handleSelectAdmin}
            />

            <div className={classes.label}>
              <label>Select Users</label>
            </div>
            <UserViewerForManageUsers
              currentUsers={projectUsers}
              handleSelectUserModalChange={handleSelectUserModalState}
              handleSelectUsers={handleSelectUser}
            />

            <br />

            {isUpdating ? (
              <div className={classes.circularProgress}>
                <CircularProgress color="success" />
              </div>
            ) : (
              <div className={classes.cardAction}>
                <Button
                  type="submit"
                  value="Submit"
                  size="small"
                  disableElevation
                  disabled={isUpdating ? true : false}
                >
                  Done
                </Button>
              </div>
            )}
          </form>
        </CardContent>
      </Card>
      <div>
        <Modal
          className={classes.modal}
          open={isSelectAdminModalOpen}
          onClose={() => setSelectAdminModalOpen(!isSelectAdminModalOpen)}
        >
          <SelectUserModal
            closeModal={() => setSelectAdminModalOpen(!isSelectAdminModalOpen)}
            assignedUser={projectAdmin}
            usersList={usersList}
            handleSelectUsers={handleSelectAdmin}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.modal}
          open={isSelectUserModalOpen}
          onClose={() => setSelectUserModalOpen(!isSelectUserModalOpen)}
        >
          <SelectUserModal
            closeModal={() => setSelectUserModalOpen(!isSelectUserModalOpen)}
            assignedUser={projectUsers}
            usersList={usersList}
            handleSelectUsers={handleSelectUser}
          />
        </Modal>
      </div>
    </div>
  );
}
ManageUsersModal.propTypes = {
  projectId: PropTypes.number,
  project: PropTypes.array,
  closeModal: PropTypes.func,
};
