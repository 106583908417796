import React from "react";

const ClockPlus = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.0625 5C6.755 5 6.5 5.255 6.5 5.5625V9.08C6.5 9.3425 6.635 9.5825 6.8525 9.7175L9.5825 11.3975C9.70384 11.4711 9.84944 11.4935 9.98728 11.4597C10.1251 11.426 10.2439 11.3388 10.3175 11.2175C10.3911 11.0962 10.4135 10.9506 10.3797 10.8127C10.346 10.6749 10.2588 10.5561 10.1375 10.4825L7.625 8.975V5.5625C7.625 5.255 7.37 5 7.0625 5Z"
        fill="#606479"
      />
      <path
        d="M12.44 8C12.5531 8.74545 12.5032 9.50656 12.2939 10.2309C12.0846 10.9552 11.7207 11.6256 11.2275 12.1958C10.7342 12.7661 10.1232 13.2227 9.43659 13.5341C8.74995 13.8456 8.00396 14.0045 7.25 14C4.325 14 2 11.675 2 8.75C2 5.825 4.325 3.5 7.25 3.5C7.775 3.5 8.2775 3.575 8.75 3.7175V2.1725C8.27 2.06 7.7675 2 7.25 2C3.5 2 0.5 5 0.5 8.75C0.5 12.5 3.5 15.5 7.25 15.5C8.20076 15.5039 9.14155 15.3062 10.0102 14.9197C10.8789 14.5333 11.6558 13.9669 12.2894 13.2581C12.9231 12.5493 13.3992 11.7141 13.6863 10.8077C13.9734 9.90136 14.065 8.94438 13.955 8H12.44Z"
        fill="#606479"
      />
      <path
        d="M15.5 2.75H14V1.25C14 0.8375 13.6625 0.5 13.25 0.5C12.8375 0.5 12.5 0.8375 12.5 1.25V2.75H11C10.5875 2.75 10.25 3.0875 10.25 3.5C10.25 3.9125 10.5875 4.25 11 4.25H12.5V5.75C12.5 6.1625 12.8375 6.5 13.25 6.5C13.6625 6.5 14 6.1625 14 5.75V4.25H15.5C15.9125 4.25 16.25 3.9125 16.25 3.5C16.25 3.0875 15.9125 2.75 15.5 2.75Z"
        fill="#606479"
      />
    </svg>
  );
};

export default ClockPlus;
