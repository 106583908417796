//to-do.
// figure out how much seconds in visible and how much to calculate
// figure out scroll bar first.

// zoom value, pan position, totalDuration,

// function generateTimelineMarkers(panPosition, zoomValue, totalDuration, canvasWidth, lineGap){
//     ctx.clearRect(0, 0, 1500, 40);
//     setLineGap(linegap + zoomLevel);
//     const diff =
//       Math.ceil(diffCounter / 5) * 5 <= 0 ? 1 : Math.ceil(diffCounter / 5) * 5;
//     for (let i = 0; i < duration; i++) {
//       if (i % diff === 0) {
//         ctx.fillRect(i * lineGap - panPosition.x, 15, 1, 40);
//         ctx.fillStyle = "#606479";
//         ctx.fillText(i, i * lineGap - panPosition.x, 10);
//         if (diff === 1 && zoomLevel >= 100) {
//           for (let iFPS = 1; iFPS <= 24; iFPS++) {
//             const localLineGap = lineGap / 24;
//             ctx.fillRect(
//               i * lineGap + localLineGap * iFPS - panPosition.x,
//               25,
//               1,
//               40
//             );
//             // ctx.fillText(iFPS, i * lineGap + localLineGap * iFPS, 20);
//           }
//         }
//       }
//       if (i % diff !== 0) {
//         if (i % 1 === 0) {
//           ctx.fillRect(i * lineGap - panPosition.x, 25, 1, 40);
//         }
//       }
//     }
//     ctx.fillStyle = "#000000";
//     ctx.fillRect(0, 35, 1500, 5);
//     ctx.fillStyle = "#606479";
//     ctx.fillRect(panPosition.x, 35, 1500 / zoomLevel, 5);
// }

//edge case for shorts with same start_frame needs to be addressed.
export function generateArrayOfShots(shotReps, layers) {
  const localShotReps = {};
  const arrayOfLayerId = layers.map((each) => each.id).reverse();
  //ids are reversed so that layer position is changed
  for (let i = 0; i < shotReps.length; i++) {
    localShotReps[shotReps[i].start_frame] = {
      ...shotReps[i],
      selected: false,
      layerPosition: arrayOfLayerId.indexOf(shotReps[i].layer.id) + 1 || 0,
    };
  }
  return localShotReps;
}
