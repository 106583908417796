import React, { useEffect, useState } from "react";
import SequencerVersionBrowserOverview from "../SequencerVersionBrowser/SequencerVersionBrowserOverview/SequencerVersionBrowserOverview.component";
import SequencerPlayer from "../SequencerPlayer/SequencerPlayer";
import SequencerTimeLine from "../SequenecerTimeline/SequencerTimeLine";
import { fget, fpatch, fpost } from "../../../API/callsAPI.js";
import { ReArrangeShots } from "../SequencerUtility/ReArrangeShots";
import { generateArrayOfShots } from "../SequenecerTimeline/TimelineUtility";
import useStyles from "./SequencerOverview.style.js";
import SequencerVersionSwitcherModal from "../SequencerPageModals/SequencerVersionSwitcherModal/SequencerVersionSwitcherModal.component";
import { Modal, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import SequencerPlayerV2 from "../SequencerPlayer/SequencerPlayerv2";

export default function SequencerOverview({ params }) {
  const classes = useStyles();
  const [statusList, setStatusList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sequencerData, setSequencerData] = useState({
    currentTimeline: {},
    layers: [],
    shotReps: [],
  });
  //shot-reps that seats at client side.
  // const [shotReps, setShotReps] = useState([]);
  const [currentGlobalTime, setCurrentGlobalTime] = useState(0);
  const [totalDurationTimeline, setTotalDurationTimeline] = useState(0);
  const [seekToTime, setSeekToTime] = useState(0);
  const [fps, setFps] = useState(24);
  const [shotsLineUp, setShotsLineUp] = useState([]);
  //local state for controlling timeline behaviour
  const [timelineShotReps, setTimelineShotReps] = useState({});
  //state to updateTimeline draw
  const [timelineShotCounterUpdate, setTimelineShotCounterUpdate] = useState(0);
  const [isPaused, setIsPaused] = useState(true);
  //state to keep version switcher modal data
  const [versionSwitcherModalData, setVersionSwitcherModalData] = useState({
    selectedLayer: {},
    selectedShotId: null,
    selectedShotVersionId: null,
    isVersionSwitcherModalOpen: false,
  });
  const [refreshCounter, setRefreshCounter] = useState(0);
  //selection of shot on click via timeline.
  const [selectedShot, setSelectedShot] = useState({});
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  //update refresh counter to reload data
  const updateRefreshCounter = () => {
    setRefreshCounter(refreshCounter + 1);
  };
  //state to call specific apis
  useEffect(() => {
    //make API calls to fetch the timelines and shot-reps.
    //once the api call is done generate the timeline and version browser.
    setIsLoading(true);
    try {
      async function fetchPlaylistContent() {
        const resTimeline = await fget({
          url: `sequencers/timelines/${params.playlistId}`,
        });
        const resLayers = await fget({
          url: `sequencers/layers/?project=${params.projectId}&timeline=${params.playlistId}`,
        });
        const resShotReps = await fget({
          url: `sequencers/shot-reprs/?layer__timeline=${resTimeline.data.id}`,
        });
        //sort layer based on zindex
        function sortLayers(layers) {
          return layers.sort((a, b) => a.z_index - b.z_index);
        }
        setSequencerData({
          currentTimeline: resTimeline?.data || {},
          layers: sortLayers(resLayers.data.results) || [],
          shotReps: resShotReps.data.results || [],
        });
        setIsLoading(false);
      }
      fetchPlaylistContent();
      //once the data is fetched we consume it and convert the shot-reps into lineup of video to be player sequentially.
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
    return () => {};
  }, [refreshCounter]);

  useEffect(() => {
    if (sequencerData.shotReps.length) {
      ReArrangeShots(sequencerData.shotReps);
    }
    return () => {};
  }, [sequencerData.shotReps]);

  useEffect(() => {
    //effect to get signed urls initially and generate local shots for timeline!
    //fetch for first 5-10.
    if (sequencerData.shotReps.length) {
      const localLineUp = [];
      const shotReps = sequencerData.shotReps;
      //fetch the signed url first;
      //extract ids of shots.
      const ArrayOfShotId = shotReps.map((eachShot) => eachShot.shot_version);
      //fetch
      try {
        async function generateShots() {
          const result = await fetchShotSources(ArrayOfShotId);
          let prevFps = 0;
          for (let i = 0; i < sequencerData.shotReps.length; i++) {
            prevFps = shotReps[i - 1]?.end_frame || 0;
            localLineUp[i] = {
              id: shotReps[i].id,
              index: i,
              start: 0,
              end: (shotReps[i].end_frame - shotReps[i].start_frame) / fps,
              fpsStart: shotReps[i].start_frame,
              fpsEnd: shotReps[i].end_frame,
              label: shotReps[i].shot_version,
              shotVersion: shotReps[i].shot_version,
              sources: result[shotReps[i].shot_version].signed_url,
              previousEnd: prevFps / 24,
            };
          }
          setShotsLineUp(localLineUp);
          if (localLineUp.length) {
            setTotalDurationTimeline(
              localLineUp[localLineUp.length - 1].fpsEnd
            );
          }
          setTimelineShotCounterUpdate(timelineShotCounterUpdate + 1);
        }
        generateShots();
      } catch (error) {}

      setTimelineShotReps(
        generateArrayOfShots(sequencerData.shotReps, sequencerData.layers)
      );
    }

    return () => {};
  }, [sequencerData.shotReps]);

  //fetches signed url and attaches it to the shot-reps source.
  async function fetchShotSources(shotList) {
    const queryParam = shotList.map((eachShot) => `shot_version=${eachShot}`);
    const signedUrlObj = {};
    try {
      const query = queryParam.join("&");
      const signedUrls = await fget({
        url: `sequencers/timelines/${params.playlistId}/files?${query}`,
      });
      if (signedUrls.data?.results?.length) {
        for (let i = 0; i < signedUrls.data?.results?.length; i++) {
          signedUrlObj[signedUrls.data?.results[i].shot_version] =
            signedUrls.data?.results[i];
        }
      }
    } catch (error) {
      console.log(error);
    }
    return Promise.resolve(signedUrlObj);
  }

  //function to handle shot addition to timeline
  function handleShotAddition(selectedShot) {
    //fetch shot versions of the shots
    try {
      async function getShotVersions() {
        const shotVersions = await fget({
          url: `trackables/shot-version/?project=${params.projectId}&shot=${selectedShot.id}`,
        });
        //save the versions somewhere on frontend.
        const sortedVersions = shotVersions.data.results.sort(
          (a, b) =>
            new Date(a.created_at).getUTCSeconds() -
            new Date(b.created_at).getUTCSeconds()
        );
        //get the time out of the latest version to be added.
        //1: fetch signed url (using the sequencer signed url call)
        const signedUrl = await fget({
          url: `trackables/shotversion/${sortedVersions[0]?.id}/file`,
        });
        console.log("fetched signed url");
        //not sure about GC for the created element
        const vidElement = document.createElement("video");
        console.log("element created");
        vidElement.src = signedUrl.data?.signed_url;
        console.log("calculating duration...");
        vidElement.onloadedmetadata = function () {
          updateTimeline({
            shotversion: sortedVersions[0],
            shot: selectedShot,
            signed_url: signedUrl,
            duration: vidElement.duration,
          });
          vidElement.remove();
        };
        //submit to oother function that will be called only when we have access to total duration
        //get the latest version
      }
      getShotVersions();
    } catch (error) {}
  }

  //appends newely created shot to the local shot object
  function updateTimeline(shotDetail) {
    //updates shot reprs in timeline after metedata of shot to be added is fetched
    if (sequencerData.layers.length === 0) {
      console.error("No layer found in Timeline, create a layer initially");
      return;
    }
    const newShot = {
      end_frame:
        (totalDurationTimeline / 24 + parseInt(shotDetail.duration)) * 24,
      start_frame: totalDurationTimeline,
      shot: shotDetail.shot.id,
      shot_version: shotDetail.shotversion.id,
      layer: sequencerData.layers[0].id, //find the layer id
      //layerPosition: 1,
      visibility_status: true,
      // selected: true,
    };
    try {
      async function createShotReps() {
        const response = await fpost({
          url: "sequencers/shot-reprs/",
          data: newShot,
        });
        if (response.status === 200 || 201) {
          updateRefreshCounter();
        }
      }
      createShotReps();
    } catch (error) {}

    // setTimelineShotReps(localShotReps);
    // setTimelineShotCounterUpdate(timelineShotCounterUpdate + 1);
  }

  //find the shot version according to the layer.
  const getFilteredShot = (time, layer) => {
    const selectedShot = Object.values(timelineShotReps).find(
      (eachShot) =>
        time * 24 >= eachShot.start_frame &&
        time * 24 <= eachShot.end_frame &&
        eachShot.layerPosition === layer
    );
    return selectedShot;
  };

  //handles shot selection
  function handleTimelineClickEvent({ type, data }) {
    if (type === "selectShot") {
      //find the shot according to the layer and time(works only in nonoverlap pattern).
      const currentShot = data[0];
      if (currentShot) {
        setSelectedShot(currentShot);
        // const localShotReps = timelineShotReps;
        // //find shot, and update the selected value
        // //loops for n times within object.
        // for (const shotStartFrame in localShotReps) {
        //   //object keys have type as string
        //   if (shotStartFrame == currentShot.start_frame) {
        //     localShotReps[currentShot.start_frame] = {
        //       ...currentShot,
        //       selected: !currentShot.selected,
        //     };
        //   } else {
        //     localShotReps[shotStartFrame] = {
        //       ...localShotReps[shotStartFrame],
        //       selected: false,
        //     };
        //   }
        // }
        // //if clicked shot is selected, update the state.
        // if (!currentShot.selected) {
        //   setSelectedShot(currentShot);
        // } else {
        //   setSelectedShot({});
        // }

        // setTimelineShotReps(localShotReps);
        //refresh for triggering draw() on canvas.
        setTimelineShotCounterUpdate(timelineShotCounterUpdate + 1);
      }
    }
  }
  //double click event to open version switcher modal and set data
  const handleTimelineDoubleClickEvent = ({ type, data }) => {
    //sequencer timeline will send the shot reprs directly!
    if (type === "selectShot") {
      //find the shot according to the layer.
      if (data && data.length) {
        const selectedShot = data[0];
        setVersionSwitcherModalData({
          selectedLayer: selectedShot,
          selectedShotId: selectedShot.shot,
          selectedShotVersionId: selectedShot.shot_version,
          isVersionSwitcherModalOpen: true,
        });
      }
    }
  };
  // function to close version switcher modal
  const handleCloseVersionSwitcherModal = () => {
    setVersionSwitcherModalData({
      selectedLayer: {},
      selectedShotId: null,
      selectedShotVersionId: null,
      isVersionSwitcherModalOpen: false,
    });
  };
  // function to make api call to update shot-representation
  const handleUpdatePlayList = async (shotVersionId) => {
    const updatedShotReprsData = {
      start_frame: versionSwitcherModalData?.selectedLayer?.start_frame,
      end_frame: versionSwitcherModalData?.selectedLayer?.end_frame,
      visibility_status: true,
      shot: versionSwitcherModalData?.selectedShotId,
      shot_version: shotVersionId,
      layer: versionSwitcherModalData?.selectedLayer?.layer.id,
    };
    const updatedPlaylist = {
      url: `sequencers/shot-reprs/${versionSwitcherModalData?.selectedLayer?.id}/`,
      data: updatedShotReprsData,
    };
    try {
      const res = await fpatch(updatedPlaylist);
      if (res?.status === 200 || 201) {
        updateRefreshCounter();
        handleCloseVersionSwitcherModal();
        setSnackValue({
          isOpen: true,
          message: `SuccessFully Updated shot representation for id ${res?.data?.id}`,
          isError: false,
        });
      }
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  function handleExpiredSrc(videoMeta, cb) {
    const shotVersionsId = [videoMeta.shotVersion];
    fetchShotSources(shotVersionsId).then((res) => {
      const item = shotsLineUp.find(
        (each) => each.shotVersion === videoMeta.shotVersion
      );
      const result = res[videoMeta.shotVersion].signed_url;
      if (item) {
        item.sources = result;
      }
      if (cb) {
        cb(result);
      }
    });
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackValue.isOpen}
        autoHideDuration={1500}
        onClose={() => setSnackValue({ isOpen: false, message: "" })}
      >
        <Alert severity={snackValue.isError === false ? "success" : "warning"}>
          {snackValue.message}
        </Alert>
      </Snackbar>
      <div className={classes.sequencerRoot}>
        <div className={classes.playerAndBrowserContainer}>
          <div className={classes.sequencerBrowserContainer}>
            <SequencerVersionBrowserOverview
              sequenceList={sequencerData?.currentTimeline?.sequences}
              statusList={statusList}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              projectId={params.projectId}
              onShotAdd={(selectedShot) => handleShotAddition(selectedShot)}
            />
          </div>
          <div className={classes.sequencerPlayerContainer}>
            <SequencerPlayerV2
              onGlobalTimeUpdate={(time) => {
                setCurrentGlobalTime(time);
              }}
              elementsCount={shotsLineUp.length}
              orderedVideo={shotsLineUp}
              isPaused={isPaused}
              setIsPaused={setIsPaused}
              seekToTime={seekToTime}
              currentGlobalTime={currentGlobalTime}
              onTimelineSeek={(time) => setSeekToTime(time)}
              totalDurationTimeline={totalDurationTimeline}
              currentTimeline={sequencerData.currentTimeline}
              handleExpiredSrc={handleExpiredSrc}
            />
          </div>
        </div>
        <div className={classes.timelineContainer}>
          <SequencerTimeLine
            layers={sequencerData.layers}
            selectedShot={selectedShot}
            timelineShotReps={timelineShotReps}
            timelineShotCounterUpdate={timelineShotCounterUpdate}
            currentGlobalTime={currentGlobalTime}
            onTimelineSeek={(time) => setSeekToTime(time)}
            handleTimelineDoubleClickEvent={handleTimelineDoubleClickEvent}
            onTimelineClick={handleTimelineClickEvent}
            updateRefreshCounter={updateRefreshCounter}
            setSequencerData={setSequencerData}
            sequencerData={sequencerData}
            params={params}
            setSnackValue={setSnackValue}
          />
        </div>
      </div>
      <div>
        <Modal
          className={classes.sequencerPageModal}
          open={versionSwitcherModalData?.isVersionSwitcherModalOpen}
          onClose={() => handleCloseVersionSwitcherModal()}
        >
          <SequencerVersionSwitcherModal
            versionSwitcherModalData={versionSwitcherModalData}
            closeModal={() => handleCloseVersionSwitcherModal()}
            handleUpdatePlayList={handleUpdatePlayList}
          />
        </Modal>
      </div>
    </>
  );
}
