import React, { useEffect, useState, useRef } from "react";
import { TableCell, TableRow, Button } from "@material-ui/core";
import useStyles from "../TaskPageListViewOverview.styles";
import { PencilSimpleLine } from "phosphor-react";
import StatusDropDown from "../../../../Utilities/StatusDropDown/StatusDropDown.component";
import NoThumbnail from "../../../../Utilities/NoThumbnail.component";
import UsersViewer from "../../../../Utilities/UsersViewer/UsersViewer";
import TaskVersionHover from "../../../../Utilities/TaskVersionHover/TaskVersionHover";
import { TrackablesAndIcons } from "../../../../Settings/Utility/TrackablesDetails";
import PriorityViewer from "../../../../Utilities/PriorityViewer/PriorityViewer.component";
import { ChatText } from "phosphor-react";
import UserViewerForManageUsers from "../../../../Utilities/ManageUsers/UsersViewerForManageUsers/UserViewerForManageUsers.component";

const TaskPageListItems = (props) => {
  const classes = useStyles();
  const ref = useRef(null);

  const [assignedUser, setAssignedUser] = useState([]);
  const [open, setOpen] = useState(false);
  const [reviewers, setReviewers] = useState([]);

  useEffect(() => {
    if (props.singleTask.assigned_users.length > 0) {
      setAssignedUser(props.singleTask?.assigned_users);
    }
    if (props?.singleTask?.reviewer?.length > 0) {
      let reviewerList = [];
      setReviewers([]);
      props.projectUsers.map((singleUser) => {
        props.singleTask?.reviewer.map((singleReviewer) => {
          if (singleUser.id === singleReviewer) {
            reviewerList.push(singleUser);
          }
        });
      });
      setReviewers(reviewerList);
    }
  }, [props.singleTask]);

  const getTaskUser = (user) => {
    setAssignedUser(user);
  };

  const removeTaskUser = (user) => {
    handleRemoveUser(assignedUser.filter((sUser) => sUser.id !== user.id));
  };

  const handleRemoveUser = (currUsers) => {
    let allId = [];
    if (currUsers) {
      currUsers?.map((eachUser) => allId.push(eachUser.id));
    }
    props.handleTaskUpdate(props?.singleTask?.id, { assigned_users: allId });
  };

  const handleSubmit = () => {
    let allId = [];
    if (assignedUser) {
      assignedUser?.map((eachUser) => allId.push(eachUser.id));
    }
    props.handleTaskUpdate(props?.singleTask?.id, { assigned_users: allId });
  };

  const handleResetAssignedUser = () => {
    setAssignedUser(props.singleTask?.assigned_users);
  };

  return (
    <TableRow
      hover
      style={{
        cursor: "pointer",
        display: props.hideItems ? "none" : "",
        width: "100%",
      }}
      key={props.singleTask.id}
    >
      <TableCell className={classes.listText}>
        <span>{props.singleTask.name ? props.singleTask.name : "no name"}</span>
      </TableCell>
      <TableCell className={classes.listText} align="center">
        <span className={classes.date}>
          {new Date(props.singleTask.start_time).toString().substr(4, 6)} -{" "}
          {new Date(props.singleTask.end_time).toString().substr(4, 6)}
        </span>
      </TableCell>
      <TableCell className={classes.listText}>
        <span className={classes.inLineDiv} style={{ marginLeft: "15px" }}>
          <StatusDropDown
            url={`trackables/task/${props.singleTask.id}/`}
            statusList={props?.statusList}
            currStatus={props?.singleTask?.status}
            updateRefreshCounter={props.updateRefreshCounter}
          />
        </span>
      </TableCell>
      <TableCell className={classes.listText} align="center">
        <span className={classes.inLineDiv}>
          <PriorityViewer priority={props.singleTask.priority} />
        </span>
      </TableCell>
      <TableCell className={classes.listText} align="center">
        {props.singleTask.assetversions.length === 0 &&
        props.singleTask.shotversions.length === 0 ? (
          <div className={classes.dash}>--</div>
        ) : (
          <div
            className={classes.inLineDiv}
            style={{
              marginRight: "10px",
              position: "relative",
              height: "100%",
            }}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {
                TrackablesAndIcons?.find(
                  (track) =>
                    track?.trackable === props?.singleTask?.linked_class
                )?.icon
              }
            </div>

            {props.singleTask.linked?.thumbnail ? (
              props.singleTask.assetversions.length <= 1 &&
              props.singleTask.shotversions.length <= 1 && (
                <img
                  style={{
                    borderRadius: "7px",
                    objectFit: "cover",
                    margin: "4px 0px 4px 10px",
                  }}
                  src={props.singleTask.linked?.thumbnail}
                  height="28px"
                  width="28px"
                  alt="No Img"
                  onContextMenu={(e) => e.preventDefault()}
                />
              )
            ) : (
              <NoThumbnail
                style={{
                  margin: "4px 0px 4px 10px",
                  height: "28px",
                  width: "28px",
                }}
              />
            )}

            <div
              style={{
                marginLeft: "6px",
                display: "flex",
                alignItems: "center",
                height: "100%",
                maxWidth: "135px",
                position: "relative",
              }}
              ref={ref}
            >
              {props.singleTask.shotversions.length === 1 ||
              props.singleTask.assetversions.length === 1 ? (
                <span
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    direction: "rtl",
                  }}
                >
                  {props.singleTask.shotversions[0]?.name ||
                    props.singleTask.assetversions[0]?.name}
                </span>
              ) : (
                ""
              )}
              {props.singleTask.shotversions.length > 1 ||
              props.singleTask.assetversions.length > 1 ? (
                <span>
                  {props.singleTask.shotversions.length ||
                    props.singleTask.assetversions.length}{" "}
                  Linked Versions
                </span>
              ) : (
                ""
              )}
              {open ? (
                <div
                  style={{
                    marginTop: "0px",
                    position: "absolute",
                    left: `${ref?.current?.offsetWidth}px`,
                    cursor: "auto",
                    zIndex: "2",
                  }}
                >
                  <TaskVersionHover
                    versionList={
                      props.singleTask.shotversions.length
                        ? props.singleTask.shotversions
                        : props.singleTask.assetversions
                    }
                    parent={props.singleTask.linked}
                    scrollValue={props.scrollValue}
                    versionType={props.singleTask}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </TableCell>
      <TableCell className={classes.listText} align="center">
        <div className={classes.inLineDiv}>
          {props.singleTask.department?.name}
        </div>
      </TableCell>
      <TableCell className={classes.listText} align="center">
        <div className={classes.inLineDiv} style={{ marginRight: "25px" }}>
          <UsersViewer
            selectedTask={props.singleTask}
            editable="yes"
            showDoneButton={true}
            handleSubmit={handleSubmit}
            removeTaskUser={removeTaskUser}
            getTaskUser={getTaskUser}
            usersList={props.projectUsers}
            assignedUser={assignedUser}
            type={"multiple"}
            handleResetAssignedUser={handleResetAssignedUser}
          />
        </div>
      </TableCell>
      <TableCell className={classes.listText} align="center">
        <div className={classes.inLineDiv} style={{ marginRight: "30px" }}>
          {reviewers?.length ? (
            <UsersViewer
              assignedUser={reviewers}
              selectedTask={props?.singleTask}
              editable="no"
            />
          ) : (
            "--"
          )}
        </div>
      </TableCell>
      <TableCell className={classes.listTextTwo} align="center">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ChatText
            size={17}
            color="#606479"
            weight="fill"
            style={{ marginRight: "7px" }}
          />
          {props.singleTask.related_comments_number}
        </div>
      </TableCell>
      <TableCell className={classes.listTextTwo}>
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={() => props.handleViewTaskModal(props.singleTask)}
            style={{ minWidth: "0px", padding: "5px" }}
          >
            <PencilSimpleLine size={22} />
          </Button>
        </span>
      </TableCell>
    </TableRow>
  );
};

export default TaskPageListItems;
