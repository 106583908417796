const EmptyBoxIcon = () => {
  return (
    <svg
      width="303"
      height="260"
      viewBox="0 0 303 260"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.25"
        d="M301.115 179.262C292.867 137.259 244.542 103.688 235.117 59.9177C217.986 4.22946 161.523 -4.57495 124.832 19.6104C66.6949 57.9324 128.715 67.4423 14.7273 158.11C-13.4189 180.497 -4.45026 279.322 84.0959 256.642C131.747 244.436 146.233 242.724 167.667 240.714C221.692 235.649 314.961 249.771 301.115 179.262Z"
        fill="#606479"
      />
      <path
        d="M236.575 189.79L153.073 234.325L69.6982 189.79V122.538H236.575V189.79Z"
        fill="#6C65D9"
      />
      <path
        d="M153.074 235.008C152.963 235.008 152.855 234.981 152.757 234.928L69.3848 190.393C69.2764 190.335 69.1856 190.249 69.1221 190.143C69.0586 190.037 69.0249 189.916 69.0244 189.792V122.538C69.0244 122.357 69.0957 122.183 69.2225 122.055C69.3494 121.927 69.5214 121.855 69.7008 121.855H236.575C236.754 121.855 236.926 121.927 237.053 122.055C237.18 122.183 237.251 122.357 237.251 122.538V189.79C237.251 189.914 237.217 190.035 237.154 190.141C237.09 190.247 236.999 190.333 236.891 190.391L153.39 234.927C153.293 234.98 153.184 235.008 153.074 235.008ZM70.3751 189.38L153.074 233.553L235.899 189.378V123.221H70.3751V189.38Z"
        fill="#262626"
      />
      <path
        d="M236.574 108.107L153.073 63.5719L190.596 45.3079L274.097 89.8436L236.574 108.107Z"
        fill="#6C65D9"
      />
      <path
        d="M236.575 108.79C236.465 108.79 236.356 108.763 236.259 108.711L152.758 64.1757C152.647 64.1166 152.555 64.0276 152.491 63.9188C152.428 63.8099 152.395 63.6854 152.398 63.559C152.4 63.4326 152.437 63.3094 152.504 63.2029C152.572 63.0965 152.667 63.011 152.78 62.956L190.302 44.692C190.397 44.6453 190.502 44.622 190.608 44.624C190.714 44.626 190.818 44.6532 190.912 44.7035L274.413 89.2392C274.524 89.2983 274.616 89.3871 274.68 89.496C274.743 89.6048 274.776 89.7293 274.773 89.8557C274.771 89.982 274.734 90.1052 274.667 90.2116C274.599 90.318 274.504 90.4034 274.391 90.4583L236.869 108.722C236.777 108.767 236.677 108.79 236.575 108.79ZM154.574 63.5987L236.589 107.341L272.597 89.8146L190.582 46.0735L154.574 63.5987Z"
        fill="#262626"
      />
      <path
        d="M69.698 108.107L153.199 63.5719L115.677 45.3079L32.1758 89.8436L69.698 108.107Z"
        fill="#6C65D9"
      />
      <path
        d="M69.6984 108.79C69.5967 108.79 69.4962 108.767 69.4046 108.722L31.8845 90.4583C31.7718 90.4034 31.6764 90.318 31.6088 90.2116C31.5413 90.1052 31.5044 89.982 31.5021 89.8557C31.4998 89.7293 31.5322 89.6048 31.5958 89.496C31.6594 89.3871 31.7517 89.2983 31.8623 89.2392L115.364 44.7035C115.457 44.6532 115.561 44.626 115.667 44.624C115.773 44.622 115.878 44.6453 115.973 44.692L153.496 62.956C153.608 63.011 153.704 63.0965 153.771 63.2029C153.839 63.3094 153.876 63.4326 153.878 63.559C153.88 63.6854 153.848 63.8099 153.784 63.9188C153.721 64.0276 153.628 64.1166 153.518 64.1757L70.0166 108.711C69.9185 108.764 69.8092 108.791 69.6984 108.79ZM33.6767 89.8157L69.6843 107.342L151.699 63.5998L115.691 46.0735L33.6767 89.8157Z"
        fill="#262626"
      />
      <path
        d="M69.6991 108.107L153.2 152.643L110.267 176.371L26.7656 131.836L69.6991 108.107Z"
        fill="#6C65D9"
      />
      <path
        d="M110.267 177.054C110.156 177.054 110.048 177.027 109.951 176.975L26.4493 132.439C26.3415 132.381 26.2512 132.295 26.1879 132.19C26.1246 132.084 26.0907 131.964 26.0899 131.84C26.089 131.717 26.1211 131.596 26.1829 131.49C26.2446 131.383 26.3337 131.296 26.4407 131.237L69.3742 107.508C69.4723 107.454 69.5822 107.425 69.694 107.424C69.8058 107.423 69.9161 107.451 70.0149 107.504L153.516 152.039C153.624 152.097 153.714 152.183 153.778 152.289C153.841 152.394 153.875 152.515 153.876 152.638C153.877 152.761 153.844 152.882 153.783 152.989C153.721 153.095 153.632 153.182 153.525 153.242L110.591 176.97C110.492 177.025 110.38 177.054 110.267 177.054ZM28.1939 131.825L110.261 175.595L151.772 152.653L69.7048 108.882L28.1939 131.825Z"
        fill="#262626"
      />
      <path
        d="M236.575 108.107L153.074 152.643L196.008 176.371L279.509 131.836L236.575 108.107Z"
        fill="#6C65D9"
      />
      <path
        d="M196.008 177.054C195.895 177.054 195.783 177.025 195.684 176.97L152.75 153.242C152.643 153.182 152.554 153.095 152.492 152.989C152.431 152.882 152.399 152.761 152.399 152.638C152.4 152.515 152.434 152.394 152.497 152.289C152.561 152.183 152.651 152.097 152.759 152.039L236.26 107.504C236.359 107.451 236.469 107.423 236.581 107.424C236.693 107.425 236.803 107.454 236.901 107.508L279.834 131.237C279.941 131.296 280.03 131.383 280.092 131.49C280.154 131.596 280.186 131.717 280.185 131.84C280.184 131.964 280.15 132.084 280.087 132.19C280.024 132.295 279.933 132.381 279.826 132.439L196.324 176.975C196.227 177.027 196.119 177.054 196.008 177.054ZM154.504 152.653L196.015 175.595L278.082 131.825L236.571 108.882L154.504 152.653Z"
        fill="#262626"
      />
      <path
        d="M153.2 63.5719L69.6992 108.107L153.074 152.643L236.576 108.107L153.2 63.5719Z"
        fill="#262626"
      />
      <path
        d="M153.75 152.642H152.397V234.325H153.75V152.642Z"
        fill="#262626"
      />
      <path
        d="M106.29 104.064C106.178 104.064 106.069 104.028 105.978 103.963C105.861 103.879 105.782 103.752 105.757 103.609C105.733 103.466 105.766 103.319 105.849 103.201C105.949 103.058 115.729 88.7806 104.811 75.7642C102.879 73.4484 100.469 71.5874 97.7489 70.3115C95.0292 69.0357 92.0662 68.3759 89.0673 68.3784H88.9202C89.957 72.0155 89.7719 75.2494 88.3038 77.7303C87.6783 78.8514 86.7552 79.7738 85.6385 80.3936C84.5218 81.0134 83.2559 81.306 81.9835 81.2385C80.9301 81.1514 79.9248 80.756 79.0906 80.1006C78.2565 79.4453 77.6297 78.5585 77.287 77.5489C76.0863 74.386 77.3411 71.3194 80.6485 69.3428C82.7437 68.1761 85.0718 67.4998 87.4607 67.3641C86.6836 65.2834 85.673 63.2995 84.4488 61.451C80.5067 55.3712 73.3222 51.1624 64.7367 49.904C57.4607 48.8378 50.4877 50.0936 46.9699 53.1023C46.8603 53.196 46.7184 53.2418 46.5753 53.2297C46.4323 53.2177 46.2999 53.1488 46.2071 53.0381C46.1144 52.9275 46.069 52.7842 46.081 52.6397C46.0929 52.4953 46.1612 52.3615 46.2707 52.2679C50.0142 49.0663 57.3232 47.7127 64.892 48.8253C73.7843 50.1291 81.2421 54.5133 85.3547 60.8548C86.688 62.866 87.7725 65.0344 88.5841 67.3117C91.8191 67.2332 95.0303 67.8886 97.9811 69.2297C100.932 70.5707 103.547 72.5632 105.634 75.0603C117.103 88.732 106.834 103.686 106.729 103.836C106.679 103.906 106.613 103.964 106.537 104.003C106.461 104.043 106.376 104.064 106.29 104.064ZM87.8119 68.4182C85.4958 68.518 83.2338 69.1564 81.2021 70.2838C78.3471 71.9898 77.2897 74.4959 78.3 77.1582C78.5737 77.9739 79.077 78.6914 79.7482 79.2229C80.4194 79.7543 81.2295 80.0767 82.0792 80.1505C83.1492 80.1989 84.2114 79.9458 85.1471 79.4195C86.0827 78.8932 86.855 78.1144 87.3774 77.1702C88.7248 74.8915 88.8482 71.8625 87.8103 68.4182H87.8119Z"
        fill="#606479"
      />
      <path
        d="M37.6304 61.9128C36.7108 62.1917 35.7197 62.0972 34.8682 61.6493C34.0166 61.2014 33.3718 60.4354 33.0711 59.5146C32.7704 58.5938 32.8375 57.5908 33.2582 56.7192C33.6789 55.8476 34.4198 55.1762 35.3233 54.8481C36.2268 54.52 37.2215 54.5609 38.0955 54.9623C38.9696 55.3637 39.6539 56.0938 40.0029 56.9971C40.3518 57.9004 40.3378 58.9056 39.9638 59.7986C39.5898 60.6916 38.8854 61.402 38.0005 61.7783C37.8797 61.8299 37.7561 61.8748 37.6304 61.9128ZM35.8311 55.8406C35.7435 55.8672 35.6573 55.8986 35.573 55.9346C34.9504 56.1997 34.4549 56.6998 34.192 57.3284C33.9291 57.957 33.9196 58.6644 34.1656 59.2999C34.4115 59.9355 34.8934 60.4489 35.5087 60.7309C36.124 61.013 36.824 61.0413 37.4597 60.8098C38.0953 60.5783 38.6163 60.1054 38.9118 59.4918C39.2073 58.8781 39.2538 58.1722 39.0415 57.5244C38.8292 56.8766 38.3748 56.3381 37.7752 56.0236C37.1756 55.7091 36.478 55.6434 35.8311 55.8406Z"
        fill="#F9F9F9"
      />
      <path
        d="M37.6241 53.3499C36.7542 53.6122 35.819 53.5405 34.9983 53.1486C34.1777 52.7567 33.5294 52.0723 33.1782 51.2269C32.8966 50.5492 32.8203 49.8024 32.9591 49.0809C33.0978 48.3594 33.4453 47.6957 33.9576 47.1736C34.4699 46.6516 35.124 46.2947 35.8372 46.1481C36.5504 46.0014 37.2906 46.0717 37.9642 46.3499C38.6378 46.6281 39.2147 47.1018 39.6217 47.7111C40.0287 48.3204 40.2477 49.0379 40.2509 49.7728C40.2541 50.5078 40.0414 51.2272 39.6397 51.8401C39.238 52.453 38.6654 52.9318 37.9942 53.216C37.8732 53.2671 37.7497 53.3118 37.6241 53.3499ZM35.827 47.2772C35.7383 47.3041 35.6511 47.3356 35.5656 47.3717C34.9308 47.641 34.4279 48.1538 34.1673 48.7974C33.9067 49.441 33.9099 50.1627 34.176 50.804C34.3701 51.2714 34.6942 51.6721 35.1091 51.9574C35.5239 52.2428 36.0118 52.4006 36.5136 52.4119C37.0154 52.4232 37.5097 52.2874 37.9367 52.0209C38.3637 51.7544 38.7051 51.3687 38.9196 50.9105C39.1341 50.4522 39.2125 49.941 39.1454 49.4386C39.0782 48.9363 38.8684 48.4644 38.5412 48.08C38.214 47.6955 37.7836 47.4151 37.3019 47.2725C36.8202 47.1299 36.3079 47.1319 35.827 47.2772Z"
        fill="#F9F9F9"
      />
      <path
        d="M36.5734 57.2144C39.2308 57.2144 41.3851 55.7614 41.3851 53.969C41.3851 52.1766 39.2308 50.7236 36.5734 50.7236C33.916 50.7236 31.7617 52.1766 31.7617 53.969C31.7617 55.7614 33.916 57.2144 36.5734 57.2144Z"
        fill="#6C65D9"
      />
      <path
        d="M38.7038 57.4531C38.014 57.659 37.2982 57.7629 36.5788 57.7613C33.5767 57.7613 31.2217 56.1001 31.2217 53.9739C31.2217 51.8477 33.5696 50.1804 36.5707 50.1772C37.8791 50.1518 39.1664 50.5126 40.2747 51.2154C40.7725 51.4773 41.1897 51.8719 41.481 52.3563C41.7723 52.8408 41.9266 53.3966 41.9271 53.9634C41.9276 54.5302 41.7743 55.0862 41.4838 55.5712C41.1934 56.0562 40.7769 56.4516 40.2796 56.7143C39.7934 57.038 39.2624 57.287 38.7038 57.4531ZM34.7742 51.5302C33.3283 51.9673 32.3034 52.911 32.3039 53.9712C32.3039 55.434 34.2612 56.6684 36.5788 56.6662C37.6736 56.6895 38.7514 56.3903 39.6806 55.805C40.029 55.643 40.3242 55.3834 40.5309 55.057C40.7377 54.7306 40.8474 54.3513 40.847 53.9638C40.8467 53.5764 40.7363 53.1972 40.529 52.8712C40.3217 52.5452 40.0261 52.2862 39.6773 52.1247C38.7475 51.5407 37.6692 51.2433 36.5745 51.269C35.965 51.2674 35.3586 51.3553 34.7742 51.5302Z"
        fill="#F9F9F9"
      />
      <path
        d="M37.7541 57.1155L37.6404 50.804L36.0863 50.7406L35.9775 57.1893L37.7541 57.1155Z"
        fill="#F9F9F9"
      />
      <path
        d="M30.1901 55.3423C30.4645 55.3423 30.6869 55.1177 30.6869 54.8406C30.6869 54.5636 30.4645 54.339 30.1901 54.339C29.9158 54.339 29.6934 54.5636 29.6934 54.8406C29.6934 55.1177 29.9158 55.3423 30.1901 55.3423Z"
        fill="#F9F9F9"
      />
      <path
        d="M30.5778 52.6581C30.8522 52.6581 31.0746 52.4335 31.0746 52.1564C31.0746 51.8794 30.8522 51.6548 30.5778 51.6548C30.3035 51.6548 30.0811 51.8794 30.0811 52.1564C30.0811 52.4335 30.3035 52.6581 30.5778 52.6581Z"
        fill="#F9F9F9"
      />
      <path
        d="M191.315 72.55C191.199 72.5499 191.086 72.5124 190.993 72.4429C190.9 72.3733 190.831 72.2754 190.797 72.1636C189.003 65.3951 188.524 58.3395 189.387 51.387C190.786 41.2231 196.337 27.8837 214.836 22.0426C217.439 21.1975 220.109 20.5794 222.817 20.1951C223.689 14.6973 227.005 8.7333 234.421 4.61853C252.041 -5.15856 263.943 3.61361 264.061 3.70322C264.176 3.79018 264.252 3.91963 264.272 4.0631C264.292 4.20658 264.255 4.35231 264.169 4.46825C264.083 4.5842 263.955 4.66085 263.813 4.68134C263.671 4.70184 263.527 4.6645 263.412 4.57754C263.299 4.4923 251.925 -3.84708 234.943 5.577C228.013 9.42237 224.841 14.9415 223.939 20.065C232.098 19.2536 236.006 22.0022 237.76 24.0782C238.845 25.3266 239.542 26.8698 239.765 28.5155C239.987 30.1613 239.726 31.8368 239.012 33.3334C238.448 34.543 237.534 35.5515 236.39 36.2255C235.247 36.8996 233.928 37.2076 232.608 37.1088C228.051 36.8389 224.28 32.9678 223.002 27.2465C222.58 25.3097 222.468 23.3174 222.669 21.3448C220.123 21.7143 217.611 22.2976 215.16 23.0886C200.742 27.641 192.433 37.2143 190.459 51.5406C189.613 58.3353 190.079 65.2312 191.832 71.8467C191.857 71.9284 191.862 72.0148 191.848 72.0991C191.834 72.1833 191.8 72.2629 191.75 72.3317C191.7 72.4004 191.634 72.4562 191.558 72.4947C191.483 72.5332 191.399 72.5532 191.315 72.5532V72.55ZM223.773 21.2082C223.547 23.1432 223.643 25.1026 224.057 27.0055C225.227 32.2394 228.608 35.7766 232.671 36.0176C233.778 36.0977 234.884 35.8379 235.843 35.2723C236.801 34.7067 237.568 33.8617 238.043 32.8482C238.662 31.5431 238.887 30.0831 238.69 28.65C238.493 27.2169 237.882 25.874 236.935 24.7886C234.405 21.7902 229.787 20.5793 223.773 21.2082Z"
        fill="#606479"
      />
      <path
        d="M276.665 9.22614C275.936 9.2246 275.223 9.01497 274.607 8.62144C273.991 8.22791 273.498 7.66653 273.184 7.00209C272.871 6.33765 272.75 5.59723 272.835 4.86629C272.921 4.13535 273.209 3.44368 273.667 2.87112C274.125 2.29856 274.734 1.86844 275.423 1.6304C276.113 1.39236 276.855 1.35612 277.565 1.52584C278.274 1.69556 278.921 2.06434 279.431 2.58961C279.942 3.11487 280.295 3.77522 280.45 4.49443C280.571 5.06455 280.565 5.65485 280.431 6.2221C280.297 6.78935 280.039 7.3192 279.676 7.77283C279.313 8.22647 278.855 8.59242 278.334 8.84388C277.813 9.09534 277.243 9.22595 276.665 9.22614ZM276.673 2.51355C276.098 2.51353 275.536 2.69379 275.067 3.02947C274.597 3.36516 274.242 3.83974 274.05 4.38782C273.858 4.9359 273.84 5.53048 273.997 6.08963C274.154 6.64877 274.479 7.14495 274.927 7.50978C275.375 7.8746 275.924 8.09011 276.499 8.12661C277.073 8.1631 277.645 8.01878 278.134 7.71354C278.624 7.4083 279.008 6.95716 279.233 6.42231C279.459 5.88746 279.514 5.29522 279.392 4.72722C279.26 4.09878 278.918 3.53549 278.422 3.13226C277.927 2.72903 277.309 2.51052 276.673 2.51355Z"
        fill="#F9F9F9"
      />
      <path
        d="M275.036 18.1448C274.045 18.1447 273.091 17.7606 272.371 17.0717C271.651 16.3827 271.22 15.4413 271.167 14.4412C271.113 13.4411 271.442 12.4584 272.085 11.6955C272.727 10.9326 273.635 10.4474 274.621 10.34C275.607 10.2325 276.596 10.5109 277.385 11.1178C278.174 11.7248 278.703 12.614 278.862 13.6026C279.021 14.5911 278.799 15.6037 278.241 16.4319C277.684 17.2601 276.833 17.8408 275.864 18.0546C275.592 18.1145 275.315 18.1447 275.036 18.1448ZM275.05 11.4316C274.85 11.4317 274.65 11.4535 274.455 11.4966C273.763 11.6499 273.157 12.0649 272.759 12.6563C272.362 13.2476 272.204 13.9703 272.318 14.6756C272.432 15.3809 272.81 16.0152 273.373 16.448C273.936 16.8808 274.642 17.0792 275.346 17.0023C276.049 16.9254 276.697 16.5792 277.155 16.0348C277.614 15.4903 277.848 14.7892 277.81 14.0755C277.772 13.3619 277.465 12.6901 276.952 12.1983C276.438 11.7065 275.758 11.4321 275.05 11.4316Z"
        fill="#F9F9F9"
      />
      <path
        d="M280.848 10.8225C281.189 8.95605 279.222 7.02492 276.455 6.50925C273.687 5.99359 271.168 7.08866 270.827 8.95515C270.485 10.8216 272.452 12.7527 275.22 13.2684C277.987 13.7841 280.507 12.689 280.848 10.8225Z"
        fill="#6C65D9"
      />
      <path
        d="M276.582 13.9431C276.092 13.9423 275.603 13.8966 275.121 13.8065C273.76 13.5785 272.495 12.9544 271.48 12.0109C271.036 11.6281 270.694 11.1385 270.487 10.5872C270.28 10.0359 270.213 9.44075 270.294 8.85676C270.425 8.28212 270.698 7.75008 271.086 7.30979C271.474 6.8695 271.966 6.53515 272.517 6.33762C273.804 5.84668 275.199 5.72046 276.553 5.97261C277.906 6.22476 279.165 6.84568 280.194 7.76823C280.638 8.15095 280.979 8.64059 281.187 9.19189C281.394 9.74318 281.46 10.3383 281.379 10.9223C281.248 11.497 280.976 12.029 280.587 12.4693C280.199 12.9096 279.707 13.2439 279.157 13.4415C278.341 13.7825 277.465 13.9531 276.582 13.9431ZM275.087 6.92779C274.353 6.91835 273.624 7.05906 272.944 7.34145C272.56 7.47142 272.213 7.6962 271.936 7.99542C271.659 8.29464 271.461 8.65886 271.359 9.05512C271.314 9.46214 271.371 9.87393 271.524 10.2532C271.677 10.6325 271.921 10.9673 272.234 11.2273C273.109 11.9987 274.174 12.5182 275.317 12.7311C276.459 12.9441 277.637 12.8427 278.728 12.4376C279.112 12.3077 279.459 12.0829 279.735 11.7837C280.012 11.4845 280.211 11.1202 280.313 10.724C280.358 10.3169 280.301 9.90504 280.148 9.52571C279.995 9.14639 279.751 8.81163 279.438 8.55184C278.576 7.75826 277.504 7.23548 276.353 7.04746C275.936 6.96908 275.514 6.92902 275.09 6.92779H275.087Z"
        fill="#F9F9F9"
      />
      <path
        d="M275.212 6.38354L274.124 12.977L275.73 13.3464L277.076 6.65295L275.212 6.38354Z"
        fill="#F9F9F9"
      />
      <path
        d="M281.736 13.477C282.026 13.477 282.262 13.2392 282.262 12.9458C282.262 12.6525 282.026 12.4147 281.736 12.4147C281.445 12.4147 281.21 12.6525 281.21 12.9458C281.21 13.2392 281.445 13.477 281.736 13.477Z"
        fill="#F9F9F9"
      />
      <path
        d="M282.652 10.7579C282.942 10.7579 283.178 10.5201 283.178 10.2267C283.178 9.93336 282.942 9.69556 282.652 9.69556C282.361 9.69556 282.126 9.93336 282.126 10.2267C282.126 10.5201 282.361 10.7579 282.652 10.7579Z"
        fill="#F9F9F9"
      />
    </svg>
  );
};

export default EmptyBoxIcon;
