import React, { Suspense, useState, useRef, useEffect } from "react";
import { Button } from "@material-ui/core";
import { Canvas } from "@react-three/fiber";
import { Environment, Loader, Stage } from "@react-three/drei";
import useStyles from "./ChangeHdr.style";
import { X, CaretRight } from "phosphor-react";
import useDraggableScroll from "use-draggable-scroll";

export default function ChangeHdr(props) {
  const classes = useStyles();
  const ref = useRef(null);
  const { onMouseDown } = useDraggableScroll(ref, {
    direction: "horizontal",
  });

  const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  const displayHdrData = () => {
    return (
      <div
        className={classes.backgroundOptionsContainer}
        ref={ref}
        onMouseDown={onMouseDown}
      >
        {props.HdrData.map((val, index) => {
          return (
            <div className={classes.container}>
              <Canvas
                className={
                  props.file.file === val.file
                    ? classes.hdrSelected
                    : classes.canvasHdr
                }
                onClick={() => {
                  props.selectedFile(val);
                }}
                key={index}
              >
                <Suspense fallback={null}>
                  <Stage>
                    <Environment background={true} files={val.file} path={""} />
                  </Stage>
                </Suspense>
              </Canvas>
              <Loader containerStyles={{ innerHeight: "10px" }} />
              {val.project ? (
                <X
                  onClick={() => {
                    props.deleteHdr(val);
                  }}
                  size={25}
                  className={classes.close}
                />
              ) : (
                " "
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      {props.HdrData.length ? (
        props.HdrData.length > 4 ? (
          <>
            {/* display image instead */}
            {displayHdrData()}

            <Button
              className={classes.rightArrow}
              onClick={() => {
                sideScroll(ref.current, 25, 100, 10);
              }}
            >
              <CaretRight weight="bold" size="40px" fill="#6C65D9" />
            </Button>
          </>
        ) : (
          displayHdrData()
        )
      ) : (
        <div className={classes.backgroundOptionsContainer}>
          No background HDR file found!
        </div>
      )}

      {/* <Button className={classes.rightArrow}>
        <CaretRight weight="bold" size="40px" fill="#6C65D9" />
      </Button> */}
    </>
  );
}
