import React from "react";
import useStyles from "./SequencerPlayerTitle.style.js";
import { ChatDots } from "phosphor-react";
export default function SequencerPlayerTitle({ title, lastModified }) {
  const classes = useStyles();
  return (
    <>
      <div style={{ display: "flex", gap: "5%", width: "100%" }}>
        <div style={{ marginTop: "4px" }}>
          <ChatDots size={27} weight="bold" />
        </div>
        <div className={classes.titleContainer}>
          <div className={classes.title}>{title}</div>
          <div style={{ display: "flex", gap: "2%" }}>
            <div className={classes.dateTime}>Last modified</div>
            <div className={classes.dateTime}> | </div>
            <div>{lastModified}</div>
          </div>
        </div>
      </div>
      <div style={{ float: "right", fontSize: "24px", fontWeight: "500" }}>
        1204
      </div>
    </>
  );
}
