import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "30px",
    fontSize: "14px",
  },
  card: {
    background: theme.palette.secondary.light,
    borderRadius: "30px",
    width: "620px",
    maxHeight: "670px",
  },
  CardContent: {
    backgroundColor: theme.palette.secondary.light,
    dispay: "flex",
    flexDirection: "column",
  },
  versionList: {
    maxHeight: "600px",
    overflowY: "auto",
    overflowX: "hidden",
  },
  titleContainer: {
    width: "555px",
    height: "36px",
    marginLeft: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: "24px",
    fontWeight: "400",
  },
  crossIcon: {
    cursor: "pointer",
  },
  listItem: {
    height: "115px",
    width: "570px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    borderRadius: "10px",
  },
  selectedItem: {
    height: "115px",
    width: "570px",
    cursor: "pointer",
    borderRadius: "10px",
    backgroundColor: theme.palette.primary.main,
  },
  listText: {
    width: "385px",
    height: "100%",
    marginLeft: "10px",
  },
  listTextBox1: {
    width: "100%",
    height: "50%",
    display: "flex",
    paddingTop: "10px",
    justifyContent: "space-between",
  },
  listTextBox2: {
    width: "100%",
    height: "30%",
    display: "flex",
    justifyContent: "space-between",
  },
  fadedText: {
    color: theme.palette.text.tertiary,
  },
}));

export default useStyles;
