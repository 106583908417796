import React from "react";
import SequenceOverview from "../../Components/Sequences/SequenceOverview/SequenceOverview.component.jsx";
import { fget, fpostBFF } from "../../API/callsAPI";

export default function SequencePage(props) {
  return (
    <div>
      <SequenceOverview params={props.match.params} searchQuery = {props.searchQuery} />
    </div>
  );
}
