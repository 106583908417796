import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  FormHelperText,
  TextField,
} from "@material-ui/core";
import { X, Asterisk } from "phosphor-react";
import React, { useState } from "react";
import useStyles from "./CreateNewOrEditTaskTemplateModal.style";
import { useFormik } from "formik";
import * as yup from "yup";
import { fpatch, fpost } from "../../../../../API/callsAPI";
import HelperText from "../../../../Utilities/HelperText/HelperText";

const CreateNewOrEditTaskTemplateModal = ({
  handleClose,
  type,
  selectedTemplate,
  updateRefreshCounter,
  projectId,
  handleManageTaskPhaseModalInfo,
  setSnackValue,
}) => {
  const classes = useStyles();
  const [createWithPhase, setCreateWithPhase] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const NewTasksTemplateValidationScheme = yup.object({
    templateName: yup
      .string("String required")
      .min(1, "Too Short!")
      .max(50, "Maximum Character Limit: 50")
      .required("This is a required field"),
    description: yup
      .string("String required")
      .required("This is a required field"),
  });

  const formik = useFormik({
    initialValues: {
      templateName: selectedTemplate?.name ? selectedTemplate?.name : "",
      description: selectedTemplate?.description
        ? selectedTemplate?.description
        : "",
    },
    validationSchema: NewTasksTemplateValidationScheme,
    onSubmit: async (values) => {
      let formData = new FormData();
      formData.append("name", values.templateName);
      formData.append("description", values.description);
      formData.append("project", projectId);

      try {
        setIsUpdating(true);
        if (type === "New") {
          const newTemplate = {
            url: `task-template/`,
            data: formData,
          };
          const newRes = await fpost(newTemplate);
          if (newRes.status === 200 || 201) {
            setSnackValue({
              isOpen: true,
              message: `successfully created task template "${newRes?.data?.name}"`,
              isError: false,
            });
            updateRefreshCounter();
            handleClose();
            setIsUpdating(false);
            if (createWithPhase) {
              handleManageTaskPhaseModalInfo(newRes?.data);
            }
          }
        } else {
          const editTemplate = {
            url: `task-template/${selectedTemplate?.id}/`,
            data: formData,
          };
          const editRes = await fpatch(editTemplate);
          if (editRes.status === 200 || 201) {
            setSnackValue({
              isOpen: true,
              message: `successfully edited task template "${editRes?.data?.name}"`,
              isError: false,
            });
            updateRefreshCounter();
            handleClose();
            setIsUpdating(false);
            if (createWithPhase) {
              handleManageTaskPhaseModalInfo(editRes?.data);
            }
          }
        }
      } catch (error) {
        setSnackValue({
          isOpen: true,
          message: `${error}`,
          isError: true,
        });
        setIsUpdating(false);
      }
    },
  });
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div className={classes.cardTitle}>
            <span className={classes.title}>{type} Task Template</span>
            <Button
              className={classes.closeButton}
              onClick={() => handleClose()}
            >
              <X size={28} />
            </Button>
          </div>
          <br />
          <form onSubmit={formik.handleSubmit}>
            <div>
              <span className={classes.inputTitle}>Task Template Name</span>
              <Asterisk size={12} color="#FF0000" weight="fill" />
              <div className={classes.cardContentDiv1}>
                <TextField
                  placeholder="Enter a task template name"
                  fullWidth
                  type="text"
                  className={classes.cardContentInput}
                  InputProps={{ disableUnderline: true }}
                  name="templateName"
                  id="templateName"
                  value={formik.values.templateName}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.templateName)}
                />
              </div>
              {formik.errors.templateName && formik.touched.templateName ? (
                <HelperText text={formik.errors.templateName} />
              ) : null}
            </div>
            <div style={{ marginTop: "15px" }}>
              <span className={classes.inputTitle}>Description</span>
              <Asterisk size={12} color="#FF0000" weight="fill" />
              <div className={classes.cardContentDiv2}>
                <TextField
                  type="text"
                  fullWidth
                  multiline={true}
                  InputProps={{ disableUnderline: true }}
                  placeholder="Enter a Description..."
                  name="description"
                  className={classes.cardContentInput}
                  id="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.description)}
                />
              </div>
              {formik.errors.description && formik.touched.description ? (
                <HelperText text={formik.errors.description} />
              ) : null}
            </div>
            <CardActions className={classes.buttons}>
              <Button
                size="small"
                className={classes.addPhasesButton}
                disableElevation
                type="submit"
                value="Submit"
                onClick={() => setCreateWithPhase(true)}
                disabled={isUpdating ? true : false}
              >
                {isUpdating ? (
                  <CircularProgress style={{ width: "20px", height: "20px" }} />
                ) : (
                  <span>Add Phases</span>
                )}
              </Button>
              <Button
                size="small"
                className={classes.confirmButton}
                disableElevation
                type="submit"
                value="Submit"
                disabled={isUpdating ? true : false}
              >
                {isUpdating ? (
                  <CircularProgress style={{ width: "20px", height: "20px" }} />
                ) : (
                  <span>Confirm</span>
                )}
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default CreateNewOrEditTaskTemplateModal;
