import React, { useEffect, useState } from "react";
import useStyles from "./DepartmentOverview.styles";
import DepartmentPageTool from "../DepartmentPageTool/DepartmentPageTool.component";
import { Modal } from "@material-ui/core";
import DepartmentCard from "../DepartmentCard/DepartmentCard.component";
import { fdelete, fget } from "../../../API/callsAPI";
import NewDepartment from "../NewDepartmentModal/NewDepartment.component";
import Loader from "../../Utilities/Loader/Loader";
import NoResultFound from "../../Utilities/NoResultFound/NoResultFound";
import DeleteModal from "../../Utilities/DeleteModal/DeleteModal.component";
import ErrorOrSuccessMessage from "../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";
function DepartmentOverview(props) {
  const classes = useStyles();

  const [usersList, setUsersList] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [deleteDepartmentInfo, setDeleteDepartmentInfo] = useState({
    isModalOpen: false,
    selectedDepartment: {},
  });
  const [editDepartmentInfo, setEditDepartmentInfo] = useState({
    isModalOpen: false,
    selectedDepartment: {},
  });
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });

  const handleModalState = () => {
    setModalOpen(true);
  };

  const handleDeleteDepartmentInfo = (dept) => {
    setDeleteDepartmentInfo({
      isModalOpen: !deleteDepartmentInfo?.isModalOpen,
      selectedDepartment: dept,
    });
  };

  const closeDeleteModal = () => {
    setDeleteDepartmentInfo({
      isModalOpen: false,
      selectedDepartment: {},
    });
    closeEditModal();
  };

  const handleEditDepartmentInfo = (dept) => {
    setEditDepartmentInfo({
      isModalOpen: !editDepartmentInfo?.isModalOpen,
      selectedDepartment: dept,
    });
  };

  const closeEditModal = () => {
    setEditDepartmentInfo({
      isModalOpen: false,
      selectedDepartment: {},
    });
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchDepartments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCounter]);

  const updateRefreshCounter = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  //!function to fetch depatments
  const fetchDepartments = async () => {
    try {
      const departmentsRes = await fget({
        url: `department/?project=${props.params.projectId}`,
      });
      setItems(departmentsRes.data.results);
      setIsLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUsers = async () => {
    try {
      const usersRes = await fget({
        url: `project/${props.params.projectId}/users/`,
      });
      setUsersList(
        usersRes.data.results.map((el) => {
          const data = {
            label: el.first_name + " " + el.last_name,
            value: el.id,
            avatar: el?.avatar,
          };
          return data;
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const deleteDepartment = async () => {
    try {
      const res = await fdelete({
        url: `department/${deleteDepartmentInfo?.selectedDepartment?.id}/`,
      });
      setSnackValue({
        isOpen: true,
        message: `Successfully deleted department`,
        isError: false,
      });
      updateRefreshCounter();
      closeDeleteModal();
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  return (
    <div className={classes.root}>
      <ErrorOrSuccessMessage snackValue={snackValue} />
      <DepartmentPageTool openModal={handleModalState} />
      {isLoaded ? (
        <>
          {items.length ? (
            <div className={classes.cardContainer}>
              {items
                .filter((search) => {
                  if (props.searchQuery === undefined) {
                    return search;
                  } else {
                    return search;
                  }
                })
                .map((department) => (
                  <div>
                    <DepartmentCard
                      depart={department}
                      projectId={props.params.projectId}
                      handleDeleteDepartmentInfo={handleDeleteDepartmentInfo}
                      handleEditDepartmentInfo={handleEditDepartmentInfo}
                    />
                  </div>
                ))}
            </div>
          ) : (
            <NoResultFound />
          )}
        </>
      ) : (
        <Loader />
      )}
      <div>
        <Modal
          open={isModalOpen}
          onClose={() => setModalOpen(false)}
          className={classes.departmentModal}
        >
          <NewDepartment
            type="New"
            usersList={usersList}
            setSnackValue={setSnackValue}
            projectId={props.params.projectId}
            updateRefreshCounter={updateRefreshCounter}
            closeNewDeptModal={() => {
              setModalOpen(false);
            }}
          />
        </Modal>
        <Modal
          open={editDepartmentInfo?.isModalOpen}
          onClose={() => closeEditModal()}
          className={classes.departmentModal}
        >
          <NewDepartment
            type="Edit"
            usersList={usersList}
            setSnackValue={setSnackValue}
            projectId={props.params.projectId}
            updateRefreshCounter={updateRefreshCounter}
            closeNewDeptModal={() => {
              closeEditModal();
            }}
            selectedDepartment={editDepartmentInfo?.selectedDepartment}
            handleDeleteDepartmentInfo={handleDeleteDepartmentInfo}
          />
        </Modal>
        <Modal
          open={deleteDepartmentInfo?.isModalOpen}
          onClose={() => closeDeleteModal()}
          className={classes.departmentModal}
        >
          <DeleteModal
            type="department"
            name={deleteDepartmentInfo?.selectedDepartment?.name}
            handleDelete={deleteDepartment}
            handleCloseDeleteModal={closeDeleteModal}
          />
        </Modal>
      </div>
    </div>
  );
}

export default DepartmentOverview;
