import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Modal,
  TextField,
} from "@material-ui/core";
import React from "react";
import { useState } from "react";
import UploadFile from "../../Utilities/UploadFile/UploadFile";
import { useFormik } from "formik";
import * as yup from "yup";
import useStyles from "./ReportIssueForm.style";
import DragScreen from "../../Utilities/DragScreen/DragScreen";
import ConfirmDeleteFile from "../../Utilities/ConfirmDeleteFile/ConfirmDeleteFile.component";
import ErrorOrSuccessMessage from "../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";
import { fpost } from "../../../API/callsAPI";

const ReportIssueForm = (props) => {
  const classes = useStyles();
  const [thumbnail, uploadThumbnailSet] = useState();
  const [thumbnailTouched, setThumbnailTouch] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [snackValue, setSnackValue] = React.useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  const [deleteFileModal, setDeleteFileModal] = useState(false);

  const handleThumbnailUpload = (event) => {
    let file = event.target.files[0];
    event.target.value = null;
    console.log(file);
    uploadThumbnailSet(file);
    setThumbnailTouch(true);
  };

  const handleClearUpload = (event) => {
    event?.stopPropagation();
    uploadThumbnailSet(null);
    setThumbnailTouch(false);
    setDeleteFileModal(false);
  };

  const NewReportValidationScheme = yup.object({
    subject: yup
      .string("String required")
      .label("subject")
      .min(1, "Too Short!")
      .max(50, "Maximum Character Limit: 50")
      .required("This is a required field"),
    type: yup.string().required("This is a required field"),
  });

  const formik = useFormik({
    initialValues: {
      subject: "",
      description: "",
    },
    validationSchema: NewReportValidationScheme,

    onSubmit: async (values) => {
      console.log("calling");
    },
  });

  const submitIssue = async (event) => {
    setFileUploading(true);
    setLoader(true);
    let formData = new FormData();
    formData.append("subject", formik?.values?.subject);
    if (thumbnail) {
      formData.append("attachment", thumbnail);
    }
    formData.append("description", formik.values.description);
    try {
      const issueData = {
        url: "report/",
        data: formData,
      };
      const res = await fpost(issueData);
      if (res.status === 200 || 201) {
        props.updateRefreshCounter();
        formik.resetForm();
        handleClearUpload(event);
        setFileUploading(false);
        setLoader(false);
        setSnackValue({
          isOpen: true,
          message: `Issue Reported Successfully`,
          isError: false,
        });
      }
    } catch (error) {
      console.log(error);
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  const handleDragFile = (e) => {
    if (e.type === "dragenter") {
      //when  file enter- display dragscreen
      props.handleDragScreen(true);
      props.setDragFileEnterModal(true);
    }
    //catching the dragged file for uploading
    if (e.type === "drop") {
      props.handleDragScreen(false);
      console.log(e.dataTransfer.files[0]);
      uploadThumbnailSet(e.dataTransfer.files[0]);
      setThumbnailTouch(true);
    }
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <div>
      <ErrorOrSuccessMessage snackValue={snackValue} />
      <Card
        className={classes.card}
        onDragEnter={(e) => handleDragFile(e)}
        onDrop={(e) => handleDragFile(e)}
        onDragLeave={(e) => handleDragFile(e)}
        onDragOver={(e) => handleDragFile(e)}
      >
        <CardContent className={classes.cardContent}>
          <DragScreen dragScreen={props.dragScreen} />
          <form onSubmit={formik.handleSubmit}>
            <div className={classes.formContentContainer}>
              <div className={classes.inputAndTitleContainer}>
                <span className={classes.inputTitle}>
                  What was the Issue about?
                  <span style={{ color: "red" }}>*</span>
                </span>
                <div
                  className={classes.issueNameInput}
                  style={{ marginTop: "15px" }}
                >
                  <TextField
                    fullWidth
                    placeholder="Name Your Issue"
                    name="subject"
                    id="TextField"
                    className={classes.cardContentInput}
                    InputProps={{ disableUnderline: true }}
                    value={formik.values.subject}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.subject && Boolean(formik.errors.subject)
                    }
                    helperText={
                      formik.errors.subject ? formik.errors.subject : null
                    }
                  />
                </div>
              </div>

              <div className={classes.inputAndTitleContainer}>
                <span className={classes.inputTitle}>
                  Add attachment here<span style={{ color: "red" }}>*</span>
                </span>
                <UploadFile
                  fileUploading={fileUploading}
                  uploadProgress={uploadProgress}
                  thumbnail={thumbnail}
                  handleClearUpload={handleClearUpload}
                  handleThumbnailUpload={handleThumbnailUpload}
                  setDeleteFileModal={setDeleteFileModal}
                />
              </div>

              <div className={classes.inputAndTitleContainer}>
                <span className={classes.inputTitle}>Describe your Issue</span>
                <div className={classes.descriptionInput}>
                  <TextField
                    type="text"
                    fullWidth
                    multiline={true}
                    InputProps={{ disableUnderline: true }}
                    placeholder="Describe the issue in detail here"
                    name="description"
                    className={classes.cardContentInput}
                    id="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.description)}
                  />
                </div>
              </div>
            </div>
            <CardActions className={classes.cardAction}>
              <Button
                className={classes.reportButton}
                type="submit"
                value="Submit"
                size="small"
                variant="outlined"
                disableElevation
                color="secondary"
                disabled={
                  fileUploading
                    ? true
                    : formik.values?.subject && thumbnail
                    ? false
                    : true
                }
                onClick={(e) => submitIssue(e)}
              >
                {loader ? (
                  <CircularProgress
                    color="secondary"
                    style={{ width: "25px", height: "25px" }}
                  />
                ) : (
                  <span>Report</span>
                )}
              </Button>
              <Button
                className={classes.clearButton}
                size="small"
                variant="outlined"
                disableElevation
                color="secondary"
                disabled={fileUploading}
                onClick={(e) => {
                  formik.resetForm();
                  handleClearUpload(e);
                }}
              >
                {loader ? (
                  <CircularProgress
                    color="secondary"
                    style={{ width: "25px", height: "25px" }}
                  />
                ) : (
                  <span>Clear Form</span>
                )}
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
      <div>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={deleteFileModal}
          onClose={() => setDeleteFileModal(false)}
        >
          <ConfirmDeleteFile
            name={typeof thumbnail === "object" ? thumbnail?.name : thumbnail}
            closeModal={() => {
              setDeleteFileModal(false);
            }}
            deleteFile={() => {
              handleClearUpload();
            }}
          />
        </Modal>
      </div>
    </div>
  );
};

export default ReportIssueForm;
