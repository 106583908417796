import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  leftArrowDisabled: {
    position: "absolute",
    height: "90px",
    paddingTop: "25px",
    width: "40px",
    minHeight: 0,
    minWidth: "0px",
    color: theme.palette.text.tertiary,
    marginTop: "-35px",
    borderRadius: "10px",
    "-webkit-user-select": "none",
    "-moz-user-select": "none",
    "-ms-user-select": "none",
    "user-select": "none",
  },
  leftArrowEnabled: {
    position: "absolute",
    height: "90px",
    paddingTop: "25px",
    width: "150px",
    minHeight: 0,
    minWidth: "0px",
    color: theme.palette.secondary.main,
    marginTop: "-35px",
    cursor: "pointer",
    borderRadius: "10px",
    background:
      "linear-gradient(to right, #0B0C10 9%, rgba(11, 12, 16, 0) 100%)",
    "-webkit-user-select": "none",
    "-moz-user-select": "none",
    "-ms-user-select": "none",
    "user-select": "none",
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    display: "flex",
    overflow: "hidden",
    marginLeft: "20px",
    marginRight: "20px",
  },
  versionList: {
    display: "flex",
    flexDirection: "column",
  },
  version: {
    height: "88px",
    minWidth: "160px",
    margin: "14px 14px 0 14px",
    cursor: "pointer",
    backgroundColor: "black",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  versionSelected: {
    height: "88px",
    minWidth: "160px",
    margin: "14px 14px 0 14px",
    cursor: "pointer",
    backgroundColor: "black",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "3px solid #6C65D9",
  },
  versionThumbnail: {
    height: "80px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "centger",
    alignItems: "center",
  },
  noThumbnail: {
    height: "88px",
    minWidth: "160px",
    cursor: "pointer",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "centger",
    alignItems: "center",
  },
  versionName: {
    margin: "5px 0 5px 15px",
    width: "160px",
  },
  rightArrowDisabled: {
    position: "absolute",
    right: "0px",
    height: "90px",
    paddingTop: "25px",
    width: "40px",
    minWidth: "0px",
    color: "grey",
    marginTop: "-35px",
    borderRadius: "10px",
    "-webkit-user-select": "none",
    "-moz-user-select": "none",
    "-ms-user-select": "none",
    "user-select": "none",
  },
  rightArrowEnabled: {
    position: "absolute",
    right: "0px",
    height: "90px",
    paddingTop: "25px",
    paddingLeft: "110px",
    width: "150px",
    minWidth: "0px",
    color: "#6C65D9",
    marginTop: "-35px",
    cursor: "pointer",
    background: "linear-gradient(270deg, #0B0C10 9%, rgba(11, 12, 16, 0) 100%)",
    borderRadius: "10px",
    "-webkit-user-select": "none",
    "-moz-user-select": "none",
    "-ms-user-select": "none",
    "user-select": "none",
  },
}));

export default useStyles;
