import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "6px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": " inset 0 0 5px rgb(54, 54, 54)",
      borderRadius: "10px",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "10px",
    },

    "*::-webkit-scrollbar-thumb:hover": {
      backgroundColor: theme.palette.secondary.main,
      width: "10px",
    },
  },
  TaskPageModals: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    backgroundColor: theme.palette.primary.main,
  },
  headTable: {
    padding: "1.3% 0% !important",
    color: theme.palette.text.tertiary,
  },
  paginationDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "5px",
  },
  text: {
    color: theme.palette.text.tertiary,
  },
  paginationRightTextAndButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "20px",
  },
  nextAndPrev: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: "10px",
  },
  previous: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    "&:hover": {
      backgroundColor: theme.palette.text.tertiary,
    },
  },
  next: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "0px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.text.tertiary,
    },
  },
}));

export default useStyles;
