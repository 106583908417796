import React from "react";
import useStyles from "../ComparePageOverview/ComparePageOverview.styles";
import CanvasFrame from "../../CanvasFrame/CanvasFrame.component";

function CanvasModal(props) {
  const classes = useStyles();

  return (
    <CanvasFrame
      image={props.screenshot}
      users={props.allUsersName}
      closeModal={props.canvasModalToggle}
      toggle={props.canvasModalToggle}
      progress={props.currentDuration}
      assetID={props.assetId}
      version_id={props.version_id}
    />
  );
}

export default CanvasModal;
