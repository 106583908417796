import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({

  btn: {
    minWidth: "0",
    padding: "3px",
  },
  playpauseButton: {
    color: theme.palette.secondary.main,
  },
  btnNext: {
    border: `2px solid ${theme.palette.border.main}`,
    minWidth: "0",
    padding: "3px",
  },
  btnPrevious: {
    transform: "rotate(180deg)",
    border: `2px solid ${theme.palette.border.main}`,
    minWidth: "0",
    padding: "3px",
  },
  btnContainer: {
    display: "flex",
    gap: "8px",
  },
  icon: {
    cursor: "pointer",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
}));

export default useStyles;
