import React, { useState, useEffect } from "react";
import { fdelete, fpatch } from "../../../API/callsAPI";
//icons
import {
  ShareNetwork,
  PencilSimpleLine,
  Trash,
  ArrowsOut,
  Check,
  ArrowUUpRight,
} from "phosphor-react";

//MUI components
import {
  Button,
  TableCell,
  TableRow,
  Modal,
  Tooltip,
  Avatar,
} from "@material-ui/core";
import useStyles from "./ReviewVersionList.styles";
import ImageViewer from "../../Utilities/ImageViewer/ImageViewer.component";
import NoThumbnail from "../../Utilities/NoThumbnail.component";
//components
import ReviewPageShareFileModal from "../../Reviews/ReviewPageModals/ReviewPageShareFileModal";
import DeleteModal from "../../Utilities/DeleteModal/DeleteModal.component";
import DateAndTimeViewer from "../../Utilities/DateAndTimeViewer/DateAndTimeViewer.component";
import UserAvatar from "../../Utilities/UserAvatar/UserAvatar.component";
import ReviewVersionEditReview from "../ReviewVersionModals/ReviewVersionEditReview";

function ReviewVersionList({
  isReviewVersionTogglerOn,
  isExternalReviewer,
  handleVersionViewer,
  review,
  updateRefreshCounter,
  setSnackValue,
  fetchFile,
  handleViewModal,
  loggedInUser,
}) {
  const classes = useStyles();
  const [isShareFileModalOpen, setShareFileModalOpen] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(""); //status marked by each user for each version
  const [isDeleteReviewFileModalOpen, setDeleteReviewFileModalOpen] =
    useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const handleShareFileModal = () => {
    setShareFileModalOpen(!isShareFileModalOpen);
  };

  const handleDeleteFileModal = () => {
    setDeleteReviewFileModalOpen(!isDeleteReviewFileModalOpen);
  };

  const handleEditModal = () => {
    setEditModalOpen(!isEditModalOpen);
  };

  const handleDeleteReviewFile = async () => {
    try {
      const res = await fdelete({
        url: `review-version/${review.id}/`,
      });
      if (res.status === 204) {
        setSnackValue({
          isOpen: true,
          message: res.data.detail,
          isError: false,
        });
        updateRefreshCounter();
        handleDeleteFileModal();
      }
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  const updateStatus = async (versionStatus) => {
    let statusObj = {
      url: `review-version/${review.id}/`,
      data: { status: versionStatus },
    };

    try {
      const res = await fpatch(statusObj);
      if (res.status === 200 || 201) {
        updateRefreshCounter();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    review?.status_by_users?.map((eachUser) => {
      if (eachUser.user.id === loggedInUser?.id) {
        setCurrentStatus(eachUser.status);
      }
      return eachUser;
    });
  }, []);

  const UserAction = () => {
    return (
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Tooltip title="Share File" placement="top">
          <Button
            className={classes.actionBtn}
            onClick={(e) => {
              e.stopPropagation();
              handleShareFileModal();
            }}
          >
            <ShareNetwork size={23} />
          </Button>
        </Tooltip>
        <Tooltip title="Edit Version" placement="top">
          <Button
            className={classes.actionBtn}
            onClick={(e) => {
              e.stopPropagation();
              handleEditModal();
            }}
          >
            <PencilSimpleLine size={23} />
          </Button>
        </Tooltip>
        <Tooltip title="Delete" placement="top">
          <Button
            className={classes.actionBtn}
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteFileModal();
            }}
          >
            <Trash size={23} />
          </Button>
        </Tooltip>
        <Tooltip title="View" placement="top">
          <Button
            className={classes.actionBtn}
            onClick={(event) => {
              event.stopPropagation();
              fetchFile(review.id)
                // opening the modal after the function has fetched file, else previous file loads first
                .then(() => handleViewModal());
            }}
          >
            <ArrowsOut size={23} />
          </Button>
        </Tooltip>
      </div>
    );
  };

  const ExternalReviewerAction = () => {
    return (
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Tooltip title="View" placement="top">
          <Button
            className={classes.actionBtn}
            onClick={(event) => {
              event.stopPropagation();
              fetchFile(review.id)
                // opening the modal after the function has fetched file, else previous file loads first
                .then(() => handleViewModal());
            }}
          >
            <ArrowsOut size={23} className={classes.actionIcons} />
          </Button>
        </Tooltip>
        <Tooltip title="Mark as Revision" placement="top">
          <Button
            className={classes.actionBtn}
            onClick={(event) => {
              event.stopPropagation();
              updateStatus("Revision");
            }}
          >
            <div
              className={classes.arrowIconCircle}
              style={{
                backgroundColor: currentStatus === "Revision" ? "#CE7606" : "",
                border:
                  currentStatus === "Revision"
                    ? "1px solid #CE7606"
                    : "1px solid #606479",
              }}
            >
              <ArrowUUpRight
                size={18}
                color={currentStatus === "Revision" ? "#F9F9F9" : "#606479"}
              />
            </div>
          </Button>
        </Tooltip>
        <Tooltip title="Mark as Completed" placement="top">
          <Button
            className={classes.actionBtn}
            onClick={(event) => {
              event.stopPropagation();
              updateStatus("Completed");
            }}
          >
            <div
              className={classes.arrowIconCircle}
              style={{
                backgroundColor: currentStatus === "Completed" ? "#6C65D9" : "",
                border:
                  currentStatus === "Completed"
                    ? "1px solid #6C65D9"
                    : "1px solid #606479",
              }}
            >
              <Check
                size={18}
                color={currentStatus === "Completed" ? "#F9F9F9" : "#606479"}
              />
            </div>
          </Button>
        </Tooltip>
      </div>
    );
  };

  const getColor = (status) => {
    switch (status) {
      case "Completed":
        return "#00BF07";
      case "Revision":
        return "#CE7606";
      case "In Progress":
        return "#31BAF4";
      default:
        return;
    }
  };

  return (
    <>
      <TableRow
        hover
        style={{
          cursor: "pointer",
          width: "100%",
        }}
        onClick={(e) => handleVersionViewer(review)}
      >
        <TableCell
          component="th"
          scope="row"
          className={classes.listText}
          align="left"
        >
          <div style={{ display: "flex" }}>
            {review?.thumbnail ? (
              <ImageViewer
                sliderState={isReviewVersionTogglerOn ? 25 : 0}
                url={review.thumbnail}
              />
            ) : (
              <NoThumbnail sliderState={isReviewVersionTogglerOn ? 25 : 0} />
            )}
            <p style={{ marginLeft: "10px" }}>{review.name}</p>
          </div>
        </TableCell>
        <TableCell className={classes.listText} align="center">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DateAndTimeViewer dateAndTime={review.created_date} />
          </div>
        </TableCell>
        {!isExternalReviewer && (
          <TableCell className={classes.listText} align="center">
            {review.created_by && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className={classes.avatarWithName}>
                  <UserAvatar
                    avatar={review.created_by?.avatar}
                    firstName={review.created_by?.first_name}
                    lastName={review.created_by?.last_name}
                  />
                  <span>
                    {review.created_by?.first_name}{" "}
                    {review.created_by?.last_name}
                  </span>
                </div>
              </div>
            )}
          </TableCell>
        )}
        <TableCell className={classes.listText} align="center">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              height: "100%",
              marginTop: "5px",
            }}
          >
            {review.status_by_users?.map((eachUser, index) => {
              return (
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  key={eachUser.id}
                >
                  <Tooltip
                    title={
                      eachUser.user.first_name + " " + eachUser.user.last_name
                    }
                    key={eachUser.user.id}
                  >
                    <div>
                      {eachUser.user.avatar ? (
                        <img
                          className={classes.userImage}
                          style={{
                            border: `2px solid ${getColor(eachUser?.status)}`,
                          }}
                          src={eachUser.user.avatar}
                          alt=""
                        />
                      ) : (
                        <Avatar
                          className={classes.avatar}
                          style={{
                            border: `2px solid ${getColor(eachUser?.status)}`,
                          }}
                        >
                          {eachUser.user.first_name} {eachUser.user.last_name}
                        </Avatar>
                      )}
                    </div>
                  </Tooltip>
                  {index !== review?.status_by_users.length - 1 ? (
                    <span style={{ color: `${getColor(eachUser?.status)}` }}>
                      ---
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
          </div>
        </TableCell>
        <TableCell className={classes.listText} align="center">
          {review.comment_count} Messages
        </TableCell>
        <TableCell align="right" className={classes.listText}>
          {isExternalReviewer ? <ExternalReviewerAction /> : <UserAction />}
        </TableCell>
      </TableRow>
      <div>
        <Modal
          className={classes.reviewVersionListModals}
          open={isShareFileModalOpen}
          onClose={handleShareFileModal}
        >
          <ReviewPageShareFileModal
            versionId={""}
            handleShareFileModal={handleShareFileModal}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.reviewVersionListModals}
          open={isDeleteReviewFileModalOpen}
          onClose={handleDeleteFileModal}
        >
          <DeleteModal
            type="review version file"
            name={review.name}
            handleCloseDeleteModal={handleDeleteFileModal}
            handleDelete={handleDeleteReviewFile}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.reviewVersionListModals}
          open={isEditModalOpen}
          onClose={handleEditModal}
        >
          <ReviewVersionEditReview
            handleEditModal={handleEditModal}
            setSnackValue={setSnackValue}
            updateRefreshCounter={updateRefreshCounter}
            review={review}
          />
        </Modal>
      </div>
    </>
  );
}

export default ReviewVersionList;
