//Library
import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";

//Components

import { fget } from "../../../API/callsAPI";

//CSS Styles
import ProjectHealth from "../UpdatePageWidgets/ProjectHealth/ProjectHealth";
import ProjectUpdates from "../UpdatePageWidgets/ProjectUpdates/ProjectUpdates";
import TaskOverview from "../UpdatePageWidgets/TaskOverview/TaskOverview";
import useStyles from "./UpdatesOverview.styles";

function UpdatesOverview(props) {
  //States
  const [allUsers, setAllUsers] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState();
  const [projectDetails, setProjectDetails] = useState({});

  //UseEffect
  useEffect(() => {
    fetchUsers();
    getProjectDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const accessToken = document.cookie.replace(
      /(?:(?:^|.*;\s*)access\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
    accessToken &&
      setLoggedInUser(
        allUsers.find(
          (user) =>
            user.id === JSON.parse(atob(accessToken.split(".")[1])).user_id
        )
      );
    // eslint-disable-next-line no-useless-escape
  }, [allUsers]);

  const fetchUsers = async () => {
    try {
      const res = await fget({
        url: `project/${props.project}/users/`,
      });

      setAllUsers(
        res.data.results.map((el) => {
          const data = {
            name: el.first_name + " " + el.last_name,
            id: el.id,
          };
          return data;
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
  const getProjectDetails = async () => {
    try {
      const res = await fget({
        url: `project/${props.project}/`,
      });
      setProjectDetails(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ height: `calc(100vh - 130px)` }}>
      <Grid container spacing={2} style={{ height: "100%" }}>
        <Grid item md={4} style={{ height: "100%" }}>
          <ProjectHealth
            projectDetails={projectDetails}
            projectId={props.project}
            loggedInUser={loggedInUser}
          />
        </Grid>
        <Grid item md={4} style={{ height: "100%" }}>
          <TaskOverview
            projectId={props.project}
            users={allUsers}
            loggedInUser={loggedInUser}
            projectDetails={projectDetails}
          />
        </Grid>
        <Grid item md={4} style={{ height: "100%" }}>
          <ProjectUpdates users={allUsers} projectId={props.project} />
        </Grid>
      </Grid>
    </div>
  );
}

export default UpdatesOverview;
