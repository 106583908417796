import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cardContentDiv2: {
    width: "100%",
    height: "75px",
    display: "grid",
    placeItems: "center",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    borderStyle: "dashed",
    border: `2px solid ${theme.palette.text.tertiary}`,
    marginTop: "2%",
  },
  displayFlex: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
  },
  closeIcon: {
    color: theme.palette.text.primary,
    cursor: "pointer",
  },
  fileIcon: {
    color: theme.palette.text.primary,
    position: "absolute",
    left: "0",
    marginLeft: "2rem",
  },
  label: {
    height: "100%",
    width: "100%",
    cursor: "pointer",
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
  },
  labelText: {
    textAlign: "center",
  },
  fileName: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    padding: "0px 17px",
    overflow: "hidden",
    wordBreak: "break-all",
  },
  deleteContainer: {
    height: "100%",
    border: "none",
    width: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderLeft: `2px solid ${theme.palette.text.tertiary}`,
    borderLeftStyle: "dashed",
    "&:hover": {
      backgroundColor: "rgb(178,34,34,0.3)",
    },
  },
}));

export default useStyles;
