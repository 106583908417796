import React from "react";
import useStyles from "./UpdatesPageCardCommentDiv.styles";
import {
  Divider,
  Modal,
  Avatar,
  Button,
  Tooltip,
  Card,
} from "@material-ui/core";
import { useState } from "react";
import { fdelete, fgetNextPage } from "../../../API/callsAPI";
import DeleteModal from "../../Utilities/DeleteModal/DeleteModal.component";
import ErrorOrSuccessMessage from "../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";
import { CornerDownLeft, X } from "react-feather";

function UpdatesPageCardCommentDiv(props) {
  const classes = useStyles();
  //States
  // const [commentList, setCommentList] = useState(props.commentResult); //store the comment object which contains list and details of comment
  const [nextPageLoading, setNextPageLoading] = useState(false); //check if the next page is loading or not
  const [hasMore, setHasMore] = useState(props.commentList.next ? true : false); //to check if there are more comments to load
  const [nextPageUrl, setNextPageUrl] = useState(props.commentList.next);
  const [displayImage, setDisplayImage] = useState(null);
  const [imageModal, setImageModal] = useState(false);
  const [selectedCommentId, setSelectedCommentId] = useState();
  const [isDeleteCommentModalOpen, setDeleteCommentModalOpen] = useState(false);
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });

  const displayImageToggle = () => setImageModal(!imageModal);
  //Function To fetch comments when page changes
  const fetchComments = () => {
    setHasMore(false);
    setNextPageLoading(true);
    fgetNextPage({
      url: nextPageUrl,
    })
      .then((res) => res.data)
      .then(
        (result) => {
          props.setCommentList((prevComments) => [
            ...prevComments,
            ...result.results,
          ]);
          if (result.next) {
            setNextPageUrl(result.next);
            setHasMore(true);
          }
          setNextPageLoading(false);
        },
        (error) => {
          setNextPageLoading(false);
          setHasMore(false);
        }
      );
  };

  //function to deleteComment
  const deleteComment = (e, ob) => {
    setSelectedCommentId(ob.id);
    setDeleteCommentModalOpen(true);
  };

  const handleDeleteComment = async () => {
    try {
      await fdelete({
        url: `trackables/trackable-comment/${selectedCommentId}/`,
      });
      setSnackValue({
        isOpen: true,
        message: `SuccessFully deleted comment`,
        isError: false,
      });
      setDeleteCommentModalOpen(false);
      var a = props.commentList.filter((e) => {
        return e.id !== selectedCommentId;
      });
      props.setCommentList(a);
      props.updateCommentCount(a.length);
      setTimeout(() => props.closeDeleteCommentModalState(), 1500);
    } catch (error) {
      setDeleteCommentModalOpen(false);
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  const getDisplayTime = (time) => {
    if (time.includes(":")) {
      return time;
    }
    let seconds = 0;
    let minutes = 0;
    if (time % 60) {
      minutes = parseInt(time / 60);
      seconds = parseInt(time % 60);
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
    }
    return `${minutes}:${seconds}`;
  };

  const scrollTo = (comment) => {
    const scrollItem = document.getElementById(comment?.replied_to?.id);
    scrollItem &&
      scrollItem.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <>
      <ErrorOrSuccessMessage snackValue={snackValue} />
      <Modal
        open={imageModal}
        onClose={displayImageToggle}
        className={classes.modal1}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            outline: "none",
            height: "80vh",
          }}
        >
          <X style={{ alignSelf: "flex-end" }} onClick={displayImageToggle} />
          <img src={displayImage} height="90%" alt="attachment"></img>
        </div>
      </Modal>
      <div
        style={{
          maxHeight: "400px",
          overflowY: "auto",
          padding: "5px 12px 5px 5px",
        }}
      >
        {props.commentResult.length > 0
          ? props.commentResult.map((singleComment, index) => {
              return (
                <div id={singleComment.id}>
                  <div className={classes.commentHead}>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {singleComment?.author?.avatar ? (
                        <img
                          className={classes.userImage}
                          src={singleComment?.author?.avatar}
                          alt=""
                        />
                      ) : (
                        <Avatar className={classes.avatar}>
                          {singleComment?.author?.first_name[0]
                            ?.slice()[0]
                            ?.toUpperCase() +
                            singleComment?.author?.last_name[0]
                              ?.slice()[0]
                              ?.toUpperCase()}
                        </Avatar>
                      )}
                      <span style={{ fontSize: "18px" }}>
                        {singleComment.author.first_name +
                          " " +
                          singleComment.author.last_name}
                      </span>
                    </span>
                    <div className={classes.date}>
                      <span>
                        {new Intl.DateTimeFormat("en-US", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                        }).format(new Date(singleComment.created_at))}
                      </span>
                      <span>|</span>
                      <span>
                        {new Intl.DateTimeFormat("en-US", {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        }).format(new Date(singleComment.created_at))}
                      </span>
                    </div>
                    {/* {ob.author.id === props.loggedInUser?.user_id ? (
                <div
                  onClick={(e) => {
                    deleteComment(e, ob);
                  }}
                >
                  <TrashBin />
                </div>
              ) : (
                ""
              )} */}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "start",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "50px",
                      }}
                    >
                      {singleComment?.replied_to !== null ? (
                        <Card
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                            padding: "5px 10px",
                            gap: "15px",
                          }}
                          onClick={() => {
                            scrollTo(singleComment);
                          }}
                        >
                          {singleComment.replied_to.attachment ? (
                            <img
                              style={{
                                borderRadius: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setDisplayImage(
                                  singleComment.replied_to.attachment
                                );
                                displayImageToggle();
                              }}
                              alt="projectImage"
                              src={singleComment.replied_to.attachment}
                              height="50px"
                              width="50px"
                              onContextMenu={(e) => e.preventDefault()}
                            />
                          ) : null}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: singleComment?.replied_to?.description
                                ?.split("<p>")[1]
                                ?.split("</p>")[0],
                            }}
                          ></span>
                          <div className={classes.time}>
                            <span>
                              {new Intl.DateTimeFormat("en-US", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                              }).format(
                                new Date(singleComment.replied_to?.created_at)
                              )}
                            </span>
                            <span>|</span>
                            <span>
                              {new Intl.DateTimeFormat("en-US", {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              }).format(
                                new Date(singleComment.replied_to?.created_at)
                              )}
                            </span>
                          </div>
                        </Card>
                      ) : (
                        ""
                      )}
                      {singleComment?.description?.split("<p>")[0] ? (
                        <span
                          style={{
                            cursor: "pointer",
                            color: "#008cdb",
                          }}
                          onClick={(e) =>
                            props.changeSeekTime(
                              singleComment?.description?.split("<p>")[0]
                            )
                          }
                        >
                          {getDisplayTime(
                            singleComment?.description?.split("<p>")[0]
                          )}
                        </span>
                      ) : null}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: singleComment?.description
                            ?.split("<p>")[1]
                            ?.split("</p>")[0],
                        }}
                      ></span>
                    </div>
                    <Tooltip title="Reply" placement="top-start">
                      <Button
                        style={{
                          padding: "0",
                          margin: "0",
                          minWidth: "25px",
                        }}
                        onClick={() => {
                          props.setRepliedTo(singleComment);
                        }}
                      >
                        <CornerDownLeft
                          style={{ hight: "16px", width: "16px" }}
                        />
                      </Button>
                    </Tooltip>
                  </div>
                  {singleComment.attachment ? (
                    <img
                      className={classes.AssetImage}
                      onClick={() => {
                        setDisplayImage(singleComment.attachment);
                        displayImageToggle();
                      }}
                      alt="projectImage"
                      src={singleComment.attachment}
                      width="75%"
                    />
                  ) : null}
                  <Divider
                    style={{
                      display:
                        props?.commentResult.length === index + 1
                          ? "none"
                          : "block",
                      marginBottom: "5px",
                    }}
                  />
                </div>
              );
            })
          : ""}
      </div>
      <div>
        <Modal
          className={classes.updatesPageModals}
          open={isDeleteCommentModalOpen}
          onClose={() => setDeleteCommentModalOpen(false)}
        >
          <DeleteModal
            type="Comment"
            name={selectedCommentId}
            handleCloseDeleteModal={() => {
              setDeleteCommentModalOpen(false);
            }}
            handleDelete={handleDeleteComment}
          />
        </Modal>
      </div>
    </>
  );
}

export default UpdatesPageCardCommentDiv;
