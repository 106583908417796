import { Avatar } from "@material-ui/core";
import React from "react";
import useStyles from "./UserAvatar.style";

const UserAvatar = ({ avatar, firstName, lastName }) => {
  const classes = useStyles();
  return (
    <>
      {avatar ? (
        <img className={classes.userImage} src={avatar} alt="" />
      ) : (
        <Avatar className={classes.avatar}>
          {firstName?.slice()[0]?.toUpperCase() +
            lastName?.slice()[0]?.toUpperCase()}
        </Avatar>
      )}
    </>
  );
};

export default UserAvatar;
