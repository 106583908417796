import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
  Modal,
} from "@material-ui/core";

import * as Icon from "react-feather";
//Validators
import { useFormik } from "formik";
import * as yup from "yup";
//
import useStyles from "./AssetVersionEditAssetModal.styles";
import { Asterisk } from "phosphor-react";

import { fdelete, fget, fpatch, uploadFile } from "./../../../API/callsAPI";
import { useHistory } from "react-router-dom";
import UploadFile from "../../Utilities/UploadFile/UploadFile";
import DragScreen from "../../Utilities/DragScreen/DragScreen";
import ModalTitleAndButtons from "../../Utilities/ModalTitleAndButtons/ModalTitleAndButtons.component";
import ErrorOrSuccessMessage from "../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";
import ConfirmDeleteFile from "../../Utilities/ConfirmDeleteFile/ConfirmDeleteFile.component";
import HelperText from "../../Utilities/HelperText/HelperText";

const AssetVersionEditVersionModal = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [file, setFileUpload] = useState(null);
  const [fileTouched, setFileTouched] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [preLoadedFile, setPreLoadedFile] = useState(false);
  const [selectedTask, setSelectedTask] = useState(
    props.data.task?.id ? props.data.task?.id : null
  );
  const [deleteFileModal, setDeleteFileModal] = useState(false);

  useEffect(() => {
    if (props?.data?.file) {
      const fileNameArray = props.data.file.split("/");
      setFileUpload(fileNameArray[fileNameArray.length - 1]);
      setPreLoadedFile(true);
    }
    // fetching the tasks only if the taskList is empty
    // if its fetched in new version modal no need to make the api call again
    !props.taskList.length && props.fetchTasks();
  }, []);

  const displaySelectedFile = (event) => {
    let file = event.target.files[0];
    event.target.value = null;
    setFileUpload(file);
    setFileTouched(true);
  };

  const handleClearUpload = (event) => {
    event?.stopPropagation();
    setFileUpload(null);
    setFileTouched(false);
  };

  const [snackValue, setSnackValue] = React.useState({
    isOpen: false,
    message: "",
    isError: false,
  });

  //Validators
  const EditAssetVersionValidationScheme = yup.object({
    assetVersionName: yup
      .string("String required")
      .label("assetName")
      .min(1, "Too Short!")
      .max(50, "Maximum Character Limit: 50")
      .required(),
  });

  const formik = useFormik({
    //add initail values for editing a version
    initialValues: {
      assetVersionName: props.data.name,
      status: props.data.status ? props.data.status.id : "",
    },
    validationSchema: EditAssetVersionValidationScheme,
    onSubmit: async (values) => {
      setLoading(true);
      setFileUploading(true);
      let formdata = new FormData();
      formdata.append("name", values.assetVersionName);
      formdata.append("status", values.status);
      const isFileUpdated = typeof file === "string" ? false : true;
      if (isFileUpdated) {
        if (file) {
          formdata.append("file_name", file.name);
        }
      } else {
        // formdata.append("file_name", file);
      }
      selectedTask !== null && formdata.append("task", selectedTask);

      let editedVersion = {
        url: `trackables/asset-version/${props.data.id}/`,
        data: formdata,
      };
      try {
        const editVersionRes = await fpatch(editedVersion);
        if (editVersionRes.status === 200 || 201) {
          if (isFileUpdated && file) {
            getSignedUrl(editVersionRes.data.id);
          } else {
            setLoading(false);
            setSnackValue({
              isOpen: true,
              message: `SuccessFully Edited ${editVersionRes?.data?.name}`,
              isError: false,
            });
            setFileUploading(false);
            props.updateRefreshCounter();
            props.handleEditAssetVersionModalState();
          }
        }
      } catch (error) {
        setLoading(false);
        setSnackValue({
          isOpen: true,
          message: `${error}`,
          isError: true,
        });
      }
    },
  });

  const getSignedUrl = async (newId) => {
    try {
      const uploadUrl = await fget({
        url: `trackables/assetversion/${newId}/upload`,
      });
      if (uploadUrl.status === 200) {
        await uploadFile({ url: uploadUrl.data?.signed_url, data: file });
        fget({
          url: `trackables/assetversion/${newId}/thumbnail`,
        });
        setLoading(false);
        setSnackValue({
          isOpen: true,
          message: `SuccessFully Edited ${props?.data?.name}`,
          isError: false,
        });
        setFileUploading(false);
        props.updateRefreshCounter();
        props.handleEditAssetVersionModalState();
      }
    } catch (error) {
      setLoading(false);
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  const handleDragFile = (e) => {
    if (e.type == "dragenter") {
      //when  file enter- display dragscreen
      props.handleDragScreen(true);
      props.setDragFileEnterModal(true);
    }
    //catching the dragged file for uploading
    if (e.type === "drop") {
      props.handleDragScreen(false);
      setFileUpload(e.dataTransfer.files[0]);
      setFileTouched(true);
      setPreLoadedFile(false);
    }
    e?.preventDefault();
    e?.stopPropagation();
  };

  const handleDeleteFile = async (e) => {
    if (preLoadedFile) {
      try {
        await fdelete({
          url: `trackables/assetversion/${props.data.id}/file`,
        });
        props.updateRefreshCounter();
        setDeleteFileModal(false);
        setSnackValue({
          isOpen: true,
          message: `file deleted successfully`,
          isError: false,
        });
        handleClearUpload(e);
      } catch (error) {
        setSnackValue({
          isOpen: true,
          message: `Something went wrong in file delete`,
          isError: true,
        });
      }
    } else {
      setDeleteFileModal(false);
      setPreLoadedFile(false);
      handleClearUpload(e);
    }
  };

  return (
    <div className={classes.root} onClick={(e) => e?.stopPropagation()}>
      <ErrorOrSuccessMessage snackValue={snackValue} />
      <Card
        className={classes.card}
        onDragEnter={(e) => handleDragFile(e)}
        onDrop={(e) => handleDragFile(e)}
        onDragLeave={(e) => handleDragFile(e)}
        onDragOver={(e) => handleDragFile(e)}
      >
        <CardContent className={classes.cardContent}>
          <DragScreen dragScreen={props.dragScreen} />
          <form enctype="multipart/form-data" onSubmit={formik.handleSubmit}>
            <ModalTitleAndButtons
              name="Version"
              type="Edit"
              closeModal={(e) => props.handleEditAssetVersionModalState(e)}
              openDeleteModal={(e) =>
                props.handleDeleteAssetVersionModalState(e, props.data)
              }
            />
            <div className={classes.cardContentDiv1}>
              <div>
                <span>Version Name</span>
                <Asterisk size={12} color="#FF0000" weight="fill" />
                <div
                  className={classes.cardContentDiv2}
                  style={{ marginTop: "5px" }}
                >
                  <TextField
                    type="text"
                    className={classes.cardContentInput1}
                    InputProps={{ disableUnderline: true }}
                    name="assetVersionName"
                    value={formik.values.assetVersionName}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.assetVersionName)}
                  />
                </div>
                {formik.errors.assetVersionName &&
                formik.touched.assetVersionName ? (
                  <HelperText text={formik.errors.assetVersionName} />
                ) : null}
              </div>
              <div style={{ marginTop: "25px" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <span>Status</span>
                    <div
                      className={classes.cardContentDiv2}
                      style={{ marginTop: "5px", width: "150px" }}
                    >
                      <Select
                        disableUnderline={true}
                        style={{ width: "100%" }}
                        labelId="demo-simple-select-outlined-label"
                        className={classes.cardContentInput1}
                        id="selectStatusV"
                        name="status"
                        value={formik.values.status}
                        MenuProps={{
                          classes: {
                            paper: classes.dropDown,
                          },
                        }}
                        onChange={formik.handleChange}
                        label="Select Status"
                      >
                        <MenuItem
                          value=""
                          className={classes.addNewStatusButton}
                          onClick={() => {
                            history.push(`/${props.projectId}/settings`);
                          }}
                        >
                          <Icon.Plus />
                          Add new Status
                        </MenuItem>
                        <MenuItem value="" disabled>
                          Select Status
                        </MenuItem>
                        {props.status &&
                          props.status.map((eachStatus) => (
                            <MenuItem value={eachStatus.id}>
                              <span
                                style={{
                                  height: "15px",
                                  width: "15px",
                                  backgroundColor: `rgb(${eachStatus?.color})`,
                                  borderRadius: "50%",
                                  display: "inline-block",
                                  margin: "0px 5px",
                                }}
                              ></span>
                              {eachStatus.name} - {eachStatus.code}
                            </MenuItem>
                          ))}
                      </Select>
                    </div>
                  </div>
                  <div style={{ maxWidth: "50%" }}>
                    <span>Status Code</span>
                    <div
                      style={{ marginTop: "5px" }}
                      className={classes.cardContentDiv2}
                    >
                      <TextField
                        className={classes.cardContentInput1}
                        InputProps={{
                          disableUnderline: true,
                          style: { opacity: "0.4" },
                        }}
                        disabled
                        fullWidth
                        value={
                          props.status.find(
                            (eachStatus) =>
                              eachStatus.id === formik.values.status
                          )?.code
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <span style={{ display: "block", margin: "25px 0 5px 0" }}>
                  Link to Task
                </span>
                <Select
                  style={{ width: "100%" }}
                  id="linkToTask"
                  label="Link to Task"
                  name="task"
                  className={classes.cardContentDiv2}
                  disableUnderline
                  value={selectedTask}
                  onChange={(e) => setSelectedTask(e.target.value)}
                  MenuProps={{
                    classes: {
                      paper: classes.dropDown,
                    },
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  {props.taskList &&
                    props.taskList.map((task) => (
                      <MenuItem value={task.id}>{task.name}</MenuItem>
                    ))}
                </Select>
              </div>
              <div
                style={{
                  padding: "0px 6px",
                  marginTop: "25px",
                }}
              >
                Attachment
                <UploadFile
                  thumbnail={file}
                  preLoadedFile={preLoadedFile}
                  handleClearUpload={handleClearUpload}
                  handleThumbnailUpload={displaySelectedFile}
                  setDeleteFileModal={setDeleteFileModal}
                />
              </div>
            </div>
            <br />

            <CardActions className={classes.cardAction}>
              <Button
                fullWidth
                className={classes.done}
                type="submit"
                value="Submit"
                size="small"
                disableElevation
              >
                {loading ? (
                  <CircularProgress style={{ width: "25px", height: "25px" }} />
                ) : (
                  "Done"
                )}
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
      <div>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={deleteFileModal}
          onClose={() => setDeleteFileModal(false)}
        >
          <ConfirmDeleteFile
            name={typeof file === "object" ? file?.name : file}
            closeModal={() => {
              setDeleteFileModal(false);
            }}
            deleteFile={() => {
              handleDeleteFile();
            }}
          />
        </Modal>
      </div>
    </div>
  );
};

export default AssetVersionEditVersionModal;
