import React from "react";
import { ScaleLoader } from "react-spinners";

const NewDataLoadingLoader = ({ trackable }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "24px",
      }}
    >
      <ScaleLoader
        color="#6C65D9"
        height={55}
        style={{
          display: "flex",
          verticalAlign: "middle",
        }}
      />
      <div style={{ fontSize: "48px" }}>Please Wait</div>
      <div style={{ fontSize: "24px" }}>Loading More {trackable}...</div>
    </div>
  );
};

export default NewDataLoadingLoader;
