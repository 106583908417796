import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  buttons: {
    gap: "10px",
    display: "flex",
  },

  addNewTemplateButton: {
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    width: "auto",
    borderRadius: "10px",
    fontSize: "14px",
    padding: "12px",
    color: theme.palette.text.secondary,
  },
  importTemplateButton: {
    height: "40px",
    width: "auto",
    borderRadius: "10px",
    fontSize: "14px",
    padding: "12px",
    color: theme.palette.text.secondary,
    border: `2px solid ${theme.palette.secondary.main}`,
  },
  buttonName: {
    marginLeft: "10px",
  },
}));

export default useStyles;
