import React, { useState, useEffect, useRef, memo } from "react";
import { useHistory } from "react-router";
//@MUI
import {
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
//@API
import { fget, fpostBFF } from "./../../../API/callsAPI";

//@css
import useStyles from "./AssetsOverview.styles";

//@Components
import AssetsTools from "../AssetsPageTool/AssetsPageTool.component";
import AssetList from "../AssetsList/AssetsList.component";
import AssetsPageNewAssetModal from "../AssetsPageModal/AssetsPageNewAssetModal";
import NoData from "../../../Pages/InformativeComponents/NoData.component";
import CustomPagination from "../../Utilities/CustomPagination/CustomPagination.component";
import PropTypes from "prop-types";
import { checkImage } from "../../utils";
import Loader from "../../Utilities/Loader/Loader";
import ErrorOrSuccessMessage from "../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";
import NewDataLoadingLoader from "../../Utilities/NewDataLoadingLoader/NewDataLoadingLoader.component";

function AssetsOverview(props) {
  const classes = useStyles();
  const history = useHistory();
  const tableRef = useRef();

  const [isNewAssetModalOpen, setNewAssetModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [sortByDefault, setSortByDefault] = useState("");
  const [assetType, setAssetType] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [assetList, setAssetList] = useState();
  const [sliderState, setSliderState] = useState(0);
  const [assetFilterStatus, setAssetFilterStatus] = useState("");
  const [assetFilterType, setAssetFilterType] = useState("");
  const [departments, setDepartments] = useState([]);
  const [allUsers, setAllUsers] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [isAssetTogglerOn, setIsAssetTogglerOn] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0); //state that handles refresh counter
  const [signedUrl, setSignedUrl] = useState("");
  const [dragScreen, setDragScreen] = useState(false);
  //state will be true when we start drag file inside modals
  const [dragFileEnterModal, setDragFileEnterModal] = useState(false);
  const [scrollValue, setScrollValue] = useState("");
  const [updateOnPageChange, setUpdateOnPageChange] = useState(0);
  const [assetStats, setAssetStats] = useState({ total: 0, visibleCount: 0 });
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  const [isNewPageLoading, setIsNewPageLoading] = useState(false);

  const decodedURL = decodeURIComponent(
    escape(window.atob(history.location.search.substring(1)))
  );
  let searchParams = new URLSearchParams(decodedURL);

  useEffect(() => {
    fetchAssetType();
    fetchAsset();
    setAssetFilterType(searchParams.get("type") || "");
    setAssetFilterStatus(searchParams.get("status") || "");
    setSortByDefault(searchParams.get("sortBy") || "A-Z");
    var scrollStored = JSON.parse(localStorage.getItem("scroll"));
    if (scrollStored) {
      setScrollValue(scrollStored.asset);
    } else {
      const scrollData = {
        asset: "0",
        assetVersion: "0",
        sequence: "0",
        shots: "0",
        shotVersion: "0",
      };
      localStorage.setItem("scroll", JSON.stringify(scrollData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); //add refreshCounter to dep array so when it chnges the functions will be called

  const updateRefreshCounter = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  const handlePageChange = (pageNo) => {
    setCurrentPage(pageNo);
    setUpdateOnPageChange(updateOnPageChange + 1);
  };

  /** Handles state of modal */
  const handleNewAssetModalState = ({ closeFlag }) => {
    setNewAssetModalOpen(!isNewAssetModalOpen);
  };

  const handleAssetToggler = () => {
    setIsAssetTogglerOn(!isAssetTogglerOn);
  };

  //Fetch status types
  const fetchAssetType = async () => {
    try {
      const assetTypeRes = await fget({
        url: `trackables/asset-type-choices/`,
      });
      setAssetType(assetTypeRes.data.asset_type_choices);
    } catch (error) {
      console.log(error);
    }
  };

  // @fetches assets list from bff at initial mount
  const fetchAsset = async () => {
    let asset = {
      url: "web/asset/list/",
      data: {
        project_id: props.params.projectId,
      },
    };
    try {
      const fetchAssetRes = await fpostBFF(asset);
      if (
        (searchParams.get("type") === null &&
          searchParams.get("status") === null) ||
        (searchParams.get("type")?.length === 0 &&
          searchParams.get("status")?.length === 0)
      ) {
        if (fetchAssetRes.data.asset_list.detail) {
          console.log(fetchAssetRes.data.asset_list.detail); //handle error here
        } else {
          setAssetList(fetchAssetRes.data.asset_list);
          setIsLoaded(true);
        }
      }
      setStatusList(fetchAssetRes?.data?.status_list?.results);
      setAssetStats((prevData) => ({
        ...prevData,
        visibleCount: fetchAssetRes.data.asset_list.count,
      }));
      setAllUsers(fetchAssetRes.data.project_users.results);
      if (fetchAssetRes.data.department_list.detail) {
        console.log(fetchAssetRes.data.department_list.detail); //handle error here
      } else {
        setDepartments(
          fetchAssetRes.data.department_list.results.map((el) => {
            const data = {
              title: el.name,
              id: el.id,
            };
            return data;
          })
        );
      }
      window.scrollTo(0, sessionStorage.getItem("scrollpos"));
    } catch (error) {
      setIsLoaded(true);
      //if bff fails try load each url manually!
      console.log(error);
    }
  };

  const getAssetStats = async () => {
    try {
      const assetRes = await fget({
        url: `trackables/trackable-count/asset/${props.params.projectId}/`,
      });
      setAssetStats((prevData) => ({
        ...prevData,
        total: assetRes.data.instances,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      parseInt(assetFilterStatus) > 0 ||
      assetFilterType.length ||
      updateOnPageChange ||
      refreshCounter
    ) {
      fetchFilteredAsset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    assetFilterStatus,
    assetFilterType,
    currentPage,
    updateOnPageChange,
    refreshCounter,
  ]);

  useEffect(() => {
    getAssetStats();
  }, [refreshCounter]);

  const fetchFilteredAsset = async () => {
    // setIsLoaded(false);
    const url = `trackables/asset/?page=${currentPage}&project=${props.params.projectId}&type=${assetFilterType}&status=${assetFilterStatus}&sortBy=${sortByDefault}`;
    try {
      const filterAssetRes = await fget({
        url: `trackables/asset/?page=${currentPage}&project=${props.params.projectId}&type=${assetFilterType}&status=${assetFilterStatus}`,
      });
      setIsLoaded(true);
      setAssetList(filterAssetRes.data);
      setIsNewPageLoading(false);
    } catch (error) {
      console.log("error", error);
      setIsNewPageLoading(false);
    }
    history.push("?" + btoa(unescape(encodeURIComponent(url.split("?")[1]))));
  };

  async function fetchFile(id) {
    if (id) {
      // fetching the signed url only if file is there

      try {
        const res = await fget({
          url: `trackables/asset/${id}/file`,
        });
        setSignedUrl(res.data.signed_url);
      } catch (error) {
        setSignedUrl("");
        console.log(error);
      }
    } else setSignedUrl("");
  }

  useEffect(() => {
    assetList?.results &&
      searchParams.get("sortBy") &&
      handleSubmitSort(searchParams.get("sortBy"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetList]);

  //Function to sort Assets
  function handleSubmitSort(sortBy) {
    searchParams.set("sortBy", `${sortBy}`);
    history.push(
      "?" + btoa(unescape(encodeURIComponent(searchParams.toString())))
    );
    switch (sortBy) {
      case "A-Z":
        assetList?.results?.sort((a, b) => a.name.localeCompare(b.name));
        setSortByDefault("A-Z");
        break;
      case "Z-A":
        assetList?.results?.sort((a, b) => b.name.localeCompare(a.name));
        setSortByDefault("Z-A");
        break;
      case "LatestModified":
        assetList?.results?.sort(function (a, b) {
          var dateA = new Date(a.updated_at),
            dateB = new Date(b.updated_at);
          return dateB - dateA;
        });
        setSortByDefault("LatestModified");
        break;
      case "LastModified":
        assetList?.results?.sort(function (a, b) {
          var dateA = new Date(a.updated_at),
            dateB = new Date(b.updated_at);
          return dateA - dateB;
        });
        setSortByDefault("LastModified");
        break;
      case "DateCreated":
        assetList?.results?.sort(function (a, b) {
          var dateA = new Date(a.created_at),
            dateB = new Date(b.created_at);
          return dateA - dateB;
        });
        setSortByDefault("DateCreated");
        break;
      default:
        return assetList?.results;
    }
  }

  const handleDragFile = (e) => {
    setDragScreen(true);
    if (e.type === "drop") {
      //if we drop file , removing drag zone screen
      setDragScreen(false);
    }
    if (e.type === "dragleave") {
      //if we leave the drag file , removing drag zone screen
      if (dragFileEnterModal) {
        setDragScreen(true);
      } else {
        setDragScreen(false);
      }
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragScreen = (el) => {
    setDragScreen(el);
  };

  let scrollTimer = null;
  const handleTableScroll = (e) => {
    if (scrollTimer !== null) clearTimeout(scrollTimer);

    scrollTimer = setTimeout(() => {
      searchParams.set("scroll", `${e.target.scrollTop}`);
      history.replace(
        "?" + btoa(unescape(encodeURIComponent(searchParams.toString())))
      );
      let scrollStored = JSON.parse(localStorage.getItem("scroll"));
      scrollStored.asset = e.target.scrollTop;
      localStorage.setItem("scroll", JSON.stringify(scrollStored));
    }, 700);
  };

  return (
    <div
      className={classes.root}
      onDragEnter={(e) => handleDragFile(e)}
      onDrop={(e) => handleDragFile(e)}
      onDragLeave={(e) => handleDragFile(e)}
      onDragOver={(e) => handleDragFile(e)}
    >
      <ErrorOrSuccessMessage snackValue={snackValue} />
      <AssetsTools
        openNewAssetModal={handleNewAssetModalState}
        status={statusList}
        assetType={assetType}
        handleSliderChange={(value) => {
          setSliderState(value);
          sessionStorage.setItem("sliderState", `${sliderState}`);
        }}
        sliderState={sliderState}
        handleStatusChange={(value) => {
          if (assetFilterType === "" && value === "") {
            history.replace(history.location.pathname);
            window.location.reload();
          } else setAssetFilterStatus(value);
        }}
        handleTypeChange={(value) => {
          if (assetFilterStatus === "" && value === "") {
            history.replace(history.location.pathname);
            window.location.reload();
          } else setAssetFilterType(value);
        }}
        filterValues={{ status: assetFilterStatus, type: assetFilterType }}
        handleAssetToggler={handleAssetToggler}
        onSubmit={handleSubmitSort}
        defaultvalue={sortByDefault}
        assetStats={assetStats}
      />
      {isLoaded ? (
        <>
          {assetList && assetList.count !== 0 ? (
            <>
              <TableContainer className={classes.sequenceTableContainer}>
                <Table aria-label="simple table" className={classes.table}>
                  <TableHead className={classes.tableHeader}>
                    <TableRow className={classes.tableRow}>
                      <TableCell className={classes.headTableTwo} align="left">
                        Asset Name
                      </TableCell>
                      <TableCell className={classes.headTable} align="center">
                        Type
                      </TableCell>
                      <TableCell className={classes.headTable} align="center">
                        Status
                      </TableCell>
                      <TableCell className={classes.headTable} align="center">
                        Last Modified
                      </TableCell>
                      <TableCell className={classes.headTable} align="center">
                        Departments
                      </TableCell>
                      <TableCell className={classes.headTableTwo} align="right">
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    className={classes.tableBody}
                    onScroll={handleTableScroll}
                    ref={tableRef}
                    onLoad={() => (tableRef.current.scrollTop = scrollValue)}
                    style={{
                      height:
                        assetList?.count > 50
                          ? `calc(100vh - 290px)`
                          : `calc(100vh - 250px)`,
                    }}
                  >
                    {assetList && assetList.count !== 0
                      ? assetList.results
                          ?.filter((search) => {
                            if (props.searchQuery === undefined) {
                              return search;
                            } else if (props.searchQuery === "") {
                              return search;
                            } else if (
                              search.name
                                ?.toLowerCase()
                                .includes(props.searchQuery.toLowerCase())
                            ) {
                              return search;
                            }
                            return null;
                          })
                          .map((res) => {
                            return (
                              <AssetList
                                key={res.id}
                                assetType={assetType}
                                data={res}
                                projectId={props.params.projectId}
                                statusList={statusList}
                                sliderState={sliderState}
                                isAssetTogglerOn={isAssetTogglerOn}
                                updateRefreshCounter={updateRefreshCounter}
                                setIsViewModalOpen={setIsViewModalOpen}
                                fetchFile={fetchFile}
                                dragScreen={dragScreen}
                                handleDragScreen={handleDragScreen}
                                setDragFileEnterModal={setDragFileEnterModal}
                                setSnackValue={setSnackValue}
                              />
                            );
                          })
                      : " "}
                  </TableBody>
                </Table>
              </TableContainer>
              {assetList && assetList.count > 50 ? (
                <footer style={{ bottom: "0", height: "100%" }}>
                  <div>
                    <CustomPagination
                      handlePageChange={handlePageChange}
                      currentPage={currentPage}
                      totalItem={assetList?.count}
                      totalPage={Math.ceil(assetList?.count / 50)}
                      setIsNewPageLoading={setIsNewPageLoading}
                      trackable="Assets"
                    />
                  </div>
                </footer>
              ) : (
                ""
              )}
            </>
          ) : (
            <NoData />
          )}
        </>
      ) : (
        <Loader />
      )}
      <div>
        <Modal className={classes.assetsPageModals} open={isNewPageLoading}>
          <NewDataLoadingLoader trackable="Assets" />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.assetsPageModals}
          open={isNewAssetModalOpen}
          onClose={() => setNewAssetModalOpen(false)}
        >
          <AssetsPageNewAssetModal
            users={allUsers}
            status={statusList}
            assetType={assetType}
            dragScreen={dragScreen}
            handleDragScreen={handleDragScreen}
            setDragFileEnterModal={setDragFileEnterModal}
            departments={departments}
            projectId={props.params.projectId}
            handleNewAssetModal={handleNewAssetModalState}
            updateRefreshCounter={updateRefreshCounter}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.assetsPageModals}
          open={isViewModalOpen}
          onClose={() => {
            setIsViewModalOpen(false);
          }}
        >
          {signedUrl ? (
            checkImage(signedUrl) ? (
              <img
                src={signedUrl}
                className="card-img-top"
                style={{ width: "500px", height: "auto" }}
                alt="asset"
              />
            ) : (
              <video
                src={signedUrl}
                controls
                autoPlay
                style={{ width: "auto", height: "500px" }}
              />
            )
          ) : (
            <span>No file found</span>
          )}
        </Modal>
      </div>
    </div>
  );
}

export default AssetsOverview;

AssetsOverview.propTypes = {
  searchQuery: PropTypes.string,
};
