import React, { useState } from "react";
import { Reorder, useDragControls } from "framer-motion";
import useStyles from "./RearrangeReviewer.styles";
import { DotsSixVertical } from "phosphor-react";
import { Divider, Modal, Button } from "@material-ui/core";
import UserViewerForManageUsers from "../../Utilities/ManageUsers/UsersViewerForManageUsers/UserViewerForManageUsers.component";
import SelectUserModal from "../../Utilities/ManageUsers/SelectUsersModal/SelectUserModal.component";
import UserAvatar from "../../Utilities/UserAvatar/UserAvatar.component";

export default function RearrangeReviewer({
  closeModal,
  usersList,
  handleSelectedUser,
}) {
  const classes = useStyles();
  const [isSelectUserModalOpen, setSelectUserModalOpen] = useState(false);
  const [users, setUsers] = useState(usersList);
  const [arrangeUsers, setArrangeUsers] = useState(users);
  const dragControls = useDragControls();

  const handleSelectUserModalState = () => {
    setSelectUserModalOpen(!isSelectUserModalOpen);
  };

  const handleSelectUser = (user) => {
    setUsers(user);
    setArrangeUsers(user);
  };

  const handleRearrangeOder = (e) => {
    setArrangeUsers(e);
  };

  return (
    <div className={classes.container}>
      <p className={classes.containerHeader}>Arrange Reviewer</p>
      <div style={{ marginTop: "18px" }}>
        <span className={classes.containerText}>Reviewers</span>
        <Divider variant="inset" className={classes.divider} />
      </div>
      <div
        style={{
          marginTop: "17px",
          marginBottom: "30px",
          width: "100%",
        }}
      >
        <UserViewerForManageUsers
          currentUsers={users}
          handleSelectUserModalChange={handleSelectUserModalState}
          handleSelectUsers={handleSelectUser}
        />
      </div>
      <div
        style={{
          marginBottom: "36px",
          maxHeight: "240px",
          overflowY: "scroll",
        }}
      >
        <Reorder.Group
          className={classes.reorderGroup}
          layoutScroll
          values={arrangeUsers}
          onReorder={(e) => handleRearrangeOder(e)}
        >
          {arrangeUsers.map((user) => (
            <Reorder.Item
              value={user}
              style={{ width: "100%" }}
              className={classes.itemContainer}
              dragControls={dragControls}
              dragListener={true}
              key={user.value}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <UserAvatar
                  avatar={user?.avatar}
                  firstName={user?.label}
                  lastName={user?.last_name}
                />
                {user?.label}
              </div>
              <div className={classes.iconContainer}>
                <DotsSixVertical
                  className={classes.icon}
                  dragControls={dragControls}
                  size={22}
                />
              </div>
            </Reorder.Item>
          ))}
        </Reorder.Group>
      </div>
      <div>
        <Modal
          className={classes.modal}
          open={isSelectUserModalOpen}
          onClose={handleSelectUserModalState}
        >
          <SelectUserModal
            closeModal={handleSelectUserModalState}
            assignedUser={users}
            usersList={usersList}
            handleSelectUsers={handleSelectUser}
          />
        </Modal>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          className={classes.doneBtn}
          onClick={() => {
            handleSelectedUser(arrangeUsers);
            closeModal();
          }}
        >
          Done
        </Button>
        <Button className={classes.cancelBtn} onClick={closeModal}>
          Cancel
        </Button>
      </div>
    </div>
  );
}
