import { Grid, Modal } from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";
import { fdelete, fget } from "../../../../API/callsAPI";
import DeleteModal from "../../../Utilities/DeleteModal/DeleteModal.component";
import Loader from "../../../Utilities/Loader/Loader";
import NoResultFound from "../../../Utilities/NoResultFound/NoResultFound";
import SingleTemplateCard from "./SingleTemplateCard.component";
import CreateNewOrEditTaskTemplateModal from "../TaskTemplateModals/CreateNewOrEditTaskTemplateModal/CreateNewOrEditTaskTemplateModal";
import ManageTaskPhaseModal from "../TaskTemplateModals/ManageTaskPhaseModal/ManageTaskPhaseModal.component";
import useStyles from "./TaskTemplateSettingOverview.style";
import TaskTemplateTools from "../TaskTemplateTools/TaskTemplateTools";
import { useHistory } from "react-router-dom";
import ImportTemplateModal from "../TaskTemplateModals/ImportTemplateModal/ImportTemplateModal.component";

const TaskTemplateSettingOverview = ({ setSnackValue, projectId }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);
  const [taskTemplateList, setTaskTemplateList] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [isNewTaskTemplateModalOpen, setNewTaskTemplateModalOpen] =
    useState(false);
  const [sortByDefault, setSortByDefault] = useState("A-Z");
  const [isImportTemplateModalOpen, setIsImportTemplateModalOpen] =
    useState(false);
  const [editTaskTemplateInfo, setEditTaskTemplateInfo] = useState({
    selectedTemplate: {},
    isModalOpen: false,
  });
  const [manageTasksPhaseModalInfo, setManageTaskPhaseModalInfo] = useState({
    templateDetails: {},
    isModalOpen: false,
  });
  const [deleteTasksTemplateInfo, setDeleteTasksTemplateInfo] = useState({
    selectedTemplate: {},
    isModalOpen: false,
  });
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [usersList, setUsersList] = useState([]);

  const updateRefreshCounter = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  const handleNewTaskTemplateModalState = () => {
    setNewTaskTemplateModalOpen(!isNewTaskTemplateModalOpen);
  };

  const handleEditTaskTemplateModalInfo = (data) => {
    setEditTaskTemplateInfo({
      selectedTemplate: data,
      isModalOpen: !editTaskTemplateInfo.isModalOpen,
    });
  };

  const handleManageTaskPhaseModalInfo = (details) => {
    setManageTaskPhaseModalInfo({
      templateDetails: details,
      isModalOpen: !manageTasksPhaseModalInfo.isModalOpen,
    });
  };

  const closeManageTaskPhaseModal = () => {
    setManageTaskPhaseModalInfo({
      templateDetails: {},
      isModalOpen: false,
    });
  };

  const handleDeleteTaskTemplateInfo = (data) => {
    setDeleteTasksTemplateInfo({
      selectedTemplate: data,
      isModalOpen: !deleteTasksTemplateInfo.isModalOpen,
    });
  };

  const decodedURL = decodeURIComponent(
    escape(window.atob(history.location.search.substring(1)))
  );
  const searchParams = new URLSearchParams(decodedURL);

  useEffect(() => {
    getDepartments();
    fetchUsers();
    setSortByDefault(searchParams.get("sortBy") || "A-Z");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTasksTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCounter]);

  const getTasksTemplates = async () => {
    try {
      const url = `task-template/?project=${projectId}&sortBy=${sortByDefault}`;
      const res = await fget({ url });
      setTaskTemplateList(res?.data?.results);
      setIsLoaded(true);
    } catch (error) {
      console.log(error);
      setIsLoaded(true);
    }
  };

  const getDepartments = async () => {
    try {
      const res = await fget({ url: `department/?project=${projectId}` });
      setDepartments(res?.data?.results);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUsers = async () => {
    try {
      const usersRes = await fget({
        url: `project/${projectId}/users/`,
      });
      setUsersList(
        usersRes.data.results.map((el) => {
          const data = {
            label: el.first_name + " " + el.last_name,
            value: el.id,
            avatar: el?.avatar,
          };
          return data;
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const deleteTaskTemplate = async () => {
    try {
      const url = `task-template/${deleteTasksTemplateInfo?.selectedTemplate?.id}`;
      const res = await fdelete({ url });
      if (res === 200 || 201) {
        setSnackValue({
          isOpen: true,
          message: "successfully deleted template",
          isError: false,
        });
        setDeleteTasksTemplateInfo({
          selectedTemplate: {},
          isModalOpen: false,
        });
        closeManageTaskPhaseModal();
        updateRefreshCounter();
      }
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  //Function to sort task templates
  const handleSubmitSort = (sortBy) => {
    searchParams.set("sortBy", `${sortBy}`);
    history.push(
      "?" + btoa(unescape(encodeURIComponent(searchParams.toString())))
    );
    switch (sortBy) {
      case "A-Z":
        taskTemplateList?.sort((a, b) => a.name.localeCompare(b.name));
        setSortByDefault("A-Z");
        break;
      case "Z-A":
        taskTemplateList?.sort((a, b) => b.name.localeCompare(a.name));
        setSortByDefault("Z-A");
        break;
      case "LatestModified":
        taskTemplateList?.sort(function (a, b) {
          var dateA = new Date(a.updated_at),
            dateB = new Date(b.updated_at);
          return dateB - dateA;
        });
        setSortByDefault("LatestModified");
        break;
      case "LastModified":
        taskTemplateList?.sort(function (a, b) {
          var dateA = new Date(a.updated_at),
            dateB = new Date(b.updated_at);
          return dateA - dateB;
        });
        setSortByDefault("LastModified");
        break;
      case "DateCreated":
        taskTemplateList?.sort(function (a, b) {
          var dateA = new Date(a.created_at),
            dateB = new Date(b.created_at);
          return dateA - dateB;
        });
        setSortByDefault("DateCreated");
        break;
      default:
        return taskTemplateList?.results;
    }
  };
  return (
    <div className={classes.root}>
      <TaskTemplateTools
        handleNewTaskTemplateModalState={handleNewTaskTemplateModalState}
        onSubmit={handleSubmitSort}
        defaultvalue={sortByDefault}
        setIsImportTemplateModalOpen={setIsImportTemplateModalOpen}
      />
      {isLoaded ? (
        <>
          {taskTemplateList?.length > 0 ? (
            <Grid container spacing={2}>
              {taskTemplateList?.map((singleTemplate) => {
                return (
                  <SingleTemplateCard
                    key={singleTemplate?.id}
                    singleTemplate={singleTemplate}
                    handleManageTaskPhaseModalInfo={
                      handleManageTaskPhaseModalInfo
                    }
                    handleDeleteTaskTemplateInfo={handleDeleteTaskTemplateInfo}
                    handleEditTaskTemplateModalInfo={
                      handleEditTaskTemplateModalInfo
                    }
                  />
                );
              })}
            </Grid>
          ) : (
            <NoResultFound />
          )}
        </>
      ) : (
        <Loader />
      )}
      {/* <div
        className="taskTemplatesContainer"
        style={{
          display: "flex",
          gap: "10px",
          flexWrap: "wrap",
          height: "440px",
          overflowY: "auto",
        }}
      >
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((d) => {
          return <SingleTemplateCard />;
        })}
      </div> */}
      <div>
        <Modal
          className={classes.taskTemplateModals}
          open={isNewTaskTemplateModalOpen}
          onClose={() => setNewTaskTemplateModalOpen(false)}
        >
          <CreateNewOrEditTaskTemplateModal
            type="New"
            projectId={projectId}
            handleClose={() => setNewTaskTemplateModalOpen(false)}
            updateRefreshCounter={updateRefreshCounter}
            handleManageTaskPhaseModalInfo={handleManageTaskPhaseModalInfo}
            setSnackValue={setSnackValue}
          />
        </Modal>
        <Modal
          className={classes.taskTemplateModals}
          open={editTaskTemplateInfo.isModalOpen}
          onClose={() =>
            setEditTaskTemplateInfo({
              selectedTemplate: {},
              isModalOpen: false,
            })
          }
        >
          <CreateNewOrEditTaskTemplateModal
            type="Edit"
            projectId={projectId}
            selectedTemplate={editTaskTemplateInfo.selectedTemplate}
            updateRefreshCounter={updateRefreshCounter}
            handleClose={() =>
              setEditTaskTemplateInfo({
                selectedTemplate: {},
                isModalOpen: false,
              })
            }
            setSnackValue={setSnackValue}
          />
        </Modal>
        <Modal
          className={classes.taskTemplateModals}
          open={isImportTemplateModalOpen}
          onClose={() => setIsImportTemplateModalOpen(false)}
        >
          <ImportTemplateModal
            projectId={projectId}
            updateRefreshCounter={updateRefreshCounter}
            handleClose={() => setIsImportTemplateModalOpen(false)}
            setSnackValue={setSnackValue}
          />
        </Modal>
        <Modal
          className={classes.taskTemplateModals}
          open={manageTasksPhaseModalInfo.isModalOpen}
          onClose={() => closeManageTaskPhaseModal()}
        >
          <ManageTaskPhaseModal
            handleClose={closeManageTaskPhaseModal}
            departments={departments}
            templateDetails={manageTasksPhaseModalInfo?.templateDetails}
            updateRefreshCounter={updateRefreshCounter}
            handleDeleteTaskTemplateInfo={handleDeleteTaskTemplateInfo}
            handleEditTaskTemplateModalInfo={handleEditTaskTemplateModalInfo}
            setSnackValue={setSnackValue}
            usersList={usersList}
          />
        </Modal>
        <Modal
          className={classes.taskTemplateModals}
          open={deleteTasksTemplateInfo.isModalOpen}
          onClose={() =>
            setDeleteTasksTemplateInfo({
              selectedTemplate: {},
              isModalOpen: false,
            })
          }
        >
          <DeleteModal
            type="Tasks Template"
            name={deleteTasksTemplateInfo?.selectedTemplate?.name}
            handleCloseDeleteModal={() => {
              setDeleteTasksTemplateInfo({
                selectedTemplate: {},
                isModalOpen: false,
              });
            }}
            handleDelete={() => {
              deleteTaskTemplate();
            }}
          />
        </Modal>
      </div>
    </div>
  );
};

export default TaskTemplateSettingOverview;
