import React, { useEffect, useState } from "react";
import useStyles from "./SelectUsersModal.style";
import { Button, Card, Input } from "@material-ui/core";
import { Check, MagnifyingGlass, X } from "phosphor-react";
import UserAvatar from "../../UserAvatar/UserAvatar.component";

export default function SelectUserModal({
  assignedUser,
  handleSelectUsers,
  closeModal,
  usersList,
}) {
  const classes = useStyles();

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUsers, setSelectedUsers] = useState(
    assignedUser !== undefined || null ? assignedUser : []
  );

  const handleUserSelect = (user) => {
    if (assignedUser[0] !== null) {
      const exist = selectedUsers.some(
        (eachUser) => eachUser.value === user.value
      );
      if (exist) {
        setSelectedUsers(
          selectedUsers.filter((each) => each.value !== user.value)
        );
      } else {
        setSelectedUsers([...selectedUsers, user]);
      }
    } else {
      setSelectedUsers([user]);
    }
  };

  useEffect(() => {
    handleSelectUsers(selectedUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers]);

  const handleSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <Card className={classes.card}>
      <div className={classes.toolContainer}>
        <div className={classes.searchDiv}>
          <MagnifyingGlass size={25} style={{ marginLeft: "2%" }} />
          <Input
            className={classes.searchInput}
            disableUnderline="false"
            placeholder="Search anything"
            onChange={(e) => handleSearchQuery(e)}
          />
        </div>
        <div>
          <X size={27} className={classes.icon} onClick={() => closeModal()} />
        </div>
      </div>
      <div className={classes.userList}>
        {usersList
          ? usersList &&
            usersList
              ?.filter((search) => {
                if (searchQuery === undefined) {
                  return search;
                } else if (searchQuery === "") {
                  return search;
                } else if (
                  `${search.label}`
                    ?.toLowerCase()
                    .includes(searchQuery.toLowerCase())
                ) {
                  return search;
                }
                return null;
              })
              .map((user, index) => (
                <div
                  className={classes.user}
                  key={index}
                  onClick={() => {
                    handleUserSelect(user);
                  }}
                >
                  <div className={classes.displayFlex}>
                    <UserAvatar
                      avatar={user?.avatar}
                      firstName={user?.label?.split(" ")[0]}
                      lastName={user?.label?.split(" ")[1]}
                    />
                    <span style={{ fontSize: "14px" }}>{user.label}</span>
                  </div>
                  <div>
                    {selectedUsers?.map((singleUser) =>
                      singleUser?.value === user?.value ? (
                        <span>
                          <Check size={25} className={classes.check} />
                        </span>
                      ) : (
                        ""
                      )
                    )}
                  </div>
                </div>
              ))
          : "no User"}
      </div>
      <div className={classes.cardAction}>
        <Button size="small" disableElevation onClick={() => closeModal()}>
          Done
        </Button>
      </div>
    </Card>
  );
}
