import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { fget } from "../../../API/callsAPI";

//@MUI
import useStyles from "./ReviewsOverview.styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
} from "@material-ui/core";

//@Components
import ErrorOrSuccessMessage from "../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";
import ReviewTools from "../ReviewsPageTools/ReviewsPageTool.component";
import ReviewsList from "../ReviewsList/ReviewsList.component";
import CustomPagination from "../../Utilities/CustomPagination/CustomPagination.component";
import ReviewPageNewReviewModal from "../ReviewPageModals/ReviewPageNewReviewModal.jsx";
import ReviewPageManageReviewModal from "../ReviewPageModals/ReviewPageManageReviewModal";
import ReviewPageNoData from "../ReviewPageNoData/ReviewPageNoData";
import Loader from "../../Utilities/Loader/Loader";

function ReviewsOverview(props) {
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [updateOnPageChange, setUpdateOnPageChange] = useState(0);
  const [isNewReviewModalOpen, setNewReviewModalOpen] = useState(false);
  const [isManageReviewerModalOpen, setManageReviewerModalOpen] =
    useState(false);
  const [reviewFiles, setReviewFiles] = useState([]); //all the review files
  const [filterCompleted, setFilterCompleted] = useState("False"); //for filtering according to pending and completed in the tools
  const [orgUsers, setOrgUsers] = useState([]);
  const [projectReviewer, setProjectReviewer] = useState([]); //reviewer added to a project
  const [isReviewTogglerOn, setIsReviewTogglerOn] = useState(false);
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });

  const handleReviewToggler = () => {
    setIsReviewTogglerOn(!isReviewTogglerOn);
  };

  const handleNewReviewModal = () => {
    setNewReviewModalOpen(!isNewReviewModalOpen);
  };

  const handleManageReviewModal = () => {
    if (!isManageReviewerModalOpen) {
      getReviewer();
    }
    setManageReviewerModalOpen(!isManageReviewerModalOpen);
  };

  const getReviewer = async () => {
    try {
      const usersRes = await fget({
        url: `project/${props.params.projectId}/reviewers`,
      });
      if (usersRes.status === 200) {
        setProjectReviewer(
          usersRes.data.results.map((user) => {
            const data = {
              label: user.first_name + " " + user.last_name,
              value: user.id,
              avatar: user?.avatar,
              email: user.email,
            };
            return data;
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getReviewFiles = async () => {
    setIsLoaded(false);
    try {
      const reviewFilesRes = await fget({
        url: `project/${props.params.projectId}/reviews/?page=${currentPage}&completed=${filterCompleted}`,
      });
      if (reviewFilesRes.status === 200) {
        setReviewFiles(reviewFilesRes.data);
        setIsLoaded(true);
      }
    } catch (error) {
      console.log(error);
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    if (!props.externalReviewer) {
      fetchOrghUsers();
    }
    getReviewer();
  }, []);

  useEffect(() => {
    getReviewFiles();
  }, [updateOnPageChange, refreshCounter, filterCompleted]);

  const fetchOrghUsers = async () => {
    try {
      const usersRes = await fget({
        url: `organization/users/`,
      });
      if (usersRes.status === 200) {
        setOrgUsers(
          usersRes.data.results.map((user) => {
            const data = {
              label: user.first_name + " " + user.last_name,
              value: user.id,
              avatar: user?.avatar,
              email: user.email,
            };
            return data;
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageChange = (pageNo) => {
    setCurrentPage(pageNo);
    setUpdateOnPageChange(updateOnPageChange + 1);
  };

  const updateRefreshCounter = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  return (
    <div className={classes.root}>
      <ErrorOrSuccessMessage snackValue={snackValue} />
      <ReviewTools
        handleNewReviewModal={handleNewReviewModal}
        handleManageReviewModal={handleManageReviewModal}
        setFilterCompleted={setFilterCompleted}
        filterCompleted={filterCompleted}
        handleReviewToggler={handleReviewToggler}
        isReviewVersion={"no"}
        isExternalReviewer={props.externalReviewer}
      />
      {isLoaded ? (
        reviewFiles.count !== 0 ? (
          <>
            <TableContainer className={classes.tableContainer}>
              <Table
                stickyHeader
                className={classes.table}
                aria-label="simple table"
              >
                <TableHead className={classes.tableHeader}>
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.headTableCell} align="left">
                      File Name
                    </TableCell>
                    <TableCell className={classes.headTableCell} align="center">
                      Modified on
                    </TableCell>
                    <TableCell className={classes.headTableCell} align="center">
                      Added by
                    </TableCell>
                    <TableCell className={classes.headTableCell} align="center">
                      Status
                    </TableCell>
                    <TableCell className={classes.headTableCell} align="center">
                      Reviewer
                    </TableCell>
                    <TableCell className={classes.headTableCell} align="center">
                      Comments
                    </TableCell>
                    <TableCell className={classes.headTableCell} align="right">
                      {props.externalReviewer ? "Versions" : "Actions"}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reviewFiles?.results?.map((review) => {
                    return (
                      <ReviewsList
                        isReviewTogglerOn={isReviewTogglerOn}
                        isExternalReviewer={props.externalReviewer}
                        projectId={props.params.projectId}
                        review={review}
                        updateRefreshCounter={updateRefreshCounter}
                        key={review.id}
                        setSnackValue={setSnackValue}
                        isCompleted={filterCompleted}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <footer style={{ bottom: "0", height: "100%" }}>
              <div>
                <CustomPagination
                  handlePageChange={handlePageChange}
                  currentPage={currentPage}
                  totalItem={reviewFiles?.count ? reviewFiles.count : 0}
                  totalPage={
                    reviewFiles?.count ? Math.ceil(reviewFiles?.count / 50) : 0
                  }
                  // setIsNewPageLoading={setIsNewPageLoading}
                  trackable="Review Files"
                />
              </div>
            </footer>
          </>
        ) : (
          <ReviewPageNoData
            filterCompleted={filterCompleted}
            handleNewReviewModal={handleNewReviewModal}
            isExternalReviewer={props.externalReviewer}
            isReviewVersion={false}
          />
        )
      ) : (
        <Loader />
      )}
      <div>
        <Modal
          className={classes.reviewPageModals}
          open={isNewReviewModalOpen}
          onClose={handleNewReviewModal}
        >
          <ReviewPageNewReviewModal
            handleNewReviewModal={handleNewReviewModal}
            reviewerUserList={projectReviewer}
            projectId={props.params.projectId}
            setSnackValue={setSnackValue}
            updateRefreshCounter={updateRefreshCounter}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.reviewPageModals}
          open={isManageReviewerModalOpen}
          onClose={handleManageReviewModal}
        >
          <ReviewPageManageReviewModal
            handleManageReviewModal={handleManageReviewModal}
            orgUsers={orgUsers}
            setSnackValue={setSnackValue}
            projectId={props.params.projectId}
            projectReviewer={projectReviewer}
            getReviewer={getReviewer}
          />
        </Modal>
      </div>
    </div>
  );
}

export default ReviewsOverview;

ReviewsOverview.propTypes = {
  searchQuery: PropTypes.string,
};
