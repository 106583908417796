import React from "react";

const ExpandAllIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.46875 8.00195L14.5312 8.00195C14.6556 8.00195 14.7748 7.94928 14.8627 7.85551C14.9506 7.76174 15 7.63456 15 7.50195L15 4.50195C15 4.36935 14.9506 4.24217 14.8627 4.1484C14.7748 4.05463 14.6556 4.00195 14.5313 4.00195L0.46875 4.00195C0.34443 4.00195 0.225201 4.05463 0.137293 4.1484C0.0493864 4.24217 3.17573e-07 4.36934 3.0598e-07 4.50195L4.37114e-08 7.50195C3.21184e-08 7.63456 0.049386 7.76174 0.137293 7.85551C0.225201 7.94927 0.34443 8.00195 0.46875 8.00195ZM0.9375 5.00195L14.0625 5.00195L14.0625 7.00195L0.9375 7.00195L0.9375 5.00195Z"
        fill="#F9F9F9"
      />
      <path
        d="M7.51341 9.00195L5.10013 11.4152C5.03596 11.48 4.99997 11.5675 4.99997 11.6587C4.99997 11.7499 5.03596 11.8374 5.10013 11.9022C5.16419 11.9661 5.25098 12.002 5.34146 12.002C5.43194 12.002 5.51873 11.9661 5.58278 11.9022L7.51341 9.95018L9.44404 11.8808C9.50809 11.9447 9.59488 11.9806 9.68537 11.9806C9.77585 11.9806 9.86264 11.9447 9.92669 11.8808C9.99086 11.816 10.0269 11.7285 10.0269 11.6373C10.0269 11.5462 9.99086 11.4587 9.92669 11.3939L7.51341 9.00195Z"
        fill="#F9F9F9"
      />
      <path
        d="M7.51344 3.00195L9.92673 0.588671C9.99089 0.523886 10.0269 0.43639 10.0269 0.345207C10.0269 0.254024 9.99089 0.166528 9.92673 0.101743C9.86267 0.0378405 9.77588 0.00195312 9.6854 0.00195312C9.59492 0.00195312 9.50813 0.0378405 9.44407 0.101743L7.51344 2.05373L5.58282 0.1231C5.51876 0.0591967 5.43197 0.0233097 5.34149 0.0233097C5.25101 0.0233097 5.16422 0.0591967 5.10016 0.1231C5.036 0.187884 5 0.275381 5 0.366564C5 0.457747 5.036 0.545243 5.10016 0.610028L7.51344 3.00195Z"
        fill="#F9F9F9"
      />
    </svg>
  );
};

export default ExpandAllIcon;
