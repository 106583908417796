import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "20%",
  },
  userDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  titleText: {
    fontSize: "18px",
    fontWeight: 600,
    marginBottom: "5px",
  },
  flexBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  userDiv1: {
    marginBottom: "15px",
    marginTop: "5px",
  },
  adminOrUserText: {
    fontSize: "14px",
    fontWeight: 600,
    marginBottom: "5px",
  },
  manageUserButton: {
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    justifyContent: "flex-start",
    textTransform: "none",
    borderRadius: "7px",
    margin: "0px 4px",
    color: theme.palette.text.secondary,
    "& :hover": {
      color: theme.palette.secondary.main,
    },
  },
  usersContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  departmentModal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  border: {
    color: theme.palette.text.tertiary,
  },
  userNameChip: {
    marginRight: "5px",
  },
  avatarWithName: {
    border: `2px solid ${theme.palette.text.tertiary}`,
    display: "flex",
    alignItems: "center",
    gap: "5px",
    borderRadius: "25px",
    paddingRight: "10px",
  },
}));

export default useStyles;
