import React from "react";

//@MUI
import useStyles from "./ReviewsPageTool.styles.js";
import { Button, Divider, Tabs, Tab } from "@material-ui/core";

//icons
import { Users, DotsThreeCircle, CheckCircle } from "phosphor-react";
import { Plus } from "react-feather";

//@components
import BreadCrumb from "../../Utilities/BreadCrumb/BreadCrumb.jsx";
import {
  ToggleButton,
  FilterButton,
} from "../../CommonComponents/CommonButtons/CommonButtons.Component.jsx";
import ExternalReviewerIcon from "../../Utilities/Svg/ExternalReviewerIcon.jsx";

function ReviewTools({
  handleManageReviewModal,
  setFilterCompleted,
  filterCompleted,
  handleReviewToggler,
  handleNewReviewModal,
  isReviewVersion,
  isExternalReviewer,
  parentReview,
}) {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.divContainer}>
          <div className={classes.innerLeftContainer}>
            <ExternalReviewerIcon />
            <BreadCrumb shotCode={parentReview ? parentReview.name : ""} />
          </div>
          {isReviewVersion === "yes" ? (
            ""
          ) : (
            <Tabs
              value={filterCompleted}
              onChange={(event, value) => {
                setFilterCompleted(value);
              }}
              style={{ marginTop: "-8px" }}
            >
              <Tab
                value={"False"}
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <DotsThreeCircle
                      style={{ marginRight: "10px" }}
                      size={18}
                    />
                    Pending
                  </div>
                }
              />
              <Tab
                value={"True"}
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CheckCircle style={{ marginRight: "10px" }} size={18} />
                    Completed
                  </div>
                }
              />
            </Tabs>
          )}
        </div>
        <div className={classes.divContainer}>
          <ToggleButton handleToggler={handleReviewToggler} />
          {!isExternalReviewer && (
            <>
              <Button
                className={classes.manageReviewBtn}
                startIcon={<Users size={18} />}
                onClick={handleManageReviewModal}
              >
                Manage Reviewer
              </Button>
              <Button
                className={classes.addReviewBtn}
                startIcon={<Plus size={19} />}
                onClick={handleNewReviewModal}
              >
                {isReviewVersion === "yes" ? "Add to Version" : "Add to Review"}
              </Button>
            </>
          )}
        </div>
      </div>
      <Divider className={classes.divider} />
    </div>
  );
}

export default ReviewTools;

// ReviewTools.propTypes = {
//
// };
