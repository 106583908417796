import React from "react";
import PlaylistOverview from "../../Components/PlayList/PlaylistOverview/PlaylistOverview.component";

export default function PlaylistPage(props) {
  return (
    <>
      <PlaylistOverview
        params={props.match.params}
        searchQuery={props.searchQuery}
      />
    </>
  );
}
