import React, { useState, useEffect } from "react";
import * as Icon from "react-feather";
import { Modal, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
//@MUI
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
//Validators
import { useFormik } from "formik";
import * as yup from "yup";
//
import useStyles from "./ShotsPageEditShotModal.styles";
import { Asterisk } from "phosphor-react";
import {
  fdelete,
  fget,
  fpatch,
  fpostBFF,
  uploadFile,
} from "../../../API/callsAPI";
import { useHistory } from "react-router-dom";
import DragScreen from "../../Utilities/DragScreen/DragScreen";
import UploadFile from "../../Utilities/UploadFile/UploadFile";
import ModalTitleAndButtons from "../../Utilities/ModalTitleAndButtons/ModalTitleAndButtons.component";
import ConfirmDeleteFile from "../../Utilities/ConfirmDeleteFile/ConfirmDeleteFile.component";
import ErrorOrSuccessMessage from "../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";
import HelperText from "../../Utilities/HelperText/HelperText";

export default function ShotsPageEditShotModal(props) {
  const classes = useStyles();
  const history = useHistory();

  const [thumbnail, uploadThumbnailSet] = useState();
  const [department, setDepartment] = useState([]);
  const [parentSequences, setParentSequences] = useState();
  const [defaultDept, setDefaultDept] = useState();
  const [loading, setLoading] = useState(false);
  const [preLoadedFile, setPreLoadedFile] = useState(false);
  const [deleteFileModal, setDeleteFileModal] = useState(false);
  const [snackValue, setSnackValue] = React.useState({
    isOpen: false,
    message: "",
    isError: false,
  });

  useEffect(() => {
    if (props?.parentShot?.file) {
      const fileNameArray = props.parentShot.file.split("/");
      uploadThumbnailSet(fileNameArray[fileNameArray.length - 1]);
      setPreLoadedFile(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchParentSequences();
    defaultDepartments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchParentSequences() {
    const sequence = {
      url: "web/sequence/list/",
      data: {
        project_id: props.projectId,
      },
    };
    try {
      const res = await fpostBFF(sequence);
      setParentSequences(res?.data?.sequence_list?.results);
    } catch (error) {
      console.log(error);
    }
  }

  const handleThumbnailUpload = (event) => {
    let file = event.target.files[0];
    event.target.value = null;
    uploadThumbnailSet(file);
    setPreLoadedFile(false);
  };

  const handleClearUpload = (event) => {
    event?.stopPropagation();
    uploadThumbnailSet(null);
  };

  const defaultDepartments = () => {
    let departmentsAlready = [];
    props.parentShot &&
      props.parentShot.allowed_dept.forEach((eachDepartment) => {
        let temp = {
          name: eachDepartment.name,
          id: eachDepartment.id,
        };
        departmentsAlready.push(temp);
      });

    setDefaultDept(departmentsAlready);
    setDepartment(departmentsAlready);
  };

  const handleDeptChange = (data) => {
    setDepartment(data);
  };

  //Validators
  const EditShotValidationScheme = yup.object({
    shotName: yup
      .string("String required")
      .min(1, "Too Short!")
      .max(50, "Maximum Character Limit: 50")
      .required("This is a required field"),
  });

  const formik = useFormik({
    initialValues: {
      shotName: props.parentShot ? props.parentShot.code : "",
      status: props.parentShot?.status ? props.parentShot.status.id : "",
      parentSequence: props.sequenceId,
      description: props.parentShot ? props.parentShot.description : "",
      department: [],
    },
    validationSchema: EditShotValidationScheme,
    onSubmit: async (values) => {
      setLoading(true);
      let formdata = new FormData();
      formdata.append("code", values.shotName);
      thumbnail && formdata.append("file_name", thumbnail.name);
      // fileTouched && formdata.append("file", file);
      formdata.append("status", values.status);
      formdata.append("project", props.projectId);
      formdata.append("description", values.description);
      formdata.append("parent_sequence", values.parentSequence);
      formdata.append("vp_linked_level_sequence", "");
      if (department) {
        for (var i = 0; i < department.length; i++) {
          formdata.append(`allowed_dept`, department[i].id);
        }
      }
      let updatedShot = {
        url: `trackables/shot/${props.shotId}/`,
        data: formdata,
      };
      try {
        const res = await fpatch(updatedShot);

        if (res.status === 200 || 201) {
          if (thumbnail) {
            getSignedUrl(res.data.id);
          } else {
            setLoading(false);
            setSnackValue({
              isOpen: true,
              message: `SuccessFully edited ${res.data.code}`,
              isError: false,
            });
            props.updateRefreshCounter();
            props.handleEditShotModal();
          }
        }
      } catch (error) {
        setLoading(false);
        setSnackValue({
          isOpen: true,
          message: `${error}`,
          isError: true,
        });
      }
    },
  });

  const getSignedUrl = async (newId) => {
    /* let shotClass = props.trackableClassList.find((el) => el.model === "shot"); */
    try {
      const res = await fget({
        url: `trackables/shot/${newId}/upload`,
      });
      if (res.status === 200) {
        await uploadFile({ url: res.data?.signed_url, data: thumbnail });
        fget({ url: `trackables/shot/${newId}/thumbnail` });
        setLoading(false);
        setSnackValue({
          isOpen: true,
          message: `SuccessFully Edited ${props?.data?.name}`,
          isError: false,
        });
        // setFileUploading(false);
        props.updateRefreshCounter();
        props.handleEditShotModal();
      }
    } catch (error) {
      setLoading(false);
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  const handleDragFile = (e) => {
    console.log(e.type);
    if (e.type === "dragenter") {
      //when  file enter- display dragscreen
      props.handleDragScreen(true);
      props.setDragFileEnterModal(true);
    }
    //catching the dragged file for uploading
    if (e.type === "drop") {
      props.handleDragScreen(false);
      uploadThumbnailSet(e.dataTransfer.files[0]);
      setPreLoadedFile(false);
    }
    e?.preventDefault();
    e?.stopPropagation();
  };

  //function to remove preloaded or selected file
  const handleDeleteFile = async (e) => {
    if (preLoadedFile) {
      try {
        await fdelete({
          url: `trackables/shot/${props.shotId}/file`,
        });
        props.updateRefreshCounter();
        setDeleteFileModal(false);
        setSnackValue({
          isOpen: true,
          message: `file deleted successfully`,
          isError: false,
        });
        handleClearUpload(e);
      } catch (error) {
        setSnackValue({
          isOpen: true,
          message: `Something went wrong in file delete`,
          isError: true,
        });
      }
    } else {
      setDeleteFileModal(false);
      setPreLoadedFile(false);
      handleClearUpload(e);
    }
  };

  return (
    <div
      className={classes.root}
      onDragEnter={(e) => handleDragFile(e)}
      onDrop={(e) => handleDragFile(e)}
      onDragLeave={(e) => handleDragFile(e)}
      onDragOver={(e) => handleDragFile(e)}
    >
      <ErrorOrSuccessMessage snackValue={snackValue} />
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <DragScreen dragScreen={props.dragScreen} />
          <form enctype="multipart/form-data" onSubmit={formik.handleSubmit}>
            <ModalTitleAndButtons
              name="Shot"
              type="Edit"
              closeModal={(e) => props.handleEditShotModal(e)}
              openDeleteModal={(e) => props.handledDeleteShotModalOpenState(e)}
            />
            <div className={classes.cardContentDiv1}>
              <div style={{ marginTop: "15px" }}>
                <div>
                  <span>Shot Name</span>
                  <Asterisk size={12} color="#FF0000" weight="fill" />
                  <div className={classes.cardContentDiv3}>
                    <TextField
                      type="text"
                      InputProps={{ disableUnderline: true }}
                      className={classes.cardContentInput1}
                      name="shotName"
                      value={formik.values.shotName}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.shotName)}
                    />
                  </div>
                  {formik.errors.shotName && formik.touched.shotName ? (
                    <HelperText text={formik.errors.shotName} />
                  ) : null}
                </div>
                <div
                  style={{
                    marginTop: "25px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <span>Status</span>
                    <div
                      className={classes.cardContentDiv3}
                      style={{ marginTop: "5px", width: "150px" }}
                    >
                      <Select
                        disableUnderline={true}
                        style={{ width: "100%" }}
                        className={classes.cardContentInput1}
                        labelId="demo-simple-select-outlined-label"
                        id="selectStatusShot"
                        value={formik.values.status}
                        onChange={formik.handleChange}
                        name="status"
                        label="Select Status"
                      >
                        <MenuItem
                          value=""
                          className={classes.addNewStatusButton}
                          onClick={() => {
                            history.push(`/${props.projectId}/settings`);
                          }}
                        >
                          <Icon.Plus />
                          Add new Status
                        </MenuItem>
                        {props.status &&
                          props.status.map((eachStatus) => (
                            <MenuItem value={eachStatus.id}>
                              <span
                                style={{
                                  height: "15px",
                                  width: "15px",
                                  backgroundColor: `rgb(${eachStatus?.color})`,
                                  borderRadius: "50%",
                                  display: "inline-block",
                                  margin: "0px 5px",
                                }}
                              ></span>
                              {eachStatus.name} - {eachStatus.code}
                            </MenuItem>
                          ))}
                      </Select>
                    </div>
                  </div>
                  <div style={{ maxWidth: "50%" }}>
                    <span>Status Code</span>
                    <div
                      style={{ marginTop: "5px", cursor: "not-allowed" }}
                      className={classes.cardContentDiv3}
                    >
                      <TextField
                        disabled
                        className={classes.cardContentInput1}
                        InputProps={{
                          disableUnderline: true,
                          style: { opacity: "0.4" },
                        }}
                        value={
                          props.status.find(
                            (eachStatus) =>
                              eachStatus.id === formik.values.status
                          )?.code
                        }
                      />
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "25px" }}>
                  <span>Parent Sequence</span>
                  <div className={classes.cardContentDiv3}>
                    <Select
                      disableUnderline
                      style={{
                        width: "100%",
                        paddingLeft: "2px",
                      }}
                      labelId="demo-simple-select-outlined-label"
                      id="parentSequence"
                      label="Select Status"
                      className={classes.cardContentInput1}
                      name="parentSequence"
                      value={formik.values.parentSequence}
                      onChange={formik.handleChange}
                    >
                      {parentSequences &&
                        parentSequences.map((eachSequence) => (
                          <MenuItem value={eachSequence.id}>
                            {eachSequence.id} - {eachSequence.code}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                </div>
                <div style={{ marginTop: "25px" }}>
                  <span>Description</span>
                  <div className={classes.cardContentDiv4}>
                    <TextField
                      fullWidth
                      type="text"
                      className={classes.cardContentInput1}
                      InputProps={{ disableUnderline: true }}
                      placeholder="Enter a Description..."
                      name="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                {defaultDept ? (
                  <div style={{ marginTop: "25px" }}>
                    <label>Departments</label>
                    <Autocomplete
                      className={classes.cardContentDiv3}
                      id="selectDepartments"
                      multiple
                      defaultValue={defaultDept}
                      options={props.departments ? props.departments : []}
                      getOptionLabel={(option) =>
                        option.name ? option.name : "No Option"
                      }
                      renderInput={(params) => (
                        <TextField
                          InputProps={{ disableUnderline: true }}
                          className={classes.cardContentInput1}
                          type="text"
                          {...params}
                        />
                      )}
                      onChange={(e, v) => handleDeptChange(v)}
                    />
                  </div>
                ) : (
                  ""
                )}
                <div style={{ marginTop: "25px" }}>
                  <span>Attachment</span>
                  <UploadFile
                    thumbnail={thumbnail}
                    preLoadedFile={preLoadedFile}
                    handleClearUpload={handleClearUpload}
                    handleThumbnailUpload={handleThumbnailUpload}
                    setDeleteFileModal={setDeleteFileModal}
                  />
                </div>
              </div>
            </div>
            <br />
            <CardActions className={classes.cardAction}>
              <Button
                fullWidth
                className={classes.done}
                type="submit"
                value="Submit"
                size="small"
                disableElevation
              >
                {loading ? (
                  <CircularProgress style={{ width: "25px", height: "25px" }} />
                ) : (
                  <b>Done Editing</b>
                )}
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
      <div>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={deleteFileModal}
          onClose={() => setDeleteFileModal(false)}
        >
          <ConfirmDeleteFile
            name={typeof thumbnail === "object" ? thumbnail?.name : thumbnail}
            closeModal={() => {
              setDeleteFileModal(false);
            }}
            deleteFile={() => {
              handleDeleteFile();
            }}
          />
        </Modal>
      </div>
    </div>
  );
}
