import React from "react";
import ShotVersionsOverview from "../../Components/ShotVersion/ShotVersionsOverview/ShotversionsOverview.component";

export default function ShotVersion(props) {
  return (
    <div>
      <ShotVersionsOverview params={props.match.params} searchQuery = {props.searchQuery}/>
    </div>
  );
}
