import React from "react";
import SequencerOverview from "../../Components/Sequencer/SequencerOverview/SequencerOverview";

export default function Sequencer({ match }) {
  return (
    <>
      <SequencerOverview params={match.params} />
    </>
  );
}
