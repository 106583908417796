import {
  Button,
  Checkbox,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useState } from "react";
import DepartmentTaskTable from "./DepartmentTaskTable.component";
import useStyles from "./DepartmentDetailsTaskPage.styles";
import { CaretDown, CaretUp, Funnel } from "phosphor-react";
import { SortButton } from "../../CommonComponents/CommonButtons/CommonButtons.Component";
import { useLocation } from "react-router-dom";
import Loader from "../../Utilities/Loader/Loader";

export default function DepartmentDetailsTaskPage({
  groupedTaskList,
  projectId,
  isTasksLoaded,
  showMyTasks,
  setShowMyTasks,
}) {
  const classes = useStyles();

  return (
    <div style={{ height: "100%" }}>
      <div className={classes.taskViewTitle} style={{ height: "10%" }}>
        <div className={classes.titleText}>Related Tasks</div>
        <div className={classes.inLineDiv}>
          <div style={{ width: "200px" }}>
            <Checkbox
              checked={showMyTasks}
              onChange={() => setShowMyTasks(!showMyTasks)}
            />
            <span className={classes.checkBoxText}>Show only my tasks</span>
          </div>
          {/* <Button
            className={classes.taskPageButton}
            // onClick={props.openFilterModal}
          >
            <Funnel size={27} className={classes.iconStyle} />
            <p className={classes.text}>Filter</p>
          </Button>
          <SortButton openSortModal={() => console.log("sort")} /> */}
        </div>
      </div>
      <Divider />
      <div className={classes.tableContainerDiv}>
        {isTasksLoaded ? (
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeader} align="left">
                    Name
                  </TableCell>
                  <TableCell align="left" className={classes.tableHeader}>
                    Duration
                  </TableCell>
                  <TableCell align="left" className={classes.tableHeader}>
                    Status
                  </TableCell>
                  <TableCell align="left" className={classes.tableHeader}>
                    Priority
                  </TableCell>
                  <TableCell align="left" className={classes.tableHeader}>
                    Trackable
                  </TableCell>
                  <TableCell align="left" className={classes.tableHeader}>
                    Users
                  </TableCell>
                  <TableCell align="right" className={classes.tableHeader}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupedTaskList?.groupedTask?.map((singleGroup) => {
                  return (
                    <GroupView
                      singleGroup={singleGroup}
                      projectId={projectId}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
}

const GroupView = ({ singleGroup, projectId }) => {
  const classes = useStyles();
  const location = useLocation();
  const [hideItems, setHideItems] = useState(false);

  const redirect = () => {
    if (singleGroup?.linkedClass === "Asset") {
      location.pathname = `/${projectId}/assets/${singleGroup?.trackable}/assetversions`;
      window.open(`${location.pathname}`);
    } else {
      location.pathname = `/${projectId}/sequence/${singleGroup?.tasks[0].linked.parent_sequence}/shots/${singleGroup?.trackable}/shotversions`;
      window.open(`${location.pathname}`);
    }
  };

  return (
    <>
      <TableRow hover={false} className={classes.borderBottom}>
        <div>
          <Button
            className={classes.trackableNameButton}
            onClick={() => redirect()}
          >
            {singleGroup?.trackableName}
          </Button>
          <Button
            onClick={() => setHideItems(!hideItems)}
            style={{ minWidth: "10px" }}
          >
            {hideItems ? <CaretDown size={20} /> : <CaretUp size={20} />}
          </Button>
        </div>
      </TableRow>
      {singleGroup.tasks.map((task) => {
        return (
          <DepartmentTaskTable
            task={task}
            redirect={redirect}
            hideItems={hideItems}
            projectId={projectId}
          />
        );
      })}
    </>
  );
};
