import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "28%",
    "& .MuiFormHelperText-root": {
      color: "#ffcc00",
    },
    [theme.breakpoints.down("md")]: {
      width: "35%",
      // height: "11rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      // height: "11rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "50%",
      // height: "11rem",
    },
  },
  card: {
    borderRadius: "8px",
  },
  cardContent: {
    backgroundColor: theme.palette.primary.main,
    padding: "18px 18px 18px 18px",
    fontSize: "120%",
  },
  title: {
    color: theme.palette.secondary,
    fontSize: "20px",
    fontWeight: "700",
  },
  closeIcon: {
    color: theme.palette.secondary,
    cursor: "pointer",
    marginTop: "7%",
    float: "right",
  },

  allowImportButton: {
    border: "1px solid",
    borderColor: theme.palette.secondary.main,
    color: theme.palette.text.secondary,
    width: "100%",
    taxtAlign: "center",
    borderRadius: "7px",
    "& :hover": {
      color: theme.palette.text.primary,
    },
  },
  notAllowImportButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.secondary,
    width: "100%",
    borderRadius: "7px",
    justifyContent: "center",
    alignContent: "center",
    color: "white",
    "& :hover": {
      color: "white",
    },
  },
}));

export default useStyles;
