import React from "react";
import { Trash, CloudArrowUp } from "phosphor-react";
import useStyles from "./UploadFile.style.js";
export default function UploadFile(props) {
  const classes = useStyles();
  return (
    <div className={classes.cardContentDiv2}>
      {props.fileUploading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p style={{ fontSize: "13px" }}>{props.uploadProgress}% uploading</p>
        </div>
      ) : (
        <>
          {props.thumbnail ? (
            <div
              className={classes.displayFlex}
              style={{
                height: "100%",
              }}
            >
              <span className={classes.fileName}>
                {typeof props.thumbnail === "object"
                  ? props.thumbnail.name
                  : props.thumbnail}
              </span>
              <div
                className={classes.deleteContainer}
                onClick={(e) => {
                  props.setDeleteFileModal(true);
                  /* if (props?.thumbnail?.name) {
                  } else {
                    props.handleClearUpload();
                  } */
                }}
              >
                <Trash size={20} className={classes.closeIcon} />
              </div>
            </div>
          ) : (
            <label className={classes.label} for="thumbnailUpload">
              <div className={classes.fileIcon}>
                <CloudArrowUp size={25} />
              </div>
              <div className={classes.labelText}>
                Click to upload
                <br />
                or
                <br />
                Drag and Drop on the screen
              </div>
            </label>
          )}
          <input
            type="file"
            style={{ display: "none" }}
            accept="image/* video/*"
            id="thumbnailUpload"
            onChange={(e) => props.handleThumbnailUpload(e)}
          />
        </>
      )}
    </div>
  );
}
