import React from "react";
import SvgUnAuth from "./SvgUnAuth.component";

export default function UnAuthorized(props) {
  return (
    <>
      <h1
        style={{
          fontSize: "20px",
          backgroundColor: "#1B1D28",
          padding: "20px 30px",
          margin: "0px",
          fontFamily: "Nunito Sans",
          color: "#4ECCA3",
        }}
      >
        MovieCollab
      </h1>

      <div
        style={{
          backgroundColor: "#1B1D28",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <h1
            style={{
              fontSize: "60px",
              margin: "0px",
              fontFamily: "Nunito Sans",
              color: "#4ECCA3",
            }}
          >
            {props.notLoggedIn !== true
              ? "Not LoggedIn"
              : "Unauthorized  Access"}
          </h1>
          <br />
          <p
            style={{
              fontFamily: "Nunito Sans",
              color: "#ffffff",
              fontSize: "16px",
            }}
          >
            Looks like the page you were trying to <br />
            access requires special permission.
          </p>
        </div>
        <div>
          <SvgUnAuth />
        </div>
      </div>
    </>
  );
}
