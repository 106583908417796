import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  statusContainer: {
    backgroundColor: theme.palette.secondary.light,
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    width: "237px",
    marginBottom: "1%",
    padding: "8px",
    borderRadius: "10px",
  },
  icon: {
    cursor: "pointer",
  },
  statusName: {
    fontSize: "16px",
  },
  statusIcon: {
    color: theme.palette.secondary.dark,
    marginRight: "10px",
  },
  menuIcon: {
    cursor: "pointer",
  },
  menuPop: {
    margin: "0px",
    padding: "0px",
    backgroundColor: theme.palette.secondary.light,
    boxShadow: "none",
  },

  modalButton: {
    width: "160px",
    height: "40px",

    borderColor: theme.palette.secondary.light,
    background: theme.palette.secondary.light,
    fontFamily: "Nunito sans",
    textAlign: "center",
    color: theme.palette.text.primary,
  },
  button: {
    minWidth: "0",
    padding: "0",
  },
}));

export default useStyles;
