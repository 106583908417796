import { Cookie } from "phosphor-react";
import { Tooltip } from "@material-ui/core";
import React from "react";

const PriorityViewer = ({ priority }) => {
  return (
    <Tooltip title={priority} placement="top">
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        {priority === "Low" && (
          <>
            <Cookie size={20} style={{ color: "#FC5959" }} />
          </>
        )}
        {priority === "Medium" && (
          <>
            <Cookie size={20} style={{ color: "#FC5959" }} />
            <Cookie size={20} style={{ color: "#FC5959" }} />
          </>
        )}
        {priority === "High" && (
          <>
            <Cookie size={20} style={{ color: "#FC5959" }} />
            <Cookie size={20} style={{ color: "#FC5959" }} />
            <Cookie size={20} style={{ color: "#FC5959" }} />
          </>
        )}
      </div>
    </Tooltip>
  );
};

export default PriorityViewer;
