import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "480px",
    "& .MuiFormHelperText-root": {
      color: "#ffcc00",
    },
  },
  card: {
    borderRadius: "10px",
    color: theme.palette.text.tertiary,
  },
  cardContent: {
    backgroundColor: theme.palette.primary.main,
    padding: "18px 18px 18px 18px",
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: "24px",
    fontWeight: "500",
    marginBottom: "10px",
  },
  icon: {
    color: theme.palette.text.primary,
    cursor: "pointer",
  },
  cardContentDiv1: {
    justifyContent: "space-around",
    marginTop: "5%",
    height: "390px",
    overflowY: "auto",
    overflowX: "hidden",
  },
  cardContentInput1: {
    padding: "5px 5px 5px 10px",
    height: "41px",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    width: "100%",
    marginBottom: "10px",
  },
  toolContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  seqModalCloseIcon: {
    cursor: "pointer",
    marginTop: "8px",
  },
  searchDiv: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "10px",
    width: "90%",
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.primary,
  },
  sequenceModal: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    padding: "10px",
    minWidth: "350px",
    maxWidth: "350px",
  },
  searchInput: {
    padding: "3px 9px",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  sequenceList: {
    height: "250px",
    overflowY: "scroll",
    marginTop: "4%",
  },
  singleSequence: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    cursor: "pointer",
    position: "relative",
  },
  check: {
    color: theme.palette.secondary.main,
  },
  hrSeq: {
    borderTop: 0,
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
    margin: "10px 3px",
  },
  submitBtn: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.secondary.main,
    padding: "10px",
    borderRadius: "10px",
    color: theme.palette.text.primary,
    width: "140px",
  },
  textFieldContainer: {
    marginTop: "8px",
    marginRight: "5px",
  },
  userPlusIcon: {
    width: "36px",
    height: "36px",
    borderRadius: "50%",
    borderColor: theme.palette.text.tertiary,
    color: theme.palette.text.tertiary,
    border: "2px solid",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  seqButtonDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15px",
  },
  seqButton: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "10px",
    width: "85px",
  },
  paper: {
    background: theme.palette.secondary.light,
    color: theme.palette.text.primary,
  },
  input: {
    "&:focus": {
      background: "none",
    },
  },
  fullNameUser: {
    display: "flex",
    alignItems: "center",
    border: `1px solid grey`,
    borderRadius: "15px",
    marginRight: "5px",
  },
}));

export default useStyles;
