import * as React from "react";
import { Reorder, useDragControls } from "framer-motion";
import { X, DotsSixVertical } from "phosphor-react";
import useStyles from "./RearrangeItems.style.js";
export const Item = ({ item }) => {
  const dragControls = useDragControls();
  const classes = useStyles();
  return (
    <Reorder.Item
      value={item}
      style={{ width: "100%" }}
      className={classes.itemContainer}
      dragControls={dragControls}
      dragListener={true}
    >
      <div style={{ width: "30%" }}>{item?.name}</div>
      <div className={classes.dateContainer}>
        {new Date(item?.start_time).toString().substr(4, 6)} -{" "}
        {new Date(item?.end_time).toString().substr(4, 6)}
      </div>
      <div className={classes.iconContainer}>
        <DotsSixVertical
          className={classes.icon}
          dragControls={dragControls}
          size={22}
        />
      </div>
    </Reorder.Item>
  );
};
