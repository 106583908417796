const urls = `MOVCOLAB_URL=${process.env.REACT_APP_BASE_URL}\nSSO_URL=${process.env.REACT_APP_BASE_URL_SSO}\nPROJECT_ID=`;

export const downloadToFile = (projectId, filename, contentType) => {
  const a = document.createElement("a");
  const file = new Blob([urls + projectId], { type: contentType });
  a.href = URL.createObjectURL(file);
  a.download = filename;
  a.click();

  URL.revokeObjectURL(a.href);
};
// downloadToFile('', 'URLs.env', 'text/plain');
