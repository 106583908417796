import { ActionTypes } from "../actions/currentUser";

const defaultState = {
  currentUsers: ["no current User"],
};

export default function currentUserReducer(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.SET_CURRENTUSERS:
      return { ...state, currentUsers: action.payload };

    default:
      return state;
  }
}
