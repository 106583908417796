import React, { useState, useEffect } from "react";

//UI
import { Droplet } from "react-feather";
import { Divider, CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import useStyles from "./NotificationsPage.styles";

//APIs
import { fget, fpatch } from "../../API/callsAPI";

function NotificationsPage() {
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [url, setUrl] = useState([]);

  const fetchNotification = async () => {
    try {
      const notificationRes = await fget({
        url: "notification/",
      });
      setNotifications(notificationRes.data.results);
      setIsLoaded(true);
    } catch (error) {
      setIsLoaded(true);
      console.log(error);
    }
  };

  const readNotifications = async (id, i) => {
    let newNotification = {
      url: `notification/${id}/`,
      data: {
        isRead: true,
      },
    };

    try {
      await fpatch(newNotification);
      fetchNotification();
    } catch (error) {
      console.log(error);
    }
    fetchNotification();
    window.open(url[i], "_self");
  };

  const formUrl = () => {
    let tempUrl = [];
    {
      notifications.map((el) => {
        let url = `/${el.project}/`;
        if (el.department) {
          url += `departments/${el.department}/department`;
        } else if (el.sequence) {
          url += `${el.sequence}/sequences/`;
          if (el.trackable) {
            url += `${el.trackable}/shots`;
            if (el.version) {
              url += `${el.version}`;
            }
          }
        } else if (el.trackable) {
          url += `assets/${el.trackable}/assetversions`;

          if (el.version) {
            url += `${el.version}/assetversion`;
          }
        } else {
          url += "updates";
        }
        tempUrl.push(url);
      });
    }
    setUrl(tempUrl);
  };
  useEffect(() => {
    fetchNotification();
  }, []);
  useEffect(() => {
    formUrl();
  }, [notifications]);

  return (
    <div className={classes.notificationsDropdown}>
      <h1 style={{ fontFamily: "Nunito Sans", paddingLeft: "20px" }}>
        Notifications
      </h1>
      <div className={classes.notificationsDropdownMenu}>
        {!isLoaded ? (
          <CircularProgress color="secondary" />
        ) : (
          notifications.map((el, i) => {
            if (!el.isRead)
              return (
                <div onClick={() => readNotifications(el.id, i)}>
                  <div className={classes.notificationsItems}>
                    <Droplet className={classes.notificationDrop} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <p className={classes.description}>{el.message}</p>
                      <p className={classes.date}>
                        {new Intl.DateTimeFormat("en-IN", {
                          day: "2-digit",
                          month: "long",
                          year: "numeric",
                        }).format(new Date(el.created_at))}
                      </p>
                    </div>
                  </div>
                  <Divider
                    style={{ marginLeft: "20px", marginRight: "20px" }}
                  />
                </div>
              );
            else {
              return (
                <Link to={url[i]} style={{ textDecoration: "none" }}>
                  <div className={classes.notificationsItems}>
                    <Droplet className={classes.notificationDrop} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <p className={classes.description2}>{el.message}</p>
                      <p className={classes.date}>
                        {new Intl.DateTimeFormat("en-IN", {
                          day: "2-digit",
                          month: "long",
                          year: "numeric",
                        }).format(new Date(el.created_at))}
                      </p>
                    </div>
                  </div>
                  <Divider
                    style={{ marginLeft: "20px", marginRight: "20px" }}
                  />
                </Link>
              );
            }
          })
        )}
      </div>
    </div>
  );
}

export default NotificationsPage;
