import { layer } from "@fortawesome/fontawesome-svg-core";

class TimelineContent {
  #sortedLayer = [];
  #reverseSortedArray = [];
  //takes no. of tracks/layers.
  //takes shots parameter.

  //once we have shot reprs and layers
  // {
  //     0: {
  //         clips: {},
  //         layerName: {}
  //     },
  //     1: {
  //         clips: {}
  //     }
  // }
  constructor(l, s, { ondata }) {
    //sort layers in assending
    l.sort((a, b) => a.z_index - b.z_index);
    this.#sortedLayer = l;
    const temp = l;
    this.#reverseSortedArray = temp.reverse();
    const result = this.generateData(l, s);
    ondata(result);
  }
  
  onDataUpdate(layers, shotReprs, {ondata}){
    layers.sort((a, b) => a.z_index - b.z_index);
    this.#reverseSortedArray = layers;
    const temp = layers;
    this.#reverseSortedArray = temp.reverse();
    const result = this.generateData(layers, shotReprs);
    ondata(result);
  }

  generateData(layers, shotReprs) {
    const localObject = {};
    localObject.count = layers.length;
    const arrOfKeys = [];
    for (let i = 0; i < layers.length; i++) {
      localObject[i] = layers[i];
      localObject[i].data = {};
      localObject[i].index = i;
      arrOfKeys.push(i);
    }
    for (let i = 0; i < shotReprs.length; i++) {
      const space = this.getIndexOfLayer(shotReprs[i].layer.id);
      const layerPosition = this.#reverseSortedArray.findIndex((each) => each.id === shotReprs[i].layer.id)
      if (space !== -1) {
        //clean up this part of code!
        localObject[space].data[shotReprs[i].start_frame] = shotReprs[i];
        localObject[space].data[shotReprs[i].start_frame].layer.index = layerPosition;
        localObject[space].data[shotReprs[i].start_frame].layer.index = layerPosition;
      }
    }
    const objArr = [];
    for (const key in arrOfKeys) {
      objArr.push(localObject[key].data);
    }
    const resultOfShots = this.mergeObjects(objArr);
    const layersAndShots = {
        layers: localObject,
        shotReprs: resultOfShots
    }
    return layersAndShots;
  }

  getIndexOfLayer(layerId) {
    const res = this.#sortedLayer.findIndex((each) => each.id === layerId);
    return res;
  }

  mergeObjects(objects) {
    const result = {};

    for (const object of objects) {
      for (const key in object) {
        if (result[key]) {
          result[key].push(object[key]);
        } else {
          result[key] = [object[key]];
        }
      }
    }
    return result;
  }
}

export default TimelineContent;