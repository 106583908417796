import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    height: "90px",
  },
  trackableName: {
    width: "9%",
    marginTop: "20px",
    fontFamily: "Poppins",
  },
  trackableIcon: {
    display: "flex",
    alignItems: "center",
    marginTop: "3px",
  },
  rowContainer: {
    display: "flex",
    marginTop: "8px",
    marginLeft: "4px",
    width: "90%",
  },
  tablerow: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    position: "relative",
  },
  tablerowButton: {
    width: "150px",
    position: "absolute",
    right: "0px",
    background: theme.palette.gradient.primary,
  },
  tableRowLeftButton: {
    width: "150px",
    position: "absolute",
    left: "-80px",
    background: "linear-gradient(90deg, #1B1D28 60%, rgba(0, 0, 0, 0) 100%)",
    zIndex: 5,
  },
  tablerowButtonOpenModal: {
    width: "150px",
    position: "absolute",
    right: "410px",
    background: theme.palette.gradient.primary,
  },
  ChevronRight: {
    width: "50px",
    height: "70px",
    cursor: "pointer",
    color: theme.palette.secondary.main,
    marginLeft: "60%",
  },
}));
