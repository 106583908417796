import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  toolsContainer: {
    height: "72px",
    width: "100%",
    padding: "0 30px",
    fontSize: "18px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "40px",
  },
  annotationButton: {
    minWidth: "0px",
    paddin: "5px",
  },
  iconStyle: {
    height: "30px",
    width: "30px",
  },
  actionIcons: {
    color: theme.palette.text.tertiary,
  },
  arrowIconCircle: {
    padding: "2px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    border: `1px solid ${theme.palette.text.tertiary}`,
  },
  btnContainer: {
    display: "flex",
    alignItems: "center",
    gap: "14px",
  },
  toolButton: {
    width: "155px",
    height: "32px",
    borderRadius: "43px",
    padding: "0px 8px 0px 9px",
    border: "1.5px solid #CE7606",
  },
  toolButton2: {
    width: "145px",
    height: "32px",
    borderRadius: "43px",
    padding: "0px 8px 0px 2px",
    border: `1.5px solid ${theme.palette.secondary.main}`,
  },
}));

export default useStyles;
