import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@material-ui/core";

import useStyles from "./ProjectPageFiltersModal.styles";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { X } from "react-feather";
import PropTypes from "prop-types";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

export default function ProjectPageFiltersModal(props) {
  const [selectedStartDate, setSelectedStartDate] = React.useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = React.useState(new Date());

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  const clearFilter = () => {
    props.updateRefreshToken();
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <h1 className={classes.title}>Filters</h1>
            <div className={classes.clear}>
              <X size="30px" onClick={() => props.closeFilterModal()} />
            </div>
          </div>
          <div className={classes.cardContentDiv}>
            <div className={classes.datePickerContainer}>
              <div>
                From
                <div className={classes.datePickerDiv}>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      invalidDateMessage={"inValid"}
                      variant="inline"
                      format="DD/MM/yyyy"
                      margin="normal"
                      value={selectedStartDate}
                      onChange={(date) => handleStartDateChange(date)}
                      id="Startdate-picker"
                      InputProps={{
                        disableUnderline: true,
                        style: { fontFamily: "Poppins" },
                      }}
                      InputLabelProps={{
                        style: { fontFamily: "Poppins" },
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                        style: {
                          padding: "0px",
                        },
                      }}
                      className={classes.datePickerComponent}
                      size="small"
                      fullWidth={true}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>

              <div>
                To
                <div className={classes.datePickerDiv}>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      invalidDateMessage={"inValid"}
                      variant="inline"
                      format="DD/MM/yyyy"
                      margin="normal"
                      value={selectedEndDate}
                      InputProps={{
                        disableUnderline: true,
                        style: { fontFamily: "Poppins" },
                      }}
                      InputLabelProps={{
                        style: { fontFamily: "Poppins" },
                      }}
                      onChange={(date) => handleEndDateChange(date)}
                      id="Enddate-picker"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                        style: {
                          padding: "0px",
                        },
                      }}
                      className={classes.datePickerComponent}
                      size="small"
                      fullWidth={true}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
        <div className={classes.cardAction}>
          <Button
            size="small"
            variant="outlined"
            className={classes.doneButton}
            onClick={() => {
              props.setBtnActive(true);
              props.handleFilter(selectedStartDate, selectedEndDate);
            }}
          >
            Done
          </Button>
          <div className={classes.clear}>
            <Button
              onClick={() => {
                props.setBtnActive(false);
                clearFilter();
              }}
            >
              Clear Filter
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
}

ProjectPageFiltersModal.propTypes = {
  handleFilter: PropTypes.func,
  value: PropTypes.array,
  closeFilterModal: PropTypes.func,
};
