import { Box, Button, Card, CardContent, Typography } from "@material-ui/core";
import React from "react";
import useStyles from "./ErrorStyles";
import { useHistory } from "react-router-dom";
import PageBreakSvg from "../Svg/PageBreakSvg";
import { Info } from "phosphor-react";

const PageBreakError = ({ error, resetError, componentStack }) => {
  console.log(error);
  const history = useHistory();
  const classes = useStyles();
  const [showError, setShowError] = React.useState(true);
  return (
    <Box className={classes.root}>
      <Card elevation={0} className={classes.card}>
        <CardContent className={classes.cardContent}>
          <PageBreakSvg />
          <Typography className={classes.errorHead}>
            Page Break Error
          </Typography>
          <Typography className={classes.errorOccurred}>
            <Info size={40} />
            <span>
              An error occurred, Check browser console for error details
            </span>
          </Typography>
          <div className={classes.buttonDiv}></div>

          {error ? (
            <>
              <div className={classes.errorAndButton}>
                <div className={classes.buttonDiv}>
                  <Button
                    className={classes.reportIssueButton}
                    onClick={() => window.open("/reports")}
                  >
                    Report An Issue
                  </Button>
                  <Button
                    className={classes.showErrorButton}
                    onClick={() => window.location.reload()}
                  >
                    Reload
                  </Button>
                  {/* <Button
                    className={classes.showErrorButton}
                    onClick={() => setShowError(!showError)}
                  >
                    {showError ? "Hide" : "Show"} Error
                  </Button> */}
                </div>
                <div className={classes.errorMessage}>
                  <Typography>
                    {error && showError
                      ? error
                          .toString()
                          .split("\n")
                          .map((each, i) => `${i}: ${each}`)
                      : ""}
                  </Typography>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default PageBreakError;
