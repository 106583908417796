import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { useStyles } from "./TaskGanttNavBar.styles";
import { ViewMode } from "movie-collab-gantt";
export default function TaskGanttNavBar(props) {
  const classes = useStyles();
  const [viewState, setViewState] = useState({ M: false, W: false, D: true });

  function handleViewChange(type) {
    //for css border change
    if (type === "M") {
      setViewState({ M: true, W: false, D: false });
      props.onViewChange(ViewMode.Month);
    } else if (type === "W") {
      setViewState({ M: false, W: true, D: false });
      props.onViewChange(ViewMode.Week);
    } else if (type === "D") {
      setViewState({ M: false, W: false, D: true });
      props.onViewChange(ViewMode.Day);
    }
    //for view to propogate
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "100%",
          alignItems: "center",
        }}
      >
        <div>
          {props.isDateChanged ? (
            <Button
              className={classes.buttons}
              variant="filled"
              disabled={false}
              onClick={props.onSave}
              id="saveButton"
            >
              Save
            </Button>
          ) : (
            <Button
              className={classes.buttons}
              variant="filled"
              disabled={true}
            >
              Save
            </Button>
          )}

          <Button
            className={classes.buttons}
            onClick={() => handleViewChange("M")}
            style={
              viewState.M
                ? { border: "2px solid rgba(96, 100, 121, 1)" }
                : { border: "2px" }
            }
          >
            Monthly
          </Button>
          <Button
            className={classes.buttons}
            onClick={() => handleViewChange("W")}
            style={
              viewState.W
                ? { border: "2px solid rgba(96, 100, 121, 1)" }
                : { border: "2px" }
            }
          >
            Weekly
          </Button>
          <Button
            className={classes.buttons}
            onClick={() => handleViewChange("D")}
            style={
              viewState.D
                ? { border: "2px solid rgba(96, 100, 121, 1)" }
                : { border: "2px" }
            }
          >
            Day
          </Button>
        </div>
      </div>
    </>
  );
}
