import React, { useState, useEffect } from "react";
import { fget } from "../../../API/callsAPI";
import ConfigureEmail from "./ConfigureEmails/ConfigreEmails";
import ConfigureNotifications from "./ConfigureNotifications/ConfigureNotifications";
import ChannelConnectorConfiguration from "./ChannelConnectorConfiguration/ChannelConnectorConfiguration";
import { Divider } from "@material-ui/core";
export default function GeneralSettingOverview({ projectId, setSnackValue }) {
  const [notificationEvents, setnotificationEvents] = useState({});
  const [emailEvents, setEmailEvents] = useState({});
  useEffect(() => {
    fetchNotificationEvents();
    fetchEmailEvents();
  }, []);
  const fetchNotificationEvents = async () => {
    try {
      const res = await fget({
        url: `notification-events/${projectId}`,
      });
      setnotificationEvents(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchEmailEvents = async () => {
    try {
      const res = await fget({
        url: `email-events/${projectId}`,
      });
      setEmailEvents(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div style={{ marginTop: "0.75%" }}>
        <ConfigureNotifications
          setSnackValue={setSnackValue}
          notifications={notificationEvents}
          projectId={projectId}
        />
      </div>
      <Divider />
      <div style={{ marginTop: "0.75%" }}>
        <ConfigureEmail
          setSnackValue={setSnackValue}
          emailNotifications={emailEvents}
          projectId={projectId}
        />
      </div>
      <Divider />
      <div style={{ marginTop: "0.75%" }}>
        <ChannelConnectorConfiguration
          projectId={projectId}
          setSnackValue={setSnackValue}
        />
      </div>
    </>
  );
}
