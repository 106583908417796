import { Box, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import CompletePercentage from "../../../../Utilities/CompletePercentage/CompletePercentage";
import CompletePercentageTwo from "../../../../Utilities/CompletePercentage/CompletePercentageTwo";
import NoResultMessage from "../../../../Utilities/NoResultFound/NoResultMessage";
import useStyles from "./ProjectHealthWidgets.style";

const ProjectStatus = ({ healthData, projectDetails }) => {
  const classes = useStyles();
  const [taskCompletePercentage, setTaskCompletePercentage] = useState(0);
  const [series, setSeries] = useState([]);
  const [uniqueDaysArray, setUniqueDaysArray] = useState([]);
  const options = {
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    chart: {
      height: 120,
      type: "area",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    xaxis: {
      type: "date",
      categories: uniqueDaysArray,
      tooltip: false,
      mim: 1,
      max: 8,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
      crosshairs: {
        show: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
      crosshairs: {
        show: false,
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const date = uniqueDaysArray && uniqueDaysArray[dataPointIndex];
        return (
          "<div >" +
          '<div class="custom-tooltip">' +
          "<div class='task-number-in-health'>" +
          "Task Completed : " +
          series[seriesIndex][dataPointIndex] +
          "</div>" +
          "</div>" +
          "<div class='date-in-health'>" +
          date +
          "</div>" +
          "</div>"
        );
      },
      shared: false,
      x: {
        format: "dd/MM/yy",
      },
      theme: false,
      style: {
        fontSize: "12px",
        fontFamily: undefined,
        color: "red",
        backgroundColor: "red",
      },
    },
    fill: {
      gradient: {
        enabled: true,
        gradientToColors: [
          "rgba(108, 101, 217, 0.2)",
          "rgba(108, 101, 217, 0)",
        ],
        type: "vertical",
        shadeIntensity: 1,
      },
    },
  };

  useEffect(() => {
    const result = {};
    const uniqueDays = new Set(); // Store unique days

    healthData &&
      healthData?.task_completion?.forEach((obj) => {
        const tasks = obj.tasks;
        Object.entries(tasks).forEach(([day, count]) => {
          if (result[day]) {
            result[day] += count;
          } else {
            result[day] = count;
          }
          uniqueDays.add(day); // Add day to the set
        });
      });
    const totalsArray = Object.values(result);
    setUniqueDaysArray(Array.from(uniqueDays)); // Convert set to array
    setSeries([
      {
        name: "Total",
        data: totalsArray,
      },
    ]);
    setTaskCompletePercentage(
      (healthData?.completed_tasks / healthData?.total_tasks).toFixed(1) * 100
    );
  }, [healthData]);

  return (
    <Grid container className={classes.styleRoot}>
      <Grid item md={4} className={classes.flexCenter}>
        <Box>
          <span
            className={
              projectDetails?.completion_percentage >= 0 &&
              projectDetails?.completion_percentage <= 20
                ? classes.percentagePlaceOne
                : projectDetails?.completion_percentage > 20 &&
                  projectDetails?.completion_percentage <= 40
                ? classes.percentagePlaceTwo
                : projectDetails?.completion_percentage > 40 &&
                  projectDetails?.completion_percentage <= 60
                ? classes.percentagePlaceThree
                : classes.percentagePlaceFour
            }
          >
            {parseInt(projectDetails?.completion_percentage)}%
          </span>
          <div style={{ height: "160px", paddingLeft: "40px" }}>
            <div style={{ position: "relative", top: "10px" }}>
              <CompletePercentage
                percentage={taskCompletePercentage}
                size={130}
                stroke={10}
                showPercentage={true}
              />
            </div>
            <div style={{ position: "relative", top: "-135px", left: "-15px" }}>
              <CompletePercentageTwo
                percentage={projectDetails?.completion_percentage}
                size={160}
                stroke={4}
                showPercentage={false}
              />
            </div>
          </div>
          <div className={classes.taskCOmpletionText}>
            Task Completion Percentage
          </div>
        </Box>
      </Grid>
      <Grid item md={8}>
        <Box>
          {healthData?.task_completion?.length ? (
            <>
              <Box className={classes.flexAround}>
                <Typography
                  className={`${classes.healthText} ${
                    projectDetails?.completion_percentage >= 0 &&
                    projectDetails?.completion_percentage <= 20
                      ? classes.textFirst
                      : projectDetails?.completion_percentage > 20 &&
                        projectDetails?.completion_percentage <= 40
                      ? classes.textSecond
                      : projectDetails?.completion_percentage > 40 &&
                        projectDetails?.completion_percentage <= 60
                      ? classes.textThird
                      : projectDetails?.completion_percentage > 60 &&
                        projectDetails?.completion_percentage <= 80
                      ? classes.textFourth
                      : classes.textFifth
                  }`}
                >
                  {projectDetails?.completion_percentage >= 0 &&
                  projectDetails?.completion_percentage <= 20
                    ? "Significant Delay"
                    : projectDetails?.completion_percentage > 20 &&
                      projectDetails?.completion_percentage <= 40
                    ? "Partial Delay"
                    : projectDetails?.completion_percentage > 40 &&
                      projectDetails?.completion_percentage <= 60
                    ? "Healthy and on time"
                    : projectDetails?.completion_percentage > 60 &&
                      projectDetails?.completion_percentage <= 80
                    ? "Ahead of Schedule"
                    : "High Productivity"}
                </Typography>
                {/* <Button>
              <Info size={24} className={classes.icon} />
            </Button> */}
              </Box>
              <Box className={classes.flexCenter}>
                <Typography>
                  <span style={{ fontSize: "10px", color: "#6C65D9" }}>
                    Total Task :
                  </span>
                  {"  "}
                  <span>{healthData?.total_tasks ?? 0}</span>
                </Typography>
                <Typography>
                  <span style={{ fontSize: "10px", color: "#6C65D9" }}>
                    Total Tasks Completed :
                  </span>
                  {"  "}
                  <span>{healthData?.completed_tasks ?? 0}</span>
                </Typography>
              </Box>
              <Box sx={{ marginTop: "-20px" }}>
                <ReactApexChart
                  options={options}
                  series={series}
                  type="area"
                  height="100%"
                />
              </Box>
            </>
          ) : (
            <>
              <NoResultMessage name="Info" iconSize={30} fontSize="18px" />
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ProjectStatus;
