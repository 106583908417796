/* eslint no-undef: "off"*/
// const extractFrames = require('ffmpeg-extract-frames');

(function (root, factory) {
  if (typeof define === "function" && define.amd) {
    define([], factory);
  } else {
    root.captureVideoFrame = factory();
  }
})(this, function () {
  return function captureVideoFrame(video, format, quality) {
    if (typeof video === "string") {
      video = document.getElementById(video);
    }
    format = format || "jpeg";
    quality = quality || 0.92;

    if (!video || (format !== "png" && format !== "jpeg")) {
      return false;
    }

    var canvas = document.createElement("CANVAS");

    // canvas.width = "598"; //fixed
    // canvas.height = "270"; //variable
    // canvas.width = video.videoWidth;
    // canvas.height = video.videoHeight;
    canvas.width = 720;
    canvas.height = (canvas.width / video.videoWidth) * video.videoHeight;
    if (video.videoHeight >= video.videoWidth) {
      canvas.width = 720;
      canvas.height = 402;
      var scale = Math.min(
        canvas.width / video.videoWidth,
        canvas.height / video.videoHeight
      );
      // get the top left position of the image
      var x = canvas.width / 2 - (video.videoWidth / 2) * scale;
      var y = canvas.height / 2 - (video.videoHeight / 2) * scale;
      canvas
        .getContext("2d")
        .drawImage(
          video,
          x,
          y,
          video.videoWidth * scale,
          video.videoHeight * scale
        );
    } else {
      canvas.width = 720;
      canvas.height = (canvas.width / video.videoWidth) * video.videoHeight;

      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, canvas.width, canvas.height);
    }

    var dataUri = canvas.toDataURL("image/" + format, quality);
    var data = dataUri.split(",")[1];
    var mimeType = dataUri.split(";")[0].slice(5);

    var bytes = window.atob(data);
    var buf = new ArrayBuffer(bytes.length);
    var arr = new Uint8Array(buf);

    for (var i = 0; i < bytes.length; i++) {
      arr[i] = bytes.charCodeAt(i);
    }

    var blob = new Blob([arr], { type: mimeType });
    return { blob: blob, dataUri: dataUri, format: format };
  };
});
