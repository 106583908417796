import TimelineContent from "./TimelineContent";

class TimelineRenderer {
  #ctx; //context of the canvas
  #timelineContent; //tree for representing a timeline
  #currentTimelineTime = 0;
  #zoomLevel = 1;
  #diffCounter = null;
  #duration;
  #visibleMarkers = null;
  #canvasBounding;
  #lineGap;
  #hiddenFactor;
  #panBehaviour = { atLast: false };

  #offSetDurationX = 0;

  //callback functions
  #onTimelineSeek = null;
  #onTimelineClick = null;
  #onTimelineDoubleClick = null;

  //timeline content Data.
  #timelineContentClass;
  #timelineData;
  #timelineLayersId;

  #timelineSelectedShot = null;

  constructor(
    { canvasElement, height, width },
    { layers, shotReprs, duration },
    { onTimelineClick, onTimelineSeek, onTimelineDoubleClick }
  ) {
    this.#ctx = canvasElement.getContext("2d");
    this.#canvasBounding = canvasElement.getBoundingClientRect();
    this.#timelineLayersId = layers.map((each, i) => each.id);
    this.#onTimelineClick = onTimelineClick;
    this.#onTimelineSeek = onTimelineSeek;
    this.#onTimelineDoubleClick = onTimelineDoubleClick;
    this.canvasElement = canvasElement;
    this.canvasElement.height = height;
    this.canvasElement.width = width;
    this.DefaultLinegap = this.canvasElement.width / 1000;
    this.#lineGap = this.DefaultLinegap;
    this.canvasElement.onwheel = this.handleMouseWheel.bind(this);
    this.canvasElement.onclick = this.handleMouseClick.bind(this);
    this.canvasElement.ondblclick = this.handleMouseDoubleClick.bind(this);
    this.panPosition = { x: 0, y: 0 };
    this.#duration = duration;
    this.#diffCounter = this.#duration / 10;
    this.#visibleMarkers = duration;

    //timelineData
    if (layers && shotReprs) {
      this.#timelineContentClass = new TimelineContent(layers, shotReprs, {
        ondata: (res) => {
          this.#timelineData = res;
          this.draw();
        },
      });
    }

    this.draw();
  }

  onContentDataUpdate(layers, shotReprs) {
    if (this.#timelineContentClass) {
      this.#timelineContentClass.onDataUpdate(layers, shotReprs, {
        ondata: (result) => (this.#timelineData = result),
      });
      this.draw();
    }
  }

  handleMouseDoubleClick(event) {
    event.preventDefault();
    const time = parseInt(
      (event.pageX - this.#canvasBounding.left) /
        (this.#lineGap + this.#zoomLevel) +
        this.#offSetDurationX
    );

    const layer =
      parseInt((event.pageY - this.#canvasBounding.top - 60) / 27) - 1;
    const layerId = Object.values(this.#timelineData.layers).find(
      (each) => each.index === layer
    );

    const result = layerId
      ? Object.values(this.#timelineData.shotReprs).find(
          (eachShot) =>
            time * 24 >= eachShot[0].start_frame &&
            time * 24 <= eachShot[0].end_frame &&
            eachShot[0].layer.id === layerId.id
        )
      : null;

    if (result) {
      this.#onTimelineDoubleClick({
        type: "selectShot",
        data: result,
        action: "doubleClick",
      });
    }
  }

  handleMouseWheel(event) {
    // console.log(event);
    // check wether thumb is movable
    event.preventDefault();
    if (event.deltaY > 0) {
      if (this.#panBehaviour.atLast) {
        if (event.ctrlKey) {
          this.#zoomLevel = this.#zoomLevel + 1;
          if (this.#zoomLevel % 10 === 0) {
            this.#diffCounter = Math.max(1, this.#diffCounter - 10);
          }
        } else {
          if (event.shiftKey) {
            this.panPosition.y = Math.min(this.panPosition.y + 5);
          } else {
            this.panPosition.x = Math.min(this.panPosition.x + 5);
          }
        }
        this.draw();
      }
    } else {
      if (event.ctrlKey) {
        this.#zoomLevel = Math.max(1, this.#zoomLevel - 1);
        if (this.#zoomLevel % 10 === 0) {
          //not working as expected;
          this.#diffCounter = Math.min(this.#zoomLevel, this.#diffCounter + 10);
        }
      } else {
        if (event.shiftKey) {
          this.panPosition.y = Math.max(0, this.panPosition.y - 5);
        } else {
          this.panPosition.x = Math.max(0, this.panPosition.x - 5);
        }
      }
    }
    //ctrl plus zoom we will zoom in
    this.draw();
  }

  handleMouseClick(event) {
    if (event.pageY - this.#canvasBounding.top < 60) {
      this.#onTimelineSeek(
        parseInt(
          (
            (event.pageX - this.#canvasBounding.left) /
              (this.#lineGap + this.#zoomLevel) +
            this.#offSetDurationX
          ).toFixed()
        )
      );
    } else {
      //handle shot selection
      //x,y
      const time = parseInt(
        (event.pageX - this.#canvasBounding.left) /
          (this.#lineGap + this.#zoomLevel) +
          this.#offSetDurationX
      );

      const layer =
        parseInt((event.pageY - this.#canvasBounding.top - 60) / 27) - 1;
      const layerId = Object.values(this.#timelineData.layers).find(
        (each) => each.index === layer
      );

      const result = layerId
        ? Object.values(this.#timelineData.shotReprs).find(
            (eachShot) =>
              time * 24 >= eachShot[0].start_frame &&
              time * 24 <= eachShot[0].end_frame &&
              eachShot[0].layer.id === layerId.id
          )
        : null;

      if (result && result !== this.#timelineSelectedShot) {
        this.#onTimelineClick({
          type: "selectShot",
          data: result,
          action: "singleClick",
        });
      } else {
        this.#onTimelineClick({
          type: "selectShot",
          data: [{}],
          action: "singleClick",
        });
      }
      this.#timelineSelectedShot = result || {};
    }
  }

  /**
   * @param
   * {type, value}
   */
  set setCurrentTimelineTime({ type, value }) {
    //for now use seconds once encoder is there in place lets use frames.
    this.#currentTimelineTime = value;
    if (
      value >= this.#visibleMarkers - 3 &&
      value <= this.#visibleMarkers + 3
    ) {
      this.panPosition.x = this.panPosition.x + 3 * this.DefaultLinegap;
    }
    this.draw();
  }

  set setZoomLevel(value) {
    this.#zoomLevel = value;
    this.#lineGap = this.#zoomLevel + this.DefaultLinegap;
    this.draw();
  }

  set setCanvasWidth(value) {
    this.canvasElement.width = value;
    this.draw();
  }

  draw() {
    let canvasDivision = {
      markerAreaH: 40,
      contentAreaH: 260,
      contentAreaW: this.canvasElement.width,
      totalCanvasH: this.canvasElement.height,
      totalCanvasW: this.canvasElement.width,
    };
    const barPointer = 0;
    const duration = 1000;
    const trackNumbers = this.#timelineData.layers.count;
    const panPosition = this.panPosition;
    //zoomLevel: 1, 10, 12
    //linegap: initially canvasWidth / duration
    //lineGap: zoomlevel + linegap;
    const zoomLevel = this.#zoomLevel;
    const lineGap = this.#lineGap + zoomLevel || this.DefaultLinegap;
    // capacity: canvasDivision.contentAreaW / lineGap;
    const capacity = canvasDivision.contentAreaW / lineGap;
    const percentVisible = (capacity / duration) * 100;
    const diffCounter = this.#diffCounter; // make this dynamic
    const ctx = this.#ctx;

    const hiddenFactor = (panPosition.x / capacity) * duration;
    this.#hiddenFactor = hiddenFactor;
    const offsetDurationX = parseInt(hiddenFactor / lineGap);
    this.#offSetDurationX = offsetDurationX;
    const visibleMarkers = parseInt(
      canvasDivision.totalCanvasW / lineGap + offsetDurationX
    );

    this.#panBehaviour.atLast =
      (canvasDivision.contentAreaW / 100) * percentVisible + panPosition.x <
      canvasDivision.contentAreaW;

    this.#visibleMarkers = visibleMarkers;

    this.#ctx.clearRect(
      0,
      0,
      canvasDivision.totalCanvasW,
      canvasDivision.totalCanvasH
    );

    for (let eachTrack = 0; eachTrack < trackNumbers + 1; eachTrack++) {
      //29 height of each track, 60 height of markers.
      this.#ctx.fillStyle = "#252A38";
      this.#ctx.fillRect(
        0,
        29 * eachTrack + 60,
        canvasDivision.totalCanvasW,
        27
      );
    }

    //pointer
    // the triangle
    const mark = this.#currentTimelineTime;
    ctx.beginPath();
    ctx.moveTo(mark * lineGap - 10 - hiddenFactor, 0);
    ctx.lineTo(mark * lineGap + 1 - hiddenFactor, 10);
    ctx.lineTo(mark * lineGap + 10 - hiddenFactor, 0);
    ctx.closePath();
    // the fill color
    ctx.fillStyle = "#6C65D9";
    ctx.fill();

    ctx.setLineDash([14, 8]); /*dashes are 5px and spaces are 3px*/
    ctx.beginPath();
    ctx.moveTo(mark * lineGap + 1 - hiddenFactor, 10);
    ctx.lineTo(mark * lineGap + 1 - hiddenFactor, canvasDivision.contentAreaH);
    ctx.lineWidth = 2;
    ctx.strokeStyle = "#6C65D9";
    ctx.stroke();

    //scroll
    if (true) {
      //horizontal
      //scroll container
      this.#ctx.fillStyle = "#252A38";
      this.#ctx.fillRect(
        0,
        canvasDivision.totalCanvasH - 24, //y cordinate is at 24 minus the height
        canvasDivision.contentAreaW,
        24
      );
      //scroll area
      this.#ctx.fillStyle = "#000000";
      this.#ctx.fillRect(
        0,
        canvasDivision.totalCanvasH - 14,
        canvasDivision.contentAreaW,
        7
      );
      //scroll thumb
      this.#ctx.fillStyle = "#606479";
      this.#ctx.fillRect(
        panPosition.x,
        canvasDivision.totalCanvasH - 14,
        (canvasDivision.contentAreaW / 100) * percentVisible,
        7
      );

      // //vertical
      // //scroll container
      // this.#ctx.fillStyle = "#252A38";
      // this.#ctx.fillRect(
      //   canvasDivision.totalCanvasW - 20,
      //   50,
      //   20,
      //   canvasDivision.contentAreaH
      // );

      // //scroll area
      // this.#ctx.fillStyle = "#000000";
      // this.#ctx.fillRect(
      //   canvasDivision.totalCanvasW - 13,
      //   55,
      //   7,
      //   canvasDivision.contentAreaH - 10
      // );
      // //scroll thumb
      // this.#ctx.fillStyle = "#606479";
      // this.#ctx.fillRect(
      //   canvasDivision.totalCanvasW - 13,
      //   panPosition.y + 50,
      //   7,
      //   (canvasDivision.contentAreaH / 100) * percentVisible
      // );
    }

    const diff =
      Math.ceil(diffCounter / 5) * 5 <= 0 ? 1 : Math.ceil(diffCounter / 5) * 5;
    //markers
    for (let i = 0; i < visibleMarkers + 10; i++) {
      //marker
      ctx.fillStyle = "#606479";
      if (i % diff === 0) {
        ctx.fillRect(i * lineGap - hiddenFactor, 25, 1, 30);

        ctx.fillText(i, i * lineGap - hiddenFactor, 20);
        if (diff === 1 && zoomLevel >= 100) {
          for (let iFPS = 1; iFPS <= 24; iFPS++) {
            const localLineGap = lineGap / 24;
            ctx.fillRect(
              i * lineGap + localLineGap * iFPS - hiddenFactor,
              25,
              1,
              25
            );
            //ctx.fillText(iFPS, i * lineGap + localLineGap * iFPS, 20);
          }
        }
      } else {
        if (i % 1 === 0) {
          ctx.fillRect(i * lineGap - hiddenFactor, 35, 1, 20);
        }
      }
      //bars
      ctx.fillStyle = "#606479";

      const curr = this.#timelineData?.shotReprs[i * 24];
      if (Boolean(curr)) {
        curr.map((each) =>
          ctx.fillRect(
            i * lineGap - hiddenFactor,
            29 * (each.layer.index + 1) + 60,
            ((each.end_frame - each.start_frame) / 24) * lineGap - 1,
            27
          )
        );
      }
    }
  }
}

export default TimelineRenderer;
