import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    statusColor:{
        border:"2px solid ",
        borderColor: theme.palette.border.main,
        borderRadius:"50%",
        height:"20px",
        width:"20px", 
    }
}))

export default useStyles;