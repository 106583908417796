import React from "react";
import useStyles from "./PlaylistItems.styles";
import ImageViewer from "../../Utilities/ImageViewer/ImageViewer.component";
import NoThumbnail from "../../Utilities/NoThumbnail.component";
import { Button, TableCell, TableRow, Avatar } from "@material-ui/core";
import { PencilSimpleLine } from "phosphor-react";
import SequencerIcon from "../../Utilities/Svg/SequencerIcon";
import DateAndTimeViewer from "../../Utilities/DateAndTimeViewer/DateAndTimeViewer.component";
import UserAvatar from "../../Utilities/UserAvatar/UserAvatar.component";

const PlaylistItems = ({ data, toggler, onItemSelect, handleEditModal }) => {
  const classes = useStyles();

  return (
    <>
      <TableRow hover className={classes.table} onClick={() => onItemSelect()}>
        <TableCell component="th" scope="row" className={classes.listItem}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div>
              {data?.thumbnail ? (
                <ImageViewer
                  sliderState={toggler ? 25 : 0}
                  url={data.thumbnail}
                />
              ) : (
                <NoThumbnail sliderState={toggler ? 25 : 0} />
              )}
            </div>
            {data?.name}
          </div>
        </TableCell>
        <TableCell className={classes.listItem}>
          <DateAndTimeViewer dateAndTime={data?.last_modified} />
        </TableCell>
        <TableCell className={classes.listItem}>
          <div
            style={{
              display: "flex",
              gap: "2px",
            }}
          >
            {data?.allowed_users.length
              ? data.allowed_users.map((user) => (
                  <UserAvatar
                    avatar={user?.avatar}
                    firstName={user?.first_name}
                    lastName={user?.last_name}
                  />
                ))
              : ""}
          </div>
        </TableCell>
        <TableCell className={classes.listItem}>
          <div
            style={{
              display: "flex",
            }}
          >
            <div className={classes.createdBy}>
              <UserAvatar
                avatar={data?.created_by?.avatar}
                firstName={data?.created_by?.first_name}
                lastName={data?.created_by?.last_name}
              />
              <span style={{ margin: "0 5px" }}>
                {data?.created_by?.first_name} {data?.created_by?.last_name}
              </span>
            </div>
          </div>
        </TableCell>
        <TableCell align="right" className={classes.listItem}>
          <Button
            style={{ minHeight: 0, minWidth: 0 }}
            onClick={(e) => {
              e.stopPropagation();
              onItemSelect();
            }}
          >
            <SequencerIcon />
          </Button>
          <Button
            style={{ minHeight: 0, minWidth: 0 }}
            onClick={(e) => {
              e.stopPropagation();
              handleEditModal(data);
            }}
          >
            <PencilSimpleLine style={{ height: "20px", width: "20px" }} />
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

export default PlaylistItems;
