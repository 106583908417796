import { DotsThree, Pencil, Trash } from "phosphor-react";
import React, { useState } from "react";
import StatusColor from "../../../../Utilities/StatusColor/StatusColor.jsx";
import StringTruncate from "../../../../Utilities/StringTruncate/StringTruncate.js";
import StatusTrackable from "../StatusTrakables/StatusTrakables.jsx";
import useStyles from "./StatusItem.style.js";
import { Divider, Button, Menu, MenuItem } from "@material-ui/core";
export default function StatusItem({
  trackableClass,
  selectedTrackable,
  status,
  handleEditStatusModal,
  handleDeleteStatusModal,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };
  return (
    <>
      <div className={classes.statusContainer}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className={classes.statusName}>
            <i className={`${status.icon} ${classes.statusIcon}`} />
            {StringTruncate(status.name, 15)}
          </div>
          <Button className={classes.button} onClick={(e) => handleOpenMenu(e)}>
            <DotsThree size={30} />
          </Button>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", gap: "7px" }}>
            <StatusTrackable
              trackable={status.status_class}
              trackableClass={trackableClass}
              selectedTrackable={selectedTrackable}
            />
          </div>
          <StatusColor color={status.color} />
        </div>
      </div>
      <div>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeMenu}
          elevation={1}
          MenuListProps={{ className: `${classes.menuPop}` }}
        >
          <MenuItem
            className={classes.modalButton}
            onClick={(e) => {
              handleEditStatusModal(e, status);
            }}
          >
            <Pencil size={19} style={{ marginRight: "5%" }} />
            Edit Status
          </MenuItem>
          <Divider />
          <MenuItem
            className={classes.modalButton}
            onClick={(e) => {
              handleDeleteStatusModal(e, status);
              closeMenu();
            }}
          >
            <Trash size={19} style={{ marginRight: "5%" }} />
            Delete Status
          </MenuItem>
        </Menu>
      </div>
    </>
  );
}
