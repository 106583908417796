import React from "react";
import useStyles from "./SequencerVersionBreadcrumb.style.js";
import { Link, Typography, Breadcrumbs } from "@material-ui/core";
import { CaretRight } from "phosphor-react";

const SequencerVersionBreadcrumb = ({
  pathnames,
  handleTrackableViewAndBreadcrumbPath,
}) => {
  const classes = useStyles();

  const capitalizeFirstChar = (str) => {
    return str[0].toUpperCase() + str.substring(1, str.length);
  };

  return (
    <div>
      <Breadcrumbs separator={<CaretRight className={classes.separatorIcon} />}>
        {pathnames?.map((path, index) => {
          const isLastPath = index === pathnames?.length - 1;
          return isLastPath ? (
            <Typography className={classes.endBreadCrumb}>
              {capitalizeFirstChar(path.name)}
            </Typography>
          ) : (
            <Link
              key={index}
              className={classes.breadCrumbLink}
              onClick={() => {
                handleTrackableViewAndBreadcrumbPath(index);
              }}
            >
              {capitalizeFirstChar(path.name)}
            </Link>
          );
        })}
      </Breadcrumbs>
    </div>
  );
};

export default SequencerVersionBreadcrumb;
