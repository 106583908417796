import React from "react";

import Modal from "@material-ui/core/Modal";
import * as Icon from "react-feather";

export default function ImageViewer(props) {
  const [imageModal, setImageModal] = React.useState(false);
  return (
    <div
      style={{
        width:
          props.sliderState !== undefined
            ? `${props.sliderState + 45}px`
            : "100%",
        height:
          props.sliderState !== undefined
            ? `${props.sliderState + 45}px`
            : "100%",
        position: "relative",
        display: "inline-block",
        marginTop: "5px",
        ...props.style,
      }}
    >
      <img
        style={{ borderRadius: "10px", objectFit: "cover", width: "100%" }}
        height="40px"
        width="40px"
        src={props.url}
        alt="No Img"
        onContextMenu={(e) => e.preventDefault()}
        onClick={(e) => e.stopPropagation()}
      />

      <Icon.Maximize
        style={{
          maxWidth: "15px",
          maxHeight: "15px",
          position: "absolute",
          bottom: "2%",
          right: "2%",
          cursor: "pointer",
          zIndex: "1",
        }}
        onClick={(e) => {
          setImageModal(true);
          e.stopPropagation();
        }}
      />

      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={imageModal}
        onClose={(e) => {
          setImageModal(false);
          e.stopPropagation();
        }}
      >
        <img
          src={props.url}
          alt="no Img"
          height="100%"
          style={{ maxHeight: "400px" }}
          onContextMenu={(e) => e.preventDefault()}
        />
      </Modal>
    </div>
  );
}
