import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  ViewModalContainer: {
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    padding: "5px 10px 0px 10px",
    height: "100%",
    overflow: "hidden",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    width: "100%",
    padding: "0px",
    height: "100%",
  },
  taskViewerTools: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    gap: "10px",
    padding: "10px",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    marginTop: "3px",
  },
  duration: {
    color: "grey",
    fontSize: "14px",
  },
  taskName: {
    fontSize: "24px",
  },
  input: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "5px",
    padding: "2%",
  },
  icon: {
    cursor: "pointer",
  },
  commentInput: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "5px",
    padding: "2%",
  },
  descriptionContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "7px",
  },
  menuItem: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
  },
  menuPaper: {
    height: "160px",
    width: "310px",
  },
  sendButton: {
    backgroundColor: theme.palette.secondary.main,
  },
  attachmentLabel: {
    backgroundColor: theme.palette.secondary.light,
    padding: "3%",
    borderRadius: "5px",
    width: "48%",
    display: "flex",
    alignItems: "center",
    height: "100%",
    cursor: "pointer",
  },
  file: {
    backgroundColor: theme.palette.secondary.light,
    padding: "3%",

    marginBottom: "2%",
    borderRadius: "5px",
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    width: "48%",
    border: "1px solid white",
  },
  label: {
    color: theme.palette.text.tertiary,
  },
  menuDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.secondary.light,
    padding: "0px",
    width: "140px",
    height: "80px",
  },
  editAndCloseButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "95%",
    fontSize: "14px",
  },
  statusAndPriority: {
    display: "flex",
    justifyContent: "space-between",
    gap: "18px",
  },
  labelAndContentGap: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  dividerLine: {
    display: "flex",
    alignItems: "center",
    transformOrigin: "top",
    transform: "scaleY(1)",
    transformStyle: "flat",
    transition: "all 1000ms ease",
  },
  line: {
    display: "block",
    height: "2px",
    backgroundColor: theme.palette.secondary.main,
    width: "100%",
  },
  upOrDownIcon: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "50%",
    height: "30px",
    width: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  taskDetails: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    transformOrigin: "top",
    transform: "scaleY(1)",
    transformStyle: "flat",
    transition: "all 1000ms ease",
    height: "auto",
    padding: "0px 10px",
  },
}));
export default useStyles;
