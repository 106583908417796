import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  floatingDiv: {
    position: "fixed",
    right: "0px",
    width: "450px",
    borderLeft: "2px solid #606479",
    backgroundColor: theme.palette.primary.main,
    overflowX: "hidden",
    overflowY: "scroll",
    zIndex: 100,
  },
}));
