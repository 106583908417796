import { makeStyles } from "@material-ui/core/styles";
import { renderToStaticMarkup } from "react-dom/server";
export const useStyles = makeStyles((theme) => ({
    dragScreen:{
        position:"absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        borderRadius: "10px",
        display:"flex",
        fontSize:"1.5rem",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
        border: `1px solid ${theme.palette.border.main}`,
        borderStyle: "dashed",
        // display: "block",
        color: "#FFF",
        zIndex:"999",
        backgroundColor: "rgb(37, 42, 56,0.4)",
      },
}))

