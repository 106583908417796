import React from "react";
import { Grid } from "@material-ui/core";
import useStyles from "./ProjectList.style";

export default function ProjectListItems({ project }) {
  const classes = useStyles();
  return (
    <Grid
      container
      item
      lg={12}
      alignItems="center"
      className={classes.projectListContainer}
    >
      <Grid item lg={2} md={2} sm={2} xs={3}>
        <img
          className={classes.projectImage}
          src={
            project.thumbnail
              ? project.thumbnail
              : "https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg"
          }
          alt=""
        />
      </Grid>
      <Grid item lg={10}>
        <span className={classes.projectName}>{project.name}</span>
      </Grid>
    </Grid>
  );
}
