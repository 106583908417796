import { Chip } from "@material-ui/core";
import React from "react";
import useStyles from "./AssetListStatusDrop.styles";

export default function AssetListCharactersTags(props) {
  const classes = useStyles();
  return (
    <>
      <Chip
        className={classes.listText}
        style={{ fontSize: "smaller", minWidth: "20px" }}
        size="small"
        label={props.content}
        variant="outlined"
      />
    </>
  );
}
