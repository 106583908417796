import React, { useEffect, useState } from "react";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { Column } from "./Column";
import GroupBoard from "./GroupBoard";
import useStyles from "./TaskPageKanbanView.style.js";
import { fdelete, fpatch } from "../../../../../API/callsAPI";
import { Button, Card, CardContent, Modal, Slide } from "@material-ui/core";
import { Plus } from "phosphor-react";
import TaskViewModal from "../../../TaskPageModals/TaskViewModal/TaskViewModal";
import TaskPageEditModal from "../../../TaskPageModals/TaskPageEditModal";
import DropHereIcon from "../../../../Utilities/Svg/DropHereIcon";
import CreateNewStatusModal from "../../../../Utilities/NewStatus/CreateNewStatusModal";
import DeleteModal from "../../../../Utilities/DeleteModal/DeleteModal.component";
import EditStatusModal from "../../../../Utilities/EditStatus/EditStatusModal";

const TaskPageKanbanViewOverview = (props) => {
  const {
    taskStatus,
    taskList,
    setUpdating,
    setSnackValue,
    updateRefreshCounter,
    handleNewTaskModalState,
    projectId,
    projectUsers,
    departments,
    trackableType,
  } = props;
  const classes = useStyles();
  const [groupedByStatus, setGroupedByStatus] = useState([]);
  const [dropTarget, setDropTarget] = useState(null);
  const [updateData, setUpdateData] = useState(false);
  const [isConfirmModalOpen, setConfirmModalStateOpen] = useState(false);
  const [targetTask, setTargetTask] = useState({});
  const [targetStatus, setTargetStatus] = useState({});
  const [openTaskViewModal, setTaskViewModal] = useState(false);
  const [viewModal, setViewModal] = useState(true);
  const [isTaskPageEditModalOpen, setIsTaskPageEditModalOpen] = useState(false);
  const [isAddStatusModalOpen, setIsAddStatusModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState({});
  const [mutualTaskList, setMutualTaskList] = useState([]); //task sharing same trackable
  const [isDeleteStatusModalOpen, setDeleteStatusModalOpen] = useState(false);
  const [isEditStatusModalOpen, setEditStatusModalOpen] = useState(false);
  const [selectedStatusToEdit, setSelectedStatusToEdit] = useState({});
  const [selectedStatusToDelete, setSelectedStatusToDelete] = useState({});
  const [editStatus, setEditStatus] = useState(false);

  function handleViewTaskModal(task, mutualTasks) {
    setTaskViewModal(true);
    setSelectedTask(task);
    if (mutualTasks) {
      setMutualTaskList(mutualTasks);
    }
  }

  const handleEditTaskModal = () => {
    setIsTaskPageEditModalOpen(!isTaskPageEditModalOpen);
  };

  const handleAddNewStatusModalState = () => {
    setIsAddStatusModalOpen(!isAddStatusModalOpen);
  };

  function handleDeleteStatusModal(e, status) {
    setSelectedStatusToDelete(status);
    setDeleteStatusModalOpen(!isDeleteStatusModalOpen);
  }

  function handleEditStatusModal(e, status) {
    setSelectedStatusToEdit(status);
    setEditStatus(true);
    setEditStatusModalOpen(!isEditStatusModalOpen);
  }

  const handleConfirmModalOpen = () => setConfirmModalStateOpen(true);
  const handleConfirmModalClose = () => setConfirmModalStateOpen(false);

  useEffect(() => {
    const taskByStatusGroup = props?.taskStatus?.map((status) => ({
      status,
      tasks: taskList.filter((task) => task.status?.id === status.id),
    }));
    const noStatusTask = taskList.filter((task) => task.status === null);
    const noStatusTaskList = {
      status: {
        id: "noStatus",
        name: "No Status Assigned",
        code: null,
        color: "255, 0, 0",
        project: 2,
      },
      tasks: noStatusTask,
    };
    if (taskByStatusGroup?.length > 0) {
      setGroupedByStatus([noStatusTaskList, ...taskByStatusGroup]);
    } else {
      setGroupedByStatus([noStatusTaskList]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskList]);

  const hoverOver = (draggedTaskId, hoverOverColumnId) => {
    const draggedTask = taskList.find((task) => task.id === draggedTaskId);
    if (draggedTask.status) {
      if (draggedTask.status.id !== hoverOverColumnId) {
        setDropTarget(hoverOverColumnId);
      } else {
        setDropTarget(null);
      }
    } else {
      if (hoverOverColumnId !== "noStatus") {
        setDropTarget(hoverOverColumnId);
      } else {
        setDropTarget(null);
      }
    }
  };

  const moveCard = async (draggedTaskId, destinationColumnId) => {
    setUpdateData({
      url: `trackables/task/${draggedTaskId}/`,
      data: {
        status: destinationColumnId !== "noStatus" ? destinationColumnId : "",
      },
    });
    const draggedTask = taskList.find((task) => task.id === draggedTaskId);
    const draggedStatus = taskStatus.find(
      (status) => status.id === destinationColumnId
    );
    setTargetTask(draggedTask);
    setTargetStatus(draggedStatus);

    if (draggedTask.status) {
      if (draggedTask.status.id !== destinationColumnId) {
        handleConfirmModalOpen();
      }
    } else {
      if (destinationColumnId !== "noStatus") {
        handleConfirmModalOpen();
      }
    }
    setDropTarget(null);
  };

  const updateStatus = async () => {
    setUpdating(true);
    try {
      const res = await fpatch(updateData);
      handleConfirmModalClose();
      setUpdating(false);
      setSnackValue({
        isOpen: true,
        message: `SuccessFully changed status ${res.data.name}`,
        isError: false,
      });
      updateRefreshCounter();
    } catch (error) {
      handleConfirmModalClose();
      setUpdating(false);
      setSnackValue({
        isOpen: true,
        message: `error in changing status`,
        isError: true,
      });
    }
  };

  const handleTaskUpdate = async (taskId, updatedTaskForm) => {
    let updatedTask = {
      url: `trackables/task/${taskId}/`,
      data: updatedTaskForm,
    };
    try {
      const updateTaskRes = await fpatch(updatedTask);
      setIsTaskPageEditModalOpen(false);
      setViewModal(false);
      setTaskViewModal(false);
      setSnackValue({
        isOpen: true,
        message: `SuccessFully edited task : ${updateTaskRes.data.name}`,
        isError: false,
      });
      updateRefreshCounter();
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  const deleteStatus = async () => {
    try {
      await fdelete({
        url: `trackables/status/${selectedStatusToDelete?.id}`,
      });
      setSnackValue({
        isOpen: true,
        message: `SuccessFully deleted comment`,
        isError: false,
      });
      updateRefreshCounter();
      setDeleteStatusModalOpen(false);
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  return (
    <>
      <div
        className={classes.board}
        style={{
          height:
            trackableType === "task"
              ? `calc(100vh - 230px)`
              : `calc(100vh - 257px)`,
        }}
      >
        {groupedByStatus?.map((singleGroup) => {
          return (
            <Column
              key={singleGroup.status.id}
              column={singleGroup.status}
              moveCard={moveCard}
              hoverOver={hoverOver}
              handleEditStatusModal={handleEditStatusModal}
              handleDeleteStatusModal={handleDeleteStatusModal}
              dropTarget={dropTarget}
            >
              {dropTarget === singleGroup.status.id ? (
                <div className={classes.dropHereImageContainer}>
                  <DropHereIcon color={singleGroup.status.color} />
                  <h2>{singleGroup.status.name}</h2>
                </div>
              ) : (
                <GroupBoard
                  singleGroup={singleGroup}
                  moveCard={moveCard}
                  handleNewTaskModalState={handleNewTaskModalState}
                  handleViewTaskModal={handleViewTaskModal}
                  departments={departments}
                />
              )}
            </Column>
          );
        })}
        <div
          className={classes.addNewStatus}
          onClick={() => {
            handleAddNewStatusModalState();
          }}
        >
          <div
            style={{
              textAlign: "center",
            }}
          >
            <Plus size={35} />
            <h3>Add New Status</h3>
          </div>
        </div>
      </div>
      <Modal
        open={isConfirmModalOpen}
        onClose={handleConfirmModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.kanbanPageModal}
      >
        <div className={classes.modalRoot}>
          <Card className={classes.card}>
            <CardContent className={classes.CardContent}>
              <p className={classes.headText}>Confirm Update</p>
              <p className={classes.text}>
                Do you want to update status of "{targetTask?.name}" "
                {targetTask?.status?.name}" to "{targetStatus?.name}"
              </p>

              <div className={classes.buttonDiv}>
                <Button
                  className={classes.yesButton}
                  type="submit"
                  size="small"
                  disableElevation
                  onClick={() => {
                    updateStatus();
                  }}
                >
                  Yes, Update it
                </Button>
                <Button
                  className={classes.noButton}
                  type="submit"
                  size="small"
                  disableElevation
                  onClick={() => {
                    setSnackValue({
                      isOpen: true,
                      message: `canceled`,
                      isError: true,
                    });
                    handleConfirmModalClose();
                  }}
                >
                  No, keep it
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      </Modal>
      <div>
        <Modal
          className={classes.kanbanPageModal}
          open={isAddStatusModalOpen}
          onClose={() => setIsAddStatusModalOpen(false)}
        >
          <CreateNewStatusModal
            projectId={projectId}
            closeCreateNewStatusModal={() => handleAddNewStatusModalState()}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.kanbanPageModal}
          open={isDeleteStatusModalOpen}
          onClose={() => setDeleteStatusModalOpen(false)}
        >
          <DeleteModal
            type="Status"
            name={selectedStatusToDelete?.name}
            handleCloseDeleteModal={() => {
              setDeleteStatusModalOpen(false);
            }}
            handleDelete={deleteStatus}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.kanbanPageModal}
          open={isEditStatusModalOpen}
          onClose={() => setEditStatusModalOpen(false)}
        >
          <EditStatusModal
            closeCreateNewStatusModal={() => setEditStatusModalOpen(false)}
            projectId={projectId}
            edit={editStatus}
            editStatus={selectedStatusToEdit}
          />
        </Modal>
      </div>
      <Slide direction="left" in={openTaskViewModal}>
        <div
          style={{
            display: openTaskViewModal ? "block" : "none",
            top: trackableType === "task" ? "155px" : "185px",
            height:
              trackableType === "task"
                ? `calc(100vh - 155px)`
                : `calc(100vh - 185px)`,
          }}
          className={classes.floatingDiv}
        >
          <div
            style={{
              height: "100%",
              padding: "5px",
            }}
          >
            {viewModal ? (
              <TaskViewModal
                closeModal={() => setTaskViewModal(false)}
                task={selectedTask}
                projectId={projectId}
                taskStatus={taskStatus}
                handleEditTaskModal={handleEditTaskModal}
                handleTaskUpdate={handleTaskUpdate}
                projectUsers={projectUsers}
                switchToEditModal={() => setViewModal(false)}
                handleTaskDelete={props.handleTaskDelete}
                viewType="Kanban"
              />
            ) : (
              <TaskPageEditModal
                SwitchToViewModal={() => setViewModal(true)}
                selectedTask={selectedTask}
                mutualTaskList={mutualTaskList}
                statusList={taskStatus}
                handleEditTaskModal={() => setTaskViewModal(false)}
                handleTaskUpdate={handleTaskUpdate}
                projectUsers={projectUsers}
              />
            )}
          </div>
        </div>
      </Slide>
    </>
  );
};

export default DragDropContext(HTML5Backend)(TaskPageKanbanViewOverview);
