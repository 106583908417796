import {
  Button,
  Card,
  CardContent,
  FormControl,
  MenuItem,
  Select,
} from "@material-ui/core";
import { X } from "phosphor-react";
import React from "react";
import useStyles from "./VersionFilterModal.style";

const VersionFilterModal = ({
  closeModal,
  status,
  taskList,
  filterValues,
  setFilterValues,
  updateList,
  setFilterButtonActive,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div class={classes.popoverArrow}></div>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2 className={classes.title}>Filters</h2>
            <div className={classes.clear} onClick={(e) => closeModal()}>
              <X size="30px" />
            </div>
          </div>
          <div className={classes.statusSelect}>
            <div className={classes.textGray}>Status :</div>
            <FormControl className={classes.formControl}>
              <Select
                placeholder="Select Status"
                style={{ padding: "3px" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                disableUnderline
                className={classes.select}
                value={filterValues.status}
                onChange={(e) =>
                  setFilterValues({
                    ...filterValues,
                    status: e.target.value,
                  })
                }
                name="status"
              >
                <MenuItem value="">None</MenuItem>
                {status &&
                  status.map((eachStatus) => (
                    <MenuItem value={eachStatus.id}>{eachStatus.name}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className={classes.taskSelect}>
            <div className={classes.textGray}>Task :</div>
            <FormControl className={classes.formControl}>
              <Select
                style={{ padding: "3px" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                disableUnderline
                className={classes.select}
                value={filterValues.task}
                onChange={(e) =>
                  setFilterValues({
                    ...filterValues,
                    task: e.target.value,
                  })
                }
                name="task"
              >
                <MenuItem value="">None</MenuItem>
                {taskList &&
                  taskList.map((eachTask) => (
                    <MenuItem value={eachTask.id}>{eachTask.name}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        </CardContent>
        <div className={classes.cardAction}>
          <Button
            size="small"
            variant="outlined"
            className={classes.doneButton}
            onClick={() => {
              if (filterValues?.task || filterValues?.status) {
                setFilterButtonActive(true);
              } else {
                setFilterButtonActive(false);
              }
              updateList();
              closeModal();
            }}
          >
            Done
          </Button>
          <Button
            size="small"
            style={{
              height: "40px",
              marginTop: "0.2rem",
            }}
            onClick={() => {
              // setFilterButtonActive(false);
              setFilterValues({
                status: "",
                task: "",
              });
            }}
          >
            <X
              size="20"
              style={{ marginTop: "-0.2rem", paddingRight: "3px" }}
            />
            Clear All
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default VersionFilterModal;
