import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Button,
  TextField,
  CircularProgress,
  Modal,
} from "@material-ui/core";
import { Asterisk } from "phosphor-react";
import useStyles from "./NewDepartment.styles";
import { fpost, fget, fpatch } from "../../../API/callsAPI";
import ModalTitleAndButtons from "../../Utilities/ModalTitleAndButtons/ModalTitleAndButtons.component";
import UserViewerForManageUsers from "../../Utilities/ManageUsers/UsersViewerForManageUsers/UserViewerForManageUsers.component";
import SelectUserModal from "../../Utilities/ManageUsers/SelectUsersModal/SelectUserModal.component";
import { useFormik } from "formik";
import * as yup from "yup";
import HelperText from "../../Utilities/HelperText/HelperText";

function NewDepartment({
  type,
  setSnackValue,
  projectId,
  updateRefreshCounter,
  closeNewDeptModal,
  selectedDepartment,
  handleDeleteDepartmentInfo,
  usersList,
}) {
  const classes = useStyles();

  const [loader, setLoader] = React.useState(false);
  const [projectUsers, setProjectUsers] = useState([]);
  const [projectAdmin, setProjectAdmin] = useState([]);
  const [isSelectAdminModalOpen, setSelectAdminModalOpen] = useState(false);
  const [isSelectUserModalOpen, setSelectUserModalOpen] = useState(false);

  useEffect(() => {
    const admins = usersList?.filter((el) =>
      selectedDepartment?.admin_users?.includes(el.value)
    );
    const users = usersList?.filter((el) =>
      selectedDepartment?.users?.includes(el.value)
    );
    setProjectAdmin(admins);
    setProjectUsers(users);
  }, [selectedDepartment, usersList]);

  const handleSelectAdmin = (admins) => {
    setProjectAdmin(admins);
  };

  const handleSelectUser = (users) => {
    setProjectUsers(users);
  };

  const handleSelectAdminModalState = () => {
    setSelectAdminModalOpen(!isSelectAdminModalOpen);
  };

  const handleSelectUserModalState = () => {
    setSelectUserModalOpen(!isSelectUserModalOpen);
  };

  //Validators
  const NewDepartmentValidationSchema = yup.object({
    name: yup
      .string("String required")
      .label("projectName")
      .min(1, "Too Short!")
      .max(50, "Maximum Character Limit: 50")
      .required("This is a required field"),

    description: yup
      .string("String required")
      .label("description")
      .max(100, "Maximum Character Limit: 100")
      .required("This is a required field"),
  });

  const formik = useFormik({
    initialValues: {
      name: selectedDepartment?.name ? selectedDepartment?.name : "",
      description: selectedDepartment?.description
        ? selectedDepartment?.description
        : "",
    },
    validationSchema: NewDepartmentValidationSchema,
    onSubmit: async (values) => {
      setLoader(true);

      const deptData = {
        name: values.name,
        description: values.description,
        project: projectId,
        users: projectUsers ? projectUsers.map((user) => user.value) : [],
        admin_users: projectAdmin ? projectAdmin.map((user) => user.value) : [],
      };

      try {
        if (type === "New") {
          const newDepartment = {
            url: "department/",
            data: deptData,
          };
          const newDepartmentRes = await fpost(newDepartment);
          if (newDepartmentRes.status === 200 || 201) {
            setLoader(false);
            setSnackValue({
              isOpen: true,
              message: `SuccessFully Created "${newDepartmentRes.data.name}"`,
              isError: false,
            });
          }
          updateRefreshCounter();
          closeNewDeptModal();
        } else {
          const editDepartment = {
            url: `department/${selectedDepartment?.id}/`,
            data: deptData,
          };
          const editDepartmentRes = await fpatch(editDepartment);
          if (editDepartmentRes.status === 200 || 201) {
            setLoader(false);
            setSnackValue({
              isOpen: true,
              message: `SuccessFully Edited "${editDepartmentRes.data.name}"`,
              isError: false,
            });
          }
          updateRefreshCounter();
          closeNewDeptModal();
        }
      } catch (error) {
        setLoader(false);
        setSnackValue({
          isOpen: true,
          message: `${error}`,
          isError: true,
        });
      }
    },
  });

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <form enctype="multipart/form-data" onSubmit={formik.handleSubmit}>
            <ModalTitleAndButtons
              name="Department"
              type={type}
              closeModal={() => closeNewDeptModal()}
              openDeleteModal={() =>
                handleDeleteDepartmentInfo(selectedDepartment)
              }
            />
            <div className={classes.cardContentDiv1}>
              <span className={classes.inputLabel}>Department Name</span>
              <Asterisk size={12} color="#FF0000" weight="fill" />
              <div className={classes.departmentName}>
                <TextField
                  type="text"
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  className={classes.cardContentInput1}
                  InputProps={{ disableUnderline: true }}
                  placeholder="Enter name"
                  fullWidth
                  error={Boolean(formik.errors.name)}
                />
              </div>
              {formik.errors.name && formik.touched.name ? (
                <HelperText text={formik.errors.name} />
              ) : null}
            </div>

            <div className={classes.label}>
              <label>Select Admin</label>
            </div>
            <UserViewerForManageUsers
              currentUsers={projectAdmin}
              handleSelectUserModalChange={handleSelectAdminModalState}
              handleSelectUsers={handleSelectAdmin}
            />

            <div className={classes.label}>
              <label>Select Users</label>
            </div>
            <UserViewerForManageUsers
              currentUsers={projectUsers}
              handleSelectUserModalChange={handleSelectUserModalState}
              handleSelectUsers={handleSelectUser}
            />

            <div className={classes.cardContentDiv1}>
              <span className={classes.inputLabel}>Description</span>
              <Asterisk size={12} color="#FF0000" weight="fill" />
              <div className={classes.descriptionInput}>
                <TextField
                  type="text"
                  id="description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  style={{ padding: "8px" }}
                  multiline
                  className={classes.cardContentInput1}
                  disableUnderline="false"
                  placeholder="Enter description"
                  fullWidth
                  InputProps={{ disableUnderline: true }}
                  error={Boolean(formik.errors.name)}
                />
              </div>
              {formik.errors.description && formik.touched.description ? (
                <HelperText text={formik.errors.description} />
              ) : null}
            </div>

            <br />

            <CardActions className={classes.cardAction}>
              <Button
                className={classes.done}
                type="submit"
                value="Submit"
                disabled={loader ? true : false}
                size="small"
                disableElevation
              >
                {loader ? (
                  <CircularProgress style={{ width: "25px", height: "25px" }} />
                ) : (
                  <b>
                    {type === "New" ? "Create New Department" : "Done Editing"}
                  </b>
                )}
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
      <div>
        {/* to select admins */}
        <Modal
          className={classes.modal}
          open={isSelectAdminModalOpen}
          onClose={() => setSelectAdminModalOpen(!isSelectAdminModalOpen)}
        >
          <SelectUserModal
            closeModal={() => setSelectAdminModalOpen(!isSelectAdminModalOpen)}
            assignedUser={projectAdmin}
            usersList={usersList}
            handleSelectUsers={handleSelectAdmin}
          />
        </Modal>
        {/* to select users */}
        <Modal
          className={classes.modal}
          open={isSelectUserModalOpen}
          onClose={() => setSelectUserModalOpen(!isSelectUserModalOpen)}
        >
          <SelectUserModal
            closeModal={() => setSelectUserModalOpen(!isSelectUserModalOpen)}
            assignedUser={projectUsers}
            usersList={usersList}
            handleSelectUsers={handleSelectUser}
          />
        </Modal>
      </div>
    </div>
  );
}

export default NewDepartment;
