import { React } from "react";
import { Asterisk } from "phosphor-react";

export default function HelperText({ text }) {
  return (
    <div
      style={{
        textAlign: "right",
        color: "#F9F9F9",
        fontSize: "11px",
        fontFamily: "Poppins",
      }}
    >
      <Asterisk size={12} color="#FF0000" weight="fill" />
      {text}
    </div>
  );
}
