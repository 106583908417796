import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  commentBox: {
    width: "100%",
    minHeight: "30px",
    height: "25%",
    // border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    padding: "12px",
    background: theme.palette.primary.main,
  },

  sendBtn: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "10px",
    marginLeft: "10px",
    minWidth: "35px",
    height: "35px",
  },

  icon: {
    // margin: "2px",
    color: theme.palette.text.secondary,
    width: "15px",
    borderRadius: "2px",
  },
  replyDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid gray",
    padding: "6px",
  },
  replyingToText: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  text: {
    color: theme.palette.primary.light,
  },
  icon2: {
    color: theme.palette.text.primary,
    cursor: "pointer",
    "&:hover ": {
      color: theme.palette.primary.light,
    },
  },
  commentDiv: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "10px",
    padding: "0px 5px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
}));

export default useStyles;
