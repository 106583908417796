import { Button, CircularProgress } from "@material-ui/core";
import { CaretLeft, CaretRight } from "phosphor-react";
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { fget } from "../../../../../API/callsAPI";
import NoResultMessage from "../../../../Utilities/NoResultFound/NoResultMessage";
import useStyles from "./ProjectHealthWidgets.style";

const DepartmentGraph = ({ projectId }) => {
  const classes = useStyles();
  const [seriesList, setSeriesList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const getHealth = async () => {
      setIsLoading(true);
      try {
        const res = await fget({
          url: `project/${projectId}/task-widget/?date=${selectedDate
            ?.toISOString()
            .slice(0, 10)}`,
        });
        if (res?.data?.task_completion) {
          const seriesData = res?.data?.task_completion?.map(
            (singleDepartment) => {
              return {
                name: singleDepartment?.department?.name,
                data: Object.values(singleDepartment?.tasks),
                dates: Object.keys(singleDepartment?.tasks),
              };
            }
          );
          setSeriesList(seriesData);
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    getHealth();
  }, [projectId, selectedDate]);

  const options = {
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    //to hide dept name and color suggestions
    legend: {
      show: false, // Hide legend
    },
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    xaxis: {
      type: "date",
      categories: seriesList?.dates,
      tooltip: false,
      min: 1,
      max: 8,
      labels: {
        style: {
          colors: "#606479",
        },
      },
      title: {
        text: new Date().toLocaleString("default", { month: "long" }),
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "#F9F9F9",
          fontSize: "14px",
          fontFamily: "Poppins sans-serif",
          fontWeight: 400,
          cssClass: "apexcharts-xaxis-title",
        },
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
        color: "#78909C",
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: "#606479",
        },
      },
      title: {
        text: "Number of task",
        rotate: -90,
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "#F9F9F9",
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-title",
        },
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const seriesName = w.globals.seriesNames[seriesIndex];
        const value = series[seriesIndex][dataPointIndex];
        const date = seriesList[seriesIndex]?.dates[dataPointIndex];
        return `<div class="custom-tooltip-of-graph">
          <div class='department-name'>${seriesName}</div>
          <div class='department-date'>${date}</div>
          <div class='department-value'>${value}</div>
        </div>`;
      },
      shared: false,
      x: {
        format: "dd/MM/yy",
      },
      theme: false,
      style: {
        fontSize: "12px",
        fontFamily: undefined,
        color: "red",
        backgroundColor: "red",
      },
    },
  };

  return (
    <div className={classes.performanceRoot}>
      <div className={classes.performanceTools}>
        <div style={{ fontWeight: 500, fontSize: "18px" }}>
          Performance Graph
        </div>
        <div className={classes.dropDownAndButtons}>
          {/* <div className={classes.dropdownContainer}>
            <Select
              style={{ width: "100%" }}
              disableUnderline
              className={classes.select}
              value={selectedWeek}
              onChange={(e) => setSelectedWeek(e?.target?.value)}
            >
              {[
                { title: "This week", value: "this week" },
                { title: "Past week", value: "Past week" },
              ].map((option) => (
                <MenuItem value={option?.value}>{option?.title}</MenuItem>
              ))}
            </Select>
          </div> */}
          <Button
            className={classes.leftRightButton}
            onClick={() =>
              setSelectedDate(
                new Date(
                  new Date(
                    new Date(selectedDate).setDate(
                      new Date(selectedDate).getDate() - 7
                    )
                  )
                )
              )
            }
          >
            <CaretLeft size={20} />
          </Button>
          <Button
            className={classes.leftRightButton}
            disabled={
              selectedDate?.toISOString().slice(0, 10) ===
              new Date()?.toISOString().slice(0, 10)
            }
            onClick={() =>
              setSelectedDate(
                new Date(
                  new Date(
                    new Date(selectedDate).setDate(
                      new Date(selectedDate).getDate() + 7
                    )
                  )
                )
              )
            }
          >
            <CaretRight size={20} />
          </Button>
        </div>
      </div>
      <div style={{ height: "90%" }}>
        {isLoading ? (
          <div className={classes.flexCenter} style={{ height: "100%" }}>
            <CircularProgress
              type="Oval"
              color="secondary"
              height={50}
              width={50}
            />
          </div>
        ) : (
          <>
            {seriesList?.length ? (
              <ReactApexChart
                options={options}
                series={seriesList}
                type="line"
                height="95%"
              />
            ) : (
              <div style={{ height: "100%" }} className={classes.flexCenter}>
                <NoResultMessage name="Info" iconSize={30} fontSize="18px" />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DepartmentGraph;
