import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text,
    marginTop: "6px",
    padding: "0%",
    fontSize: "1rem",
    fontWeight: "600",
  },
  container: {
    width: "425px",
    borderRadius: "10px",
    backgroundColor: theme.palette.secondary.light,
    padding: "12px 18px 18px 18px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  actionContainer: {
    display: "flex",
    alignItem: "center",
    justifyContent: "space-between",
    marginTop: "18px",
  },
  submitBtn: {
    borderRadius: "100px",
    backgroundColor: theme.palette.secondary.main,
    padding: "4px 8px",
  },
  clearBtn: {
    borderRadius: "100px",
    border: `1px solid ${theme.palette.text.tertiary}`,
    padding: "4px 10px",
  },
  textField: {
    backgroundColor: theme.palette.primary.main,
    padding: "4px 10px",
    borderRadius: "100px",
    width: "235px",
  },
}));
export default useStyles;
