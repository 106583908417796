import React from "react";
import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
} from "@material-ui/core";
import useStyles from "./SelectVersionModal.styles";
import * as Icon from "react-feather";
import NoThumbnail from "../../../Utilities/NoThumbnail.component";

function SelectVersion({
  versions,
  setPlayerTwo,
  setPlayerOne,
  player,
  closeModal,
  selectedVersion,
  setSelectedVersion,
}) {
  const classes = useStyles();
  const changeVersion = (eachVersion) => {
    if (player == "1") {
      setPlayerOne((prevPlayer) => ({
        ...prevPlayer,
        version: eachVersion,
      }));
    } else if (player == "2") {
      setPlayerTwo((prevPlayer) => ({
        ...prevPlayer,
        version: eachVersion,
      }));
    }
    setSelectedVersion(eachVersion);
  };

  return (
    <div>
      <Card className={classes.card}>
        <CardContent className={classes.CardContent}>
          <div className={classes.titleContainer}>
            <p className={classes.title}>Choose Version</p>
            <Icon.X
              size="30px"
              onClick={() => closeModal()}
              className={classes.crossIcon}
            />
          </div>
          <List className={classes.versionList}>
            {versions?.map((eachVersion) => {
              return (
                <ListItem
                  className={
                    selectedVersion == eachVersion
                      ? classes.selectedItem
                      : classes.listItem
                  }
                  onClick={() => changeVersion(eachVersion)}
                >
                  <ListItemIcon>
                    {eachVersion.thumbnail ? (
                      <img
                        style={{
                          borderRadius: "10px",
                          objectFit: "cover",
                        }}
                        src={eachVersion.thumbnail}
                        height="104px"
                        width="185px"
                        alt="No Img"
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    ) : (
                      <NoThumbnail
                        style={{
                          height: "104px",
                          width: "185px",
                          objectFit: "cover",
                        }}
                      />
                    )}
                  </ListItemIcon>
                  <div className={classes.listText}>
                    <div className={classes.listTextBox1}>
                      <p>{eachVersion.name}</p>
                      <p className={classes.fadedText}>
                        Updated By {eachVersion.updated_by?.first_name}{" "}
                        {eachVersion.updated_by?.last_name}
                      </p>
                    </div>
                    <div className={classes.listTextBox2}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <p>{eachVersion.status?.name}</p>
                        <div
                          style={{
                            backgroundColor: `rgb(${eachVersion.status?.color})`,
                            height: "16px",
                            width: "16px",
                            borderRadius: "50%",
                            marginLeft: "5px",
                          }}
                        ></div>
                      </div>
                      <div
                        className={classes.fadedText}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {new Date(eachVersion.updated_at).toLocaleDateString(
                          "en-US",
                          {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                          }
                        )}{" "}
                        {new Date(eachVersion.updated_at).toLocaleTimeString(
                          [],
                          { hour: "2-digit", minute: "2-digit" }
                        )}
                      </div>
                    </div>
                  </div>
                </ListItem>
              );
            })}
          </List>
        </CardContent>
      </Card>
    </div>
  );
}

export default SelectVersion;
