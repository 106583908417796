import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "60%",
    "& .MuiFormHelperText-root": {
      color: "#ffcc00",
    },
  },
  card: {
    borderRadius: "10px",
    color: theme.palette.text.primary,
    height: "500px",
    border: `2px solid ${theme.palette.secondary.main}`,
  },
  cardContent: {
    backgroundColor: theme.palette.primary.main,
    padding: "0px",
    position: "relative",
    height: "100%",
  },
  titleAndClose: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 20px 0px 20px",
  },
  reportIssueTitle: {
    fontSize: "18px",
    fontWeight: 500,
    border: `2px solid ${theme.palette.secondary.main}`,
    borderBottom: `0px !important`,
    padding: "10px",
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
    height: "2px",
  },
  tableContainer: {
    width: "100%",
  },
  table: {
    backgroundColor: theme.palette.primary.main,
    width: "100%",
  },
  tableHeader: {
    backgroundColor: theme.palette.primary.main,
    display: "table",
    width: "100%",
    tableLayout: "fixed",
  },
  tableRow: {
    display: "table",
    width: "100%",
    tableLayout: "fixed",
  },
  headTable: {
    padding: "8px 0px !important",
    color: theme.palette.text.tertiary,
  },
  tableBody: {
    width: "100%",
    height: `380px`,
    overflowY: "auto",
    display: "block",
    tableLayout: "fixed",
    paddingRight: "10px",
  },
  bodyRow: {
    display: "table",
    width: "100%",
    tableLayout: "fixed",
  },
  issueName: {
    padding: "8px 0px",
  },
  textGray: {
    color: theme.palette.text.tertiary,
  },
  issueDate: {
    padding: "8px 0px",
    color: theme.palette.text.tertiary,
    fontSize: "12px",
  },
  statusCell: {
    padding: "8px 0px",
  },
  statusContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  statusDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  statusName: {
    width: "80%",
    overflow: "hidden",
    textAlign: "start",
  },
  statusColor: {
    borderRadius: "50%",
    display: "inline-block",
    marginLeft: "10px",
    height: "15px",
    width: "15px",
    border: `2px solid ${theme.palette.border.main}`,
  },

  bottomCardContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  card2: {
    width: "80%",
    borderRadius: "10px",
    color: theme.palette.text.primary,
    marginTop: "50px",
    border: `2px solid ${theme.palette.secondary.main}`,
  },
  cardContent2: {
    backgroundColor: theme.palette.primary.main,
    padding: "15px !important",
    position: "relative",
  },
  line: {
    height: "400px",
    width: "2px",
    backgroundColor: theme.palette.text.tertiary,
    marginLeft: "10px",
  },

  tanksText: {
    textAlign: "center",
  },
  colorChange: {
    color: theme.palette.secondary.main,
  },
  avatarAndDate: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
  },
  avatar: {
    height: "25px",
    width: "25px",
    textAlign: "center",
    borderRadius: "50%",
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.primary,
    fontWeight: "400 !important",
    fontSize: "15px",
  },
  lineAndTexts: {
    display: "flex",
    alignItems: "center",
  },
  line2: {
    height: "110px",
    width: "2px",
    backgroundColor: theme.palette.text.tertiary,
    marginLeft: "10px",
    display: "block",
  },
  attachmentAndDescription: {
    marginLeft: "30px",
  },
  attachment: {
    fontSize: "20px",
    fontWeight: 600,
    marginBottom: "15px",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  issueId: {
    fontSize: "16px",
    color: theme.palette.text.primary,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageViewer: {
    backgroundColor: theme.palette.primary.light,
    padding: "5px",
    borderRadius: "10px",
  },
  image: {
    height: "70vh",
    width: "70vw",
  },
}));

export default useStyles;
