import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    // borderCollapse: "",
  },

  AssetImageDiv: {
    background: "#C4C4C4",
    borderRadius: "10px",
    marginRight: "20px",
  },
  AssetImage: {
    borderRadius: "8px",
  },
  DepartmentsDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  listText: {
    color: theme.palette.text.primary,
    padding: "1px",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
    width: "17.5%",
  },
  listTextTwo: {
    color: theme.palette.text.primary,
    padding: "1px",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
    width: "15%",
    overflow: "hidden",
    borderCollapse: "collapse",
  },
  assetsListModals: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default useStyles;
