import React from "react";
import { X, AlertCircle } from "react-feather";
import { Button } from "@material-ui/core";
//styles
import useStyles from "./PlayerErrorCard.styles";

export default function PlayerErrorCard({ errorMessage, reLoad, file }) {
  const classes = useStyles();
  return (
    <div className={classes.errorMessageBox}>
      <div
        style={{
          position: "absolute",
          top: "35%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className={classes.errorContainer}>
          <AlertCircle className={classes.alertCircle} />
          <p className={classes.errorMessage}>{errorMessage.message}</p>
        </div>
        <div>
          <Button
            style={{
              margin: "15px",
              borderRadius: "10px",
              backgroundColor: "#6C65D9",
              padding: "10px",
            }}
            onClick={() => (window.location.href = file)}
          >
            Download File
          </Button>
        </div>
        {errorMessage.code !== 4 ? (
          <Button className={classes.reloadbtn} onClick={reLoad}>
            Reload
          </Button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
