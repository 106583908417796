import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  select: {
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      borderRadius: "5px",
    },
  },
  dropDown: {
    backgroundColor: theme.palette.secondary.light,
  },
  input: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "2%",
    width: "100%",
    paddingLeft: "9%",
  },
}));

export default useStyles;
