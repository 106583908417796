import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
    position: "absolute",
    width: `calc(100vw - 150px)`,
    bottom: "8px",
  },
  showingNumber: {
    color: theme.palette.text.tertiary,
  },
  gray: {
    color: theme.palette.text.tertiary,
  },
  buttonsAndPageNumber: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  pageListContainer: {
    backgroundColor: theme.palette.secondary.light,
    position: "absolute",
    bottom: "50px",
    right: "200px",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    padding: "5px 10px",
    borderRadius: "10px",
  },
  triangleContainer: {
    backgroundColor: "transparent",
    position: "absolute",
    bottom: "30px",
    right: "190px",
    height: "20px",
    width: "110px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  triangle: {
    backgroundColor: theme.palette.secondary.light,
    height: "20px",
    width: "30px",
    clipPath: "polygon(100% 0, 0 0, 49% 100%)",
  },
  singlePageNumber: {
    width: "30px",
    height: "30px",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    color: theme.palette.text.tertiary,
    "&:hover": {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  selectedPageNumber: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.primary,
    width: "30px",
    height: "30px",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  nextAndPrev: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: "10px",
  },
  previous: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    "&:hover": {
      backgroundColor: theme.palette.text.tertiary,
    },
  },
  next: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "0px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.text.tertiary,
    },
  },
}));

export default useStyles;
