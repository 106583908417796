import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    padding: "1%",
    width: "20%",
    [theme.breakpoints.down("md")]: {
      width: "33%",
      padding: "1%",
      // height: "11rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "40%",
      padding: "1%",
      // height: "11rem",
    },
  },
  toolContainer: {
    display: "flex",
    direction: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: {
    cursor: "pointer",
  },
  searchDiv: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "10px",
    width: "80%",
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.primary,
  },
  searchInput: {
    padding: "3px 9px",
  },
  userList: {
    height: "250px",
    overflowY: "scroll",
    marginTop: "4%",
  },
  user: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "2%",
    paddingTop: "2%",
    cursor: "pointer",
  },
  displayFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
  },
  userImageContainer: {
    height: "35px",
    width: "35px",
    borderRadius: "50%",
    marginRight: "8px",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.secondary,
    borderRadius: "10px",
    fontSze: "14px",
    width: "90px",
    "& hover ": {
      color: theme.palette.text.primary,
    },
  },
  check: {
    color: "lime",
    fontWeight: "600",
    marginRight: "10px",
  },
  userImage: {
    height: "100%",
    width: "100%",
    borderRadius: "50%",
    objectFit: "contain",
  },
  cardAction: {
    display: "flex",
    justifyContent: "center",
    "& :first-child": {
      backgroundColor: theme.palette.secondary.main,
      height: "40px",
      width: "100px",
      textTransform: "none",
      padding: "4px ",
      borderRadius: "10px",
      color: theme.palette.text.secondary,
    },
  },
}));

export default useStyles;
