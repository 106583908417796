import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
  },
  tableContainer: {
    width: "100%",
  },
  table: {
    borderCollapse: "collapse",
  },
  tableHeader: {
    backgroundColor: theme.palette.primary.main,
    display: "table",
    width: "100%",
    tableLayout: "fixed",
  },
  tableRow: {
    borderCollapse: "collapse",
  },
  tableBody: {
    display: "block",
    overflowY: "scroll",
    height: `calc(95vh - 250px)`,
    [theme.breakpoints.down(823)]: {
      height: `calc(100vh - 305px)`,
    },
  },
  tableCell: {
    backgroundColor: theme.palette.primary.main,
    padding: "1.3% 0% !important",
    color: theme.palette.text.tertiary,
    zIndex: "50",
    borderCollapse: "collapse",
    width: "17%",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
  },
  playListPageModals: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    padding: "15px",
  },
}));

export default useStyles;
