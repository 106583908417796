import React, { useState } from "react";
import useStyles from "./SettingOverview.style.js";
import { Button } from "@material-ui/core";
import SettingOverviewTools from "../SettingsOverviewTools/SettingOverviewTools";
import { FileArrowDown } from "phosphor-react";
import { downloadToFile } from "../../../Utils/scripts/envFileGenerator.js";
import ErrorOrSuccessMessage from "../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component.jsx";
import StatusSettingOverview from "../StatusSettingOverview/StatusSettingOverview.jsx";
import TaskTemplateSettingOverview from "../TaskTemplateSettings/TaskTemplateSettingOverview/TaskTemplateSettingOverview.jsx";
import GeneralSettingOverview from "../GeneralSettingOverview/GeneralSettingOverview.jsx";

export default function SettingsOverview(props) {
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  const classes = useStyles();
  const [currentSettingTab, setCurrentSettingTab] = useState("statusSetting");

  //render tab content conditionally
  const renderTab = {
    statusSetting: (
      <StatusSettingOverview
        setSnackValue={setSnackValue}
        projectId={props.params.projectId}
      />
    ),
    taskTemplateSetting: (
      <TaskTemplateSettingOverview
        setSnackValue={setSnackValue}
        projectId={props.params.projectId}
      />
    ),
    genralSetting: (
      <GeneralSettingOverview
        setSnackValue={setSnackValue}
        projectId={props.params.projectId}
      />
    ),
  };

  const handleTab = (value) => {
    return renderTab[value];
  };

  const handleSettingsTabChange = (tab) => {
    setCurrentSettingTab(tab);
  };

  return (
    <>
      <ErrorOrSuccessMessage snackValue={snackValue} />
      <div className={classes.root}>
        <div className={classes.divChildContent1}>
          <span className={classes.settingTitle}>Project Settings</span>
          <Button
            style={{ padding: "0px" }}
            onClick={() =>
              downloadToFile(props.params.projectId, "URLs.env", "text/plain")
            }
          >
            <FileArrowDown height={20} width={20} />
            <span>URLs</span>
          </Button>
        </div>

        <div>
          <SettingOverviewTools
            handleSettingsTabChange={handleSettingsTabChange}
            currentSettingTab={currentSettingTab}
          />
        </div>
        <div>{handleTab(currentSettingTab)}</div>
      </div>
    </>
  );
}
