import { Button, CircularProgress } from "@material-ui/core";
import { Reorder } from "framer-motion";
import React, { useEffect, useState } from "react";
import { fpatch } from "../../../../../API/callsAPI";
import SingleCardForRearrange from "./SingleCardForRearrange.component";
import useStyles from "./RearrangeTaskPhases.style";
import { Save, X } from "react-feather";

const RearrangeTaskPhases = ({
  phases,
  departments,
  updateRefreshCounter,
  setSnackValue,
  setCurrentView,
}) => {
  const classes = useStyles();

  const [items, setItems] = useState(phases);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleRearrangeOder = (e) => {
    setItems(e);
  };

  useEffect(() => {
    const tasksOrder = phases.map((item) => {
      return item.id;
    });
    let rearrangedTask = [];
    for (let i = 0; i < phases.length; i++) {
      const index = tasksOrder.findIndex((item) => phases[i].id === item);
      rearrangedTask[index] = items[i];
    }
    setItems(rearrangedTask);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rearrangePhases = async () => {
    setIsUpdating(true);
    const rearrangeData = {
      url: `phase-order/`,
      data: {
        order: items.map((item) => {
          return item.id;
        }),
      },
    };
    try {
      const res = await fpatch(rearrangeData);
      if (res.status === 200 || 201) {
        setSnackValue({
          isOpen: true,
          message: `successfully rearranged task phases`,
          isError: false,
        });
        updateRefreshCounter();
        setCurrentView("managePhases");
        setIsUpdating(false);
      }
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
      setIsUpdating(false);
    }
  };

  return (
    <div style={{ height: "90%" }}>
      <div style={{ height: "87%", overflowY: "auto" }}>
        <Reorder.Group
          className={classes.reorderGroup}
          layoutScroll
          values={items}
          onReorder={(e) => handleRearrangeOder(e)}
          style={{ margin: 0, padding: 0 }}
        >
          {items.map((item, index) => (
            <SingleCardForRearrange
              item={item}
              key={item.id}
              itemsList={phases}
              index={index}
              departments={departments}
            />
          ))}
        </Reorder.Group>
      </div>
      <div className={classes.flexCenter}>
        <div className={classes.buttons}>
          <Button
            className={classes.saveButton}
            onClick={() => rearrangePhases()}
            disabled={isUpdating ? true : false}
          >
            {isUpdating ? (
              <CircularProgress style={{ width: "20px", height: "20px" }} />
            ) : (
              <>
                <Save size={20} />
                <span style={{ marginLeft: "10px" }}>Save Changes</span>
              </>
            )}
          </Button>
          <Button
            className={classes.discardButton}
            onClick={() => {
              setCurrentView("managePhases");
            }}
          >
            <X /> <span style={{ marginLeft: "10px" }}>Discard Changes</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RearrangeTaskPhases;
