import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Plus } from "react-feather";
import { reOrderTask } from "../../../TaskPageOverview/TaskPageUtilities";
import useStyles from "./TaskPageKanbanView.style.js";
import { DraggableCard } from "../CustomizedCardForKanban/CustomizedTaskCardForKanban";
import { CaretDown, CaretUp } from "phosphor-react";

const GroupBoard = ({
  singleGroup, //list of task grouped by status
  moveCard, //handle api call of changing status after drop
  handleNewTaskModalState, //handle open and close of new task modal
  handleViewTaskModal, //handle opening task view modal
  departments,
}) => {
  const [trackableGroup, setTrackableGroup] = useState([]);
  const [showScrollBar, setShowScrollBar] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const groupedByTrackable = reOrderTask(singleGroup.tasks);
    setTrackableGroup(groupedByTrackable.groupedTask);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleGroup]);

  return (
    <div
      className={`${classes.group} ${
        showScrollBar === true ? classes.showScrollBar : classes.hideScrollBar
      }`}
      onMouseEnter={() => {
        setShowScrollBar(true);
      }}
      onMouseLeave={() => {
        setShowScrollBar(false);
      }}
      id="group"
    >
      {trackableGroup.length > 0 ? (
        <>
          {trackableGroup.map((singleTrackable) => {
            return (
              <SingleGroup
                key={singleTrackable?.id}
                singleTrackable={singleTrackable}
                singleGroup={singleGroup}
                moveCard={moveCard}
                handleViewTaskModal={(tasks) => {
                  handleViewTaskModal(tasks, []); //pass tasks with same trackable as a second parameter
                }}
                handleNewTaskModalState={handleNewTaskModalState}
                departments={departments}
              />
            );
          })}
        </>
      ) : (
        <>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => {
              handleNewTaskModalState();
            }}
          >
            <Plus size={20} />
          </Button>
        </>
      )}
    </div>
  );
};

export const SingleGroup = ({
  singleTrackable,
  singleGroup,
  moveCard,
  handleViewTaskModal,
  handleNewTaskModalState,
  departments,
}) => {
  const classes = useStyles();
  const [showAndHide, setShowAndHide] = useState(false);
  const [redirectPath, setRedirectPath] = useState("");

  useEffect(() => {
    if (singleTrackable.linkedClass === "Asset") {
      setRedirectPath(
        `/${departments[0].project}/assets/${singleTrackable.trackable}/assetversions`
      );
    } else {
      setRedirectPath(
        `/${departments[0].project}/sequence/${singleTrackable.tasks[0].linked.parent_sequence}/shots/${singleTrackable.trackable}/shotversions`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleTrackable, departments]);

  return (
    <div className={classes.trackable}>
      <div className={classes.groupInfo}>
        <span
          className={classes.groupTitle}
          onClick={() => {
            window.open(redirectPath);
          }}
        >
          <div>{singleTrackable.trackableName} </div>
          <div className={classes.linkedClass}>
            {singleTrackable.linkedClass}
          </div>
        </span>
        <div>
          <button className={classes.button}>
            {/* <PencilLine size={20} style={{ padding: "0" }} /> */}
          </button>
          <button
            className={classes.button}
            onClick={() => {
              setShowAndHide(!showAndHide);
            }}
          >
            {showAndHide ? <CaretDown size={20} /> : <CaretUp size={20} />}
          </button>
        </div>
      </div>
      {showAndHide === false ? (
        <>
          {singleTrackable.tasks.map((singleTask, index) => {
            return (
              <DraggableCard
                key={singleTask.id}
                id={singleTask.id}
                singleGroupId={singleGroup.status.id}
                singleGroupIndex={index}
                singleTask={singleTask}
                moveCard={moveCard}
                handleViewTaskModal={handleViewTaskModal}
                redirectPath={redirectPath}
              />
            );
          })}
          <Button
            variant="outlined"
            fullWidth
            style={{ border: "0px", margin: "0px", padding: "0px" }}
            onClick={() => {
              handleNewTaskModalState();
            }}
          >
            <Plus size={20} />
          </Button>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default GroupBoard;
