import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    background: theme.palette.primary.main,
    height: "85%",
    width: "85%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    position: "absolute",
    marginTop: "5%",
    marginLeft: "10%",
    overflowY: "auto",
  },
  modal1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: 600,
  },
  modalHead: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.palette.secondary.main,
    fontFamily: "Nunito Sans",
  },
  cardDiv: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
  },
  cardContent: {
    margin: "2px",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  cardTitleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  date: {
    fontSize: "10px",
    color: theme.palette.text.tertiary,
    textAlign: "end",
  },
  image: {
    cursor: "pointer",
    // marginRight: "10%",
    width: "100%",
    maxHeight: "280px",
    borderRadius: "10px",
  },
  editLogo: {
    margin: "2px",
    color: theme.palette.text.secondary,
    width: "15px",
    borderRadius: "2px",
  },
  editButton: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "10px",
    minWidth: "35px",
    height: "35px",
  },
  commentIconDiv: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    cursor: "pointer",
    marginLeft: "10px",
    color: theme.palette.secondary.main,
  },
  commentIcon: {
    margin: "0px",
    padding: "0px",
  },
  canvasDiv: {
    borderRadius: "10px",
    height: "95%",
    width: "95%",
    padding: "2% 5%",
    background: theme.palette.primary.main,
  },
  commentsTools: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 10px",
    color: theme.palette.primary.light,
    // height: "8%",
    margin: "5px 0px",
  },
  statusDropDown: {
    display: "flex",
    alignItems: "center",
    width: "190px",
  },
  input: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "10px",
    padding: "0px",
    width: "160px",
    color: theme.palette.primary.light,
  },
  menuItem: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
  },
  filterText: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.light,
  },
  flexBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  status: {
    display: "flex",
    alignItems: "start",
    justifyContent: "end",
    marginRight: "-15px",
  },
  showHide: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  threeDImage: {
    width: "100%",
    height: "250px",
    borderRadius: "10px",
  },
  threeIconContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    display: "flex",
    gap: "5px",
  },
  noteModal: {
    backgroundColor: theme.palette.primary.main,
    alignItems: "flex-start",
    gap: "10px",
    width: "350px",
    borderRadius: "10px",
    border: `1px solid ${theme.palette.secondary.main}`,
    marginTop: "-5px",
    textAlign: "center",
    padding: "4px 10px",
    fontWeight: 600,
    fontSize: "14px",
  },
}));

export default useStyles;
