import { Chip } from "@material-ui/core";
import React from "react";
import useStyles from "./AssetListStatusDrop.styles";

export default function AssetListDepartmentsTags(props) {
  const classes = useStyles();
  return (
    <>
      {props.allowedDepartments ? (
        <Chip
          className={classes.listText}
          style={{ fontSize: "smaller", minWidth: "20px" }}
          size="small"
          label={
            props.allowedDepartments.name ? props.allowedDepartments.name : ""
          }
          variant="outlined"
        />
      ) : (
        "No Departments"
      )}
    </>
  );
}
