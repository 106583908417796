import { TableCell, TableRow, Tooltip } from "@material-ui/core";
import { CheckSquareOffset } from "phosphor-react";
import React from "react";
import useStyles from "./DepartmentDetailsTaskPage.styles";
import UsersViewer from "../../Utilities/UsersViewer/UsersViewer";
import { TrackablesAndIcons } from "../../Settings/Utility/TrackablesDetails";
import NoThumbnail from "../../Utilities/NoThumbnail.component";
import { useState } from "react";
import { Link } from "react-router-dom";
import PriorityViewer from "../../Utilities/PriorityViewer/PriorityViewer.component";
export default function DepartmentTaskTable({
  hideItems,
  task,
  redirect,
  projectId,
}) {
  const classes = useStyles();
  const [isHovered, setIsHovered] = useState(false);
  return (
    <>
      <TableRow style={{ display: hideItems ? "none" : "" }}>
        <TableCell align="left" className={classes.tableCell}>
          {" "}
          {task?.name}{" "}
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          {new Intl.DateTimeFormat("en-GB", {
            day: "2-digit",
            month: "short",
          }).format(new Date(task?.start_time))}{" "}
          -{" "}
          {new Intl.DateTimeFormat("en-GB", {
            day: "2-digit",
            month: "short",
          }).format(new Date(task?.end_time))}
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          {task?.status?.id ? (
            <Tooltip title={task?.status?.name} placement="top">
              <div className={classes.statusDiv}>
                <span
                  className={classes.statusColor}
                  style={{
                    backgroundColor: `rgb(${task?.status?.color})`,
                  }}
                ></span>
                <span className={classes.statusName}>{task?.status?.name}</span>
              </div>
            </Tooltip>
          ) : (
            "no status"
          )}
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          <PriorityViewer priority={task?.priority} />
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          <div
            className={classes.trackableDiv}
            style={{
              textDecoration: isHovered ? "underline" : "none",
            }}
            onClick={() => redirect()}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div>
              {
                TrackablesAndIcons?.find(
                  (track) => track?.trackable === task?.linked_class
                )?.icon
              }
            </div>

            {task?.linked?.thumbnail ? (
              <img
                className={classes.trackableImage}
                src={task?.linked?.thumbnail}
                alt="No Img"
                onContextMenu={(e) => e.preventDefault()}
              />
            ) : (
              <NoThumbnail
                style={{
                  margin: "4px 6px 4px 10px",
                  height: "28px",
                  width: "28px",
                }}
              />
            )}

            <span style={{ display: "block" }}>
              <span>{task?.linked.name || task?.linked.code}</span>
              {task?.shotversions[0]?.name || task?.assetversions[0]?.name ? (
                <>
                  <span>{" - "}</span>
                  <span>
                    {task?.shotversions[0]?.name ||
                      task?.assetversions[0]?.name}
                  </span>
                </>
              ) : (
                ""
              )}
              {task?.shotversions.length > 1 ||
              task?.assetversions.length > 1 ? (
                <span>
                  {" "}
                  +
                  {(task?.shotversions.length || task?.assetversions.length) -
                    1}
                </span>
              ) : (
                ""
              )}
            </span>
          </div>
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          {task?.assigned_users?.length > 0 ? (
            <UsersViewer
              assignedUser={task?.assigned_users}
              selectedTask={task}
              editable="no"
            />
          ) : (
            "No user"
          )}
        </TableCell>
        <TableCell align="right" className={classes.tableCell}>
          <Link to={`/${projectId}/tasks`} className={classes.link}>
            <CheckSquareOffset size={25} />
          </Link>
        </TableCell>
      </TableRow>
    </>
  );
}
