import React from "react";
import useStyles from "./TrackablesAndIcons";
import { TrackableData } from "../../Utilities/Trackables/trackablesData.jsx";
import { Button } from "@material-ui/core";

export default function AllTrackables({
  filterByTrackable,
  selectedTrackable,
}) {
  const classes = useStyles();
  return (
    <div className={classes.trackableContainer}>
      {TrackableData.map((val) => (
        <Button
          key={val.id}
          className={
            val.id === selectedTrackable?.id
              ? classes.selectedContainer
              : classes.container
          }
          style={{}}
          onClick={() => filterByTrackable(val)}
        >
          <div className={classes.iconContainer}>{val.icon}</div>
          <div>{val.trackable}</div>
        </Button>
      ))}
    </div>
  );
}
