import React, { useRef, useState, useCallback } from "react";
import { Popover, Tooltip } from "@material-ui/core";
import {
  MagnifyingGlassMinus,
  MagnifyingGlassPlus,
  ArrowCounterClockwise,
  CornersOut,
  DotsThreeVertical,
  DownloadSimple,
} from "phosphor-react";
import useStyles from "./ImageViewer.styles";
import RotateIcon from "../Svg/RotateIcon";

const ImageViewer = ({
  matchHeight,
  setImageRef,
  setIsOpenFullscreenViewer,
  setFileForFullscreenViewer,
  file,
}) => {
  const classes = useStyles();
  const refDiv = useRef(null);
  const image = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  let imagePosition = { x: 0, y: 0 };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  let zoomImage = 0;
  let rotateImage = 0;
  let imageOpData = [];

  const handleZoomIn = () => {
    if (zoomImage) {
      refDiv.current.style.transform = `scale(${
        zoomImage + 1.3
      }) rotate(${rotateImage}deg)`;
      refDiv.current.style.transformOrigin = "50% 50%";

      zoomImage = zoomImage + 1.3;
      imageOpData.push({ zoomIn: zoomImage });
    } else {
      refDiv.current.style.transform = `scale(1.3) rotate(${rotateImage}deg)`;
      refDiv.current.style.transformOrigin = "50% 50%";

      zoomImage = 1.3;
      imageOpData.push({ zoomIn: zoomImage });
    }
  };

  const handleZoomOut = () => {
    if (zoomImage) {
      refDiv.current.style.transform = `scale(${
        zoomImage - 1.3 < 1 ? 1 : zoomImage - 1.3
      }) rotate(${rotateImage}deg)`;
      refDiv.current.style.transformOrigin = "50% 50%";

      zoomImage = zoomImage - 1.3 < 1 ? 0 : zoomImage - 1.3;
      imageOpData.push({ zoomOut: zoomImage });
    }
  };

  const handleRotate = () => {
    if (rotateImage === 270) {
      rotateImage = 0;
      refDiv.current.style.transform = `rotate(${0}deg)`;
    } else {
      refDiv.current.style.transform = `rotate(${rotateImage + 90}deg)`;
      rotateImage = rotateImage + 90;
    }
    imageOpData.push({ rotate: rotateImage });
  };

  const undoChanges = () => {
    let lastChange = imageOpData.at(-1);
    if (lastChange.zoomIn) {
      zoomImage = zoomImage - 1.3 < 1 ? 1 : zoomImage - 1.3;
      refDiv.current.style.transform = `scale(${zoomImage})`;
      refDiv.current.style.transformOrigin = "50% 50%";

      zoomImage = zoomImage - 1.3 < 1 ? 0 : zoomImage - 1.3;
      imageOpData = imageOpData.slice(0, -1);
    } else if (lastChange.zoomOut) {
      zoomImage = zoomImage + 1.3;
      refDiv.current.style.transform = `scale(${zoomImage})`;
      refDiv.current.style.transformOrigin = "50% 50%";
      imageOpData = imageOpData.slice(0, -1);
    } else if (lastChange.zoomOut === 0) {
      refDiv.current.style.transform = `scale(${zoomImage + 1.3})`;
      refDiv.current.style.transformOrigin = "50% 50%";
      imageOpData = imageOpData.slice(0, -1);
    } else if (lastChange.rotate) {
      rotateImage = rotateImage - 90;
      refDiv.current.style.transform = `rotate(${rotateImage}deg)`;
      imageOpData = imageOpData.slice(0, -1);
    }
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
    const startX = event.pageX;
    const startY = event.pageY;
    const { x, y } = imagePosition;
    const handleMouseMove = (event) => {
      event.preventDefault();
      let deltaX;
      let deltaY;
      if (rotateImage === 90) {
        deltaX = event.pageY - startY;
        deltaY = -(event.pageX - startX);
      } else if (rotateImage === 180) {
        deltaX = -(event.pageX - startX);
        deltaY = -(event.pageY - startY);
      } else if (rotateImage === 270) {
        deltaX = -(event.pageY - startY);
        deltaY = event.pageX - startX;
      } else {
        deltaX = event.pageX - startX;
        deltaY = event.pageY - startY;
      }
      imagePosition = { x: x + deltaX, y: y + deltaY };
      image.current.style.transform = `translate(${x + deltaX}px, ${
        y + deltaY
      }px)`;
    };
    const handleMouseUp = (event) => {
      event.preventDefault();
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  const handleImageLoad = () => {
    const naturalWidth = image.current.clientWidth;
    const naturalHeight = image.current.clientHeight;
    const { width, height } = refDiv?.current?.getBoundingClientRect();
    if (
      naturalHeight <= refDiv.current.clientHeight &&
      naturalWidth <= refDiv.current.clientWidth
    ) {
      imagePosition = {
        x: (width - naturalWidth) / 2,
        y: (height - naturalHeight) / 2,
      };
    }
  };

  const setRefs = useCallback((node) => {
    image.current = node;
    setImageRef(node);
  }, []);

  return (
    <>
      <div
        style={{
          width: matchHeight ? "98%" : "100%",
          maxHeight: "98%",
          backgroundColor: "black",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
          }}
          onMouseDown={handleMouseDown}
          ref={refDiv}
        >
          <img
            ref={setRefs}
            onLoad={handleImageLoad}
            crossOrigin="Anonymous"
            style={{
              position: "absolute",
              width: "auto",
              height: "100%",
              top: `0 px`,
              left: `0 px`,
              transformOrigin: "top left",
            }}
            src={file}
            onContextMenu={(e) => e.preventDefault()}
            draggable={false}
          />
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "0.1%",
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "10px",
            width: "100%",
            boxShadow: "0 0 10px 1px black",
            background: "rgba(0,0,0,0.45)",
            // boxShadow: [
            //   "inset 0 -50px 50px -30px rgba(0,0,0,1)",
            //   "0 -10px 10px 0 rgba(0,0,0,0.5)",
            // ],
          }}
          className={classes.imageIconColor}
        >
          <div>
            <Tooltip title="Reset to default" placement="top">
              <ArrowCounterClockwise
                weight="bold"
                style={{ cursor: "pointer", marginLeft: "15px" }}
                onClick={undoChanges}
              />
            </Tooltip>
          </div>
          <div style={{ display: "flex", gap: "20px" }}>
            <Tooltip title="Rotate 90 deg" placement="top">
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={handleRotate}
              >
                <RotateIcon />
              </div>
            </Tooltip>
            <div>
              <Tooltip title="Zoom in" placement="top">
                <MagnifyingGlassPlus
                  weight="bold"
                  style={{ cursor: "pointer" }}
                  onClick={handleZoomIn}
                />
              </Tooltip>
            </div>
            <div>
              <Tooltip title="Zoom out" placement="top">
                <MagnifyingGlassMinus
                  weight="bold"
                  style={{ cursor: "pointer" }}
                  onClick={handleZoomOut}
                />
              </Tooltip>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              gap: "20px",
            }}
          >
            <div>
              <Tooltip title="Fullscreen" placement="top">
                <CornersOut
                  weight="bold"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsOpenFullscreenViewer(true);
                    setFileForFullscreenViewer(file);
                    console.log("clicked");
                  }}
                />
              </Tooltip>
            </div>
            <div>
              <Tooltip title="Menu" placement="top">
                <DotsThreeVertical
                  weight="bold"
                  style={{ cursor: "pointer" }}
                  onClick={handleClick}
                />
              </Tooltip>
            </div>
            <div>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                style={{
                  marginTop: "-45px",
                }}
              >
                <div
                  classeName={classes.downloadOption}
                  style={{
                    backgroundColor: "#1B1D28",
                    padding: "5px",
                    cursor: "pointer",
                  }}
                >
                  <a href={file} download>
                    <Tooltip title="Download" placement="top">
                      <DownloadSimple
                        size={20}
                        className={classes.imageIconColor}
                      />
                    </Tooltip>
                  </a>
                </div>
              </Popover>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageViewer;
