import { useLocation } from "react-router-dom";
import useStyles from "./TaskVersionHover.styles";
import { Card, Divider, Tooltip } from "@material-ui/core";
import NoThumbnail from "../NoThumbnail.component";
import VersionIcon from "../Svg/VersionIcon";
import { useEffect, useState } from "react";

const TaskVersionHover = ({
  versionList,
  parent,
  versionLength,
  kanban,
  handleClose,
  viewType,
}) => {
  const [margin, setMargin] = useState(55);
  const classes = useStyles();
  const location = useLocation();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // the card element moves to top when more items are there in table view
  // to increase marginTop of card if so
  useEffect(() => {
    if (versionLength) {
      let marginTop = 55;
      for (let i = 3; i <= versionLength; i++) {
        marginTop += 40;
      }
      setMargin(marginTop);
    }
  }, [versionLength]);

  const getDateAndTime = (timestamp) => {
    const date = new Date(timestamp);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutes = date.getMinutes();
    const formattedTimestamp =
      month +
      " " +
      day +
      ", " +
      year +
      " | " +
      hours +
      ":" +
      (minutes < 10 ? "0" : "") +
      minutes +
      " " +
      ampm;
    return formattedTimestamp;
  };

  return (
    <div
      style={
        viewType === "List" ||
        viewType === "Gantt" ||
        viewType === "Table" ||
        viewType === "Kanban"
          ? {
              display: "flex",
              flexDirection: "column",
            }
          : {
              display: "flex",
              alignItems: "center",
              marginLeft: "4px",
            }
      }
      onMouseLeave={handleClose}
    >
      <div
        className={
          viewType === "List"
            ? classes.popoverArrowTriangleList
            : viewType === "Gantt" ||
              viewType === "Table" ||
              viewType === "Kanban"
            ? classes.popoverArrowTriangleTable
            : classes.popoverArrowTriangle
        }
      ></div>
      <div
        className={
          viewType === "List"
            ? classes.popoverArrowList
            : viewType === "Gantt" ||
              viewType === "Table" ||
              viewType === "Kanban"
            ? classes.popoverArrowTable
            : classes.popoverArrow
        }
      ></div>
      <Card className={classes.card}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p className={classes.lighterText}>Version Name</p>
          <p className={classes.lighterText}>Last Modified</p>
        </div>
        <div
          style={{
            padding: "10px",
            width: "100%",
            maxHeight: "300px",
            overflow: "scroll",
          }}
        >
          {kanban &&
            versionList.map((version, index) => {
              if (index !== 0) {
                return (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <VersionIcon />
                        {parent.thumbnail ? (
                          <img
                            style={{
                              borderRadius: "7px",
                              objectFit: "cover",
                              marginRight: "5px",
                              marginLeft: "10px",
                            }}
                            src={parent.thumbnail}
                            height="28px"
                            width="28px"
                            alt="Img"
                            onContextMenu={(e) => e.preventDefault()}
                          />
                        ) : (
                          <NoThumbnail
                            style={{
                              marginRight: "5px",
                              marginLeft: "10px",
                              height: "28px",
                              width: "28px",
                            }}
                          />
                        )}
                        {parent.name ? (
                          <Tooltip title={version.name} placement="top-start">
                            <span
                              className={classes.onHover}
                              onClick={() => {
                                location.pathname = `/${parent.project}/assets/${parent.id}/assetversions/`;
                                const encode = btoa(
                                  unescape(
                                    encodeURIComponent(
                                      `versionId=${version.id}`
                                    )
                                  )
                                );

                                window.open(`${location.pathname}?${encode}`);
                              }}
                            >
                              {version.name}
                            </span>
                          </Tooltip>
                        ) : (
                          <Tooltip title={version.name} placement="top-start">
                            <span
                              className={classes.onHover}
                              onClick={() => {
                                location.pathname = `/${parent.project}/sequence/${parent.parent_sequence}/shots/${parent.id}/shotversions/`;
                                const encode = btoa(
                                  unescape(
                                    encodeURIComponent(
                                      `versionId=${version.id}`
                                    )
                                  )
                                );

                                window.open(`${location.pathname}?${encode}`);
                              }}
                            >
                              {version.name}
                            </span>
                          </Tooltip>
                        )}
                      </div>
                      <div className={classes.lighterText}>
                        {getDateAndTime(version.updated_at)}
                      </div>
                    </div>
                    {versionList.at(-1) !== version && (
                      <Divider
                        style={{ margin: "5px 0", backgroundColor: "#606479" }}
                      />
                    )}
                  </>
                );
              }
            })}
          {!kanban &&
            versionList.map((version, index) => {
              return (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <VersionIcon />
                      {parent.thumbnail ? (
                        <img
                          style={{
                            borderRadius: "7px",
                            objectFit: "cover",
                            marginRight: "5px",
                            marginLeft: "10px",
                          }}
                          src={parent.thumbnail}
                          height="28px"
                          width="28px"
                          alt="Img"
                          onContextMenu={(e) => e.preventDefault()}
                        />
                      ) : (
                        <NoThumbnail
                          style={{
                            marginRight: "5px",
                            marginLeft: "10px",
                            height: "28px",
                            width: "28px",
                          }}
                        />
                      )}
                      {parent.name ? (
                        <Tooltip title={version.name} placement="top-start">
                          <span
                            className={classes.onHover}
                            onClick={() => {
                              location.pathname = `/${parent.project}/assets/${parent.id}/assetversions/`;
                              const encode = btoa(
                                unescape(
                                  encodeURIComponent(`versionId=${version.id}`)
                                )
                              );

                              window.open(`${location.pathname}?${encode}`);
                            }}
                          >
                            {version.name}
                          </span>
                        </Tooltip>
                      ) : (
                        <Tooltip title={version.name} placement="top-start">
                          <span
                            className={classes.onHover}
                            onClick={() => {
                              location.pathname = `/${parent.project}/sequence/${parent.parent_sequence}/shots/${parent.id}/shotversions/`;
                              const encode = btoa(
                                unescape(
                                  encodeURIComponent(`versionId=${version.id}`)
                                )
                              );

                              window.open(`${location.pathname}?${encode}`);
                            }}
                          >
                            {version.name}
                          </span>
                        </Tooltip>
                      )}
                    </div>
                    <div className={classes.lighterText}>
                      {getDateAndTime(version.updated_at)}
                    </div>
                  </div>
                  {versionList.at(-1) !== version && (
                    <Divider
                      style={{ margin: "5px 0", backgroundColor: "#606479" }}
                    />
                  )}
                </>
              );
            })}
        </div>
      </Card>
    </div>
  );
};

export default TaskVersionHover;
