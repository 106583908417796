import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    statusTrackables:{
        color:theme.palette.text.tertiary,
    },
    selectedTrackable:{
        color:theme.palette.text.primary
    }

}))

export default useStyles;