import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  inlineButtons: {
    minWidth: "36px",
    borderRadius: "6px",
    padding: "6px",
    margin: "2px",
  },
}));

export default useStyles;
