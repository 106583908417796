import { Button, Grid, Popover, Tab, Tabs } from "@material-ui/core";
import { ArchiveBox, ListBullets } from "phosphor-react";
import React, { useState } from "react";
import * as Icon from "react-feather";
import { SortButton } from "../../CommonComponents/CommonButtons/CommonButtons.Component";
import useStyles from "./ProjectPageTools.styles";
import PropTypes from "prop-types";
import ProjectPageFiltersModal from "../ProjectPageModals/ProjectPageFiltersModal";
import { FilterButton } from "../../CommonComponents/CommonButtons/CommonButtons.Component";
import {
  ProjectIcon,
  ProjectIconWhite,
} from "../../Utilities/Svg/ProjectPageIcon";

function ProjectTools({
  toggleCardView,
  openNewProjModal,
  isCardView,
  handleFilter,
  value,
  updateRefreshToken,
  onSubmit,
  defaultvalue,
  handleProjectView,
  projectView,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [btnActive, setBtnActive] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Grid container className={classes.root} justify="space-between">
      <Grid item lg={4} md={4} sm={2} xs={12}>
        <div style={{ display: "flex", alignItems: "center", gap: "40px" }}>
          <div className={classes.title}>Projects</div>
          <Tabs
            value={projectView}
            onChange={(event, value) => {
              handleProjectView(value);
            }}
          >
            <Tab
              value={"active"}
              className={classes.tab}
              label={
                <div className={classes.tabItems}>
                  {projectView === "active" ? (
                    <>
                      <ProjectIconWhite />
                      <span className={classes.active}>Active</span>
                    </>
                  ) : (
                    <>
                      <ProjectIcon />
                      <span className={classes.inActive}>Active</span>
                    </>
                  )}
                </div>
              }
            />
            <Tab
              value={"archived"}
              className={classes.tab}
              label={
                <div className={classes.tabItems}>
                  {projectView === "archived" ? (
                    <>
                      <ArchiveBox size={20} className={classes.active} />
                      <span className={classes.active}>Archived</span>
                    </>
                  ) : (
                    <>
                      <ArchiveBox size={20} className={classes.inActive} />
                      <span className={classes.inActive}>Archived</span>
                    </>
                  )}
                </div>
              }
            />
          </Tabs>
        </div>
      </Grid>
      <Grid
        container
        item
        lg={8}
        md={8}
        sm={10}
        xs={12}
        className={classes.projectPageToolsGrid}
        justify="flex-end"
      >
        <Grid item>
          <Button
            className={classes.projectPageButton}
            onClick={toggleCardView}
          >
            {isCardView ? (
              <>
                {" "}
                <ListBullets size={27} />{" "}
                <p className={classes.tool}>List View</p>
              </>
            ) : (
              <>
                <ListBullets size={27} />{" "}
                <p className={classes.tool}>Card View</p>{" "}
              </>
            )}
          </Button>
        </Grid>
        <Grid item>
          <FilterButton btnActive={btnActive} openFilterModal={handleClick} />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            PaperProps={{
              style: {
                backgroundColor: "transparent",
                border: "none",
                boxShadow: "none",
              },
            }}
          >
            <div className={classes.popoverArrowTriangle}></div>
            <div style={{ position: "relative" }}>
              <div className={classes.popoverArrow}></div>
              <ProjectPageFiltersModal
                handleFilter={handleFilter}
                value={value}
                updateRefreshToken={updateRefreshToken}
                closeFilterModal={handleClose}
                setBtnActive={setBtnActive}
              />
            </div>
          </Popover>
        </Grid>
        <Grid item>
          <SortButton
            onSubmit={onSubmit}
            defaultvalue={defaultvalue}
            type="project"
          />
        </Grid>
        <Grid item>
          <Button
            size="small"
            id="NewProject"
            className={classes.projectPageNewProjButton}
            disableElevation
            onClick={openNewProjModal}
          >
            <Icon.Plus className={classes.plusIcon} />
            New Project
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ProjectTools;

ProjectTools.propTypes = {
  toggleCardView: PropTypes.func,
  openFilterModal: PropTypes.func,
  openSortModal: PropTypes.func,
  openNewProjModal: PropTypes.func,
  isCardView: PropTypes.bool,
};
