import React from "react";

export const AdminIcon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 18C3.5544 16.9895 0 12.6998 0 8.18182V3.27273L8 0L16 3.27273V8.18182C16 12.7015 12.4456 16.9895 8 18ZM1.6 4.09091V8.18182C1.64572 10.0736 2.29629 11.8979 3.45214 13.3755C4.608 14.8532 6.20545 15.9028 8 16.3636C9.79455 15.9028 11.392 14.8532 12.5479 13.3755C13.7037 11.8979 14.3543 10.0736 14.4 8.18182V4.09091L8 1.63636L1.6 4.09091Z"
        fill="#6C65D9"
      />
      <path
        d="M8 8C9.10457 8 10 7.10457 10 6C10 4.89543 9.10457 4 8 4C6.89543 4 6 4.89543 6 6C6 7.10457 6.89543 8 8 8Z"
        fill="#6C65D9"
      />
      <path
        d="M4 11.5C4.39431 12.2485 4.97247 12.8744 5.67531 13.3137C6.37815 13.753 7.18042 13.9898 8 14C8.81958 13.9898 9.62185 13.753 10.3247 13.3137C11.0275 12.8744 11.6057 12.2485 12 11.5C11.98 9.92 9.3264 9 8 9C6.6664 9 4.02 9.92 4 11.5Z"
        fill="#6C65D9"
      />
    </svg>
  );
};
export const UserIcon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C9.06087 0 10.0783 0.421427 10.8284 1.17157C11.5786 1.92172 12 2.93913 12 4C12 5.06087 11.5786 6.07828 10.8284 6.82843C10.0783 7.57857 9.06087 8 8 8C6.93913 8 5.92172 7.57857 5.17157 6.82843C4.42143 6.07828 4 5.06087 4 4C4 2.93913 4.42143 1.92172 5.17157 1.17157C5.92172 0.421427 6.93913 0 8 0ZM8 16C8 16 16 16 16 14C16 11.6 12.1 9 8 9C3.9 9 0 11.6 0 14C0 16 8 16 8 16Z"
        fill="#6C65D9"
      />
    </svg>
  );
};
export const ReviewerIcon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 4C7.92826 4 8.8185 4.36875 9.47487 5.02513C10.1313 5.6815 10.5 6.57174 10.5 7.5C10.5 8.42826 10.1313 9.3185 9.47487 9.97487C8.8185 10.6313 7.92826 11 7 11C6.07174 11 5.1815 10.6313 4.52513 9.97487C3.86875 9.3185 3.5 8.42826 3.5 7.5C3.5 6.57174 3.86875 5.6815 4.52513 5.02513C5.1815 4.36875 6.07174 4 7 4ZM7 18C7 18 14 18 14 16.25C14 14.15 10.5875 11.875 7 11.875C3.4125 11.875 0 14.15 0 16.25C0 18 7 18 7 18Z"
        fill="#6C65D9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.8742 1.80512C13.9868 1.69965 14.05 1.5566 14.05 1.40744C14.05 1.25828 13.9868 1.11523 13.8742 1.00975C13.7617 0.904281 13.6091 0.845027 13.45 0.845027C13.2908 0.845027 13.1382 0.904281 13.0257 1.00975C12.9132 1.11523 12.85 1.25828 12.85 1.40744C12.85 1.5566 12.9132 1.69965 13.0257 1.80512C13.1382 1.9106 13.2908 1.96985 13.45 1.96985C13.6091 1.96985 13.7617 1.9106 13.8742 1.80512ZM14.3993 0.317379C14.6672 0.522632 14.8511 0.808389 14.92 1.12623H16.45C16.6091 1.12623 16.7617 1.18549 16.8742 1.29096C16.9868 1.39643 17.05 1.53948 17.05 1.68865V8.43759C17.05 8.58675 16.9868 8.7298 16.8742 8.83527C16.7617 8.94075 16.6091 9 16.45 9H11.443C11.4796 8.91058 11.5134 8.81988 11.5443 8.72803C11.7867 8.00753 11.8443 7.23872 11.7118 6.49076C11.5793 5.74279 11.261 5.03912 10.7854 4.44305C10.5159 4.10528 10.2008 3.80806 9.84998 3.55908V1.68865C9.84998 1.53948 9.91319 1.39643 10.0257 1.29096C10.1382 1.18549 10.2908 1.12623 10.45 1.12623H11.98C12.0488 0.808389 12.2328 0.522632 12.5007 0.317379C12.7686 0.112126 13.104 0 13.45 0C13.796 0 14.1314 0.112126 14.3993 0.317379ZM11.7818 4.06184C11.6974 4.14094 11.65 4.24823 11.65 4.3601C11.65 4.47197 11.6974 4.57926 11.7818 4.65837C11.8662 4.73747 11.9806 4.78191 12.1 4.78191H14.8C14.9193 4.78191 15.0338 4.73747 15.1182 4.65837C15.2026 4.57926 15.25 4.47197 15.25 4.3601C15.25 4.24823 15.2026 4.14094 15.1182 4.06184C15.0338 3.98273 14.9193 3.93829 14.8 3.93829H12.1C11.9806 3.93829 11.8662 3.98273 11.7818 4.06184ZM11.7818 5.46787C11.8662 5.38876 11.9806 5.34432 12.1 5.34432H14.8C14.9193 5.34432 15.0338 5.38876 15.1182 5.46787C15.2026 5.54697 15.25 5.65426 15.25 5.76613C15.25 5.878 15.2026 5.98529 15.1182 6.0644C15.0338 6.1435 14.9193 6.18794 14.8 6.18794H12.1C11.9806 6.18794 11.8662 6.1435 11.7818 6.0644C11.6974 5.98529 11.65 5.878 11.65 5.76613C11.65 5.65426 11.6974 5.54697 11.7818 5.46787Z"
        fill="#6C65D9"
      />
    </svg>
  );
};
