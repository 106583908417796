import React from "react";
import SvgUnAuth from "./SvgUnAuth.component";

export default function ExternalReviewerFallback(props) {
  return (
    <>
      <div
        style={{
          backgroundColor: "#1B1D28",
          height: "90vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <h1
            style={{
              fontSize: "60px",
              margin: "0px",
              fontFamily: "Nunito Sans",
              color: "#4ECCA3",
            }}
          >
            Unauthorized Access
          </h1>
          <br />
          <p
            style={{
              fontFamily: "Nunito Sans",
              color: "#ffffff",
              fontSize: "16px",
            }}
          >
            Looks like you don't have access to complete project <br />
            access requires special permission of "project user" or "project
            admin". Please contact support.
          </p>
        </div>
        <div>
          <SvgUnAuth />
        </div>
      </div>
    </>
  );
}
