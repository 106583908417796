import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  singleCardContainer: {
    width: "98%",
    padding: "10px",
    borderRadius: "10px",
    backgroundColor: theme.palette.secondary.light,
  },
  flexBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textGray: {
    color: theme.palette.text.tertiary,
  },
  customArrow: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "-4px",
  },
  circle: {
    marginLeft: "-1px",
    color: theme.palette.text.tertiary,
  },
  line: {
    height: "30px",
    width: "1px",
    backgroundColor: theme.palette.text.tertiary,
    display: "block",
    marginTop: "-3px",
    marginLeft: "-1.5px",
  },
  triangle: {
    transform: "rotate(180deg)",
    marginTop: "-3px",
    marginLeft: "-1px",
    color: theme.palette.text.tertiary,
  },
}));

export default useStyles;
