import React from "react";
import useStyles from "./PlaylistTool.styles";
import {
  SortButton,
  ToggleButton,
} from "../../CommonComponents/CommonButtons/CommonButtons.Component";
import { Button } from "@material-ui/core";
import { Funnel, Plus } from "phosphor-react";

const PlaylistTool = ({
  handleToggler,
  openFilterModal,
  openCreatePlaylistModal,
  onSubmit,
  defaultvalue,
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <div className={classes.title}>Playlist</div>
        </div>
        <div className={classes.inlineDiv}>
          <div>
            <ToggleButton handleToggler={handleToggler} />
          </div>
          <Button className={classes.filterButton} onClick={openFilterModal}>
            <Funnel size={27} className={classes.iconStyle} />
            <p className={classes.text}>Filter</p>
          </Button>
          <div>
            <SortButton onSubmit={onSubmit} defaultvalue={defaultvalue} />
          </div>
          <div>
            <Button
              size="small"
              className={classes.createPlaylistButton}
              disableElevation
              onClick={openCreatePlaylistModal}
            >
              <Plus className={classes.plusIcon} />
              Create New Playlist
            </Button>
          </div>
        </div>
      </div>
      <hr />
    </>
  );
};

export default PlaylistTool;
