import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  notificationsDropdown: {
    width: "550px",
    background: theme.palette.primary.main,
    borderRadius: "10px",
    fontFamily: "Nunito sans",
    textAlign: "center",
    color: theme.palette.text.primary,
    zIndex: 1,
    padding: "10px 8px",
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  notificationsDropdownMenu: {
    overflowY: "auto",
    height: "222px",
    display: "flex",
    flexDirection: "column",
  },
  popoverArrow: {
    zIndex: -1,
    borderRadius: "5px",
    position: "absolute",
    top: "-12px",
    right: "0px",
    borderLeft: "20px solid transparent",
    borderRight: "20px solid transparent",
    borderBottom: `20px solid ${theme.palette.primary.main}`,
    width: "0",
    height: "0",
  },
  notificationsItems: {
    cursor: "pointer",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    color: theme.palette.text.primary,
    p: {
      color: theme.palette.text.primary,
      padding: "0",
      margin: "0",
      textAlign: "start",
      width: "50%",
    },
    th: { display: "flex", justifyContent: "space-between", width: "100%" },
  },
  notificationDrop: {
    color: theme.palette.secondary.main,
    marginLeft: "5px",
    padding: "2px",
  },
  description: {
    color: theme.palette.text.primary,
    margin: "0",
    textAlign: "start",
    width: "70%",
    padding: "5px",
    fontFamily: "Nunito Sans",
    fontWeight: "bold",
  },
  description2: {
    color: theme.palette.text.primary,
    margin: "0",
    textAlign: "start",
    width: "70%",
    padding: "5px",
    fontFamily: "Nunito Sans",
  },
  divider: {
    marginRight: "20px",
    backgroundColor: theme.palette.text.tertiary,
  },
  date: {
    fontFamily: "Nunito Sans",
    fontSize: "10px",
  },
  seeAll: {
    fontFamily: "Nunito Sans",
    color: theme.palette.secondary.main,
    cursor: "pointer",
    marginBottom: "0px",
  },
}));

export default useStyles;
