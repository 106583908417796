import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    projectImage:{
        height:"40px",
        width:"70%",
        borderRadius:"8px",
        marginTop:"10%",
        objectFit:"cover",
        
    },
    projectListContainer:{
        borderBottom:"1px solid ",
        borderBottomColor:theme.palette.secondary.light,
        cursor:"pointer",
    },
    projectName:{
        color:theme.palette.secondary,
        fontWeight:"500",
        fontSize:"17px"
    }
}))

export default useStyles;