import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "25%",
        "& .MuiFormHelperText-root": {
          color: "#ffcc00",
        },
        [theme.breakpoints.down("md")]: {
            width: "33%",
            // height: "11rem",
        },
          [theme.breakpoints.down("sm")]: {
            width: "50%",
            // height: "11rem",
        },
    },
    
    card: {
        borderRadius: "8px",
    },
    cardContent: {
        backgroundColor: theme.palette.primary.main,
        padding:"18px 18px 18px 18px"
      
    },
    title: {
        color: theme.palette.secondary,
        fontSize:"20px",
        fontWeight:"500",
    },
      closeIcon:{
        color: theme.palette.secondary,
        cursor:"pointer",
        marginTop:"6%",
        float:"right"
    },
    searchInput: {
        padding: "3px 9px",
        width: "60%",
        background: theme.palette.secondary.light,
        fontFamily: "Nunito Sans",
        fontStyle: "normal",
    },
      searchDiv: {
        backgroundColor: theme.palette.secondary.light,
        borderRadius: "8px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        marginTop:"4%",
        color: theme.palette.text.primary,
    },
    projectListContainer:{
        height:"280px",
        overflowY:"scroll",
        width:"100%"
        
    },
}))

export default useStyles