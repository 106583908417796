import React from "react";

import ComparePageOverview from "../../Components/ComparePage/ComparePageOverview/ComparePageOverview.component.jsx";

export default function ComparePage(props) {
  return (
    <>
      <ComparePageOverview
        params={props.match.params}
        trackableType={props.trackableType}
      />
    </>
  );
}
