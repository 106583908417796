import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "450px",
    "& .MuiFormHelperText-root": {
      color: "#ffcc00",
    },
  },
  card: {
    borderRadius: "20px",
  },
  cardContent: {
    backgroundColor: theme.palette.primary.main,
    padding: "20px",
  },
  displayFlex: {
    display: "flex",
    justifyContent: "space-between",
  },
  closeIcon: {
    color: theme.palette.text.primary,
    cursor: "pointer",
    marginTop: "3px",
  },
  title: {
    color: theme.palette.secondary.main,
    fontSize: "25px",
    fontWeight: "600",
    marginRight: "40px",
  },
  inputLabel: {
    color: theme.palette.text.tertiary,
  },

  iconStyle: { width: "100%", marginTop: "18%" },

  cardContentDiv1: {
    justifyContent: "space-around",
    fontFamily: "Nunito Sans",
  },
  cardContentDiv2: {
    width: "50%",
    height: "6rem",
    display: "grid",
    placeItems: "center",
    border: "2px solid white", //need to redefine colors for borders in theme
    borderRadius: "10px",
    cursor: "pointer",
  },
  departmentName: {
    backgroundColor: theme.palette.secondary.light,
    maxHeight: "auto",
    minHeight: "40px",
    padding: "4px",
    borderRadius: "10px",
    marginTop: "7px",
  },

  descriptionInput: {
    backgroundColor: theme.palette.secondary.light,
    maxHeight: "auto",
    minHeight: "80px",
    padding: "4px",
    borderRadius: "10px",
    marginTop: "7px",
  },
  cardContentInput1: {
    padding: "0.2px 6px",
  },

  newStatus: {
    float: "right",
    display: "flex",
    cursor: "pointer",
    transition: "color 0.2s ease-in-out",
    color: theme.palette.secondary.main,
  },

  autoComplete: {
    marginTop: "6px",
    width: "100%",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "5px",
  },
  done: {
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    width: "200px",
    textTransform: "none",
    padding: "6px ",
    borderRadius: "7px",
    color: theme.palette.text.secondary,
  },
  cardAction: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
  },
  label: {
    marginTop: "10px",
    marginBottom: "2px",
    fontSize: "16px",
    color: theme.palette.text.tertiary,
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default useStyles;
