import React, { useEffect, useRef } from "react";
import { useState } from "react";
import TimelineRenderer from "./TimelineUtility/TimelineRenderer";

export default function TimeLineViewV2({
  sequencerData,
  currentGlobalTime,
  onTimelineSeek,
  onTimelineClick,
  handleTimelineDoubleClickEvent,
}) {
  const canvasRef = useRef(null);
  const timeline = useRef(null);
  const [canvasWidth, setCanvasWidth] = useState(1500);

  useEffect(() => {
    if (canvasRef.current && sequencerData.layers.length) {
      timeline.current = new TimelineRenderer(
        { canvasElement: canvasRef.current, height: 300, width: canvasWidth },
        {
          layers: sequencerData.layers,
          shotReprs: sequencerData.shotReps,
          duration: 200,
        },
        {
          onTimelineClick: (clickEvent) => {
            if (clickEvent.action === "singleClick") {
              //perform shot selection operation
              onTimelineClick(clickEvent);
            }
          },
          onTimelineSeek: (timeValue) => {
            onTimelineSeek(timeValue);
          },
          onTimelineDoubleClick: (clickEvent) => {
            if (clickEvent.action === "doubleClick") {
              //perform version viewer opening!
              handleTimelineDoubleClickEvent(clickEvent);
            }
          },
        }
      );
    }
    return () => {};
  }, [sequencerData.layers]);

  useEffect(() => {
    if (timeline.current) {
      timeline.current.onContentDataUpdate(
        sequencerData.layers,
        sequencerData.shotReps
      );
    }

    return () => {};
  }, [sequencerData.layers, sequencerData.shotReps]);

  useEffect(() => {
    window.addEventListener("resize", function (event) {
      if (canvasRef.current) {
        // canvasRef.current.style.width = window.innerWidth - 345 + "px";
        setCanvasWidth(window.innerWidth - 345);
      }
    });
    setCanvasWidth(window.innerWidth - 345);
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  useEffect(() => {
    if (timeline.current) {
      timeline.current.setCanvasWidth = canvasWidth;
    }
    return () => {};
  }, [canvasWidth]);

  useEffect(() => {
    if (timeline.current) {
      timeline.current.setCurrentTimelineTime = {
        type: "seconds",
        value: currentGlobalTime,
      };
    }
    return () => {};
  }, [currentGlobalTime]);

  return (
    <>
      <div style={{ width: "100%", height: "100%", color: "white" }}>
        <div
          style={{
            height: "300px",
            backgroundColor: "#1B1D28",
            overflow: "hidden",
            width: canvasWidth + "px",
          }}
        >
          <canvas ref={canvasRef} />
        </div>
      </div>
    </>
  );
}
