import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    background: theme.palette.primary.main,
  },
  title: {
    color: theme.palette.text.primary,
    margin: "6px  6px  10px 0",
    fontSize: "24px",
  },
  tasksPageToolsGrid: {
    display: "flex",
    alignItems: "center",
  },

  text: {
    color: theme.palette.text.primary,
    fontSize: "14px",
  },

  iconStyle: {
    color: theme.palette.text.primary,
  },

  checkBoxText: {
    fontFamily: "poppins",
    color: theme.palette.text.primary,
  },

  taskPageButton: {
    // height: "2rem",
    // margin: "0px 10px",
    textTransform: "none",
    height: "40px",
    width: "126px",
    color: theme.palette.text.primary,
    margin: "0px 2px",
    borderRadius: "10px",
  },
  menuitem: {
    backgroundColor: theme.palette.primary.main,
  },

  plusIcon: {
    width: "22px",
    height: "22px",
    color: theme.palette.text.primary,
  },

  tasksPageNewTaskButton: {
    background: "#6C65D9",
    height: "40px",
    width: "115px",
    justifyContent: "flex-start",
    textTransform: "none",
    borderRadius: "7px",
    margin: "0px 4px",
    color: "white",
    "& :hover": {
      color: "white",
    },
  },

  hrElement: {
    margin: "-2px",
    position: "sticky",
    top: "16.2%",
    zIndex: "1",
    marginBottom: "1.7px",
    [theme.breakpoints.down(1250)]: {
      top: "16%",
    },
  },
  sortActive: {
    color: theme.palette.secondary.main,
  },
  sortInactive: {
    color: theme.palette.text.secondary,
  },
  dotBlur: {
    height: "7px",
    width: "7px",
    borderRadius: "50%",
    backgroundColor: "#6C65D9",
    marginLeft: "50%",
    filter: "blur(4px)",
  },
  dot: {
    height: "5px",
    width: "5px",
    borderRadius: "50%",
    backgroundColor: "#6C65D9",
    marginLeft: "50%",
    marginTop: "-5px",
  },
}));

export default useStyles;
