import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  playerContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.secondary.light,
  },
  controlsContainer: {
    height: "17%",
    width: "100%",
    position: "relative",
    marginTop: "-7%",
  },
}));

export default useStyles;
