import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  divContainer: {
    width: "100%",
    fontFamily: "Poppins",
    padding: "3%",
    paddingTop: "0%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  label: {
    color: theme.palette.text.tertiary,
  },
  dateDiv: {
    color: theme.palette.text.tertiary,
    height: "20px",
  },
  dateText: {
    fontWeight: "400",
  },
  statusContainer: {
    height: "20px",
    display: "flex",
    justifyContent: "space-between",
  },
  statusTitle: {
    fontWeight: "300",
    color: theme.palette.text.tertiary,
    marginRight: "12px",
  },
  statusText: {
    fontWeight: "400",
    marginRight: "12px",
  },
  priorityTitle: {
    fontWeight: "300",
    color: theme.palette.text.tertiary,
    marginRight: "12px",
  },
  priorityText: {
    fontWeight: "300",
    marginRight: "12px",
    minWidth: "60px",
  },
  trackableContainer: {
    height: "42px",
    display: "flex",
    alignItems: "center",
  },
  trackableTitle: {
    fontWeight: "300",
    color: theme.palette.text.tertiary,
    marginRight: "12px",
  },
  trackableIcon: {
    height: "18px",
    width: "18px",
    marginRight: "8px",
  },
  trackableThumbnail: {
    borderRadius: "10px",
    objectFit: "cover",
    marginRight: "12px",
  },
  trackableText: {
    fontWeight: "300",
    marginRight: "12px",
  },
  descriptionContainer: {
    height: "100px",
  },
  descriptionTitle: {
    fontWeight: "300",
    color: theme.palette.text.tertiary,
    marginRight: "12px",
  },
  descriptionContent: {
    height: "83px",
    width: "100%",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "5px",
    padding: "8px",
    marginTop: "8px",
  },
}));
