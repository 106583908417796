import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  floatingDiv: {
    position: "fixed",
    top: "155px",
    right: "0px",
    width: "450px",
    height: "83%",
    borderLeft: "1px solid #606479",
    backgroundColor: theme.palette.primary.main,
    overflowX: "hidden",
    overflowY: "scroll",
  },
  folatingDivContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "15px 15px 0px 12px",
  },
  taskNameTitle: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: "500",
  },
  menuDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.secondary.light,
    padding: "0px",
    width: "140px",
    height: "80px",
  },
  editAndCloseButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "95%",
    fontSize: "14px",
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default useStyles;
