import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    breadCrumbLink: {
        color: theme.palette.text.tertiary,
        // fontSize: "20px",
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.text.primary,
        },
        fontFamily: "poppins"
    },
    endBreadCrumb: {
        color: theme.palette.text.primary,
        // fontSize: "20px",
        fontFamily: "poppins"
    },
    separatorIcon: {
        color: theme.palette.text.primary
    }
}));

export default useStyles;
