import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "300px",
    height: "150px",
    position: "absolute",
    right: "3%",
    top: "19vh",
    [theme.breakpoints.down("md")]: {
      top: "15vh",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20vh",
    },
  },
  popoverArrow: {
    zIndex: -1,
    borderRadius: "5px",
    position: "absolute",
    top: "-15px",
    right: "10px",
    borderLeft: "20px solid transparent",
    borderRight: "20px solid transparent",
    borderBottom: `20px solid ${theme.palette.primary.main}`,
    width: "0",
    height: "0",
  },
  card: {
    borderRadius: "20px",
  },
  cardContent: {
    backgroundColor: theme.palette.primary.main,
    padding: "13px 27px",
  },
  title: {
    color: theme.palette.text.primary,
  },
  clear: {
    cursor: "pointer",
    color: theme.palette.text.primary,
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
  },

  textColor: {
    color: theme.palette.text.primary,
  },

  cardAction: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column",
  },

  newTaskButton: {
    color: theme.palette.text.secondary,
    background: theme.palette.secondary.main,
    fontSize: "14px",
    height: "40px",
    width: "100%",
    whiteSpace: "nowrap",
    "& :hover": {
      color: theme.palette.text.primary,
    },
  },
}));

export default useStyles;
