import React, { useState } from "react";
import { Button, Card, CardContent, Select, MenuItem } from "@material-ui/core";

import useStyles from "./MyTaskPageFiltersModal.styles";
import { X } from "react-feather";

export default function MyTaskPageFiltersModal({
  handleTaskFilter,
  closeModal,
  departments,
  projectUsers,
  taskStatus,
  trackableList,
  filtersForTask,
  clearFilter,
  setFiltersForTask,
  setCurrentPage,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div class={classes.popoverArrow}></div>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2 className={classes.title}>Filters</h2>
            <div className={classes.clear} onClick={(e) => closeModal()}>
              <X size="30px" />
            </div>
          </div>
          <div className={classes.cardContentDiv}>
            {/* <div className={classes.datePickerContainer}>
              <div>
                From
                <div className={classes.datePickerDiv}>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      invalidDateMessage={"inValid"}
                      variant="inline"
                      format="DD/MM/yyyy"
                      margin="normal"
                      value={props.selectedStartDate}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      onChange={(date) => props.setSelectedStartDate(date)}
                      id="Startdate-picker"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                        style: {
                          padding: "0px",
                        },
                      }}
                      className={classes.datePickerComponent}
                      size="small"
                      fullWidth={true}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>

              <div>
                To
                <div className={classes.datePickerDiv}>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      invalidDateMessage={"inValid"}
                      variant="inline"
                      format="DD/MM/yyyy"
                      margin="normal"
                      value={props.selectedEndDate}
                      onChange={(date) => props.setSelectedEndDate(date)}
                      id="Enddate-picker"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                        style: {
                          padding: "0px",
                        },
                      }}
                      className={classes.datePickerComponent}
                      size="small"
                      fullWidth={true}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div> */}
          </div>
          <div>
            <label id="demo-simple-select">Department</label>
            <Select
              style={{ padding: "3px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              disableUnderline
              className={classes.datePickerComponent}
              value={filtersForTask.department}
              onChange={(e) =>
                setFiltersForTask({
                  ...filtersForTask,
                  department: e.target.value,
                })
              }
              name="department"
            >
              <MenuItem value="">All Departments</MenuItem>
              {departments &&
                departments.map((eachDepartment) => (
                  <MenuItem value={eachDepartment.id}>
                    {eachDepartment.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div style={{ marginTop: "10px" }}>
            <label id="demo-simple-select">Assigned User</label>
            <Select
              style={{ padding: "3px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              disableUnderline
              className={classes.datePickerComponent}
              value={filtersForTask.assigned_user}
              onChange={(e) =>
                setFiltersForTask({
                  ...filtersForTask,
                  assigned_user: e.target.value,
                })
              }
              name="department"
            >
              <MenuItem value="">All Users</MenuItem>
              {projectUsers &&
                projectUsers.map((eachUser) => (
                  <MenuItem value={eachUser.id}>
                    {eachUser.first_name} {eachUser.last_name}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div style={{ marginTop: "10px" }}>
            <label id="demo-simple-select">Status</label>
            <Select
              style={{ padding: "3px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              disableUnderline
              className={classes.datePickerComponent}
              value={filtersForTask.status}
              onChange={(e) =>
                setFiltersForTask({ ...filtersForTask, status: e.target.value })
              }
              name="status"
            >
              <MenuItem value="">None</MenuItem>
              {taskStatus &&
                taskStatus.map((taskStatus) => (
                  <MenuItem value={taskStatus.id}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: `rgb(${taskStatus?.color})`,
                          borderRadius: "50%",
                          display: "inline-block",
                          marginRight: "10px",
                          height: "15px",
                          width: "15px",
                        }}
                      ></span>
                      <span style={{ width: "80%", overflow: "hidden" }}>
                        {taskStatus.name}
                      </span>
                    </div>
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div style={{ marginTop: "10px" }}>
            <label id="demo-simple-select">Trackable</label>
            <Select
              style={{ padding: "3px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              disableUnderline
              className={classes.datePickerComponent}
              value={filtersForTask.trackable}
              onChange={(e) =>
                setFiltersForTask({
                  ...filtersForTask,
                  trackable: e.target.value,
                })
              }
              name="trackable"
            >
              <MenuItem value="">All Trackables</MenuItem>
              {trackableList &&
                trackableList.map((trackable) => (
                  <MenuItem value={trackable.linked.id}>
                    {trackable.linked.code || trackable.linked.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
        </CardContent>
        <div className={classes.cardAction}>
          <Button
            size="small"
            variant="outlined"
            className={classes.doneButton}
            disabled={
              (filtersForTask.department ||
                filtersForTask.assigned_user ||
                filtersForTask.status ||
                filtersForTask.trackable) === ""
            } //enable only if change occured
            onClick={() => {
              setCurrentPage(1);
              handleTaskFilter();
              closeModal();
            }}
          >
            Done
          </Button>
          <Button
            size="small"
            style={{
              height: "40px",
              marginTop: "0.2rem",
            }}
            onClick={() => {
              clearFilter();
            }}
          >
            <X
              size="20"
              style={{ marginTop: "-0.2rem", paddingRight: "3px" }}
            />
            Clear All
          </Button>
        </div>
      </Card>
    </div>
  );
}
