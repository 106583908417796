import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "20%",
    borderRadius: "20px",
    fontFamily: "poppins",
  },

  card: {
    background: theme.palette.primary.main,
    borderRadius: "20px",
  },

  cardContent: {
    backgroundColor: "#393E46",
  },

  headText: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    fontWeight: "600",
    fontSize: "30px",
    margin: "3px 0px",
  },

  text: {
    fontSize: "16px",
    textAlign: "center",
  },

  buttonDiv: {
    display: "flex",
    padding: "20px 0px 0px 0px",
    alignItems: "center",
    justifyContent: "space-evenly",
  },

  yesButton: {
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    width: "120px",
    borderRadius: "20px",
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.text.primary,
    },
  },
  noButton: {
    height: "40px",
    width: "120px",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "20px",
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
}));

export default useStyles;
