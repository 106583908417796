import React from "react";
import EmptyBoxIcon from "../../Utilities/Svg/EmptyBoxIcon";
import { Button } from "@material-ui/core";
import useStyles from "./ReviewPageNoData.styles";
//icons
import { Plus } from "react-feather";

const ReviewPageNoData = ({
  filterCompleted,
  handleNewReviewModal,
  isReviewVersion,
  isExternalReviewer,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <p style={{ fontSize: "36px", margin: "0px" }}>
        {filterCompleted === "True"
          ? "Nothing here!"
          : "Nothing for review here!"}
      </p>
      <EmptyBoxIcon />
      {!isExternalReviewer && (
        <>
          <p style={{ fontSize: "18px", margin: "0px" }}>
            {filterCompleted === "True"
              ? "Once the files have been done reviewing, they appear here"
              : isReviewVersion
              ? `Add Files for external reviewing by clicking on "Add to Version"`
              : `Add Files for external reviewing by clicking on "Add to Review"`}
          </p>
          {filterCompleted === "False" && (
            <Button
              startIcon={<Plus size={19} />}
              className={classes.addReviewBtn}
              onClick={handleNewReviewModal}
            >
              {isReviewVersion ? "Add to Version" : "Add to Review"}
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default ReviewPageNoData;
