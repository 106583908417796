import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  trackableContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  trackableIcon: {
    marginRight: "12px",
    paddingTop: "8px",
  },
  onHover: {
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
}));
