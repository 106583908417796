import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  table: {
    cursor: "pointer",
    display: "table",
    width: "100%",
    tableLayout: "fixed",
  },
  listItem: {
    padding: "1px",
    overflow: "hidden",
    borderCollapse: "collapse",
    width: "17%",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
  },
  SequenceImageDiv: {
    borderRadius: "10px",
    marginRight: "20px",
  },
  SequenceImage: {
    borderRadius: "8px",
    objectFit: "cover",
  },
  DepartmentsDiv: {
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  },

  textColor: {
    color: theme.palette.text.primary,
  },
  actionIcon: {
    backgroundColor: theme.palette.secondary.main,
  },
  sequencePageModals: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createdBy: {
    display: "flex",
    alignItems: "center",
    border: `1px solid grey`,
    borderRadius: "15px",
  },
}));

export default useStyles;
