import React from "react";
import { useState } from "react";
import ReactPlayer from "react-player";
import VideoController from "../ComparePageVideoController/VideoController";
import useStyles from "./CombineVideoPlayer.styles";

export default function CombineVideoPlayer({
  playerOne,
  playerTwo,
  setPlayerOne,
  setPlayerTwo,
  volumePlayerOne,
  refPlayerOne,
  refPlayerTwo,
  setCurrentDurationOne,
  setCurrentDurationTwo,
  opacityValueOne,
  opacityValueTwo,
  currentDuration,
  onTimeSeek,
  handlePlayPause,
  handlePrevious,
  handleNext,
  onVolumeChange,
  onMute,
  setOpacityValueOne,
  setOpacityValueTwo,
  onRefactor,
  onMuteOne,
  onMuteTwo,
  loopVersions,
}) {
  const classes = useStyles();
  const [showControls, setShowControls] = useState(false);

  return (
    <div
      className={classes.CombinePlayerContainer}
      onMouseEnter={() => setShowControls(true)}
      onMouseLeave={() => setShowControls(false)}
    >
      <div className={classes.playerContainer}>
        <div className={classes.playerContainerOne}>
          <video
            style={{
              height: "100%",
              width: "100%",
              borderRadius: "0px !important",
              opacity: `${opacityValueOne}`,
              position: "absolute",
              top: "0",
              left: "0",
            }}
            src={playerOne.fileURL ? playerOne.fileURL : ""}
            crossOrigin="anonymous"
            ref={refPlayerOne}
            onLoadedData={() => {
              setShowControls(true);
              setPlayerOne({
                ...playerOne,
                totalDuration: refPlayerOne.current.duration,
              });
            }}
            onContextMenu={(e) => e.preventDefault()}
            onTimeUpdate={(e) => {
              setCurrentDurationOne(refPlayerOne.current.currentTime);
            }}
            onEnded={() => loopVersions()}
          />
        </div>

        <div className={classes.playerContainerTwo}>
          <video
            style={{
              height: "100%",
              width: "100%",
              borderRadius: "0px !important",
              opacity: `${opacityValueTwo}`,
              position: "absolute",
              top: "0",
              left: "0",
            }}
            src={playerTwo.fileURL ? playerTwo.fileURL : ""}
            crossOrigin="anonymous"
            ref={refPlayerTwo}
            onLoadedData={() => {
              setShowControls(true);
              setPlayerTwo({
                ...playerTwo,
                totalDuration: refPlayerTwo.current.duration,
              });
            }}
            onContextMenu={(e) => e.preventDefault()}
            onTimeUpdate={(e) => {
              setCurrentDurationTwo(refPlayerTwo.current.currentTime);
            }}
            onEnded={() => loopVersions()}
          />
        </div>
      </div>
      <div className={classes.videoControllerContainer}>
        {showControls ? (
          <VideoController
            isPlaying={playerOne.isPlaying}
            totalDuration={playerOne.totalDuration}
            currentDuration={currentDuration}
            volume={volumePlayerOne.volume}
            muted={volumePlayerOne.muted}
            onTimeSeek={onTimeSeek}
            handlePlayPause={handlePlayPause}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            onVolumeChange={onVolumeChange}
            onMute={(value) => onMute(value)}
            setOpacityValueOne={setOpacityValueOne}
            setOpacityValueTwo={setOpacityValueTwo}
            opacityValueOne={opacityValueOne}
            opacityValueTwo={opacityValueTwo}
            onRefactor={onRefactor}
            type="onTop"
            onMuteOne={onMuteOne}
            onMuteTwo={onMuteTwo}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
