import React from "react";
import useStyles from "./SequencerVersionBrowserOverview.style.js";
import { useState } from "react";
import SequencerVersionBrowserTools from "../SequencerVersionBrowserTools/SequencerVersionBrowserTools.component.jsx";
import SelectedVersion from "../SelectedVersion/SelectedVersion.component.jsx";
import { Plus } from "react-feather";
import NoThumbnail from "../../../Utilities/NoThumbnailVersionViewer.png";
import SequencerVersionBreadcrumb from "../SequencerVersionBreadcrumb/SequencerVersionBreadcrumb.component.jsx";
import { fget } from "../../../../API/callsAPI.js";
import Loader from "../../../Utilities/Loader/Loader.jsx";
import { Button, Tooltip } from "@material-ui/core";

export default function SequencerVersionBrowserOverview({
  sequenceList,
  statusList,
  isLoading,
  setIsLoading,
  projectId,
  onShotAdd,
}) {
  const classes = useStyles();
  const [selected, setSelected] = useState({});
  const [zoom, setZoom] = useState(false);
  const [trackableToShowInBrowser, setTrackableToShowInBrowser] =
    useState("sequence");
  const [shotList, setShotList] = useState([]);
  const [pathnames, setPathnames] = useState([
    { name: "sequence", type: "sequence" },
  ]);

  const handleZoom = () => {
    setZoom(!zoom);
  };

  const handleSelectVersion = (data) => {
    if (data.id === selected.id) {
      setSelected({});
    } else {
      setSelected(data);
    }
  };

  const handleBrowseShots = async (data) => {
    setIsLoading(true);
    setSelected({});
    setTrackableToShowInBrowser("shots");
    setPathnames([
      {
        name: data.code || data.name,
        type: "sequence",
      },
      { name: "shots", type: "shots" },
    ]);

    const url = `trackables/shot/?page=${1}&project=${projectId}&parent_sequence=${
      data.id
    }`;
    try {
      const resShots = await fget({
        url,
      });
      setShotList(resShots.data.results);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleTrackableViewAndBreadcrumbPath = (index) => {
    setSelected({});
    setIsLoading(true);
    const remainingPaths = pathnames.slice(0, index + 1);
    if (remainingPaths.length === 1) {
      setPathnames([{ name: "sequence", type: "sequence" }]);
    } else {
      setPathnames(remainingPaths);
    }
    setTrackableToShowInBrowser(remainingPaths[remainingPaths.length - 1].type);
    setIsLoading(false);
  };

  return (
    <>
      <div>
        <SequencerVersionBrowserTools
          handleZoom={handleZoom}
          zoom={zoom}
          statusList={statusList}
        />
      </div>
      <div className={classes.breadcrumbDiv}>
        <SequencerVersionBreadcrumb
          pathnames={pathnames}
          handleTrackableViewAndBreadcrumbPath={
            handleTrackableViewAndBreadcrumbPath
          }
        />
      </div>
      <div style={{ height: selected.id ? "60%" : "85%" }}>
        {isLoading ? (
          <Loader />
        ) : (
          <div className={classes.sequenceVersions}>
            {trackableToShowInBrowser === "sequence" ? (
              <>
                {sequenceList?.map((sData) => (
                  <SequenceImageViewer
                    key={sData?.id}
                    sData={sData}
                    selected={selected}
                    handleSelectVersion={handleSelectVersion}
                    zoom={zoom}
                    handleBrowseShots={handleBrowseShots}
                    trackableToShowInBrowser={trackableToShowInBrowser}
                  />
                ))}
              </>
            ) : (
              <>
                {shotList?.map((sData) => (
                  <SequenceImageViewer
                    key={sData?.id}
                    sData={sData}
                    selected={selected}
                    handleSelectVersion={handleSelectVersion}
                    zoom={zoom}
                    trackableToShowInBrowser={trackableToShowInBrowser}
                    handleAddClick={() => onShotAdd(sData)}
                  />
                ))}
              </>
            )}
          </div>
        )}
      </div>
      <div>{selected.id ? <SelectedVersion selected={selected} /> : ""}</div>
    </>
  );
}

export const SequenceImageViewer = ({
  handleSelectVersion,
  zoom,
  selected,
  sData,
  handleBrowseShots,
  handleAddClick,
  trackableToShowInBrowser,
}) => {
  const classes = useStyles();

  // function to truncate name
  const stringBreak = (s) => {
    return s.length > 10 ? s.substr(0, 10) + "..." : s;
  };

  return (
    <div style={{ position: "relative" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <img
          onClick={(event) => {
            event.stopPropagation();
            handleSelectVersion(sData);
          }}
          onDoubleClick={(event) => {
            event.stopPropagation();
            if (trackableToShowInBrowser === "sequence") {
              handleBrowseShots(sData);
            }
          }}
          style={{
            borderRadius: "5px",
            cursor: "pointer",
            height: zoom ? "80px" : "55px",
            width: zoom ? "110px" : "80px",
          }}
          src={sData?.thumbnail || NoThumbnail}
          alt=""
          className={selected.id === sData.id ? classes.selectedThumbnail : ""}
        />
        <Tooltip title={sData?.code || sData?.name}>
          <span className={classes.versionName}>
            {stringBreak(sData?.code || sData?.name)}
          </span>
        </Tooltip>
      </div>
      {trackableToShowInBrowser !== "sequence" ? (
        <Button className={classes.plusIcon} onClick={handleAddClick}>
          <Plus size={zoom ? 22 : 17} />
        </Button>
      ) : (
        ""
      )}
    </div>
  );
};
