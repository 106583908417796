import React from "react";
import useStyles from "./StatusSettingTools.style.js";
import AllTrackables from "../../TrackablesAndIcons/TrackablesAndIcons.jsx";
import { Button } from "@material-ui/core";
import * as Icon from "react-feather";
import { ArrowSquareIn } from "phosphor-react";
export default function StatusSettingTools({
  selectedTrackable,
  filterByTrackable,
  handleAddStatusModal,
  handleStatusModal,
}) {
  const classes = useStyles();
  return (
    <div className={classes.divContent1}>
      <div className={classes.divChildContent1}>
        <div>
          <h3 className={classes.statusSettingTitle}>Status Settings</h3>
        </div>
        <div>
          <AllTrackables
            filterByTrackable={filterByTrackable}
            selectedTrackable={selectedTrackable}
          />
        </div>
      </div>
      <div className={classes.divChildContent2}>
        <Button
          className={classes.addStatusButton}
          onClick={() => handleAddStatusModal()}
        >
          <Icon.Plus style={{ marginRight: "6%", marginBottom: "1px" }} />
          Add status
        </Button>
        <Button
          className={classes.importStatusButton}
          onClick={() => handleStatusModal()}
        >
          <ArrowSquareIn
            size={22}
            style={{ marginRight: "6%", marginBottom: "1px" }}
          />
          Import status
        </Button>
      </div>
    </div>
  );
}
