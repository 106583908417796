import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { fget, fpatch } from "../../../../API/callsAPI";

//@MUI
import useStyles from "./ReviewVersionOverview.styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
} from "@material-ui/core";

//@Components
import ErrorOrSuccessMessage from "../../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";
import ReviewTools from "../../../Reviews/ReviewsPageTools/ReviewsPageTool.component";
import ReviewVersionList from "../../ReviewVersionList/ReviewVersionList.component";
import ReviewPageNoData from "../../../Reviews/ReviewPageNoData/ReviewPageNoData";
import CustomPagination from "../../../Utilities/CustomPagination/CustomPagination.component";
import ReviewPageManageReviewModal from "../../../Reviews/ReviewPageModals/ReviewPageManageReviewModal";
import ReviewVersionNewReviewModal from "../../ReviewVersionModals/ReviewVersionNewReviewModal";
import ReviewPageVersionViewerOverview from "../../../Utilities/ReviewPageVersionViewer/ReviewPageVersionViewerOverview/ReviewPageVersionViewerOverview";
import Loader from "../../../Utilities/Loader/Loader";
import { checkImage } from "../../../utils";

function ReviewVersionOverview(props) {
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);
  const [signedUrl, setSignedUrl] = useState("");
  const [isNewReviewVersionModalOpen, setNewReviewVersionModalOpen] =
    useState(false);
  const [isManageReviewerModalOpen, setManageReviewerModalOpen] =
    useState(false);
  const [isReviewVersionTogglerOn, setIsReviewVersionTogglerOn] =
    useState(false);
  const [isVersionViewerOpen, setVersionViewerOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [updateOnPageChange, setUpdateOnPageChange] = useState(0);
  const [orgUsers, setOrgUsers] = useState([]);
  const [projectReviewer, setProjectReviewer] = useState([]);
  const [reviewVersions, setReviewVersions] = useState([]); //review versions
  const [selectedVersion, setSelectedVersion] = useState(); //selected version for version viewer
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false); //for view modal for corners out button
  const [loggedInUser, setLoggedInUser] = useState();
  const [allUsersName, setAllUsersName] = useState([]);
  const [parentReview, setParentReview] = useState({});
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });

  const handleNewReviewVersionModal = () => {
    setNewReviewVersionModalOpen(!isNewReviewVersionModalOpen);
  };

  const handleManageReviewModal = () => {
    setManageReviewerModalOpen(!isManageReviewerModalOpen);
  };

  const handleReviewVersionToggler = () => {
    setIsReviewVersionTogglerOn(!isReviewVersionTogglerOn);
  };

  const handleViewModal = () => {
    setIsViewModalOpen(!isViewModalOpen);
  };

  const handleVersionViewer = (version) => {
    if (version) {
      setSelectedVersion(version);
    }
    setVersionViewerOpen(true);
  };

  const handlePageChange = (pageNo) => {
    setCurrentPage(pageNo);
    setUpdateOnPageChange(updateOnPageChange + 1);
  };

  const updateRefreshCounter = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  const getReviewVersions = async () => {
    setIsLoaded(false);
    try {
      const versionRes = await fget({
        url: `review/${props.params.id}/versions/?page=${currentPage}`,
      });
      if (versionRes.status === 200) {
        setReviewVersions(versionRes.data);
        setIsLoaded(true);
      }
    } catch (error) {
      setIsLoaded(true);
      console.log(error);
    }
  };

  const fetchOrghUsers = async () => {
    try {
      const usersRes = await fget({
        url: `organization/users/`,
      });
      if (usersRes.status === 200) {
        setOrgUsers(
          usersRes.data.results.map((user) => {
            const data = {
              label: user.first_name + " " + user.last_name,
              value: user.id,
              avatar: user?.avatar,
              email: user.email,
            };
            return data;
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getReviewer = async () => {
    try {
      const usersRes = await fget({
        url: `project/${props.params.projectId}/reviewers`,
      });
      if (usersRes.status === 200) {
        setProjectReviewer(
          usersRes.data.results.map((user) => {
            const data = {
              label: user.first_name + " " + user.last_name,
              value: user.id,
              avatar: user?.avatar,
              email: user.email,
            };
            return data;
          })
        );
        setAllUsersName(
          usersRes.data.results.map((user) => {
            const data = {
              name: user.first_name + " " + user.last_name,
              id: user.id,
            };
            return data;
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateVersionStatus = async (versionStatus) => {
    let statusObj = {
      url: `review-version/${selectedVersion.id}/`,
      data: { status: versionStatus },
    };
    try {
      const res = await fpatch(statusObj);
      if (res.status === 200 || 201) {
        updateRefreshCounter();
        return Promise.resolve(res);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const fetchFile = async (id) => {
    const fetchFileRes = await fget({
      url: `reviewversion/${id}/file`,
    });
    setSignedUrl(fetchFileRes.data.signed_url);
  };

  const getLoggedInUser = async () => {
    const accessToken = document.cookie.replace(
      /(?:(?:^|.*;\s*)access\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
    if (accessToken) {
      const userId = JSON.parse(atob(accessToken.split(".")[1])).user_id;
      try {
        const res = await fget({ url: `user/${userId}/` });
        setLoggedInUser(res.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getParentReview = async () => {
    // console.log("the params", props.params.id, props.params.projectId);
    try {
      const res = await fget({
        url: `project/${props.params.projectId}/reviews/${props.params.id}/`,
      });
      if (res.status === 200) {
        setParentReview(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getReviewVersions();
  }, [updateOnPageChange, refreshCounter]);

  useEffect(() => {
    getParentReview();
    if (!props.externalReviewer) {
      fetchOrghUsers();
    }
    getReviewer();
    getLoggedInUser();
  }, []);

  return (
    <div className={classes.root}>
      <ErrorOrSuccessMessage snackValue={snackValue} />
      <ReviewTools
        handleNewReviewModal={handleNewReviewVersionModal}
        handleManageReviewModal={handleManageReviewModal}
        setFilterStatus={() => console.log("inside version")}
        handleReviewToggler={handleReviewVersionToggler}
        isReviewVersion={"yes"}
        isExternalReviewer={props.externalReviewer}
        parentReview={parentReview}
      />
      {isLoaded ? (
        reviewVersions.count !== 0 ? (
          <>
            <TableContainer className={classes.tableContainer}>
              <Table
                stickyHeader
                className={classes.table}
                aria-label="simple table"
              >
                <TableHead className={classes.tableHeader}>
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.headTableCell} align="left">
                      Version Name
                    </TableCell>
                    <TableCell className={classes.headTableCell} align="center">
                      Last Modified
                    </TableCell>
                    {!props.externalReviewer && (
                      <TableCell
                        className={classes.headTableCell}
                        align="center"
                      >
                        Added by
                      </TableCell>
                    )}
                    <TableCell className={classes.headTableCell} align="center">
                      Reviewer
                    </TableCell>
                    <TableCell className={classes.headTableCell} align="center">
                      Comments
                    </TableCell>
                    <TableCell className={classes.headTableCell} align="right">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reviewVersions?.results?.map((review) => {
                    return (
                      <ReviewVersionList
                        isReviewVersionTogglerOn={isReviewVersionTogglerOn}
                        isExternalReviewer={props.externalReviewer}
                        handleVersionViewer={handleVersionViewer}
                        review={review}
                        key={review.id}
                        updateRefreshCounter={updateRefreshCounter}
                        setSnackValue={setSnackValue}
                        fetchFile={fetchFile}
                        handleViewModal={handleViewModal}
                        loggedInUser={loggedInUser}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <footer style={{ bottom: "0", height: "100%" }}>
              <div>
                <CustomPagination
                  handlePageChange={handlePageChange}
                  currentPage={currentPage}
                  totalItem={reviewVersions?.count}
                  totalPage={Math.ceil(reviewVersions?.count / 50)}
                  // setIsNewPageLoading={setIsNewPageLoading}
                  trackable="Review Versions"
                />
              </div>
            </footer>
          </>
        ) : (
          <ReviewPageNoData
            filterCompleted={"False"}
            handleNewReviewModal={handleNewReviewVersionModal}
            isReviewVersion={true}
            isExternalReviewer={props.externalReviewer}
          />
        )
      ) : (
        <Loader />
      )}
      <div>
        <Modal
          className={classes.reviewPageModals}
          open={isNewReviewVersionModalOpen}
          onClose={handleNewReviewVersionModal}
        >
          <ReviewVersionNewReviewModal
            handleNewReviewVersionModal={handleNewReviewVersionModal}
            setSnackValue={setSnackValue}
            reviewId={props.params.id}
            updateRefreshCounter={updateRefreshCounter}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.reviewPageModals}
          open={isManageReviewerModalOpen}
          onClose={handleManageReviewModal}
        >
          <ReviewPageManageReviewModal
            handleManageReviewModal={handleManageReviewModal}
            orgUsers={orgUsers}
            setSnackValue={setSnackValue}
            projectId={props.params.projectId}
            projectReviewer={projectReviewer}
            getReviewer={getReviewer}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.reviewPageModals}
          open={isVersionViewerOpen}
          onClose={() => setVersionViewerOpen(false)}
        >
          <ReviewPageVersionViewerOverview
            onClose={() => setVersionViewerOpen(false)}
            selectedVersion={selectedVersion}
            setSelectedVersion={setSelectedVersion}
            versionList={reviewVersions.results}
            updateVersionStatus={updateVersionStatus}
            projectId={props.params.projectId}
            isExternalReviewer={props.externalReviewer}
            loggedInUser={loggedInUser}
            allUsersName={allUsersName}
            parentReview={parentReview}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.reviewPageModals}
          open={isViewModalOpen}
          onClose={handleViewModal}
        >
          {signedUrl ? (
            checkImage(signedUrl) ? (
              <img
                src={signedUrl}
                className="card-img-top"
                style={{ width: "500px", height: "auto" }}
                alt="asset"
              />
            ) : (
              <video
                src={signedUrl}
                controls
                autoPlay
                style={{ width: "auto", height: "500px" }}
              />
            )
          ) : (
            <span>No file found</span>
          )}
        </Modal>
      </div>
    </div>
  );
}

export default ReviewVersionOverview;

ReviewVersionOverview.propTypes = {
  searchQuery: PropTypes.string,
};
