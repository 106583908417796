import React, { useState } from "react";
import { useEffect } from "react";

import useStyles from "./SequencerPlayer.style";
import SequencerPlayerTitle from "./SequencerPlayerTitle/SequencerPlayerTitle";
import SequencerPlayerSeekbar from "./SequencerPlayerSeekbar/SequencerPlayerSeekbar";
import { getVideoByTime } from "../SequencerUtility/findShots";
import SequencerPlayerV3 from "./SequencerPlayerV3/SequencerPlayerV3";

export default function SequencerPlayerV2({
  elementsCount,
  orderedVideo,
  isPaused,
  seekToTime,
  currentTimeline,
  currentGlobalTime,
  totalDurationTimeline,
  onTimelineSeek,
  onGlobalTimeUpdate,
  setIsPaused,
  handleExpiredSrc,
}) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.titleContainer}>
        <SequencerPlayerTitle
          title={currentTimeline.name || "NaN"}
          lastModified={`${new Date(
            currentTimeline.last_modified
          ).toLocaleDateString()}  ${new Date(
            currentTimeline.last_modified
          ).toLocaleTimeString()}`}
        />
      </div>
      <div className={classes.videoConatiner}>
        <SequencerPlayerV3
          orderedVideo={orderedVideo}
          isPaused={isPaused}
          onGlobalTimeUpdate={onGlobalTimeUpdate}
          seekToTime={seekToTime}
          setIsPaused={setIsPaused}
          handleExpiredSrc={handleExpiredSrc}
        />
      </div>
      <div className={classes.controllerContainer}>
        <SequencerPlayerSeekbar
          isPaused={isPaused}
          totalDurationTimeline={totalDurationTimeline}
          currentGlobalTime={currentGlobalTime}
          onTimelineSeek={onTimelineSeek}
          setIsPaused={setIsPaused}
        />
      </div>
    </>
  );
}
