import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { SequencerStreamer } from "./SequencerStreamer";

export default function SequencerPlayerV3({
  orderedVideo,
  onGlobalTimeUpdate,
  seekToTime,
  isPaused,
  setIsPaused,
  handleExpiredSrc,
}) {
  const containerRef = useRef();
  const sequencerStreamer = useRef();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (containerRef && orderedVideo.length) {
      sequencerStreamer.current = new SequencerStreamer(
        containerRef.current,
        {
          orderedVideo: orderedVideo,
        },
        {
          onGlobalTimeUpdate: (value) => {
            onGlobalTimeUpdate(value);
          },
          onPause: setIsPaused,
          onUrlExpire: handleExpiredSrc,
        }
      );
      setReady(true);
    }
    return () => {};
  }, [containerRef.current, orderedVideo]);

  useEffect(() => {
    if (sequencerStreamer.current) {
      sequencerStreamer.current.seekTotime = seekToTime;
    }
    return () => {};
  }, [seekToTime]);

  useEffect(() => {
    if (sequencerStreamer.current) {
      sequencerStreamer.current.setPlaying = !isPaused;
    }
    return () => {};
  }, [isPaused]);

  return (
    <>
      <div ref={containerRef}></div>
    </>
  );
}
