const CompareIcon = () => {
  return (
    <svg
      width="35"
      height="25"
      viewBox="0 0 35 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="6"
        width="28"
        height="18"
        stroke="#F9F9F9"
        stroke-width="2"
      />
      <rect x="5" width="30" height="20" fill="#F9F9F9" />
    </svg>
  );
};

export default CompareIcon;
