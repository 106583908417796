import { Grid } from "@material-ui/core";
import React from "react";
import useStyles from "./SelectedVersion.style";
import NoThumbnail from "../../../Utilities/NoThumbnailVersionViewer.png";

const SelectedVersion = ({ selected }) => {
  const classes = useStyles();
  return (
    <div className={classes.selectedSequence}>
      <Grid container style={{ justifyContent: "flex-end" }}>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          style={{ display: "flex", alignItems: "center" }}
        >
          <img
            src={selected?.thumbnail || NoThumbnail}
            alt=""
            height="100px"
            width="190px"
            style={{ borderRadius: "10px" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          style={{
            display: "flex",
            // alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className={classes.divFlex}>
            <div>Name: {selected?.name || selected?.code}</div>
            <div>ID: {selected?.id}</div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          style={{
            display: "flex",
            // alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className={classes.divFlex}>
            <div>
              Updated At : {new Date(selected?.updated_at).toLocaleString()}
            </div>
            <div>
              Created by :{" "}
              {selected?.created_by?.first_name +
                " " +
                selected?.created_by?.last_name}
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <p>Duration - {selected.duration}</p>
            <p>Frames - {selected.frames}</p>
            <p>Assets - {selected.shots}</p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SelectedVersion;
