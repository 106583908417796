import React from "react";
import { useEffect } from "react";
import { Route } from "react-router-dom";

//Component overview import from pages folder.
import AssetsPage from "./Pages/AssetsPage/AssetsPage.component";
import AssetVersions from "./Pages/AssetVersions/AssetVersions.component";
import SequencePage from "./Pages/SequencePage/SequencePage.component";
import ShotsPage from "./Pages/ShotsPage/ShotsPage.component";
import ShotVersion from "./Pages/ShotVersions/ShotVersion.Component";
import Departments from "./Pages/DepartmentPage/Department.component";
import PlaylistPage from "./Pages/PlaylistPage/PlaylistPage.component";
import Sequencer from "./Pages/Sequencer/Sequencer.component";
import ReportPageOverview from "./Components/ReportPage/ReportPageOverview/ReportPageOverview.component";
import ReviewersPage from "./Pages/ReviewersPage/ReviewersPage";
import ReviewVersion from "./Pages/ReviewVersion/ReviewVersion";

//inconsistent direct imports.
import TaskComponentOverview from "./Components/MyTasks/TaskPageOverview/TaskPageOverview.component";
import ComparePage from "./Pages/ComparePage/ComparePage.component";
import DepartmentDetail from "./Pages/DepartmentDetails/DepartmentDetail.component";
import Updates from "./Pages/UpdatesPage/Updates.component";
import NotificationsPage from "./Components/Notifications/NotificationsPage.component";
import SettingsPage from "./Pages/SettingsPage/SettingsPage";
import { useState } from "react";
import UnAuthorized from "./Pages/InformativeComponents/UnAuthorized.component";
import ExternalReviewerFallback from "./Pages/InformativeComponents/ExternalReviewerFallback";
import Loader from "./Components/Utilities/Loader/Loader";

//Add all new routes in this component instead of app.js
//this abstracts code out of app.js and prevents conflicts for crossdomain deployments.
export default function RegisterAppRoutes({
  searchQuery,
  showSeqExperimental,
  userRoleForProject,
  isUserInfoLoading,
}) {
  const [allowAllRoutes, setAllowAllRoutes] = useState(true);
  useEffect(() => {
    setAllowAllRoutes(
      Boolean(userRoleForProject === "admin" || userRoleForProject === "user")
    );
    return () => {};
  }, [userRoleForProject]);

  const withUserAccess = (component) => {
    if (isUserInfoLoading) {
      return Loader;
    }
    return allowAllRoutes ? component : ExternalReviewerFallback;
  };

  const playlistPageComponent = (routerParams) => {
    if (allowAllRoutes) {
      return <PlaylistPage {...routerParams} searchQuery={searchQuery} />;
    } else {
      return <UnAuthorized />;
    }
  };

  const ReviewerPageComponent = ({ routerParams }) => {
    return (
      <ReviewersPage
        {...routerParams}
        searchQuery={searchQuery}
        externalReviewer={!allowAllRoutes}
      />
    );
  };

  const ReviewVersionComponent = ({ routerParams }) => {
    return (
      <ReviewVersion
        {...routerParams}
        searchQuery={searchQuery}
        externalReviewer={!allowAllRoutes}
      />
    );
  };

  const ReportPageComponent = (routerParams) => {
    return <ReportPageOverview {...routerParams} />;
  };

  return (
    <>
      <Route exact path="/reports" component={ReportPageComponent} />
      {/* FIX-ME: Component import not consistent */}
      <Route
        exact
        path="/:projectId/tasks"
        component={(routerParams) => (
          <TaskComponentOverview {...routerParams} trackableType="task" />
        )}
      />
      {/* FIX-ME: Component import not consistent */}
      <Route
        exact
        path="/:projectId/updates"
        component={withUserAccess(Updates)}
      />
      {/* FIX-ME: Component import not consistent */}
      <Route exact path="/notifications" component={NotificationsPage} />
      {/* extreviewer routes     */}
      <Route
        exact
        path="/:projectId/review"
        component={(routerParams) => (
          <ReviewerPageComponent routerParams={routerParams} />
        )}
      />
      <Route
        exact
        path="/:projectId/review/:id/version"
        component={(routerParams) => (
          <ReviewVersionComponent routerParams={routerParams} />
        )}
      />

      <Route
        exact
        path="/:projectId/sequence"
        component={withUserAccess(SequencePage)}
      />
      <Route
        exact
        path="/:projectId/playlist"
        component={playlistPageComponent}
      />

      {showSeqExperimental ? (
        <Route
          exact
          path="/:projectId/playlist/:playlistId/sequencer"
          component={withUserAccess(Sequencer)}
        />
      ) : (
        ""
      )}

      <Route
        exact
        path="/:projectId/departments"
        component={withUserAccess(Departments)}
      />
      {/* FIX-ME: Component import not consistent */}
      <Route
        exact
        path="/:projectId/departments/:deptID/department"
        component={withUserAccess(DepartmentDetail)}
      />
      <Route
        exact
        path="/:projectId/sequence/:sid/shots"
        component={withUserAccess(ShotsPage)}
      />
      <Route
        exact
        path="/:projectId/sequence/:sid/shots/:shotID/shotversions"
        component={withUserAccess(ShotVersion)}
      />
      {/* FIX-ME: Component import not consistent */}
      <Route
        exact
        path="/:projectId/sequence/:sid/shots/:shotID/shotversions/:shotVID/compare"
        component={(routerParams) => (
          <ComparePage {...routerParams} trackableType="shot" />
        )}
      />
      <Route
        exact
        path="/:projectId/assets"
        component={withUserAccess(AssetsPage)}
      />
      <Route
        exact
        path="/:projectId/assets/:assetID/assetversions"
        component={withUserAccess(AssetVersions)}
      />
      {/* FIX-ME: Component import not consistent */}
      <Route
        exact
        path="/:projectId/assets/:assetID/assetversions/:avID/assetversions/compare"
        component={(routerParams) => (
          <ComparePage {...routerParams} trackableType="asset" />
        )}
      />
      {/* FIX-ME: Component import not consistent */}
      <Route
        exact
        path="/:projectId/assets/:Id/task"
        component={(routerParams) => (
          <TaskComponentOverview {...routerParams} trackableType="asset" />
        )}
      />
      {/* FIX-ME: Component import not consistent */}
      <Route
        exact
        path="/:projectId/sequence/:sid/shots/:Id/task"
        component={(routerParams) => (
          <TaskComponentOverview {...routerParams} trackableType="shot" />
        )}
      />
      {/* FIX-ME: Component import not consistent */}
      <Route exact path="/:projectId/settings" component={SettingsPage} />
      {/* "if its a valid route disable the below render"     */}
    </>
  );
}
