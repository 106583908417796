import React, { useEffect, useState } from "react";
import { Gantt, ViewMode } from "movie-collab-gantt";
import "movie-collab-gantt/dist/index.css";
import { handleTaskChange, TaskToGanttTasks } from "./TaskEventFunctions";
import TaskGanttNavBar from "../TaskGanttNavBar/TaskGanttNavBar";
import { Button, Menu, Slide, Modal } from "@material-ui/core";
import { Trash, PencilLine, DotsThree, X, ArrowLeft } from "phosphor-react";
import { useLocation } from "react-router";
import TaskGanttViewModalOverview from "../TaskGanttModals/TaskGanttViewModal/TaskGanttViewOverview";
import TaskGanttEditModal from "../TaskGanttModals/TaskGanttEditModal/TaskGanttEditModalOverview/TaskGanttEditModal";
import useStyles from "./TaskGanttOverview.styles";
import DeleteModal from "../../DeleteModal/DeleteModal.component";
import RearrangeIcon from "../../Svg/RearrangeIcon";
import Rearrange from "../../Rearrange/Rearrange";
import { reArrangeTask } from "../../../MyTasks/TaskPageOverview/TaskPageUtilities";
import { useMediaQuery } from "@material-ui/core";

export default function TaskGanttOverview({
  tasksList,
  usersList,
  statusList,
  onTaskUpdate,
  onTaskDateChange,
  viewModeUser,
  externalSaveEvent,
  submitNextField,
  projectUsers,
  projectId,
  departments,
  handleDeleteTask,
  rearrangeTaskOrder,
  onTaskRefresh,
  trackableType,
}) {
  //style
  //checking for screen height at 90% and 100%
  const [height, setHeight] = useState(750);
  const classes = useStyles();
  const location = useLocation();
  const theme = localStorage.getItem("theme");
  //local taskList entire gantt relies on it.
  const [localTasksList, setLocalTasksList] = useState([]);
  const [viewMode, setViewMode] = useState(ViewMode.Day);
  const [selectedTask, setSelectedTask] = useState({});
  const [isDateChanged, setIsDateChanged] = useState(false);

  //modal states
  const [taskModalState, setTaskModalState] = useState(false);
  const [taskModalType, setTaskModalType] = useState("View"); //View or Edit
  const [optionModalAnchor, setOptionModalAnchor] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  //state to trigger useEffect for external save event
  const [saveEventCounter, setSaveEventCounter] = useState(0);
  // state to save the selected task's group
  const [selectedTaskGroup, setSelectedTaskGroup] = useState([]);
  // state to open/close task rearrange modal
  const [isReArrangeModalOpen, setIsReArrangeModalOpen] = useState(false);

  function handleGanttEditModal(e) {
    setTaskModalType("Edit");
  }

  //
  let columnWidth = 65;
  if (viewModeUser) {
    if (viewModeUser === ViewMode.Month) {
      columnWidth = 300;
    } else if (viewModeUser === ViewMode.Week) {
      columnWidth = 250;
    }
  } else {
    if (viewMode === ViewMode.Month) {
      columnWidth = 300;
    } else if (viewMode === ViewMode.Week) {
      columnWidth = 250;
    }
  }

  function TaskToGanttTask(tasksList) {
    var localTasks = [];
    for (let index = 0; index < tasksList.length; index++) {
      let taskObj = {
        id: tasksList[index].id,
        type: "task",
        name: tasksList[index].name,
        start: new Date(tasksList[index].start_time),
        end: new Date(tasksList[index].end_time),
        /**
         * From 0 to 100
         */
        progress: 0,
        isDisabled: false,
        // project: string;
        dependencies: tasksList[index].next || "",
        // hideChildren: boolean;
        displayOrder: index + 1,
        // isChildTask?: boolean;
        taskStatus: tasksList[index].status || {},
        linked: tasksList[index].linked.id,
      };

      localTasks.push(taskObj);
    }
    setLocalTasksList(TaskToGanttTasks(tasksList));
  }

  //component lifecycle
  useEffect(() => {
    TaskToGanttTask(tasksList);
    setIsDateChanged(false);
  }, [tasksList]);

  //when external event occurs run function through useEffect
  useEffect(() => {
    if (saveEventCounter) {
      handleTaskDateChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveEventCounter]);

  useEffect(() => {
    if (externalSaveEvent) {
      externalSaveEvent({
        isDateChanged: isDateChanged,
        triggerSaveEvent: () => setSaveEventCounter(saveEventCounter + 1),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDateChanged]);

  //api functions
  //to handle task date change by dragging.
  const handleTaskDateChange = () => {
    let tasks = localTasksList;
    // eslint-disable-next-line array-callback-return
    //change the end->end_time so that entire object can be sent in payload
    for (let index = 0; index < tasks.length; index++) {
      tasks[index].start_time = tasks[index].start;
      tasks[index].end_time = tasks[index].end;
      tasks[index].color = "decolor";
    }
    onTaskDateChange(tasks.filter((eachTask) => eachTask.type !== "project"));
  };
  //to handle task update from modal.
  const handleTaskSubmit = (editedTask) => {
    //submit the edited object directly
    //dont add next field in the object as it will throw error
    onTaskUpdate(selectedTask.id, editedTask);
    //to update next field there is other type of api call
    //refer departments page to look for next field edit procedure
  };

  //functions to handle gantt events
  const handleTaskChangeLocal = (task) => {
    setIsDateChanged(true);
    const updatedTaskList = handleTaskChange(task, localTasksList);
    setLocalTasksList(updatedTaskList);
  };

  const handleTaskDelete = (task) => {
    const conf = window.confirm("Are you sure about " + task.name + " ?");
    if (conf) {
      setLocalTasksList(localTasksList.filter((t) => t.id !== task.id));
    }
    return conf;
  };

  // const handleProgressChange = async (task) => {
  //   setLocalTasksList(localTasksList.map((t) => (t.id === task.id ? task : t)));
  // };

  const handleDblClick = (task) => {
    const selectedTaskModal = tasksList.filter(
      (eachtask) => eachtask.id === task.id
    );
    if (selectedTaskModal.length) {
      setSelectedTask(selectedTaskModal[0]);
      // getting the selected task's group
      setSelectedTaskGroup(
        tasksList.filter(
          (eachtask) => eachtask.linked?.id === selectedTaskModal[0]?.linked?.id
        )
      );
      setTaskModalType("View");
      setTaskModalState(true);
    }

    // setSelectedTask(task);
  };

  const handleTrackableClick = (task) => {
    if (task.parentSequence === "Asset") {
      location.pathname = `/${projectId}/assets/${task.id}/assetversions`;
      window.open(`${location.pathname}`);
    } else {
      location.pathname = `/${projectId}/sequence/${task.parentSequence}/shots/${task.id}/shotversions`;
      window.open(`${location.pathname}`);
    }
  };

  const handleSelect = (task, isSelected) => {
    console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
  };

  const handleExpanderClick = (task) => {
    setLocalTasksList(localTasksList.map((t) => (t.id === task.id ? task : t)));
  };

  const handleResize = () => {
    let height = window.innerHeight < 990 ? 65 : 72;
    const heightNew = (height * window.innerHeight) / 100;
    setHeight(heightNew);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    //call it initially
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        style={{
          height:
            trackableType === "task"
              ? `calc(100vh - 226px)`
              : `calc(100vh - 256px)`,
          width: `calc(100vw - 180px)`,
          marginTop: "2px",
        }}
      >
        {!viewModeUser ? (
          <div style={{ width: "100%", height: "50px", marginBottom: "20px" }}>
            <TaskGanttNavBar
              onViewChange={(View) => {
                setViewMode(View);
              }}
              onAddMilestone={() => {}}
              onAddNewTask={() => {}}
              onSave={() => {
                handleTaskDateChange();
              }}
              isDateChanged={isDateChanged}
            />
          </div>
        ) : (
          ""
        )}
        <div style={{ width: "100%" }}>
          {localTasksList.length > 0 ? (
            <Gantt
              tasks={localTasksList}
              viewMode={viewModeUser ? viewModeUser : viewMode}
              onDateChange={handleTaskChangeLocal}
              onDelete={handleTaskDelete}
              onDoubleClick={handleDblClick}
              onSelect={handleSelect}
              onExpanderClick={handleExpanderClick}
              onTrackableClick={handleTrackableClick}
              ganttHeight={height}
              listCellWidth={"295px"}
              columnWidth={columnWidth} //if day or month
              themeObject={
                theme === "light"
                  ? {
                      backgroundColor: "#F9F9F9",
                      textColor: "rgba(0, 0, 0, 1)",
                      borderColor: "rgba(96, 100, 121, 1)",
                      milestoneColor: "rgba(236, 236, 236, 1)",
                      todayLineColor: "rgba(108, 101, 217, 1)",
                    }
                  : {
                      backgroundColor: "rgba(27, 29, 40, 1)",
                      textColor: "rgba(249, 249, 249, 1)",
                      borderColor: "rgba(96, 100, 121, 1)",
                      milestoneColor: "rgba(37, 42, 56, 1)",
                      todayLineColor: "rgba(108, 101, 217, 1)",
                    }
              }
              arrowColor="rgba(249, 249, 249, 1)"
            />
          ) : (
            ""
          )}
        </div>
        <Slide direction="left" in={taskModalState}>
          <div
            style={{
              display: taskModalState ? "block" : "none",
            }}
            className={classes.floatingDiv}
          >
            <div
              style={{
                height: "100%",
                padding: "5px",
              }}
            >
              <div className={classes.folatingDivContainer}>
                {taskModalType === "View" ? (
                  <span className={classes.taskNameTitle}>
                    {selectedTask.name || ""}
                  </span>
                ) : (
                  <Button>
                    <ArrowLeft
                      onClick={() => setTaskModalType("View")}
                      size={25}
                      style={{ cursor: "pointer" }}
                    />
                  </Button>
                )}
                <div style={{ marginRight: "5%" }}>
                  {taskModalType === "View" ? (
                    <Button
                      onClick={(event) => {
                        setOptionModalAnchor(event.target);
                      }}
                      style={{ minWidth: "20px" }}
                    >
                      <DotsThree height={20} width={20} />
                    </Button>
                  ) : (
                    <Button onClick={() => setIsReArrangeModalOpen(true)}>
                      <RearrangeIcon />
                    </Button>
                  )}
                  <Button
                    onClick={() => setTaskModalState(false)}
                    style={{ minWidth: "20px" }}
                  >
                    <X height={25} width={25} />
                  </Button>
                </div>
              </div>
              {taskModalType === "View" && selectedTask.id ? (
                <TaskGanttViewModalOverview
                  selectedTask={selectedTask}
                  closeModal={() => setTaskModalState(false)}
                  editModal={handleGanttEditModal}
                  tasksList={tasksList}
                  projectId={projectId}
                  handleDeleteTask={handleDeleteTask}
                />
              ) : (
                <>
                  {taskModalType === "Edit" && selectedTask.id ? (
                    <TaskGanttEditModal
                      statusList={statusList}
                      closeModal={() => setTaskModalState(false)}
                      selectedTask={selectedTask}
                      tasksList={tasksList}
                      onSubmit={(editedTask) => {
                        handleTaskSubmit(editedTask);
                      }}
                      submitNextField={submitNextField}
                      projectUsers={projectUsers}
                      departments={departments}
                    />
                  ) : (
                    ""
                  )}
                </>
              )}
              {/* //open the view modal  */}
            </div>
            <Menu
              anchorEl={optionModalAnchor}
              open={Boolean(optionModalAnchor)}
              onClose={() => setOptionModalAnchor(null)}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              MenuListProps={{
                style: {
                  padding: "0px",
                },
              }}
            >
              <div className={classes.menuDiv}>
                <Button
                  style={{ minWidth: "120px", height: "30px", padding: "4px" }}
                  disabled={taskModalType === "View" ? false : true}
                  onClick={() => {
                    setOptionModalAnchor(null);
                    setTaskModalType("Edit");
                  }}
                >
                  <div className={classes.editAndCloseButton}>
                    <PencilLine size={20} style={{ marginRight: "2px" }} />
                    <span>Edit Task</span>
                  </div>
                </Button>
                <hr style={{ width: "90%", margin: "1px" }} />
                <Button
                  style={{ minWidth: "120px", height: "30px", padding: "4px" }}
                  onClick={() => setDeleteModalOpen(true)}
                >
                  <div className={classes.editAndCloseButton}>
                    <Trash size={20} />
                    <span>Delete Task</span>
                  </div>
                </Button>
              </div>
            </Menu>
          </div>
        </Slide>
      </div>
      {deleteModalOpen && (
        <Modal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          className={classes.modal}
        >
          <DeleteModal
            name={selectedTask.name}
            type="task"
            handleCloseDeleteModal={() => setDeleteModalOpen(false)}
            handleDelete={() => {
              handleDeleteTask(selectedTask.id).then((res) => {
                setTaskModalState(false);
                setOptionModalAnchor(null);
              });
              setDeleteModalOpen(false);
            }}
          />
        </Modal>
      )}
      <Modal
        open={isReArrangeModalOpen}
        onClose={() => setIsReArrangeModalOpen(false)}
        className={classes.modal}
      >
        <Rearrange
          onClose={() => setIsReArrangeModalOpen(false)}
          itemsList={selectedTaskGroup}
          rearrangeTaskOrder={(item) => {
            rearrangeTaskOrder(item);
            onTaskRefresh();
          }}
          tasksOrder={reArrangeTask(selectedTaskGroup)}
        />
      </Modal>
    </>
  );
}
