import React, { useEffect, useRef } from "react";
import { FireBase } from "../firebase/firebaseInitModule.js";

export default function PushNotification() {
  const firebase = useRef(null);

  //to-do: shift  this to app.js later

  useEffect(() => {
    //check env if firebase config is avialable then initiate process.
    let firebaseConfig;
    try {
      firebaseConfig = {
        apiKey: process.env.REACT_APP_FCM_API_KEY,
        authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FCM_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FCM_APPID,
        measurementId: process.env.REACT_APP_FCM_MEASUREMENT_ID,
        vapId: process.env.REACT_APP_FCM_FIREBASE_VAPID,
      };
    } catch (error) {
      console.log("coudnt register firebase due to missing config");
    }
    if (!firebaseConfig) {
      return;
    }

    setTimeout(() => {
      //disabled entry point for firebase untill its figured out!
      const FCM = false;
      if (FCM) {
        firebase.current = new FireBase(firebaseConfig, {
          onFireBaseToken: (tokenData) => {
            console.log("rec new fb token:", tokenData);
          },
        });
      }
    }, 15000);

    return () => {};
  }, []);

  return <></>;
}
