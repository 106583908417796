import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "30%",
  },
  card: {
    borderRadius: "10px",
  },
  cardContent: {
    backgroundColor: theme.palette.primary.main,
    padding: "30px",
  },
  displayFlex: {
    display: "flex",
    justifyContent: "space-between",
  },
  closeIcon: {
    color: theme.palette.text.primary,
    cursor: "pointer",
    marginTop: "3px",
  },
  title: {
    fontSize: "30px",
    fontWeight: "500",
  },

  cardAction: {
    display: "flex",
    justifyContent: "center",
    "& :first-child": {
      backgroundColor: theme.palette.secondary.main,
      height: "40px",
      width: "100px",
      textTransform: "none",
      padding: "4px ",
      borderRadius: "10px",
      color: theme.palette.text.secondary,
    },
  },
  label: {
    marginTop: "20px",
    marginBottom: "15px",
    fontSize: "20px",
    color: theme.palette.text.tertiary,
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  circularProgress: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default useStyles;
