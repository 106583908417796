import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    color: theme.palette.text.primary,
    fontFamily: "Nunito Sans",
  },
  sequencePageToolsGrid: {
    display: "flex",
    alignItems: "center",
  },

  inlineDiv: {
    width: "60%",
    flexWrap: "wrap",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "20px",
  },

  // sequencePageButton: {
  //   textTransform: "none",
  //   height: "2.5rem",
  //   width: "100%",
  //   color: theme.palette.text.primary,
  //   margin: "0px 17px",
  //   "& :first-child": {
  //     color: theme.palette.text.primary,
  //     margin: "8px 1px",
  //   },
  //   "& :last-child": {
  //     margin: "8px",

  //     fontSize: "1.2rem",
  //   },
  // },
  plusIcon: {
    width: "28px",
    height: "19px",
  },
  formControl: {
    alignItems: "center",
    justifyContent: "center",
    width: "250px",
    height: "30px",
    margin: "0px 8px",
    background: theme.palette.secondary.light,
    borderRadius: "4px",
  },

  dropdownDiv: {
    display: "flex",
    width: "250px",
    background: theme.palette.secondary.light,
    borderRadius: "5px",
  },

  selectText: {
    marginLeft: "10px",
    fontFamily: "Nunito Sans",
    opacity: "0.7",
    color: theme.palette.text.primary,
  },

  iconStyle: {
    marginRight: "10px",
    height: "20px",
    color: theme.palette.secondary.main,
  },

  formLabelText: {
    height: "0%",
    width: "100%",
    opacity: "0",
    background: theme.palette.secondary.light,
  },

  sequencePageNewSequenceButton: {
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    width: "auto",
    borderRadius: "10px",
    fontSize: "14px",
    padding: "12px",
    color: theme.palette.text.secondary,
  },
  sliderDiv: {
    display: "flex",
    alignItems: "center",
  },

  slider: {
    width: 200,
    alignItems: "center",
    color: theme.palette.secondary.main,
    height: "0",
  },

  inputSelect: {
    height: "35px",
    display: "flex",
  },
}));

export default useStyles;
