import React from "react";
import ReviewsOverview from "../../Components/Reviews/ReviewsOverview/ReviewsOverview.component";
import PropTypes from "prop-types";

export default function ReviewersPage(props) {
  return (
    <div>
      <ReviewsOverview
        params={props.match.params}
        searchQuery={props.searchQuery}
        externalReviewer={props.externalReviewer}
      />
    </div>
  );
}

ReviewersPage.propTypes = {
  searchQuery: PropTypes.string,
};
