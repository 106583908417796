import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    settingTitle: {
        color: theme.palette.primary,
        fontWeight: "500",
        fontSize: "24px",
      },
      statusSettingTitle: {
        color: theme.palette.text,
        // color:theme.palette.secondary.light,
        margin: "0%",
        padding: "0%",
        fontWeight: "500",
      },
      settingsPageModals: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      statusButton: {
        backgroundColor: theme.palette.secondary.main,
        height: "40px",
        // width: "145px",
    
        textTransform: "none",
        borderRadius: "7px",
        // margin: "0px 4px",
        color: theme.palette.text.secondary,
        "& :hover": {
          color: theme.palette.secondary.main,
        },
      },
      border: {
        color: theme.palette.text.tertiary,
      },
      divContent1: {
        display: "flex",
        direction: "row",
        justifyContent: "space-between",
      },
      divChildContent1: {
        display: "flex",
        direction: "row",
        justifyContent: "space-between",
        alignItems: "center",
      },
      divChildContent2: {
        display: "flex",
        direction: "row",
        justifyContent: "flex-end",
      },
    addStatusButton: {
        backgroundColor: theme.palette.secondary.main,
        height: "40px",
        width:"126px",
        textTransform: "none",
        borderRadius: "10px",
        margin: "0px 4px",
        textAlign:"center",
        fontWeight:"400",
        color: theme.palette.text.secondary,
        "& :hover": {
        color: theme.palette.text.primary,
        },
    },
    importStatusButton: {
        backgroundColor: theme.palette.secondary.main,
        height: "40px",
        width:"160px",
        textTransform: "none",
        borderRadius: "10px",
        margin: "0px 4px",
        textAlign:"center",
        fontWeight:"400",
        color: theme.palette.text.secondary,
        "& :hover": {
        color: theme.palette.text.primary,
        },
    },
  }
));

export default useStyles;
