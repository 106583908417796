import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import { Plus, X } from "phosphor-react";
import React from "react";
import NoThumbnail from "../../Utilities/NoThumbnail.component";
import StatusViewer from "../../Utilities/StatusViewer/StatusViewer";
import VersionIcon from "../../Utilities/Svg/VersionIcon";
import UsersViewer from "../../Utilities/UsersViewer/UsersViewer";
import useStyles from "./ExtendDeadlineModal.style";
import { fpatch } from "../../../API/callsAPI";

const ExtendDeadlineModal = ({
  taskDetails,
  closeModal,
  updateRefreshCounter,
  setSnackValue,
}) => {
  const [selectedDays, setSelectedDays] = React.useState(0);
  const [selectOtherDays, setSelectOtherDays] = React.useState(false);
  const [sentNotification, setSentNotification] = React.useState(true);
  const classes = useStyles();

  const handleTaskUpdate = async () => {
    let updatedTask = {
      url: `trackables/task/${taskDetails.id}/`,
      data: {
        end_time: new Date(
          new Date(
            new Date(taskDetails?.end_time).setDate(
              new Date(taskDetails?.end_time).getDate() + Number(selectedDays)
            )
          )
        ).toISOString(),
      },
    };
    try {
      const updateTaskRes = await fpatch(updatedTask);
      updateRefreshCounter();
      closeModal();
      setSnackValue({
        isOpen: true,
        message: `SuccessFully extended deadline for ${taskDetails?.name}`,
        isError: false,
      });
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `Error on extending deadline of ${taskDetails?.name}`,
        isError: true,
      });
    }
  };
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Box className={classes.flexBetween}>
            <span className={classes.modalName}>Extend Deadline</span>
            <Button onClick={() => closeModal()}>
              <X size={25} />
            </Button>
          </Box>
          <Box className={classes.flexBetween}>
            <div className={classes.titleAndContent}>
              <span className={classes.titleLeft}>Task Name</span>
              <span className={classes.taskName}>
                {taskDetails?.name ?? ""}
              </span>
            </div>
            <div className={classes.titleAndContent}>
              <span className={classes.titleRight}>Current Timeline</span>
              <span className={classes.date}>
                {new Intl.DateTimeFormat("en-US", {
                  day: "2-digit",
                  month: "short",
                }).format(new Date(taskDetails?.start_time))}{" "}
                -{" "}
                {new Intl.DateTimeFormat("en-US", {
                  day: "2-digit",
                  month: "short",
                }).format(new Date(taskDetails?.end_time))}
              </span>
            </div>
          </Box>
          <Box className={classes.flexCenter}>
            <div className={classes.titleAndContent}>
              <span className={classes.titleCenter}>Status</span>
              <StatusViewer status={taskDetails?.status} />
            </div>
          </Box>
          <Box className={classes.flexBetween}>
            <div className={classes.titleAndContent}>
              <span className={classes.titleLeft}>Users</span>
              {taskDetails?.assigned_users?.length ? (
                <UsersViewer
                  assignedUser={taskDetails?.assigned_users}
                  selectedTask={{
                    assigned_users: taskDetails?.assigned_users,
                  }}
                  editable="no"
                />
              ) : (
                <span className={classes.grayText}>- -</span>
              )}
            </div>
            <div className={classes.titleAndContent}>
              <span className={classes.titleRight}>Reviewers</span>
              {taskDetails?.assigned_users?.length ? (
                <UsersViewer
                  assignedUser={taskDetails?.assigned_users}
                  selectedTask={{
                    assigned_users: taskDetails?.assigned_users,
                  }}
                  editable="no"
                />
              ) : (
                <span className={classes.grayText}>- -</span>
              )}
            </div>
          </Box>
          <Box className={classes.flexCenter}>
            <div className={classes.titleAndContent}>
              <span className={classes.titleCenter}>Trackable</span>
              <Box className={classes.flexBetween}>
                <VersionIcon />
                {taskDetails?.linked?.thumbnail ? (
                  <img
                    src={taskDetails?.linked?.thumbnail}
                    className={classes.trackableImage}
                  />
                ) : (
                  <NoThumbnail
                    style={{
                      marginRight: "5px",
                      marginLeft: "10px",
                      height: "28px",
                      width: "28px",
                    }}
                  />
                )}
                <span>{taskDetails?.linked?.name}</span>
              </Box>
            </div>
          </Box>
          <Box>
            <div className={classes.titleAndContent}>
              <span className={classes.grayText}>Choose (No. of Days)</span>
              <Box className={classes.flexBetween}>
                <Button
                  className={
                    selectedDays === 1
                      ? classes.noOfDaysSelected
                      : classes.noOfDays
                  }
                  onClick={() => {
                    setSelectedDays(1);
                    setSelectOtherDays(false);
                  }}
                >
                  <Plus
                    size={18}
                    className={
                      selectedDays === 1 ? classes.whiteText : classes.grayText
                    }
                  />
                  <span>1 day</span>
                </Button>
                <Button
                  className={
                    selectedDays === 2
                      ? classes.noOfDaysSelected
                      : classes.noOfDays
                  }
                  onClick={() => {
                    setSelectedDays(2);
                    setSelectOtherDays(false);
                  }}
                >
                  <Plus
                    size={18}
                    className={
                      selectedDays === 2 ? classes.whiteText : classes.grayText
                    }
                  />
                  <span>2 days</span>
                </Button>
                <Button
                  className={
                    selectedDays === 3
                      ? classes.noOfDaysSelected
                      : classes.noOfDays
                  }
                  onClick={() => {
                    setSelectedDays(3);
                    setSelectOtherDays(false);
                  }}
                >
                  <Plus
                    size={18}
                    className={
                      selectedDays === 3 ? classes.whiteText : classes.grayText
                    }
                  />
                  <span>3 days</span>
                </Button>
                <Button
                  className={
                    selectOtherDays
                      ? classes.noOfDaysSelected
                      : classes.noOfDays
                  }
                  onClick={() => {
                    setSelectedDays(0);
                    setSelectOtherDays(true);
                  }}
                >
                  <Plus
                    size={18}
                    className={
                      selectOtherDays ? classes.whiteText : classes.grayText
                    }
                  />
                  <div className={classes.flexBetween} style={{ gap: "5px" }}>
                    {selectOtherDays ? (
                      <input
                        type="text"
                        inputMode="numeric"
                        autoFocus={selectOtherDays}
                        className={classes.dayInput}
                        value={selectedDays}
                        onChange={(e) => {
                          setSelectedDays(
                            Number(e.target.value.replace(/\D/g, ""))
                          );
                        }}
                      />
                    ) : (
                      <span>_ _ </span>
                    )}
                    <span>days</span>
                  </div>
                </Button>
              </Box>
            </div>
          </Box>
          <Box className={classes.flexCenter}>
            <div className={classes.titleAndContent}>
              <span className={classes.titleCenter}>New Timeline</span>
              <span className={classes.newDate}>
                {new Intl.DateTimeFormat("en-US", {
                  day: "2-digit",
                  month: "short",
                }).format(new Date(taskDetails?.start_time))}{" "}
                -{" "}
                {new Intl.DateTimeFormat("en-US", {
                  day: "2-digit",
                  month: "short",
                }).format(
                  new Date(
                    new Date(taskDetails?.end_time).setDate(
                      new Date(taskDetails?.end_time).getDate() +
                        Number(selectedDays)
                    )
                  )
                )}
              </span>
            </div>
          </Box>
          {/* <Box>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sentNotification}
                    onChange={() => setSentNotification(!sentNotification)}
                  />
                }
                label={
                  <span className={classes.grayText}>
                    Send alerts to assigned users &amp; <span>reviewer</span>
                  </span>
                }
              />
            </FormGroup>
          </Box> */}
          <Box className={classes.flexCenter}>
            <Button
              className={classes.doneButton}
              onClick={() => handleTaskUpdate()}
              disabled={selectedDays > 0 ? false : true}
            >
              Done
            </Button>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export default ExtendDeadlineModal;
