import React from "react";
import useStyles from "./StatusViewer.style.js";
export default function StatusViewer({ status }) {
  const classes = useStyles();
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span>{status ? `${status.name}` : "No Status"}</span>
      <span
        className={classes.statusColor}
        style={{
          backgroundColor: `rgb(${status?.color})`,
        }}
      ></span>
    </div>
  );
}
