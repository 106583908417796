import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: "233px",
    height: "180px",
    backgroundColor: theme.palette.secondary.light,
    border: "none",
    boxShadow: "none",
    padding: "8px",
  },
  cardMedia: {
    height: "123px",
    width: "219px",
  },
  cardContent: {
    height: "57px",
    marginTop: "4px",
    padding: "0px",
    fontSize: "10px",
  },
  cardContentUser: {
    display: "flex",
    alignItems: "center",
    height: "22px",
  },
  userDetail: {
    margin: "10px",
    fontSize: "14px",
    fontFamily: "Poppins",
  },
  annotationDate: {
    textAlign: "right",
    fontSize: "11px",
    fontFamily: "Poppins",
    color: theme.palette.text.tertiary,
    marginTop: "3px",
  },
  pointer: {
    zIndex: 1,
    borderRadius: "5px",
    position: "absolute",
    bottom: "-12px",
    left: "36px",
    borderLeft: "20px solid transparent",
    borderRight: "20px solid transparent",
    borderTop: `20px solid ${theme.palette.secondary.light}`,
    width: "0",
    height: "0",
  },
}));

export default useStyles;
