import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Nunito sans",
    paddingLeft: "25px",
    paddingRight: "25px",
  },
  modal: {
    background: theme.palette.primary.main,
    height: "85%",
    width: "85%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    position: "absolute",
    marginTop: "5%",
    marginLeft: "10%",
    overflowY: "auto",
  },
  modalHead: {
    background: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.palette.secondary.main,
    fontFamily: "Nunito Sans",
  },

  divContainerLeft: {
    display: "flex",
    width: "40%",
    alignItems: "baseline",
  },
  assetName: {
    padding: "4px",
    fontSize: "30px",
    margin: "0px",
  },
  assetType: {
    padding: "4px",
    fontSize: "15px",
    margin: "0px",
    color: "grey",
  },
  icon: {
    color: theme.palette.secondary.main,
  },
  annotateButton: {
    width: "5%",
    borderRadius: "8px",
    padding: "1px",
    marginRight: "14px",
    fontSize: "1.3rem",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.secondary,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.secondary,
    },
  },

  canvasDiv: {
    borderRadius: "10px",
    height: "95%",
    width: "95%",
    padding: "2% 5%",

    background: theme.palette.primary.main,
  },

  ChevronsBtn: {
    width: "5%",
    borderRadius: "8px",
    padding: "1px",
    fontSize: "1.3rem",
    marginRight: "14px",
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
  },
  loader: {
    position: "absolute",
    top: "40%",
    left: "38%",
    height: "75px",
    width: "212px",
    zIndex: "2",
    borderRadius: "20px",
    border: "2px solid white",
    backdropFilter: "blur(5px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    padding: "24px",
  },
}));

export default useStyles;
