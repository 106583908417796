import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  popoverArrow: {
    borderRadius: "5px",
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderBottom: `20px solid ${theme.palette.secondary.light}`,
    borderTop: "3px solid transparent",
    width: "0",
    height: "0",
    transform: "rotate(-90deg)",
  },
  popoverArrowTriangle: {
    borderRadius: "3px",
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderBottom: `20px solid ${theme.palette.secondary.main}`,
    borderTop: "3px solid transparent",
    width: "0",
    height: "0",
    transform: "rotate(-90deg)",
    position: "absolute",
    left: "0.4%",
    zIndex: "-1",
  },
  popoverArrowTriangleList: {
    borderRadius: "3px",
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderBottom: `20px solid ${theme.palette.secondary.main}`,
    borderTop: "3px solid transparent",
    position: "absolute",
    top: "-1.5%",
    left: "11%",
    width: "0",
    height: "0",
    zIndex: "-1",
  },
  popoverArrowList: {
    borderRadius: "5px",
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderBottom: `20px solid ${theme.palette.secondary.light}`,
    borderTop: "3px solid transparent",
    width: "0",
    height: "0",
    zIndex: "1",
    marginBottom: "-3px",
    marginLeft: "11%",
  },
  popoverArrowTriangleTable: {
    borderRadius: "3px",
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderBottom: `20px solid ${theme.palette.secondary.main}`,
    borderTop: "3px solid transparent",
    position: "absolute",
    top: "-1.5%",
    left: "38%",
    width: "0",
    height: "0",
    zIndex: "-1",
  },
  popoverArrowTable: {
    borderRadius: "5px",
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderBottom: `20px solid ${theme.palette.secondary.light}`,
    borderTop: "3px solid transparent",
    width: "0",
    height: "0",
    zIndex: "1",
    marginBottom: "-3px",
    marginLeft: "38%",
  },
  card: {
    borderRadius: "10px",
    backgroundColor: theme.palette.secondary.light,
    maxWidth: "600px",
    minWidth: "385px",
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  onHover: {
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
    maxWidth: "150px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  lighterText: {
    fontFamily: "Poppins",
    color: theme.palette.text.tertiary,
  },
}));

export default useStyles;
