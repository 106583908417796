import React from "react";
import UpdatesOverview from "../../Components/Updates/UpdatesOverview/UpdatesOverview.component";

function Updates(props) {
  return (
    <div>
      <UpdatesOverview project={props.match.params.projectId} />
    </div>
  );
}

export default Updates;
