import { Divider, Grid } from "@material-ui/core";
import { ArrowSquareOut } from "phosphor-react";
import React from "react";
import { Link } from "react-router-dom";

const ReportPageTools = () => {
  return (
    <div>
      <Grid
        container
        style={{
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={4}
          style={{
            fontSize: "24px",
            fontWeight: 500,
          }}
        >
          MovieColab Support
        </Grid>
        <Grid
          item
          xs={4}
          style={{ textAlign: "center", fontSize: "24px", fontWeight: 500 }}
        >
          Report an issue
        </Grid>
        <Grid
          item
          xs={4}
          style={{
            textAlign: "end",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Link
            to="/"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              textDecoration: "none",
              color: "white",
            }}
          >
            <ArrowSquareOut size={20} />
            <span>Go to Home Page</span>
          </Link>
        </Grid>
      </Grid>
      <Divider style={{ marginTop: "5px" }} />
    </div>
  );
};

export default ReportPageTools;
