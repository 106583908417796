import { Button } from "@material-ui/core";
import React, { useState } from "react";
import useStyles from "./CustomPagination.style.js";
import { CaretLeft, CaretRight } from "phosphor-react";

const CustomPagination = ({
  currentPage,
  totalPage,
  totalItem,
  handlePageChange,
  setIsNewPageLoading,
  trackable,
}) => {
  const classes = useStyles();
  const [showPageList, setShowPageList] = useState(false);
  return (
    <div className={classes.root}>
      <span className={classes.showingNumber}>
        Showing {(currentPage - 1) * 50 + 1}
        {"  "}-{"  "}
        {currentPage * 50 >= totalItem ? totalItem : currentPage * 50} of{"  "}
        {totalItem} {trackable}
      </span>
      <div className={classes.buttonsAndPageNumber}>
        <div
          onMouseEnter={() => setShowPageList(true)}
          onMouseLeave={() => setShowPageList(false)}
        >
          <div style={{ display: showPageList ? "block" : "none" }}>
            <div className={classes.pageListContainer}>
              {[...Array(totalPage).keys()].map((page) => {
                return (
                  <div
                    className={
                      currentPage === page + 1
                        ? classes.selectedPageNumber
                        : classes.singlePageNumber
                    }
                    onClick={() => {
                      handlePageChange(page + 1);
                      setIsNewPageLoading(true);
                    }}
                  >
                    <span>{page + 1}</span>
                  </div>
                );
              })}
            </div>
            <div className={classes.triangleContainer}>
              <div className={classes.triangle}></div>
            </div>
          </div>
          <div>
            <span className={classes.gray}>Page : </span>
            {currentPage} out of {totalPage}
          </div>
        </div>

        <div className={classes.nextAndPrev}>
          <Button
            className={classes.previous}
            onClick={() => {
              handlePageChange(currentPage - 1);
              setIsNewPageLoading(true);
            }}
            disabled={currentPage === 1 ? true : false}
          >
            <CaretLeft size={20} />
            <span>Previous</span>
          </Button>
          <Button
            className={classes.next}
            onClick={() => {
              handlePageChange(currentPage + 1);
              setIsNewPageLoading(true);
            }}
            disabled={currentPage === totalPage ? true : false}
          >
            <span>Next</span>
            <CaretRight size={20} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CustomPagination;
