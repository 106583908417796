import { Button, Card, CardActions, CardContent } from "@material-ui/core";
import React from "react";
import { X } from "react-feather";
import useStyles from "./ShotOptionModal.styles";

export default function ShotPageOptionModal({
  setIsCreateShotOptionModalOpen,
  setIsMultiCreateModalOpen,
  setIsNewCreateModalOpen,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div class={classes.popoverArrow}></div>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div
              className={classes.clear}
              onClick={(e) => {
                //props.closeModal()
                setIsCreateShotOptionModalOpen(false);
              }}
            >
              <X size="30px" />
            </div>
          </div>
          <CardActions className={classes.cardAction}>
            <Button
              color="secondary"
              className={classes.newTaskButton}
              onClick={() => {
                setIsNewCreateModalOpen(true);
                setIsCreateShotOptionModalOpen(false);
              }}
            >
              Create New Shot
            </Button>
          </CardActions>
          <CardActions className={classes.cardAction}>
            <Button
              color="secondary"
              className={classes.newTaskButton}
              onClick={() => {
                setIsMultiCreateModalOpen(true);
                setIsCreateShotOptionModalOpen(false);
              }}
            >
              Create Multiple Shots
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </div>
  );
}
