import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  DeptDetailsModals: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  newButton: {
    padding: "0px 4px 0px 0px",
    height: "40px",
    width: "150px",
    textTransform: "none",
    borderRadius: "7px",
  },
  buttonIcon: {
    height: "14px",
  },
  buttonDeleteDepartment: {
    padding: "0px 4px 0px 0px",
    height: "40px",
    marginRight: "20px",
    borderRadius: "6px",
    color: "#D75858",
    border: "1.5px solid #D75858",
  },
  departmentPageModals: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  border: {
    marginTop: "20px",
    color: theme.palette.text.tertiary,
  },
}));

export default useStyles;
