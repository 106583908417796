import { colors } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "red",
    backgroundColor: theme.palette.secondary.light,
    width: "60%",
    height: "55%",
    gap: "7px",
    borderRadius: "10px",
    padding: "18px",
  },
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  reorderGroup:{
    padding:"0%",
    margin:"0%",
    height:"100%",
    overflowY:"auto",
  },
  closeIcon: {
    cursor: "pointer",
    padding:"0% !important"
  },
  title: {
    fontSize: "1.3rem",
    fontWeight: "500",
  },
  rearrangeText:{
    fontWeight:"500"
  },
  content: {
    overflow: "auto",
  },
  button:{
    backgroundColor: theme.palette.secondary.main
  },
  
}));

export default useStyles;
