import React, { useEffect } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  InputAdornment,
} from "@material-ui/core";
import useStyles from "./CommentBox.style.js";
import { useFormik } from "formik";
import * as yup from "yup";
import { ArrowBendDownRight, PaperclipHorizontal, X } from "phosphor-react";
import { useState } from "react";
import UserAvatar from "../UserAvatar/UserAvatar.component.jsx";
import { createRef } from "react";
export default function CommentBox({
  postComment,
  showCircularProgress,
  type,
  loggedInUser,
  repliedTo,
  setRepliedTo,
}) {
  const classes = useStyles();
  const inputRef = createRef();
  const [attachment, setAttachment] = useState();
  const [fileAttached, setFileAttached] = useState(false);

  const getAttachment = (e) => {
    e?.preventDefault();
    let file = e.target.files[0];
    e.target.value = null;
    setAttachment(file);
    setFileAttached(true);
  };

  const handleClearUpload = (e) => {
    setFileAttached(false);
    e?.stopPropagation();
    setAttachment(null);
  };

  const editTaskListValidationScheme = yup.object({
    comment: yup
      .string("String Required")
      .min(1, "Too Short")
      .max(50, "Maximum Character Limit:50")
      .required("This is a required field"),
  });
  const formik = useFormik({
    initialValues: {
      comment: "",
    },
    validationSchema: editTaskListValidationScheme,
  });

  useEffect(() => {
    inputRef.current.focus();
  }, [repliedTo?.id]);

  const submitComment = (e) => {
    postComment(formik.values.comment, attachment);
    formik.values.comment = "";
    handleClearUpload(e);
  };

  return (
    <div className={classes.root}>
      <div className={classes.inputAndSendContainer}>
        <div className={classes.inputFieldAndFileName}>
          {repliedTo?.id ? (
            <div className={classes.flexBetween}>
              <div className={classes.replyingToText}>
                <ArrowBendDownRight size={20} className={classes.text} />
                <span className={classes.text}>Replying to :</span>
                <span>
                  {repliedTo?.author?.first_name} {repliedTo?.author?.last_name}
                </span>
              </div>
              <X
                size={20}
                className={classes.icon}
                onClick={() => setRepliedTo({})}
              />
            </div>
          ) : (
            ""
          )}
          {/* <input type="text" ref={inRef} /> */}
          <TextField
            id="commentBox"
            style={{ height: "40px", overflow: "hidden" }}
            fullWidth
            type="text"
            size="small"
            value={formik.values.comment}
            onChange={formik.handleChange}
            className={classes.commentInput}
            placeholder="Write a Comment"
            InputProps={{
              disableUnderline: true,
              style: { fontSize: "14px", padding: "5px 0px" },
              startAdornment: (
                <InputAdornment position="start">
                  <UserAvatar
                    avatar={loggedInUser?.avatar}
                    firstName={loggedInUser?.first_name}
                    lastName={loggedInUser?.last_name}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <label className={classes.attachmentLabel} for={type}>
                    <PaperclipHorizontal
                      size={20}
                      className={classes.fileInputIcon}
                    />
                  </label>
                </InputAdornment>
              ),
            }}
            inputProps={{
              ref: inputRef,
              onKeyDown: (event) => {
                if (event.key === "Enter") {
                  submitComment(event);
                }
              },
            }}
            name="comment"
            error={formik.touched.comment ? formik.errors.comment : ""}
            helperText={formik.errors.comment ? formik.errors.comment : null}
          />
          {fileAttached ? (
            <div className={classes.fileName}>
              {attachment?.name.length > 30
                ? attachment?.name.slice(0, 30) + "..."
                : attachment?.name}
              <X
                size={20}
                className={classes.closeIcon}
                onClick={(e) => {
                  handleClearUpload(e);
                }}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div>
          <Button
            onClick={(e) => {
              submitComment(e);
            }}
            className={classes.sendButton}
            style={{ float: "right" }}
            disabled={formik.values.comment ? false : true}
          >
            {showCircularProgress ? (
              <CircularProgress color="primary" size={27} />
            ) : (
              "Send"
            )}
          </Button>
        </div>
      </div>
      <input
        type="file"
        accept="image/png, image/jpeg, image/jpg"
        id={type}
        style={{ display: "none" }}
        onChange={(e) => {
          getAttachment(e);
        }}
      />
    </div>
  );
}
