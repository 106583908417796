import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  taskItemDiv: {
    height: "70px",
    minWidth: "233px",
    display: "flex",
    //backgroundColor: "rgba(49, 186, 244, 0.2)",
    marginLeft: "8px",
  },
  taskItemHead: {
    width: "4px",
    height: "100%",
    //backgroundColor: "rgba(49, 186, 244)",
  },
  taskItemTitleContainer: {
    width: "195px",
    height: "20px",
    marginLeft: "6px",
    marginTop: "8px",
  },
  taskItemUserContainer: {
    marginTop: "4px",
    marginLeft: "6px",
    width: "96%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  taskItemUsers: {
    display: "flex",
    flexWrap: "nowrap",
  },
  taskItemAvtaarOne: {
    height: "28px",
    width: "28px",
    textAlign: "center",
    borderRadius: "50%",
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.primary,
  },
  taskItemAvtaar: {
    height: "28px",
    width: "28px",
    textAlign: "center",
    borderRadius: "50%",
    marginLeft: "-14px",
    zIndex: "+1",
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.primary,
  },
}));
