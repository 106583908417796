import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  updatesPageModals: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  commentDiv: {
    fontFamily: "Nunito sans",
    width: "90%",
    paddingRight: "15px",
    overflowX: "hidden",
    overflowY: "auto",
    maxHeight: "65vh",
  },
  modal1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "none",
  },

  commentHead: {
    display: "flex",
    fontFamily: "Nunito sans",
    justifyContent: "space-between",
    alignItems: "center",
  },
  time: {
    color: "grey",
    fontFamily: "Nunito Sans",
    fontSize: "12px",
  },
  AssetImage: {
    borderRadius: "10px",
    cursor: "pointer",
    marginLeft: "50px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconDiv: {
    width: "10%",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    alignItems: "center",
  },
  textLoadMore: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
    textAlign: "center",
  },
  nextCommentLoader: {
    textAlign: "center",
  },

  textColor: {
    color: theme.palette.text.tertiary,
    marginLeft: "40px",
  },
  date: {
    color: theme.palette.primary.light,
    display: "flex",
    gap: "5px",
  },
  avatar: {
    height: "40px",
    width: "40px",
    textAlign: "center",
    borderRadius: "50%",
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.primary,
    fontWeight: "400 !important",
    fontSize: "15px",
  },
  userImage: {
    objectFit: "cover",
    height: "40px",
    width: "40px",
    borderRadius: "50%",
  },
}));

export default useStyles;
