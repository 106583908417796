import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import {
  ArrowBendDownRight,
  FilmSlate,
  PaperPlaneTilt,
  X,
} from "phosphor-react";
import React from "react";
import { Edit } from "react-feather";
import ViewComments from "../../CommentBox/ViewComments/ViewComments";
import StatusDropDown from "../../StatusDropDown/StatusDropDown.component";
import UsersViewer from "../../UsersViewer/UsersViewer";
import useStyles from "./LinkedTaskAndComments.style";
import { useFormik } from "formik";
import * as yup from "yup";
import { fget, fpost } from "../../../../API/callsAPI";
import PriorityViewer from "../../PriorityViewer/PriorityViewer.component";
import { useEffect } from "react";
import { useState } from "react";
import UserAvatar from "../../UserAvatar/UserAvatar.component";
import { createRef } from "react";

const LinkedTaskAndComments = ({
  linkedTask,
  taskComments,
  taskStatusList,
  projectId,
  updateLinkedTaskAndComments,
  loggedInUser,
  selectedVersion,
}) => {
  const classes = useStyles();
  const inputRef = createRef();
  const [currentUser, setCurrentUser] = useState({});
  const [repliedTo, setRepliedTo] = useState({});
  const [showReplay, setShowReplay] = useState(null);
  const [isCommentPosting, setIsCommentPosting] = useState(false);
  const [linkVersion, setLinkVersion] = useState(true);
  //Validators
  const CommentsValidationScheme = yup.object({
    comments: yup
      .string("String required")
      .label("comments")
      .min(1, "Too Short!")
      .max(100, "Maximum Character Limit: 50")
      .required(),
  });
  const formik = useFormik({
    initialValues: {
      comments: "",
    },
    validationSchema: CommentsValidationScheme,
    onSubmit: async (values) => {
      let formData = new FormData();
      formData.append("name", linkedTask.name);
      formData.append(
        "description",
        linkVersion
          ? `<version>${selectedVersion?.name}<version> ${values.comments}`
          : values.comments
      );
      formData.append("task", linkedTask.id);
      formData.append("id", projectId);
      if (repliedTo?.id) {
        formData.append("replied_to", repliedTo?.id);
      }

      let data = {
        url: `trackables/task-comment/`,
        data: formData,
      };
      try {
        setIsCommentPosting(true);
        const res = await fpost(data);
        if (res.status === 200 || 201) {
          formik.values.comments = "";
          updateLinkedTaskAndComments();
          setShowReplay(repliedTo?.id);
          setRepliedTo({});
          setIsCommentPosting(false);
        }
      } catch (err) {
        console.log(err);
        setRepliedTo({});
        setIsCommentPosting(false);
      }
    },
  });

  useEffect(() => {
    getLoggedInUser();
  }, [projectId]);

  const getLoggedInUser = async () => {
    try {
      const usersRes = await fget({
        url: `project/${projectId}/users/`,
      });
      setCurrentUser(
        usersRes?.data?.results?.find((res) => res.id === loggedInUser?.user_id)
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    inputRef.current.focus();
  }, [repliedTo?.id]);

  return (
    <div
      className={classes.root}
      style={{
        height: "95%",
        // height: matchHeight ? "calc(100% - 107px)" : "calc(100% - 97px)",
      }}
    >
      <div
        style={{
          height: "50%",
        }}
      >
        <div className={classes.header}>
          <span className={classes.title}>Task Details</span>
          <span className={classes.taskName}>{linkedTask?.name}</span>
          <Button disabled>
            <Edit />
          </Button>
        </div>
        <div className={classes.dateAndPriority}>
          <div className={classes.flexColumn}>
            <span className={classes.date}>
              {new Intl.DateTimeFormat("en-US", {
                day: "2-digit",
                month: "short",
              }).format(new Date(linkedTask?.start_time))}{" "}
              -{" "}
              {new Intl.DateTimeFormat("en-US", {
                day: "2-digit",
                month: "short",
              }).format(new Date(linkedTask?.end_time))}
            </span>
            <div className={classes.fieldName}>Duration</div>
          </div>
          <div className={classes.flexColumn}>
            <PriorityViewer priority={linkedTask?.priority} />
            <div className={classes.fieldName}>Priority</div>
          </div>
        </div>
        <div className={classes.statusContainer}>
          <StatusDropDown
            statusList={taskStatusList}
            currStatus={linkedTask?.status}
            updateRefreshCounter={updateLinkedTaskAndComments}
            url={`trackables/task/${linkedTask?.id}/`}
          />
          <div className={classes.fieldName}>Status</div>
        </div>
        <div className={classes.userAndDepartment}>
          <div className={classes.flexColumn}>
            <UsersViewer
              assignedUser={linkedTask?.assigned_users}
              selectedTask={linkedTask}
              editable="no"
            />
            <div className={classes.fieldName}>Users</div>
          </div>
          <div className={classes.flexColumn}>
            <div>{linkedTask?.department?.name}</div>
            <div className={classes.fieldName}>Department</div>
          </div>
        </div>
      </div>
      <Divider className={classes.divider} />
      <div
        style={{
          height: repliedTo?.id ? "32%" : "37%",
          overflowY: "auto",
          padding: "20px",
        }}
      >
        <ViewComments
          comments={taskComments}
          loggedInUser={currentUser}
          updateCommentsRefreshCounter={updateLinkedTaskAndComments}
          setRepliedTo={setRepliedTo}
          showReplay={showReplay}
          setShowReplay={setShowReplay}
        />
      </div>
      <div>
        {repliedTo?.id ? (
          <div className={classes.flexBetween}>
            <div className={classes.replyingToText}>
              <ArrowBendDownRight size={20} className={classes.text} />
              <span className={classes.text}>Replying to :</span>
              <span>
                {repliedTo?.author?.first_name} {repliedTo?.author?.last_name}
              </span>
            </div>
            <X
              size={20}
              className={classes.icon}
              onClick={() => setRepliedTo({})}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <form enctype="multipart/form-data" onSubmit={formik.handleSubmit}>
        <div
          style={{
            display: "flex",
          }}
        >
          <div className={classes.submitCommentField}>
            <div className={classes.linkTaskOption}>
              <FilmSlate size={18} />
              <span>Link Version</span>
              <Checkbox
                size="small"
                style={{ padding: "2px" }}
                checked={linkVersion}
                onChange={() => setLinkVersion(!linkVersion)}
              />
            </div>
            <TextField
              type="text"
              id="comments"
              placeholder={"Type a Comment..."}
              fullWidth
              name="comments"
              value={formik.values.comments}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.comments)}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <UserAvatar
                      avatar={currentUser?.avatar}
                      firstName={currentUser?.first_name}
                      lastName={currentUser?.last_name}
                    />
                  </InputAdornment>
                ),
                /* endAdornment: (
                  <InputAdornment position="end">
                    <label className={classes.attachmentLabel} for={type}>
                      <PaperclipHorizontal
                        size={20}
                        className={classes.fileInputIcon}
                      />
                    </label>
                  </InputAdornment>
                ), */
              }}
              inputProps={{
                ref: inputRef,
              }}
              /* helperText={
                formik.errors.comments ? formik.errors.comments : null
              } */
            />
          </div>
          <Button
            type="submit"
            className={classes.submitButton}
            disabled={isCommentPosting ? true : false}
          >
            {isCommentPosting ? (
              <CircularProgress color="primary" size={27} />
            ) : (
              <PaperPlaneTilt size={22} />
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default LinkedTaskAndComments;
