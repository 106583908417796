import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  selectedSequence: {
    position: "absolute",
    bottom: "0px",
    width: "96%",
    overflowY: "hidden",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "10px",
    margin: "5px 0px",
    paddingLeft: "5px",
  },
  divFlex: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  selectedThumbnail: {
    border: `3px solid ${theme.palette.secondary.main}`,
  },
}));

export default useStyles;
