import { Button } from "@material-ui/core";
import React from "react";
import { useState } from "react";

export default function MediaError({ additionalText, error, file }) {
  const [showError, setShowError] = useState(false);
  return (
    <>
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#000000",
        }}
      >
        <span style={{ color: "red" }}>MediaError</span>
        <span>{additionalText || ""}</span>
        <br />
        <span>
          "Check browser console for error details, or contact Support."
        </span>

        {error ? (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "50%",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  style={{
                    margin: "15px",
                    border: "1px solid white",
                    borderRadius: "10px",
                  }}
                  onClick={() => setShowError(!showError)}
                >
                  {showError ? "Hide" : "Show"} Error
                </Button>
                {file && (
                  <Button
                    style={{
                      margin: "15px",
                      borderRadius: "10px",
                      backgroundColor: "#6C65D9",
                    }}
                    onClick={() => (window.location.href = file)}
                  >
                    Download File
                  </Button>
                )}
              </div>
              <div
                style={{
                  width: "700px",
                  height: "150px",
                  overflowX: "hidden",
                  overflowY: "scroll",
                  color: "yellow",
                  marginTop: "40px",
                  fontWeight: "200",
                }}
              >
                <span style={{ margin: "2px" }}>
                  {error && showError
                    ? error
                        .toString()
                        .split("\n")
                        .map((each, i) => `${i}: ${each}`)
                    : ""}
                </span>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
