import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Paperclip } from "phosphor-react";
import React from "react";
import { useState } from "react";
import { X } from "react-feather";
import UserAvatar from "../../Utilities/UserAvatar/UserAvatar.component";
import useStyles from "./ViewAllReportsModal.style";

const ViewAllReportsModal = ({ reportsList, handleClose }) => {
  const classes = useStyles();
  const [selectedIssue, setSelectedIssue] = useState(reportsList[0]);
  const [viewAttachment, setViewAttachment] = useState({
    isModalOpen: false,
    image: "",
  });

  const handleSelectedIssue = (issue) => {
    if (issue.id === selectedIssue.id) {
      setSelectedIssue({});
    } else {
      setSelectedIssue(issue);
    }
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div className={classes.titleAndClose}>
            <div className={classes.reportIssueTitle}>Reported Issues</div>
            <Button onClick={handleClose}>
              <X size={20} />
              Close
            </Button>
          </div>
          <Divider className={classes.divider} />
          <Grid container spacing={2} style={{ padding: "5px 20px 20px 20px" }}>
            <Grid
              item
              md={selectedIssue?.id ? 8 : 12}
              style={{ display: "flex" }}
            >
              <TableContainer className={classes.tableContainer}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead className={classes.tableHeader}>
                    <TableRow className={classes.tableRow}>
                      <TableCell className={classes.headTable} align="left">
                        Issue Name
                      </TableCell>
                      <TableCell className={classes.headTable} align="center">
                        Submitted on
                      </TableCell>
                      <TableCell className={classes.headTable} align="right">
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBody}>
                    {reportsList?.map((report) => (
                      <TableRow
                        key={report?.id}
                        className={classes.bodyRow}
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            selectedIssue?.id === report?.id ? "#252A38" : "",
                        }}
                        onClick={() => handleSelectedIssue(report)}
                      >
                        <TableCell className={classes.issueName}>
                          {report?.subject}
                        </TableCell>
                        <TableCell className={classes.issueDate} align="center">
                          <span>
                            {new Intl.DateTimeFormat("en-US", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            }).format(new Date(report?.created_at))}
                          </span>
                        </TableCell>
                        <TableCell className={classes.statusCell} align="right">
                          <div className={classes.statusContainer}>
                            <div className={classes.statusDiv}>
                              <span className={classes.statusName}>
                                {report?.isResolved
                                  ? "Resolved"
                                  : "In Progress"}
                              </span>
                              {report?.isResolved ? (
                                <span
                                  className={classes.statusColor}
                                  style={{
                                    backgroundColor: `#F49A31`,
                                  }}
                                ></span>
                              ) : (
                                <span
                                  className={classes.statusColor}
                                  style={{
                                    backgroundColor: `#2DAD00`,
                                  }}
                                ></span>
                              )}
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div
                className={classes.line}
                style={{ display: selectedIssue.id ? "block" : "none" }}
              ></div>
            </Grid>
            {selectedIssue?.id ? (
              <Grid item md={selectedIssue?.id ? 4 : 0}>
                <div style={{ fontSize: "25px", fontWeight: 600 }}>
                  {selectedIssue?.subject}
                </div>
                <div>
                  <div style={{ marginTop: "20px" }}>
                    <div
                      className={classes.avatarAndDate}
                      style={{ marginLeft: "-3px" }}
                    >
                      {/* <Avatar className={classes.avatar}>
                      {selectedIssue?.user?.first_name[0]}
                      {selectedIssue?.user?.last_name[0]}
                    </Avatar> */}
                      <UserAvatar
                        avatar={selectedIssue?.user?.avatar}
                        firstName={selectedIssue?.user?.first_name}
                        lastName={selectedIssue?.user?.last_name}
                      />
                      <span className={classes.textGray}>
                        {new Intl.DateTimeFormat("en-US", {
                          day: "2-digit",
                          month: "long",
                          year: "numeric",
                        }).format(new Date(selectedIssue?.created_at))}
                      </span>
                    </div>
                    <div className={classes.lineAndTexts}>
                      <div className={classes.line2}></div>
                      <div className={classes.attachmentAndDescription}>
                        {selectedIssue?.attachment_name ? (
                          <div
                            className={classes.attachment}
                            onClick={() =>
                              setViewAttachment({
                                isModalOpen: true,
                                image: selectedIssue?.file,
                              })
                            }
                          >
                            <Paperclip size={20} />
                            <span>
                              {selectedIssue?.attachment_name?.slice(0, 20)}
                              {selectedIssue?.attachment_name?.length > 20
                                ? "..."
                                : ""}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                        <span className={classes.textGray}>
                          {selectedIssue?.description}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <div className={classes.avatarAndDate}>
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 73 49"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19 44.9474V15.1053L36.5 32.421L54 15.1053V44.9474"
                          stroke="#6C65D9"
                          stroke-width="9.57895"
                        />
                        <path
                          d="M5 0V49"
                          stroke="#6C65D9"
                          stroke-width="9.57895"
                        />
                        <path
                          d="M68 0V49"
                          stroke="#6C65D9"
                          stroke-width="9.57895"
                        />
                      </svg>
                      <span className={classes.textGray}>
                        {new Intl.DateTimeFormat("en-US", {
                          day: "2-digit",
                          month: "long",
                          year: "numeric",
                        }).format(new Date(selectedIssue?.created_at))}
                      </span>
                    </div>
                    <div style={{ marginLeft: "40px", marginTop: "10px" }}>
                      <div
                        style={{
                          fontSize: "25px",
                          fontWeight: 600,
                          marginBottom: "15px",
                        }}
                      >
                        We received your report
                      </div>
                      <span className={classes.textGray}>
                        Case Number :{" "}
                        <span className={classes.issueId}>
                          {selectedIssue?.id}
                        </span>{" "}
                        We will work on your issue and come back with the right
                        solution for you soon. <br /> Thank you,{" "}
                        {selectedIssue?.user?.first_name}{" "}
                        {selectedIssue?.user?.last_name}, for reporting this!
                      </span>
                    </div>
                  </div>
                </div>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </CardContent>
      </Card>
      <div className={classes.bottomCardContainer}>
        <Card className={classes.card2}>
          <CardContent className={classes.cardContent2}>
            <div className={classes.tanksText}>
              We at <span className={classes.colorChange}>MovieColab</span>
              {"  "}
              are constantly working to make your work flow as smooth as
              possible. <br />
              <br />
              <span className={classes.colorChange}>Thank you</span> for
              reporting the issues, You can find your issues being resolved in
              the section above. :)
            </div>
          </CardContent>
        </Card>
      </div>
      <Modal
        open={viewAttachment?.isModalOpen}
        onClose={() => setViewAttachment({ isModalOpen: false, image: "" })}
        className={classes.modal}
      >
        <div className={classes.imageViewer}>
          <div style={{ textAlign: "end" }}>
            <Button
              onClick={() =>
                setViewAttachment({ isModalOpen: false, image: "" })
              }
            >
              <X />
            </Button>
          </div>
          <img src={viewAttachment?.image} alt="" className={classes.image} />
        </div>
      </Modal>
    </div>
  );
};

export default ViewAllReportsModal;
