import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    color: theme.palette.text.primary,
    margin: "5px 10px",
  },
  assetPageToolsGrid: {
    display: "flex",
    alignItems: "center",
  },
  titleAndSiderContainer: {
    display: "flex",
    alignItems: "center",
    width: "40%",
  },

  plusIcon: {
    width: "25px",
    height: "15px",
  },

  iconStyle: {
    marginRight: "10px",
    height: "20px",
    color: theme.palette.secondary.main,
  },
  formLabelText: {
    padding: "0.2px 6px",
    height: "0%",
    width: "100%",
    opacity: "0",
    background: theme.palette.secondary.light,
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
  },

  NewDepartmentButton: {
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    fontWeight:"400",
    padding:"0px 7px 0px 0px",
    justifyContent: "flex-start",
    textTransform: "none",
    borderRadius: "7px",
    margin: "0px 4px",
    color: theme.palette.text.secondary,
    "& :hover": {
      color: theme.palette.secondary.main,
    },
  },
}));

export default useStyles;
