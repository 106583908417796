import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  title: {
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "5px",
  },
  filterButtons: {
    height: "7%",
    backgroundColor: "#1B1D28",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "10px",
    padding: "0px 10px 10px 10px",
    gap: "15px",
  },

  button: {
    fontSize: "12px",
    borderRadius: "10px",
    color: theme.palette.text.tertiary,
  },
  selectedButton: {
    backgroundColor: theme.palette.secondary.main,
    fontSize: "12px",
    borderRadius: "10px",
  },
  icon: {
    marginRight: "5px",
  },

  //common styles
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
