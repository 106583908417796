import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  viewProject: {
    cursor: "pointer",
    color: theme.palette.secondary.main,
    fontFamily: "poppins",
    padding: "0px 0px 3px 0px",
  },
  textLink: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
  },
  text: {
    color: theme.palette.text.primary,
    textDecoration: "none",
    padding: "0px 0px 3px 0px",
  },
  trashIcon: {
    color: theme.palette.text.primary,
    width: "30px",
    height: "45px",
    paddingRight: "10px",
  },
  archiveIcon: {
    color: theme.palette.text.primary,
    width: "30px",
    height: "45px",
    paddingRight: "5px",
  },
  trashIcon1: {
    color: theme.palette.text.primary,
    width: "30px",
    height: "45px",
    paddingRight: "10px",
  },

  menuPop: {
    margin: "0px",
    backgroundColor: theme.palette.secondary.light,
  },
  userRole: {
    border: `1px solid ${theme.palette.text.tertiary}`,
    display: "inline-flex",
    alignItems: "center",
    gap: "10px",
    padding: "0px 10px",
    borderRadius: "20px",
    cursor: "pointer",
  },
  userIcon: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "50%",
    padding: "3px 6px",
    marginLeft: "-11px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  userRoleText: {
    backgroundColor: theme.palette.secondary.light,
    padding: "4px 6px",
    borderRadius: "5px",
    border: `1px solid ${theme.palette.secondary.main}`,
    zIndex: 9999,
    position: "absolute",
    width: "450px",
  },

  modalButton: {
    width: "180px",
    height: "45px",
    borderRadius: "15px",
    borderColor: theme.palette.secondary.light,
    background: theme.palette.secondary.light,
    fontFamily: "Nunito sans",
    textAlign: "center",
    color: theme.palette.text.primary,
  },
}));

export default useStyles;
