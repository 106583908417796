import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
  },
  tableContainer: {
    width: "100%",
    marginTop: "5px",
  },
  table: {
    width: "100%",
  },
  tableHeader: {
    background: theme.palette.primary.main,
    width: "100%",
  },
  tableRow: {
    borderCollapse: "collapse",
    width: "100%",
  },
  tableBody: {
    display: "block",
    overflowY: "auto",
    [theme.breakpoints.down(1205)]: {
      height: `calc(100vh - 330px)`,
    },
    [theme.breakpoints.down(801)]: {
      height: `calc(100vh - 360px)`,
    },
  },

  tooglerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  versionsTab: {
    textTransform: "none",
    minWidth: "40px",
    margin: "0px 10px",
  },
  taskTab: {
    textTransform: "none",
    minWidth: "40px",
  },

  shotVersionPageModals: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  shotVersionTableCell: {
    padding: "1.3% 0% !important",
    color: theme.palette.text.tertiary,
    backgroundColor: theme.palette.primary.main,
    zIndex: "3",
    // borderCollapse: "collapse",
    width: "16%",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
  },
  shotVersionTableCellTwo: {
    padding: "1.3% 0% !important",
    color: theme.palette.text.tertiary,
    backgroundColor: theme.palette.primary.main,
    zIndex: "3",
    // borderCollapse: "collapse",
    width: "10%",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
  },
  shotVersionTableCellThree: {
    padding: "1.3% 1% 1.3% 0% !important",
    color: theme.palette.text.tertiary,
    backgroundColor: theme.palette.primary.main,
    zIndex: "3",
    // borderCollapse: "collapse",
    width: "10%",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
  },
  modal: {
    background: theme.palette.primary.main,
    height: "85%",
    width: "85%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    position: "absolute",
    marginTop: "5%",
    marginLeft: "10%",
    overflowY: "auto",
  },
  modalHead: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.palette.secondary.main,
    fontFamily: "poppins",
  },
  canvasDiv: {
    borderRadius: "10px",
    height: "95%",
    width: "95%",
    padding: "2% 5%",
    background: theme.palette.primary.main,
  },
}));

export default useStyles;
