import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  reloadbtn: {
    height: "45px",
    width: "97px",
    background: theme.palette.secondary.main,
    marginTop: "60px",
  },
  errorMessage: {
    fontSize: "24px",
    fontFamily: "poppins",
    color: theme.palette.text.tertiary,
  },
  alertCircle: {
    height: "60px",
    width: "60px",
    color: theme.palette.text.tertiary,
    marginRight: "25px",
  },
  errorContainer: {
    display: "flex",
    alignItems: "center",
  },
  errorMessageBox: {
    position: "relative",
    paddingTop: "56.25%",
    backgroundColor: "#000000",
  },
}));

export default useStyles;
