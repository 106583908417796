import React, { useState, useEffect } from "react";

//@MUI
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Input,
  TextField,
  Modal,
  Grid,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import { CirclePicker } from "react-color";
//@CSS
import useStyles from "./CreateNewStatusModal.styles";
//Validators
import { useFormik } from "formik";
import * as yup from "yup";
//
import { Icons } from "./Icons";
import { fget, fpost } from "./../../../API/callsAPI";
import { FormControlLabel, Checkbox, Box } from "@material-ui/core";
import { X } from "react-feather";
import { Asterisk } from "phosphor-react";
import HelperText from "../HelperText/HelperText";
//set of predefined colors for status
const colour = [
  "#f44336",
  "#e91e63",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#2196f3",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#4caf50",
  "#8bc34a",
  "#cddc39",
  "#ffeb3b",
  "#ffc107",
  "#ff9800",
  "#ff5722",
  "#795548",
  "#607d8b",
  "#FF8888",
  "#FFE5E5",
  "#FFC1C1",
  "#FFC259",
  "#A09B6C",
  "#B1DD27",
  "#7EFE00",
  "#4DFFDB",
  "#5FD3C0",
  "#AB94DC",
  "#FF4DDB",
  "#F4939D",
];
/**
 * Returns create status modal,
 * pass trackableType if opened from any trackable's page and want default trackable selected
 * @param {string} trackableType asset, assetversion....
 */

const CreateNewStatusModal = (props) => {
  const classes = useStyles();

  const [statusClassChoice, setStatusClassChoice] = useState([]);
  const [icon, setIcon] = useState("fa fa-glass");
  const [viewIconModal, setViewIconModal] = useState(false);
  const [iconFilter, setIconFilter] = useState("");
  const [color, setcolor] = useState({ r: 244, g: 67, b: 54, a: 1 });
  const [statusClass, setStatusClass] = useState([]); //autocomplete controlled comp. value
  const [defaultClass, setDefaultClass] = useState([]); //default value to render in AutoComplete should have atleast 1 element
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });

  //Select Type default value
  useEffect(() => {
    getstatusClassChoice();
  }, []);

  const toggle = () => {
    setIconFilter("");
    setViewIconModal(!viewIconModal);
  };

  const getstatusClassChoice = async () => {
    try {
      const res = await fget({
        url: "trackables/trackable-classes",
      });
      const deStructure = res.data?.map((trackable) => {
        return { value: trackable.id, model: trackable.model };
      });
      setStatusClassChoice(deStructure);
      //if trackableType? set defaults : works without defaults
      if (props.trackableType) {
        const getTrackableValue = deStructure.filter(
          (eachElement) => eachElement.model === props.trackableType
        );
        setDefaultClass(getTrackableValue);
        setStatusClass(getTrackableValue);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilter = (e) => {
    setIconFilter(e.target.value);
  };

  let filteredIcons = [];
  filteredIcons = Icons.filter((icon) => {
    let text = icon.icon.toString().toLowerCase();
    return text.indexOf(iconFilter.toLowerCase()) !== -1;
  });

  const selectIcon = (icon) => {
    setIcon(icon);
    toggle();
  };
  //Validators
  const NewStatusValidationScheme = yup.object({
    statusName: yup
      .string("String required")
      .label("statusName")
      .min(1, "Too Short!")
      .max(50, "Maximum Character Limit: 50")
      .required("This is a required field"),
    statusCode: yup.string().required("This field is required"),
  });

  const formik = useFormik({
    initialValues: {
      statusCode: "",
      statusName: "",
    },
    validationSchema: NewStatusValidationScheme,
    onSubmit: async (values) => {
      let colorString = `${color.r}, ${color.g}, ${color.b}`;

      let formdata = new FormData();
      formdata.append("name", values.statusName);
      formdata.append("code", values.statusCode);
      formdata.append("color", colorString);
      formdata.append("icon", icon);
      formdata.append("project", props.projectId);
      formdata.append("editable", true);
      if (statusClass.length) {
        statusClass.map((eachItem) =>
          formdata.append("status_class", eachItem.value)
        );
      }

      let newStatus = {
        url: `trackables/status/`,
        data: formdata,
      };

      try {
        const res = await fpost(newStatus);
        if (res.status === 200 || 201) {
          setSnackValue({
            isOpen: true,
            message: `SuccessFully Created Status`,
            isError: false,
          });
          props.fetchShotStatus && props.fetchShotStatus();
          props.fetchTaskStatus && props.fetchTaskStatus();
          props.updateRefreshCounter();
          props.closeCreateNewStatusModal();
        }
      } catch (error) {
        setSnackValue({
          isOpen: true,
          message: `${error}`,
          isError: true,
        });
      }
    },
  });

  const selectTrackables = (trackable, e) => {
    if (e.target.checked == true) {
      setStatusClass((statusClass) => [...statusClass, trackable]);
    } else {
      setStatusClass(statusClass.filter((i) => i.value !== trackable.value));
    }
  };

  const trackables = (
    <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
      {statusClassChoice.map((trackable, index) => {
        return (
          <FormControlLabel
            label={trackable.model}
            control={<Checkbox />}
            onChange={(e) => selectTrackables(trackable, e)}
          />
        );
      })}
    </Box>
  );

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackValue.isOpen}
        autoHideDuration={2000}
        onClose={() => setSnackValue({ isOpen: false, message: "" })}
      >
        <Alert severity={snackValue.isError === false ? "success" : "warning"}>
          {snackValue.message}
        </Alert>
      </Snackbar>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <form onSubmit={formik.handleSubmit}>
            <h1 className={classes.title}>New Status</h1>
            <div className={classes.cardContentDiv1}>
              <div>
                <span> Name of Status</span>
                <Asterisk size={12} color="#FF0000" weight="fill" />
                <div style={{ marginTop: "8px" }}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Name of new status"
                    InputLabelProps={{ style: {} }}
                    disableUnderline={false}
                    name="statusName"
                    onChange={formik.handleChange}
                    value={formik.values.statusName}
                    error={Boolean(formik.errors.statusName)}
                  />
                </div>
                {formik.errors.statusName && formik.touched.statusName ? (
                  <HelperText text={formik.errors.statusName} />
                ) : null}
              </div>
              <div style={{ marginTop: "12px" }}>
                <span>Status Code</span>
                <Asterisk size={12} color="#FF0000" weight="fill" />
                <div style={{ marginTop: "8px" }}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Unique code for your status"
                    InputLabelProps={{ style: {} }}
                    disableUnderline={false}
                    name="statusCode"
                    onChange={formik.handleChange}
                    value={formik.values.statusCode}
                    error={Boolean(formik.errors.statusCode)}
                  />
                </div>
                {formik.errors.statusCode && formik.touched.statusCode ? (
                  <HelperText text={formik.errors.statusCode} />
                ) : null}
              </div>

              <div style={{ marginTop: "2%" }}>
                <Grid container spacing={1}>
                  <Grid container xs={6} spacing={2}>
                    <Grid item xs={12}>
                      <p>Select Color</p>
                      <div className={classes.cardContentDivColorPicker}>
                        <CirclePicker
                          id="color"
                          name="color"
                          colors={colour}
                          color={color}
                          circleSpacing={10}
                          onChangeComplete={(color) => {
                            setcolor(color.rgb);
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.inlineDiv}>
                        <label>Select Icon</label>
                        <div
                          onClick={() => toggle()}
                          className={classes.cardContentDivIcon}
                        >
                          <i
                            className={icon}
                            style={{ fontSize: "2rem", marginRight: "8px" }}
                          ></i>
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid item xs={6}>
                    <Grid container spacing={1}>
                      <Grid item lg={12}>
                        <div style={{ marginTop: "10px" }}>
                          {statusClassChoice.length ||
                          (props.trackableType && defaultClass.length) ? (
                            <>
                              <div style={{ textAlign: "center" }}>
                                <label>Select Trackable</label>
                              </div>
                              <div style={{ float: "right" }}>
                                {/* <FormControlLabel
                                  label="Parent"
                                  control={
                                    <Checkbox
                                    // checked={checked[0] && checked[1]}
                                    // indeterminate={checked[0] !== checked[1]}
                                    // onChange={handleChange1}
                                    />
                                  }
                                /> */}
                                {trackables}
                              </div>
                            </>
                          ) : (
                            <div className={classes.autoComplete}></div>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>

            <CardActions style={{ marginTop: "20px", padding: "0%" }}>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={6}>
                  <Button
                    size="large"
                    id="createStatus"
                    variant="contained"
                    type="submit"
                    fullWidth
                    value="submit"
                    className={classes.yesButton}
                  >
                    {props.edit ? "Yes, Edit Status" : "Yes, add status"}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    size="large"
                    id="cancel"
                    fullWidth
                    onClick={() => props.closeCreateNewStatusModal()}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </form>
        </CardContent>
      </Card>

      <Modal
        open={viewIconModal}
        close={() => setViewIconModal(!viewIconModal)}
        className={classes.iconModal}
      >
        <div className={classes.iconMoadlContainer}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div>
                <div className={classes.statusModalHeader}>
                  <h1 className={classes.title}>Search Icon</h1>
                  <Button onClick={() => setViewIconModal(!viewIconModal)}>
                    <X size={24} />
                  </Button>
                </div>
                <div className={classes.cardContentDiv2}>
                  <Input
                    id="code"
                    name="code"
                    type="text"
                    disableUnderline={true}
                    placeholder="Search"
                    onChange={handleFilter}
                    value={iconFilter}
                    className={classes.IconInput}
                  />
                </div>
              </div>
              <div className={classes.iconContainer}>
                <ul className={classes.iconPickerList}>
                  {filteredIcons.map((eachIcon) => (
                    <div
                      className={
                        icon === eachIcon.icon
                          ? classes.iconListPicked
                          : classes.iconList
                      }
                    >
                      <li>
                        <span
                          onClick={() => selectIcon(eachIcon.icon)}
                          className={eachIcon.icon}
                        ></span>
                      </li>
                    </div>
                  ))}
                </ul>
              </div>
            </CardContent>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

export default CreateNewStatusModal;
