import {
  Button,
  CircularProgress,
  Menu,
  Modal,
  TextField,
} from "@material-ui/core";
import { useFormik } from "formik";
import {
  ArrowBendUpLeft,
  DotsThree,
  DownloadSimple,
  Pencil,
  Trash,
} from "phosphor-react";
import React, { useState } from "react";
import { useEffect } from "react";
import { createRef } from "react";
import { fdelete, fpatch } from "../../../../API/callsAPI";
import ConfirmDeleteFile from "../../ConfirmDeleteFile/ConfirmDeleteFile.component";
import DeleteModal from "../../DeleteModal/DeleteModal.component";
import UserAvatar from "../../UserAvatar/UserAvatar.component";
import useStyles from "./ViewComments.style";

const SingleComment = ({
  comment,
  loggedInUser,
  updateCommentsRefreshCounter,
  setSnackValue,
  projectId,
  showReplyButton,
  setRepliedTo,
}) => {
  const classes = useStyles();
  const inputRef = createRef();
  const [imageModal, setImageModal] = useState(false);
  const [attachment, setAttachment] = useState();

  const [editComment, setEditComment] = useState({});
  const [deleteCommentData, setDeleteCommentData] = useState({
    isOpen: false,
    selectedComment: {},
  });
  const [deleteAttachmentData, setDeleteAttachmentData] = useState({
    isOpen: false,
    selectedComment: {},
  });
  const [isEdited, setIsEdited] = useState(true);
  const [filesOptionMenu, setFilesOptionMenu] = useState(null);
  const [versionName, setVersionName] = useState("");
  const [commentDescription, setCommentDescription] = useState("");

  useEffect(() => {
    if (comment?.description?.includes("<version>")) {
      const commentSplit = comment?.description?.split("<version>");
      setVersionName(commentSplit[1]);
      setCommentDescription(commentSplit[2]);
    } else {
      setCommentDescription(comment?.description);
    }
  }, [comment]);

  const formik = useFormik({
    initialValues: {
      commentDetails: editComment?.description,
    },
  });

  const viewAttachment = (file) => {
    setImageModal(true);
    setAttachment(file);
  };

  const postEditedComment = async () => {
    setIsEdited(false);
    let formData = new FormData();
    formData.append("name", comment?.task.name);
    formData.append(
      "description",
      versionName
        ? `<version>${versionName}<version> ${formik.values.commentDetails}`
        : formik.values.commentDetails
    );
    formData.append("task", comment?.task.id);
    formData.append("id", projectId);
    let data = {
      url: `trackables/task-comment/${editComment.id}/`,
      data: formData,
    };
    try {
      const res = await fpatch(data);
      if (res.status === 200 || 201) {
        updateCommentsRefreshCounter();
        setEditComment({});
        formik.values.commentDetails = "";
        setSnackValue({
          isOpen: true,
          message: `Your Comment edited Successfully."`,
          isError: false,
        });
        setIsEdited(true);
      }
    } catch (err) {
      console.log(err);
      // setShowCircularProgress(false);
      setSnackValue({
        isOpen: true,
        message: `please fill all the required field`,
        isError: true,
      });
    }
  };

  const handleDeleteComment = async () => {
    try {
      await fdelete({
        url: `trackables/task-comment/${deleteCommentData?.selectedComment?.id}/`,
      });
      updateCommentsRefreshCounter();
      setDeleteCommentData({ isOpen: false, selectedComment: {} });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteAttachment = async () => {
    let formData = new FormData();
    formData.append("attachment", "");
    let data = {
      url: `trackables/task-comment/${deleteAttachmentData.selectedComment.id}/`,
      data: formData,
    };
    try {
      const res = await fpatch(data);
      if (res.status === 200 || 201) {
        updateCommentsRefreshCounter();
        setDeleteAttachmentData({ isOpen: false, selectedComment: {} });
        setSnackValue({
          isOpen: true,
          message: `Attachment deleted successfully`,
          isError: false,
        });
      }
    } catch (err) {
      setDeleteAttachmentData({ isOpen: false, selectedComment: {} });
      setSnackValue({
        isOpen: true,
        message: `Something went wrong`,
        isError: true,
      });
    }
  };

  const downloadFile = (url, filename) => {
    const aTag = document.createElement("a");
    const file = url.split("/").pop();
    aTag.href = url;
    aTag.target = "_blank";
    aTag.setAttribute("download", file);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };

  useEffect(() => {
    inputRef?.current?.focus();
  }, [editComment?.id]);

  return (
    <>
      <div style={{ marginBottom: "10px", width: "100%" }}>
        <div className={classes.flexBetween}>
          <div className={classes.authorName}>
            <UserAvatar
              avatar={comment?.author?.avatar}
              firstName={comment?.author?.first_name}
              lastName={comment?.author?.last_name}
            />
            <span>
              {comment?.author?.first_name} {comment?.author?.last_name}
            </span>
          </div>
          <div className={classes.date}>
            <span>
              {new Intl.DateTimeFormat("en-US", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              }).format(new Date(comment.created_at))}
            </span>
            <span>|</span>
            <span>
              {new Intl.DateTimeFormat("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              }).format(new Date(comment.created_at))}
            </span>
          </div>
        </div>
        {editComment.id === comment.id ? (
          <div className={classes.editComment}>
            <div style={{ width: "100%" }}>
              <TextField
                fullWidth
                type="text"
                size="small"
                defaultValue={editComment.description}
                value={formik.values.commentDetails}
                onChange={formik.handleChange}
                className={classes.commentInput}
                placeholder="Write a Comment"
                inputProps={{
                  ref: inputRef,
                  className: classes.editInput,
                  onKeyDown: (event) => {
                    if (event.key === "Enter") {
                      postEditedComment();
                    }
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    fontSize: "14px",
                  },
                }}
                name="commentDetails"
                /*  error={formik.touched.comment ? formik.errors.comment : ""}
            helperText={formik.errors.comment ? formik.errors.comment : null} */
              />
            </div>
            <div className={classes.buttons}>
              <Button
                onClick={() => setEditComment({})}
                className={classes.cancelButton}
              >
                cancel
              </Button>
              <Button
                className={classes.saveButton}
                onClick={() => postEditedComment()}
              >
                {isEdited ? (
                  <span>Save</span>
                ) : (
                  <CircularProgress size="small" />
                )}
              </Button>
            </div>
          </div>
        ) : (
          <div className={classes.commentsDetailsAndButtons}>
            <div className={classes.commentsDetails}>
              <div>
                {versionName?.length > 0 ? (
                  <span className={classes.versionName}>#{versionName}</span>
                ) : (
                  ""
                )}{" "}
                <span className={classes.descriptionText}>
                  {commentDescription}
                </span>
              </div>
              {comment.attachment ? (
                <div className={classes.fileName}>
                  <span
                    className={classes.attachmentIcon}
                    onClick={() => viewAttachment(comment.attachment)}
                  >
                    {comment.filename.length > 30
                      ? comment.filename.slice(0, 30) + "..."
                      : comment.filename}
                  </span>
                  <DotsThree
                    className={classes.icon}
                    size={20}
                    onClick={(event) => {
                      setFilesOptionMenu(event.target);
                    }}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className={classes.iconsContainer}>
              {showReplyButton ? (
                <ArrowBendUpLeft
                  size={20}
                  className={classes.icon}
                  onClick={() => setRepliedTo(comment)}
                />
              ) : (
                ""
              )}
              {loggedInUser?.id === comment?.author?.id ? (
                <>
                  <Pencil
                    size={20}
                    className={classes.icon}
                    onClick={() => {
                      setEditComment(comment);
                      formik.values.commentDetails = commentDescription;
                    }}
                  />
                  <Trash
                    size={20}
                    className={classes.icon}
                    onClick={() => {
                      setDeleteCommentData({
                        isOpen: true,
                        selectedComment: comment,
                      });
                    }}
                  />
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </div>

      <div>
        <Menu
          anchorEl={filesOptionMenu}
          open={Boolean(filesOptionMenu)}
          onClose={() => setFilesOptionMenu(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          MenuListProps={{
            style: {
              padding: "0px",
            },
          }}
        >
          <div className={classes.menuDiv}>
            <Button
              className={classes.menuButton}
              onClick={() => downloadFile(comment.attachment, comment.filename)}
            >
              <div className={classes.editAndCloseButton}>
                <DownloadSimple size={20} />
                <span>Download file</span>
              </div>
            </Button>
            <hr style={{ width: "90%", margin: "1px" }} />
            {loggedInUser?.id === comment?.author?.id ? (
              <Button
                className={classes.menuButton}
                onClick={() =>
                  setDeleteAttachmentData({
                    isOpen: true,
                    selectedComment: comment,
                  })
                }
              >
                <div className={classes.editAndCloseButton}>
                  <Trash size={20} />
                  <span>Delete File</span>
                </div>
              </Button>
            ) : (
              ""
            )}
          </div>
        </Menu>
      </div>
      <div>
        <Modal
          className={classes.modals}
          open={imageModal}
          onClose={(e) => {
            setImageModal(false);
            e.stopPropagation();
          }}
        >
          <img
            src={attachment}
            alt="no Img"
            height="100%"
            style={{ maxHeight: "400px" }}
            onContextMenu={(e) => e.preventDefault()}
          />
        </Modal>
        <Modal
          className={classes.modals}
          open={deleteCommentData.isOpen}
          onClose={() =>
            setDeleteCommentData({ isOpen: false, selectedComment: {} })
          }
        >
          <DeleteModal
            type="comment"
            name={deleteCommentData?.selectedComment.id}
            handleCloseDeleteModal={() => {
              setDeleteCommentData({ isOpen: false, selectedComment: {} });
            }}
            handleDelete={handleDeleteComment}
          />
        </Modal>
        <Modal
          className={classes.modals}
          open={deleteAttachmentData.isOpen}
          onClose={() =>
            setDeleteAttachmentData({ isOpen: false, selectedComment: {} })
          }
        >
          <ConfirmDeleteFile
            name={deleteAttachmentData?.selectedComment?.filename}
            closeModal={() => {
              setDeleteAttachmentData({ isOpen: false, selectedComment: {} });
            }}
            deleteFile={() => {
              handleDeleteAttachment();
            }}
          />
        </Modal>
      </div>
    </>
  );
};

export default SingleComment;
