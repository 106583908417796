import React from "react";

export const ProjectIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.54545 4.5C5.40079 4.5 5.26205 4.55747 5.15976 4.65976C5.05747 4.76205 5 4.90079 5 5.04545V10.5C5 10.6447 5.05747 10.7834 5.15976 10.8857C5.26205 10.988 5.40079 11.0455 5.54545 11.0455C5.69012 11.0455 5.82886 10.988 5.93115 10.8857C6.03344 10.7834 6.09091 10.6447 6.09091 10.5V5.04545C6.09091 4.90079 6.03344 4.76205 5.93115 4.65976C5.82886 4.55747 5.69012 4.5 5.54545 4.5ZM9 4.5C8.85534 4.5 8.7166 4.55747 8.61431 4.65976C8.51201 4.76205 8.45455 4.90079 8.45455 5.04545V8.31818C8.45455 8.46285 8.51201 8.60158 8.61431 8.70388C8.7166 8.80617 8.85534 8.86364 9 8.86364C9.14466 8.86364 9.2834 8.80617 9.38569 8.70388C9.48799 8.60158 9.54545 8.46285 9.54545 8.31818V5.04545C9.54545 4.90079 9.48799 4.76205 9.38569 4.65976C9.2834 4.55747 9.14466 4.5 9 4.5ZM11.9091 5.04545C11.9091 4.90079 11.9666 4.76205 12.0689 4.65976C12.1711 4.55747 12.3099 4.5 12.4545 4.5C12.5992 4.5 12.7379 4.55747 12.8402 4.65976C12.9425 4.76205 13 4.90079 13 5.04545V11.9545C13 12.0992 12.9425 12.2379 12.8402 12.3402C12.7379 12.4425 12.5992 12.5 12.4545 12.5C12.3099 12.5 12.1711 12.4425 12.0689 12.3402C11.9666 12.2379 11.9091 12.0992 11.9091 11.9545V5.04545Z"
        fill="#606479"
      />
      <path
        d="M3.225 2.5H14.775C15.4512 2.5 16 3.0488 16 3.725V15.275C16 15.5999 15.8709 15.9115 15.6412 16.1412C15.4115 16.3709 15.0999 16.5 14.775 16.5H3.225C2.90011 16.5 2.58853 16.3709 2.35879 16.1412C2.12906 15.9115 2 15.5999 2 15.275V3.725C2 3.0488 2.5488 2.5 3.225 2.5ZM3.05 3.725V15.275C3.05 15.3716 3.1284 15.45 3.225 15.45H14.775C14.8214 15.45 14.8659 15.4316 14.8987 15.3987C14.9316 15.3659 14.95 15.3214 14.95 15.275V3.725C14.95 3.67859 14.9316 3.63408 14.8987 3.60126C14.8659 3.56844 14.8214 3.55 14.775 3.55H3.225C3.17859 3.55 3.13408 3.56844 3.10126 3.60126C3.06844 3.63408 3.05 3.67859 3.05 3.725Z"
        fill="#606479"
      />
    </svg>
  );
};
export const ProjectIconWhite = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.54545 4.5C5.40079 4.5 5.26205 4.55747 5.15976 4.65976C5.05747 4.76205 5 4.90079 5 5.04545V10.5C5 10.6447 5.05747 10.7834 5.15976 10.8857C5.26205 10.988 5.40079 11.0455 5.54545 11.0455C5.69012 11.0455 5.82886 10.988 5.93115 10.8857C6.03344 10.7834 6.09091 10.6447 6.09091 10.5V5.04545C6.09091 4.90079 6.03344 4.76205 5.93115 4.65976C5.82886 4.55747 5.69012 4.5 5.54545 4.5ZM9 4.5C8.85534 4.5 8.7166 4.55747 8.61431 4.65976C8.51201 4.76205 8.45455 4.90079 8.45455 5.04545V8.31818C8.45455 8.46285 8.51201 8.60158 8.61431 8.70388C8.7166 8.80617 8.85534 8.86364 9 8.86364C9.14466 8.86364 9.2834 8.80617 9.38569 8.70388C9.48799 8.60158 9.54545 8.46285 9.54545 8.31818V5.04545C9.54545 4.90079 9.48799 4.76205 9.38569 4.65976C9.2834 4.55747 9.14466 4.5 9 4.5ZM11.9091 5.04545C11.9091 4.90079 11.9666 4.76205 12.0689 4.65976C12.1711 4.55747 12.3099 4.5 12.4545 4.5C12.5992 4.5 12.7379 4.55747 12.8402 4.65976C12.9425 4.76205 13 4.90079 13 5.04545V11.9545C13 12.0992 12.9425 12.2379 12.8402 12.3402C12.7379 12.4425 12.5992 12.5 12.4545 12.5C12.3099 12.5 12.1711 12.4425 12.0689 12.3402C11.9666 12.2379 11.9091 12.0992 11.9091 11.9545V5.04545Z"
        fill="#F9F9F9"
      />
      <path
        d="M3.225 2.5H14.775C15.4512 2.5 16 3.0488 16 3.725V15.275C16 15.5999 15.8709 15.9115 15.6412 16.1412C15.4115 16.3709 15.0999 16.5 14.775 16.5H3.225C2.90011 16.5 2.58853 16.3709 2.35879 16.1412C2.12906 15.9115 2 15.5999 2 15.275V3.725C2 3.0488 2.5488 2.5 3.225 2.5ZM3.05 3.725V15.275C3.05 15.3716 3.1284 15.45 3.225 15.45H14.775C14.8214 15.45 14.8659 15.4316 14.8987 15.3987C14.9316 15.3659 14.95 15.3214 14.95 15.275V3.725C14.95 3.67859 14.9316 3.63408 14.8987 3.60126C14.8659 3.56844 14.8214 3.55 14.775 3.55H3.225C3.17859 3.55 3.13408 3.56844 3.10126 3.60126C3.06844 3.63408 3.05 3.67859 3.05 3.725Z"
        fill="#F9F9F9"
      />
    </svg>
  );
};
