import {
  Environment,
  OrbitControls,
  Stage,
  useFBX,
  useGLTF,
  useAnimations,
  useProgress,
  Html,
  Loader,
  Center,
  Text3D,
} from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { ErrorBoundary, captureException, captureMessage } from "@sentry/react";
import React, { Suspense } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import MediaError from "../ErrorFalbackComponents/MediaError";
import { useState } from "react";
import { fget } from "../../../API/callsAPI";
import { MeshNormalMaterial } from "three";
import { CircularProgress, Slide } from "@material-ui/core";
import UploadHdr from "./UploadHdr/UploadHdr";
import { X } from "react-feather";
import useStyles from "./ModelViewer.style";
import ChangeHdr from "./ChangeHdr/ChangeHdr";

export default function ModelViewerLatest({
  versionType,
  fileType,
  versionObj,
  projectId,
  fileURL,
  glbScreenshot,
  setGlbScreenshot,
  setScreenshot,
  canvasModalToggle,
  allowHdrChange,
  setAllowHdrChange,
}) {
  const classes = useStyles();
  const [modelBackgroundList, setModelBackgroundList] = useState([]);
  const [selectedBackgroundHdr, setSelectedBackgroundHdr] = useState({});
  const CanvasRef = useRef(null);

  useEffect(() => {
    //TODO: move logic to parent
    // eslint-disable-next-line no-unused-expressions
    async function init() {
      try {
        const projectHDRMaps = await fget({
          url: `trackables/hdr-maps?project=${projectId}`,
        });
        if (projectHDRMaps.data?.results.length) {
          setModelBackgroundList(projectHDRMaps.data?.results);
          //results in slow renders if pointed to remote hdr file.
          // setSelectedBackgroundHdr(projectHDRMaps.data?.results[0]);
        }
      } catch (error) {
        console.error(error);
      }
    }

    init();

    return () => {};
  }, []);

  useEffect(() => {
    if (glbScreenshot === true) {
      saveImage();
      setGlbScreenshot(false);
    }
    return () => {};
  }, [glbScreenshot]);

  function saveImage() {
    try {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const image = new Image();
      const imageMIME = "image/jpeg";
      if (!CanvasRef.current) {
        throw new Error("No frame mounted to annotate from");
      }
      image.src = CanvasRef.current.toDataURL(imageMIME);

      image.onload = () => {
        canvas.width = 720;
        canvas.height = (canvas.width / image.width) * image.height;
        const scale = Math.min(
          canvas.width / image.width,
          canvas.height / image.height
        );
        const x = canvas.width / 2 - (image.width / 2) * scale;
        const y = canvas.height / 2 - (image.height / 2) * scale;

        ctx.drawImage(image, x, y, image.width * scale, image.height * scale);

        setScreenshot(canvas.toDataURL(imageMIME));
        canvasModalToggle();
      };
    } catch (error) {
      console.log(
        "Encountered error while creating frame for annotation",
        error
      );
    }
  }

  //internal component
  function RenderGLBModel() {
    const groupRef = useRef();
    const { scene, animations } = useGLTF(fileURL);
    const { actions } = useAnimations(animations, groupRef);
    const actionsKeys = Object.keys(actions) || [];
    useEffect(() => {
      try {
        if (actionsKeys.length !== 0) {
          for (let animation in actions) {
            actions[animation].play();
          }
        }
      } catch (error) {
        captureException(error);
        console.log(
          `Caught errors while starting model animation. \nAnimation Actions Count: ${actionsKeys}`
        );
      }
    });
    if (actionsKeys.length !== 0) {
      return (
        <group ref={groupRef} dispose={null}>
          <group>
            <primitive object={scene} />
          </group>
        </group>
      );
    } else {
      return <primitive object={scene} />;
    }
  }

  function RenderFBXModel() {
    //input should be a file
    const groupRef = useRef();
    const fbxModel = useFBX(fileURL);
    const { actions } = useAnimations(fbxModel.animations, groupRef);
    const actionsKeys = Object.keys(actions) || [];
    const { error } = useProgress((state) => state.errors);
    console.log("Error's", error);
    useEffect(() => {
      try {
        if (actionsKeys.length !== 0) {
          for (let animation in actions) {
            actions[animation].play();
          }
        }
      } catch (error) {
        captureException(error);
        console.log(
          `Caught errors while starting model animation. \nAnimation Actions Count: ${actionsKeys}`
        );
      }

      return () => {};
    });
    if (actionsKeys.length > 0) {
      <group ref={groupRef} dispose={null}>
        <group>
          <primitive object={fbxModel} />
        </group>
      </group>;
    } else {
      return <primitive object={fbxModel} />;
    }
  }

  return (
    <>
      <div style={{ height: "100%" }}>
        {fileType === "GLB" ? (
          <>
            <Canvas gl={{ preserveDrawingBuffer: true }} ref={CanvasRef}>
              <Suspense fallback={null}>
                <ErrorBoundary fallback={<MediaError />}>
                  <Stage>
                    <Environment
                      background={true}
                      files={
                        Boolean(selectedBackgroundHdr.file)
                          ? selectedBackgroundHdr.file
                          : "/defaultbackground.hdr"
                      }
                      path=""
                    />
                    <Center>
                      <mesh>
                        <RenderGLBModel />
                      </mesh>
                      {/* <Center bottom={true}>
                        <Text3D
                          letterSpacing={-0.06}
                          size={0.5}
                          font="/Inter_Bold.json"
                        >
                          {versionObj.name}
                          <meshStandardMaterial color="white" />
                        </Text3D>
                      </Center> */}
                    </Center>
                  </Stage>
                </ErrorBoundary>
              </Suspense>
              <OrbitControls enableZoom />
            </Canvas>
            <Loader />
          </>
        ) : fileType === "FBX" ? (
          <>
            <Canvas gl={{ preserveDrawingBuffer: true }} ref={CanvasRef}>
              <Suspense fallback={null}>
                {/* //env */}
                <ErrorBoundary fallback={<MediaError />}>
                  <Stage>
                    <mesh>
                      <Environment
                        background={true}
                        files={
                          Boolean(selectedBackgroundHdr.file)
                            ? selectedBackgroundHdr.file
                            : "/defaultbackground.hdr"
                        }
                        path=""
                      />
                      <RenderFBXModel />
                    </mesh>
                    {/* <Center top>
                      <Text3D
                        letterSpacing={-0.06}
                        size={5.5}
                        font="/Inter_Bold.json"
                      >
                        {versionObj.name}
                        <meshStandardMaterial color="white" />
                      </Text3D>
                    </Center> */}
                  </Stage>
                </ErrorBoundary>
              </Suspense>
              <OrbitControls enableZoom enablePan />
            </Canvas>
            <Loader />
          </>
        ) : (
          ""
        )}
      </div>
      {/* ui component for changing hdr */}
      {allowHdrChange ? (
        <>
          <Slide direction="up" in={allowHdrChange}>
            <div className={classes.bgContainer}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginRight: "-9px",
                }}
              >
                <div>
                  {/* disabling upload */}
                  {/* {false ? (
                    <CircularProgress color="secondary" size={23} />
                  ) : (
                    <UploadHdr getUploadFile={() => {}} />
                  )} */}
                </div>
                <div
                  onClick={() => setAllowHdrChange(false)}
                  style={{ cursor: "pointer" }}
                >
                  <X />
                </div>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "100%",

                    // overflowX: "hidden",
                  }}
                >
                  {/* change hdr maps to images */}
                  <ChangeHdr
                    HdrData={[]} //list of hdr files
                    selectedFile={(selectedFile) =>
                      setSelectedBackgroundHdr(selectedFile)
                    } //onfile select handler
                    file={selectedBackgroundHdr} //selected hdr file
                    deleteHdr={() => {}} //onhdr file delete
                  />
                </div>
              </div>
            </div>
          </Slide>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
