import React, { useState, useEffect } from "react";
import * as Icon from "react-feather";
import { Snackbar, Menu, Modal } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import DragScreen from "../../Utilities/DragScreen/DragScreen";
import UploadFile from "../../Utilities/UploadFile/UploadFile";
//@MUI
import {
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
  Select,
  CircularProgress,
  MenuItem,
} from "@material-ui/core";

//Validators
import { useFormik } from "formik";
import * as yup from "yup";
//
import useStyles from "./SequencePageEditSequenceModal.styles";
import { Asterisk } from "phosphor-react";

import { fdelete, fget, fpatch, uploadFile } from "./../../../API/callsAPI";
import { useHistory } from "react-router-dom";
import ModalTitleAndButtons from "../../Utilities/ModalTitleAndButtons/ModalTitleAndButtons.component";
import ConfirmDeleteFile from "../../Utilities/ConfirmDeleteFile/ConfirmDeleteFile.component";
import ErrorOrSuccessMessage from "../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";
import HelperText from "../../Utilities/HelperText/HelperText";

export default function SequencePageEditSequenceModal(props) {
  const classes = useStyles();
  const history = useHistory();

  const [thumbnail, uploadThumbnailSet] = useState();
  const [loading, setLoading] = useState(false);
  const [preLoadedFile, setPreLoadedFile] = useState(false);
  const [deleteFileModal, setDeleteFileModal] = useState(false);
  const [snackValue, setSnackValue] = React.useState({
    isOpen: false,
    message: "",
    isError: false,
  });

  useEffect(() => {
    if (props?.sequence?.file) {
      const fileNameArray = props.sequence.file.split("/");
      uploadThumbnailSet(fileNameArray[fileNameArray.length - 1]);
      setPreLoadedFile(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleThumbnailUpload = (event) => {
    let file = event.target.files[0];
    event.target.value = null;
    uploadThumbnailSet(file);
    setPreLoadedFile(false);
  };

  const handleClearUpload = (e) => {
    uploadThumbnailSet(null);
    e?.stopPropagation();
  };

  //Validators
  const NewSequenceValidationScheme = yup.object({
    sequenceName: yup
      .string("String required")
      .min(1, "Too Short!")
      .max(50, "Maximum Character Limit: 50")
      .required("This is a required field"),
  });

  const formik = useFormik({
    initialValues: {
      sequenceName: props.sequence ? props.sequence.code : "",
      status:
        props.sequence && props.sequence.status ? props.sequence.status.id : "",
      description: props.sequence ? props.sequence.description : "",
    },
    validationSchema: NewSequenceValidationScheme,
    onSubmit: async (values) => {
      setLoading(true);

      let formdata = new FormData();
      formdata.append("code", values.sequenceName);
      thumbnail && formdata.append("file_name", thumbnail.name);
      formdata.append("status", values.status);
      formdata.append("description", values.description);

      let newSequence = {
        url: `trackables/shot-sequence/${props.sequenceId}/`,
        data: formdata,
      };

      try {
        const editSeqRes = await fpatch(newSequence);
        if (editSeqRes.status === 200 || 201) {
          if (thumbnail) {
            getSignedUrl(editSeqRes.data.id);
          } else {
            setLoading(false);
            setSnackValue({
              isOpen: true,
              message: `SuccessFully edited ${editSeqRes.data.code}`,
              isError: false,
            });
            props.updateRefreshCounter();
            props.handleEditSequenceModal();
          }
        }
      } catch (error) {
        setLoading(false);
        setSnackValue({
          isOpen: true,
          message: `${error}`,
          isError: true,
        });
      }
    },
  });

  const getSignedUrl = async (newId) => {
    try {
      const uploadUrl = await fget({
        url: `trackables/sequence/${newId}/upload`,
      });
      if (uploadUrl.status === 200) {
        await uploadFile({ url: uploadUrl.data?.signed_url, data: thumbnail });
        await fget({
          url: `trackables/sequence/${newId}/thumbnail`,
        });
        setLoading(false);
        setSnackValue({
          isOpen: true,
          message: `SuccessFully Edited ${props?.data?.name}`,
          isError: false,
        });
        props.updateRefreshCounter();
        props.handleEditSequenceModal();
      }
    } catch (error) {
      setLoading(false);
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  const handleDragFile = (e) => {
    if (e.type === "dragenter") {
      //when  file enter- display dragscreen
      props.handleDragScreen(true);
      props.setDragFileEnterModal(true);
    }
    //catching the dragged file for uploading
    if (e.type === "drop") {
      props.handleDragScreen(false);
      console.log(e.dataTransfer.files[0]);
      uploadThumbnailSet(e.dataTransfer.files[0]);
      setPreLoadedFile(false);
    }
    e.preventDefault();
    e?.stopPropagation();
  };

  const handleDeleteFile = async (e) => {
    if (preLoadedFile) {
      try {
        await fdelete({
          url: `trackables/sequence/${props.sequenceId}/file`,
        });
        props.updateRefreshCounter();
        setDeleteFileModal(false);
        setSnackValue({
          isOpen: true,
          message: `file deleted successfully`,
          isError: false,
        });
        handleClearUpload(e);
      } catch (error) {
        console.log(error);
        setSnackValue({
          isOpen: true,
          message: `Something went wrong in file delete`,
          isError: true,
        });
      }
    } else {
      setDeleteFileModal(false);
      setPreLoadedFile(false);
      handleClearUpload(e);
    }
  };

  return (
    <div className={classes.root}>
      <ErrorOrSuccessMessage snackValue={snackValue} />
      <Card
        className={classes.card}
        onDragEnter={(e) => handleDragFile(e)}
        onDrop={(e) => handleDragFile(e)}
        onDragLeave={(e) => handleDragFile(e)}
        onDragOver={(e) => handleDragFile(e)}
      >
        <CardContent className={classes.cardContent}>
          <DragScreen dragScreen={props.dragScreen} />
          <form enctype="multipart/form-data" onSubmit={formik.handleSubmit}>
            <ModalTitleAndButtons
              name="Sequence"
              type="Edit"
              closeModal={(e) => props.handleEditSequenceModal(e)}
              openDeleteModal={(e) => props.handleDeleteSequenceModalState(e)}
            />
            <div className={classes.cardContentDiv1}>
              <div>
                <span>Sequence Name</span>
                <Asterisk size={12} color="#FF0000" weight="fill" />
                <div
                  style={{ marginTop: "5px" }}
                  className={classes.cardContentDiv3}
                >
                  <TextField
                    fullWidth
                    type="text"
                    className={classes.cardContentInput1}
                    InputProps={{ disableUnderline: true }}
                    name="sequenceName"
                    value={formik.values.sequenceName}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.sequenceName)}
                  />
                </div>
                {formik.errors.sequenceName && formik.touched.sequenceName ? (
                  <HelperText text={formik.errors.sequenceName} />
                ) : null}
              </div>

              <div style={{ marginTop: "25px" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <span>Status</span>
                    <div
                      className={classes.cardContentDiv3}
                      style={{ marginTop: "5px", width: "150px" }}
                    >
                      <Select
                        disableUnderline={true}
                        style={{ width: "100%" }}
                        labelId="demo-simple-select-outlined-label"
                        className={classes.cardContentInput1}
                        id="selectStatusS"
                        name="status"
                        value={formik.values.status}
                        onChange={formik.handleChange}
                        label="Select Status"
                      >
                        <MenuItem
                          value=""
                          className={classes.addNewStatusButton}
                          onClick={() => {
                            history.push(`/${props.projectId}/settings`);
                          }}
                        >
                          <Icon.Plus />
                          Add new Status
                        </MenuItem>
                        {props.status &&
                          props.status.map((eachStatus) => (
                            <MenuItem value={eachStatus.id}>
                              <span
                                style={{
                                  height: "15px",
                                  width: "15px",
                                  backgroundColor: `rgb(${eachStatus?.color})`,
                                  borderRadius: "50%",
                                  display: "inline-block",
                                  margin: "0px 5px",
                                }}
                              ></span>
                              {eachStatus.name} - {eachStatus.code}
                            </MenuItem>
                          ))}
                      </Select>
                    </div>
                  </div>
                  <div style={{ maxWidth: "50%" }}>
                    <span>Status Code</span>
                    <div
                      style={{ marginTop: "5px", cursor: "not-allowed" }}
                      className={classes.cardContentDiv3}
                    >
                      <TextField
                        className={classes.cardContentInput1}
                        InputProps={{
                          disableUnderline: true,
                          style: { opacity: "0.4" },
                        }}
                        disabled
                        fullWidth
                        value={
                          props.status.find(
                            (eachStatus) =>
                              eachStatus.id === formik.values.status
                          )?.code
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "25px" }}>
                <span>Description</span>
                <div
                  style={{ marginTop: "5px" }}
                  className={classes.cardContentDiv4}
                >
                  <TextField
                    type="text"
                    className={classes.cardContentInput2}
                    multiline={true}
                    InputProps={{ disableUnderline: true }}
                    placeholder="Enter a Description..."
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    helperText={
                      formik.errors.description
                        ? formik.errors.description
                        : null
                    }
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                padding: "0px",
                marginTop: "25px",
              }}
            >
              <span style={{ marginTop: "5%" }}>Attachment</span>
              <UploadFile
                thumbnail={thumbnail}
                preLoadedFile={preLoadedFile}
                handleClearUpload={handleClearUpload}
                handleThumbnailUpload={handleThumbnailUpload}
                setDeleteFileModal={setDeleteFileModal}
              />
            </div>
            <CardActions className={classes.cardAction}>
              <Button
                fullWidth
                className={classes.done}
                loading={!loading}
                type="submit"
                value="Submit"
                size="small"
                // disabled={fileUploading}
                disableElevation
              >
                {loading ? (
                  <CircularProgress style={{ width: "25px", height: "25px" }} />
                ) : (
                  <b>Done Editing</b>
                )}
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
      <div>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={deleteFileModal}
          onClose={() => setDeleteFileModal(false)}
        >
          <ConfirmDeleteFile
            name={typeof thumbnail === "object" ? thumbnail?.name : thumbnail}
            closeModal={() => {
              setDeleteFileModal(false);
            }}
            deleteFile={() => {
              handleDeleteFile();
            }}
          />
        </Modal>
      </div>
    </div>
  );
}
