import { React, useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import useStyles from "./BreadCrumb";
import { Link, Typography, Breadcrumbs } from "@material-ui/core";
import { CaretRight } from "phosphor-react";

function BreadCrumb(props) {
  const classes = useStyles();
  const history = useHistory();
  const pathname = props.location.pathname;
  const [code, setCode] = useState(false);
  const nagvigatingUrl = pathname.split("/").filter((x) => x);
  const pathnames = pathname
    .split("/")
    .filter(
      (x) => typeof (parseInt(x) ? parseInt(x) : x) !== "number" && x !== ""
    );

  const capitaliseFirstChar = (str) => {
    return str[0].toUpperCase() + str.substring(1, str.length);
  };
  const goToPage = (index) => {
    const routeTo = `/${nagvigatingUrl.slice(0, 2 * index + 2).join("/")}`;
    history.push(routeTo);
  };
  return (
    <Breadcrumbs
      separator={<CaretRight className={classes.separatorIcon} />}
      aria-label="breadcrumb"
    >
      {pathnames.map((path, index) => {
        const isLast = index === pathnames.length - 1;
        return isLast ? (
          <Typography
            key={index}
            className={classes.endBreadCrumb}
            style={{
              fontSize: props?.fontSize ? `${props?.fontSize}` : "20px",
            }}
          >
            {props.shotCode ? props.shotCode : capitaliseFirstChar(path)}
            {/* {
                         props.shotCode ? (
                            capitaliseFirstChar(props.shotCode)
                         )
                          : "dd"
                        //  {capitaliseFirstChar(props.shotCode ? props.shotCode : path)}
                     }  */}
          </Typography>
        ) : (
          <Link
            key={index}
            className={classes.breadCrumbLink}
            style={{
              textDecoration: "none",
              fontSize: props?.fontSize ? `${props?.fontSize}` : "20px",
            }}
            onClick={() => {
              goToPage(index);
            }}
          >
            {capitaliseFirstChar(path)}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
}

export default withRouter(BreadCrumb);
