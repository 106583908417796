import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    tab:{
        borderRadius:"10px 10px 0px 0px",
        backgroundColor:theme.palette.secondary.light,
        marginBottom:"-2px",
        padding:"0% !important",
        minHeight: "45px",
        marginRight:"8px",
        fontSize:"1rem",
        color:theme.palette.text.tertiary,
        border:`3px solid ${theme.palette.secondary.light}`,
        padding:"0px 12px 0px 12px",
        

    },
    selectedTab:{
        border:`3px solid ${theme.palette.secondary.dark}`,
        borderRadius:"10px 10px 0px 0px",
        backgroundColor:theme.palette.secondary.light,
        marginBottom:"-2px",
        padding:"0% !important",
        minHeight: "43px",
        marginRight:"8px",
        fontSize:"1rem",
        borderBottom:`3px solid red`,
        padding:"0px 12px 0px 12px",
        zIndex:"999"
    },
    tabs:{
        minHeight:"45px",
        maxHeight:"45px",
        padding:"0% !important",
        borderBottom:`3px solid ${theme.palette.secondary.dark}`
    },
    addStatusButton: {
        backgroundColor: theme.palette.secondary.main,
        height: "40px",
        width:"126px",
        textTransform: "none",
        borderRadius: "10px",
        margin: "0px 4px",
        textAlign:"center",
        fontWeight:"400",
        color: theme.palette.text.secondary,
        "& :hover": {
        color: theme.palette.text.primary,
        },
    },
    importStatusButton: {
        backgroundColor: theme.palette.secondary.main,
        height: "40px",
        width:"160px",
        textTransform: "none",
        borderRadius: "10px",
        margin: "0px 4px",
        textAlign:"center",
        fontWeight:"400",
        color: theme.palette.text.secondary,
        "& :hover": {
        color: theme.palette.text.primary,
        },
    },
  }
));

export default useStyles;
