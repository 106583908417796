import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dropdownContainer: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    padding: "0px 5px",
  },

  select: {
    padding: "0.2px 6px",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    width: "100%",
    height: "40px",
    color: "white",
    cursor: "pointer",
  },
}));

export default useStyles;
