import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  DepartmentCard: {
    //display: "flex",
    justifyContent: "center",
    width: "13rem",
    height: "11.5rem",
    background: theme.palette.primary.main,
    boxShadow: " 6px 11px 43px rgba(0, 0, 0, 0.45)",
    borderRadius: "10px",
    "&:hover": {
      background: theme.palette.secondary.light,
      boxShadow: " 6px 11px 43px rgba(0, 0, 0, 1);",
      cursor: "pointer",
    },
  },
  LogoDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: "-50px",
  },

  name: {
    textAlign: "center",
    margin: "8px",
    fontWeight: "700",
  },

  date: {
    textAlign: "center",
    margin: "4px",
    fontSize: "12px",
  },

  user: {
    textAlign: "center",
    margin: "4px",
    fontSize: "12px",
  },
  DepartmentIcon: {
    color: theme.palette.secondary.main,
    marginTop: "20px",
  },

  editIcon: {
    color: theme.palette.text.primary,
    width: "30px",
    height: "45px",
    paddingRight: "10px",
  },
  trashIcon: {
    color: theme.palette.text.primary,
    width: "30px",
    height: "45px",
    paddingRight: "10px",
  },

  menuPop: {
    margin: "0px",
    backgroundColor: theme.palette.secondary.light,
  },

  modalButton: {
    width: "180px",
    height: "45px",
    borderRadius: "15px",
    borderColor: theme.palette.secondary.light,
    background: theme.palette.secondary.light,
    fontFamily: "Nunito sans",
    textAlign: "center",
    color: theme.palette.text.primary,
  },
}));

export default useStyles;
