import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import TimeLineView from "./TimeLineView";
import SequencerTimeLineLayer from "./SequencerTimelineLayer/SequencerTimeLineLayer";
import TimeLineIcon from "../../Utilities/Svg/Timeline";
import { fpost, fdelete, fpatch } from "../../../API/callsAPI";
import useStyles from "./SequencerTimeLine.style";
import { CaretUp, Trash, CaretDown } from "phosphor-react";
import DeleteModal from "../../Utilities/DeleteModal/DeleteModal.component";
import { Modal } from "@material-ui/core";
import TimeLineViewV2 from "./TimeLineViewV2";
export default function SequencerTimeLine({
  layers,
  timelineShotReps,
  timelineShotCounterUpdate,
  currentGlobalTime,
  onTimelineSeek,
  sequencerData,
  setSequencerData,
  updateRefreshCounter,
  params,
  handleTimelineDoubleClickEvent,
  onTimelineClick,
  selectedShot,
  setSnackValue,
}) {
  const classes = useStyles();
  const [zoomLevel, setZoomLevel] = useState(1);
  const [diffCounter, setDiffCounter] = useState(20); //divide duration by 10 eg: for 200
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  //single state to determine to toggle disablility of arrow buttons
  const [toolsButtonDisableState, setToolsButtonDisableState] = useState({
    up: true,
    down: true,
  });
  const [showSequencerTools, setShowSequencerTools] = useState(false);

  useEffect(() => {
    checkToolsButtonVisiblity(selectedShot?.layer?.z_index);
    if (selectedShot.id) {
      setShowSequencerTools(true);
    } else {
      setShowSequencerTools(false);
    }
  }, [selectedShot]);

  function checkToolsButtonVisiblity(layerIndex) {
    //find the logic
    if (layerIndex === 1 && layerIndex === layers.length) {
      setToolsButtonDisableState({ up: true, down: true });
    } else if (layerIndex === layers.length) {
      setToolsButtonDisableState({ up: true, down: false });
    } else if (layerIndex === 1) {
      setToolsButtonDisableState({ up: false, down: true });
    } else {
      setToolsButtonDisableState({ up: false, down: false });
    }
  }

  const addNewLayer = async () => {
    let zIndex;
    zIndex = layers.length === 0 ? 1 : layers[layers.length - 1].z_index + 1;
    let layerName = zIndex > 9 ? `Layer${zIndex}` : `Layer0${zIndex}`;
    const formData = {
      name: layerName,
      visibility_status: true,
      z_index: zIndex,
      timeline: params.playlistId,
    };
    let newLayer = {
      url: "sequencers/layers/",
      data: formData,
    };
    try {
      const newLayerRes = await fpost(newLayer);
      if (newLayerRes.status === 200 || 201) {
        setSequencerData({
          ...sequencerData,
          layers: [...sequencerData.layers, newLayerRes.data],
        });
        updateRefreshCounter();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const deleteLayer = async (id) => {
    try {
      await fdelete({
        url: `sequencers/layers/${id}/`,
      });
      updateRefreshCounter();
    } catch (error) {
      console.log(error);
    }
  };

  const editLayerName = async (layerName, id) => {
    let formdata = {
      name: layerName,
    };
    let updatedLayerName = {
      url: `sequencers/layers/${id}/`,
      data: formdata,
    };
    try {
      const updatedLayer = await fpatch(updatedLayerName);
      if (updatedLayer.status === 200 || 201) {
        updateRefreshCounter();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeVisibility = async (visibility, id) => {
    let formdata = {
      visibility_status: visibility,
    };
    let updatedLayerName = {
      url: `sequencers/layers/${id}/`,
      data: formdata,
    };
    try {
      const updatedLayer = await fpatch(updatedLayerName);
      if (updatedLayer.status === 200 || 201) {
        updateRefreshCounter();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteShot = async (id) => {
    try {
      await fdelete({
        url: `sequencers/shot-reprs/${id}/`,
      });
      updateRefreshCounter();
      setIsDeleteModalOpen(false);
      setSnackValue({
        isOpen: true,
        message: `Shot Deleted SuccessFully."`,
        isError: false,
      });
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  //to find layer we have to move shot reprs
  const moveShotReprs = (tool) => {
    let ZindexToUpdate = 0;
    const index = layers.findIndex(
      (x) =>
        x.id === selectedShot.layer.id &&
        x.z_index === selectedShot.layer.z_index
    );
    if (tool === "up") {
      ZindexToUpdate = index - 1;
    } else {
      ZindexToUpdate = index + 1;
    }
    const layerToUpdate = layers[ZindexToUpdate];
    updateLayer(layerToUpdate.id, tool);
  };

  //paatch request to update new layer position to  shot reprs
  const updateLayer = async (id, tool) => {
    let formData = {
      layer: id,
    };
    let updateLayerNumber = {
      url: `sequencers/shot-reprs/${selectedShot.id}/`,
      data: formData,
    };
    try {
      const moveShotUp = await fpatch(updateLayerNumber);
      if (moveShotUp.status === 200 || 201) {
        updateRefreshCounter();
        setSnackValue({
          isOpen: true,
          message:
            tool == "up"
              ? "Shot Move Up Successfully"
              : "Shot Move Down Successfully. ",
          isError: false,
        });
        setShowSequencerTools(false);
      }
    } catch (error) {
      console.log(error);
      setSnackValue({
        isOpen: true,
        message: `Shot Deleted SuccessFully."`,
        isError: false,
      });
    }
  };

  return (
    <>
      <div
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "#252A38",
          borderRadius: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          className={classes.containerOne}
          style={{ visibility: showSequencerTools ? "visible" : "hidden" }}
        >
          <div className={classes.toolsContainer}>
            <Button
              className={classes.toolsButton}
              disabled={toolsButtonDisableState.up}
            >
              <CaretUp
                size={23}
                weight="fill"
                onClick={() => moveShotReprs("up")}
              />
            </Button>
            <Button
              className={classes.toolsButton}
              disabled={toolsButtonDisableState.down}
            >
              <CaretDown
                size={23}
                weight="fill"
                onClick={() => moveShotReprs("down")}
              />
            </Button>
            <Button
              className={classes.toolsButton}
              onClick={() => setIsDeleteModalOpen(true)}
            >
              <Trash size={23} />
            </Button>
          </div>
        </div>

        <div
          style={{
            height: "calc(100% - 40px)",
            display: "flex",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "180px",
              backgroundColor: "#1B1D28",
              height: "100%",
              border: "1.5px solid #000000",
              boxShadow: "10px 5px 15px rgba(0, 0, 0, 0.25)",
            }}
          >
            <div
              style={{
                height: "60px",
                borderBottom: "1px solid #606479",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: "5px",
                paddingLeft: "5px",
                width: "92%",
              }}
            >
              TimeLine
              <div style={{ display: "flex" }}>
                <Button
                  className={classes.layerBtn}
                  disabled={zoomLevel <= 10 ? true : false}
                  onClick={() => {
                    setZoomLevel(zoomLevel - 10);
                    setDiffCounter(diffCounter + 10);
                  }}
                >
                  -
                </Button>

                <Button className={classes.layerBtn}>
                  <TimeLineIcon />
                </Button>
                <Button
                  className={classes.layerBtn}
                  onClick={() => {
                    setZoomLevel(zoomLevel + 10);
                    setDiffCounter(diffCounter - 10);
                  }}
                >
                  +
                </Button>
              </div>
            </div>
            <Button
              className={classes.newLayers}
              onClick={addNewLayer}
              disabled={layers.length === 4 ? true : false}
            >
              <p
                className={classes.addNewLayer}
                disabled={layers.length === 4 ? true : false}
              >
                + Add New Layer
              </p>
            </Button>
            {layers?.map((layer) => {
              return (
                <SequencerTimeLineLayer
                  key={layer.id}
                  layer={layer}
                  deleteLayer={deleteLayer}
                  editLayerName={editLayerName}
                  changeVisibility={changeVisibility}
                />
              );
            })}
          </div>
          <div style={{ height: "100%", width: "100%" }}>
            {/* <TimeLineView
              zoomLevel={zoomLevel}
              diffCounter={diffCounter}
              layers={layers}
              shotReps={timelineShotReps}
              timelineShotCounterUpdate={timelineShotCounterUpdate}
              currentGlobalTime={currentGlobalTime}
              onTimelineSeek={onTimelineSeek}
              handleTimelineDoubleClickEvent={handleTimelineDoubleClickEvent}
              onTimelineClick={onTimelineClick}
            /> */}
            <TimeLineViewV2
              zoomLevel={zoomLevel}
              diffCounter={diffCounter}
              layers={layers}
              shotReps={timelineShotReps}
              timelineShotCounterUpdate={timelineShotCounterUpdate}
              currentGlobalTime={currentGlobalTime}
              onTimelineSeek={onTimelineSeek}
              handleTimelineDoubleClickEvent={handleTimelineDoubleClickEvent}
              onTimelineClick={onTimelineClick}
              sequencerData={sequencerData}
            />
          </div>
        </div>
      </div>
      <div>
        <Modal
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          open={isDeleteModalOpen}
          onClose={(e) => {
            setIsDeleteModalOpen(false);
            e.stopPropagation();
          }}
        >
          <DeleteModal
            name={"Shot"}
            handleCloseDeleteModal={() => {
              setIsDeleteModalOpen(false);
            }}
            handleDelete={() => deleteShot(selectedShot.id)}
          />
        </Modal>
      </div>
    </>
  );
}
