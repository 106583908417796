import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "509px",
    borderRadius: "20px",
    padding: "5px 30px 30px 30px",
    background: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: "Poppins",
  },
  containerHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "24px",
    fontFamily: "Poppins",
  },
  linkDiv: {
    width: "449px",
    height: "45px",
    borderRadius: "10px",
    background: theme.palette.secondary.light,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  fileLink: {
    padding: "10px 0 10px 10px",
    fontSize: "14px",
    color: theme.palette.text.tertiary,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    direction: "ltr",
  },
  copyBtn: {
    width: "150px",
    height: "100%",
    background: theme.palette.secondary.main,
    borderRadius: "10px",
    fontSize: "14px",
  },
}));

export default useStyles;
