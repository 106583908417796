import React from "react";
import useStyles from "./ImportStatusList.style";
import { Grid } from "@material-ui/core";

export default function ImportedStatusList({ status }) {
  const classes = useStyles();
  return (
    <>
      <Grid item lg={2} md={2} sm={2} xs={2}>
        <i className={status.icon}></i>
      </Grid>
      <Grid item lg={4} md={6} sm={5} xs={8}>
        <span className={classes.projectName}>{status.name}</span>
      </Grid>
      <Grid item lg={2} md={2} sm={2} xs={2}>
        <div
          style={{
            border: "2px solid white",
            borderRadius: "50%",
            height: "20px",
            width: "20px",
            backgroundColor: "green",
          }}
        ></div>
      </Grid>
    </>
  );
}
