import React from "react";
import Img from "./no-thumbnail.png";
export default function NoThumbnail(props) {
  return (
    <div
      style={{
        height:
          props.sliderState !== undefined
            ? `${props.sliderState + 45}px`
            : "45px",
        position: "relative",
        display: "inline-block",
        marginTop: "5px",
        ...props.style,
      }}
    >
      <img src={Img} alt="" height="100%" />
    </div>
  );
}
