import React from "react";
import * as Icon from "react-feather";

//@MUI
import { Button } from "@material-ui/core";

import useStyles from "./ShotVersionsListStatusButtons.styles";

export default function ShotVersionsListStatusButtons(props) {
  const classes = useStyles();
  return (
    <div>
      <Button
        className={classes.inlineButtons}
        onClick={props.openEditShotVersionModal}
      >
        <Icon.Edit3 style={{ padding: "2px" }} />
      </Button>
      {/* <Button
        variant="outlined"
        color="secondary"
        className={classes.inlineButtons}
        onClick={props.openDeleteShotVersionModal}
      >
        <Icon.Trash2 style={{ padding: "2px" }} />
      </Button> */}
    </div>
  );
}
