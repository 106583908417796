import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Popover } from "@material-ui/core";
import { getTrackableIconFromAssetName } from "../Trackables/trackablesData.jsx";
import ImageViewer from "../ImageViewer/ImageViewer.component";
import NoThumbnail from "../NoThumbnail.component";
import { useStyles } from "./TrackableViewer.js";
import TaskVersionHover from "../TaskVersionHover/TaskVersionHover.jsx";
export default function TrackableViewer({ trackable, viewType }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (anchorEl) {
      setOpen(true);
    }
  }, [anchorEl]);

  const classes = useStyles();
  const location = useLocation();

  const openTrackableInNewTab = () => {
    if (trackable.linked_class === "Asset") {
      location.pathname = `/${trackable.linked.project}/assets/${trackable.linked.id}/assetversions/`;
      window.open(`${location.pathname}`);
    } else {
      location.pathname = `/${trackable.linked.project}/sequence/${trackable.linked.parent_sequence}/shots/${trackable.linked.id}/shotversions`;
      window.open(`${location.pathname}`);
    }
  };

  const openVersionInNewTab = () => {
    const version =
      trackable.shotversions[0]?.id || trackable.assetversions[0]?.id;
    if (trackable.linked_class === "Asset") {
      location.pathname = `/${trackable.linked.project}/assets/${trackable.linked.id}/assetversions/`;
      const encode = btoa(unescape(encodeURIComponent(`versionId=${version}`)));

      window.open(`${location.pathname}?${encode}`);
    } else {
      location.pathname = `/${trackable.linked.project}/sequence/${trackable.linked.parent_sequence}/shots/${trackable.linked.id}/shotversions/`;
      const encode = btoa(unescape(encodeURIComponent(`versionId=${version}`)));
      window.open(`${location.pathname}?${encode}`);
    }
  };

  return (
    <div className={classes.trackableContainer}>
      <div className={classes.trackableIcon}>
        {getTrackableIconFromAssetName(trackable.linked_class)}
      </div>
      {trackable.linked.thumbnail ? (
        <ImageViewer
          style={{
            borderRadius: "10px",
            width: "40px",
            marginRight: "12px",
          }}
          url={trackable.linked.thumbnail}
        />
      ) : (
        <NoThumbnail style={{ marginRight: "12px", borderRadius: "10px" }} />
      )}

      <span>
        <span className={classes.onHover} onClick={openTrackableInNewTab}>
          {trackable.assetversions.length === 0 &&
          trackable.shotversions.length === 0
            ? trackable.linked.name || trackable.linked.code
            : ""}
        </span>

        {trackable.shotversions.length === 1 ||
        trackable.assetversions.length === 1 ? (
          <span
            className={classes.onHover}
            onClick={openVersionInNewTab}
            onMouseEnter={handleClick}
          >
            {trackable.shotversions[0]?.name ||
              trackable.assetversions[0]?.name}
          </span>
        ) : (
          ""
        )}
        {trackable.shotversions.length > 1 ? (
          <span onMouseEnter={handleClick}>
            {trackable.shotversions.length} Linked Versions
          </span>
        ) : trackable.assetversions.length > 1 ? (
          <span onMouseEnter={handleClick}>
            {trackable.assetversions.length} Linked Versions
          </span>
        ) : (
          ""
        )}
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              border: "none",
              boxShadow: "none",
            },
          }}
        >
          <TaskVersionHover
            versionList={
              trackable.shotversions.length
                ? trackable.shotversions
                : trackable.assetversions
            }
            parent={trackable.linked}
            viewType={viewType}
            versionLength={
              trackable.shotversions.length
                ? trackable.shotversions.length
                : trackable.assetversions.length
            }
            handleClose={handleClose}
          />
        </Popover>
      </span>
    </div>
  );
}
