import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  TaskPageModals: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  AssetImageDiv: {
    background: "#C4C4C4",
    borderRadius: "10px",
    marginRight: "20px",
  },
  AssetImage: {
    borderRadius: "8px",
  },
  DepartmentsDiv: {
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  },
  h: {},
  inLineDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  table: {
    width: "100%",
  },
  tableHeader: {
    background: theme.palette.primary.main,
    width: "100%",
  },
  tableRow: {
    borderCollapse: "collapse",
    width: "100%",
  },

  headTable: {
    color: theme.palette.text.tertiary,
    padding: "10px 0% !important",
    background: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
    fontFamily: "Poppins",
  },
  headTableTwo: {
    color: theme.palette.text.tertiary,
    padding: "10px 0% !important",
    background: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
    fontFamily: "Poppins",
  },

  listText: {
    color: theme.palette.text.primary,
    padding: "2px 0px  !important",
    width: "11%",
    height: "38px",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
  },
  listTextTwo: {
    color: theme.palette.text.primary,
    padding: "2px 0px  !important",
    width: "6%",
    height: "38px",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
  },

  trackableNameButton: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "0",
    cursor: "pointer",
    margin: "5px 0px",
    fontFamily: "poppins",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  trackableName: {
    fontSize: "18px",
    fontWeight: "600",
    padding: "5px 0",
    minWidth: 0,
    marginRight: "15px",
    textAlign: "left",
    display: "flex",
    justifyContent: "flex-start",
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
  },
  floatingDiv: {
    position: "fixed",
    right: "0px",
    width: "550px",
    borderLeft: "2px solid #606479",
    backgroundColor: theme.palette.primary.main,
    overflowX: "hidden",
    overflowY: "scroll",
    zIndex: "100",
  },
  root: {
    width: "13%",
    height: "150px",
    position: "absolute",
    marginTop: "190px",
    zIndex: 5,
    // right: "20%",
    // top: "3vh",
    // [theme.breakpoints.down("md")]: {
    //   top: "15vh",
    // },
    // [theme.breakpoints.down("sm")]: {
    //   top: "20vh",
    // },
  },
  popoverArrow: {
    // zIndex: -1,
    borderRadius: "5px",
    position: "absolute",
    top: "-15px",
    right: "10px",
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderBottom: `20px solid ${theme.palette.secondary.light}`,
    borderTop: "3px solid transparent",
    width: "0",
    height: "0",
  },
  card: {
    borderRadius: "20px",
    backgroundColor: theme.palette.secondary.light,
    padding: "13px 27px",
  },
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    padding: "13px 27px",
  },
  onHover: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
  dash: {
    color: theme.palette.text.tertiary,
  },
  date: {
    color: theme.palette.text.tertiary,
  },
}));

export default useStyles;
