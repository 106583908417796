import { React, useState } from "react";
import { Button, Slider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import {
  Play,
  Pause,
  SpeakerHigh,
  SpeakerSlash,
  ArrowCounterClockwise,
  Eye,
  EyeSlash,
  CornersOut,
  Rectangle,
} from "phosphor-react";
import useStyles from "./VideoController.styles";

export default function VideoController({
  type,
  isPlaying,
  handlePlayPause,
  currentDuration,
  totalDuration,
  onTimeSeek,
  handlePrevious,
  handleNext,
  volume,
  muted,
  onVolumeChange,
  onMute,
  onRefactor,
  setOpacityValueOne,
  setOpacityValueTwo,
  opacityValueOne,
  opacityValueTwo,
  onMuteOne,
  onMuteTwo,
}) {
  const classes = useStyles();
  //shows and hides the volume control slider
  const [showControls, setShowControls] = useState(false);
  //to set the opacity of video back to what it was on clicking the eye button
  const [visibilityOne, setVisibilityOne] = useState(1);
  const [visibilityTwo, setVisibilityTwo] = useState(1);
  const muiTheme = createMuiTheme({
    overrides: {
      MuiSlider: {
        thumb: {
          color: "#6C65D9",
        },
        track: {
          color: "#1B1D28",
          minWidth: "4px",
        },
        rail: {
          color: "#606479",
          minWidth: "4px",
        },
      },
    },
  });

  function handleKeyDown(event) {
    event.preventDefault();
  }

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          gap: "1px",
          alignItems: "center",
          border: type === "main" ? "2px solid #6C65D9" : "",
          borderRadius: type === "main" ? "20px" : "0px",
          background:
            type === "general"
              ? "linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0.61) 49.23%, rgba(0, 0, 0, 0) 100%)"
              : "",
        }}
        onKeyDown={handleKeyDown}
      >
        <Button
          onClick={() => handlePlayPause(!isPlaying)}
          style={{
            minWidth: type === "general" ? "5.5%" : "4%",
            minHeight: type === "main" ? "50%" : "50%",
            marginLeft: "0.5%",
            marginRight: type === "general" ? "1%" : "",
          }}
        >
          {isPlaying ? (
            <Pause
              style={{ height: "100%", width: "100%" }}
              weight="fill"
              className={classes.playpauseButton}
            />
          ) : (
            <Play
              style={{ height: "100%", width: "100%" }}
              weight="fill"
              className={classes.playpauseButton}
            />
          )}
        </Button>
        {type !== "general" ? (
          <Button
            style={{ minWidth: "3.5%", marginRight: "0.5%" }}
            onClick={onRefactor}
          >
            <ArrowCounterClockwise
              style={{ height: "100%", width: "100%" }}
              weight="fill"
            />
          </Button>
        ) : (
          ""
        )}
        <Button
          onClick={handlePrevious}
          className={classes.btnPrevious}
          style={{
            minWidth: "0",
            padding: "0.5%",
          }}
        >
          <Play weight="fill" className={classes.playpauseButton} />
        </Button>
        <Button
          className={classes.btnNext}
          onClick={handleNext}
          style={{
            minWidth: "0",
            padding: "0.5%",
          }}
        >
          <Play weight="fill" className={classes.playpauseButton} />
        </Button>
        <div
          style={{
            marginLeft:
              type === "general" ? "1%" : type == "onTop" ? "0.5%" : "",
            marginRight: type === "general" ? "1%" : "1%",
            width:
              type === "general" ? "77%" : type === "onTop" ? "70%" : "80%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              height:
                type === "main" ? "45%" : type === "onTop" ? "35%" : "40%",
            }}
          >
            <Slider
              className={classes.slider}
              color="secondary"
              min={0}
              max={100}
              step={0.5}
              value={(currentDuration / totalDuration) * 100}
              onChange={(seekEvent, value) => {
                seekEvent.preventDefault();
                seekEvent.stopPropagation();
                onTimeSeek((value / 100) * totalDuration);
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop:
                  type === "main" ? "-1.1%" : type === "onTop" ? "-1%" : "-2%",
              }}
            >
              <span>
                {new Date(currentDuration * 1000).toISOString().substr(14, 5)}
              </span>
              <span>
                {new Date(totalDuration * 1000).toISOString().substr(14, 5)}
              </span>
            </div>
          </div>
        </div>
        <div>
          <Rectangle
            className={classes.icon}
            style={{ marginLeft: "8px", cursor: "pointer" }}
            size={27}
            weight="bold"
          />
        </div>
        <div>
          <CornersOut
            className={classes.icon}
            style={{ marginLeft: "8px", cursor: "pointer" }}
            size={27}
            weight="bold"
          />
        </div>
        {type == "onTop" ? (
          <div style={{ display: "flex", height: "40%", width: "9%" }}>
            {opacityValueOne > 0 ? (
              <Button
                style={{ minWidth: "48%" }}
                onClick={() => {
                  setVisibilityOne(opacityValueOne);
                  setOpacityValueOne(0);
                  onMuteOne(0);
                }}
              >
                <Eye
                  style={{ height: "100%", width: "100%" }}
                  weight="duotone"
                />
                <span
                  style={{ position: "relative", left: "-5px", top: "8px" }}
                >
                  1
                </span>
              </Button>
            ) : (
              <Button
                style={{ minWidth: "50%" }}
                onClick={() => {
                  setOpacityValueOne(visibilityOne);
                  onMuteOne(50);
                }}
              >
                <EyeSlash
                  style={{ height: "100%", width: "100%" }}
                  weight="duotone"
                />
                <span
                  style={{ position: "relative", left: "-5px", top: "8px" }}
                >
                  1
                </span>
              </Button>
            )}
            {opacityValueTwo > 0 ? (
              <Button
                style={{ minWidth: "50%" }}
                onClick={() => {
                  setVisibilityTwo(opacityValueTwo);
                  setOpacityValueTwo(0);
                  onMuteTwo(0);
                }}
              >
                <Eye
                  style={{ height: "100%", width: "100%" }}
                  weight="duotone"
                />
                <span
                  style={{ position: "relative", left: "-5px", top: "8px" }}
                >
                  2
                </span>
              </Button>
            ) : (
              <Button
                style={{ minWidth: "50%" }}
                onClick={() => {
                  setOpacityValueTwo(visibilityTwo);
                  onMuteTwo(50);
                }}
              >
                <EyeSlash
                  style={{ height: "100%", width: "100%" }}
                  weight="duotone"
                />
                <span
                  style={{ position: "relative", left: "-5px", top: "8px" }}
                >
                  2
                </span>
              </Button>
            )}
          </div>
        ) : (
          ""
        )}
        <div
          className={
            showControls ? classes.showVolumeControls : classes.volumeControls
          }
          style={{
            marginBottom:
              type === "main" ? "11%" : type === "onTop" ? "16.5%" : "19%",
            width: type == "main" ? "3.5%" : type === "onTop" ? "4%" : "5.5%",
          }}
          onMouseLeave={() => setShowControls(false)}
        >
          {showControls ? (
            <ThemeProvider theme={muiTheme}>
              <Slider
                className={classes.input}
                color="secondary"
                sx={{
                  '& input[type="range"]': {
                    WebkitAppearance: "slider-vertical",
                  },
                }}
                orientation="vertical"
                valueLabelDisplay="auto"
                min={0}
                max={100}
                step={1}
                value={volume}
                onChange={(seekEvent, value) => {
                  seekEvent.preventDefault();
                  seekEvent.stopPropagation();
                  onVolumeChange(value);
                }}
              />
            </ThemeProvider>
          ) : (
            <div className={classes.input}></div>
          )}
          <Button
            style={{
              // minWidth: type === "main" ? "25px" : "100%",
              minHeight: "10% !important",
              minWidth: "100%",
              marginTop: "3%",
            }}
            onMouseEnter={() => setShowControls(true)}
            onClick={() => onMute(!muted)}
          >
            {muted == false ? (
              <SpeakerHigh
                style={{ height: "100%", width: "100%" }}
                weight="fill"
              />
            ) : (
              <SpeakerSlash
                style={{ height: "100%", width: "100%" }}
                weight="fill"
              />
            )}
          </Button>
        </div>
      </div>
    </>
  );
}
