import { Button, Divider, Modal } from "@material-ui/core";
import React, { useState } from "react";
import { fpatch } from "../../../API/callsAPI";
import ManageUsersModal from "../../Utilities/ManageUsers/ManageUsersModal/ManageUsersModal.component";
import UserAvatar from "../../Utilities/UserAvatar/UserAvatar.component";
import useStyles from "./DepartmentDetailsUsers.styles";

export default function DepartmentDetailsUsers({
  departmentId,
  isUpdating,
  setIsUpdating,
  setSnackValue,
  allUsers,
  departmentUsers,
  departmentAdmin,
  setDepartmentUsers,
  setDepartmentAdmin,
  getDepartmentInfo,
}) {
  const [isModalOpen, setModalOpen] = useState(false);
  const classes = useStyles();

  const handleFormSubmit = async (e) => {
    setIsUpdating(true);
    e.preventDefault();
    let newDepartmentData = {
      url: `department/${departmentId}/`,
      data: {
        users: departmentUsers.map((user) => {
          return user.value;
        }),
        admin_users: departmentAdmin.map((user) => {
          return user.value;
        }),
      },
    };
    try {
      const res = await fpatch(newDepartmentData);
      setIsUpdating(false);
      setSnackValue({
        isOpen: true,
        message: `SuccessFully updated user for "${res?.data?.name}"`,
        isError: false,
      });
      getDepartmentInfo();
      setModalOpen(false);
    } catch (error) {
      setIsUpdating(false);
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  const handleSelectDepartmentAdmin = (admins) => {
    setDepartmentAdmin(admins);
  };

  const handleSelectDepartmentUsers = (users) => {
    setDepartmentUsers(users);
  };

  function handleModalState() {
    setModalOpen(true);
  }

  return (
    <div>
      <div className={classes.userDiv}>
        <div className={classes.titleText}>Admin and Users</div>
      </div>
      <Divider className={classes.border} />
      <div className={classes.userDiv1}>
        <div className={classes.flexBetween}>
          <div className={classes.adminOrUserText}>Admin</div>
          {departmentAdmin && departmentUsers ? (
            <Button
              className={classes.manageUserButton}
              onClick={handleModalState}
            >
              Manage Users
            </Button>
          ) : (
            ""
          )}
        </div>

        {departmentAdmin?.length > 0 ? (
          <div className={classes.usersContainer}>
            {departmentAdmin?.map((user) => {
              return (
                <div className={classes.avatarWithName}>
                  <UserAvatar
                    avatar={user?.avatar}
                    firstName={user?.label?.split(" ")[0]}
                    lastName={user?.label?.split(" ")[1]}
                  />
                  <span>{user.label}</span>
                </div>
              );
            })}
          </div>
        ) : (
          "No Admin Found"
        )}
      </div>

      <div className={classes.userDiv1}>
        <div className={classes.adminOrUserText}>Users</div>
        {departmentUsers?.length > 0 ? (
          <div className={classes.usersContainer}>
            {departmentUsers?.map((user) => {
              return (
                <div className={classes.avatarWithName}>
                  <UserAvatar
                    avatar={user?.avatar}
                    firstName={user?.label?.split(" ")[0]}
                    lastName={user?.label?.split(" ")[1]}
                  />
                  <span>{user.label}</span>
                </div>
              );
            })}
          </div>
        ) : (
          "No User Found"
        )}
      </div>
      <div>
        <Modal
          open={isModalOpen}
          onClose={() => setModalOpen(false)}
          className={classes.departmentModal}
        >
          <ManageUsersModal
            isUpdating={isUpdating}
            handleSelectAdmin={handleSelectDepartmentAdmin}
            handleSelectUser={handleSelectDepartmentUsers}
            projectAdmin={departmentAdmin}
            projectUsers={departmentUsers}
            usersList={allUsers}
            submitManageUsersChanges={handleFormSubmit}
            closeModal={() => setModalOpen(false)}
          />
        </Modal>
      </div>
    </div>
  );
}
