import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  sequencerRoot: {
    height: "calc(100vh - 120px)",
    width: "100%",
    minWidth: "1000px",
    overflowX: "hidden",
  },
  playerAndBrowserContainer: {
    width: "100%",
    height: "60%",
    display: "flex",
    marginBottom: "15px",
  },
  sequencerBrowserContainer: {
    position: "relative",
    width: "65%",
    backgroundColor: "#252A38",
    borderRadius: "10px",
    height: "100%",
    marginRight: "10px",
    padding: "20px",
  },
  sequencerPlayerContainer: {
    width: "35%",
    padding: "1%",
    backgroundColor: "#252A38",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    height: "100%",
    marginLeft: "10px",
  },
  timelineContainer: {
    width: "100%",
    height: "35%",
  },
  sequencerPageModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
