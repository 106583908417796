import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  addReviewBtn: {
    backgroundColor: theme.palette.secondary.main,
    height: "46px",
    width: "auto",
    borderRadius: "10px",
    fontSize: "14px",
    padding: "12px",
    color: theme.palette.text.secondary,
    marginLeft: "20px",
  },
  container: {
    marginTop: "6%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "48px",
    fontFamily: "Poppins",
  },
}));

export default useStyles;
