import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    // width: "30%",
    // overflowY: "scroll",
    // height: "90%",
    [theme.breakpoints.down("lg")]: {
      width: "30%",
    },
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "60%",
    },
  },
  iconMoadlContainer: {
    width: "30%",
  },
  card: {
    borderRadius: "20px",
  },
  cardContent: {
    backgroundColor: theme.palette.primary.main,
    padding: "5px 30px",
  },
  statusModalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    color: theme.palette.secondary.main,
  },

  cardContentDiv1: {
    justifyContent: "space-around",
  },

  cardContentDiv2: {
    backgroundColor: theme.palette.secondary.light,
    maxHeight: "37px",
    //padding: "-4px",
    borderRadius: "4px",
  },

  cardContentDivColorPicker: {
    // backgroundColor: theme.palette.secondary.light,
    maxHeight: "200px",
  },

  cardContentDivIcon: {
    color: theme.palette.secondary.main,
    fontSize: "50px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },

  autoComplete: {
    marginTop: "6px",
    width: "100%",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "5px",
  },

  cardAction: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    paddingBottom: "15px ",
    "& :first-child": {
      backgroundColor: theme.palette.secondary.main,
      height: "40px",
      // width: "85px",
      textTransform: "none",
      padding: "6px ",
      borderRadius: "7px",
      color: theme.palette.text.secondary,
    },
  },

  inlineDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  iconModal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  iconContainer: {
    overflowY: "scroll",
    height: "340px",
    margin: "10px 0px",
  },
  IconInput: {
    width: "100%",
    padding: "5px",
    fontSize: "18px",
  },

  iconPickerList: {
    display: "flex",
    flexFlow: "row wrap",
    listStyle: "none",
    paddingLeft: "0",
    marginTop: "15px",
    width: "100%",
  },

  iconList: {
    color: "greenyellow",
    fontSize: "30px",
    margin: "8px",
    textAlign: "center",
    cursor: "pointer",
    padding: "5px",
  },
  iconListPicked: {
    color: "greenyellow",
    fontSize: "30px",
    margin: "8px",
    textAlign: "center",
    cursor: "pointer",
    backgroundColor: theme.palette.secondary.light,
    padding: "8px",
  },

  yesButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.text.primary,
    },
  },
}));

export default useStyles;
