import { createTheme } from "@material-ui/core/styles";

export const darkTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#1B1D28", //Dark background color
    },
    secondary: {
      main: "#6C65D9", //purple color for fonts and icons
      light: "#252A38", //grey color
    },
    text: {
      primary: "#EEEEEE", // text color black in white theme
      secondary: "#EEEEEE", //text color for buttons
      tertiary: "#606479",
    },
    border: {
      main: "white",
    },
    gradient: {
      primary: "linear-gradient(270deg, #1B1D28 9%, rgba(0, 0, 0, 0) 100%)",
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        fontFamily: "Poppins",
      },
    },
    MuiButton: {
      root: {
        fontFamily: "Poppins",
        textTransform: "None",
        minWidth: "0px",
      },
    },
    MuiPaper: {
      root: {
        fontFamily: "Poppins",
      },
    },
    MuiTab: {
      root: {
        fontFamily: "Poppins",
        textTransform: "None",
      },
    },
    MuiInput: {
      input: {
        "&::placeholder": {
          color: "white",
        },
        color: "white",
      },
    },

    MuiRadio: {
      root: {
        color: "#6C65D9",
      },
      colorSecondary: {
        "&$checked": {
          color: "#6C65D9",
        },
      },
    },

    MuiCheckbox: {
      root: {
        color: "#6C65D9",
      },
      colorSecondary: {
        "&$checked": {
          color: "#6C65D9",
        },
      },
    },
    MuiMenuItem: {
      root: {
        backgroundColor: "#1B1D28",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "10px",
        border: "1px solid #6C65D9",
        borderRadius: "5px",
        padding: "4px 6px",
        backgroundColor: "#252A38",
        color: "#EEEEEE",
      },
    },
    MuiList: {
      padding: {
        paddingTop: "0px",
        paddingBottom: "0px",
      },
    },
  },
});
export const lightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#F9F9F9", //white
    },
    secondary: {
      main: "#6C65D9", //lavender
      light: "#ECECEC",
    },
    text: {
      primary: "#000000", //black
      secondary: "white",
      tertiary: "#606060",
    },
    border: {
      main: "#000000",
    },
    gradient: {
      primary:
        "linear-gradient(270deg, #FFFFFF 15%, rgba(255, 250, 250, 0) 100%)",
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        fontFamily: "Poppins",
      },
    },
    MuiButton: {
      root: {
        fontFamily: "Poppins",
        textTransform: "None",
      },
    },
    MuiPaper: {
      root: {
        fontFamily: "Poppins",
      },
    },
    MuiTab: {
      root: {
        fontFamily: "Poppins",
        textTransform: "None",
      },
    },
    MuiInput: {
      input: {
        "&::placeholder": {
          color: "black",
        },
        color: "black",
      },
    },

    MuiRadio: {
      root: {
        color: "#6C65D9",
      },
      colorSecondary: {
        "&$checked": {
          color: "#6C65D9",
        },
      },
    },

    MuiCheckbox: {
      root: {
        color: "#6C65D9",
      },
      colorSecondary: {
        "&$checked": {
          color: "#6C65D9",
        },
      },
    },
    MuiMenuItem: {
      root: {
        backgroundColor: "#f9f9f9",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "10px",
        border: "1px solid #6C65D9",
        borderRadius: "5px",
        padding: "4px 6px",
        backgroundColor: "#ECECEC",
        color: "#000000",
      },
    },
  },
});
