import React from "react";

const DateAndTimeViewer = ({ dateAndTime }) => {
  return (
    <div style={{ display: "flex", gap: "5px" }}>
      <span>
        {new Intl.DateTimeFormat("en-US", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        }).format(new Date(dateAndTime))}
      </span>
      <span>|</span>
      <span>
        {new Intl.DateTimeFormat("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }).format(new Date(dateAndTime))}
      </span>
    </div>
  );
};

export default DateAndTimeViewer;
