import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  title: {
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "5px",
  },
  dropdowns: {
    height: "7%",
    backgroundColor: "#1B1D28",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "10px",
    padding: "0px 0px 10px 0px",
    gap: "15px",
  },
  taskListViewer: {
    height: "65%",
    backgroundColor: "#252A38",
    borderRadius: "20px 20px 0 0",
    padding: "10px 5px 12px 12px",
  },
  taskListFilterButtonContainer: {
    height: "10%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  filterButtons: {
    backgroundColor: "#1B1D28",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "10px",
    padding: "10px 15px",
    gap: "15px",
  },
  button: {
    fontSize: "12px",
    borderRadius: "10px",
    color: theme.palette.text.tertiary,
  },
  selectedButton: {
    backgroundColor: theme.palette.secondary.main,
    fontSize: "12px",
    borderRadius: "10px",
  },
  icon: {
    marginRight: "5px",
  },
  grayIcon: {
    color: theme.palette.text.tertiary,
    cursor: "pointer",
  },
  grayText: {
    color: theme.palette.text.tertiary,
  },
  taskCards: {
    height: "88%",
    overflowY: "auto",
    paddingRight: "10px",
    marginTop: "2%",
  },
  singleTaskCard: {
    backgroundColor: "#1B1D28",
    padding: "8px",
    borderRadius: "10px",
    marginBottom: "8px",
  },
  commentCountButton: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    cursor: "pointer",
  },
  dotBlur: {
    height: "7px",
    width: "7px",
    borderRadius: "50%",
    backgroundColor: "#6C65D9",
    filter: "blur(4px)",
  },
  dot: {
    height: "5px",
    width: "5px",
    borderRadius: "50%",
    backgroundColor: "#6C65D9",
    marginTop: "-5px",
  },
  taskInfoContainer: {
    height: "27%",
    backgroundColor: "#252A38",
    borderRadius: "0px 0px 20px 20px",
    padding: "10px 5px 12px 12px",
    marginTop: "1%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  taskInfoLeft: {
    width: "100%",
    borderRight: `2px solid ${theme.palette.text.tertiary}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  taskInfoRight: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  //common styles
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flexEnd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  flexColumn: {
    textAlign: "right",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },

  taskInfoTitle: {
    fontSize: "14px",
  },
  departmentName: {
    fontSize: "16px",
    color: theme.palette.text.tertiary,
    marginLeft: "10px",
  },
  number: {
    fontSize: "24px",
    color: theme.palette.secondary.main,
  },
  blueNumber: {
    fontSize: "24px",
    color: "#5C9EFF",
    fontWeight: 500,
  },
  greenNumber: {
    fontSize: "24px",
    color: "#87F056",
    fontWeight: 500,
  },
  redNumber: {
    fontSize: "24px",
    color: "#FF4E4E",
    fontWeight: 500,
  },
  yellowNumber: {
    fontSize: "24px",
    color: "#FFD232",
    fontWeight: 500,
  },
}));

export default useStyles;
