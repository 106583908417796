import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: { marginTop: "20px",position:"relative" },

  formControlLabel: {
    marginTop: "-0.5%",
    fontFamily: "Poppins, sans-serif",
  },

  labelText: {
    color: theme.palette.text.primary,
    fontWeight: "400",
  },
  statusSettingTitle: {
    color: theme.palette.text,
    // color:theme.palette.secondary.light,
    marginTop:"6px",
    padding: "0%",
    fontSize:"1rem",
    fontWeight: "600",
  },
  divContent: {
    display: "flex",
    direction: "row",
    justifyContent: "space-between",
    alginItems: "center",
  },
  divContent1: {
    display: "flex",
    direction: "row",
    position:"absolute",
    right:"3.8%",
    justifyContent: "space-between",
    alginItems: "center",
  },
  saveChangesButton: {
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    width: "150px",

    textTransform: "none",
    borderRadius: "7px",
    margin: "0px 8px",

    textAlign: "center",
    fontWeight: "400",
    color: theme.palette.text.secondary,
    "& :hover": {
      color: theme.palette.text.primary,
    },
  },
  discardChangeButton: {
    border: "1px solid",
    borderColor: theme.palette.secondary.main,
    color: theme.palette.text.primary,
    height: "40px",
    width: "181px",
    taxtAlign: "center",
    borderRadius: "7px",
    "& :hover": {
      color: theme.palette.text.primary,
    },
  },
  discardDisabledButton: {
    backgroundColor: theme.palette.text.tertiary,
    border: "1px solid",
    borderColor: theme.palette.text.tertiary,
    color: theme.palette.text.tertiary,
    height: "40px",
    width: "181px",
    taxtAlign: "center",
    borderRadius: "7px",
    "& :hover": {
      color: theme.palette.text.tertiary,
    },
  },
  saveDisableButoon: {
    backgroundColor: theme.palette.text.tertiary,
    height: "40px",
    width: "150px",

    textTransform: "none",
    borderRadius: "7px",
    margin: "0px 8px",

    textAlign: "center",
    fontWeight: "400",
    color: theme.palette.text.tertiary,
    "& :hover": {
      color: theme.palette.text.tertiary,
    },
  },
}));
export default useStyles;
