import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "6px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": " inset 0 0 5px rgb(54, 54, 54)",
      borderRadius: "10px",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "10px",
    },

    "*::-webkit-scrollbar-thumb:hover": {
      backgroundColor: theme.palette.secondary.main,
      width: "10px",
    },
  },
  root:{
    paddingRight:"2.5%"
  },
  departmentModal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, 200px)",
    justifyContent: "space-between",
    gridGap: "35px",
  },
}));

export default useStyles;
