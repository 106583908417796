import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  notificationsItems: {
    height: "50px",
    padding: "20px 20px 20px 0px",
    display: "flex",
    alignItems: "center",
    gap: "15px",
    fontFamily: "Poppins",
    fontSize: "12px",
    cursor: "pointer",
  },
  notificationsItemsUnread: {
    height: "50px",
    padding: "20px 20px 20px 0px",
    display: "flex",
    alignItems: "center",
    gap: "15px",
    fontFamily: "Poppins",
    fontSize: "12px",
    cursor: "pointer",
    background: theme.palette.secondary.light,
  },
  notificationIcon: {
    color: theme.palette.secondary.main,
    height: "22px",
    minWidth: "22px",
  },
  descriptionDropDown: {
    textAlign: "start",
    width: "70%",
    padding: "5px",
  },
  dropdownDate: {
    width: "25%",
    textAlign: "end",
    color: theme.palette.text.tertiary,
  },
  divider: {
    marginRight: "22px",
    backgroundColor: theme.palette.text.tertiary,
  },
}));

export default useStyles;
