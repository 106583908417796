import React, { useEffect, useState } from "react";
import useStyles from "./ChannelConnectorConfiguration.style";
import MicrosoftTeamsIcon from "../../../Utilities/Svg/MicrosoftTeamsIcon";
import SlackIcon from "../../../Utilities/Svg/SlackIcon";
import { Button, Card, TextField } from "@material-ui/core";
import { fpatch, fget } from "../../../../API/callsAPI";

export default function ChannelConnectorConfiguration({
  projectId,
  setSnackValue,
}) {
  const classes = useStyles();
  const [teamsUrl, setTeamsUrl] = useState("");

  const getProjects = async () => {
    try {
      const res = await fget({ url: "/project/" });
      const project = res.data.results.find((pro) => pro.id == projectId);
      if (project.webhook_url !== null) {
        setTeamsUrl(project.webhook_url);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  const handleSubmitUrl = async () => {
    let dataObj = {
      url: `project/${projectId}/`,
      data: { webhook_url: teamsUrl },
    };
    try {
      await fpatch(dataObj);
      setSnackValue({
        isOpen: true,
        message: "Connection Made Successfully",
        isError: false,
      });
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
      console.log("error", error);
    }
  };

  return (
    <>
      <div className={classes.title}>Channel Connector Configuration</div>
      <div style={{ display: "flex", gap: "48px", marginTop: "16px" }}>
        <Card className={classes.container}>
          <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <MicrosoftTeamsIcon />
            Microsoft Teams
          </div>
          <div className={classes.actionContainer}>
            <TextField
              placeholder="Paste the URL here"
              InputProps={{ disableUnderline: true }}
              variant="standard"
              className={classes.textField}
              value={teamsUrl}
              onChange={(e) => setTeamsUrl(e.target.value)}
            />
            <Button
              className={classes.submitBtn}
              disabled={teamsUrl === "" ? true : false}
              onClick={handleSubmitUrl}
            >
              Submit
            </Button>
            <Button
              className={classes.clearBtn}
              disabled={teamsUrl === "" ? true : false}
              onClick={() => setTeamsUrl("")}
            >
              Clear
            </Button>
          </div>
        </Card>
      </div>
    </>
  );
}
