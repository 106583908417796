import React from "react";
import { useStyles } from "./TableViewRowItem.style";
import UsersViewer from "../../../../Utilities/UsersViewer/UsersViewer";
// const pallet = [
//   { dark: "49, 244, 80", light: "49, 244, 80, 0.2" },
//   { dark: "244, 201, 49", light: "244, 201, 49, 0.2" },
//   { dark: "49, 244, 80", light: "49, 244, 80, 0.2" },
//   { dark: "244, 49, 49", light: "244, 49, 49, 0.2" },
//   { dark: "58, 239, 152", light: "58, 239, 152, 0.2" },
// ];
export default function TableViewRowItem({ task, handleViewTaskModal }) {
  const classes = useStyles();

  return (
    <>
      <div
        className={classes.taskItemDiv}
        onDoubleClick={() => handleViewTaskModal(task)}
        style={{
          backgroundColor: `rgba(${task.status?.color || "49, 244, 80"}, 0.2)`,
        }}
      >
        <div
          className={classes.taskItemHead}
          style={{
            backgroundColor: `rgba(${task.status?.color || "49, 244, 80"})`,
          }}
        ></div>
        <div style={{ width: "96%" }}>
          <div className={classes.taskItemTitleContainer}>
            <span
              style={{
                fontSize: "14px",
                fontFamily: "Poppins",
                color: "#F9F9F9",
              }}
            >
              {task.name}
            </span>
          </div>
          <div className={classes.taskItemUserContainer}>
            <div className={classes.taskItemUsers}>
              <UsersViewer
                selectedTask={task}
                editable="no"
                assignedUser={task.assigned_users}
              />
            </div>
            <span style={{ fontSize: "11px", fontWeight: "400" }}>
              {new Date(task.start_time).toLocaleDateString("en-US", {
                day: "2-digit",
                month: "short",
              })}
              -
              {new Date(task.end_time).toLocaleDateString("en-US", {
                day: "2-digit",
                month: "short",
              })}
            </span>
            {/* <span style={{ fontFamily: "Poppins", marginLeft: "8px" }}>
              {task.assigned_user?.first_name} - {task.assigned_user?.last_name}
            </span> */}
          </div>
        </div>
      </div>
    </>
  );
}
