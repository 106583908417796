import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "6px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": " inset 0 0 5px rgb(54, 54, 54)",
      borderRadius: "10px",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "10px",
    },

    "*::-webkit-scrollbar-thumb:hover": {
      backgroundColor: theme.palette.secondary.main,
      width: "10px",
    },
  },
  root: {
    // paddingLeft: "2%",
    display:"flex",
    flexDirection:"column",
    gap:"14px",
    paddingRight: "2.5%",
  },
  settingTitle: {
    color: theme.palette.primary,
    fontWeight: "500",
    fontSize: "24px",
  },
  statusSettingTitle: {
    color: theme.palette.text,
    // color:theme.palette.secondary.light,
    margin: "0%",
    padding: "0%",
    fontWeight: "600",
  },
  settingsPageModals: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  statusButton: {
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    // width: "145px",

    textTransform: "none",
    borderRadius: "7px",
    // margin: "0px 4px",
    color: theme.palette.text.secondary,
    "& :hover": {
      color: theme.palette.secondary.main,
    },
  },
  border: {
    color: theme.palette.text.tertiary,
  },
  settingTitle:{
    fontSize:"24px"
  },
  divChildContent1: {
    display: "flex",
    direction: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
 
}));
export default useStyles;
