import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  userViewer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  usersList: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },

  avatarWithName: {
    border: `2px solid ${theme.palette.text.tertiary}`,
    display: "flex",
    alignItems: "center",
    gap: "5px",
    borderRadius: "25px",
    paddingRight: "10px",
  },

  addUserInput: {
    width: "37px",
    height: "37px",
    borderRadius: "50%",
    borderColor: theme.palette.text.tertiary,
    color: theme.palette.text.tertiary,
    border: "2px solid",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
  },

  usersContainer: {
    display: "flex",
    direction: "row",
    flexWrap: "nowrap",
    gap: "8px",
  },
  close: {
    color: "#fff",
    backgroundColor: "#FF4F4F",
    position: "absolute",
    fontSize: "10px",
    borderRadius: "50%",
    marginLeft: "20px",
    marginTop: "-45px",
    cursor: "pointer",
    borderColor: "transparent",
  },
  crossContainer: {
    position: "absolute",
    top: "-10px",
    right: "-5px",
  },
  close2: {
    color: "#fff",
    backgroundColor: "#FF4F4F",
    fontSize: "10px",
    borderRadius: "50%",
    cursor: "pointer",
    borderColor: "transparent",
  },
}));

export default useStyles;
