const SelectVersionIcon = () => {
  return (
    <svg
      width="32"
      height="28"
      viewBox="0 0 32 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3687 22.4937C23.2046 22.6578 22.9821 22.75 22.75 22.75H5.25C5.01794 22.75 4.79538 22.6578 4.63128 22.4937C4.46719 22.3296 4.375 22.1071 4.375 21.875V12.25H23.625V13.5625"
        stroke="#F9F9F9"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.37512 12.2508L22.9689 7.26331L22.0611 3.88362C22.032 3.77239 21.9809 3.66809 21.911 3.57683C21.8411 3.48556 21.7537 3.40917 21.6538 3.35213C21.554 3.29508 21.4438 3.25854 21.3297 3.24463C21.2155 3.23072 21.0998 3.23973 20.9892 3.27112L4.09074 7.79925C3.9793 7.82848 3.87473 7.87943 3.78303 7.94918C3.69133 8.01893 3.6143 8.1061 3.55638 8.20569C3.49845 8.30528 3.46077 8.41533 3.44548 8.52952C3.43019 8.64372 3.43761 8.7598 3.4673 8.87112L4.37512 12.2508Z"
        stroke="#F9F9F9"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.2295 5.07422L19.5889 8.16953"
        stroke="#F9F9F9"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.62793 7.12109L11.9764 10.2164"
        stroke="#F9F9F9"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.15 15.7542L23.095 12.8855H24.1008L22.717 16.625H21.5695L20.1925 12.8855H21.205L22.15 15.7542Z"
        fill="#F9F9F9"
      />
      <path
        d="M24.7188 20.125H22.75V18.1562L28.6562 12.25L30.625 14.2188L24.7188 20.125Z"
        stroke="#F9F9F9"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.125 14L28.875 15.75"
        stroke="#F9F9F9"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SelectVersionIcon;
