import { makeStyles } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    trackableContainer:{
        color:theme.palette.text.tertiary,
        fontSize:"14px",
        display:"flex",
        alignContent:"center",
        
        direction:"row",
        marginLeft:"3px"
    },
    container:{
        display:"flex",
        direction:"row",
        marginLeft:"12px",
        justifyContent:"center",
        alignItems:"center",
        color:theme.palette.text.tertiary,
        "&:hover":{
            color:theme.palette.text.primary,
        }
        
    },
    selectedContainer:{
        display:"flex",
        direction:"row",
        color:"white",
        marginLeft:"12px",
        justifyContent:"center",
        alignItems:"center",
        color:theme.palette.text.primary,
        // color:theme.palette.text.tertiary,
        "&:hover":{
            color:theme.palette.text.primary,
        }
    },
    iconContainer:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        marginRight:'5px',
        
    }
}))

export default useStyles;