import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "450px",
    "& .MuiFormHelperText-root": {
      color: "#ffcc00",
    },
  },
  card: {
    borderRadius: "10px",
    color: theme.palette.text.tertiary,
  },
  cardContent: {
    backgroundColor: theme.palette.primary.main,
    padding: "20px",
    position: "relative",
  },
  dragScreen: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    display: "flex",
    fontSize: "1.5rem",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${theme.palette.border.main}`,
    borderStyle: "dashed",
    // display: "block",
    color: "#FFF",
    zIndex: "999",
    backgroundColor: "rgb(37, 42, 56,0.4)",
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: "25px",
    fontWeight: "700",
  },
  closeIcon: {
    color: theme.palette.text.primary,
    cursor: "pointer",
  },

  iconStyle: {
    width: "100%",
    marginTop: "25%",
    color: theme.palette.text.primary,
  },
  displayFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  cardContentDiv1: {
    marginTop: "5%",
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },

  cardContentDiv3: {
    backgroundColor: theme.palette.secondary.light,
    padding: "4px",
    borderRadius: "10px",
  },
  cardContentInput1: {
    padding: "0.2px 6px",
  },

  autoComplete: {
    width: "100%",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "5px",
  },

  cardAction: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "space-around",
  },

  button: {
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    width: "100%",
    textTransform: "none",
    padding: "6px",
    borderRadius: "7px",
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.text.primary,
    },
  },
  addNewStatusButton: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "10px",
  },
}));

export default useStyles;
