import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.secondary.light,
    border: "1px solid transparent",
    width: "100%",
    marginBottom: "8px",
    padding: "0px 10px",
    borderRadius: "10px",
    cursor: "move",
  },
  taskTitle: { fontSize: "16px", marginBottom: "10px" },
  displayFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  taskNameAndEdit: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inLineDiv: {
    display: "flex",
    alignItems: "center",
  },
  dragging: {
    border: `2px dashed ${theme.palette.secondary.main}`,
    borderRadius: "10px",
  },
  spacer: {
    opacity: 0,
  },
  button: {
    padding: "3px",
    width: "30px",
    backgroundColor: "transparent",
    color: theme.palette.text.primary,
    border: "none",
    marginLeft: "5px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  onHover: {
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
}));

export default useStyles;
