import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "450px",
    "& .MuiFormHelperText-root": {
      color: "#ffcc00",
    },
  },
  card: {
    borderRadius: "10px",
    color: theme.palette.text.tertiary,
  },
  cardContent: {
    backgroundColor: theme.palette.primary.main,
    padding: "20px",
    position: "relative",
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: "25px",
    fontWeight: "700",
  },
  closeIcon: {
    color: theme.palette.text.primary,
    cursor: "pointer",
  },
  displayFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  iconStyle: { width: "100%", marginTop: "18%" },

  cardContentDiv1: {
    marginTop: "5%",
  },
  cardContentDiv2: {
    width: "100%",
    height: "45px",
    padding: "0px 8px",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
  },

  cardContentDiv3: {
    backgroundColor: theme.palette.secondary.light,
    padding: "4px",
    borderRadius: "10px",
  },

  cardContentDiv4: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    width: "100%",
    height: "3.5rem",
  },

  cardContentInput2: {
    width: "100% ",
    padding: "0.2px 6px",
    height: "100%",
    overflow: "hidden",
  },

  cardContentInput1: {
    padding: "0.2px 6px",
  },

  autoComplete: {
    width: "100%",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "5px",
  },

  labelText: {
    display: "block",
    borderRadius: "5px",
    cursor: "pointer",
    textAlign: "center",
  },

  cardAction: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  
  addNewStatusButton: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "10px",
  },

  doneButton: {
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    width: "100%",
    textTransform: "none",
    padding: "6px",
    borderRadius: "7px",
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.text.primary,
    },
  },
}));

export default useStyles;
