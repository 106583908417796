import { FormControl, MenuItem, Select, Switch } from "@material-ui/core";
import useStyles from "./SequencerVersionBrowserTools.style";
import React from "react";
import { SquaresFour, Square } from "phosphor-react";
import StatusDropDown from "../../../Utilities/StatusDropDown/StatusDropDown.component";

const SequencerVersionBrowserTools = ({ handleZoom, zoom, statusList }) => {
  const classes = useStyles();
  const currentStatus = {
    id: 78,
    name: "In progress",
    code: "009",
    color: "96, 125, 139",
    icon: "fa fa-glass",
    editable: true,
    next: null,
    project: 2,
    status_class: [17, 13, 14],
  };
  return (
    <div className={classes.sequencerToolRoot}>
      <div className={classes.dropdownContainer}>
        <div>
          <FormControl fullWidth style={{ width: "100%" }}>
            <Select
              disableUnderline
              className={classes.dropDownSelect}
              value={10}
              label="Age"
              // onChange={handleChange}
            >
              <MenuItem className={classes.menuItem} value={10}>
                Sequence
              </MenuItem>
              <MenuItem className={classes.menuItem} value={20}>
                Music
              </MenuItem>
              <MenuItem className={classes.menuItem} value={30}>
                Playlist
              </MenuItem>
            </Select>
          </FormControl>
        </div>

        <div>
          <StatusDropDown
            url={`add api to change status`}
            statusList={statusList}
            currStatus={currentStatus}
            updateRefreshCounter={() => {
              console.log("add refresh counter function");
            }}
          />
        </div>
        <div>
          <FormControl fullWidth style={{ width: "100%" }}>
            <div className={classes.displayFlexAlignCenter}>
              <span style={{ marginRight: "5px" }}>Sort: </span>
              <Select
                disableUnderline
                className={classes.dropDownSelect}
                value={10}
                label="Age"
                // onChange={handleChange}
                fullWidth
              >
                <MenuItem className={classes.menuItem} value={10}>
                  A-Z
                </MenuItem>
                <MenuItem className={classes.menuItem} value={20}>
                  Z-A
                </MenuItem>
                <MenuItem className={classes.menuItem} value={30}>
                  1-100
                </MenuItem>
                <MenuItem className={classes.menuItem} value={30}>
                  100-1
                </MenuItem>
              </Select>
            </div>
          </FormControl>
        </div>
      </div>
      <div>
        <div className={classes.switchDiv}>
          <SquaresFour size={32} color="white" weight="fill" />
          <Switch
            value={zoom}
            onClick={() => {
              handleZoom();
            }}
          />
          <Square size={32} color="white" weight="fill" />
        </div>
      </div>
    </div>
  );
};

export default SequencerVersionBrowserTools;
