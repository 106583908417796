import React from "react";

const SideBySide = () => {
  return (
    <div
      style={{
        width: "65px",
        height: "25px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <svg
        width="30"
        height="20"
        viewBox="0 0 30 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1"
          width="28"
          height="18"
          stroke="#F9F9F9"
          stroke-width="2"
        />
      </svg>
      <svg
        width="30"
        height="20"
        viewBox="0 0 30 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="30" height="20" fill="#F9F9F9" />
      </svg>
    </div>
  );
};

export default SideBySide;
