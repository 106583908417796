import { makeStyles } from "@material-ui//styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "auto",
    borderRadius: "20px",
  },

  card: {
    background: theme.palette.primary.main,
    borderRadius: "10px",
  },

  headText: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    fontWeight: "550",
    fontSize: "170%",
  },

  text: {
    fontFamily: "Nunito Sans",
    fontSize: "120%",
    paddingLeft: "10px",
  },

  buttonDiv: { display: "flex" },

  yesButton: {
    backgroundColor: theme.palette.primary.main,
    // paddingBottom: "20px ",
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    width: "50%",
    textTransform: "none",
    borderRadius: "7px",
    color: theme.palette.text.secondary,
  },
  noButton: {
    marginLeft: "12px",
    width: "50%",
    height: "40px",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "5px",
    justifyContent: "center",
    color: theme.palette.secondary.main,
    fontFamily: "Nunito sans",
    cursor: "pointer",
    paddingTop: "9px",
    textAlign: "center",
  },
}));

export default useStyles;
