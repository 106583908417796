export const ActionTypes = {
  SET_ADMINUSERS: "settingAdminUser",
  GET_ADMINUSERS: "gettingAdminUserFromBackend",
};

export const setAdminUser = (user) => ({
  type: ActionTypes.SET_ADMINUSERS,
  payload: user,
});

export const getAdminUser = (projectId) => ({
  type: ActionTypes.GET_ADMINUSERS,
  meta: {
    projectId: projectId,
  },
});
