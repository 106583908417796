import React from "react";

const OnTop = (props) => {
  return (
    <div
      style={{
        width: "40px",
        height: "30px",
        position: "relative",
        zIndex: "1",
      }}
    >
      <div style={{ position: "relative", zIndex: "1" }}>
        <svg
          width="30"
          height="20"
          viewBox="0 0 30 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="30"
            height="20"
            fill={props.position == "onTop" ? "#6C65D9" : "#F9F9F9"}
          />
        </svg>
      </div>
      <div>
        <svg
          width="30"
          height="20"
          style={{
            position: "relative",
            left: "-6px",
            top: "-22",
            zIndex: "0",
          }}
          viewBox="0 0 30 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1"
            y="1"
            width="28"
            height="18"
            stroke={props.position == "bottom" ? "#6C65D9" : "#F9F9F9"}
            stroke-width="2"
          />
        </svg>
      </div>
    </div>
  );
};

export default OnTop;
