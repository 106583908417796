import React, { useState } from "react";
import useStyles from "./ReviewVersionCommentOverview.styles";
import { Button, Card, Tooltip, Divider, Modal } from "@material-ui/core";
import { CornerDownLeft, Trash2 } from "react-feather";
import { PaperPlaneTilt, PaintBrush, X } from "phosphor-react";
import SkeletonDiv from "../../VersionViewer/Comments/SkeletonDiv";
import UserAvatar from "../../UserAvatar/UserAvatar.component";
import PostEditor from "../../../PostEditor/postEditor";
import DeleteModal from "../../DeleteModal/DeleteModal.component";
import ErrorOrSuccessMessage from "../../ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";
import { fpost, fdelete } from "../../../../API/callsAPI";

export default function ReviewVersionCommentOverview({
  loggedInUser,
  selectedVersion,
  fetchComments,
  commentList,
  handleOpenCanvas,
  changeSeekTime,
  allUsersName,
}) {
  const classes = useStyles();
  const [selectedCommentId, setSelectedCommentId] = useState();
  const [isDeleteCommentModalOpen, setDeleteCommentModalOpen] = useState(false);
  const [repliedTo, setRepliedTo] = useState({});
  const [showReplyBox, setShowReplyBox] = useState(false);
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });

  let mentionsRef = React.createRef();

  const deleteComment = (comment) => {
    setSelectedCommentId(comment.id);
    setDeleteCommentModalOpen(true);
  };

  const handleCommentSubmit = async (comment, mentions, replied_to) => {
    const dataObj = {
      url: `review-version/${selectedVersion.id}/comments/`,
      data: {
        text: comment,
      },
    };
    if (mentions) dataObj.data.mentions_to = mentions;
    if (replied_to) dataObj.data.replied_to = replied_to.id;
    try {
      const commentRes = await fpost(dataObj);
      if (commentRes.status === 200 || 201) {
        fetchComments();
        return Promise.resolve();
      }
    } catch (error) {
      return Promise.reject();
    }
  };

  function handleKeyDown(event) {
    event.stopPropagation();
  }

  const scrollTo = (comment) => {
    const scrollItem = document.getElementById(comment.replied_to.id);
    scrollItem &&
      scrollItem.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const getDisplayTime = (time) => {
    if (time.includes(":")) {
      return time;
    }
    let seconds = 0;
    let minutes = 0;
    if (time % 60) {
      minutes = parseInt(time / 60);
      seconds = parseInt(time % 60);
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
    }
    return `${minutes}:${seconds}`;
  };

  const handleDeleteComment = async () => {
    try {
      await fdelete({
        url: `review/comments/${selectedCommentId}/`,
      });
      fetchComments();
      setDeleteCommentModalOpen(false);
      setSnackValue({
        isOpen: true,
        message: `SuccessFully deleted comment`,
        isError: false,
      });
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  return (
    <>
      <ErrorOrSuccessMessage snackValue={snackValue} />
      <div
        style={{
          height: "calc(100% - 107px)",
          overflowY: "scroll",
          marginBottom: "12px",
        }}
      >
        {/* single comment */}
        {commentList.loading ? (
          <div style={{ margin: "15px 5px" }}>
            {/* calling this component 3 times to fill up comments area */}
            <SkeletonDiv />
            <SkeletonDiv />
            <SkeletonDiv />
          </div>
        ) : (
          commentList?.data?.map((eachComment) => (
            <>
              <div
                id={eachComment.id}
                style={{
                  marginTop: "8px",
                }}
              >
                <div className={classes.commentHead}>
                  {/* user not an object yet! */}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <UserAvatar
                      avatar={eachComment?.user?.avatar}
                      firstName={eachComment?.user?.first_name}
                      lastName={eachComment?.user?.last_name}
                    />
                    <span style={{ margin: "0px 8px" }}>
                      {eachComment?.user?.first_name}{" "}
                      {eachComment?.user?.last_name}
                    </span>
                  </div>
                  <span
                    className={classes.time}
                    style={{
                      justifySelf: "flex-end",
                    }}
                  >
                    {new Intl.DateTimeFormat("en-US", {
                      day: "2-digit",
                      month: "long",
                      year: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    }).format(new Date(eachComment?.date_added))}
                  </span>
                </div>
                {eachComment?.replied_to !== null ? (
                  <Card
                    style={{
                      marginLeft: "30px",
                      marginTop: "10px",
                      marginBottom: "10px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      padding: "5px 15px",
                      gap: "15px",
                    }}
                    onClick={() => {
                      scrollTo(eachComment);
                    }}
                  >
                    {eachComment.replied_to?.attachment ? (
                      <img
                        // className={classes.AssetImage}
                        style={{
                          marginLeft: "40px",
                          borderRadius: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          //   setOpen(true);
                          //   setDisplayImage(eachComment.replied_to.attachment);
                        }}
                        alt="projectImage"
                        src={eachComment.replied_to.attachment}
                        height="50px"
                        width="50px"
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    ) : null}
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          eachComment.replied_to.text
                            .replace(/<p>/g, "<div>")
                            .replace(/<\/p>/g, "</div>")
                            .replace("0:0", "")
                            .slice(0, 15) + "...",
                      }}
                    ></span>
                    <div className={classes.time}>
                      {new Intl.DateTimeFormat("en-US", {
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      }).format(new Date(eachComment.replied_to.date_added))}
                    </div>
                  </Card>
                ) : (
                  ""
                )}
                <div
                  style={{
                    display: "flex",
                    marginLeft: "40px",
                    justifyContent: "space-between",
                  }}
                >
                  {/* add show more on big comments */}
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {eachComment?.text?.split("<p>")[0] && (
                      <span
                        style={{
                          // marginLeft: "40px",
                          cursor: "pointer",
                          color: "#008cdb",
                        }}
                        onClick={(e) =>
                          changeSeekTime(eachComment?.text?.split("<p>")[0])
                        }
                      >
                        {getDisplayTime(eachComment?.text?.split("<p>")[0])}
                      </span>
                    )}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: eachComment?.text
                          ?.split("<p>")[1]
                          ?.split("</p>")[0],
                      }}
                    ></span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginRight: "10px",
                    }}
                  >
                    <Tooltip title="Reply" placement="top-start">
                      <Button
                        style={{
                          padding: "0",
                          margin: "0",
                          minWidth: "25px",
                        }}
                        onClick={() => {
                          setRepliedTo(eachComment);
                          setShowReplyBox(true);
                        }}
                      >
                        <CornerDownLeft
                          style={{ hight: "16px", width: "16px" }}
                        />
                      </Button>
                    </Tooltip>
                    {/* change to user's id when updated from backend */}
                    {eachComment.user.id == loggedInUser?.id ? (
                      <Tooltip title="Delete Comment" placement="top-start">
                        <Button
                          style={{
                            padding: "0",
                            margin: "0",
                            minWidth: "25px",
                          }}
                          onClick={() => deleteComment(eachComment)}
                        >
                          <Trash2 style={{ hight: "16px", width: "16px" }} />
                        </Button>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                {eachComment?.attachment ? (
                  <img
                    // className={classes.AssetImage}
                    style={{
                      marginLeft: "40px",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      //   setOpen(true);
                      //   setDisplayImage(eachComment.attachment);
                    }}
                    alt="projectImage"
                    src={eachComment.attachment}
                    height="190px"
                    width="358px"
                    onContextMenu={(e) => e.preventDefault()}
                  />
                ) : null}
              </div>
              <Divider />
            </>
          ))
        )}
      </div>
      {showReplyBox ? (
        <div style={{ position: "relative" }} onKeyDown={handleKeyDown}>
          <Card
            className={classes.replyCard}
            z
            onClick={() => {
              scrollTo({ replied_to: repliedTo });
            }}
          >
            {repliedTo.attachment ? (
              <img
                // className={classes.AssetImage}
                onClick={() => {
                  // setOpen(true);
                  // setDisplayImage(repliedTo.attachment);
                }}
                alt="projectImage"
                src={repliedTo?.attachment}
                height="50px"
                width="50px"
                onContextMenu={(e) => e.preventDefault()}
              />
            ) : null}
            <span
              dangerouslySetInnerHTML={{
                __html:
                  repliedTo?.text
                    ?.replace(/<p>/g, "<div>")
                    ?.replace(/<\/p>/g, "</div>")
                    ?.replace("0:0", "")
                    ?.slice(0, 15) + "...",
              }}
            ></span>
            <div
              onClick={() => {
                setShowReplyBox(false);
              }}
            >
              <X size={20} />
            </div>
          </Card>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className={classes.submitCommentField}>
              <UserAvatar
                avatar={loggedInUser?.avatar}
                firstName={loggedInUser?.first_name}
                lastName={loggedInUser?.last_name}
              />
              <PostEditor
                placeholder={"Type a Comment..."}
                ref={mentionsRef}
                mentions={allUsersName}
                handleSubmit={() => {
                  handleCommentSubmit(
                    mentionsRef.current.toHtml(),
                    mentionsRef.current.onExtractMentions(),
                    repliedTo
                  ).then(() => {
                    mentionsRef.current.reset();
                    setShowReplyBox(false);
                  });
                }}
              />
              <div>
                <Button
                  style={{ minWidth: "0px", padding: "10px" }}
                  onClick={handleOpenCanvas}
                >
                  <PaintBrush size={15} />
                </Button>
              </div>
            </div>
            <Button
              className={classes.submitButton}
              onClick={() => {
                handleCommentSubmit(
                  mentionsRef.current.toHtml(),
                  mentionsRef.current.onExtractMentions(),
                  repliedTo
                ).then(() => {
                  mentionsRef.current.reset();
                  setShowReplyBox(false);
                });
              }}
            >
              <PaperPlaneTilt size={22} />
            </Button>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          onKeyDown={handleKeyDown}
        >
          <div className={classes.submitCommentField}>
            <UserAvatar
              avatar={loggedInUser?.avatar}
              firstName={loggedInUser?.first_name}
              lastName={loggedInUser?.last_name}
            />
            <PostEditor
              placeholder={"Type a Comment..."}
              ref={mentionsRef}
              mentions={allUsersName}
              handleSubmit={() => {
                handleCommentSubmit(
                  mentionsRef.current.toHtml(),
                  mentionsRef.current.onExtractMentions()
                )
                  .then(() => {
                    mentionsRef.current.reset();
                  })
                  .catch(() => {
                    mentionsRef.current.reset();
                  });
              }}
            />
            <div>
              <Button
                style={{ minWidth: "0px", padding: "10px" }}
                onClick={handleOpenCanvas}
              >
                <PaintBrush size={15} />
              </Button>
            </div>
          </div>
          <Button
            className={classes.submitButton}
            onClick={() => {
              handleCommentSubmit(
                mentionsRef.current.toHtml(),
                mentionsRef.current.onExtractMentions()
              )
                .then(() => {
                  mentionsRef.current.reset();
                })
                .catch(() => {
                  mentionsRef.current.reset();
                });
            }}
          >
            <PaperPlaneTilt size={22} />
          </Button>
        </div>
      )}
      <div>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={isDeleteCommentModalOpen}
          onClose={() => setDeleteCommentModalOpen(false)}
        >
          <DeleteModal
            type="Comment"
            name={selectedCommentId}
            handleCloseDeleteModal={() => {
              setDeleteCommentModalOpen(false);
            }}
            handleDelete={handleDeleteComment}
          />
        </Modal>
      </div>
    </>
  );
}
