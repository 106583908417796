import React from "react";
import useStyles from "./ViewImportedStatus.style";
import { Card, CardContent, Grid, Button } from "@material-ui/core";
import ImportedStatusList from "./ImportedStatusList/ImportedStatusList";

export default function ViewImportedStatus({
  importedStatus,
  closeImportedStatusModal,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div className={classes.title}>
            The following status were imported :
          </div>
          <div style={{ marginTop: "7%" }}>
            <Grid
              container
              className={classes.projectListContainer}
              spacing={2}
            >
              {importedStatus.Status?.length > 0 ? (
                importedStatus.Status.map((status) => {
                  return (
                    <Grid
                      container
                      item
                      key={status.id}
                      lg={12}
                      alignItems="center"
                      className={classes.projectList}
                    >
                      <ImportedStatusList status={status} />
                    </Grid>
                  );
                })
              ) : (
                <div style={{ padding: "2%" }}>
                  No Status Present in this project
                </div>
              )}
            </Grid>
          </div>
          <div style={{ marginTop: "6%" }}>
            <Button
              className={classes.okButton}
              onClick={() => closeImportedStatusModal()}
            >
              <b>Ok</b>
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
