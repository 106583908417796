import React, { useState, useEffect } from "react";

//@MUI
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Input,
  TextField,
  Modal,
  Grid,
  FormControlLabel,
  Checkbox,
  Box,
} from "@material-ui/core";

//react-feather
import { X } from "react-feather";
//phosphor-react
import { Asterisk } from "phosphor-react";

//@CSS
import useStyles from "./EditStatusModal.style";

//Validators
import { useFormik } from "formik";
import * as yup from "yup";
//

import { Icons } from "../NewStatus/Icons";
import { CirclePicker } from "react-color";

import { fget, fpatch } from "./../../../API/callsAPI";

import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import HelperText from "../HelperText/HelperText";

const EditStatusModal = (props) => {
  const classes = useStyles();

  const [statusClassChoice, setStatusClassChoice] = useState([]);
  const [icon, setIcon] = useState(props.editStatus.icon);
  const [viewIconModal, setViewIconModal] = useState(false);
  const [iconFilter, setIconFilter] = useState("");
  const [color, setcolor] = useState(`rgb(${props.editStatus.color})`);
  const [statusClass, setStatusClass] = useState([]);
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  const [colorChange, setColorChange] = useState(false);

  useEffect(() => {
    getstatusClassChoice();
  }, []);

  const defaultVal = [];
  var def = [];

  if (statusClassChoice.length > 0) {
    props.editStatus.status_class.map((e, i) => {
      let sequenceClass = statusClassChoice.find(
        (statusclass) => statusclass.value === e
      );
      defaultVal.push(sequenceClass.model);
    });
    defaultVal.map((v) => {
      let trackableValue = statusClassChoice.find(
        (status_class) => status_class.model === v
      );
      def.push(statusClassChoice.indexOf(trackableValue));
    });
  }

  const toggle = () => {
    setIconFilter("");
    setViewIconModal(!viewIconModal);
  };

  const getstatusClassChoice = async () => {
    try {
      const res = await fget({
        url: "trackables/trackable-classes",
      });
      const statuses = res.data?.map((statusInfo) => {
        if (props.editStatus.status_class.includes(statusInfo.id)) {
          return {
            value: statusInfo.id,
            model: statusInfo.model,
            checked: true,
          };
        } else {
          return {
            value: statusInfo.id,
            model: statusInfo.model,
            checked: false,
          };
        }
      });
      setStatusClassChoice(statuses);
      const checkedStatuses = statuses.filter((statusInfo) =>
        props.editStatus.status_class.includes(statusInfo.value)
      );
      setStatusClass(checkedStatuses);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilter = (e) => {
    setIconFilter(e.target.value);
  };

  let filteredIcons = [];
  filteredIcons = Icons.filter((icon) => {
    let text = icon.icon.toString().toLowerCase();
    return text.indexOf(iconFilter.toLowerCase()) !== -1;
  });

  const selectIcon = (icon) => {
    setIcon(icon);

    toggle();
  };

  const selectedClass = [];

  //set of predefined colors for status
  const colour = [
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffeb3b",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#607d8b",
    "#FF8888",
    "#FFE5E5",
    "#FFC1C1",
    "#FFC259",
    "#A09B6C",
    "#B1DD27",
    "#7EFE00",
    "#4DFFDB",
    "#5FD3C0",
    "#AB94DC",
    "#FF4DDB",
    "#F4939D",
  ];

  //Validators
  const NewStatusValidationScheme = yup.object({
    statusName: yup
      .string("String required")
      .label("statusName")
      .min(1, "Too Short!")
      .max(50, "Maximum Character Limit: 50")
      .required("This is a required field"),
    statusCode: yup.string().required("This field is required"),
    // icon:yup.string("String required")
  });

  const formik = useFormik({
    initialValues: {
      statusCode: props.edit ? props.editStatus.code : "",
      statusName: props.edit ? props.editStatus.name : "",
    },
    validationSchema: NewStatusValidationScheme,
    onSubmit: async (values) => {
      if (!colorChange) {
        var colorOnly = color
          .substring(color.indexOf("(") + 1, color.lastIndexOf(")") + 1)
          .split(/,s*/);
      }

      let formdata = new FormData();
      formdata.append("name", values.statusName);
      formdata.append("code", values.statusCode);
      formdata.append(
        "color",
        colorChange
          ? `${color.r}, ${color.g}, ${color.b}`
          : `${colorOnly[0]},${colorOnly[1]},${colorOnly[2].slice(0, -1)}`
      );
      formdata.append("icon", icon);
      formdata.append("project", props.projectId);
      if (statusClass.length) {
        statusClass.map((eachItem) =>
          formdata.append("status_class", eachItem.value)
        );
      }

      let editStatus = {
        url: `trackables/status/${props.editStatus.id}/`,
        data: formdata,
      };

      try {
        const res = await fpatch(editStatus);

        if (res.status === 200 || 201) {
          setSnackValue({
            isOpen: true,
            message: `Status Edited Successfully`,
            isError: false,
          });

          props.fetchShotStatus && props.fetchShotStatus();
          props.fetchTaskStatus && props.fetchTaskStatus();
          props.updateRefreshCounter();
          props.closeCreateNewStatusModal();
          props.handleCloseMenu();
        }
      } catch (error) {
        setSnackValue({
          isOpen: true,
          message: `${error}`,
          isError: true,
        });
      }
    },
  });

  const selectTrackables = (trackable, e) => {
    if (e.target.checked == true) {
      setStatusClass([...statusClass, trackable]);
    } else {
      setStatusClass(statusClass.filter((i) => i.value !== trackable.value));
    }
  };

  const trackables = (
    <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
      {statusClassChoice.map((trackable, index) => {
        return (
          <FormControlLabel
            label={trackable.model}
            control={<Checkbox defaultChecked={trackable.checked} />}
            onChange={(e) => selectTrackables(trackable, e)}
          />
        );
      })}
    </Box>
  );

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackValue.isOpen}
        autoHideDuration={2000}
        onClose={() => setSnackValue({ isOpen: false, message: "" })}
      >
        <Alert severity={snackValue.isError === false ? "success" : "warning"}>
          {snackValue.message}
        </Alert>
      </Snackbar>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <form enctype="multipart/form-data" onSubmit={formik.handleSubmit}>
            <h1 className={classes.title}>
              {props.edit ? "Edit Status" : "NewStatus"}
            </h1>
            <div className={classes.cardContentDiv1}>
              <div>
                <span> Name of Status</span>
                <Asterisk size={12} color="#FF0000" weight="fill" />
                <div style={{ marginTop: "8px" }}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Name of new status"
                    InputLabelProps={{ style: {} }}
                    disableUnderline={false}
                    name="statusName"
                    onChange={formik.handleChange}
                    value={formik.values.statusName}
                    error={Boolean(formik.errors.statusName)}
                  />
                </div>
                {formik.errors.statusName && formik.touched.statusName ? (
                  <HelperText text={formik.errors.statusName} />
                ) : null}
              </div>
              <div style={{ marginTop: "12px" }}>
                <span>Status Code</span>
                <Asterisk size={12} color="#FF0000" weight="fill" />
                <div style={{ marginTop: "8px" }}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Unique code for your status"
                    InputLabelProps={{ style: {} }}
                    disableUnderline={false}
                    name="statusCode"
                    onChange={formik.handleChange}
                    value={formik.values.statusCode}
                    error={Boolean(formik.errors.statusCode)}
                  />
                </div>
                {formik.errors.statusCode && formik.touched.statusCode ? (
                  <HelperText text={formik.errors.statusCode} />
                ) : null}
              </div>

              <div style={{ marginTop: "2%" }}>
                <Grid container spacing={1}>
                  <Grid container xs={6} spacing={2}>
                    <Grid item xs={12}>
                      <p>Select Color</p>
                      <div className={classes.cardContentDivColorPicker}>
                        <CirclePicker
                          id="color"
                          name="color"
                          colors={colour}
                          color={color}
                          circleSpacing={10}
                          onChangeComplete={(color) => {
                            setcolor(color.rgb);
                            setColorChange(true);
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.inlineDiv}>
                        <label>Select Icon</label>
                        <div
                          onClick={() => toggle()}
                          className={classes.cardContentDivIcon}
                        >
                          <i
                            className={icon}
                            style={{ fontSize: "2rem", marginRight: "8px" }}
                          ></i>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={1}>
                      <Grid item lg={12}>
                        <div style={{ marginTop: "10px" }}>
                          {statusClassChoice.length ? (
                            <>
                              <div style={{ textAlign: "center" }}>
                                <label>Select Trackable</label>
                              </div>
                              <div style={{ float: "right" }}>{trackables}</div>
                            </>
                          ) : (
                            <div className={classes.autoComplete}></div>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>

            <CardActions style={{ marginTop: "20px", padding: "0%" }}>
              <Grid container spacing={2}>
                <Grid item lg={6}>
                  <Button
                    size="large"
                    id="createStatus"
                    variant="contained"
                    fullWidth
                    type="submit"
                    className={classes.yesButton}
                  >
                    {"Yes, Edit Status"}
                  </Button>
                </Grid>
                <Grid item lg={6}>
                  <Button
                    variant="outlined"
                    size="large"
                    id="cancel"
                    fullWidth
                    onClick={() => props.closeCreateNewStatusModal()}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </form>
        </CardContent>
      </Card>

      <Modal
        open={viewIconModal}
        close={() => setViewIconModal(!viewIconModal)}
        className={classes.iconModal}
      >
        <div className={classes.iconMoadlContainer}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div>
                <div className={classes.editStatusHeader}>
                  <h1 className={classes.title}>Search Icon</h1>
                  <Button onClick={() => setViewIconModal(!viewIconModal)}>
                    <X size={24} />
                  </Button>
                </div>
                <div className={classes.cardContentDiv2}>
                  <Input
                    id="code"
                    name="code"
                    type="text"
                    disableUnderline={true}
                    placeholder="Search"
                    onChange={handleFilter}
                    value={iconFilter}
                    className={classes.IconInput}
                  />
                </div>
              </div>
              <div className={classes.iconContainer}>
                <ul className={classes.iconPickerList}>
                  {filteredIcons.map((eachIcon) => (
                    <div
                      className={
                        icon === eachIcon.icon
                          ? classes.iconListPicked
                          : classes.iconList
                      }
                    >
                      <li>
                        <span
                          onClick={() => selectIcon(eachIcon.icon)}
                          className={eachIcon.icon}
                        ></span>
                      </li>
                    </div>
                  ))}
                </ul>
              </div>
            </CardContent>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

export default EditStatusModal;
