import React from "react";
import { Card, Divider } from "@material-ui/core";
import useStyles from "./MultipleDepartmentViewer.style";

const MultipleDepartmentViewer = (props) => {
  const classes = useStyles();

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div className={classes.popoverArrowTriangle}></div>
      <div className={classes.popoverArrow}></div>
      <Card className={classes.card}>
        {props.department.map((eachDept, index) => {
          if (index !== 0) {
            return (
              <div style={{ width: "100%" }}>
                <p style={{ textAlign: "left" }}>{eachDept.name}</p>
                {index === props.department.length - 1 ? (
                  ""
                ) : (
                  <Divider style={{ width: "90%" }} />
                )}
              </div>
            );
          }
        })}
      </Card>
    </div>
  );
};

export default MultipleDepartmentViewer;
