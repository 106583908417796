import React, { useState, useEffect, useRef } from "react";
import { UserPlus } from "phosphor-react";
import useStyles from "./UsersViewer.style";
import { Avatar, Modal, Tooltip } from "@material-ui/core";
import UsersList from "../UsersList/UsersList";
import UserAvatar from "../UserAvatar/UserAvatar.component";

export default function UsersViewer(props) {
  const [isOpenUserModal, setOpenUserModal] = useState(false);

  const [assignedUser, setAssignedUser] = useState([]);
  const [seeAllUser, setSeeAllUser] = useState(true);
  const [displayUsers, setDisplayUsers] = useState(props.assignedUser);
  //useRef to see the extra users when hovered over the "+" icon
  const userNameNonEditable = useRef("");
  const userNameEditable = useRef("");

  const classes = useStyles();

  function handleUserModalChange() {
    setOpenUserModal(!isOpenUserModal);
  }
  useEffect(() => {
    if (props.assignedUser?.length > 0) {
      setAssignedUser(props.assignedUser);
      setSeeAllUser(true);
    }
  }, [props.assignedUser]);

  useEffect(() => {
    setDisplayUsers(props.assignedUser);
  }, [props.assignedUser]);

  //to display users without task data
  useEffect(() => {
    if (props?.users) {
      setAssignedUser(props.users);
      setSeeAllUser(true);
    }
  }, [props?.users]);

  useEffect(() => {
    if (seeAllUser && assignedUser.length > 4) {
      userNameNonEditable.current = "";
      assignedUser.slice(4, assignedUser.length).forEach((user, index) => {
        if (index === assignedUser.length - 5) {
          userNameNonEditable.current += `${user.first_name}`;
        } else {
          userNameNonEditable.current += `${user.first_name}, `;
        }
      });
      setSeeAllUser(false);
    }

    if (seeAllUser && assignedUser.length > 2) {
      userNameEditable.current = "";
      assignedUser.slice(2, assignedUser.length).forEach((user, index) => {
        if (index === assignedUser.length - 3) {
          userNameEditable.current += `${user.first_name}`;
        } else {
          userNameEditable.current += `${user.first_name}, `;
        }
      });
      setSeeAllUser(false);
    }
  }, [assignedUser]);

  const handleRemoveUser = (data) => {
    setDisplayUsers(displayUsers.filter((user) => user.id !== data.id));
    props.removeTaskUser(data);
  };

  return (
    <div className={classes.usersContainer}>
      {props.editable !== "no" ? (
        <>
          <div className={classes.usersContainer}>
            {displayUsers &&
              displayUsers
                ?.slice(0, 2)
                ?.map((user) => (
                  <SingleUser
                    user={user}
                    handleRemoveUser={handleRemoveUser}
                    key={user?.id}
                  />
                ))}

            <Tooltip title={userNameEditable.current}>
              <div
                style={{
                  marginLeft: "2px",
                  textAlign: "center",
                  paddingTop: "5.5px",
                  marginRight: "5px",
                }}
              >
                {assignedUser?.length > 2 && (
                  <span>+{assignedUser.length - 2}</span>
                )}
              </div>
            </Tooltip>

            <div onClick={() => handleUserModalChange()}>
              <div className={classes.addUserInput}>
                <label className={classes.labelText}>
                  <div style={{ cursor: "pointer" }}>
                    <UserPlus size={21} />
                  </div>
                </label>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={classes.usersContainer}>
            {assignedUser.length === 1 ? (
              <SingleUserWithName user={assignedUser[0]} />
            ) : (
              <>
                {assignedUser &&
                  assignedUser?.slice(0, 4).map((user, index) => (
                    <Tooltip
                      title={`${user?.first_name + " " + user?.last_name}`}
                      key={user?.id}
                    >
                      <div
                        className={`${
                          index === 0 || index === 1
                            ? classes.taskItemAvtaarOne
                            : classes.taskItemAvtaar
                        }`}
                      >
                        <UserAvatar
                          avatar={user?.avatar}
                          firstName={user?.first_name}
                          lastName={user?.last_name}
                        />
                      </div>
                    </Tooltip>
                  ))}
              </>
            )}
          </div>
          <div>
            {assignedUser?.length > 4 && (
              <Tooltip title={userNameNonEditable.current}>
                <div
                  style={{
                    marginLeft: "2px",
                    textAlign: "center",
                    paddingTop: "5.5px",
                  }}
                >
                  +{assignedUser.length - 4}
                </div>
              </Tooltip>
            )}
          </div>
        </>
      )}
      <div>
        <Modal
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          open={isOpenUserModal}
          onClose={() => setOpenUserModal(!isOpenUserModal)}
        >
          <UsersList
            getSelectedUser={props.getTaskUser}
            closeModal={() => setOpenUserModal(!isOpenUserModal)}
            type={props.type}
            assignedUser={props.assignedUser}
            usersList={props.usersList}
            showDoneButton={props.showDoneButton}
            handleSubmit={props.handleSubmit}
            handleResetAssignedUser={props?.handleResetAssignedUser}
          />
        </Modal>
      </div>
    </div>
  );
}

const SingleUser = ({ user, handleRemoveUser }) => {
  const classes = useStyles();
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Tooltip title={`${user?.first_name + " " + user?.last_name}`}>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div style={{ position: "relative" }}>
          <div className={classes.user}>
            {user?.image ? (
              <>
                <img className={classes.userImage} src={user?.image} alt="" />
              </>
            ) : (
              <>
                <UserAvatar
                  avatar={user?.avatar}
                  firstName={user?.first_name}
                  lastName={user?.last_name}
                />
              </>
            )}
          </div>

          <button
            type="button"
            className={classes.close}
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleRemoveUser(user)}
            style={{ display: isHovered ? "block" : "none" }}
          >
            <span>x</span>
          </button>
        </div>
      </div>
    </Tooltip>
  );
};

const SingleUserWithName = ({ user }) => {
  const classes = useStyles();
  return (
    <div key={user?.value} className={classes.user}>
      <div className={classes.avatarWithName}>
        <UserAvatar
          avatar={user?.avatar}
          firstName={user?.first_name}
          lastName={user?.last_name}
        />
        <span style={{ fontSize: "10px" }}>
          {user.first_name + " " + user.last_name}
        </span>
      </div>
    </div>
  );
};
