import React, { useState } from "react";
//UI
import { X, ChevronDown, UserPlus } from "react-feather";
import { Button, MenuItem, FormControl, Select } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import useStyles from "./NotificationAdvanceFilter.styles";

import moment from "moment";
import MomentUtils from "@date-io/moment";

function NotificationAdvanceFilter({
  closeAdvanceFilterModal,
  handleAdvanceFilter,
  clearAdvanceFilter,
}) {
  const classes = useStyles();
  const filter = ["1", "6", "12", "24"];

  const getFullDate = (date) => {
    let fullDate = [
      date.getFullYear(),
      ("0" + (date.getMonth() + 1)).slice(-2),
      ("0" + date.getDate()).slice(-2),
    ].join("-");
    return fullDate;
  };

  const getFormattedTime = (fulldate) => {
    let date = new Date(Date.parse(fulldate));
    let options = { hour: "2-digit", minute: "2-digit", hour12: false };
    let timeString = date.toLocaleTimeString([], options);
    return timeString;
  };

  const [startDate, setStartDate] = useState(getFullDate(new Date()));
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [endDate, setEndDate] = useState(getFullDate(new Date()));
  const [duration, setDuration] = useState("");

  const handleStartDate = (date) => {
    const finalStartDate = getFullDate(date);
    setStartDate(finalStartDate);
  };

  const handleEndDate = (date) => {
    const finalEndDate = getFullDate(date);
    setEndDate(finalEndDate);
  };

  const handleStartTime = (date) => {
    setStartTime(date);
  };

  const handleEndTime = (date) => {
    setEndTime(date);
  };

  const handleClearAdvanceFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setStartTime(null);
    setEndTime(null);
    clearAdvanceFilter();
  };

  const handleLastFewHours = (event) => {
    setDuration(event.target.value);
    setEndDate(getFullDate(new Date()));
    setEndTime(new Date());
    setStartDate(
      getFullDate(moment().subtract(event.target.value, "hours").toDate())
    );
    setStartTime(moment().subtract(event.target.value, "hours").toDate());
  };

  return (
    <div className={classes.advanceModal}>
      <div className={classes.advanceModalHeader}>
        <p style={{ fontSize: "24px" }}>Advance Filters</p>
        <Button onClick={closeAdvanceFilterModal} className={classes.crossBtn}>
          <X size="30px" />
        </Button>
      </div>
      <FormControl variant="outlined" className={classes.formControl}>
        <div className={classes.dropdownDiv}>
          <text className={classes.selectText}>
            {duration ? `Last ${duration} hours` : "None"}
          </text>
          <ChevronDown className={classes.iconStyle} />
        </div>
        <Select
          style={{ height: "0%", opacity: "0" }}
          className={classes.formLabelText}
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          label="Select Status"
          defaultValue="none"
          MenuProps={{
            classes: {
              paper: classes.durationDropDown,
            },
          }}
          value={duration}
          onChange={handleLastFewHours}
        >
          <MenuItem value={""} className={classes.menuItem}>
            <div>None</div>
          </MenuItem>

          {filter.map((eachFilter, i) => (
            <MenuItem key={i} value={eachFilter}>
              <div>Last {eachFilter} hour</div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className={classes.datePicker}>
        <div className={classes.datePickerDiv}>
          <span className={classes.datePickerText}>Start Date</span>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
              disableToolbar
              autoOk
              invalidDateMessage={"inValid"}
              variant="inline"
              format="yyyy/MM/DD"
              margin="normal"
              name="startDate"
              value={startDate}
              onChange={(date) => handleStartDate(new Date(date))}
              maxDate={new Date()}
              style={{ marginTop: "5px" }}
              InputProps={{
                disableUnderline: true,
              }}
              id="Startdate-picker"
              KeyboardButtonProps={{
                "aria-label": "change date",
                style: {
                  padding: "0px",
                },
              }}
              className={classes.dateInput}
              size="small"
              fullWidth={true}
            />
          </MuiPickersUtilsProvider>
        </div>

        <div className={classes.datePickerDiv}>
          <div>
            <span className={classes.datePickerText}>End Date</span>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDatePicker
                disableToolbar
                autoOk
                invalidDateMessage={"inValid"}
                variant="inline"
                format="yyyy/MM/DD"
                margin="normal"
                style={{ marginTop: "5px" }}
                value={endDate}
                onChange={(date) => handleEndDate(new Date(date))}
                id="Enddate-picker"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                  style: {
                    padding: "0px",
                  },
                }}
                maxDate={new Date()}
                InputProps={{
                  disableUnderline: true,
                }}
                className={classes.dateInput}
                size="small"
                fullWidth={true}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
      </div>
      <div className={classes.datePicker}>
        <div className={classes.datePickerDiv}>
          <span className={classes.datePickerText}>Time</span>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardTimePicker
              placeholder="00:00 AM"
              mask="__:__ _M"
              value={startTime}
              onChange={(date) => handleStartTime(date)}
            />
          </MuiPickersUtilsProvider>
        </div>
        {/* <Divider flexItem style={{width: "20px"}} /> */}

        <div className={classes.datePickerDiv}>
          <div>
            <span className={classes.datePickerText}>" "</span>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardTimePicker
                placeholder="00:00 AM"
                mask="__:__ _M"
                value={endTime}
                onChange={(date) => handleEndTime(date)}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
      </div>
      <div className={classes.userDiv}>
        <span className={classes.datePickerText}>User</span>
        <div style={{ marginTop: "8px" }}>
          <UserPlus className={classes.userPlusIcon} />
        </div>
      </div>
      <div className={classes.advanceNotificationBtnDiv}>
        <Button
          className={classes.filterBtn}
          onClick={() =>
            handleAdvanceFilter({
              startDate: startDate,
              endDate: endDate,
              startTime: getFormattedTime(startTime),
              endTime: getFormattedTime(endTime),
            })
          }
        >
          Filter
        </Button>
        <Button onClick={handleClearAdvanceFilter}>
          <span style={{ textDecoration: "underline" }}>Clear Filters</span>
        </Button>
      </div>
    </div>
  );
}

export default NotificationAdvanceFilter;
