import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    dateTime:{
        color:theme.palette.text.tertiary,
    },
    titleContainer:{
        display: "flex",
        flexDirection: "column",
        gap: "3%",
        width: "100%",
    },
    title:{
        fontSize: "24px", 
        fontWeight: "500"
    },
}))
export default useStyles;