import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  EditorState,
  getDefaultKeyBinding,
  RichUtils,
  convertToRaw,
} from "draft-js";
import Editor from "draft-js-plugins-editor";
import createMentionPlugin, {
  defaultSuggestionsFilter,
} from "draft-js-mention-plugin";
import { stateToHTML } from "draft-js-export-html";
import "draft-js-mention-plugin/lib/plugin.css";
import "draft-js/dist/Draft.css";
import _ from "lodash";

import MentionComponent from "./mentionComponent";
import EntryComponent from "./EntryComponent";

const cmdState = {
  handled: "handled",
  notHandled: "not-handled",
};

class PostEditor extends Component {
  constructor(props) {
    super(props);
    this.mentionPlugin = createMentionPlugin({
      entityMutability: "IMMUTABLE",
      mentionComponent: MentionComponent, // since we want to remove the entire name at once.
    });
    this.state = {
      editorState: EditorState.createEmpty(),
      suggestions: this.props.mentions,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.resetPrevious !== this?.props?.resetPrevious) {
      if (this?.props?.resetPrevious > 0) {
        this?.setState({
          editorState: EditorState.createEmpty(),
        });
      }
    }
  }

  reset = () => {
    this.setState({
      editorState: EditorState.createEmpty(),
    });
  };

  onChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  onSearchChange = ({ value }) => {
    this.setState({
      suggestions: defaultSuggestionsFilter(value, this.props.mentions),
    });
  };

  keyBindingFn = (e) => {
    // retrun custom commands on keyPress if required
    if (!e.shiftKey && e.key === "Enter") {
      return this.props.handleSubmit();
    }
    return getDefaultKeyBinding(e);
  };

  toHtml = () => {
    const contentState = this.state.editorState.getCurrentContent();
    const options = {
      // eslint-disable-next-line consistent-return
      entityStyleFn: (entity) => {
        const entityType = entity.get("type").toLowerCase();
        if (entityType === "mention") {
          const data = entity.getData();
          return {
            element: "movcolab",
            attributes: {
              "data-mention-id": _.get(data, "mention.id"),
              class: "mention_class",
              href: "",
            },
            style: {
              color: "#4ECCA3",
            },
          };
        }
      },
    };
    return stateToHTML(contentState, options);
  };

  onExtractMentions = () => {
    const contentState = this.state.editorState.getCurrentContent();
    const raw = convertToRaw(contentState);
    let mentionedUsers = [];
    for (let key in raw.entityMap) {
      const ent = raw.entityMap[key];
      if (ent.type === "mention") {
        mentionedUsers.push(ent.data.mention.id);
      }
    }

    return mentionedUsers;
  };

  handleKeyCommand = (command) => {
    // handle custom command here;

    const newState = RichUtils.handleKeyCommand(
      this.state.editorState,
      command
    );
    if (newState) {
      this.onChange(newState);
      return cmdState.handled;
    }
    return cmdState.notHandled;
  };

  handlePaste = (text) => {
    // handle url highlight here.
  };

  render() {
    const { MentionSuggestions } = this.mentionPlugin;
    const plugins = [this.mentionPlugin];
    const { className, style, placeholder } = this.props;

    return (
      <div style={{ width: "100%" }}>
        <Editor
          editorState={this.state.editorState}
          onChange={this.onChange}
          plugins={plugins}
          keyBindingFn={this.keyBindingFn}
          handleKeyCommand={this.handleKeyCommand}
          placeholder={placeholder}
          ref={(element) => {
            this.editor = element;
          }}
          handlePastedFiles={this.props.handleFilePaste}
          handlePastedText={this.handlePaste}
        />

        <MentionSuggestions
          style={{
            color: "black",
            boxShadow: "6px 11px 43px rgba(0, 0, 0, 0.65)",
          }}
          onSearchChange={this.onSearchChange}
          suggestions={this.state.suggestions}
          entryComponent={EntryComponent}
        />
      </div>
    );
  }
}

PostEditor.propTypes = {
  /**
   * mentions {array} - array of names for `@`mentions to work
   */
  mentions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  /**
   * className {string} - className applied to top most Wrapper
   */
  className: PropTypes.string,
  /**
   * style {object} - inline style to be applied to top most Wrapper
   */
  style: PropTypes.object,
  /**
   * placeholder {string} - placeholder to display when editor has no text
   */
  placeholder: PropTypes.string,

  /**
   * resetPrevious {number} - If greater than 0, reset all states to null.
   */
  resetPrevious: PropTypes.number,
};

PostEditor.defaultProps = {
  mentions: [],
  resetPrevious: 0,
};

export default PostEditor;
