import React, { useState } from "react";

//@Components
import AssetVersionsListStatusButtons from "../AssetVersionsListContents/AssetVersionsListStatusButtons.component";
import AssetVersionEditVersionModal from "../AssetsVersionsPageModal/AssetVersionEditVersionModal";

//@MUI
import { TableRow, TableCell, Modal, Chip } from "@material-ui/core";
import NoThumbnail from "../../Utilities/NoThumbnail.component";
import ImageViewer from "../../Utilities/ImageViewer/ImageViewer.component";
import useStyles from "./Assetversionlist.style";
import StatusDropDown from "../../Utilities/StatusDropDown/StatusDropDown.component";
import DeleteModal from "../../Utilities/DeleteModal/DeleteModal.component";
import { fdelete } from "../../../API/callsAPI";
import DateAndTimeViewer from "../../Utilities/DateAndTimeViewer/DateAndTimeViewer.component";
import TaskDetailsOnHover from "../../Utilities/TaskDetailsOnHover/TaskDetailsOnHover";
import { ChatText } from "phosphor-react";
import { useRef } from "react";

export default function Assetversionslist(props) {
  const ref = useRef(null);
  const [userCreatedAssetVersion, setUserCreatedAssetVersion] = useState(
    props.data.created_by
      ? props.data.created_by.first_name + " " + props.data.created_by.last_name
      : " "
  );
  const classes = useStyles();

  const [isEditAssetVerisonModalOpen, setEditAssetVersionModalOpen] =
    useState(false);

  const [isDeleteAssetVerisonModalOpen, setDeleteAssetVersionModalOpen] =
    useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [hoverOnTaskData, setHoverOnTaskData] = useState({
    isOpen: false,
    task: {},
  });

  function handleEditAssetVersionModalState(e) {
    e?.stopPropagation();
    setEditAssetVersionModalOpen(!isEditAssetVerisonModalOpen);
  }

  function handleDeleteAssetVersionModalState(e, data) {
    e?.stopPropagation();
    setDeleteData(data);
    setDeleteAssetVersionModalOpen(!isDeleteAssetVerisonModalOpen);
  }

  const handleAssetVersionDelete = async () => {
    try {
      await fdelete({
        url: `trackables/asset-version/${deleteData?.id}/`,
      });
      props.updateRefreshCounter();
      setDeleteAssetVersionModalOpen(false);
      setEditAssetVersionModalOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <TableRow
        hover
        onClick={(e) => props.openAssetDetailModal(props.data)}
        style={{
          cursor: "pointer",
          width: "100%",
          display: "table",
          tableLayout: "fixed",
        }}
      >
        <TableCell
          align="left"
          className={classes.assetVersionListPageTableCellThree}
        >
          <div style={{ display: "flex" }}>
            {props.data.thumbnail ? (
              <ImageViewer
                url={props.data.thumbnail}
                sliderState={props.isAssetTogglerOn ? 25 : 0}
              />
            ) : (
              <NoThumbnail sliderState={props.isAssetTogglerOn ? 25 : 0} />
            )}
            <p style={{ marginLeft: "10px" }}>
              {props.data.name ? props.data.name : "No Name"}
            </p>
          </div>
        </TableCell>

        <TableCell
          align="center"
          className={classes.assetVersionListPageTableCell}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DateAndTimeViewer dateAndTime={props.data.updated_at} />
          </div>
        </TableCell>
        <TableCell className={classes.assetVersionListPageTableCell}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "15px",
            }}
          >
            <StatusDropDown
              url={`trackables/asset-version/${props.data.id}/`}
              statusList={props.status}
              currStatus={props.data.status}
              updateRefreshCounter={props.updateRefreshCounter}
            />
          </div>
        </TableCell>
        <TableCell
          align="center"
          className={classes.assetVersionListPageTableCell}
        >
          <span>{userCreatedAssetVersion}</span>
        </TableCell>
        <TableCell
          align="center"
          className={classes.assetVersionListPageTableCell}
        >
          {props?.data?.task?.name ? (
            <div className={classes.flexCenter}>
              <div
                ref={ref}
                className={classes.chipAndPopupContainer}
                onMouseEnter={() =>
                  setHoverOnTaskData({
                    isOpen: true,
                    task: props.taskList?.find(
                      (task) => task.id === props?.data?.task?.id
                    ),
                  })
                }
                onMouseLeave={() =>
                  setHoverOnTaskData({ isOpen: false, task: {} })
                }
              >
                <Chip
                  className={classes.chip}
                  size="small"
                  label={props?.data?.task?.name ?? ""}
                  variant="outlined"
                />
                {hoverOnTaskData?.isOpen ? (
                  <div
                    className={classes.popUp}
                    style={{
                      right: `${ref?.current?.offsetWidth + 40}px`,
                    }}
                  >
                    <TaskDetailsOnHover task={hoverOnTaskData?.task} />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            "No Related Task"
          )}
        </TableCell>
        <TableCell
          align="center"
          className={classes.assetVersionListPageTableCellTwo}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ChatText
              size={17}
              color="#606479"
              weight="fill"
              style={{ marginRight: "7px" }}
            />
            {props.data.related_comments_number}
          </div>
        </TableCell>
        <TableCell
          align="right"
          className={classes.assetVersionListPageTableCellTwo}
        >
          <AssetVersionsListStatusButtons
            openEditAssetVerisonModal={handleEditAssetVersionModalState}
            openDeleteAssetVersionModal={handleDeleteAssetVersionModalState}
          />
        </TableCell>
        <div>
          <Modal
            className={classes.assetsVersionModals}
            open={isEditAssetVerisonModalOpen}
            onClose={(e) => {
              setEditAssetVersionModalOpen(false);
              e.stopPropagation();
            }}
          >
            <AssetVersionEditVersionModal
              taskList={props.taskList}
              fetchTasks={props.fetchTasks}
              openCreateNewStatusModal={props.openCreateNewStatusModal}
              status={props.status}
              data={props.data}
              projectId={props.projectId}
              handleEditAssetVersionModalState={
                handleEditAssetVersionModalState
              }
              handleDeleteAssetVersionModalState={
                handleDeleteAssetVersionModalState
              }
              updateRefreshCounter={props.updateRefreshCounter}
              dragScreen={props.dragScreen}
              handleDragScreen={props.handleDragScreen}
              setDragFileEnterModal={props.setDragFileEnterModal}
            />
          </Modal>
        </div>
        <div>
          <Modal
            className={classes.assetsVersionModals}
            open={isDeleteAssetVerisonModalOpen}
            onClose={(e) => {
              setDeleteAssetVersionModalOpen(false);
              e.stopPropagation();
            }}
          >
            <DeleteModal
              type="assets version"
              name={deleteData?.name}
              handleCloseDeleteModal={() => {
                setDeleteAssetVersionModalOpen(false);
              }}
              handleDelete={handleAssetVersionDelete}
            />
          </Modal>
        </div>
      </TableRow>
    </>
  );
}
