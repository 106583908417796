import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const ErrorOrSuccessMessage = ({ snackValue }) => {
  const [snackMessage, setSnackMessage] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  useEffect(() => {
    setSnackMessage({
      isOpen: snackValue?.isOpen,
      message: snackValue?.message,
      isError: snackValue?.isError,
    });
  }, [snackValue]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={snackMessage.isOpen}
      autoHideDuration={1500}
      onClose={() => setSnackMessage({ isOpen: false, message: "" })}
    >
      <Alert severity={snackMessage.isError === false ? "success" : "warning"}>
        {snackMessage.message}
      </Alert>
    </Snackbar>
  );
};

export default ErrorOrSuccessMessage;
