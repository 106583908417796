import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({

    projectListContainer:{
        borderBottom:"1px solid ",
        borderBottomColor:theme.palette.secondary.light,
        cursor:"pointer",
        // height:"18%"
    },
    icon:{
        color:theme.palette.secondary.main,
    },
    projectName:{
        color:theme.palette.secondary,
        fontWeight:"500",
        fontSize:"17px"
    }
}))

export default useStyles;