import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    color: theme.palette.text.primary,
    margin: "0 10px 0 0",
    fontSize: "20px",
  },
  inlineDiv: {
    width: "60%",
    flexWrap: "wrap",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "20px",
  },
  createPlaylistButton: {
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    width: "auto",
    borderRadius: "10px",
    fontSize: "14px",
    padding: "12px",
    color: theme.palette.text.secondary,
  },
  plusIcon: {
    width: "28px",
    height: "19px",
  },
  filterButton: {
    textTransform: "none",
    height: "40px",
    width: "126px",
    color: theme.palette.text.primary,
    margin: "0px 2px",
    borderRadius: "10px",
  },
}));

export default useStyles;
