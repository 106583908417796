import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  displayFlexAlignCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dropDownSelect: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "10px",
    padding: "10px",
    width: "160px",
  },
  menuItem: {
    backgroundColor: theme.palette.primary.main,
  },
  sequenceVersions: {
    overflowY: "auto",
    display: "flex",
    // justifyContent: "space-between",
    flexWrap: "wrap",
    rowGap: "10px",
    columnGap: "11px",
    paddingRight: "10px",
    paddingTop: "10px",
    height: "100%",
    alignContent: "flex-start",
  },
  breadcrumbDiv: {
    display: "flex",
    alignItems: "center",
    height: "10%",
  },
  selectedThumbnail: {
    border: `3px solid ${theme.palette.secondary.main}`,
    padding: "3px",
  },
  plusIcon: {
    position: "absolute",
    top: "-8px",
    right: "-8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "50%",
    fontWeight: 600,
    minWidth:"0",
    padding:"2%",
    "&:hover":{
      backgroundColor: theme.palette.secondary.main,
    }
  },
  loaderDiv: {
    height: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  versionName: {
    fontSize: "12px",
  },
}));

export default useStyles;
