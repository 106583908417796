import React from "react";
import PropTypes from "prop-types";
import ReviewVersionOverview from "../../Components/ReviewVersion/ReviewVersionOverview/ReviewVersionTools/ReviewVersionOverview.component";

export default function ReviewVersion(props) {
  return (
    <div>
      <ReviewVersionOverview
        params={props.match.params}
        searchQuery={props.searchQuery}
        externalReviewer={props.externalReviewer}
      />
    </div>
  );
}

ReviewVersion.propTypes = {
  searchQuery: PropTypes.string,
};
