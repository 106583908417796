import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  commentDiv: {
    marginTop: "20px",
    position: "absolute",
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    border: "thin white",
  },
  commentInput: {
    width: "80%",
    minHeight: "44px",
    maxHeight:"60px",
    height: "25%",
    overflowY:"auto",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    background: theme.palette.primary.main,
    padding: "10px",
  },
  sendButton: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "10px",
    margin: "4px",
    minWidth: "44px",
    height: "44px",
    "&:hover": {
      background: theme.palette.secondary.main,
      color: theme.palette.text.primary,
    },
  },
  sendIcon: {
    margin: "2px",
    color: theme.palette.text.primary,
    height: "85%",
    borderRadius: "2px",
    background: theme.palette.secondary.main,
  },
  slider: {
    width: "100%",
    color: theme.palette.secondary.main,
  },

  backgroundC: {
    background: theme.palette.primary.main,
  },
  modalHead: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.palette.secondary.main,
    fontFamily: "poppins",
  },
  canvasDiv: {
    backgroundColor: theme.palette.primary.main,
    height: "53%",
    minHeight: "500px",
    minWidth: "900px",
    width: "1220px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1205",
    borderRadius: "30px",
    display: "flex",
    flexDirection: "column",
    gap: "2%",

    padding: "1.5%",
  },
  button: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "10px",
  },
  heading: {
    fontSize: "24px",
    fontWeight: "600",
  },
  closeIcon: {
    cursor: "pointer",
    color: theme.palette.text.primary,
  },
  image: {
    borderRadius: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  anotationTools: {
    padding: "1%",
    borderRadius: "20px",
    border: `2px solid ${theme.palette.text.tertiary}`,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    gap: "6%",
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: "24px",
  },
  label: {
    fontSize: "14px",
    fontWeight: "600",
  },
  buttonContainer: {
    display: "flex",
    gap: "8%",
    alignItems: "center",
    justifyContent: "center",
  },
  btncontainer1: {
    display: "flex",
    gap: "8%",
    width: "35%",
    alignItems: "center",
    justifyContent: "end",
  },
  btncontainer2: {
    width: "50%",
    display: "flex",
    gap: "5%",
    alignItems: "center",
    justifyContent: "start",
  },
  commentBoxContainer: {
    display: "flex",
    alignItems: "center",
    gap: "3%",
    position: "absolute",
    bottom: "3%",
    width: "100%",
  },
}));

export default useStyles;
