import React from "react";
import * as Icon from "react-feather";
import { Button } from "@material-ui/core";
import useStyles from "./DepartmentPageTool.styles";
import BreadCrumb from "../../Utilities/BreadCrumb/BreadCrumb.jsx";

function DepartmentPageTool({ openModal }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <BreadCrumb />
      <Button
        size="small"
        onClick={openModal}
        className={classes.NewDepartmentButton}
        disableElevation
      >
        <Icon.Plus className={classes.plusIcon} />
        <p>New Department</p>
      </Button>
    </div>
  );
}

export default DepartmentPageTool;
