import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import useStyles from "./StatusTrakables";
import Tooltip from "@material-ui/core/Tooltip";
import { TrackableData } from "../../../../Utilities/Trackables/trackablesData";
export default function StatusTrackable(props) {
  const [trackables, setTrackables] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    const trackableStatus = props.trackable.reduce((val, id) => {
      const matches = TrackableData.filter((el) => el.id === id);

      if (matches.length > 0) {
        val = val.concat(matches);
      }
      return val;
    }, []);
    setTrackables(trackableStatus);
  }, [props.trackable]);

  return (
    <>
      {trackables &&
        trackables.map((e) => {
          return (
            <Grid item key={e.id}>
              <Tooltip
                title={e.trackable}
                placement="right"
                className={classes.tooltip}
              >
                <div
                  className={
                    props.selectedTrackable?.id === e.id
                      ? classes.selectedTrackable
                      : classes.statusTrackables
                  }
                >
                  {e.icon}
                </div>
              </Tooltip>
            </Grid>
          );
        })}
    </>
  );
}
