import React, { useState, useEffect } from "react";
//@MUI
import { Button, Modal, Snackbar } from "@material-ui/core";
//@API
import { fget, fdelete, fpatch, fpostBFF } from "../../../API/callsAPI";

//@css
import useStyles from "./MyTasksOverview.styles";

//@Components
import NoData from "../../../Pages/InformativeComponents/NoData.component";
import MyTaskPageSortByModal from "../MyTaskPageModals/MyTaskPageSortByModal";
import MyTaskPageFiltersModal from "../MyTaskPageModals/MyTaskPageFiltersModal";
import TaskPageGanttViewOverview from "../TaskPageViews/TaskPageGanttView/TaskPageGanttViewOverview";
import TaskPageListViewOverview from "../TaskPageViews/TaskPageListView/TaskPageListViewOverview";
import { clearTrackable, reOrderTask } from "./TaskPageUtilities";
import { ViewMode } from "movie-collab-gantt";
import TaskPageTools from "../TaskPageTools/TaskPageTools.component";
import NewTask from "../TaskPageModals/NewTaskModalOverview";
import TaskPageTableViewOverview from "../TaskPageViews/TaskPageTableView/TaskPageTableViewOverview";
import CreateTaskOptionModal from "../MyTaskPageModals/CreateTaskOptionModal";
import CreateTaskFromTemplate from "../TaskPageModals/CreateTaskFromTemplate";
import { Alert } from "@material-ui/lab";
import TaskPageKanbanViewOverview from "../TaskPageViews/TaskPageKanbanView/TaskPageKanbanViewOverview/TaskPageKanbanViewOverview.component";
import { StatusRearrange } from "../../Utilities/StatusRearrange/StatusRearrange";
import Loader from "../../Utilities/Loader/Loader";
import ErrorOrSuccessMessage from "../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";
import NewDataLoadingLoader from "../../Utilities/NewDataLoadingLoader/NewDataLoadingLoader.component";
import CustomPagination from "../../Utilities/CustomPagination/CustomPagination.component";

function TaskComponentOverview(props) {
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [taskStatus, setTaskStatus] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [projectUsers, setProjectUsers] = useState([]);
  const [showMyTask, setShowMyTask] = useState(false);
  //modal state
  const [isFilterModalOpen, setFilterModalOpen] = useState(false);
  const [isCreateTaskOptionModalOpen, setCreateTaskOptionModalOpen] =
    useState(false);
  const [isNewTaskModalOpen, setIsNewTaskModalOpen] = useState(false);
  const [
    isCreateTaskFromTemplateModalOpen,
    setIsCreateTaskFromTemplateModalOpen,
  ] = useState(false);
  //gantt states
  const [taskViewMode, setTaskViewMode] = useState("List");
  const [ganttViewMode, setGanttViewMode] = useState(ViewMode.Day);
  const [ganttSaveEvent, setGanttSaveEvent] = useState({});
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [assetList, setAssetList] = useState([]);
  const [sequenceList, setSequenceList] = useState([]);

  //this state retaains value of filters in parent so even modal is closed the value will persist
  const [filtersForTask, setFiltersForTask] = useState({
    department: "",
    assigned_user: "",
    status: "",
    trackable: "",
  });
  const [isFilterActive, setIsFilterActive] = useState(false);

  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  const [updating, setUpdating] = useState(false);
  const [ganttFilterLoad, setGanttFilterLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalTaskCount, setTotalTaskCount] = useState(0);
  const [isNewPageLoading, setIsNewPageLoading] = useState(false);
  const [firstRender, setFirstRender] = useState(false);
  const [filteredTaskCount, setFilteredTaskCount] = useState(0);

  const updateRefreshCounter = () => {
    setRefreshCounter(refreshCounter + 1);
  };
  useEffect(() => {
    if (firstRender && isFilterActive === false) {
      if (props.trackableType === "asset" || "shot") {
        fetchFilteredTasks({
          trackableType: props.trackableType,
          id: props.match.params.Id,
        });
      } else {
        fetchFilteredTasks();
      }
    } else if (isFilterActive) {
      handleFilterBy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCounter, showMyTask, currentPage]);

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //change it later
    return () => {
      clearTrackable();
    };
  }, []);

  useEffect(() => {
    getAllTrackables();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTasksDepartmentAndStatusList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTasksDepartmentAndStatusList = async () => {
    setIsLoaded(false);
    const data = {
      url: "web/tasks/",
      data: {
        project_id: props.match.params.projectId,
      },
    };
    try {
      const res = await fpostBFF(data);
      if (props.trackableType === "task") {
        setTaskList(res?.data?.tasks?.results);
      } else if (props.trackableType === "asset" || "shot") {
        fetchFilteredTasks({
          trackableType: props.trackableType,
          id: props.match.params.Id,
        });
      }
      setTaskStatus(StatusRearrange(res?.data?.status_list?.results));
      setDepartments(res?.data?.department_list?.results);
      setTotalTaskCount(res?.data?.tasks?.count);
      if (props.trackableType !== "asset" && props.trackableType !== "shot") {
        setIsLoaded(true);
      }
      setFirstRender(true);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllTrackables = async () => {
    try {
      const trackableRes = await fget({
        url: `trackables/asset/?project=${props.match.params.projectId}`,
      });
      setAssetList(trackableRes.data.results);
      const sequenceListRes = await fget({
        url: `trackables/shot-sequence/?project=${props.match.params.projectId}`,
      });
      setSequenceList(sequenceListRes.data.results);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFilteredTasks = async ({ trackableType, id }) => {
    let url = `trackables/task/?project=${
      props.match.params.projectId
    }&assigned=${showMyTask ? 1 : 0}&page=${currentPage}`;

    if (trackableType && id) {
      url = `trackables/task/?project=${
        props.match.params.projectId
      }&linked=${id}&assigned=${showMyTask ? 1 : 0}`;
    }
    if (firstRender || (trackableType && id)) {
      try {
        const taskListRes = await fget({
          url: url,
        });
        setTaskList(taskListRes.data.results);
        setIsLoaded(true);
        setIsNewPageLoading(false);
        setTotalTaskCount(taskListRes?.data?.count);
      } catch (error) {
        console.log(error);
        setIsLoaded(true);
        setIsNewPageLoading(false);
      }
    }
  };

  const rearrangeTaskOrder = async (orderArray) => {
    try {
      await fpatch({
        url: "trackables/task-order/",
        data: { order: orderArray },
      });
      updateRefreshCounter();
      setSnackValue({
        isOpen: true,
        message: `SuccessFully updated`,
        isError: false,
      });
    } catch (error) {
      console.log(error);
      setSnackValue({
        isOpen: true,
        message: `Oops, some error occured. Please try again`,
        isError: true,
      });
    }
  };

  const fetchUsers = async () => {
    try {
      const usersRes = await fget({
        url: `project/${props.match.params.projectId}/users`,
      });
      if (usersRes.status === 200) {
        setProjectUsers(usersRes.data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function handleNewTaskModalState() {
    setIsNewTaskModalOpen(!isNewTaskModalOpen);
  }

  function handleCreateTaskFromTemplateModalState() {
    setIsCreateTaskFromTemplateModalOpen(!isCreateTaskFromTemplateModalOpen);
  }

  function handleCreateTaskOptionModalState() {
    setCreateTaskOptionModalOpen(!isCreateTaskOptionModalOpen);
  }

  function handleFilterModalState() {
    setFilterModalOpen(!isFilterModalOpen);
  }

  //change on tab toggle
  function handleTabChange(mode) {
    setShowMyTask(false);
    setTaskViewMode(mode);
  }
  function handleGanttViewModeChange(viewMode) {
    setGanttViewMode(viewMode);
  }

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  async function handleFilterBy() {
    // const startDate =
    //   formatDate(new Date(start)) === "NaN-NaN-NaN"
    //     ? ""
    //     : formatDate(new Date(start));
    // // const endDate = formatDate(new Date(end));
    // const currentDate =
    //   formatDate(Date.now()) === "NaN-NaN-NaN" ? "" : formatDate(Date.now());
    //"NaN-NaN-NaN";
    //add start date and end date later, date is causeing a rerender loop!
    setGanttFilterLoad(true);
    setIsLoaded(false);
    setIsNewPageLoading(false);
    //url dosent works with assigned_users="" so add assigned_users only when necessary
    const filterUrl = `trackables/task/?project=${
      props.match.params.projectId
    }&assigned=${showMyTask ? 1 : 0}&page=${currentPage}&department=${
      filtersForTask.department
    }${
      filtersForTask.assigned_user !== ""
        ? `&assigned_users=${filtersForTask.assigned_user}`
        : ``
    }&linked=${filtersForTask.trackable}&status=${filtersForTask.status}`;

    try {
      const taskListRes = await fget({
        url: filterUrl,
      });

      setTaskList(taskListRes.data.results);
      setFilteredTaskCount(taskListRes.data.count);
      setGanttFilterLoad(false);
      setIsLoaded(true);
    } catch (error) {
      setGanttFilterLoad(false);
      setIsLoaded(true);
      setIsNewPageLoading(false);
    }
  }

  const handleTaskDelete = async (id) => {
    try {
      await fdelete({
        url: `trackables/task/${id}/`,
      });
      updateRefreshCounter();
      setSnackValue({
        isOpen: true,
        message: `SuccessFully deleted`,
        isError: false,
      });
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `Oops, some error occured. Please try again`,
        isError: true,
      });
    }
  };
  const [taskTemplateList, setTaskTemplateList] = useState([]);
  useEffect(() => {
    getTasksTemplates();
  }, []);

  const getTasksTemplates = async () => {
    try {
      const url = `task-template/?project=${props.match.params.projectId}`;
      const res = await fget({ url });
      setTaskTemplateList(res?.data?.results);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTaskFilter = () => {
    setIsFilterActive(true);
    if (currentPage === 1) {
      handleFilterBy();
    }
    setCurrentPage(1);
  };

  const clearFilter = () => {
    setIsFilterActive(false);
    setFilteredTaskCount(0);
    setFiltersForTask({
      department: "",
      assigned_user: "",
      status: "",
      trackable: "",
    });
    updateRefreshCounter();
  };

  const handlePageChange = (pageNo) => {
    setCurrentPage(pageNo);
  };

  return (
    <div>
      {updating ? (
        <Snackbar
          open={true}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert severity="info">Updating Please wait</Alert>
        </Snackbar>
      ) : (
        <ErrorOrSuccessMessage snackValue={snackValue} />
      )}
      <TaskPageTools
        openFilterModal={handleFilterModalState}
        openNewTaskModal={handleNewTaskModalState}
        onTabChange={handleTabChange}
        onGanttViewModeChange={(view) => handleGanttViewModeChange(view)}
        externalSaveEvent={ganttSaveEvent}
        trackableType={props.trackableType}
        params={props.match.params}
        showMyTaskCheckBox={true}
        myTaskCheckBoxState={showMyTask}
        onCheckBoxClick={() => {
          setCurrentPage(1);
          setIsLoaded(false);
          setShowMyTask(!showMyTask);
        }}
        handleCreateTaskOptionModalState={handleCreateTaskOptionModalState}
        updateRefreshCounter={updateRefreshCounter}
        filtersForTask={filtersForTask}
      />
      {isLoaded ? (
        <>
          {taskList && taskList.length !== 0 ? (
            <>
              {taskViewMode === "List" ? (
                <TaskPageListViewOverview
                  rearrangeTaskOrder={rearrangeTaskOrder}
                  groupedTask={reOrderTask(taskList)}
                  taskStatus={taskStatus}
                  updateRefreshCounter={updateRefreshCounter}
                  projectUsers={projectUsers}
                  departments={departments}
                  projectId={props.match.params.projectId}
                  handleTaskDelete={handleTaskDelete}
                  setUpdating={setUpdating}
                  trackableType={props.trackableType}
                />
              ) : taskViewMode === "Gantt" ? (
                <TaskPageGanttViewOverview
                  rearrangeTaskOrder={rearrangeTaskOrder}
                  task={taskList}
                  statusList={taskStatus}
                  projectId={props.match.params.projectId}
                  viewModeUser={ganttViewMode}
                  projectUsers={projectUsers}
                  externalSaveEvent={(event) => setGanttSaveEvent(event)}
                  onTaskRefresh={updateRefreshCounter}
                  absoluteLinked={
                    props.trackableType === "asset" || "shot"
                      ? props.match.params.Id
                      : null
                  }
                  departments={departments}
                  handleTaskDelete={handleTaskDelete}
                  ganttFilterLoad={ganttFilterLoad}
                  setGanttFilterLoad={setGanttFilterLoad}
                  trackableType={props.trackableType}
                />
              ) : taskViewMode === "Table" ? (
                <TaskPageTableViewOverview
                  rearrangeTaskOrder={rearrangeTaskOrder}
                  groupedTask={reOrderTask(taskList)}
                  taskStatus={taskStatus}
                  updateRefreshCounter={updateRefreshCounter}
                  projectUsers={projectUsers}
                  departments={departments}
                  handleTaskDelete={handleTaskDelete}
                  trackableType={props.trackableType}
                />
              ) : taskViewMode === "Kanban" ? (
                <TaskPageKanbanViewOverview
                  taskList={taskList}
                  taskStatus={taskStatus}
                  updateRefreshCounter={updateRefreshCounter}
                  projectUsers={projectUsers}
                  departments={departments}
                  setSnackValue={setSnackValue}
                  handleNewTaskModalState={handleNewTaskModalState}
                  setUpdating={setUpdating}
                  projectId={props.match.params.projectId}
                  handleTaskDelete={handleTaskDelete}
                  trackableType={props.trackableType}
                />
              ) : (
                ""
              )}
            </>
          ) : (
            <NoData />
          )}
          <>
            <footer style={{ bottom: "0", height: "100%" }}>
              <div>
                <CustomPagination
                  handlePageChange={handlePageChange}
                  currentPage={currentPage}
                  totalItem={
                    filteredTaskCount > 0 ? filteredTaskCount : totalTaskCount
                  }
                  totalPage={
                    filteredTaskCount > 0
                      ? Math.ceil(filteredTaskCount / 50)
                      : Math.ceil(totalTaskCount / 50)
                  }
                  setIsNewPageLoading={setIsNewPageLoading}
                  trackable="Tasks"
                />
              </div>
            </footer>
          </>
        </>
      ) : (
        <Loader />
      )}
      <div>
        <Modal
          open={isFilterModalOpen}
          onClose={() => setFilterModalOpen(false)}
          className={classes.TaskPageModals}
        >
          <MyTaskPageFiltersModal
            handleTaskFilter={handleTaskFilter}
            closeModal={handleFilterModalState}
            departments={departments}
            projectUsers={projectUsers}
            taskStatus={taskStatus} //list of all status in rearranged format
            trackableList={taskList}
            filtersForTask={filtersForTask} // state persisted in overview
            clearFilter={clearFilter}
            setFiltersForTask={setFiltersForTask}
            setCurrentPage={setCurrentPage}
          />
        </Modal>
        <Modal
          className={classes.TaskPageModals}
          open={isNewTaskModalOpen}
          onClose={() => setIsNewTaskModalOpen(false)}
        >
          <NewTask
            projectId={props.match.params.projectId}
            taskCount={taskList.length}
            defaultLinked={
              props.trackableType === "asset" || "shot"
                ? props.match.params.Id
                : ""
            }
            allUsers={projectUsers}
            // allTrackables={getTrackableOfTask()}
            sequenceList={sequenceList}
            assetList={assetList}
            departments={departments}
            taskStatus={taskStatus}
            handleNewTaskModal={handleNewTaskModalState}
            updateRefreshCounter={updateRefreshCounter}
            trackableType={props.trackableType}
            setSnackValue={setSnackValue}
          />
        </Modal>
        <Modal
          className={classes.TaskPageModals}
          open={isCreateTaskOptionModalOpen}
          onClose={() => setCreateTaskOptionModalOpen(false)}
        >
          <CreateTaskOptionModal
            closeModal={handleCreateTaskOptionModalState}
            handleNewTaskModal={handleNewTaskModalState}
            handleCreateTaskFromTemplateModal={
              handleCreateTaskFromTemplateModalState
            }
          />
        </Modal>
        <Modal
          className={classes.TaskPageModals}
          open={isCreateTaskFromTemplateModalOpen}
          onClose={() => setIsCreateTaskFromTemplateModalOpen(false)}
        >
          <CreateTaskFromTemplate
            projectId={props.match.params.projectId}
            defaultLinked={
              props.trackableType === "asset" || "shot"
                ? props.match.params.Id
                : null
            }
            sequenceList={sequenceList}
            assetList={assetList}
            departments={departments}
            handleCreateTaskFromTemplateModalState={
              handleCreateTaskFromTemplateModalState
            }
            updateRefreshCounter={updateRefreshCounter}
            trackableType={props.trackableType}
            setSnackValue={setSnackValue}
            taskStatus={taskStatus}
            allUsers={projectUsers}
            taskTemplateList={taskTemplateList}
          />
        </Modal>
        <Modal className={classes.TaskPageModals} open={isNewPageLoading}>
          <NewDataLoadingLoader trackable="Task" />
        </Modal>
      </div>
    </div>
  );
}

export default TaskComponentOverview;
