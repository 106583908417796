import React from "react";
import useStyles from "./ImportStatusConfirmModal.style";
import { Card, CardContent, Grid, Input, Button } from "@material-ui/core";

export default function ImportStatusConfirmModal({
  handleImportedModal,
  handleConfrimModal,
  selectedProject,
}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div>
            Are you sure you want to import statuses from{" "}
            <b>{selectedProject.name}?</b>
          </div>
          <div style={{ marginTop: "8%" }}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={6}>
                <Button
                  className={classes.allowImportButton}
                  onClick={() => handleImportedModal()}
                >
                  Yes, Import Status
                </Button>
              </Grid>
              <Grid item lg={6} md={6} sm={6}>
                <Button
                  className={classes.notAllowImportButton}
                  onClick={() => handleConfrimModal()}
                >
                  No, Don't Import
                </Button>
              </Grid>
            </Grid>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
