import { Divider, FormControl, MenuItem, Select } from "@material-ui/core";
import React, { useState } from "react";
import useStyles from "./ViewComments.style.js";
import { ArrowBendDownRight } from "phosphor-react";
import SingleComment from "./SingleComment.jsx";
export default function ViewComments({
  comments,
  isTaskDetailsExpanded,
  updateCommentsRefreshCounter,
  loggedInUser,
  projectId,
  setSnackValue,
  setRepliedTo,
  showReplay,
  setShowReplay,
}) {
  const classes = useStyles();

  const values = [
    { id: 1, name: "Newest" },
    { id: 2, name: "Oldest" },
    { id: 3, name: "Incomplete " },
    { id: 4, name: "Time code" },
  ];
  return (
    <div style={{ height: "100%" }}>
      <div className={classes.commentsTools}>
        <div className={classes.statusDropDown}>
          <FormControl fullWidth>
            <Select
              disableUnderline
              className={classes.input}
              value={values[0].id || "none"}
              // onClick={(e) => e.stopPropagation()}
              // onChange={handleStatusChange}
            >
              {values.map((status) => (
                <MenuItem
                  className={classes.menuItem}
                  value={status.id}
                  key={status.id}
                >
                  <div className={classes.filterText}>
                    <span style={{ width: "100%", overflow: "hidden" }}>
                      {status.name}
                    </span>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>{comments.length} comments</div>
      </div>
      <div className={classes.commentsContainer}>
        {comments?.length > 0 ? (
          comments.map((comment) => (
            <>
              <SingleComment
                key={comment.id}
                comment={comment}
                loggedInUser={loggedInUser}
                updateCommentsRefreshCounter={updateCommentsRefreshCounter}
                setSnackValue={setSnackValue}
                projectId={projectId}
                showReplyButton={true}
                setRepliedTo={setRepliedTo}
              />
              {comment.replied_by?.length > 0 ? (
                <span
                  className={classes.showReplays}
                  onClick={() => {
                    showReplay === comment.id
                      ? setShowReplay(null)
                      : setShowReplay(comment.id);
                  }}
                >
                  {showReplay === comment?.id
                    ? "Hide Replies"
                    : `Show ${comment.replied_by?.length} replies`}
                </span>
              ) : (
                ""
              )}
              <Divider style={{ margin: "5px 0px" }} />
              {showReplay === comment.id ? (
                <>
                  {comment.replied_by?.length > 0 ? (
                    <div>
                      {comment.replied_by.map((replyComment) => {
                        return (
                          <div style={{ marginLeft: "20px" }}>
                            <div
                              key={replyComment.id}
                              style={{
                                display: "flex",
                              }}
                            >
                              <div style={{ margin: "5px 10px 0px 0px" }}>
                                <ArrowBendDownRight />
                              </div>
                              <SingleComment
                                comment={replyComment}
                                loggedInUser={loggedInUser}
                                updateCommentsRefreshCounter={
                                  updateCommentsRefreshCounter
                                }
                                setSnackValue={setSnackValue}
                                projectId={projectId}
                                showReplyButton={false}
                                setRepliedTo={setRepliedTo}
                              />
                            </div>
                            <Divider style={{ margin: "8px 0px" }} />
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </>
          ))
        ) : (
          <span style={{ color: "grey" }}>0 comments</span>
        )}
      </div>
    </div>
  );
}
