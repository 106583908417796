import { createStore, combineReducers } from "redux";
import adminUserReducer from "./reducers/adminUser";
import currentUserReducer from "./reducers/currentUser";
import LoaderReducer from "./reducers/Loader";
import { composeWithDevTools } from "redux-devtools-extension";

const reducers = combineReducers({
  adminUserReducer,
  currentUserReducer,
  LoaderReducer,
});

export default createStore(reducers, composeWithDevTools());
