import React, { useState } from "react";

//@MUI
import useStyles from "./ReviewPageShareFileModal.styles";
import { Button, CircularProgress } from "@material-ui/core";
import { CheckCircle, X } from "phosphor-react";

function ReviewPageShareFileModal({ handleShareFileModal, versionId }) {
  const classes = useStyles();
  const url =
    versionId !== ""
      ? window.location.href + `/${versionId}/version`
      : window.location.href;
  const [isCopying, setIsCopying] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const copyVersionUrl = () => {
    setIsCopying(true);
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setIsCopying(false);
        setIsCopied(true);
      })
      .catch((error) => {
        console.log("error", error);
        setIsCopying(false);
      });
  };

  return (
    <div className={classes.container}>
      <div className={classes.containerHeader}>
        <p>Share File for Review</p>
        <Button onClick={handleShareFileModal}>
          <X size={28} />
        </Button>
      </div>
      <div className={classes.linkDiv}>
        <p className={classes.fileLink}>{url}</p>
        <Button className={classes.copyBtn} onClick={copyVersionUrl}>
          {isCopying ? (
            <CircularProgress />
          ) : isCopied ? (
            <CheckCircle size={18} />
          ) : (
            "Copy Link"
          )}
        </Button>
      </div>
    </div>
  );
}

export default ReviewPageShareFileModal;
