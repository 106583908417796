import { Button, CircularProgress } from "@material-ui/core";
import React from "react";
import { Send, X } from "react-feather";
import { ArrowBendDownRight } from "phosphor-react";
import { fpost, fget } from "../../../API/callsAPI";
import PostEditor from "../../PostEditor/postEditor";
import useStyles from "./UpdatesPageCardComment.styles";

export default function UpdatesPageCardComment(props) {
  const classes = useStyles();
  let mentionsRef = React.createRef();
  const [addingComment, setAddingComment] = React.useState(false);
  const [resetCommentText, setResetCommentText] = React.useState(0);

  const handleSubmit = async () => {
    setAddingComment(true);
    let newSequence = {
      url: "trackables/trackable-comment/",
      data: {
        description: mentionsRef.current.toHtml(),
        mentions: mentionsRef.current.onExtractMentions(),
        trackable: props.trackableId,
        version_id: props.versionId,
      },
    };
    if (props?.repliedTo?.id) newSequence.data.replied_to = props.repliedTo.id;

    try {
      const result = await fpost(newSequence);
      props.setRepliedTo({});
      mentionsRef?.current?.reset();
      setAddingComment(false);
      setResetCommentText(resetCommentText + 1);

      const res = await fget({
        url: `user/${result.data.author}`,
      });
      result.data.author = { ...res.data };
      props.fetchComments();
      props.updateCommentCount();
    } catch (error) {
      console.log(error);
      setAddingComment(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div className={classes.commentDiv}>
        {props.repliedTo?.id && (
          <div className={classes.replyDiv}>
            <div className={classes.replyingToText}>
              <ArrowBendDownRight size={20} className={classes.text} />
              <span className={classes.text}>Replying to :</span>
              <span>
                {props.repliedTo?.author?.first_name}{" "}
                {props.repliedTo?.author?.last_name}
              </span>
            </div>
            <X
              size={20}
              className={classes.icon2}
              onClick={() => props.setRepliedTo({})}
            />
          </div>
        )}

        <div className={classes.commentBox}>
          <PostEditor
            placeholder={"Comment Here...."}
            // mentions={[{ name: "Foo", id: "1" }, { name: "Bar", id: "2" }]}
            mentions={props.users}
            ref={mentionsRef}
            handleSubmit={handleSubmit}
            resetPrevious={resetCommentText}
          />
        </div>
      </div>

      <div style={{ width: "12%", display: "flex" }}>
        <Button
          className={classes.sendBtn}
          size="small"
          onClick={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          disabled={addingComment ? true : false}
        >
          {addingComment ? (
            <CircularProgress size={15} />
          ) : (
            <Send className={classes.icon} />
          )}
        </Button>
      </div>
    </div>
  );
}
