import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "360px",
    borderRadius: "20px",
    position: "absolute",
    right: "10%",
    top: "19vh",
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("md")]: {
      top: "15%",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20%",
    },
  },
  popoverArrow: {
    zIndex: -1,
    borderRadius: "5px",
    position: "absolute",
    top: "-15px",
    right: "10px",
    borderLeft: "20px solid transparent",
    borderRight: "20px solid transparent",
    borderBottom: `20px solid ${theme.palette.primary.main}`,
    width: "0",
    height: "0",
  },
  card: {
    borderRadius: "20px",
  },
  cardContent: {
    backgroundColor: theme.palette.primary.main,
    padding: "13px 27px",
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: "600",
  },
  clear: {
    cursor: "pointer",
    color: theme.palette.text.primary,
    display: "flex",
    alignItems: "center",
  },
  cardAction: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    padding: "13px 27px",
    justifyContent: "space-between",
    "& :hover": {
      color: theme.palette.text.tertairy,
    },
  },
  doneButton: {
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    width: "115px",
    textTransform: "none",
    padding: "6px ",
    borderRadius: "7px",
    marginBottom: "15px",
    color: theme.palette.text.secondary,
  },
  textGray: {
    color: theme.palette.text.tertiary,
  },
  formControl: {
    alignItems: "center",
    justifyContent: "center",
    width: "230px",
    height: "40px",
    background: theme.palette.secondary.light,
    borderRadius: "10px",
  },
  iconStyle: {
    marginRight: "10px",
    height: "20px",
    color: theme.palette.secondary.main,
  },
  select: {
    // marginLeft: "4px",
    // marginBottom: "2.2px",
    padding: "9px",
    borderRadius: "5px",
    backgroundColor: theme.palette.secondary.light,
    width: "100%",
    minHeight: "28px",
  },
  taskSelect: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  statusSelect: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    marginBottom: "20px",
  },
}));

export default useStyles;
