import Skeleton from "@material-ui/lab/Skeleton";

// single skeleton div of one comment modal
const SkeletonDiv = () => {
  return (
    <>
      <div style={{ display: "flex", gap: "5px" }}>
        <Skeleton
          variant="rounded"
          width={50}
          height={50}
          style={{ borderRadius: "50px" }}
        />
        <Skeleton variant="text" width={150} height={40} />
        <Skeleton
          variant="text"
          width={150}
          height={40}
          style={{ marginLeft: "30%" }}
        />
      </div>
      <Skeleton
        variant="rectangular"
        height={60}
        style={{ margin: "15px 5px" }}
      />

      <Skeleton variant="text" height={5} style={{ margin: "10px 5px" }} />
    </>
  );
};

export default SkeletonDiv;
