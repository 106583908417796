import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "450px",
    "& .MuiFormHelperText-root": {
      color: "#ffcc00",
    },
  },
  card: {
    borderRadius: "10px",
    boxShadow: "none",
  },
  cardContent: {
    backgroundColor: theme.palette.primary.main,
    padding: "20px 60px",
    position: "relative",
  },
  formContentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
  inputAndTitleContainer: {
    marginTop: "15px",
  },
  inputTitle: {
    color: theme.palette.text.primary,
    fontSize: "25px",
    fontWeight: "400",
  },
  issueNameInput: {
    backgroundColor: theme.palette.secondary.light,
    padding: "4px",
    borderRadius: "10px",
    height: "40px",
  },
  cardContentInput: {
    padding: "0.2px 6px",
  },
  descriptionInput: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    height: "110px",
    width: "100%",
    marginTop: "10px",
  },
  dragScreen: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    display: "flex",
    fontSize: "1.5rem",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${theme.palette.border.main}`,
    borderStyle: "dashed",
    // display: "block",
    color: "#FFF",
    zIndex: "999",
    backgroundColor: "rgb(37, 42, 56,0.4)",
  },

  cardAction: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "space-between",
    marginTop: "50px",
    padding: "0px",
    gap: "30px",
  },

  reportButton: {
    backgroundColor: theme.palette.secondary.main,
    height: "45px",
    width: "100%",
    textTransform: "none",
    padding: "6px",
    borderRadius: "10px",
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.text.primary,
    },
  },
  clearButton: {
    border: `2px solid ${theme.palette.secondary.main}`,
    height: "45px",
    width: "100%",
    textTransform: "none",
    padding: "6px",
    borderRadius: "10px",
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.text.primary,
    },
  },
}));

export default useStyles;
