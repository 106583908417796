const MicrosoftTeamsIcon = () => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_8376_16922)">
        <path
          d="M9.07991 4.48376H12.4422C12.7599 4.48376 13.0174 4.73807 13.0174 5.05173V8.07588C13.0174 9.22869 12.071 10.1633 10.9035 10.1633H10.8935C9.72603 10.1634 8.77945 9.22904 8.7793 8.07618V4.78065C8.77929 4.74167 8.78706 4.70306 8.80216 4.66704C8.81727 4.63102 8.83941 4.59829 8.86733 4.57072C8.89524 4.54315 8.92838 4.52129 8.96486 4.50636C9.00133 4.49144 9.04043 4.48376 9.07991 4.48376Z"
          fill="#5059C9"
        />
        <path
          d="M11.3515 3.88599C12.1039 3.88599 12.7138 3.28374 12.7138 2.54083C12.7138 1.79792 12.1039 1.19568 11.3515 1.19568C10.5992 1.19568 9.98926 1.79792 9.98926 2.54083C9.98926 3.28374 10.5992 3.88599 11.3515 3.88599Z"
          fill="#5059C9"
        />
        <path
          d="M7.11418 3.88599C8.20091 3.88599 9.08187 3.01608 9.08187 1.943C9.08187 0.869909 8.20091 0 7.11418 0C6.02745 0 5.14648 0.869909 5.14648 1.943C5.14648 3.01608 6.02745 3.88599 7.11418 3.88599Z"
          fill="#7B83EB"
        />
        <path
          d="M9.73773 4.48376H4.18768C3.8738 4.49145 3.62551 4.74867 3.63278 5.05861V8.50783C3.58895 10.3678 5.07913 11.9113 6.96268 11.9568C8.84622 11.9113 10.3364 10.3678 10.2926 8.50783V5.05861C10.2999 4.74867 10.0516 4.49145 9.73773 4.48376Z"
          fill="#7B83EB"
        />
        <path
          opacity="0.1"
          d="M7.26566 4.48376V9.3173C7.26494 9.42572 7.23179 9.5315 7.1704 9.62138C7.109 9.71126 7.02209 9.78123 6.92056 9.82251C6.85446 9.85012 6.78343 9.86433 6.71168 9.86433H3.89936C3.86 9.76567 3.8237 9.66701 3.79339 9.56544C3.68743 9.22243 3.63337 8.86584 3.63297 8.50723V5.05771C3.6257 4.74827 3.87358 4.49145 4.18695 4.48381H7.26561L7.26566 4.48376Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M6.96282 4.48376V9.61625C6.96282 9.6871 6.94843 9.75724 6.92046 9.82251C6.87866 9.92276 6.8078 10.0086 6.71678 10.0692C6.62575 10.1298 6.51862 10.1626 6.40883 10.1633H4.04163C3.99017 10.0646 3.94171 9.96596 3.89936 9.86433C3.85867 9.76664 3.8233 9.66686 3.79339 9.56544C3.68743 9.22243 3.63337 8.86584 3.63297 8.50723V5.05771C3.6257 4.74827 3.87358 4.49145 4.18695 4.48381H6.96287L6.96282 4.48376Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M6.96287 4.48376V9.01841C6.96058 9.31956 6.71392 9.56318 6.40888 9.56544H3.79339C3.68743 9.22243 3.63337 8.86584 3.63297 8.50723V5.05771C3.6257 4.74827 3.87358 4.49145 4.18695 4.48381L6.96287 4.48376Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M6.66017 4.48376V9.01841C6.65783 9.31956 6.41117 9.56318 6.10619 9.56544H3.79339C3.68743 9.22243 3.63337 8.86584 3.63297 8.50723V5.05771C3.6257 4.74827 3.87358 4.49145 4.18695 4.48381L6.66017 4.48376Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M7.26573 2.93839V3.87996C7.21427 3.88297 7.16582 3.88599 7.11436 3.88599C7.0629 3.88599 7.01444 3.88297 6.96299 3.87996C6.86081 3.87327 6.75948 3.85727 6.66029 3.83216C6.35808 3.76149 6.0771 3.62119 5.84033 3.42273C5.60356 3.22427 5.41773 2.97328 5.29804 2.6903C5.25626 2.5939 5.22383 2.4938 5.20117 2.39136H6.71175C7.01719 2.39251 7.26456 2.63673 7.26573 2.93839Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M6.96275 3.23729V3.87996C6.86058 3.87328 6.75925 3.85728 6.66006 3.83217C6.35785 3.76149 6.07688 3.62118 5.84012 3.42272C5.60337 3.22426 5.41754 2.97328 5.29785 2.69031H6.40877C6.71426 2.69141 6.96158 2.93568 6.96275 3.23729Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M6.96275 3.23729V3.87996C6.86058 3.87328 6.75925 3.85728 6.66006 3.83217C6.35785 3.76149 6.07688 3.62118 5.84012 3.42272C5.60337 3.22426 5.41754 2.97328 5.29785 2.69031H6.40877C6.71426 2.69141 6.96158 2.93568 6.96275 3.23729Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M6.66011 3.23728V3.83215C6.35789 3.76148 6.07691 3.62117 5.84014 3.4227C5.60337 3.22423 5.41754 2.97324 5.29785 2.69025H6.10612C6.41161 2.6914 6.65894 2.93567 6.66011 3.23728Z"
          fill="black"
        />
        <path
          d="M0.554898 2.69024H6.1049C6.41141 2.69024 6.65985 2.93556 6.65985 3.23817V8.71855C6.65985 9.02116 6.41141 9.26649 6.10495 9.26649H0.554898C0.248441 9.26649 0 9.02116 0 8.71855V3.23822C0 2.93551 0.248441 2.69024 0.554898 2.69024Z"
          fill="url(#paint0_linear_8376_16922)"
        />
        <path
          d="M4.78977 4.77616H3.68028V7.75939H2.97345V4.77616H1.86914V4.19745H4.78977V4.77616Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_8376_16922"
          x1="115.695"
          y1="-40.1215"
          x2="542.113"
          y2="707.794"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5A62C3" />
          <stop offset="0.5" stop-color="#4D55BD" />
          <stop offset="1" stop-color="#3940AB" />
        </linearGradient>
        <clipPath id="clip0_8376_16922">
          <rect width="13.0169" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MicrosoftTeamsIcon;
