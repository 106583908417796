import React, { useRef, useState } from "react";
//@Components
import ShotVersionsListStatusButtons from "../ShotVersionsListContents/ShotVersionsListStatusButtons.component";

//@MUI
import { TableRow, TableCell, Modal, Chip } from "@material-ui/core";

import useStyles from "./ShotVersionList.styles";
import ShotVersionEditVersionModal from "../ShotVersionPageModals/ShotVersionEditVersionModal";
import DeleteModal from "../../Utilities/DeleteModal/DeleteModal.component";
import NoThumbnail from "../../Utilities/NoThumbnail.component";
import ImageViewer from "../../Utilities/ImageViewer/ImageViewer.component";
import StatusDropDown from "../../Utilities/StatusDropDown/StatusDropDown.component";
import { fdelete } from "../../../API/callsAPI";
import DateAndTimeViewer from "../../Utilities/DateAndTimeViewer/DateAndTimeViewer.component";
import TaskDetailsOnHover from "../../Utilities/TaskDetailsOnHover/TaskDetailsOnHover";
import { ChatText } from "phosphor-react";

export default function Shotversionslist(props) {
  const ref = useRef(null);
  const classes = useStyles();

  const [isEditShotVersionModalOpen, setEditShotVersionModalOpen] =
    useState(false);

  const [isDeleteShotVersionModalOpen, setDeleteShotVersionModalOpen] =
    useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [hoverOnTaskData, setHoverOnTaskData] = useState({
    isOpen: false,
    task: {},
  });

  function handleEditShotVersionModalOpenState(e) {
    e?.stopPropagation();
    setEditShotVersionModalOpen(!isEditShotVersionModalOpen);
  }

  function handleDeleteShotVersionModalOpenState(e, data) {
    e?.stopPropagation();
    setDeleteData(data);
    setDeleteShotVersionModalOpen(!isDeleteShotVersionModalOpen);
  }

  const userCreatedShotVersion = props?.data?.created_by
    ? props.data.created_by.first_name + " " + props.data.created_by.last_name
    : " ";

  const handleDeleteShotVersion = async () => {
    try {
      await fdelete({
        url: `trackables/shot-version/${deleteData?.id}/`,
      });

      props.updateRefreshCounter();
      setDeleteShotVersionModalOpen(false);
      setEditShotVersionModalOpen(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <TableRow
        hover
        onClick={(e) => props.openShotDetailModal(props.data)}
        style={{
          cursor: "pointer",
          padding: "0px",
          width: "100%",
        }}
      >
        <TableCell
          align="left"
          className={classes.shotVersionListTableCellThree}
        >
          <div style={{ display: "flex" }}>
            {props.data.thumbnail ? (
              <ImageViewer
                url={props.data.thumbnail}
                sliderState={props.shotVersionToggle ? 25 : 0}
              />
            ) : (
              <NoThumbnail sliderState={props.shotVersionToggle ? 25 : 0} />
            )}
            <p style={{ marginLeft: "10px" }}>
              {props.data.name ? props.data.name : "No Name"}
            </p>
          </div>
        </TableCell>
        <TableCell align="center" className={classes.shotVersionListTableCell}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DateAndTimeViewer dateAndTime={props?.data?.updated_at} />
          </div>
        </TableCell>
        <TableCell align="center" className={classes.shotVersionListTableCell}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "15px",
            }}
          >
            <StatusDropDown
              url={`trackables/shot-version/${props.data.id}/`}
              updateRefreshCounter={props.updateRefreshCounter}
              statusList={props.status}
              currStatus={props.data.status}
            />
          </div>
        </TableCell>
        <TableCell align="center" className={classes.shotVersionListTableCell}>
          <span>{userCreatedShotVersion}</span>
        </TableCell>
        <TableCell align="center" className={classes.shotVersionListTableCell}>
          {props?.data?.task?.name ? (
            <div className={classes.flexCenter}>
              <div
                ref={ref}
                className={classes.chipAndPopupContainer}
                onMouseEnter={() =>
                  setHoverOnTaskData({
                    isOpen: true,
                    task: props.taskList?.find(
                      (task) => task.id === props?.data?.task?.id
                    ),
                  })
                }
                onMouseLeave={() =>
                  setHoverOnTaskData({ isOpen: false, task: {} })
                }
              >
                <Chip
                  className={classes.chip}
                  size="small"
                  label={props?.data?.task?.name ?? ""}
                  variant="outlined"
                />
                {hoverOnTaskData?.isOpen ? (
                  <div
                    className={classes.popUp}
                    style={{
                      right: `${ref?.current?.offsetWidth + 40}px`,
                    }}
                  >
                    <TaskDetailsOnHover task={hoverOnTaskData?.task} />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            "No Related Task"
          )}
        </TableCell>
        <TableCell
          align="center"
          className={classes.shotVersionListTableCellTwo}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ChatText
              size={17}
              color="#606479"
              weight="fill"
              style={{ marginRight: "7px" }}
            />
            {props.data.related_comments_number}
          </div>
        </TableCell>
        <TableCell
          align="right"
          className={classes.shotVersionListTableCellTwo}
        >
          <ShotVersionsListStatusButtons
            openEditShotVersionModal={handleEditShotVersionModalOpenState}
            openDeleteShotVersionModal={handleDeleteShotVersionModalOpenState}
          />
        </TableCell>
      </TableRow>
      <div>
        <Modal
          className={classes.shotVersionPageModals}
          open={isEditShotVersionModalOpen}
          onClose={(e) => {
            setEditShotVersionModalOpen(false);
            e.stopPropagation();
          }}
        >
          <ShotVersionEditVersionModal
            data={props.data}
            sid={props.sequenceId}
            shotID={props.shotID}
            projectId={props.projectId}
            status={props.status}
            shotVersionId={props.data.id}
            handleEditShotVersionModalOpenState={
              handleEditShotVersionModalOpenState
            }
            openCreateNewStatusModal={props.openCreateNewStatusModal}
            openDeleteShotVersionModal={handleDeleteShotVersionModalOpenState}
            updateRefreshCounter={props.updateRefreshCounter}
            dragScreen={props.dragScreen}
            handleDragScreen={props.DatehandleDragScreen}
            setDragFileEnterModal={props.setDragFileEnterModal}
            taskList={props.taskList}
            fetchTasks={props.fetchTasks}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.shotVersionPageModals}
          open={isDeleteShotVersionModalOpen}
          onClose={(e) => {
            setDeleteShotVersionModalOpen(false);
            e.stopPropagation();
          }}
        >
          <DeleteModal
            type="Shot Version"
            name={deleteData?.name}
            handleCloseDeleteModal={() => {
              setDeleteShotVersionModalOpen(false);
            }}
            handleDelete={handleDeleteShotVersion}
          />
        </Modal>
      </div>
    </>
  );
}
