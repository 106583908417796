import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "521px",
    background: theme.palette.primary.main,
    borderRadius: "20px",
    padding: "24px 36px",
    display: "flex",
    flexDirection: "column",
    gap: "18px",
  },
  containerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "24px",
  },
  innerContainer1: {
    fontSize: "14px",
    color: theme.palette.text.tertiary,
  },
  btnContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "14px",
    marginTop: "30px",
  },
  newFileBtn: {
    width: "163px",
    height: "45px",
    background: theme.palette.secondary.main,
    borderRadius: "10px",
  },
  clearBtn: {
    width: "183px",
    height: "45px",
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: "10px",
  },
  newReviewTextField: {
    background: theme.palette.secondary.light,
    marginTop: "5px",
    padding: "10px 0 10px 10px",
    borderRadius: "10px",
  },
  userDisplayDiv: {
    width: "100%",
    minHeight: "48px",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default useStyles;
