import React from "react";
import { Button, Card, CardContent } from "@material-ui/core";
import useStyles from "./UpdateParentAssetOrShootThumbnailModal.style";

export default function UpdateParentAssetOrShootThumbnailModal(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.CardContent}>
          <p className={classes.headText}>Confirm Update</p>
          <p className={classes.text}>
            Do you want to update the thumbnail of parent {props?.versionType} "
            {props?.parent?.name || props?.parent?.code}"
          </p>

          <div className={classes.buttonDiv}>
            <Button
              className={classes.yesButton}
              type="submit"
              size="small"
              disableElevation
              onClick={() => {
                props.handleSubmit();
              }}
            >
              Yes, Update it
            </Button>
            <Button
              className={classes.noButton}
              type="submit"
              size="small"
              disableElevation
              onClick={props.closeUpdateParentModal}
            >
              No, keep it
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
