import React from "react";

import { Button, Card, CardContent } from "@material-ui/core";
import { PaperclipHorizontal, Trash, X } from "phosphor-react";
import useStyles from "./ConfirmDeleteFile.style";

export default function ConfirmDeleteFile({ name, closeModal, deleteFile }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.CardContent}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className={classes.trashContainer}>
              <PaperclipHorizontal size={100} className={classes.trashIcon} />
            </div>
            <div style={{ width: "75%" }}>
              <p className={classes.headText}>Delete File</p>
              <p className={classes.text}>
                Are you sure you want to delete this file?
              </p>
              <h3>
                File Name : <span className={classes.boldText}>{name}</span>
              </h3>
            </div>
          </div>
          <div className={classes.buttonDiv}>
            <Button
              className={classes.yesButton}
              type="submit"
              size="small"
              disableElevation
              onClick={(e) => {
                e.stopPropagation();
                deleteFile();
              }}
            >
              <Trash size={20} />
              Delete File
            </Button>
            <Button
              className={classes.noButton}
              type="submit"
              size="small"
              disableElevation
              onClick={(e) => {
                e.stopPropagation();
                closeModal();
              }}
            >
              <X size={20} />
              Cancel Delete
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
