import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  btnNext: {
    marginRight: "1%",
    border: `1px solid ${theme.palette.border.main}`,
  },
  btnPrevious: {
    marginRight: "1%",
    transform: "rotate(180deg)",
    border: `1px solid ${theme.palette.border.main}`,
  },
  playpauseButton: {
    color: theme.palette.secondary.main,
    height: "100%",
    width: "100%",
  },
  refactorBtn: {
    minWidth: "18px",
  },
  slider: {
    width: "100%",
    color: theme.palette.primary.main,

    "& .MuiSlider-thumb": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "10px",
    },
    "& .MuiSlider-rail": {
      color: theme.palette.text.tertiary,
    },
  },
  input: {
    height: "85%",
    width: "100%",
  },
  showVolumeControls: {
    height: "255%",
    paddingTop: "1%",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: "1",
    background: theme.palette.secondary.light,
  },
  volumeControls: {
    height: "255%",
    paddingTop: "1%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: "1",
  },
}));

export default useStyles;
