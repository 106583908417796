import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  usersContainer: {
    display: "flex",
    direction: "row",
    flexWrap: "nowrap",
  },
  user: {
    // width: "28px",
    height: "28px",
    borderRadius: "50%",
    marginRight: "5px",
  },
  userImgContainerOne: {
    width: "28px",
    height: "28px",
    borderRadius: "50%",
  },
  userImgContainer: {
    width: "28px",
    height: "28px",
    borderRadius: "50%",
    marginLeft: "-14px",
  },
  userImage: {
    objectFit: "contain",
    width: "100%",
    height: "100%",
    borderRadius: "50%",
  },
  addUserInput: {
    width: "30px",
    height: "30px",
    justifyItems: "center",
    borderRadius: "50%",
    borderColor: theme.palette.text.tertiary,
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.tertiary,
    border: "2px solid",
    display: "flex",
    alignItems: "center",
    paddingLeft: "5%",
    paddingTop: "10%",
    textAlign: "center",
    marginRight: "1%",
  },
  close: {
    color: "#fff",
    backgroundColor: "#FF4F4F",
    position: "absolute",
    fontSize: "10px",
    borderRadius: "50%",
    marginLeft: "15px",
    marginTop: "-35px",
    cursor: "pointer",
    borderColor: "transparent",
  },
  taskItemAvtaarOne: {
    height: "32px",
    width: "32px",
    textAlign: "center",
    borderRadius: "50%",
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.primary.light}`,
  },
  taskItemAvtaar: {
    height: "32px",
    width: "32px",
    textAlign: "center",
    borderRadius: "50%",
    marginLeft: "-14px",
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.primary.light}`,
  },
  taskItemAvtaarEditable: {
    height: "28px",
    width: "28px",
    textAlign: "center",
    borderRadius: "50%",
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.primary,
  },
  avatarWithName: {
    border: `1px solid ${theme.palette.text.tertiary}`,
    display: "flex",
    alignItems: "center",
    gap: "5px",
    borderRadius: "25px",
    paddingRight: "10px",
    height: "28px",
  },
  avatar: {
    height: "25px",
    width: "25px",
    textAlign: "center",
    borderRadius: "50%",
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.primary,
    fontWeight: "400 !important",
    fontSize: "12px",
  },
}));

export default useStyles;
