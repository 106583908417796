import React from "react";
import useStyles from "./SettingsOverviewTools.style";
import { Tab, Tabs } from "@material-ui/core";
export default function SettingsOverviewTools({
  handleSettingsTabChange,
  currentSettingTab,
}) {
  const classes = useStyles();
  const tabs = [
    {
      value: "statusSetting",
      label: "Status Settings",
    },
    {
      value: "taskTemplateSetting",
      label: "Manage Task Template",
    },
    {
      value: "genralSetting",
      label: "General Settings",
    },
  ];
  return (
    <>
      <div>
        <Tabs
          indicatorColor="transparent"
          className={classes.tabs}
          value={currentSettingTab}
          onChange={(event, value) => {
            handleSettingsTabChange(value);
          }}
        >
          {tabs.map((el) => (
            <Tab
              style={{
                "&.Mui-selected": {
                  outline: "none !important",
                },
              }}
              className={
                currentSettingTab == el.value
                  ? classes.selectedTab
                  : classes.tab
              }
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span>{el.label}</span>
                </div>
              }
              value={el.value}
            />
          ))}
        </Tabs>
      </div>
    </>
  );
}
