import React, { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CompletePercentageTwo = ({
  percentage,
  size,
  stroke,
  showPercentage,
}) => {
  const [colorOne, setColorOne] = useState("red");
  const [colorTwo, setColorTwo] = useState("green");
  useEffect(() => {
    if (percentage > 0 && percentage <= 20) {
      setColorOne("#DE3F3F");
      setColorTwo("#C77617");
    } else if (percentage > 20 && percentage <= 40) {
      setColorOne("#DE653F");
      setColorTwo("#C7B517");
    } else if (percentage > 40 && percentage <= 60) {
      setColorOne("#A4C717");
      setColorTwo("#DEC53F");
    } else if (percentage > 60 && percentage <= 80) {
      setColorOne("#B5DE3F");
      setColorTwo("#7CE117");
    } else {
      setColorOne("#72DE3F");
      setColorTwo("#36A600");
    }
  }, [percentage]);

  return (
    <div style={{ position: "relative", width: size, height: size }}>
      <svg style={{ position: "absolute", width: 0, height: 0 }}>
        <defs>
          <linearGradient id="gradientOuter1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: colorOne, stopOpacity: 1 }} />
            <stop
              offset="100%"
              style={{ stopColor: colorTwo, stopOpacity: 1 }}
            />
          </linearGradient>
          <pattern
            id="patternOuter1"
            x="0"
            y="0"
            width="300"
            height="300"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="150"
              height="300"
              fill="url(#gradientOuter1)"
            />
            <rect
              x="150"
              y="0"
              width="150"
              height="300"
              fill="url(#gradientOuter2)"
            />
          </pattern>
        </defs>
      </svg>
      <svg style={{ position: "absolute", width: 0, height: 0 }}>
        <defs>
          <linearGradient id="gradientOuter7" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop
              offset="0%"
              style={{ stopColor: colorOne, stopOpacity: 0.3 }}
            />
            <stop
              offset="100%"
              style={{ stopColor: colorTwo, stopOpacity: 0.3 }}
            />
          </linearGradient>
          <pattern
            id="patternOuter2"
            x="0"
            y="0"
            width="300"
            height="300"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="150"
              height="300"
              fill="url(#gradientOuter7)"
            />
            <rect
              x="150"
              y="0"
              width="150"
              height="300"
              fill="url(#gradientOuter8)"
            />
          </pattern>
        </defs>
      </svg>

      {/* Circular Progress Bar */}
      <CircularProgressbar
        value={percentage}
        strokeWidth={stroke}
        text={showPercentage ? `${percentage}%` : ""}
        styles={buildStyles({
          pathColor: `url(#patternOuter1)`,
          trailColor: "url(#patternOuter2)",
        })}
      />
    </div>
  );
};

export default CompletePercentageTwo;
