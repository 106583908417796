import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "500px",
    "& .MuiFormHelperText-root": {
      color: "#ffcc00",
    },
  },
  card: {
    borderRadius: "10px",
    // color: theme.palette.text.tertiary,
  },
  cardContent: {
    backgroundColor: theme.palette.primary.main,
    padding: "15px 20px ",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  flexBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalName: {
    fontSize: "24px",
    fontWeight: 500,
  },
  taskName: {
    fontSize: "24px",
    color: theme.palette.secondary.main,
    fontWeight: 500,
  },
  date: {
    fontSize: "18px",
    color: "#FF0000",
  },
  newDate: {
    fontSize: "24px",
    color: theme.palette.text.primary,
  },
  titleAndContent: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  titleLeft: {
    textAlign: "left",
    color: theme.palette.text.tertiary,
    fontSize: "12px",
  },
  titleCenter: {
    textAlign: "center",
    color: theme.palette.text.tertiary,
    fontSize: "12px",
  },
  titleRight: {
    textAlign: "right",
    color: theme.palette.text.tertiary,
    fontSize: "12px",
  },
  trackableImage: {
    marginRight: "5px",
    marginLeft: "10px",
    height: "28px",
    width: "28px",
  },
  noOfDays: {
    border: `2px solid ${theme.palette.text.tertiary}`,
    borderRadius: "20px",
    padding: "5px 10px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  noOfDaysSelected: {
    border: `2px solid transparent`,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.primary,
    borderRadius: "20px",
    padding: "5px 10px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  grayText: {
    color: theme.palette.text.tertiary,
    marginRight: "5px",
  },
  whiteText: {
    color: theme.palette.text.primary,
    marginRight: "5px",
  },
  dayInput: {
    width: "30px",
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    fontSize: "16px",
    color: theme.palette.text.primary,
    height: "20px",
  },
  doneButton: {
    backgroundColor: theme.palette.secondary.main,
    width: "150px",
    borderRadius: "10px",
    height: "45px",
  },
}));

export default useStyles;
