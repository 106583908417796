import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    alignItems: "center",
    justifyContent: "center",
    width: "150px",
    height: "40px",
    background: theme.palette.secondary.light,
    borderRadius: "10px",
  },
  wrenchBtn: {
    minWidth: "0px",
    padding: "8px",
    border: `1px solid ${theme.palette.secondary.main}`,
    marginRight: "5px",
    marginBottom: "10px",
  },
  dropdownDiv: {
    display: "flex",
    alignItems: "center",
    width: "150px",
    justifyContent: "space-between",
  },
  dropDown: {
    backgroundColor: theme.palette.secondary.light,
  },
  selectText: {
    marginLeft: "10px",
    fontFamily: "Nunito Sans",
    opacity: "0.7",
    fontSize: "12px",
    color: theme.palette.text.primary,
  },

  iconStyle: {
    marginRight: "10px",
    height: "20px",
    color: theme.palette.secondary.main,
  },
  formLabelText: {
    height: "0%",
    width: "100%",
    opacity: "0",
    background: theme.palette.secondary.light,
  },
  notificationIcons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  notificationBtn: {
    display: "flex",
    direction: "row",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.text.tertiary,
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  selectedBtn: {
    display: "flex",
    direction: "row",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.light,
  },
  btnText: {
    fontSize: "11px",
    marginLeft: "5px",
  },
  divider: {
    height: "80%",
    backgroundColor: theme.palette.text.tertiary,
    marginTop: "5px",
  },
  advanceFilterModal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default useStyles;
