import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    display: "flex",
    width: "100%",
  },
  CardContent1: {
    width: "37.5%",
    backgroundColor: theme.palette.secondary.light,
    fontSize: "14px",
  },
  CardContent2: {
    height: "95%",
    width: "25%",
    padding: "10px 5px 0px 5px",
  },

  circle: {
    height: "90%",
    width: "18%",
    borderRadius: "100px",
    backgroundColor: theme.palette.primary.main,
    zIndex: "1",
    margin: "0 2% 0 2%",
  },
  combinePlayButton: {
    height: "100%",
    width: "60%",
    borderRadius: "10px",
    backgroundColor: theme.palette.secondary.main,
    fontSize: "16px",
  },

  CardContent3: {
    width: "37.5%",
    backgroundColor: theme.palette.secondary.light,
    fontSize: "14px",
  },
  div1: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "14px",
  },
  div2: {
    width: "100%",
    height: "7%",
    display: "flex",
  },
  circle1: {
    position: "relative",
    right: "4%",
    width: "45%",
    height: "100%",
    backgroundColor: theme.palette.primary.main,
    zIndex: "1",
    borderRadius: "500px",
  },
  circle2: {
    position: "relative",
    left: "4%",
    width: "45%",
    height: "100%",
    backgroundColor: theme.palette.primary.main,
    zIndex: "1",
    borderRadius: "500px",
  },
  playButtondiv: {
    width: "175px",
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  playButton: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "10px",
  },
  status: {
    display: "flex",
    alignItems: "center",
    marginLeft: "3%",
    marginTop: "2%",
  },
  title: {
    width: "125%",
    height: "30%",
    marginTop: "1.7%",
    marginRight: "-20%",
  },
  statusCode: {
    color: theme.palette.text.tertiary,
    textAlign: "center",
  },
  statusCodeTwo: {
    color: theme.palette.text.tertiary,
    textAlign: "center",
  },
  updateInfo1: {
    width: "100%",
    height: "40%",
    display: "flex",
    alignItems: "flex-end",
    color: theme.palette.text.tertiary,
  },
  updateInfo2: {
    width: "100%",
    height: "37%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    color: theme.palette.text.tertiary,
  },
  btn: {
    color: theme.palette.secondary.main,
  },
  prevBtn: {
    transform: "rotate(180deg)",
    minWidth: "5%",
    border: `1px solid ${theme.palette.border.main}`,
  },
  nextBtn: {
    minWidth: "5%",
    border: `1px solid ${theme.palette.border.main}`,
  },
  onTopcard: {
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
  },
  selectVersion: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "99%",
    width: "100%",
    border: `1px dashed ${theme.palette.border.main}`,
    cursor: "pointer",
    marginTop: "1%",
  },
  infoOntop: {
    color: theme.palette.text.tertiary,
  },
  volumebtn: {
    color: theme.palette.text.tertiary,
  },
  seekbarSlider: {
    width: "100%",
    marginTop: "2.5%",
    color: theme.palette.primary.main,

    "& .MuiSlider-thumb": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "10px",
    },
    "& .MuiSlider-rail": {
      color: theme.palette.text.tertiary,
    },
  },
  volumeSlider: {
    width: "77%",
    color: theme.palette.primary.main,

    "& .MuiSlider-thumb": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "10px",
    },
    "& .MuiSlider-rail": {
      color: theme.palette.text.tertiary,
    },
  },
  opacitySlider: {
    width: "80%",
    height: "20%",
    marginTop: "1%",
    color: theme.palette.primary.main,

    "& .MuiSlider-thumb": {
      backgroundColor: theme.palette.text.tertiary,
      borderRadius: "10px",
    },
    "& .MuiSlider-rail": {
      color: theme.palette.text.tertiary,
    },
  },
}));

export default useStyles;
