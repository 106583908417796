import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  FormHelperText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { X } from "phosphor-react";
import React, { useEffect } from "react";
import useStyles from "./ImportTemplateModal.style";
import { useFormik } from "formik";
import * as yup from "yup";
import { fget, fpatch, fpost } from "../../../../../API/callsAPI";
import { useState } from "react";
import Loader from "../../../../Utilities/Loader/Loader";
import NoResultFound from "../../../../Utilities/NoResultFound/NoResultFound";

const ImportTemplateModal = ({
  handleClose,
  updateRefreshCounter,
  setSnackValue,
  projectId,
}) => {
  const classes = useStyles();
  const [view, setView] = useState("selectProject");

  //state for select project
  const [isProjectsLoaded, setIsProjectsLoaded] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});

  //state for select template
  const [isTemplatesLoaded, setIsTemplatesLoaded] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);

  //get projects
  useEffect(() => {
    getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProjects = async () => {
    setIsProjectsLoaded(false);
    try {
      const res = await fget({ url: "project/" });
      const rem = res.data.results.filter((pro) => pro.id != projectId);
      setProjectList(rem);
      setIsProjectsLoaded(true);
    } catch (error) {
      console.log(error);
      setIsProjectsLoaded(true);
    }
  };

  //get templates
  useEffect(() => {
    if (selectedProject.id && view === "selectTemplates") {
      getTasksTemplates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject?.id, view]);

  const getTasksTemplates = async () => {
    setIsTemplatesLoaded(false);
    try {
      const url = `task-template/?project=${selectedProject?.id}`;
      const res = await fget({ url });
      setTemplateList(res?.data?.results);
      setIsTemplatesLoaded(true);
    } catch (error) {
      setIsTemplatesLoaded(true);
    }
  };

  const handleSelectTemplates = (tempId) => {
    if (selectedTemplates.length > 0) {
      const exist = selectedTemplates?.some((eachSeq) => eachSeq === tempId);
      if (!exist) {
        setSelectedTemplates([...selectedTemplates, tempId]);
      } else {
        setSelectedTemplates(
          selectedTemplates.filter(
            (singleTemplateId) => singleTemplateId !== tempId
          )
        );
      }
    } else {
      setSelectedTemplates([tempId]);
    }
  };

  const selectAllTemplates = () => {
    if (selectedTemplates.length === templateList?.length) {
      setSelectedTemplates([]);
    } else {
      const allTemplateId = templateList?.map((eachTemp) => {
        return eachTemp.id;
      });
      setSelectedTemplates(allTemplateId);
    }
  };

  const handleImportTemplates = async () => {
    const importTemplateData = {
      url: "import-templates/",
      data: {
        project: parseInt(projectId),
        import_project: selectedProject?.id,
        template: selectedTemplates,
      },
    };
    try {
      const res = await fpost(importTemplateData);
      updateRefreshCounter();
      setSnackValue({
        isOpen: true,
        message: res?.data?.detail,
        isError: false,
      });
      handleClose();
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: "an error occurred importing templates",
        isError: false,
      });
      console.log(error);
    }
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          {/*select project view */}
          {view === "selectProject" ? (
            <>
              <div className={classes.cardTitle}>
                <span className={classes.title}>Import Templates</span>
                <Button
                  className={classes.closeButton}
                  onClick={() => handleClose()}
                >
                  <X size="30px" />
                </Button>
              </div>
              <div className={classes.selectText}>Select Project</div>
              <div className={classes.projectList}>
                {isProjectsLoaded ? (
                  <>
                    {projectList?.length > 0 ? (
                      <>
                        {projectList?.map((proj) => {
                          return (
                            <>
                              <div
                                onClick={() => {
                                  if (selectedProject.id === proj.id) {
                                    setSelectedProject({});
                                  } else {
                                    setSelectedProject(proj);
                                  }
                                }}
                                className={`${classes.singleProject} ${
                                  selectedProject?.id === proj?.id
                                    ? classes.selectedProject
                                    : ""
                                }`}
                              >
                                <img
                                  src={
                                    proj?.thumbnail
                                      ? proj.thumbnail
                                      : "https://images.pexels.com/photos/577514/pexels-photo-577514.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                                  }
                                  alt=""
                                  height="30px"
                                  width="30px"
                                  style={{ borderRadius: "10px" }}
                                />
                                <div
                                  style={{ fontSize: "14px", fontWeight: 500 }}
                                >
                                  {proj?.name}
                                </div>
                              </div>
                              <Divider className={classes.divider} />
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <div style={{ marginTop: "30px" }}>
                        <NoResultFound />
                      </div>
                    )}
                  </>
                ) : (
                  <Loader />
                )}
              </div>
              <div className={classes.cardAction}>
                <Button
                  className={classes.button}
                  disabled={selectedProject.id ? false : true}
                  onClick={() => setView("selectTemplates")}
                >
                  Next
                </Button>
              </div>
            </>
          ) : (
            /* select templates view */
            <>
              <div className={classes.cardTitle}>
                <span className={classes.title}>Import Templates</span>
                <Button
                  className={classes.closeButton}
                  onClick={() => handleClose()}
                >
                  <X size="30px" />
                </Button>
              </div>
              <div className={classes.selectText}>Select Templates</div>
              {isTemplatesLoaded ? (
                <>
                  {templateList?.length > 0 ? (
                    <TableContainer className={classes.tableContainer}>
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        className={classes.table}
                      >
                        <TableHead className={classes.tableHeader}>
                          <TableRow>
                            <TableCell
                              className={classes.headTable}
                              align="left"
                              style={{ width: "50%" }}
                            >
                              Template Name
                            </TableCell>
                            <TableCell
                              className={classes.headTable}
                              align="left"
                            >
                              Duration
                            </TableCell>
                            <TableCell
                              className={classes.headTable}
                              align="center"
                            >
                              No Of phases
                            </TableCell>

                            <TableCell
                              className={classes.headTable}
                              align="right"
                            >
                              <Checkbox
                                onClick={() => selectAllTemplates()}
                                checked={
                                  selectedTemplates?.length ===
                                  templateList?.length
                                    ? true
                                    : false
                                }
                              />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {templateList?.map((temp) => {
                            return (
                              <>
                                <TableRow className={classes.tableRow}>
                                  <TableCell
                                    className={classes.tableCell}
                                    style={{ width: "50%" }}
                                  >
                                    {temp?.name}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCellWithColor}
                                    align="left"
                                  >
                                    Days - {temp?.total_days || 0}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCellWithColor}
                                    align="center"
                                  >
                                    {temp?.total_phases}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCell}
                                    align="right"
                                  >
                                    <Checkbox
                                      onChange={() =>
                                        handleSelectTemplates(temp?.id)
                                      }
                                      checked={
                                        selectedTemplates?.indexOf(temp.id) !==
                                        -1
                                          ? true
                                          : false
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <div style={{ marginTop: "30px" }}>
                      <NoResultFound />
                    </div>
                  )}
                </>
              ) : (
                <Loader />
              )}
              <div className={classes.cardAction}>
                <Button
                  className={classes.button}
                  disabled={selectedTemplates?.length > 0 ? false : true}
                  onClick={() => handleImportTemplates()}
                >
                  Import Templates
                </Button>
              </div>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default ImportTemplateModal;
