import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "25%",
    "& .MuiFormHelperText-root": {
      color: "#ffcc00",
    },
    [theme.breakpoints.down("md")]: {
      width: "35%",
      // height: "11rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "35%",
      // height: "11rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "40%",
      // height: "11rem",
    },
  },
  card: {
    borderRadius: "8px",
  },
  cardContent: {
    backgroundColor: theme.palette.primary.main,
    padding: "18px 18px 18px 18px",
  },
  title: {
    color: theme.palette.secondary,
    fontSize: "20px",
    fontWeight: "500",
  },
  closeIcon: {
    color: theme.palette.secondary,
    cursor: "pointer",
    marginTop: "7%",
    float: "right",
  },
  searchInput: {
    padding: "3px 9px",
    width: "60%",
    background: theme.palette.secondary.light,
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
  },
  searchDiv: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "8px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginTop: "4%",
    color: theme.palette.text.primary,
  },
  projectListContainer: {
    height: "auto",
    maxHeight: "280px",
    overflowY: "auto",
    width: "100%",
  },
  okButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.secondary,
    width: "100%",
    borderRadius: "7px",
    justifyContent: "center",
    alignContent: "center",
    color: theme.palette.text.secondary,
    
  },
  projectList: {
    borderBottom: "1px solid ",
    borderBottomColor: theme.palette.secondary.light,
    cursor: "pointer",
    // height:"18%"
  },
  
}));

export default useStyles;
