let cachedTrackableArray = [];
export function reOrderTask(tasks) {
  //unlinked task
  //linked task
  const trackableArray = [];
  const tempStruct = {
    noOfTrackables: 0,
    noOfTask: 0,
    groupedTask: [],
    unLinkedTask: [],
  };
  let NoOfTask = tasks.length;
  let NoOfTrackables = 0;
  let ArrayOFTrackablesId = [];
  for (let index = 0; index < tasks.length; index++) {
    if (tasks[index].linked) {
      let ifExist = ArrayOFTrackablesId.indexOf(tasks[index].linked.id);
      if (tempStruct.groupedTask.length && ifExist !== -1) {
        tempStruct.groupedTask[ifExist].tasks.push(tasks[index]);
      } else {
        tempStruct.groupedTask.push({
          trackable: tasks[index].linked.id,
          trackableName: tasks[index].linked.name || tasks[index].linked.code,
          linkedClass: tasks[index].linked_class,
          tasks: [tasks[index]],
        });
        ArrayOFTrackablesId.push(tasks[index].linked.id);
        trackableArray.push(tasks[index].linked);
        NoOfTrackables++;
      }
    } else {
      tempStruct.unLinkedTask.push(tasks[index]);
    }
    tempStruct.noOfTrackables = NoOfTrackables;
    tempStruct.noOfTask = NoOfTask;
  }
  cachedTrackableArray = trackableArray;
  return tempStruct;
}

export function getTrackableOfTask() {
  return cachedTrackableArray;
}

export function clearTrackable() {
  cachedTrackableArray = [];
}

export function reArrangeTask(tasks) {
  const lastTask = tasks.filter((eachtask) => eachtask.next === null);
  const orderResult = [];
  if (lastTask.length === 1) {
    let lastTaskId = lastTask[0]?.id;
    for (let i = 0; i < tasks.length; i++) {
      if (i === 0) {
        orderResult.push(lastTaskId);
      } else {
        const findPrev = tasks.filter(
          (eachtask) => eachtask.next === lastTaskId
        );
        if (findPrev.length) {
          orderResult.push(findPrev[0]?.id);
          lastTaskId = findPrev[0]?.id;
        }
      }
    }
  }
  const unorderedId = []; 
  tasks.map((eachtask) => {
    if (orderResult.includes(eachtask.id)) {
      return;
    } else {
      unorderedId.push(eachtask.id);
    }
  });

  return  orderResult.reverse().concat(unorderedId);
}
