import { Button, Tooltip } from "@material-ui/core";
import React, { useState, useRef } from "react";
import {
  VideoCamera,
  LockSimpleOpen,
  Trash,
  VideoCameraSlash,
} from "phosphor-react";
import useStyles from "./SequencerTimeLineLayer.style";
import { useEffect } from "react";
import StringTruncate from "../../../Utilities/StringTruncate/StringTruncate";
import { Modal } from "@material-ui/core";
import DeleteModal from "../../../Utilities/DeleteModal/DeleteModal.component";

export default function SequencerTimeLineLayer({
  layer,
  editLayerName,
  deleteLayer,
  changeVisibility,
}) {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const [layerName, setLayerName] = useState(layer?.name);
  const [isDeleteSequencerLayerModalOpen, setDeleteSequencerLayerModalOpen] =
    useState(false);
  const ref = useRef(null);

  useEffect(() => {
    function handleOutsideClick(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setEdit(false);
      }
    }
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, [ref]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      editLayerName(layerName, layer.id);
      setEdit(false);
    }
  };

  return (
    <div className={classes.layers}>
      <div ref={ref} style={{ width: "56px", height: "21px" }}>
        {edit ? (
          <input
            style={{ width: "56px", height: "21px" }}
            type="text"
            value={layerName}
            onChange={(event) => setLayerName(event.target.value)}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <Tooltip title={layer?.name}>
            <span onDoubleClick={() => setEdit(true)}>
              {StringTruncate(layer?.name, 8)}
            </span>
          </Tooltip>
        )}
      </div>
      <div style={{ display: "flex" }}>
        <Button
          className={classes.layerBtn}
          onClick={() => setDeleteSequencerLayerModalOpen(true)}
        >
          <Trash size={14} />
        </Button>
        <Button
          className={classes.layerBtn}
          onClick={() => {
            changeVisibility(!layer.visibility_status, layer.id);
          }}
        >
          {layer.visibility_status ? (
            <VideoCamera size={14} />
          ) : (
            <VideoCameraSlash size={14} />
          )}
        </Button>
        <Button className={classes.layerBtn}>
          <LockSimpleOpen size={14} />
        </Button>
      </div>
      <div>
        <Modal
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          open={isDeleteSequencerLayerModalOpen}
          onClose={(e) => {
            setDeleteSequencerLayerModalOpen(false);
            e.stopPropagation();
          }}
        >
          <DeleteModal
            type="Layer"
            name={layer.name}
            handleCloseDeleteModal={() => {
              setDeleteSequencerLayerModalOpen(false);
            }}
            handleDelete={() => deleteLayer(layer.id)}
          />
        </Modal>
      </div>
    </div>
  );
}
