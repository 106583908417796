import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: "97px",
    minWidth: "348px",
    padding: "4px 6px 6px 6px",
    borderRadius: "5px",
    border: `0.5px solid ${theme.palette.secondary.main}`,
    fontFamily: "Poppins",
    textAlign: "center",
    backgroundColor: theme.palette.secondary.light,
  },
  header: {
    fontSize: "14px",
    fontWeight: "500",
    color: theme.palette.secondary.main,
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "14px",
    paddingBottom: "0px",
    color: theme.palette.text.tertiary,
  },
  textStats: {
    color: theme.palette.border.main,
  },
}));

export default useStyles;
