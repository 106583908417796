import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontFamily: "poppins",
    flexWrap: "wrap",
  },

  // styles for department details task page
  taskViewTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  titleText: {
    fontSize: "18px",
    fontWeight: 600,
  },
  inLineDiv: {
    display: "flex",
    alignItems: "center",
  },

  checkBoxText: {
    fontFamily: "poppins",
    color: theme.palette.text.tertiary,
  },

  taskPageButton: {
    textTransform: "none",
    height: "40px",
    width: "126px",
    color: theme.palette.text.primary,
    margin: "0px 2px",
    borderRadius: "10px",
  },

  iconStyle: {
    float: "right",
    width: "27px",
    height: "18px",
    color: theme.palette.secondary.main,
  },

  tableContainerDiv: {
    height: "90%",
    overflowY: "auto",
  },

  tableContainer: {
    maxHeight: "96%",
    marginTop: "5px",
    paddingRight: "10px",
  },

  tableHeader: {
    color: "grey",
    padding: "4px 0px",
    background: theme.palette.primary.main,
  },

  borderBottom: {
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
  },
  trackableNameButton: {
    fontSize: "18px",
    fontWeight: "600",
    padding: "5px 0",
    minWidth: 0,
  },

  //styles of task view table

  tableCell: {
    padding: "0px",
  },
  statusDiv: {
    display: "flex",
    alignItems: "center",
  },
  statusName: {
    width: "80%",
    overflow: "hidden",
  },
  statusColor: {
    borderRadius: "50%",
    display: "inline-block",
    marginRight: "10px",
    height: "15px",
    width: "15px",
    border: `1px solid ${theme.palette.border.main}`,
  },

  priorityCookies: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },

  trackableDiv: {
    display: "flex",
    alignItems: "center",
    marginRight: "10px",
    position: "relative",
    cursor: "pointer",
  },
  trackableImage: {
    borderRadius: "7px",
    objectFit: "cover",
    margin: "4px 6px 4px 10px",
    height: "28px",
    width: "28px",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
}));

export default useStyles;
