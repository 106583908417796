import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  colorChange: {
    color: theme.palette.secondary.main,
  },
  flexBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  tableContainer: {
    width: "100%",
  },
  table: {
    backgroundColor: theme.palette.primary.main,
    width: "100%",
  },
  tableHeader: {
    backgroundColor: theme.palette.primary.main,
    display: "table",
    width: "100%",
    tableLayout: "fixed",
  },
  tableRow: {
    display: "table",
    width: "100%",
    tableLayout: "fixed",
  },
  headTable: {
    padding: "0px 10px 0px 0px !important",
    color: theme.palette.secondary.main,
    border: "none",
  },
  tableBody: {
    width: "100%",
    height: `200px`,
    overflowY: "auto",
    display: "block",
    tableLayout: "fixed",
    paddingRight: "10px",
  },
  bodyRow: {
    display: "table",
    width: "100%",
    tableLayout: "fixed",
  },
  issueName: {
    padding: "8px 0px",
    borderBottom: "none",
  },
  issueDate: {
    padding: "8px 0px",
    borderBottom: "none",
    color: theme.palette.text.tertiary,
    fontSize: "12px",
  },
}));

export default useStyles;
