import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  popoverArrow: {
    position: "absolute",
    top: "32%",
    left: "278px",
    width: "30px",
    height: "40px",
    clipPath: "polygon(100% 50%, 0 0, 0 100%)",
    backgroundColor: theme.palette.secondary.main,
    zIndex: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  innerPop: {
    width: "28px",
    height: "38px",
    clipPath: "polygon(100% 50%, 0 0, 0 100%)",
    backgroundColor: theme.palette.secondary.light,
    zIndex: 20,
    display: "block",
    padding: "3px",
    position: "absolute",
    left: "-2px",
  },

  card: {
    borderRadius: "10px",
    backgroundColor: theme.palette.secondary.light,
    padding: "10px",
    width: "280px",
    border: `2px solid ${theme.palette.secondary.main}`,
  },
  taskDetails: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  taskName: {
    fontSize: "16px",
    fontWeight: 600,
    textAlign: "center",
  },
  dateAndPriority: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "gray",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
