import { Slide } from "@material-ui/core";
import React, { useState } from "react";
import { fpatch } from "../../../../API/callsAPI";
import TaskPageEditModal from "../../TaskPageModals/TaskPageEditModal";
import TableViewRow from "./TableViewComponents/TableViewRow";
import { useStyles } from "./TaskPageTableView.styles";
import TaskViewModal from "../../TaskPageModals/TaskViewModal/TaskViewModal";
import ErrorOrSuccessMessage from "../../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";

export default function TaskPageTableViewOverview({
  groupedTask,
  taskStatus,
  updateRefreshCounter,
  projectUsers,
  projectId,
  handleTaskDelete,
  departments,
  rearrangeTaskOrder,
  trackableType,
}) {
  const [isTaskPageEditModalOpen, setIsTaskPageEditModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState({});
  const [openTaskViewModal, setTaskViewModal] = useState(false);
  const [mutualTaskList, setMutualTaskList] = useState([]); //task sharing same trackable
  const [viewModal, setViewModal] = useState(true);
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });

  const classes = useStyles();
  function handleViewTaskModal(task, mutualTasks) {
    setTaskViewModal(true);
    setSelectedTask(task);
    if (mutualTasks) {
      setMutualTaskList(mutualTasks);
    }
  }

  const handleEditTaskModal = () => {
    setIsTaskPageEditModalOpen(!isTaskPageEditModalOpen);
  };

  const handleTaskUpdate = (taskId, updatedTaskForm) => {
    let updatedTask = {
      url: `trackables/task/${taskId}/`,
      data: updatedTaskForm,
    };
    fpatch(updatedTask).then((res) => {
      setIsTaskPageEditModalOpen(false);
      setSnackValue({
        isOpen: true,
        message: `SuccessFully edited task : ${res.data.name}`,
        isError: false,
      });
      updateRefreshCounter();
    });
  };
  return (
    <>
      <ErrorOrSuccessMessage snackValue={snackValue} />
      <div
        style={{
          marginTop: "8px",
          maxWidth: `calc(100vw - 155px)`,
          height:
            trackableType === "task"
              ? `calc(100vh - 233px)`
              : `calc(100vh - 262px)`,
          overflowY: "scroll",
          overflowX: "none",
        }}
      >
        {groupedTask.groupedTask.map((eachTrackable, index) => {
          return (
            <TableViewRow
              key={index}
              handleViewTaskModal={handleViewTaskModal}
              tasks={eachTrackable.tasks}
              openTaskViewModal={openTaskViewModal}
              trackableName={eachTrackable.trackableName}
              linkedClass={eachTrackable.linkedClass}
              isTaskPageEditModalOpen={isTaskPageEditModalOpen}
            />
          );
        })}
      </div>
      <Slide direction="left" in={openTaskViewModal}>
        <div
          style={{
            display: openTaskViewModal ? "block" : "none",
            top: trackableType === "task" ? "155px" : "185px",
            height:
              trackableType === "task"
                ? `calc(100vh - 155px)`
                : `calc(100vh - 185px)`,
          }}
          className={classes.floatingDiv}
        >
          <div
            style={{
              height: "100%",
              padding: "5px",
            }}
          >
            {viewModal ? (
              <TaskViewModal
                closeModal={() => setTaskViewModal(false)}
                task={selectedTask}
                projectId={projectId}
                taskStatus={taskStatus}
                handleEditTaskModal={handleEditTaskModal}
                handleTaskUpdate={handleTaskUpdate}
                projectUsers={projectUsers}
                switchToEditModal={() => setViewModal(false)}
                handleTaskDelete={handleTaskDelete}
                viewType="Table"
              />
            ) : (
              <TaskPageEditModal
                rearrangeTaskOrder={rearrangeTaskOrder}
                SwitchToViewModal={() => setViewModal(true)}
                selectedTask={selectedTask}
                statusList={taskStatus}
                handleEditTaskModal={() => setTaskViewModal(false)}
                handleTaskUpdate={handleTaskUpdate}
                projectUsers={projectUsers}
                mutualTaskList={mutualTaskList}
                onTaskRearrange={{}}
                departments={departments}
              />
            )}
          </div>
        </div>
      </Slide>
      {/* <Slide direction="left" in={isTaskPageEditModalOpen}>
        <div
          style={{
            display: isTaskPageEditModalOpen ? "block" : "none",
          }}
          className={classes.floatingDiv}
        >
          <div
            style={{
              height: "100%",
              padding: "5px",
            }}
          >
            <TaskPageEditModal
              selectedTask={taskPageEditModalData}
              statusList={taskStatus}
              handleEditTaskModal={(event) => setIsTaskPageEditModalOpen(false)}
              handleTaskUpdate={handleTaskUpdate}
              projectUsers={projectUsers}
              departments={departments}
            />
          </div>
        </div>
      </Slide> */}
    </>
  );
}
