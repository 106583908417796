import React from "react";

const MovieColabLogo = () => {
  return (
    <svg
      width="60"
      height="45"
      viewBox="0 0 73 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 44.9474V15.1053L36.5 32.421L54 15.1053V44.9474"
        stroke="#6C65D9"
        stroke-width="9.57895"
      />
      <path d="M5 0V49" stroke="#6C65D9" stroke-width="9.57895" />
      <path d="M68 0V49" stroke="#6C65D9" stroke-width="9.57895" />
    </svg>
  );
};

export default MovieColabLogo;
