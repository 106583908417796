import { Button, Tooltip } from "@material-ui/core";
import { UserPlus } from "phosphor-react";
import React, { useState } from "react";
import UserAvatar from "../../UserAvatar/UserAvatar.component";
import useStyles from "./UserViewerForManageUsers.style";

const UserViewerForManageUsers = ({
  currentUsers,
  handleSelectUserModalChange,
  handleSelectUsers,
}) => {
  const classes = useStyles();

  const handleRemoveUser = (user) => {
    handleSelectUsers(currentUsers.filter((each) => each.value !== user.value));
  };

  return (
    <div className={classes.userViewer}>
      <div>
        {currentUsers ? (
          <>
            {currentUsers.length > 2 ? (
              <div className={classes.usersList}>
                {currentUsers.slice(0, 7).map((user) => (
                  <SingleUser user={user} handleRemoveUser={handleRemoveUser} />
                ))}
                {currentUsers.length > 7 ? (
                  <p>+{currentUsers.length - 7}</p>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div className={classes.usersList}>
                {currentUsers.map((user) => (
                  <SingleUserWithName
                    user={user}
                    handleRemoveUser={handleRemoveUser}
                  />
                ))}
              </div>
            )}
          </>
        ) : (
          ""
        )}
      </div>
      <Button
        onClick={() => {
          handleSelectUserModalChange();
        }}
      >
        <div className={classes.addUserInput}>
          <UserPlus size={22} />
        </div>
      </Button>
    </div>
  );
};

export default UserViewerForManageUsers;

const SingleUser = ({ user, handleRemoveUser }) => {
  const classes = useStyles();
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Tooltip title={`${user?.label}`} key={user?.value}>
      <div
        className={classes.user}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {user?.image ? (
          <img className={classes.userImage} src={user?.image} alt="" />
        ) : (
          <UserAvatar
            avatar={user?.avatar}
            firstName={user?.label?.split(" ")[0]}
            lastName={user?.label?.split(" ")[1]}
          />
        )}
        <button
          type="button"
          className={classes.close}
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => handleRemoveUser(user)}
          style={{ display: isHovered ? "block" : "none" }}
        >
          <span>x</span>
        </button>
      </div>
    </Tooltip>
  );
};

const SingleUserWithName = ({ user, handleRemoveUser }) => {
  const classes = useStyles();
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      style={{ position: "relative" }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={classes.crossContainer}>
        <button
          type="button"
          className={classes.close2}
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => handleRemoveUser(user)}
          style={{ display: isHovered ? "block" : "none" }}
        >
          <span>x</span>
        </button>
      </div>
      <div className={classes.user} key={user?.value}>
        {user?.image ? (
          <img className={classes.userImage} src={user?.image} alt="" />
        ) : (
          <div className={classes.avatarWithName}>
            <UserAvatar
              avatar={user?.avatar}
              firstName={user?.label?.split(" ")[0]}
              lastName={user?.label?.split(" ")[1]}
            />
            <span>{user.label}</span>
          </div>
        )}
      </div>
    </div>
  );
};
