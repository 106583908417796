import React, { useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useStyles } from "./ShotPageMultiCreate.style";
import { X } from "react-feather";
import { Asterisk } from "phosphor-react";
import { useFormik } from "formik";
import * as yup from "yup";
import HelperText from "../../Utilities/HelperText/HelperText";
import { fpost } from "../../../API/callsAPI";
import ErrorOrSuccessMessage from "../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";

export default function ShotPageMultiCreate({
  setIsMultiCreateModalOpen,
  projectId,
  sequenceId,
  updateRefreshCounter,
}) {
  const classes = useStyles();
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  const [loader, setLoader] = useState(false);

  const validationSchema = yup.object({
    shotNamePrefix: yup
      .string("string is required")
      .min(1)
      .max(40)
      .required("Prefix is a required feild"),
    shotNameDelimiter: yup.string("string is required"),
    shotNumber: yup
      .number("required number")
      .required("shot number a required feild"),
    shotIncrementNumber: yup
      .number("required number")
      .required("shot Increment no. a required feild"),
    shotStartNumber: yup
      .number("required number")
      .required("min 0 is required"),
    shotCountNumber: yup
      .number("required number")
      .min(2)
      .max(10)
      .required("shot count is a required feild"),
  });

  const formik = useFormik({
    initialValues: {
      shotNamePrefix: "Shot",
      shotNameDelimiter: "-",
      shotNumber: 2,
      shotIncrementNumber: 10,
      shotStartNumber: 0,
      shotCountNumber: 5,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      //
      function generateListOfShots() {
        const arrayOfShots = [];
        for (let i = 0; i < values.shotCountNumber; i++) {
          const input = i * values.shotIncrementNumber + values.shotStartNumber;
          const shotName = `${values.shotNamePrefix}${
            values.shotNameDelimiter
          }${padZerosAndAdd(values.shotNumber, input)}`;
          arrayOfShots.push({
            code: shotName,
            project: projectId,
            parent_sequence: sequenceId,
            vp_linked_level_sequence: "",
          });
        }
        return arrayOfShots;
      }
      const shotsPayload = generateListOfShots();
      const shotsPost = {
        url: "trackables/shot/",
        data: shotsPayload,
      };
      try {
        const res = await fpost(shotsPost);
        if (res.status === 200 || 201) {
          setLoader(false);
          setSnackValue({
            isOpen: true,
            message: `SuccessFully Created multiple shots`,
            isError: false,
          });
          updateRefreshCounter();
          setIsMultiCreateModalOpen(false);
        }
      } catch (error) {
        setLoader(false);
        setSnackValue({
          isOpen: true,
          message: `${error}`,
          isError: true,
        });
      }
    },
  });

  function padZerosAndAdd(num, input) {
    const zerosToPad = Math.max(num - input.toString().length, 0);
    const paddedString = "0".repeat(zerosToPad).padStart(num, "0");
    const paddedNumber = parseInt(paddedString);
    const result = paddedNumber + input;
    return result.toString().padStart(num, "0");
  }

  return (
    <>
      <div className={classes.root}>
        <ErrorOrSuccessMessage snackValue={snackValue} />
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <div
              style={{
                height: "15px",
                width: "100%",
                margin: "12px 0px",
                fontSize: "20px",
                fontWeight: "500",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "white",
              }}
            >
              Multiple Shot Creation
              <Button onClick={() => setIsMultiCreateModalOpen(false)}>
                <X height={25} width={25} />
              </Button>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div
                style={{
                  height: "60px",
                  width: "100%",
                  margin: "12px 0px",
                }}
              >
                <span>Shot Name Prefix</span>
                <Asterisk size={12} color="#FF0000" weight="fill" />
                <div className={classes.cardContentDiv3}>
                  <TextField
                    placeholder="Enter shot prefix"
                    fullWidth
                    type="text"
                    InputProps={{ disableUnderline: true }}
                    className={classes.cardContentInput1}
                    name="shotNamePrefix"
                    value={formik.values.shotNamePrefix}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.shotNamePrefix)}
                  />
                  {formik.errors.shotNamePrefix &&
                  formik.touched.shotNamePrefix ? (
                    <HelperText text={formik.errors.shotNamePrefix} />
                  ) : null}
                </div>
              </div>
              <div
                style={{
                  height: "60px",
                  width: "100%",
                  margin: "12px 0px",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ width: "45%" }}>
                    <span>Shot Name Delimiter</span>
                    <Asterisk size={12} color="#FF0000" weight="fill" />
                    <div className={classes.cardContentDiv3}>
                      <TextField
                        placeholder="(_,-,#, etc.)"
                        type="text"
                        InputProps={{ disableUnderline: true }}
                        className={classes.cardContentInput1}
                        name="shotNameDelimiter"
                        value={formik.values.shotNameDelimiter}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                  <div style={{ width: "45%" }}>
                    <span>Number of Digits</span>
                    <Asterisk size={12} color="#FF0000" weight="fill" />
                    <div className={classes.cardContentDiv3}>
                      {/* //drop down */}
                      <Select
                        disableUnderline
                        style={{
                          width: "100%",
                        }}
                        labelId="demo-simple-select-outlined-label"
                        id="selectShotNumber"
                        label="Choose a number"
                        placeholder="Choose a number"
                        className={classes.cardContentInput1}
                        name="shotNumber"
                        value={formik.values.shotNumber}
                        onChange={formik.handleChange}
                      >
                        <MenuItem value={2}>00</MenuItem>
                        <MenuItem value={3}>000</MenuItem>
                        <MenuItem value={4}>0000</MenuItem>
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  height: "60px",
                  width: "100%",
                  margin: "12px 0px",
                }}
              >
                <span>Start shot number</span>
                <div className={classes.cardContentDiv3}>
                  <TextField
                    placeholder="0000"
                    fullWidth
                    type="number"
                    InputProps={{ disableUnderline: true }}
                    className={classes.cardContentInput1}
                    name="shotStartNumber"
                    value={formik.values.shotStartNumber}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
              <div
                style={{
                  height: "60px",
                  width: "100%",
                  margin: "12px 0px",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ width: "45%" }}>
                    <span>Amount of increment</span>
                    <Asterisk size={12} color="#FF0000" weight="fill" />
                    <div className={classes.cardContentDiv3}>
                      <TextField
                        placeholder="Enter a interval no."
                        type="text"
                        InputProps={{ disableUnderline: true }}
                        className={classes.cardContentInput1}
                        name="shotIncrementNumber"
                        value={formik.values.shotIncrementNumber}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                  <div style={{ width: "45%" }}>
                    <span>Total no. of shots</span>
                    <Asterisk size={12} color="#FF0000" weight="fill" />
                    <div className={classes.cardContentDiv3}>
                      <TextField
                        placeholder="Enter a number"
                        type="text"
                        InputProps={{ disableUnderline: true }}
                        className={classes.cardContentInput1}
                        name="shotCountNumber"
                        value={formik.values.shotCountNumber}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  height: "60px",
                  width: "100%",
                  margin: "16px 0px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    color: "#6C65D9",
                  }}
                >
                  Example
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    margin: "12px 0px",
                  }}
                >
                  <span style={{ fontSize: "16px", color: "white" }}>
                    {formik.values.shotNamePrefix}
                    {formik.values.shotNameDelimiter}
                    {padZerosAndAdd(
                      formik.values.shotNumber,
                      formik.values.shotStartNumber
                    )}
                  </span>
                  <span style={{ fontSize: "12px" }}>
                    +{Math.max(0, formik.values.shotCountNumber - 2)} shots
                  </span>
                  <span style={{ fontSize: "16px", color: "white" }}>
                    {formik.values.shotNamePrefix}
                    {formik.values.shotNameDelimiter}
                    {padZerosAndAdd(
                      formik.values.shotNumber,
                      (formik.values.shotCountNumber - 1) *
                        formik.values.shotIncrementNumber +
                        formik.values.shotStartNumber
                    )}
                  </span>
                </div>
              </div>

              {/* <div
                style={{
                  height: "60px",
                  width: "100%",
                  margin: "16px 0px",
                }}
              >
                <span>Department</span>
                <Asterisk size={12} color="#FF0000" weight="fill" />
                <div className={classes.cardContentDiv3}>
                  <TextField
                    placeholder="Choose department"
                    type="text"
                    InputProps={{ disableUnderline: true }}
                    className={classes.cardContentInput1}
                    name="departmentName"
                  />
                </div>
              </div> */}

              <CardActions className={classes.cardAction}>
                <Button
                  className={classes.doneButton}
                  type="submit"
                  value="Submit"
                  size="small"
                  variant="outlined"
                  disableElevation
                  color="secondary"
                >
                  {/* {loader ? (
                    <CircularProgress
                      color="secondary"
                      style={{ width: "25px", height: "25px" }}
                    />
                  ) : ( */}
                  <span>Create Multiple shots</span>
                  {/* )} */}
                </Button>
              </CardActions>
            </form>
          </CardContent>
        </Card>
      </div>
    </>
  );
}
