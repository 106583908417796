import React from "react";
import { Button } from "@material-ui/core";
import { X, Check, ArrowUUpRight, CaretRight, Circle } from "phosphor-react";
import useStyles from "./ReviewVersionViewerTools.styles";
import BreadCrumb from "../../BreadCrumb/BreadCrumb.jsx"; //not added yet!

const ReviewVersionViewerTools = ({
  matchWidth,
  onClose,
  updateVersionStatus,
  isExternalReviewer,
  currentStatus,
  setCurrentStatus,
  parentReview,
  selectedVersion,
  showBtn,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.toolsContainer}>
      <div
        style={{
          width: matchWidth ? "69%" : "820px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            fontSize: "14px",
          }}
        >
          <BreadCrumb
            fontSize="14px"
            shotCode={parentReview ? parentReview.name : ""}
          />
          <CaretRight />
          <Circle />
          <span>{selectedVersion?.name}</span>
        </div>
        {showBtn && (
          <div className={classes.btnContainer}>
            <Button
              className={classes.toolButton}
              style={{
                backgroundColor: currentStatus === "Revision" ? "#CE7606" : "",
                color: currentStatus === "Revision" ? "#F9F9F9" : "#606479",
              }}
              startIcon={
                <ArrowUUpRight
                  size={16}
                  color={currentStatus === "Revision" ? "#F9F9F9" : "#606479"}
                />
              }
              onClick={() =>
                updateVersionStatus("Revision")
                  .then((data) => setCurrentStatus("Revision"))
                  .catch((error) => console.log(error))
              }
            >
              Under Revision
            </Button>
            <Button
              className={classes.toolButton2}
              style={{
                backgroundColor: currentStatus === "Completed" ? "#6C65D9" : "",
                color: currentStatus === "Completed" ? "#F9F9F9" : "#606479",
              }}
              startIcon={
                <Check
                  size={27}
                  color={currentStatus === "Completed" ? "#F9F9F9" : "#606479"}
                />
              }
              onClick={() =>
                updateVersionStatus("Completed")
                  .then((data) => setCurrentStatus("Completed"))
                  .catch((error) => console.log(error))
              }
            >
              Completed
            </Button>
          </div>
        )}
      </div>
      <div
        style={{
          width: matchWidth ? "40%" : "420px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Button onClick={onClose}>
          <X size={22} weight="bold" />
        </Button>
      </div>
    </div>
  );
};

export default ReviewVersionViewerTools;
