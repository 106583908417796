import React from "react";

const ThreeDIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.2501 3.20096C17.4781 3.06931 17.7368 3 18.0001 3C18.2634 3 18.5221 3.06931 18.7501 3.20096L30.4411 9.95096C30.6691 10.0826 30.8585 10.272 30.9901 10.5C31.1218 10.728 31.1911 10.9867 31.1911 11.25V23.0175C31.1911 23.8074 30.9831 24.5833 30.5882 25.2674C30.1932 25.9515 29.6252 26.5195 28.9411 26.9145L20.2501 31.935C19.566 32.3299 18.79 32.5378 18.0001 32.5378C17.2102 32.5378 16.4342 32.3299 15.7501 31.935L7.05908 26.916C6.37501 26.521 5.80695 25.953 5.412 25.2689C5.01704 24.5848 4.8091 23.8089 4.80908 23.019V11.25C4.80909 10.9867 4.8784 10.728 5.01005 10.5C5.14171 10.272 5.33106 10.0826 5.55908 9.95096L17.2501 3.20096ZM18.0001 8.99996C18.3979 8.99996 18.7794 9.158 19.0607 9.4393C19.342 9.72061 19.5001 10.1021 19.5001 10.5V17.1345L25.2451 20.451C25.417 20.5487 25.568 20.6796 25.6892 20.8359C25.8104 20.9922 25.8995 21.171 25.9513 21.3619C26.0031 21.5528 26.0167 21.7521 25.9912 21.9483C25.9657 22.1444 25.9016 22.3336 25.8027 22.505C25.7038 22.6763 25.572 22.8263 25.4149 22.9465C25.2577 23.0667 25.0784 23.1545 24.8871 23.2051C24.6959 23.2557 24.4965 23.2679 24.3005 23.2411C24.1045 23.2143 23.9157 23.149 23.7451 23.049L18.0001 19.7325L12.2551 23.049C12.0844 23.149 11.8957 23.2143 11.6997 23.2411C11.5037 23.2679 11.3043 23.2557 11.1131 23.2051C10.9218 23.1545 10.7425 23.0667 10.5853 22.9465C10.4282 22.8263 10.2963 22.6763 10.1974 22.505C10.0985 22.3336 10.0345 22.1444 10.009 21.9483C9.98348 21.7521 9.99704 21.5528 10.0489 21.3619C10.1007 21.171 10.1898 20.9922 10.311 20.8359C10.4322 20.6796 10.5831 20.5487 10.7551 20.451L16.5001 17.1345V10.5C16.5001 10.1021 16.6581 9.72061 16.9394 9.4393C17.2207 9.158 17.6023 8.99996 18.0001 8.99996Z"
        fill="#6C65D9"
      />
    </svg>
  );
};

export default ThreeDIcon;
