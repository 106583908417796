import React, { useState, useEffect } from "react";
import { X } from "react-feather";
import { Asterisk } from "phosphor-react";
import { Popper, CircularProgress, Tooltip, Chip } from "@material-ui/core";
import SelectTrackablesModal from "./SelectTrackablesModal";
import HelperText from "../../Utilities/HelperText/HelperText";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import useStyles from "./NewTask.styles.js";

import { fget, fpost } from "../../../API/callsAPI";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";

//FIX performance degradation and rerenders are high in this component!
const CreateTaskFromTemplate = ({
  defaultLinked,
  setSnackValue,
  updateRefreshCounter,
  handleCreateTaskFromTemplateModalState,
  trackableType,
  assetList,
  sequenceList,
  taskTemplateList,
}) => {
  const classes = useStyles();
  const [templateId, setTemplateId] = useState("");
  const [selectedTrackable, setSelectedTrackable] = useState([]);
  const [findAsset, setFindAsset] = useState(false);
  const [findSequence, setFindSequence] = useState(false);
  const [selectedSequence, setSelectedSequence] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [trackableError, setTrackableError] = useState(false);
  const [templateError, setTemplateError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  //holds default trackable id value if opned on dedicated task page
  const [useDefaultLinked, setUseDefaultLinked] = useState({
    status: false,
    defaultLinkedId: null,
  });

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id1 = open ? "simple-popper" : undefined;

  const onChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    if (defaultLinked) {
      setUseDefaultLinked({ status: true, defaultLinkedId: defaultLinked });
    } else {
      setUseDefaultLinked({ status: false, defaultLinkedId: null });
    }
    return () => {};
  }, [defaultLinked]);

  const createMultipleTask = async ({
    arrayOfSelectedTrackable,
    selectedTemplateId,
  }) => {
    setLoading(true);
    const phases = await fget({
      url: `phase/?template=${selectedTemplateId}`,
    });

    const arrayOfNewTasks = []; //add all array
    const phasesArray = phases.data.results || [];
    //checks if phase has more that one phase.
    if (phasesArray.length === 0) {
      console.log(
        "Phases are empty, cannot creat a task out of empty template"
      );
      setLoading(false);
      return;
    }

    if (!useDefaultLinked.status) {
      //FIX-ME move below to a reusable function later
      for (let i = 0; i < arrayOfSelectedTrackable.length; i++) {
        let startDateDelay = 0;
        const currentTrackableId = arrayOfSelectedTrackable[i];
        for (let j = 0; j < phasesArray.length; j++) {
          const eachPhase = phasesArray[j];
          const startDate = new Date(
            new Date(
              new Date(selectedStartDate).setDate(
                new Date(selectedStartDate).getDate() + startDateDelay
              )
            ).setHours(0, 0, 0, 0)
          );
          const data = {
            start_time: startDate,
            end_time: new Date(
              new Date(startDate).setDate(
                new Date(startDate).getDate() + eachPhase.number_of_days
              )
            ),
            name: eachPhase.name,
            description: eachPhase.description,
            phase: eachPhase.id,
            linked: currentTrackableId.id,
            department: eachPhase.dept,
            assigned_users: eachPhase?.users_to_assign?.map((user) => user?.id),
            color: "decolor",
          };
          startDateDelay += eachPhase.number_of_days;
          arrayOfNewTasks.push(data);
        }
      }
    } else {
      for (let i = 0; i < 1; i++) {
        let startDateDelay = 0;
        const currentTrackableId = useDefaultLinked.defaultLinkedId;
        for (let j = 0; j < phasesArray.length; j++) {
          const eachPhase = phasesArray[j];
          const startDate = new Date(
            new Date(
              new Date(selectedStartDate).setDate(
                new Date(selectedStartDate).getDate() + startDateDelay
              )
            ).setHours(0, 0, 0, 0)
          );
          const data = {
            start_time: startDate,
            end_time: new Date(
              new Date(startDate).setDate(
                new Date(startDate).getDate() + eachPhase.number_of_days
              )
            ),
            name: eachPhase.name,
            description: eachPhase.description,
            phase: eachPhase.id,
            linked: currentTrackableId,
            department: eachPhase.dept,
            assigned_users: eachPhase?.users_to_assign?.map((user) => user?.id),
            color: "decolor",
          };
          startDateDelay += eachPhase.number_of_days;
          arrayOfNewTasks.push(data);
        }
      }
    }

    try {
      const newTask = {
        url: "trackables/task/",
        data: arrayOfNewTasks,
      };

      const newTaskRes = await fpost(newTask);
      if (newTaskRes.status === 200 || 201) {
        setLoading(false);
        handleCreateTaskFromTemplateModalState();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    //FIX-ME add a way to fix order of newely created task
  };

  useEffect(() => {
    if (selectedTrackable.id) {
      setTrackableError(false);
    }
    if (templateId) {
      setTemplateError(false);
    }
  }, [selectedTrackable.id, templateId]);

  const handleSelectTrackable = (data) => {
    const exist = selectedTrackable.some((eachUser) => eachUser.id === data.id);
    if (exist) {
      setSelectedTrackable(
        selectedTrackable?.filter((track) => track.id !== data.id)
      );
    } else {
      setSelectedTrackable([...selectedTrackable, data]);
    }
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <form enctype="multipart/form-data">
            <div className={classes.cardTool}>
              <span className={classes.title}>Create Task From Template</span>
              <Button onClick={() => handleCreateTaskFromTemplateModalState()}>
                <X className={classes.closeIcon} />
              </Button>
            </div>
            <div className={classes.cardContentDiv1}>
              {trackableType === "task" ? (
                <div className={classes.textFieldContainer}>
                  <span>Select Trackable</span>
                  <Asterisk size={12} color="#FF0000" weight="fill" />
                  <div
                    className={classes.cardContentDiv2}
                    style={{ marginTop: "5px" }}
                  >
                    <div
                      aria-describedby={id1}
                      onClick={handleClick}
                      className={classes.cardContentInput1}
                    >
                      {/* <div
                        style={{
                          margin: "5px",
                        }}
                      >
                        {selectedTrackable?.length} trackable selected
                      </div> */}
                    </div>
                    <Popper
                      id={id1}
                      open={open}
                      anchorEl={anchorEl}
                      style={{ zIndex: 1400 }}
                      className={classes.popperDiv}
                    >
                      <SelectTrackablesModal
                        disablePortal={trackableType ? true : false}
                        assetList={assetList}
                        sequenceList={sequenceList}
                        selectedTrackable={selectedTrackable}
                        setSelectedTrackable={setSelectedTrackable}
                        setAnchorEl={setAnchorEl}
                        findAsset={findAsset}
                        setFindAsset={setFindAsset}
                        findSequence={findSequence}
                        setFindSequence={setFindSequence}
                        setSelectedSequence={setSelectedSequence}
                        searchQuery={searchQuery}
                        selectedSequence={selectedSequence}
                        onChangeSearchQuery={onChangeSearchQuery}
                        handleSelectTrackable={handleSelectTrackable}
                      />
                    </Popper>
                  </div>
                  {trackableError ? (
                    <HelperText text={"This field is required"} />
                  ) : null}
                </div>
              ) : (
                ""
              )}
              <div className={classes.selectedTrackablesContainer}>
                {selectedTrackable?.map((singleTrackable) => {
                  return (
                    <div className={classes.singleTrack}>
                      <Tooltip title={singleTrackable?.name} placement="top">
                        <span>
                          {singleTrackable?.name.slice(0, 10)}
                          {singleTrackable?.name?.length > 10 ? "..." : ""}
                        </span>
                      </Tooltip>{" "}
                      <X
                        size={20}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSelectTrackable(singleTrackable)}
                      />
                    </div>
                  );
                })}
              </div>
              <div className={classes.textFieldContainer}>
                <span>Select Template</span>
                <Asterisk size={12} color="#FF0000" weight="fill" />
                <div
                  className={classes.cardContentDiv2}
                  style={{ marginTop: "5px" }}
                >
                  <Select
                    required={true}
                    style={{ width: "100%" }}
                    labelId="demo-simple-select-outlined-label"
                    id="department"
                    disableUnderline
                    className={classes.cardContentInput1}
                    label="Select Task Template"
                    onChange={(e) => setTemplateId(e.target.value.id)}
                    name="department"
                  >
                    {taskTemplateList &&
                      taskTemplateList.map((eachTemplate) => (
                        <MenuItem
                          value={eachTemplate}
                          disabled={!eachTemplate?.configured}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              fontSize: "12px",
                            }}
                          >
                            {eachTemplate.name || ""}
                            {eachTemplate?.configured ? (
                              <Chip
                                label="Configured"
                                variant="outlined"
                                style={{
                                  fontSize: "12px",
                                  color: "green",
                                }}
                              />
                            ) : (
                              <Chip
                                label="Un-Configured"
                                variant="outlined"
                                style={{
                                  fontSize: "12px",
                                  color: "yellow",
                                }}
                              />
                            )}
                          </div>
                        </MenuItem>
                      ))}
                  </Select>
                </div>
                {templateError ? (
                  <HelperText text={"This field is required"} />
                ) : null}
              </div>
              <div className={classes.textFieldContainer}>
                <span>Select Start Date</span>
                <div className={classes.datePickerDiv}>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                  >
                    <KeyboardDatePicker
                      autoOk
                      disablePast
                      disableToolbar
                      invalidDateMessage={"inValid"}
                      variant="inline"
                      format="DD/MM/yyyy"
                      margin="normal"
                      value={selectedStartDate}
                      onChange={(date) => handleStartDateChange(date)}
                      id="Startdate-picker"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                        style: {
                          padding: "0px",
                        },
                      }}
                      className={classes.datePickerComponent}
                      size="small"
                      fullWidth={true}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
            <br />

            <CardActions className={classes.cardAction}>
              <Button
                fullWidth
                size="small"
                disableElevation
                onClick={(e) => {
                  e.preventDefault();
                  //createTask(); //old function
                  createMultipleTask({
                    useDefaultLinked: defaultLinked,
                    arrayOfSelectedTrackable: selectedTrackable,
                    selectedTemplateId: templateId,
                  });
                }}
                disabled={
                  !(templateId && (defaultLinked || selectedTrackable.length))
                } //disable if req. feild are not selected
              >
                {loading ? (
                  <CircularProgress size={30} />
                ) : (
                  <b>Create Task From Template</b>
                )}
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default CreateTaskFromTemplate;
